import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeTransactionsComponent } from '../public/dashboard/components/employee-transactions/employee-transactions.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { StarRatingModule } from 'angular-star-rating';
import { NgxPowerBiModule } from 'ngx-powerbi';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from '../../environments/environment';
import { AvatarModule } from '../core/components/avatar/avatar.module';
import { DashboardRoutingModule } from '../public/dashboard/dashboard-routing.module';
import { DownloadMenuButtonComponent } from '../core/components/download-menu-button/download-menu-button.component';
import { EmployeeInfoComponent } from '../public/dashboard/components/employee-info/employee-info.component';
import { BreadcrumbComponent } from '../public/components/breadcrumb/breadcrumb.component';
import { EmployeeHeaderComponent } from '../public/dashboard/components/employee-header/employee-header.component';
import { MultiStepComponent } from '../core/components/multi-step/multi-step.component';
import { LinkedListComponent } from '../core/components/linked-list/linked-list.component';
import { EmployeeMovePhaseComponent } from '../public/dashboard/components/employee-move-phase/employee-move-phase.component';
import { MoveRiskModalComponent } from '../public/dashboard/components/move-risk-modal/move-risk-modal.component';
import { EmployeeProvidersComponent, FilterPipe} from '../public/provider/components/employee-providers/employee-providers.component';
import { SearchHighlightPipe } from '../public/administration/pipes/search-highlight.pipe';
import { SharedTransfereeProgramModule } from '../public/administration/modules/shared/shared.module';
import { NumberOnlyDirective } from '../core/directives/number-only.directive';
// import { HighlightSearch } from '../public/administration/modules/shared/components/configurable-field/configurable-field.component';



@NgModule({
  declarations: [
    EmployeeTransactionsComponent,
    DownloadMenuButtonComponent,
    EmployeeInfoComponent,
    BreadcrumbComponent,
    EmployeeHeaderComponent ,
    MultiStepComponent,
    LinkedListComponent,
    EmployeeMovePhaseComponent,
    MoveRiskModalComponent,
    EmployeeProvidersComponent,
    SearchHighlightPipe,
    FilterPipe,
    NumberOnlyDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    A11yModule,
    MatMenuModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatIconModule,
    MatCardModule,
    MatExpansionModule,
    MatDividerModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatCheckboxModule,
    AvatarModule,
    NgxPowerBiModule,
    NgxSpinnerModule,
    DragDropModule,
    CdkTableModule,
    CdkTreeModule,
    MatSlideToggleModule,
    MatInputModule,
    environment.animation ? BrowserAnimationsModule : NoopAnimationsModule,
    DashboardRoutingModule,
    StarRatingModule.forRoot(),
    SharedTransfereeProgramModule
  ],
  exports: [
    EmployeeTransactionsComponent,
    DownloadMenuButtonComponent,
    EmployeeInfoComponent,
    BreadcrumbComponent,
    EmployeeHeaderComponent ,
    MultiStepComponent,
    LinkedListComponent,
    StarRatingModule,
    EmployeeMovePhaseComponent,
    MoveRiskModalComponent,
    EmployeeProvidersComponent,
    SearchHighlightPipe,
    SharedTransfereeProgramModule,
    FilterPipe,
    NumberOnlyDirective   

  ]
})
export class SharedTraditionalOrderModule { }

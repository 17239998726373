<div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
   fxLayoutGap="10px" fxLayoutGap.xs="0em">Approve Move
     <span  class="close-icon" (click)="onClose()" title="close"><mat-icon>close</mat-icon></span>   
    

</div>
<div mat-dialog-content tabindex = "0" >
     <span>By approving this move you are authorizing the specified transferee's move. if you need to make any changes to their information, you can do so by clicking to edit detail.<br/>
            otherwise, if you have no changes you can authorize the move as it is.
     </span>
<div>
<div fxLayoutAlign="end end">
    <button class="cancel-btn" mat-button (click)="onCancel()">Cancel</button>
    <button class="edit-btn" mat-button (click)="onEditClick()">Edit Details</button>
    <button class="confirm-btn" mat-button (click)="onConfirm()">{{this.data.candidate.authorizationType === 'Traditional'? 'Authorize Move':'Invite Transferee'}}</button>
</div>
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FlexModule } from '@angular/flex-layout';
import { DetailComponent } from './detail/detail.component';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SharedModule } from './../../../../core/shared.module';
import { EditBenefitDialogComponent } from './components/edit-benefit-dialog/edit-benefit-dialog.component';
import {MatSelectModule} from '@angular/material/select';
import { SortbyPipe } from '../../pipes/sortby.pipe';
import { PointsLogicModalComponent } from './components/points-logic-modal/points-logic-modal.component';
import { BenefitSettingDialogComponent } from './components/benefit-setting-dialog/benefit-setting-dialog.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { CdkDropListScrollContainerDirective } from './../../../../core/directives/drop-list-scroll-container.directive'
import { SharedTraditionalOrderModule } from '../../../../../app/shared/sharedTraditionalOrder.module';
import { OpearatorUitypeRestrictionComponent } from './components/opearator-uitype-restriction/opearator-uitype-restriction.component';
import { MatTabsModule } from '@angular/material/tabs';
import { BenefitRestrictionsRulesTasksComponent } from './components/benefit-restrictions/benefit-restrictions-rules-tasks.component';
import { DynamicBenefitUpsertFormComponent } from '../../../components/dynamic-benefit-upsert-form/dynamic-benefit-upsert-form.component';
import { DynamicAttributeComponent } from '../../../../shared/components/dynamic-attribute/dynamic-attribute.component';
import { MultiplicityFormComponent } from '../../../../shared/components/multiplicity-form/multiplicity-form.component';
import { NumberWithCommasPipe } from '../../../../shared/pipes/numberwithcommas.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
@NgModule({
    declarations: [DetailComponent, EditBenefitDialogComponent, SortbyPipe, BenefitSettingDialogComponent, CdkDropListScrollContainerDirective, OpearatorUitypeRestrictionComponent, BenefitRestrictionsRulesTasksComponent, DynamicBenefitUpsertFormComponent, DynamicAttributeComponent,MultiplicityFormComponent, NumberWithCommasPipe ],
    imports: [
        SharedTraditionalOrderModule,
        CommonModule,
        MatCardModule,
        MatExpansionModule,
        FlexModule,
        MatIconModule,
        MatFormFieldModule,
        FormsModule,
        MatButtonModule,
        MatInputModule,
        MatCheckboxModule,
        MatMenuModule,
        MatSnackBarModule,
        SharedModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatChipsModule,
        MatSlideToggleModule,
        MatAutocompleteModule,
        DragDropModule,
        MatTabsModule,
        MatTooltipModule
    ],
    exports: [DetailComponent],
    schemas: [NO_ERRORS_SCHEMA],
    bootstrap: [DetailComponent]
})
export class ProgramModule { }

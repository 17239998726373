import { Injectable } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class PdfFontsService{
  /**
   * @param http Inject Http client
   */
  constructor(
    private readonly http: HttpClient
  ) {}

  /** stores base64 version of cartus logo */
  cartusLogob64;
  /** stores base64 version of benefits builder logo */
  bbLogob64;
  /** Custom fonts to be used in PDF */
  fonts = ['Avenir-Book.ttf', 'Avenir-Medium.ttf', 'Avenir-Light.ttf'];
  /** To store current location */
   url = location.origin;

  /**
   * Convert file to base64
   * @param url Image URL/Font URL
   */
  private getBase64(url: string): Observable < string > {
    return this.http.get(url, {
      responseType: 'blob'
    }).pipe(
      flatMap(blob => {
        return this.imaageToBase64(blob);
      })
    );
  }

  /**
   * Covnert File to base 64
   * @param blob File blob
   */
  private imaageToBase64(blob: Blob): Observable < any > {
    const fileReader = new FileReader();
    const observable = new Observable(observer => {
      fileReader.onloadend = () => {
        observer.next(fileReader.result);
        observer.complete();
      };
    });
    fileReader.readAsDataURL(blob);
    return observable;
  }


  /**
   * Define custom fonts to be used by PDFMake
   */
  defineFonts() {
    this.fonts.forEach(fontName => {
      this.getBase64(`${this.url}/assets/fonts/${fontName}`).subscribe(
        value => {
          pdfMake.vfs[fontName] = value.split(',')[1];
        });
    });


  }

   /**
   * Define custom fonts to be PDFMake font objects
   */
  assignFonts() {
    pdfMake.fonts = {
      // Default font should still be available
      Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-Italic.ttf'
      },
      // Make sure you define all 4 components - normal, bold, italics, bolditalics - (even if they all point to the same font file)
      AvenirMedium: {
        normal: this.fonts[1],
        bold: this.fonts[1],
        italics: this.fonts[1],
        bolditalics: this.fonts[1]
      },
      AvenirBook: {
        normal: this.fonts[0],
        bold: this.fonts[0],
        italics: this.fonts[0],
        bolditalics: this.fonts[0]
      },
      AvenirLight: {
        normal: this.fonts[2],
        bold: this.fonts[2],
        italics: this.fonts[2],
        bolditalics: this.fonts[2]
      }

    };
  }

 /**
   * To convert Logos to base 64 format
   */
  loadLogos() {
    this.getBase64(`${this.url}/assets/images/cartus.png`).subscribe(
      value => {
        this.cartusLogob64 = value;
      });

    this.getBase64(`${this.url}/assets/images/BB-logo.png`).subscribe(
      value => {
        this.bbLogob64 = value;
      });
  }


}

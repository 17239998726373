import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocationsService } from '../../administration/services/locations.service';
import { Country } from '../../../core/models/country.model';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ClientContactRegistration, phone } from '../../../core/models/client-contact-registration.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { map, startWith } from 'rxjs/operators';
import { ClientContactService } from '../../../core/services/client-contact.service';
import { DatePipe } from '@angular/common';
import { emailTypes } from '../../administration/services/family-info.service';
import { ClientService } from '../../administration/services/client.service';
import { UserTypeService } from '../../administration/services/user-type.service';
import { UserSessionService } from '../../../core/services/user-session.service';
import { FeatureFlagService } from '../../../core/services/feature-flag.service'

/** response for sendInvite to client contact service call */
export const sendInviteResponse = `Invitation Sent Successfully`;
/** response for resend invite to client contact service call */
export const resendInviteResponse = `Invitation Resent Successfully`;
/** response for client contact save draft */
export const saveDraftResponse = `Saved As Draft Successfully`;
/** response for client contact save updates */
export const saveUpdatesResponse = `Saved Updates Successfully`;
/** response for client contact save updates and mail sent */
export const sendRoleUpdateResponse = `Role Updated Successfully`;
@Component({
  selector: 'app-client-contact-details',
  templateUrl: './client-contact-details.component.html',
  styleUrls: ['./client-contact-details.component.scss']
})

export class ClientContactDetailsComponent implements OnInit {
  /**Form group name */
  updateClientContactForm: UntypedFormGroup;
  /**Countries retrieved from service */
  countryList: Array<Country>;
  /** Dynamic text to be displayed in form title */
  formTitle: string;
  /** existing client contact information */
  editClientContactRegistration: ClientContactRegistration = {} as ClientContactRegistration;
  /** List of filtered options by autocomplete phone code field */
  filteredOptions: Observable<Country[]>;
  editRole: any;
  @ViewChild('autoCompleteInput', {
    read: MatAutocompleteTrigger,
    static: true
  }) autoComplete: MatAutocompleteTrigger;
  ssoRegex: any;
  userRole: any;
  sssFlag: any;
  clientNumber: string;
  ssoClient: boolean = false;
  ssoRegexErrorMessage: any;
  sso :boolean = false;
  /**to handle api failure for regex sso */
  showSSOApiErr: any;
  roleArray = [
    { label: "Initiator", roleName: "initiator" },
    { label: "None", roleName: "none" }
  ]
  /*for managing capabilities */
  userCapabilities: string;
  registerCapabilities: string;
  manageSso: string;
  // feature flag 
  loginappflag: boolean = false;
  //show EditClientContact popup based on featureflag 
  showEditClientContact: string='';
  //ssousers
  ssoClientContact:Boolean;
  /**
   * @param formBuilder FormBuilder
   * @param locationService LocationService
   * @param dialogRef DialogRef
   * @param data Input from list of client contacts
   * @param spinner Spinner
   * @param clientContactService Client Contact Service
   * @param dialog Dialog
   * @param toastrService Toastr
   * @param live Live
   */
  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly locationService: LocationsService,
    public dialogRef: MatDialogRef<ClientContactDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly spinner: NgxSpinnerService,
    private readonly clientContactService: ClientContactService,
    public dialog: MatDialog,
    private readonly toastrService: ToastrService,
    public live: LiveAnnouncer,
    private datePipe: DatePipe,
    private userSessionService: UserSessionService,
    private userTypeService: UserTypeService,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.loginappflag=this.data.featureFlag;
    this.clientNumber = sessionStorage.getItem('clientNo');
    // const data = this.userSessionService.ssoRole.getValue()
    // data.forEach(option => {
    //   if (option.clientNo == this.clientNumber) {
    //     this.sso = option.sso;
    //     this.ssoRegex = option.ssoRegex;
    //     this.ssoRegexErrorMessage = option.ssoRegexErrorMessage;
    //   }
    // });
    if (this.data.ssoData.sso) {
      this.sso = this.data?.ssoData?.sso ? true : false
      this.ssoRegex = this.data.ssoData.ssoRegex
      this.ssoRegexErrorMessage = this.data.ssoData.ssoRegexErrorMessage
    }
    this.userRole = this.userTypeService.userType;
    this.userTypeService.capabilities$.subscribe(ele => {
      if (ele) { this.checkCapabilities(ele) };
    });
    this.spinner.show();
    this.editClientContactRegistration = this.data.client;
    if(this.sso && (this.userCapabilities === 'write' || this.userCapabilities === 'read' || this.manageSso === 'read' || this.manageSso === 'write')){
      this.ssoClientContact=true;
    }
    else{
      this.ssoClientContact=false;
    }
    this.updateClientContactForm = this.populateForm(this.editClientContactRegistration, this.data.company);
    if (this.userCapabilities === 'read' || this.editClientContactRegistration.status === 'Active' && this.userCapabilities === 'write') {
      this.updateClientContactForm.controls.email.disable();
      (this.updateClientContactForm.controls.phoneDetails as UntypedFormGroup).controls.phoneDialingCode.disable();
      (this.updateClientContactForm.controls.phoneDetails as UntypedFormGroup).controls.phoneNumber.disable();
    }
    if ((this.editClientContactRegistration.status === 'Invitation Sent' || this.editClientContactRegistration.status === 'Invitation Not Sent') && (this.userCapabilities === 'read') || (this.editClientContactRegistration.status === 'Active' && (this.userCapabilities === 'write' && !this.manageSso))
      || (this.editClientContactRegistration.status === 'Active' && (this.manageSso === 'read')) || (this.editClientContactRegistration.status === 'Active' && (this.userCapabilities === 'read' && !this.manageSso))) {
      this.updateClientContactForm.controls.ssoIdentifier.disable();
    }
    this.formTitle = 'Edit Client Contact';
    // populate phone dial code dropdown
    this.locationService.countryList.subscribe(countryList => {
      this.filteredOptions = this.updateClientContactForm.get('phoneDetails.phoneDialingCode').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      if (countryList.length > 0) {
        countryList.sort((a, b) => a.countryName.localeCompare(b.countryName));
        this.countryList = countryList;
      }
    });
    this.spinner.hide();
  }
  /**
   * Filter in dropdown by values
   * @param value value in autocomplete
   */
  private _filter(value): Country[] {
    if (value) {
      const filterValue = typeof value === 'number' ? value : value.toLowerCase();
      const countryList = this.countryList.filter(option => option.countryName.toLowerCase().indexOf(filterValue) === 0);
      return countryList;
    } else {
      return this.countryList;
    }
  }
  /**
   * Will instantiate the form and populate values to form with existing data
   * @param editData contains existing client contact information
   */
  populateForm(editData, company): UntypedFormGroup {
    const check = this.isNullCheck;
    const addForm: UntypedFormGroup = this.formBuilder.group({
      nameDetails: this.formBuilder.group({
        firstName: [editData ? check(editData, 'firstName') : ''],
        lastName: [editData ? check(editData, 'lastName') : '']
      }),
      email: [editData && editData.email && editData.email.length > 0 ? check(editData.email[0], 'emailAddress') : '',
      [Validators.required, Validators.email, Validators.pattern('^[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$')]
      ],
      phoneDetails: this.formBuilder.group({
        phoneDialingCode: [editData && editData.phone && editData.phone.length > 0 ? check(editData.phone[0], 'phoneDialingCode') : '', this.sso ? [Validators.pattern('^[0-9]*$')] : [Validators.required, Validators.pattern('^[0-9]*$')]],
        phoneNumber: [editData && editData.phone && editData.phone.length > 0 ? check(editData.phone[0], 'phoneNumber') : '',
        this.sso ? [Validators.maxLength(18), Validators.minLength(7),
        Validators.pattern('^[0-9]*$'), Validators.max(999999999999999999)] : [Validators.required, Validators.maxLength(18), Validators.minLength(7),
        Validators.pattern('^[0-9]*$'), Validators.max(999999999999999999)]
        ]
      }, { validators: this.validatePhoneRequired.bind(this)}),
      company: [company ? company : ''],
      roleName: (this.loginappflag && !this.ssoClientContact) ? [editData && editData.role == 'client-contact-initiator' || editData.hasCCIRole ? 'initiator' : 'none'] :
        [editData && editData.role == 'client-contact-initiator' || editData.hasCCIRole ? 'initiator' : 'none', [Validators.required]],
      ssoIdentifier: [editData ? check(editData, 'ssoIdentifier') : null, this.ssoRegex ? [Validators.pattern(atob(this.ssoRegex)), Validators.required] : null],
    });

    if (editData && editData.status && editData.status === 'Withdrawn') {
      Object.keys(addForm.controls).forEach(group => addForm.controls[group].disable());
    }
    return addForm;
  }

  /**
   * Custom error messages for Email, phone number and phone dial code to verify special character or empty errors
   * @param fieldName - field parameter to check for errors
   */
  getErrorMessage(formControl, fieldName) {
    if (formControl == 'email' && (this.showSSOApiErr && this.showSSOApiErr.includes('Business email'))) {
      return this.showSSOApiErr;
    } else
      if (formControl == 'ssoIdentifier' && (this.updateClientContactForm.get(formControl).hasError('pattern') ||
        this.updateClientContactForm.get(formControl).hasError('required') || this.updateClientContactForm.get(formControl).invalid)) {
        return this.showSSOApiErr ? this.showSSOApiErr : this.ssoRegexErrorMessage;
      } else
        if (this.updateClientContactForm.get(formControl).hasError('required')) {
          return ('You must enter ' + fieldName);
        } else if (this.updateClientContactForm.get(formControl).hasError('pattern') || this.updateClientContactForm.get(formControl).hasError('minlength') ||
          this.updateClientContactForm.get(formControl).hasError('maxlength') || this.updateClientContactForm.get(formControl).hasError('email')) {
          return ('You must enter a valid ' + fieldName);
        } else {
          return '';
        }
  }

  validatePhoneRequired(group: UntypedFormGroup) {
    if ((group.controls['phoneDialingCode'].value && !group.controls['phoneNumber'].value) || (group.controls['phoneNumber'].value && !group.controls['phoneDialingCode'].value)) {
      if (group.controls['phoneDialingCode'].value) {
        group.controls['phoneNumber'].setErrors({ required: true })
      }
      else {
        group.controls['phoneDialingCode'].setErrors({ required: true })
      }
      return {
        validatePhoneRequired: true
      };
    } else {
      if (group.errors && group.errors.hasOwnProperty('validatePhoneRequired')) {
        if (!group.controls['phoneNumber'].value && !group.controls['phoneDialingCode'].value && !this.sso) {
          group.controls['phoneNumber'].setErrors({ required: true })
          group.controls['phoneDialingCode'].setErrors({ required: true })
        } else {
          if (group.controls['phoneNumber'].hasError) {
            group.controls['phoneNumber'].setErrors(null)
          }
          if (group.controls['phoneDialingCode'].hasError) {
            group.controls['phoneDialingCode'].setErrors(null)
          }
        }
        delete group.errors['validatePhoneRequired'];
        group.updateValueAndValidity();
      }
    }
  }
  /**
   * Checks for Value or will return 'empty'
   * @param value any
   */
  isNullCheck(obj: Object, key: string) {
    try {
      return ((obj[key] || obj[key] === false) && obj[key] !== null) ? obj[key] : '';
    } catch (error) {
      return '';
    }
  }

  displayFn(country: any): string {
    return country ? '+ ' + country : '';
  }

  /**
   * Click on Save Draft button, check if the values of the fields provided are valid except required validation
   * @param formGroup FormGroup
   */
  isFormValidToSaveDraft(formGroup: UntypedFormGroup) {
    const controls = formGroup.controls;
    for (const name in controls) {
      if ((controls[name] as UntypedFormGroup).controls) {
        if (controls[name].invalid && controls[name].errors && !controls[name].hasError('required')) {
          this.updateClientContactForm.controls[name].markAsTouched();
          return false;
        }
        if (!this.isFormValidToSaveDraft(controls[name] as UntypedFormGroup))
          return false;
      }
    }
    return true;
  }
  /**
   * To create request object to be sent to save draft/updates and send/resend invite
   * @param updatedFormDetails updated form details
   */
  createRequestObject(updatedFormDetails, sendInvite = false) {
    let saveRequestObj: ClientContactRegistration = {} as ClientContactRegistration;
    const updatedForm = this.updateClientContactForm;
    if (this.manageSso === 'write' && this.editClientContactRegistration.status === 'Active') {
      if (updatedForm.controls['ssoIdentifier']) {
        saveRequestObj['ssoIdentifier'] = updatedFormDetails.ssoIdentifier;
      }
      saveRequestObj['clientID'] = this.editClientContactRegistration.clientID;
      saveRequestObj['clientContactID'] = this.editClientContactRegistration.clientContactID;

    } else {
      if (updatedForm.controls['email'].dirty) {
        const emailObject = {};
        saveRequestObj['email'] = [];
        if (updatedFormDetails.email) {
          emailObject['emailAddress'] = updatedFormDetails.email;
          saveRequestObj['email'].push(emailObject);
        }
        this.editClientContactRegistration.email = saveRequestObj.email;
      }

      if (updatedForm.controls['ssoIdentifier']) {
        saveRequestObj['ssoIdentifier'] = updatedFormDetails.ssoIdentifier;
      }

      const phoneDetailsGrp = updatedForm.controls['phoneDetails'] as UntypedFormGroup;
      if (phoneDetailsGrp.controls['phoneDialingCode'].value || phoneDetailsGrp.controls['phoneNumber'].value) {
        const phoneObject = {};
        saveRequestObj['phone'] = [];
        if (updatedFormDetails.phoneDetails && phoneDetailsGrp.controls['phoneDialingCode'].value && phoneDetailsGrp.controls['phoneNumber'].value) {
          phoneObject['phoneNumber'] = updatedFormDetails.phoneDetails.phoneNumber;
          phoneObject['phoneDialingCode'] = updatedFormDetails.phoneDetails.phoneDialingCode;
          saveRequestObj['phone'].push(phoneObject);
        }else{
          phoneObject['phoneNumber'] = phoneDetailsGrp.controls['phoneNumber'].value;
          phoneObject['phoneDialingCode'] = phoneDetailsGrp.controls['phoneDialingCode'].value ;
          saveRequestObj['phone'].push(phoneObject);
        }
        this.editClientContactRegistration.phone = saveRequestObj.phone;
      }

      if (Object.keys(saveRequestObj).length !== 0 || sendInvite) {
        saveRequestObj['clientID'] = this.editClientContactRegistration.clientID;
        saveRequestObj['clientContactID'] = this.editClientContactRegistration.clientContactID;
        saveRequestObj['roleId'] = this.editClientContactRegistration.roleId;
        saveRequestObj['roleName'] = updatedFormDetails.roleName;
      }

      if (updatedForm.controls['roleName'].dirty) {
        saveRequestObj['roleChanged'] = true;
      } else {
        saveRequestObj['roleChanged'] = false;
      }
    }
    return saveRequestObj;
  }

  /**
   * To Save client contact Details as Draft and save updates
   * @param actionType Type of action
   */
  saveClientContactDetails(actionType: 'draft' | 'update') {
    this.spinner.show();
    if (this.isFormValidToSaveDraft(this.updateClientContactForm)) {
      const updateClientContactDetails = this.createRequestObject(this.updateClientContactForm.value);
      if (Object.keys(updateClientContactDetails).length !== 0) {
        this.clientContactService.updateClientContact(updateClientContactDetails).subscribe(
          (response) => {
            this.spinner.hide();
            const ssoErr = (typeof (response) == 'string' && response.includes("SSO")) ? true : false;
            if (ssoErr) {
              this.showSSOApiErr = response;
              this.checkForSSoError();
            }
            else if (!ssoErr && response) {
              this.flashAndCloseDialog(actionType === 'draft' ? saveDraftResponse : saveUpdatesResponse, true, updateClientContactDetails);
              this.spinner.hide();
            }
          },
          err => {
            this.spinner.hide();
          }
        );
      } else {
        this.flashAndCloseDialog(actionType === 'draft' ? saveDraftResponse : saveUpdatesResponse, true, null);
        this.spinner.hide();
      }
    } else {
      this.spinner.hide();
    }
  }

  /**
   * To send and resend invite to client contact
   */
  sendResendInvite() {
    if (this.updateClientContactForm.valid) {
      this.spinner.show();
      const updateClientContactDetails = this.createRequestObject(this.updateClientContactForm.value, true);
      updateClientContactDetails['sendInvite'] = true;

      //If User have CCI role and status is Invitation sent then rolechanged is null
      //commenting below code as we are setting the value of roleChanged based on roleName dropdown
      // if (this.editClientContactRegistration.hasCCIRole && this.editClientContactRegistration.status === 'Invitation Sent') {
      //   updateClientContactDetails['roleChanged'] = null;
      // } else {
      //   updateClientContactDetails['roleChanged'] = true;
      // }
      this.clientContactService.sendInviteToClientContact(updateClientContactDetails).subscribe(
        (response) => {
          this.spinner.hide();
          const ssoErr = (typeof (response) == 'string' && response.includes("SSO")) ? true : false;
          if (ssoErr) {
            this.showSSOApiErr = response;
            this.checkForSSoError();
          }
          else if (!ssoErr && response) {
            let response;
            // if (updateClientContactDetails['status'] === 'Invitation Not Sent') {
            if (this.editClientContactRegistration.status === 'Invitation Not Sent') {
              updateClientContactDetails.status = 'Invitation Sent';
              updateClientContactDetails.statusDate = this.datePipe.transform(new Date(), "yyyy-MM-dd")
              response = sendInviteResponse;
            } else {
              response = resendInviteResponse;
            }
            this.flashAndCloseDialog(response, true, updateClientContactDetails);
          }
        },
        err => {
          this.spinner.hide();
        }
      );
    }
  }

  updateRole() {
    if (this.updateClientContactForm.valid) {
      this.spinner.show();
      const updateClientContactDetails = this.createRequestObject(this.updateClientContactForm.value, true);
      this.clientContactService.updateClientContact(updateClientContactDetails).subscribe(
        (response) => {
          this.spinner.hide();
          const ssoErr = (typeof(response) == 'string' && response.includes("SSO")) ? true : false;
          if (ssoErr) {
            this.showSSOApiErr = response;
            this.checkForSSoError();
          }
          else if (!ssoErr && response) {
            let response;
            response = sendRoleUpdateResponse;
            this.flashAndCloseDialog(response, true, updateClientContactDetails);
          }
        },
        err => {
          this.spinner.hide();
        }
      );
    }
  }

  /**
   * To flash toastr with status message and close dialog on success
   *@param message string to be displayed in toastr
   *@param success success/failure
   */
  flashAndCloseDialog(message: string, success, requestObj) {
    if (success) {
      this.toastrService.info(message, null, {
        closeButton: true,
        enableHtml: true,
        disableTimeOut: false // User must explicitly dismiss error messages
      });
      this.live.announce(message);
      this.dialogRef.close(requestObj);
    } else {
      this.toastrService.error(message, null, {
        closeButton: true,
        enableHtml: true,
        disableTimeOut: false // User must explicitly dismiss error messages
      });
    }
  }

  /**
   * Invoked on click of dismiss/close
   */
  onDismiss(evt) {
    evt.preventDefault();
    this.dialogRef.close(null)
  }

  checkForSSoError() {
    if (this.showSSOApiErr.includes('Business email')) {
      this.updateClientContactForm.get('email').markAllAsTouched();
      this.updateClientContactForm.get('email').setErrors({ 'incorrect': true });
    } else {
      this.updateClientContactForm.get('ssoIdentifier').setErrors({ 'incorrect': true });
    }
  }
  disableSsO() {
    const ssoControl = this.updateClientContactForm.controls.ssoIdentifier;
    if (ssoControl.invalid && (ssoControl.touched || ssoControl.dirty)) {
      return true;
    }
    return false;
  }

  disableActiveUser() {
    const roleNameControl = this.updateClientContactForm.controls.roleName;
    if (roleNameControl.touched || roleNameControl.dirty) {
      return false;
    }
    return true;
  }

  disableEmail() {
    const emailControl = this.updateClientContactForm.controls.email;
    if (emailControl.invalid && (emailControl.touched || emailControl.dirty)) {
      return true;
    }
    return false;
  }

  disableSaveDraft() {
    const roleControl = this.updateClientContactForm.controls.roleName;
    const ssoControl = this.updateClientContactForm.controls.ssoIdentifier;
    if ((roleControl.value === 'initiator' &&
      (ssoControl.touched || ssoControl.dirty || (ssoControl.value && ssoControl.value !== '')))) {
      return true;
    }
    return false;
  }

  checkCapabilities(capabilitiesList: any[]) {
    capabilitiesList.forEach(capability => {
      if (capability && capability.permission === 'allow') {
        if (capability.name === "Manage Client Contacts") {
          capability.operation == 'read' ? this.userCapabilities = 'read' :
            capability.operation == 'write' ? this.userCapabilities = 'write' : null;
        } else if (capability.name === 'Register Client Contact') {
          capability.operation == 'read' ? this.registerCapabilities = 'read' :
            capability.operation == 'write' ? this.registerCapabilities = 'write' : null;
        } else if (capability.name === 'MP - Manage Client Contact SSO' && capability.permission == "allow") {
          capability.operation == 'read' ? this.manageSso = 'read' :
            capability.operation == 'write' ? this.manageSso = 'write' : null;
        }
      }
    });
  }

  checkSso() {
    if (this.updateClientContactForm.controls.ssoIdentifier.value == '') {
      this.updateClientContactForm.get('ssoIdentifier').clearValidators();
      this.updateClientContactForm.get('ssoIdentifier').setErrors(null);
      this.disableSsO();
    }
  } 
  onChangeValue(){
    this.disableSaveUpdates()
  }
  disableSaveUpdates() {
    const roleNameControl = this.updateClientContactForm.controls.roleName;
    if(roleNameControl.touched && roleNameControl.dirty) {
        return false;
      }
    return true;
  }
}

<div class="logout-modal-wrap">
  <div fxLayout="row" fxLayoutAlign="space-between" class="mat-dialog-title">
      <h2>Log Out?</h2>
      <button mat-icon-button icon-button mat-dialog-close aria-label="Close" class="icon-button" matTooltip="Close"><span
      class="material-icons">close</span></button>
  </div>
  <div class="mat-dialog-content"  fxLayout.xs="column" fxLayoutAlign.xs="center">
    <div class="content body-copy">
      Please be aware that if you log out, any unsaved changes may be lost.
    </div>
  </div>
  <div class="mat-dialog-actions" fxLayoutAlign="flex-end">
      <div class="logout-buttons" fxLayout="row">
          <button mat-button class="text-button" mat-dialog-close> cancel </button>
          <button mat-button class="contained-button" (click)="onLogout()"> log out </button>
      </div>
  </div>
</div>
/** a model to contain client contact details */
export interface ClientContactRegistration {
  /** clientContactID to store the client contact Id */
  clientContactID: string;
  /** Full Name of the client contact */
  fullName?: string;
  /** first name of the client contact */
  firstName?: string;
  /** last name of the client contact */
  lastName?: string;
  /** emailAddress of the client contact */
  email?: Array<email>;
  /** phoneNumber of the client contact */
  phone?: Array<phone>;
  /** status of the client contact */
  status?: string;
  /** company name of the client contact */
  company?: string;
  /** role of the client contact */
  role?: string;
  /** statusDate of the client contact */
  statusDate?: string;
  /** client id of client */
  clientID?: string;
  /** Flag to identify if role has been changes */
  roleChanged?: boolean;
  /** Status of client contact in Atlas */
  atlasStatus?: string;
  /** If invitation is being sent for the first time */
  sendInvite?: boolean;
  /** role id of client contact */
  roleId?: string;
  ssoIdentifier?: any;

  /** If true only when user has CCI role  */
  hasCCIRole?: boolean;
}

export interface email {
  emailAddress?: string;
  emailAddressDesc?: string;
}

export interface phone {
  phoneNumber?: number;
  phoneDialingCode?: string;
  phoneNumberDesc?: string;
}
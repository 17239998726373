import { Component, OnInit, OnDestroy, ViewEncapsulation, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import { EmployeeInfo } from '../../../../core/models/employee-info.model';
import { EmployeeDetailsService } from '../../../../core/services/employee-details.service';
import { UserPreferencesService } from '../../../../core/services/user-preferences.service';
import { LinkedListData } from '../../../../core/models/linked-list-data.model';
import { MilestoneService } from '../../../../core/services/milestone.service';
import { DateConversionService } from '../../../../core/services/date-conversion.service';
import { MovePhaseService } from '../../../../core/services/move-phase.service';
import { SentimentService } from '../../../../core/services/sentiment.service';
import { Sentiment } from '../../../../core/models/sentiment.model';
import { interval } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { Sentiments } from '../../../../core/models/sentiments.model';
import { Milestones } from '../../../../../app/core/models/milestones.model';
import { ProviderService } from '../../../../core/services/provider.service';

/** This component displays details about a person in the system that is moving/has moved. */
@Component({
  selector: 'app-employee-info',
  templateUrl: './employee-info.component.html',
  styleUrls: ['./employee-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeeInfoComponent implements OnInit, OnDestroy {

  /** holds subscription to the data */
  sub: Subscription;

  /** holds subscription to the movephase data */
  movePhaseSub: Subscription;

  /** holds subscription to the milestone data */
  milestoneSub: Subscription;

  /** holds subscription to the milestone data */
  sentimentSub: Subscription;

  /** Provider Subscription */
  providerSub: Subscription;

  providerCount = 0 ;

  /** observable that holds the data from the service call */
  public employee$: Observable<EmployeeInfo>;
  public employeeDetails$: Observable<EmployeeInfo>;

  /** holds the data for the Linked list component */
  milestonesData: LinkedListData[] = [];

  /** holds the sentiment data for the Linked list component */
  sentimentData: LinkedListData[] = [];

  /** empMovePhase array variable of type LinkedListData[] */
  empMovePhases: LinkedListData[] = [];

  /** holds the value for milestone expansion panel to expand/collapse */
  milestonePanelState = false;

  /** holds the value for sentiment expansion panel to expand/collapse */
  sentimentPanelState = false;

  marker: any;

  /** fileNumber variable to pass file number to get fetch response */
  fileNumber: number;

  /** hold favorite user flag of employee */
  favUser: number = 0;

  /** to store prefered currency */
  preferedCurr : string = "";

  /** Basic constructor */
  constructor(
    private readonly route: ActivatedRoute,
    private readonly empDetailSvc: EmployeeDetailsService,
    private readonly milestoneSvc: MilestoneService,
    private readonly sentimentSvc: SentimentService,
    private readonly dateSvc: DateConversionService,
    private readonly loc: Location,
    private readonly movePhaseService: MovePhaseService,
    private readonly providerSvc: ProviderService,
    private readonly router: Router,
    private readonly userPreferenceService : UserPreferencesService,
    public readonly dateConversionService: DateConversionService) {
     }

  /** get the employee data and setup the key dates data */
  ngOnInit() {
    let empMovePhase: number;
    this.sub = this.route.params.subscribe(params => {
      this.fileNumber = +params.id;
      this.milestonesData = [];
      this.sentimentData = [];
      this.userPreferenceService.getPreference('user_config', false)
      .subscribe((response) => {
        if (response) {
          response.forEach((item) => {
            if (item.preferenceconfig.name === "preferredCurrency") {
              if (
                'preferencesetting' in item &&
                item.preferencesetting.value
              ) {
              typeof item.preferencesetting.value === 'string' ? this.preferedCurr = item.preferencesetting.value : null;
              }
            }

          });
        }
      });
      this.userPreferenceService.getPreference('favorites_list', false)
        .subscribe (
          (response) => {
            if (response) {
              if ( response.updateList.value ){
                response.updateList.value.forEach(element => {
                  if( element.fileNumber == this.fileNumber) {
                    this.favUser = 1;
                  }
                });
              } 
              this.employee$ = this.empDetailSvc.getEmployeeInfo(this.fileNumber, this.favUser);
              this.employee$.subscribe((employee) => {
                empMovePhase = Number(employee.movePhase.movePhaseId);
                this.movePhaseSub = this.movePhaseService.getMovePhases().subscribe(movePhases => {
                  const index: number = movePhases.findIndex(it => it.key === '0');
                  if (index >= 0) {
                    movePhases.splice(index, 1);
                  }
                  Object.assign(this.empMovePhases, movePhases.map(movePhase => {
                    const valueClass: string = this.getValueClass(Number(movePhase.key), employee);
                    return {
                      value: movePhase.key,
                      valueClass: valueClass,
                      columns: [
                        {
                          label: movePhase.value
                        }
                      ]
                    } as LinkedListData;
                  }));
                });
              });
            }
          }
        );
      
      this.milestoneSub = this.milestoneSvc.getMilestonesByFileNum(this.fileNumber).subscribe((res: Milestones) => {
        this.createMilestonesData(res);
      });
      this.sentimentSub = this.sentimentSvc.getSentimentsByFileNum(this.fileNumber).subscribe((res: Sentiments) => {
        this.createSentimentData(res.timeLine);
      });

      this.providerSub = this.providerSvc.getProvidersByFileNum(this.fileNumber, this.preferedCurr)
      .subscribe((res) => {
        this.providerCount = res.suppliers.length;
      });
    });

    this.route.queryParams.subscribe( qparams => {
      if (qparams && qparams.milestoneView === 'open') {
        this.milestonePanelState = true;
        this.marker = interval(100).pipe(startWith(0)).subscribe(() => {
          const element = document.querySelector('#milestone-panel');
          if (element) {
            this.marker.unsubscribe();
            setTimeout(() => {
              element.scrollIntoView({ behavior: 'smooth', block: 'start'});
            }, 500);
          }
        });
      }
    });

    this.route.queryParams.subscribe( qparams => {
      if (qparams && qparams.sentimentView === 'open') {
        this.sentimentPanelState = true;
        this.marker = interval(100).pipe(startWith(0)).subscribe(() => {
          const element = document.querySelector('#sentiment-panel');
          if (element) {
            this.marker.unsubscribe();
            setTimeout(() => {
              element.scrollIntoView({ behavior: 'smooth', block: 'start'});
            }, 500);
          }
        });
      }
    });
  }

  /** getValueClass method to assign value to valueClass variable in LinkedListData */
  getValueClass(movePhaseNumber: number, employee: EmployeeInfo): string {
    if (employee && employee.movePhase) {
      const employeePhaseId = Number(employee.movePhase.movePhaseId);
      return movePhaseNumber === employeePhaseId ? 'active'
        : movePhaseNumber > employeePhaseId ? 'notDone' : 'done';
    }
  }

  /** setup the key dates data for the linked list component */
  createMilestonesData(milestones: Milestones): void {
  if (milestones.data) {
    milestones.data.forEach(element => {
      let ownerName = '';

        if (element.milestoneDesc === 'Authorized') {
          ownerName = '(by ' + milestones.initiatorFirstName + ' ' + milestones.initiatorLastName + ' )';
        } else {
          ownerName = '';
        }
        this.milestonesData.push({
          value: this.dateSvc.convertToDisplayDate(element.milestoneDate),
          valueClass: 'active',
          columns: [{
            label: element.milestoneDesc,
            subLabel: ownerName
          }]
        } as LinkedListData);
      });
    }
  }

  /** setup the sentiment data for the linked list component */
  createSentimentData(data: Sentiment[]): void {
    if (data) {
      data.sort((a, b) =>
        this.dateSvc.convertToEpoch(a.sentimentCompletedDate) -
        this.dateSvc.convertToEpoch(b.sentimentCompletedDate)
      );
      data.reverse().forEach(element => {
        this.sentimentData.push({
          value: this.dateSvc.convertToDisplayDate(element.sentimentCompletedDate),
          valueClass: 'active',
          columns: [
          {
            label: element.sentimentName
          },
          {
            label: element.sentimentLevel,
            type: 'star'
          }
          ]
        } as LinkedListData);
      });
    }
  }

  /** go back to where you came from */
  back() {
    this.loc.back();
  }

  /** viewProviders function to view providers associated to the employee */
  viewProviders(): void {
    this.router.navigate(['/provider/' + this.fileNumber]);
  }

  /** cleanup the subscription and anything else necessary */
  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.milestoneSub) {
      this.milestoneSub.unsubscribe();
    }
    if (this.movePhaseSub) {
      this.movePhaseSub.unsubscribe();
    }
    if (this.sentimentSub) {
      this.milestoneSub.unsubscribe();
    }
  }
}

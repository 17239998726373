<form [formGroup]="upsertDynamicForm"> 
      <div formArrayName="primaryForm">
            <ng-container *ngFor="
                    let formGroupValue of getFormArray(upsertDynamicForm);let formindex = index">
                  <div *ngIf="formGroupValue">
                        <ng-container [formGroupName]="formindex">
                              <ng-container *ngFor="let component of componentList; let subFormIndex = index">
                                    <div *ngIf="component">
                                          <app-dynamic-attribute (formEvent)="generateReqPayloadAttributes($event)" [dynamicDataModel]="dynamicDataModel" [formDetails]="upsertDynamicForm?.controls?.primaryForm?.controls[formindex][component]"
                                           [formGroupType]="component" [componentDescription]="componentDescription"
                                           [originalDynamicModel]="originalDynamicModel" [disableMuliplicityForm]="diasbleDynamicForm"></app-dynamic-attribute>
                                    </div>
                              </ng-container>
                        </ng-container>
                  </div>
            </ng-container>
      </div>
</form> 
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { EmployeeInfoComponent } from './components/employee-info/employee-info.component';
import { CostTransactionsComponent } from './components/cost-transactions/cost-transactions.component';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path:'settings/:id',
    component:AccountSettingsComponent,
    data: { navId: 1, title: 'Settings', showGlobalTemplate: true }
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { navId: 1, title: 'Home', showGlobalTemplate: true }
  },
  {
    path: 'empInfo/:id',
    component: EmployeeInfoComponent,
    data: { navId: 1, title: 'Employee Information', showGlobalTemplate: true }
  },
  {
    path: 'emp-cost-transactions/:id',
    component: CostTransactionsComponent,
    data: { navId: 1, title: 'All Cost Transactions of Employee', showGlobalTemplate: true }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from './base-service';
import { ApiConfigService } from './api-config.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandlerService } from './http-error-handler.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { AppConfigService } from './app-config.service';
/**
 * Used to make generic standard API calls.  The base URL for the service calls is based on the configuration.
 */
@Injectable({
  providedIn: 'root'
})
export class BaseClientService extends BaseService  {

  /**
   * base constructor
   * @param config API Config service injector
   * @param http HTTP Client injector
   * @param errorHandler HTTP error handler injector
   */

  constructor(
    config: ApiConfigService,
    http: HttpClient,
    private readonly errorHandler: HttpErrorHandlerService,
    private cookieService: CookieService,
    private appConfigSer:AppConfigService
    ) {
    super(config, http);
  }

  /** Run a GET API call, expecting a response with a single model
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param action The action that is performing the request
   * @return A response containing the expected model (single)
   */
  getOne<TResponseModel>(route: string, action: string = 'error executing requests',
  params: HttpParams = new HttpParams(), withCredentials: boolean = false): Observable<HttpResponse<TResponseModel>> {
    let options;
    if (withCredentials) {
      options = {
        headers: this.getRequestHeaders(),
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true
      };
    } else {
      options = {
        headers: this.getRequestHeaders(),
        params: params,
        observe: 'response',
        responseType: 'json'
      };
    }
    return this.http.get<TResponseModel>(
      this.rootUrl + route, options)
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run an external GET API call, expecting a response with a single model
   * @param url The url for the request
   * @param action The action that is performing the request
   * @return A response containing the expected model (single)
   */
  getExt<TResponseModel>(url: string, action: string = 'error executing requests',
  params: HttpParams = new HttpParams()): Observable<HttpResponse<TResponseModel>> {
    return this.http.get<TResponseModel>(
      url,
      {
        headers: this.getRequestHeaders(),
        params: params,
        observe: 'response',
        responseType: 'json'
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

    /** Run an external GET API callvfor DMS, expecting a response with a single model
   * @param url The url for the request
   * @param action The action that is performing the request
   * @return A response containing the expected model (single)
   */
    getDMS(url: string): Observable<any> {
      return this.http.get<any>(this.getDmsSystemURL + url, {
          headers: this.getHeadersForDsm(),
          observe: 'response',
          responseType:'blob' as 'json'
      })
    }

    dmsPost<TResponseModel>(route: string, body: any, action: string = 'error posting request',
    params: HttpParams = new HttpParams(), isBenefitBuilder: boolean = false): Observable<HttpResponse<TResponseModel>> {  
      const url = this.getDmsSystemURL + route;
      return this.http.post<TResponseModel>(url,
        body,
        {
          headers: this.getHeadersForDsm(),
          withCredentials: false,
          params: params,
          observe: 'response',
          responseType: 'json'
        })
        .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
    }

  /** Run a GET API call, expectiing a response with an array of the expected model
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param action The action that is performing the request
   * @param isBenefitBuilder The root api url or benefits 
   * @return A response containing the expected models (array)
   */
  getArr<TResponseModel>(route: string, action: string = 'error executing requests',
  params: HttpParams = new HttpParams(), isBenefitBuilder: boolean = false): Observable<HttpResponse<Array<TResponseModel>>> {
    return this.http.get<TResponseModel>(
      !isBenefitBuilder ? this.rootUrl + route : this.getBenefitBuilderURL + route,
      {
        headers: this.getRequestHeaders(isBenefitBuilder),
        withCredentials: true,
        params: params,
        observe: 'response',
        responseType: 'json'
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a PUT API call
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param body The object that is being updated
   * @param action The action that is performing the request
   * @param isBenefitBuilder The root api url or benefits
   * @return A response containing the expected result (single)
   */
  put<TResponseModel>(route: string, body: any, action: string = 'error putting request',
  params: HttpParams = new HttpParams(), isBenefitBuilder: boolean = false): Observable<HttpResponse<TResponseModel>> {
    const url = !isBenefitBuilder ? this.rootUrl + route : this.getBenefitBuilderURL + route;
    return this.http.put<TResponseModel>(url,
      body,
      {
        headers: this.getRequestHeaders(isBenefitBuilder),
        withCredentials: true,
        params: params,
        observe: 'response',
        responseType: 'json'
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run an external POST API call
   * @param url The url for the request
   * @param body The object that is being posted
   * @param action The action that is performing the request
   * @return A response containing the expected result (single)
   */
  postExt<TResponseModel>(url: string, body: any, action: string = 'error posting request',
  params: HttpParams = new HttpParams()): Observable<HttpResponse<TResponseModel>> {
    return this.http.post<TResponseModel>(url,
      body,
      {
        headers: this.getRequestHeaders(),
        params: params,
        observe: 'response',
        responseType: 'json'
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a POST API call
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param body The object that is being posted
   * @param action The action that is performing the request
   * @param isBenefitBuilder The root api url or benefits 
   * @return A response containing the expected result (single)
   */
  post<TResponseModel>(route: string, body: any, action: string = 'error posting request',
  params: HttpParams = new HttpParams(), isBenefitBuilder: boolean = false): Observable<HttpResponse<TResponseModel>> {  
    const url = !isBenefitBuilder ? this.rootUrl + route : this.getBenefitBuilderURL + route;
    return this.http.post<TResponseModel>(url,
      body,
      {
        headers: this.getRequestHeaders(isBenefitBuilder),
        withCredentials: true,
        params: params,
        observe: 'response',
        responseType: 'json'
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a DELETE API call
   * @param route The endpoint for the delete request
   * @param action The action that is performing the request
   * @return A response containing the expected result
   */
  delete<TResponseModel>(route: string, action: string = 'error delete request',
  params: HttpParams = new HttpParams(), isBenefitBuilder: boolean = false): Observable<HttpResponse<TResponseModel>> {
    const url = !isBenefitBuilder ? this.rootUrl + route : this.getBenefitBuilderURL + route;
    return this.http.delete<TResponseModel>(
      url,
      {
        headers: this.getRequestHeaders(isBenefitBuilder),
        withCredentials: true,
        params: params,
        observe: 'response',
        responseType: 'json'
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a DELETE API call with payload in body
   * @param route The endpoint for the delete request
   * @param action The action that is performing the request
   * @return A response containing the expected result
   */
  bulkDelete < TResponseModel > (route,
    body: Object,
    action = 'error delete request'): Observable < HttpResponse < TResponseModel >> {
    const url = this.rootUrl + route;
    const options = {
        headers: this.getRequestHeaders(),
        withCredentials: true,
        params: this.newParams(),
        body: body
    };
    return this.http.delete < TResponseModel > (url, options)
        .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
}


   /** Run a GET API call, expecting a response with a single model
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param action The action that is performing the request
   * @return A response containing the expected model (single)
   */
  getById<TResponseModel>(
    route: string,
    action: string = 'error executing requests',
  ): Observable<HttpResponse<TResponseModel>> {
    const url = this.getBenefitBuilderURL;
    return this.http
      .get<TResponseModel>(url + route, {
        params: this.newParams(),
        observe: 'response',
        responseType: 'json',
        headers: this.getRequestHeaders()
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }
  getRequestHeaders (isBenefitBuilder: boolean= false): HttpHeaders {
    // Only send headers which have values to send
    let headers = new HttpHeaders();
    const token = this.cookieService.get('car-ses-tok');
    if (token) {
      const context = sessionStorage.getItem('car-ses-con');
      if (context) {
        headers = new HttpHeaders({
          'Authorization' : token,
          'app-context' : context
        });
        const subContext = sessionStorage.getItem('car-ses-subcon');
        if (subContext) {
          headers = new HttpHeaders({
            'Authorization' : token,
            'app-context' : context,
            'app-subcontext': subContext
          });
        }
        const orderId = sessionStorage.getItem('car-ses-oid');
        if (orderId && isBenefitBuilder) {
          headers = new HttpHeaders({
            'Authorization' : token,
            'app-context' : context,
            'car-ses-oid': orderId
          });
        }
      } else {
        headers = new HttpHeaders({
          'Authorization' : token,
        });
      }
    }
    return headers;
  }
/**
 * headers for DSM
 * @returns 
 */
  getHeadersForDsm(): HttpHeaders {
    let headers = new HttpHeaders();
    const dmsToken = sessionStorage.getItem('car-ses-dms');
    headers = new HttpHeaders({
      'Authorization' : dmsToken,
      'apiKey': String(this.appConfigSer.getConfig('apiKey'))
    });
    return headers;
  }
}

<div>
  <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px"
  fxLayoutGap.xs="0em" role="heading">
  <h3 style="text-align: center;">Please be aware:</h3>
  <a class="close-icon" (click)="close()" title="close" role="button" aria-label="Close Program Modal"
    title="Close Program Modal">
    <mat-icon>close</mat-icon>
  </a>
</div>
  <mat-dialog-content style="text-align: center;">
    <!-- <p style="margin-bottom: 0;"> <b> Please be aware:</b></p> -->
    <ul style="text-align: left;">
        <li>Updates made in this area may enable or disable critical functionality for the client.</li>
        <li>The selected changes will take effect immediately upon clicking Save.</li>
        <li>If you wish to revert the client configuration to its previous state, please select Cancel.</li>
    </ul>
    <div id="container-confirm" style="display: flex;padding: 1em;justify-content: space-around;">
      <button mat-raised-button style="padding: 0px;width: 30%;" color="primary" mat-dialog-close mat-dialog-close="true">Confirm</button>
       <button mat-button style="cursor: pointer;width: 30%;" disableRipple color="primary" mat-dialog-close mat-dialog-close="false" cdkFocusInitial>Cancel</button>&nbsp;
    </div>
  </mat-dialog-content>
</div>
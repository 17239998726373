/* tslint:disable */
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';

/**
 * Gets the global API services configuration
 */
@Injectable({
  providedIn: 'root'
})
export class ApiConfigService {

  /** Root URL for API operations */
  rootUrl: string;
  benefitsBuilderURL: string;
  documentManagementSystemURL: string;

  /**
   * Initialize the rootURL
   */
  constructor(private readonly config: AppConfigService) {
    const apiConfig: {[key: string]: string | number }  = this.config.getConfig('api');
    this.rootUrl = apiConfig.protocol + '://' + apiConfig.host + ':' + apiConfig.port + apiConfig.base_url;

    const bburl: {[key: string]: string | number }  = this.config.getConfig('benefitsBuilderapi');
    this.benefitsBuilderURL = bburl.protocol + '://' + bburl.host + ':' + bburl.port + bburl.base_url;

    const dmsurl: {[key: string]: string | number }  = this.config.getConfig('documentManagementSystem');
    this.documentManagementSystemURL = dmsurl.protocol + '://' + dmsurl.host + ':' + dmsurl.port + dmsurl.base_url;
    
  }
}

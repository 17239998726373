<div cdkTrapFocus cdkTrapFocusAutoCapture>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mat-dialog-title">
        <div class="dialogtitle"> Transferee ID Information</div>
        <a href="javascript:void(0)" tabindex="0" class="close-icon nofocus" (click)="onDismiss()" matTooltip="Close"
            style="color: grey; cursor: pointer;">
            <mat-icon class="header-icon-style">close</mat-icon>
        </a>
    </div>

    <mat-dialog-content>
        <div>
            <!-- Transferee details-->
            <div>
                <mat-label class="table-title">Transferee Details</mat-label>
                <div class="table-scroll mat-elevation-z8">
                    <table mat-table [dataSource]="transfereeDataSource" matSort>
                      <ng-container matColumnDef="partyId">
                        <th width="20%" mat-sort-header [disabled]="true" *matHeaderCellDef style="padding-left: 24px;"> Party Id </th>
                        <td mat-cell *matCellDef="let element">
                          <div *ngIf="element.partyId">
                            <span [innerHTML]="element.partyId"></span>
                          </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="moveOrderId">
                        <th width="20%" mat-sort-header [disabled]="true" *matHeaderCellDef style="padding-left: 24px;"> MoveOrder Id</th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="element.moveOrderId">
                                <span [innerHTML]="element.moveOrderId"></span>
                            </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="referenceNumber">
                        <th width="20%" mat-sort-header [disabled]="true"  *matHeaderCellDef style="padding-left: 10px;">  Reference Number </th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="element.referenceNumber">
                                <span [innerHTML]="element.referenceNumber"></span>
                            </div>
                        </td>
                      </ng-container>
                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="3" style="text-align: center;">No records found</td>
                      </tr>
                      <tr mat-header-row *matHeaderRowDef="transfereeInfoDisplayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: transfereeInfoDisplayedColumns;"></tr>
                    </table>
                </div>
            </div>
            <!-- Family Memeber details-->
            <div>
                <mat-label class="table-title">Accompanying Member Details</mat-label>
                <div class="table-scroll mat-elevation-z8">
                    <table #familyInfoTable mat-table [dataSource]="familyDataSource" matSort>
                      <ng-container matColumnDef="name">
                        <th width="20%" mat-sort-header *matHeaderCellDef style="padding-left: 24px;">Name</th>
                        <td mat-cell *matCellDef="let element">
                          <div *ngIf="element.name">
                            <span [innerHTML]="element.name"></span>
                          </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="relationship">
                        <th width="20%" mat-sort-header *matHeaderCellDef style="padding-left: 24px;">Relationship</th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="element.relationship">
                                <span [innerHTML]="element.relationship"></span>
                            </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="partyId">
                        <th width="20%" mat-sort-header [disabled]="true" *matHeaderCellDef style="padding-left: 10px;">Party Id</th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="element.partyId">
                                <span [innerHTML]="element.partyId"></span>
                            </div>
                        </td>
                      </ng-container>
                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="3" style="text-align: center;">No records found</td>
                      </tr>
                      <tr mat-header-row *matHeaderRowDef="familyInfoDisplayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: familyInfoDisplayedColumns;"></tr>
                    </table>
                </div>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="mat-dialog-actions" fxLayout="row" fxLayoutAlign="end end">
        <button class="cancelBtn-Style active-card nofocus" (click)="onDismiss()" >CLOSE</button>
    </mat-dialog-actions>
</div>
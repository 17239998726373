import {
  Component, OnInit, ViewChild, ViewEncapsulation, OnDestroy, ElementRef, Input, Output, EventEmitter, HostListener, Inject
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortHeaderIntl, SortDirection } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DOCUMENT } from '@angular/common';
import { Subscription, of, Observable, forkJoin } from 'rxjs';
import { EmployeeDetailsService } from '../../../../core/services/employee-details.service';
import { EmployeeDetailsView } from '../../../../core/models/employee-details-view.model';
import { EmployeeDetails } from '../../../../core/models/employee-details.model';
import { AggregationFetchparameter } from '../../../../core/models/aggregation-fetch-parameter.model';
import { EmployeeRecentTransactionsComponent } from '../employee-recent-transactions/employee-recent-transactions.component';
import { EmployeeRecentSentimentComponent } from '../employee-recent-sentiment/employee-recent-sentiment.component';
import { ProviderPaymentSummaryComponent } from '../provider-payment-summary/provider-payment-summary.component';
import { EmployeeMovePhaseComponent } from '../employee-move-phase/employee-move-phase.component';
import { EmployeeRecentMilestonesComponent } from '../employee-recent-milestones/employee-recent-milestones.component';
import { Overlay, OverlayConfig, OverlayRef, OverlayContainer } from '@angular/cdk/overlay';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ComponentPortal } from '@angular/cdk/portal';
import { CdkDragStart, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { PopupPositionService } from '../../../../core/services/popup-position.service';
import { AggregationFilter } from '../../../../core/models/aggregation-filter.model';
import { UserPreference } from '../../../../core/models/user-preference.model';
import { UserPreferencesService } from '../../../../core/services/user-preferences.service';
import { UserConfigService } from '../../../../core/services/user-config.service';
import { NumbersService } from '../../../../core/services/numbers.service';
import { DateConversionService } from '../../../../core/services/date-conversion.service';
import { ProviderService } from '../../../../core/services/provider.service';
import { TransactionService } from '../../../../core/services/transaction.service';
import { RecentTransaction } from '../../../../core/models/recent-transaction.model';
import { SentimentService } from '../../../../core/services/sentiment.service';
import { MilestoneService } from '../../../../core/services/milestone.service';
import { RecentProvider } from '../../../../core/models/recent-provider.model';
import { Sentiments } from '../../../../core/models/sentiments.model';
import { Milestones } from '../../../../core/models/milestones.model';
import { Router } from '@angular/router';
import { UserTypeService } from './../../../../public/administration/services/user-type.service';
import { ClientService } from './../../../../public/administration/services/client.service';
import { ClientContactsService } from './../../../../public/administration/services/client-contacts.service';
/**Quick access keyboard code for table next, last and escape*/
export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}

/** EmployeeDetailsComponent to fetch the employee details and display in the table */
@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeeDetailsComponent implements OnInit, OnDestroy {
  /** overlayRef to hold overlay config */
  overlayRef: OverlayRef;

  /** employeeDet variable to store the response data */
  employeeDet: Subscription;

  /** columns variable array to store the column fields, headers, and sortName */
  columns: any[] = [
    { field: 'starred', header: 'Starred', sortName: 'favorites' },
    { field: 'fullName', header: 'Full Name', sortName: 'lastName' },
    { field: 'division', header: 'Division', sortName: 'divisionNumber' },
    { field: 'fileStatusDesc', header: 'File Status', sortName: 'fileStatusDesc' },
    { field: 'movePhase', header: 'Move Phase', sortName: 'movePhaseId' },
    { field: 'policy', header: 'Policy Name', sortName: 'policyName' },
    { field: 'departure', header: 'Departure', sortName: 'departureCountry' },
    { field: 'destination', header: 'Destination', sortName: 'destinationCountry' },
    { field: 'providers', header: 'Providers', sortName: 'providerCount' },
    { field: 'sentiment', header: 'Recent Sentiment', sortName: 'sentimentLevel' },
    { field: 'effectiveTransferDate', header: 'Effective Transfer Date', sortName: 'effectiveTransferDate' },
    { field: 'milestone', header: 'Recent Milestone', sortName: 'milestones' },
    { field: 'totalCost', header: 'Total Cost', sortName: 'totalCost' },
    { field: 'moveAtRisk', header: 'Move Risk Status', sortName: 'moveRisk' }
  ];

  /** displayedColumns variable array to store the displayed columns */
  displayedColumns: string[] = [];

  /** defaultColumn to store default columns */
  defaultColumn: string[] = [];

  /** previousDragIndex to grab index of table column dragged */
  previousDragIndex: number;

  /** dataSource variable to store the response converted to mat table datasource */
  dataSource: MatTableDataSource<EmployeeDetails>;

  /** dialogRef to access the Dialog */
  dialogRef: MatDialogRef<EmployeeRecentTransactionsComponent>;

  /** page event variable to handle the page events */
  pageEvent: PageEvent;

  /**Direction varialbe to get sort direction */
  direction: SortDirection;

  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /**store the filter value */
  filterText = '';

  dragColumnName: string;

  /** To get the search text entered in table search */
  @Input() searchKeyword;

  /** To get the boolean value to display no results message */
  @Input() clearResults;

  /** Output the getClearCriteriaChange boolean value */
  @Output() getClearCriteriaChange = new EventEmitter<boolean>();

  /** To get the boolean value on whether the search box has been triggered for results.
   * This determines whether to display the highlighted element in the Milestone column and hide
   * the non-highlighted element */
  @Input() searchTriggered;

  /** sortDirection to hold the asc or desc direction value */
  sortDirection: any;

  /** Decides whether to show admin link */
  isAdminVisible: boolean;
  
  /** sortColumnName to hold the clicked column name for sorting */
  sortColumnName: any;

  /** sortOptions to hold the sort column name and sort direction */
  sortOptions = {};

  /** Output the sortOptions */
  @Output() getSortOptions = new EventEmitter<any>();

  /** Input the filterDataSource from dashboard component (parent component) */
  @Input() aggregationFetchParameter: AggregationFetchparameter;

  /** employeeReport will hold the 'Employee Details' for report generation */
  employeeReport = 'Employee Details';

  private _filters: AggregationFilter;

  /** to store prefered currency */
  preferedCurr : string = "";

  /** initialSortColumn to hold the column's sortName property for inital sorting. */
  initialSortColumn: Object = 'lastName';
  /** InitialSortDirection to hold the direction of inital sorting: 'asc' | 'desc' */
  initialSortDirection: Object = 'asc';
  /** flaf for scroll top */
  public fixed = false;
  /**for Administration capability */
  userAdminCapabilities: any;
  /** AggregationFetchparameter of type AggregationFetchparameter to initialize object */
  @Input()
  set filters(value: AggregationFilter) {
    if (value) {
      this.pageEvent.pageIndex = 0;
      this._filters = value;
      const aggregationFetchParameter: AggregationFetchparameter = {
        filters: value,
        itemsPerPage: this.paginator.pageSize,
        pageNumber: this.pageEvent.pageIndex,
        searchText: value['searchText']
      };

      this.userPreferenceService.getPreference('sort_options', false).subscribe((response) => {
        if (response) {
          aggregationFetchParameter.sortObject = response;
        }
      });
      this.filterEmployeeDetails(aggregationFetchParameter);
    }
  }

  get filters() {
    return this._filters;
  }

  /** Base constructor method
   * @param providerService ProviderService injection
   * @param sentimentService SentimentService injection
   * @param milestoneService MilestoneService injection
   * @param transactionService TransactionService injection
   * @param dialog MatDialog injection
   * @param employeeService EmployeeDetailsService injection
   * @param overlay Overlay injection
   * @param positionService PopupPositionService injection
   */
  constructor(
    public dialog: MatDialog,
    private readonly employeeService: EmployeeDetailsService,
    private overlay: Overlay,
    private readonly positionService: PopupPositionService,
    private readonly providerService: ProviderService,
    private readonly milestoneService: MilestoneService,
    private readonly transactionService: TransactionService,
    private readonly sentimentService: SentimentService,
    private readonly userPreferenceService: UserPreferencesService,
    private readonly userConfigService: UserConfigService,
    private clientService: ClientService,
    private readonly clientContactsService: ClientContactsService,
    private readonly numberService: NumbersService,
    public readonly dateConversionService: DateConversionService,
    private readonly matSortService: MatSortHeaderIntl,
    private userTypeService: UserTypeService,
    public readonly router: Router,
    private announcer: LiveAnnouncer
  ) {
    this.dataSource = new MatTableDataSource();
    this.pageEvent = new PageEvent();
    this.pageEvent.pageIndex = 0;
    this.pageEvent.pageSize = 5;
    // this.matSortService.sortButtonLabel = (id: string) => {
    //   return `"Change sorting for " ${id}`;
    // };
  }

  /** applyFilter to filter data within the employee details table */
  applyFilter(filterValue: string) {
    if (filterValue !== undefined) {
      this.filterText = filterValue.trim();
      filterValue = filterValue.toLowerCase();
      this.dataSource.filter = filterValue;
    }
  }


  /** getSortingColumnms to store the default sort options depeneding on the sorted Column name */
  getSortingColumns(columnName) {
    let sortColumnsList = [];
    switch (columnName) {
      case 'favorites':
        sortColumnsList = [
          { sortColumnName: columnName, sortOrder: this.sortDirection },
          { sortColumnName: 'lastName', sortOrder: 'asc' },
          { sortColumnName: 'firstName', sortOrder: 'asc' },
          { sortColumnName: 'fileNumber', sortOrder: 'asc' }
        ];
        break;
      case 'lastName':
        sortColumnsList = [
          { sortColumnName: columnName, sortOrder: this.sortDirection },
          { sortColumnName: 'firstName', sortOrder: 'asc' },
          { sortColumnName: 'fileNumber', sortOrder: 'asc' }
        ];
        break;
      case 'departureCountry':
        sortColumnsList = [
          { sortColumnName: columnName, sortOrder: this.sortDirection },
          { sortColumnName: 'departureState', sortOrder: 'asc' },
          { sortColumnName: 'departureCity', sortOrder: 'asc' },
          { sortColumnName: 'fileNumber', sortOrder: 'asc' }
        ];
        break;
      case 'destinationCountry':
        sortColumnsList = [
          { sortColumnName: columnName, sortOrder: this.sortDirection },
          { sortColumnName: 'destinationState', sortOrder: 'asc' },
          { sortColumnName: 'destinationCity', sortOrder: 'asc' },
          { sortColumnName: 'fileNumber', sortOrder: 'asc' }
        ];
        break;
      default:
        sortColumnsList = [
          { sortColumnName: columnName, sortOrder: this.sortDirection },
          { sortColumnName: 'fileNumber', sortOrder: 'asc' },
        ];
    }
    return sortColumnsList;
  }

  /** applySort to sort the respective column with respective sort direction */
  applySort(columnName: any) {
    this.clearInitialSort();
    if (this.sortColumnName === columnName) {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc';
        this.announcer.announce('Descending ' + columnName, 'assertive');
      } else {
        this.sortDirection = 'asc';
        this.announcer.announce('Ascending ' + columnName, 'assertive');
      }
    } else {
      this.sortColumnName = columnName;
      this.sortDirection = 'asc';
      this.announcer.announce('Ascending ' + columnName, 'assertive');
    }
    this.sortOptions['sortColumnName'] = this.sortColumnName;
    this.sortOptions['sortDirection'] = this.sortDirection;

    const sortPreference = this.getSortingColumns(this.sortColumnName);

    const preference: UserPreference = {
      Key: 'sort_options',
      Value: sortPreference,
      Persist: false
    };
    this.userPreferenceService.setPreference(preference);
    forkJoin([
      this.userPreferenceService.getPreference('aggregation_filter', false),
      this.userPreferenceService.getPreference('filter_text', false),
      this.userPreferenceService.getPreference('favorites_list', false)
    ])
    .subscribe (
      (response) => {
        const aggregationFetchParameter: AggregationFetchparameter = {
          pageNumber: this.pageEvent.pageIndex,
          itemsPerPage: this.pageEvent.pageSize,
          sortColumnName: columnName,
          sortOrder: this.sortDirection
        };

        aggregationFetchParameter.sortObject = this.getSortingColumns(this.sortColumnName);

        if (response[0]) {
          aggregationFetchParameter.filters = response[0];
        }
        if (response[1]) {
          aggregationFetchParameter.searchText = response[1];
        }
        if (response[2]) {
          const favorites = { settingId: response[2].settingId, updateList: response[2].updateList };
          aggregationFetchParameter.favorites = favorites;
        } else {
          aggregationFetchParameter.favorites = {
            settingId: '',
            updateList: {
              appName: 'MovePro360',
              name: 'employeeFavorites',
              value: []
            }
          };
        }

        this.filterEmployeeDetails(aggregationFetchParameter);
      }
    );
  }

  /** updateFavorite to flag or unflag favorite */
  updateFavorite(fileNumber: any, favUser: any, index: number) {
    this.clearInitialSort();
    let favoritesList: {
      settingId: '',
      updateList: {
        appName: '',
        name: '',
        value: []
      }
    };
    const favoriteParam = {
      favoriteData: [{
        favUser: favUser == 0 ? 1 : 0,
        value: fileNumber
      }],
      type: 'employee'
    };
    this.employeeService.updateFavorite(favoriteParam)
      .subscribe((response) => {
        if (response) {
          if (favUser) {
            this.dataSource.data[index].favUser = 0;
          } else {
            this.dataSource.data[index].favUser = 1;
          }
          favoritesList = {
            settingId: response._id,
            updateList: {
              appName: response.appName,
              name: response.name,
              value: response.value
            }
          };
          const preference: UserPreference = {
            Key: 'favorites_list',
            Value: favoritesList,
            Persist: false
          };
          this.userPreferenceService.setPreference(preference);
        }
      });
  }

  /** Init method to make Service call, to convert response to mat table data and to initialize sort */
  ngOnInit() {
    window.addEventListener('scroll', this.scroll, true);
    this.getUserRoles();
    this.userTypeService.capabilities$.subscribe(ele => {
      ele ? this.checkCapabilities(ele) : null;
    });
    const paginatorIntl = this.paginator._intl;
    paginatorIntl.nextPageLabel = 'Next';
    paginatorIntl.previousPageLabel = 'Previous';
    forkJoin([this.userPreferenceService.getPreference('page_event', false),
    this.userPreferenceService.getPreference('aggregation_filter', false),
    this.userPreferenceService.getPreference('filter_text', false),
    this.userPreferenceService.getPreference('sort_options', false),
    this.userPreferenceService.getPreference('favorites_list', false)
    ])
      .subscribe(
        (response) => {
          let setEmployeeData: AggregationFetchparameter;
          if (response[0]) {
            this.pageEvent = response[0];
            setEmployeeData = {
              itemsPerPage: this.pageEvent.pageSize,
              pageNumber: this.pageEvent.pageIndex
            };
          } else {
            this.pageEvent.pageIndex = 0;
            this.pageEvent.pageSize = 25;
            setEmployeeData = {
              itemsPerPage: 25,
              pageNumber: 0
            };
          }
          if (response[1]) {
            setEmployeeData.filters = response[1];
          } else {
            let startDate = new Date();
            startDate = this.addMonths(startDate, -6);
            const startDateString = this.dateConversionService.convertToYyyyMmDd(startDate);
            const defaultFilter: any = {
              'policy': [],
              'movePhase': [],
              'fileStatusDesc': [],
              'departure': [],
              'destination': [],
              'transferStartDate': startDateString,
              'transferEndDate': null,
              'minTotalCost': null,
              'maxTotalCost': null,
              'division': [],
              'favorites': null,
            };
            setEmployeeData.filters = defaultFilter;
          }

          if (response[2]) {
            setEmployeeData.searchText = response[2];
          }
          if (response[3]) {
            setEmployeeData.sortObject = response[3];
          }
          if (response[4]) {
            setEmployeeData.favorites = response[4];
          } else {
            let favorites: any;
            this.employeeService.getFavoriteList().subscribe((res) => {
              if (res[0].preferencesetting) {
                favorites = {
                  settingId: res[0].preferencesetting._id,
                  updateList: {
                    appName: res[0].preferencesetting.appName,
                    name: res[0].preferencesetting.name,
                    value: res[0].preferencesetting.value

                  }
                };
                const preference: UserPreference = {
                  Key: 'favorites_list',
                  Value: favorites,
                  Persist: false,
                };
                this.userPreferenceService.setPreference(preference);
              } else {
                favorites = {
                  settingsId: res[0].preferenceconfig._id,
                  updateList: {
                    appName: res[0].preferenceconfig.appName,
                    name: res[0].preferenceconfig.name,
                    value: res[0].preferenceconfig.default
                  }
                };
                const preference: UserPreference = {
                  Key: 'favorites_list',
                  Value: favorites,
                  Persist: false,
                };
                this.userPreferenceService.setPreference(preference);
              }
            });
          }

          this.filterEmployeeDetails(setEmployeeData);
          this.applyFilter(setEmployeeData.searchText);
        }
      );
    // Display initial sort arrow for sort column
    this.userPreferenceService.getPreference('sort_options', false).subscribe((response) => {

      if (response && response[0].sortColumnName && response[0].sortOrder) {
        this.initialSortColumn = response[0].sortColumnName;
        this.initialSortDirection = response[0].order;
      }
    });
  }

  /** addMonths method to calculate and get six months before from today */
  addMonths(date: any, months: any) {
    date.setMonth(date.getMonth() + months);
    return date;
  }
   /** opens the Administration URL */
   openAdministation(): void {
    this.router.navigate(['../../../administration']);
  }

  getUserRoles() {
    const bbUserTypes = [ 'client-contact-initiator', 'file-setup-specialist', 'application-owner', 'application-reliability-analyst', 'client-account-manager', 'program-admin', 'product-mgmt-admin', 'consultant', 'relocation-accounting-analyst'];
    const nonBBUserTypes = ['client-contact']; // this role is not for BB.
      // tslint:disable-next-line: no-shadowed-variable
      this.clientService.getBbRole(sessionStorage.getItem('cc_clientPartyId')).subscribe(capabilities => {
        if (capabilities) {
          let role = sessionStorage.getItem('car-user-type');
        if ( role !== 'client-contact') {
          if (role === 'client-contact-initiator' && (capabilities.loginUserRoleCapabilities[0].clientRoles[0].benefitsBuilder || capabilities.loginUserRoleCapabilities[0].clientRoles[0].traditionalAuthorization || capabilities.loginUserRoleCapabilities[0].clientRoles[0].costProjection)) {
            this.isAdminVisible = true;
          } else {
            // User has no access to Admin for now.
            this.isAdminVisible = false;
          }
        } else {
          this.isAdminVisible = false;
        }
        capabilities.loginUserRoleCapabilities && 
        capabilities.loginUserRoleCapabilities.length >0?
        capabilities.loginUserRoleCapabilities.forEach(element => {
          if (element.clientRoles[0].managedMove ) { 
            this.clientContactsService.isMMUser = true;           
            sessionStorage.setItem('car-ses-mmu', JSON.stringify(true));
          }
        }) : null;
        }
    });
  }

  /** to set user preference configuration for employee list */
  setConfiguration() {
    let displayColumn: string[] = [];
    let orderColumn: string[] = [];
    this.displayedColumns = [];
    this.userPreferenceService.getPreference('user_config', false)
      .subscribe((response) => {
        if (response) {
          response.forEach((item) => {
            if (item.preferenceconfig.name === 'EmployeeListViewColumnDisplay') {
              if ('preferencesetting' in item && item.preferencesetting.value.length > 0) {
                displayColumn = item.preferencesetting.value;
              } else {
                displayColumn = item.preferenceconfig.default;
              }
            } else if (item.preferenceconfig.name === 'EmployeeListViewColumnOrder') {
              if ('preferencesetting' in item && item.preferencesetting.value.length > 0) {
                orderColumn = item.preferencesetting.value;
              } else {
                orderColumn = item.preferenceconfig.default;
              }
            }
            if (item.preferenceconfig.name === "preferredCurrency") {
              if (
                'preferencesetting' in item &&
                item.preferencesetting.value
              ) {
              typeof item.preferencesetting.value === 'string' ? this.preferedCurr = item.preferencesetting.value : null;
              }
            }

          });
          orderColumn.forEach((item) => {
            if (displayColumn.includes(item)) {
              this.displayedColumns.push(item);
            }
          });
        }
      });
  }

  scroll = (event: any): void => {
    // Here scroll is a variable holding the anonymous function
    // this allows scroll to be assigned to the event during onInit
    // and removed onDestroy
    // To see what changed:
    const number = event.srcElement.scrollTop;
    if ((event.srcElement.scrollHeight - number) < 600 && event.target.className !== 'table-scroll') {
      this.fixed = true;
    } else if (this.fixed && (event.srcElement.scrollHeight - number) > 600 && event.target.className !== 'table-scroll') {
      this.fixed = false;
    }
  }

  /** filterEmployeeDetails method to get the response and pass it to filter predicate */
  filterEmployeeDetails(aggregationFetchParameter) {
    if (this.displayedColumns.length === 0) {
      this.setConfiguration();
    }
    if (this.aggregationFetchParameter) {
      this.preferedCurr ? this.aggregationFetchParameter.preferredCurr = this.preferedCurr : null;
      if (this.aggregationFetchParameter.pageNumber === 0) {
        this.aggregationFetchParameter.pageNumber = this.aggregationFetchParameter.pageNumber + 1;
        this.employeeDet = this.employeeService.getEmployeeDetails(this.aggregationFetchParameter)
          .subscribe((res: EmployeeDetailsView) => {
            this.aggregationFetchParameter = null;
            this.setData(res);
          });
      }
    } else {
      if (!aggregationFetchParameter.itemsPerPage) {
        aggregationFetchParameter.itemsPerPage = 25;
      }
      this.preferedCurr ? aggregationFetchParameter.preferredCurr = this.preferedCurr : null;
      aggregationFetchParameter.pageNumber = aggregationFetchParameter.pageNumber + 1;
      this.employeeDet = this.employeeService.getEmployeeDetails(aggregationFetchParameter)
        .subscribe((res: EmployeeDetailsView) => {
          this.setData(res);
        });
    }
  }

  /**setData method to get response and set in mat table and in filter predicate */
  setData(res: EmployeeDetailsView) {
    if (res.count === 0) {
      this.getClearCriteriaChange.emit(true);
      return;
    }
    this.getClearCriteriaChange.emit(false);
    if (res.data && res.data.length > 0) {
      res.data.forEach((employee) => {
        if (employee.totalCost && employee.totalCostCurr) {
          employee.totalCost = this.numberService.formatForCurrency(employee.totalCost, employee.totalCostCurr);
        }

        if (employee.moveRisk) {
          const tempMoveRisk = {
            viewType: 'default',
            moveRiskLevel: employee.moveRisk,
            moveRiskName:
              employee.moveRisk === 1
                ? 'Off Track'
                : employee.moveRisk === 3
                  ? 'On Track'
                  : employee.moveRisk === 4
                    ? 'Risk Complete'
                    : 'N/A'
          };
          employee.moveRisk = tempMoveRisk;
        } else {
          const tempMoveRisk = {
            viewType: 'default',
            moveRiskLevel: employee.moveRisk,
            moveRiskName: 'N/A'
          };
          employee.moveRisk = tempMoveRisk;
        }
      });
    }

    this.dataSource = new MatTableDataSource<EmployeeDetails>(res.data);
    this.paginator.pageSize = this.pageEvent.pageSize;
    this.dataSource.paginator = this.paginator;
    setTimeout(() => {
      this.paginator.length = res.count;
      this.paginator.pageIndex = this.pageEvent.pageIndex;
    });
  }

  /** onPageChange method to set parameters on page change event */
  onPageChange(e: PageEvent) {
    if (this.pageEvent && e.pageSize && this.pageEvent.pageSize !== this.pageEvent.pageSize) {
      this.pageEvent.pageIndex = 0;
    } else {
      this.pageEvent.pageIndex = e.pageIndex;
    }
    this.pageEvent.pageSize = e.pageSize;
    const preference: UserPreference = {
      Key: 'page_event',
      Value: this.pageEvent,
      Persist: false,
    };
    this.userPreferenceService.setPreference(preference);
    if (this.pageEvent) {
      forkJoin([
        this.userPreferenceService.getPreference('aggregation_filter', false),
        this.userPreferenceService.getPreference('filter_text', false),
        this.userPreferenceService.getPreference('sort_options', false),
        this.userPreferenceService.getPreference('favorites_list', false)
      ])
        .subscribe(
          (response) => {
            const aggregationFetchParameter: AggregationFetchparameter = {
              pageNumber: this.pageEvent.pageIndex,
              itemsPerPage: this.pageEvent.pageSize
            };
            if (response[0]) {
              aggregationFetchParameter.filters = response[0];
            }
            if (response[1]) {
              aggregationFetchParameter.searchText = response[1];
            }
            if (response[2]) {
              aggregationFetchParameter.sortObject = response[2];
            }
            if (response[3]) {
              const favorites = { settingId: response[3].settingId, updateList: response[3].updateList };
              aggregationFetchParameter.favorites = favorites;
            } else {
              aggregationFetchParameter.favorites = {
                settingId: '', updateList: {
                  appName: 'MovePro360',
                  name: 'employeeFavorites',
                  value: []
                }
              };
            }
            this.filterEmployeeDetails(aggregationFetchParameter);
            this.applyFilter(aggregationFetchParameter.searchText);
          }
        );
    }
  }

  /** recentTransactionModal method to open the recent transaction View modal popup */
  recentTransactionModal(empDet: EmployeeDetails, evt): void {
    if (evt.type === 'click' || evt.keyCode === 13) {
      if (empDet.totalCost !== 0) {
        let aggregationFetchParameter = {preferredCurr : this.preferedCurr};
        this.transactionService.getRecentTransactions(empDet.fileNumber, aggregationFetchParameter).subscribe((res: RecentTransaction) => {
          const target = this.getPositionByEvents(evt);
          const element = new ElementRef(target);
          const positionStrategy = this.placeByPositionStrategy(element);
          const overlayConfig = new OverlayConfig({
            width: '450px',
            panelClass: 'recentTransactionsModal',
            hasBackdrop: true,
            backdropClass: 'mat-backdrop-transparent',
            positionStrategy,
          });
          this.overlayRef = this.overlay.create(overlayConfig);
          const containerPortal = new ComponentPortal(EmployeeRecentTransactionsComponent, null, this.positionService.createInjector({
            transactions: res,
            fileId: empDet.fileNumber,
            totalCost: empDet.totalCost,
            totalCostCurr: empDet.totalCostCurr,
            overlayRef: this.overlayRef
          }));
          this.overlayRef.attach(containerPortal);
          setTimeout(() => {
            this.overlayRef.updatePosition();
            this.overlayRef.addPanelClass('visibility');
          }, 100);
          this.overlayRef.backdropClick().subscribe(() => {
            this.overlayRef.removePanelClass('visibility');
            this.overlayRef.dispose();
          });
        });
      }
    }
  }

  /** recentMilestoneModal method to open the recent milestones View modal popup */
  recentMilestoneModal(fileNumber: number, evt): void {
    if (evt.type === 'click' || evt.keyCode === 13) {
      this.milestoneService.getMilestonesByFileNum(fileNumber, 5).subscribe((res: Milestones) => {
        const target = this.getPositionByEvents(evt);
        const element = new ElementRef(target);
        const positionStrategy = this.placeByPositionStrategy(element);
        const overlayConfig = new OverlayConfig({
          width: '320px',
          panelClass: 'recentMilestonesModal',
          hasBackdrop: true,
          backdropClass: 'mat-backdrop-transparent',
          positionStrategy,
        });
        this.overlayRef = this.overlay.create(overlayConfig);
        const containerPortal = new ComponentPortal(EmployeeRecentMilestonesComponent, null, this.positionService.createInjector({
          milestones: res,
          fileId: fileNumber,
          overlayRef: this.overlayRef
        }));
        this.overlayRef.attach(containerPortal);
        setTimeout(() => {
          this.overlayRef.updatePosition();
          this.overlayRef.addPanelClass('visibility');
        }, 100);
        this.overlayRef.backdropClick().subscribe(() => {
          this.overlayRef.removePanelClass('visibility');
          this.overlayRef.dispose();
        });
      });
    }
  }

  /** recentSentimentModal method to open the recent sentiment View modal popup */
  recentSentimentModal(fileNumber: number, sentimentLevel: number, evt): void {
    if (evt.type === 'click' || evt.keyCode === 13) {
      if (sentimentLevel && sentimentLevel > 0) {
        this.sentimentService.getSentimentsByFileNum(fileNumber, 5).subscribe((res: Sentiments) => {
          const target = this.getPositionByEvents(evt);
          const element = new ElementRef(target);
          const positionStrategy = this.placeByPositionStrategy(element);
          const overlayConfig = new OverlayConfig({
            width: '430px',
            panelClass: 'recentSentimentModal',
            hasBackdrop: true,
            backdropClass: 'mat-backdrop-transparent',
            positionStrategy,
          });
          this.overlayRef = this.overlay.create(overlayConfig);
          const containerPortal = new ComponentPortal(EmployeeRecentSentimentComponent, null, this.positionService.createInjector({
            sentiments: res,
            fileId: fileNumber,
            sentimentLevel: sentimentLevel,
            overlayRef: this.overlayRef
          }));
          this.overlayRef.attach(containerPortal);
          setTimeout(() => {
            this.overlayRef.updatePosition();
            this.overlayRef.addPanelClass('visibility');
          }, 100);
          this.overlayRef.backdropClick().subscribe(() => {
            this.overlayRef.removePanelClass('visibility');
            this.overlayRef.dispose();
          });
        });
      }
    }
  }

  /** Open the provider summary dialog */
  openProviderSummary(empDet: EmployeeDetails, evt) {
    if (evt.type === 'click' || evt.keyCode === 13) {
      if (empDet.providerCount > 0) {
        this.providerService.getRecentProvidersByFileNum(empDet.fileNumber, this.preferedCurr).subscribe((invoices: RecentProvider) => {
          const target = this.getPositionByEvents(evt);
          const element = new ElementRef(target);
          const positionStrategy = this.placeByPositionStrategy(element);
          const overlayConfig = new OverlayConfig({
            width: '500px',
            panelClass: 'providerModal',
            hasBackdrop: true,
            backdropClass: 'mat-backdrop-transparent',
            positionStrategy,
          });
          this.overlayRef = this.overlay.create(overlayConfig);
          const containerPortal = new ComponentPortal(ProviderPaymentSummaryComponent, null, this.positionService.createInjector({
            invoices: invoices,
            fileId: empDet.fileNumber,
            overlayRef: this.overlayRef
          }));
          this.overlayRef.attach(containerPortal);
          setTimeout(() => {
            this.overlayRef.updatePosition();
            this.overlayRef.addPanelClass('visibility');
            this.overlayRef.addPanelClass('cdk-focused');
          }, 100);
          this.overlayRef.backdropClick().subscribe(() => {
            this.overlayRef.removePanelClass('visibility');
            this.overlayRef.dispose();
          });
        });
      }
    }
  }

  /** Open the move phase dialog */
  openMovePhaseModal(empDet: EmployeeDetails, evt) {
    if (evt.type === 'click' || evt.keyCode === 13) {
      if (empDet.movePhase.id) {
        const target = this.getPositionByEvents(evt);
        const element = new ElementRef(target);
        const positionStrategy = this.placeByPositionStrategy(element);
        const overlayConfig = new OverlayConfig({
          width: '400px',
          height: '560px',
          panelClass: 'movePhaseModal',
          hasBackdrop: true,
          backdropClass: 'mat-backdrop-transparent',
          positionStrategy,
        });
        this.overlayRef = this.overlay.create(overlayConfig);
        const containerPortal = new ComponentPortal(EmployeeMovePhaseComponent, null, this.positionService.createInjector({
          fileId: empDet.fileNumber,
          movePhaseNo: empDet.movePhase.id,
          overlayRef: this.overlayRef
        }));
        this.overlayRef.attach(containerPortal);
        this.overlayRef.backdropClick().subscribe(() => {
          this.overlayRef.dispose();
        });
      }
    }
  }


  /** get the position by events of the target */
  getPositionByEvents(evt) {
    if (evt.type === 'click') {
      return {
        getBoundingClientRect: () => ({
          bottom: evt.clientY,
          height: 0,
          left: evt.clientX,
          right: evt.clientX,
          top: evt.clientY,
          width: 0,
        }),
      };
    } else if (evt.keyCode === 13) {
      const rect = evt.target.getBoundingClientRect();
      return {
        getBoundingClientRect: () => ({
          bottom: rect.y,
          height: 0,
          left: rect.x,
          right: rect.x,
          top: rect.y,
          width: 0
        }),
      };
    }
  }

  /** place the pop up by position strategy */
  placeByPositionStrategy(element: ElementRef) {
    return this.overlay
      .position()
      .flexibleConnectedTo(element)
      .withFlexibleDimensions(false)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'bottom',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);
  }

  /** To Destroy the subscribed method */
  ngOnDestroy(): void {
    if (this.employeeDet) {
      this.employeeDet.unsubscribe();
    }
    this.dialog.closeAll();
    window.removeEventListener('scroll', this.scroll, true);
  }

  /** To Clear the initalSort variables */
  clearInitialSort(): void {
    this.initialSortColumn = null;
    this.initialSortDirection = null;
  }

  /** Rearranges columns array and calls the setDisplayedColumnscolumns function */
  dragDrop(event: CdkDropList<string[]>, index: number) {
    moveItemInArray(this.columns, this.previousDragIndex, index);
    this.dragColumnName = '';
    this.setDisplayedColumns();
  }

  /** Captures previous index of dragged item */
  dragStarted(event: CdkDragStart, index: number) {
    const colName = event.source.element.nativeElement.innerText;
    const column = this.columns.find(col => col.header === colName);
    this.dragColumnName = column.sortName;
    this.previousDragIndex = index;
  }

  /** Rebuilds the displayedColumns array using the columns array order */
  setDisplayedColumns() {
    const orderCol: string[] = [];
    this.columns.forEach((column, index) => {
      column.index = index;
      orderCol.push(column.field);
    });
    this.updateOrderedColumn(orderCol);
  }

  updateDisplayedColumn(displayCol) {
    const reqObj = {
      name: 'EmployeeListViewColumnDisplay',
      value: displayCol
    };
    this.userConfigService.updatePreference(reqObj).subscribe((userConfig) => {
      if (userConfig) {
        const preference: UserPreference = {
          Key: 'user_config',
          Value: userConfig,
          Persist: false
        };
        this.userPreferenceService.setPreference(preference);
        this.setConfiguration();
      }
    });
  }

  updateOrderedColumn(displayCol) {
    const reqObj = {
      name: 'EmployeeListViewColumnOrder',
      value: displayCol
    };
    this.userConfigService.updatePreference(reqObj).subscribe((userConfig) => {
      if (userConfig) {
        const preference: UserPreference = {
          Key: 'user_config',
          Value: userConfig,
          Persist: false
        };
        this.userPreferenceService.setPreference(preference);
        this.setConfiguration();
      }
    });
  }

  /** parses and combines the moverisk data into an object to inject to a component */
  combineMoveRiskData(employeeElement: any): any {
    if (employeeElement) {
      return {
        viewType: employeeElement.moveRisk.viewType ? employeeElement.moveRisk.viewType : null,
        moveRiskLevel: employeeElement.moveRisk.moveRiskLevel ? employeeElement.moveRisk.moveRiskLevel : null,
        moveRiskName: employeeElement.moveRisk.moveRiskName ?  employeeElement.moveRisk.moveRiskName : null,
        transferDate: employeeElement.effectiveTransferDate ? employeeElement.effectiveTransferDate : null,
        predictedDateEarly: employeeElement.estimatedMoveDateEarly ? employeeElement.estimatedMoveDateEarly : null,
        predictedDateLate: employeeElement.estimatedMoveDateLate ?  employeeElement.estimatedMoveDateLate : null,
        riskType: employeeElement.moveRisk.moveRiskLevel ? employeeElement.moveRisk.moveRiskLevel : null,
        moveRiskCompletionDt: employeeElement.moveRiskCompletionDt ? employeeElement.moveRiskCompletionDt : null,
        estimatedMoveDate: employeeElement.estimatedMoveDate ? employeeElement.estimatedMoveDate : null,
        effectiveTransferDate: employeeElement.effectiveTransferDate ? employeeElement.effectiveTransferDate : null
      };
    } else return null;

  }
  /*function to check capability*/
  checkCapabilities (capabilitiesList: any []) {
    capabilitiesList.forEach(capability => {
      if(capability && capability.permission === 'allow') {
        if(capability.name == "MP - Administration" && capability.operation == "read" ) {
          this.userAdminCapabilities = true;
        }
      }
    });
  }
}

<div class="container" appDragAndDrop (fileDropped)="onFileDropped($event)">
    <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
    <div class="drag-label">Drag your document(s) here to upload</div>
    <div class="font-18-bold">or</div>
    <button  class="mat-button outlined-button" type="button">Select Files</button>
    <div class="m-t-30">Supported file types: CSV, DOCX, PDF, XLS, XLSX</div>
    <div>Maximum file size: 25MB</div>
</div>
<div class="files-list" fxLayout="column" [formGroup]="documentFormGroup">
    <ng-container formGroupName="uploadDocuments">
        <ng-container formArrayName="docList">
            <ng-container *ngFor="let docForm of docList.controls; let i = index;">
                <div fxLayout="row" fxFlex="100%" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                    fxLayoutGap.xs="0px" [formGroup]="docForm">
                    <div fxFlex="60%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                        <mat-form-field fxFlex="100%">
                            <mat-label>Document Name</mat-label>
                            <input type="text" number aria-label="Document Display Name" matInput
                                formControlName="documentDisplayName">
                        </mat-form-field>
                    </div>
                    <div fxFlex="37%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                        <mat-form-field fxFlex="100%">
                            <mat-label>Document Type</mat-label>
                            <mat-select formControlName="docType">
                                <mat-option *ngFor="let doc of docTypeValue" [value]="doc.name">{{doc.displayName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="docList.controls[i].get('docType').errors?.required">
                                Document Type is a required field.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="3%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                        <button mat-icon-button role="button" (click)="deleteFile(i)">
                            <mat-icon class="delete-icomn-style" style="margin-top: -1px; cursor: pointer;">
                                delete</mat-icon>
                        </button>
                    </div>
                </div>
                
            </ng-container>
        </ng-container>
    </ng-container>
    <div fxLayout="row" fxLayoutAlign="end end" *ngIf="docList.length > 0 && displayButton">
        <button class="confirm-btn" mat-button [disabled]="!documentFormGroup.valid" (click)="openSaveWarningDialog()">Upload</button>
    </div>
</div>

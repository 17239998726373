import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {

  constructor(
    public dialog: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
    ) { }

  ngOnInit(): void {
  }

  onDismissDialog(eve) {
    if (eve) {
      this.dialog.close();
    }
  }
}

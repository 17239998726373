<div class="dashboard-view" aria-label="select dashboard view">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h2>Dashboard View</h2>
    <button mat-icon-button mat-dialog-close aria-label="Close Button"><mat-icon>close</mat-icon></button>
  </div>
  <form [formGroup]="dashboardViewForm">
    <div mat-dialog-content>
      <mat-form-field>
        <mat-select formControlName="aggregationSelect" (openedChange)="onExpand($event)"
        [attr.aria-expanded]="isExpanded" (selectionChange)="onSelect($event)" [aria-label]="selectedItem + ' selected' " role="listbox">
          <mat-option *ngFor="let aggregation of aggregationList" [value]="aggregation.value" role="option">
              {{aggregation.viewValue}}
        </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center">
        <button mat-button class="text-button" mat-dialog-close>Cancel</button>
        <button mat-button class="contained-button" cdkFocusInitial (click)="onSubmit()">OK</button>
    </div>
  </form>
</div>

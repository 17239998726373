<div class="filter-modal-wrap" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mat-dialog-title">
        <h2>Filter Table</h2><button tabindex="0" mat-icon-button mat-dialog-close aria-label="Close" matTooltip="Close"><span
                class="material-icons close-icon">close</span></button>
    </div>
	<div>
		<h6 class="filter-modal">Clients List</h6>
		<div fxLayout="row" fxLayoutAlign="space-evenly" fxLayoutGap="1em" class="addcandidate-toppadding"
				fxLayout.xs="column" fxLayoutGap="1em" fxLayoutGap.xs="0px">
			<mat-form-field fxFlex>
				<mat-label>Clients</mat-label>
				<mat-select [formControl]="clients" [compareWith]="compareByClientId" (selectionChange)="onClientSelection()" multiple>
					<mat-option *ngFor="let client of clientList$ | async" [value]="client" >{{client.clientEntityName}} (Client #: {{client.clientNumber}} )</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="clear-all-pos">
			<span *ngIf="clients && clients.value && clients.value.length > 0; else noClientSelected">{{clients?.value.length || 0}}</span>
			<ng-template #noClientSelected>0</ng-template>&nbsp;selected
			<a *ngIf="clients && clients.value && clients.value.length > 0" tabindex="0" (keyup.enter)="clearAllSelectedClients()" (click)="clearAllSelectedClients()">Clear All</a>
		</div>
	</div>
	<div fxLayout="column" fxFlex="100%" fxLayoutAlign="space-between" fxLayout.xs="column" fxLayoutGap="0.5em">
        <h6 class="filter-modal">Date Range</h6>
		<div class="relative-block" fxLayout="row" id="statusTransferDate">
            <div class="inline-blk startDate date">
                <mat-form-field>
                    <input matInput [matDatepicker]="startDate" placeholder="Start Date"
						ngDefaultControl [(ngModel)]="statusStartDate" (dateChange)="selectedDataSetChange('startDate', $event.value)">
                    <mat-datepicker-toggle matSuffix [for]="startDate" matTooltip="Calendar">
                    </mat-datepicker-toggle>
                    <mat-datepicker #startDate></mat-datepicker>
                </mat-form-field> &nbsp;- &nbsp;
            </div>
            <div class="inline-blk date">
                <mat-form-field>
                    <input matInput [matDatepicker]="endDate" placeholder="End Date"
						ngDefaultControl [(ngModel)]="statusEndDate" (dateChange)="selectedDataSetChange('endDate', $event.value)" [min] = 'startDate'>
                    <mat-datepicker-toggle matSuffix [for]="endDate" matTooltip="Calendar">
                    </mat-datepicker-toggle>
                    <mat-datepicker #endDate></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class = "errorMessageSize">
            <mat-error>
                {{ getErrorMessage() }}
            </mat-error>
        </div>
	</div>
	<div fxLayout="row" fxFlex="100%" fxLayoutAlign="space-between" fxLayout.xs="column" fxLayoutGap="0.5em">
		<div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
			<div class="movePhase-filter">
				<h6 class="filter-modal" id="authorizationTypeLbl">Authorization Type:</h6>
				<app-admin-filter-selection 
					labelId="authorizationTypeLbl"
					(selectedDataSetChange)="selectedDataSetChange('authorizationType', $event)"
					[dataSet]="authorizationType" [selectedDataSet]="selectedAuthorizationType" [PlaceHolder]="'Authorization Type'"></app-admin-filter-selection>
			</div>
		</div>
		<div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%" *ngIf="selectedTabLabel === 'Unprocessed'">
			<div class="movePhase-filter">
				<h6 class="filter-modal" id="statusTypeLbl">Status:</h6>
				<app-admin-filter-selection 
					labelId="statusTypeLbl"
					(selectedDataSetChange)="selectedDataSetChange('status', $event)"
					[dataSet]="statusCheckBoxList" [selectedDataSet]="selectedStatus" [PlaceHolder]="'Authorization Type'"></app-admin-filter-selection>
			</div>
		</div>
	</div>
	<div class="mat-dialog-actions flex-direction-row-reverse">
		<div class="filter-buttons" fxLayout="row" fxLayoutAlign="end center">
			<button mat-button mat-dialog-close class="text-button" mat-dialog-close> Cancel </button>
			<button mat-button class="contained-button" id="save"  [disabled] = "disableFilter" (click)="onSubmit()"> OK</button>
		</div>
	</div>
</div>

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-benefit-restrictions-rules-tasks',
  templateUrl: './benefit-restrictions-rules-tasks.component.html',
  styleUrls: ['./benefit-restrictions-rules-tasks.component.scss']
})
export class BenefitRestrictionsRulesTasksComponent implements OnInit {
  @Input() benefit: any;
  @Input() type: string;
  isRestrictionsAdded: boolean = true;
  isTasksAdded: boolean = true;
  constructor() { }

  ngOnInit(): void {
    if(!this.benefit?.scopes ||
    (!this.benefit?.scopes.spouse_partner || !this.benefit?.scopes.spouse_partner.spousePartnerRelocating) && 
    (!this.benefit?.scopes.triggered_access || (!this.benefit?.scopes.triggered_access.hasOwnProperty('percentage') || !this.benefit?.scopes.triggered_access.hasOwnProperty('noOfDays') || !this.benefit?.scopes.triggered_access.hasOwnProperty('milestone'))) && 
    (!this.benefit?.scopes.valid_complex_rules || !this.benefit?.scopes.valid_complex_rules.length) &&
    (!this.benefit?.scopes.departure_locations) &&
    (!this.benefit?.scopes.destination_locations) &&
    (!this.benefit?.scopes.simple_rules || !this.benefit?.scopes.simple_rules.length)) {
      this.isRestrictionsAdded = false;
    } 
    if(!this.benefit?.tasks || !this.benefit?.tasks?.length) {
      this.isTasksAdded = false;
    }
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-atlas-policy-warning',
  templateUrl: './atlas-policy-warning.component.html',
  styleUrls: ['./atlas-policy-warning.component.scss']
})
export class AtlasPolicyWarningComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AtlasPolicyWarningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  close() {
    this.dialogRef.close(true);
  }

  ngOnInit() {
  }

  onConfirm() {
    this.dialogRef.close({
      action: 'confirm'
    });
  }

  onClose() {
    this.dialogRef.close({
      action: 'dismiss'
    });
  }
}

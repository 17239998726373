import { NgModule } from '@angular/core';
// import { NumberOnlyDirective } from './directives/number-only.directive';
import { MatIconModule } from '@angular/material/icon';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AdditionalDocumentUploadComponent } from '../public/administration/components/additional-document-upload/additional-document-upload.component';
import { CommonModule } from '@angular/common';
import { DocumentLibraryComponent } from '../public/administration/components/document-library/document-library.component';
import { MatTableModule } from '@angular/material/table';
import { SharedTraditionalOrderModule } from '../shared/sharedTraditionalOrder.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AvatarModule } from './components/avatar/avatar.module';
import { MatSortModule } from '@angular/material/sort';
import { MultiTypeDirective } from './directives/multi-type.directive';

@NgModule({
    imports: [
      CommonModule, 
      MatIconModule,
      ReactiveFormsModule,
      MatExpansionModule,
      MatSelectModule,
      MatFormFieldModule,
      FlexLayoutModule,
      DragDropModule,
      MatButtonModule,
      MatInputModule,
      FormsModule,
      MatTableModule,
      SharedTraditionalOrderModule,
      MatTooltipModule,
      AvatarModule,
      MatSortModule
     ],
    declarations: [
      DragAndDropDirective,
      AdditionalDocumentUploadComponent,
      DocumentLibraryComponent,
      MultiTypeDirective
    ], 
    exports: [ AdditionalDocumentUploadComponent, DocumentLibraryComponent, MultiTypeDirective ]
  })
  
  export class SharedModule {}

import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { ConfigurableFieldComponent ,HighlightSearch} from './components/configurable-field/configurable-field.component'
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [ConfigurableFieldComponent, HighlightSearch],
  imports: [
    MatCardModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDatepickerModule, 
    MatNativeDateModule,
    MatExpansionModule ,
    MatTableModule,
    
  ],
  exports: [ConfigurableFieldComponent],
})
export class SharedTransfereeProgramModule { }

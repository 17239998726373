import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-sendinvite-initialcontact-call-modal',
  templateUrl: './sendinvite-initialcontact-call-modal.component.html',
  styleUrls: ['./sendinvite-initialcontact-call-modal.component.scss']
})
export class SendinviteInitialcontactCallModalComponent implements OnInit {
  headerLable: string =  'Initial Contact Confirmed';
   /**
   * @param dialogRef DialogRef
   * @param data Data to popup
   */
  constructor(public dialogRef: MatDialogRef < SendinviteInitialcontactCallModalComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if(this.data.displayHeaderLable){
      this.headerLable = this.data.displayHeaderLable;
    }
  }

   /**
   * To dismiss/cancel the copy program popup
   */
    onDismiss() {
      this.dialogRef.close();
    }
    /**
     * Confirm removal to parent component
     */
     sendInvite() {
      this.dialogRef.close({
        action: 'sendInvite'
      });
    }

}

<div class="admin-container">
  <div class="admin-content-container">

    <mat-card class="mat-card-client-details" fxLayout fxLayoutAlign="start start" fxLayoutAlign.xs="start start">
      <div class="help-icon-wrapper">
        <span><img src="../../../../../assets/images/icon_company.svg"></span>
      </div>
      <div class="client-detail" fxFlex.xs="100">
        <div class="client-name" *ngIf="clientDetails">{{clientDetails.clientEntityName}}</div>
        <div fxLayout class="additional-detail" fxLayoutAlign="start center">
          <span class="detail-header">(Client ID: </span>
          <span class="detail-value" *ngIf="clientDetails"> {{ clientDetails.clientNumber}}</span>
          )
        </div>
        <div fxLayout class="additional-detail" fxLayoutAlign="start center">
          <span class="detail-header" *ngIf="clientDetails && clientDetails.clientAddress">Location: </span>
          <span class="detail-value" *ngIf="clientDetails && clientDetails.clientAddress">
            {{(clientDetails.clientAddress) ? clientDetails.clientAddress.city: ''}},
            {{(clientDetails.clientAddress) ? clientDetails.clientAddress.state : ''}}, {{(clientDetails.clientAddress)
            ? clientDetails.clientAddress.country : ''}}</span>
        </div>
      </div>
      <div fxLayoutAlign="end start" class="close">
        <button mat-button (click)="close()" aria-label="Back to Client List" title="Back to Client List">
          <mat-icon aria-hidden="false" aria-label="back icon">close</mat-icon>
        </button>
      </div>
    </mat-card>


    <mat-accordion class="client-details-panel" multi="true">
      <!--Additional Details -->
      <mat-expansion-panel class="mat-expansion-panel-body">
        <mat-expansion-panel-header class="division-expansion">
          <span class="panel-heading">Client Details</span>
        </mat-expansion-panel-header>
        <mat-panel-description>
          <div *ngIf="clientEmailCapability" fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%"
            class="item item-1 align-email">
            <mat-form-field class="edit-auth-emial">
              <mat-label>Authorization Email Address</mat-label>
              <div class="align-fields">
                <input [(formControl)]="emailAddress" *ngIf="editEmail" [readonly]="editEmail" matInput
                  placeholder="Please enter Authorization email address">
                <input [(formControl)]="emailAddress" *ngIf="!editEmail" (blur)="submitEmail($event.target.value)"
                  matInput placeholder="Please enter Authorization email address">
                <button class="edit_icon"><mat-icon aria-label="edit" matTooltip="Edit email"
                    (click)="updateEmail($event)">edit</mat-icon></button>
              </div>
              <mat-error *ngIf="emailAddress.invalid">
                {{getErrorMessage()}}
              </mat-error>
            </mat-form-field>
          </div>
        </mat-panel-description>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<ng-container *ngFor="let bankInfo of bankingInfoArray">
    <mat-accordion class="example-headers-align" class="bank-info-container">
        <mat-expansion-panel>
            <mat-expansion-panel-header class="panel-header-color bank-details">
                <mat-panel-title>
                    <strong>Bank Name</strong>{{bankInfo.bankName}}
                </mat-panel-title>
                <mat-panel-title>
                    <strong>Account Number</strong>{{bankInfo.accountNumber | mask: 4}}
                </mat-panel-title>
                <mat-panel-title>
                    <strong>Routing Number</strong>{{bankInfo.routingNumber}}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <section class="m-t-10">
                <div class="category-label">Bank Information</div>
                <div fxLayout="row" fxLayoutAlign="space-between" class="bank-details p-lr-10">
                    <div fxLayout="column" fxLayoutGap="10px">
                        <div fxLayout="row">
                            <strong>Country</strong>{{bankInfo?.address?.country}}
                        </div>
                        <div fxLayout="row">
                            <strong>Swift Code</strong>{{bankInfo?.swiftBICCode}}
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="10px">
                        <div fxLayout="row">
                            <strong>Currency</strong>{{bankInfo.disbursementCurrency}}
                        </div>
                        <div fxLayout="row" class="info-row">
                            <strong>Bank Name</strong>{{bankInfo.bankName}}
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="10px">
                        <div fxLayout="row">
                            <strong>Routing Number</strong>{{bankInfo.routingNumber}}
                        </div>
                        <div fxLayout="row" class="info-row">
                            <strong>Bank Details</strong> 
                            <ng-container *ngIf="bankInfo?.address as address">
                                {{address?.streetLine1}}, {{address?.city}}, {{address?.postal}}, {{address?.stateProvince}}
                            </ng-container>
                        </div>
                    </div>
                </div>
            </section>
            <section class="m-t-10">
                <div class="category-label">Account Information</div>
                <div fxLayout="row" fxLayoutAlign="space-between" class="bank-details p-lr-10">
                    <div fxLayout="column" fxLayoutGap="10px">
                        <div fxLayout="row">
                            <strong>Account Number / IBAN</strong>{{bankInfo.accountNumber | mask: 4}}
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="10px">
                        <div fxLayout="row">
                            <strong>Account Type</strong>{{bankInfo.accountType}}
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="10px">
                        <div fxLayout="row">
                            <strong>Account Holder Name</strong>{{bankInfo.accountHolder}}
                        </div>
                    </div>
                </div>
                <div fxLayout="row" class="bank-details p-lr-10 m-t-10">
                    <strong>Instructions to Bank</strong> {{bankInfo.specialInstructions}}
                </div>
            </section>
            <section class="m-t-10" *ngIf="bankInfo?.payThroughBank as payThroughBank">
                <div class="category-label">Pay Through Bank Details</div>
                <div fxLayout="row" fxLayoutAlign="space-between" class="bank-details p-lr-10">
                    <div fxLayout="column" fxLayoutGap="10px">
                        <div fxLayout="row">
                            <strong>needed?</strong> {{payThroughBank ? 'Yes' : No}}
                        </div>
                        <div fxLayout="row">
                            <strong>Name</strong> {{payThroughBank.bankName}}
                        </div>
                        <div fxLayout="row">
                            <strong>Swift Code</strong> {{payThroughBank?.swiftBICCode || 'NA'}}
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="10px">
                        <div fxLayout="row">
                            <strong>Country</strong> {{payThroughBank.address.country}}
                        </div>
                        <div fxLayout="row">
                            <strong>Details</strong>
                            <ng-container *ngIf="payThroughBank?.address as address">
                                {{address?.streetLine1}}, {{address?.city}}, {{address?.postal}}, {{address?.stateProvince}}
                            </ng-container>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="10px">
                        <div fxLayout="row">
                            <strong>Routing Number</strong>{{payThroughBank?.routingNumber || 'NA'}}
                        </div>
                        <div fxLayout="row">
                            <strong>Account Number / IBAN</strong> {{payThroughBank.accountNumber | mask: 4}}
                        </div>
                    </div>
                </div>
            </section>
        </mat-expansion-panel>
    </mat-accordion>
</ng-container>
  

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mask'
})
export class MaskPipe implements PipeTransform {

  transform(value: string, noOfDigitsToExcludeFromEnd = 0 ): string {
    if (!value) {
      return '';
    }
    const maskedValue = `${'*'.repeat(value.length - noOfDigitsToExcludeFromEnd)}${ noOfDigitsToExcludeFromEnd ? value.slice(-noOfDigitsToExcludeFromEnd): ''}`;
    return maskedValue;
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberwithcommas'
})
export class NumberWithCommasPipe implements PipeTransform {

    transform(n: any): string {
      if(typeof n === 'string') {
        n = parseFloat(n.replace(/,/g, ''));
        return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

}
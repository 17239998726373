import { Component, ElementRef, Inject, Injector, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { FamilyMember } from '../../../../../core/models/family-member.model';
import { LocationService } from '../../../../../core/services/location.service';
// import { NeedsAssessmentSharedService } from '../../../../../core/services/need';
import { FamilyInfoService } from '../../../services/family-info.service';
import { Country } from '../../../../../core/models/country.model';
import { UserType, UserTypeService } from '../../../services/user-type.service';
import { DatePipe, formatDate } from '@angular/common';
import { CandidateProfileService, CustomerRelocationDetails, HousingPreference } from '../../../services/candidate-profile.service';
import { candidateProfile } from '../../../../../core/models/candidate.model';
import { LocationsService } from '../../../services/locations.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import PlaceResult = google.maps.places.PlaceResult;
import { map, startWith } from 'rxjs/operators';
import { WorkOrderPdfGenerationService } from '../../../services/work-order-pdf-generation.service';
import pdfMake from 'pdfmake/build/pdfmake';
import * as moment from 'moment';

export interface orderModifications {
  DaysAdded: any;
  lengthOfStay: number;
  moveOut: any;
  modificationDate: any;
}

@Component({
  selector: 'app-work-detail-popup',
  templateUrl: './work-detail-popup.component.html',
  styleUrls: ['./work-detail-popup.component.scss']
})
export class WorkDetailPopupComponent implements OnInit {
  /**  to check whether businessEmail and businessNum is present or not */
  show: boolean = false;
  toggleFlag: boolean = false;
  PetdeposittoggleFlag: boolean;
  submitted: boolean = this.data.details.disable;
  businessEmail: any;
  businessNum: any;
  /** assesNeed form */
  assessNeedsForm: UntypedFormGroup;
  /**Flag to check if family details are available*/
  retrievedFamilyDetails = false;
  /**contains family details*/
  familyDetails: Array<FamilyMember> = [];
  /** To store count of family members to be updated */
  newFamilyInformation: number;
  /**Contains total number of family members relocating including yourself*/
  @Input() relocatingMembers: number;
  /** Flag to identify if needs assessment has been completed by candidate */
  isNeedsAssessmentCompleted: boolean;
  /** Flag to set card to read only by role */
  isFamilyReadOnly = false;
  /**Contains total number of family members relocating*/
  totalNumberOfRelocatingMembers: number;

  /**Contains transferee needs assessment values */
  neeedsAssessmentVal: candidateProfile;
  userRole: UserType;
  isCheckDogBreed = false;
  accompanyingMembers: Array<any> = [];
  needsAssessment: {};
  countryNames: any;
  selectedCountryNames = [];
  countryList = [];
  familyMember: any;
  //housingPreference
  housingPrefForm: UntypedFormGroup;
  originalPriorityList: string[] = [];
  numberBedroomsValueList: any = [];
  numberBathroomsValueList: any = [];
  accommodationTypeValueList: any = [];
  financialResponsibilityValueList: any = [];
  housekeepingElemValueList: any = [];
  priorityList: Array<Array<string>> = [
    [...this.originalPriorityList]
  ];
  showHousing: boolean = true;
  showParking: boolean = true;
  petType: Array<string> = ['Cat', 'Dog', 'Other'];
  completedCount: number = 0;
  consultantPhoneDetails: any;
  consultantEmailDetails: any;
  completedItemCount = 0;
  completedItemCountHosing = 0;
  relocationForm: UntypedFormGroup;
  providerValueList: any = [];
  serviceDeliveryLocationValueList: any = [];
  relocationDetailsForm: any;
  currencieList = [];
  curencieRes: any;
  orderPriorityList = [];
  reFinancialResponsibilityValueList = [];
  completedItemCountRelocation = 0;
  agentBookingData: any =[];
  bookingPartyId: any;
  bookingPartyName: string;
  supplierID: string;
  splitBill: boolean = false;
  splitBillMonetaryAmt: boolean = false;
  disableToggle: boolean;
  cancelToggle: boolean = false;

  //minDate= new Date();
  minDate= new Date();
  minMoveDate;
  diffDays: number;
  displayedColumns: string[] ;
  isTableHasData:boolean;
  statusValue = ["Set Status","Accept","Reject"];
  afterSetStatusValue = ["Accepted","Rejected"];
  statusDropDown : any = [];
  modificationId;
  disableAllStatus: boolean = false;
  editMonetaryAmount: boolean = false;
  filteredCurrencyOptions: Observable<any[]>;
  @ViewChild('autoCompleteCurrencyInput', { read: MatAutocompleteTrigger, static: true }) autoCompleteCurrency: MatAutocompleteTrigger;

  @ViewChild('scrollMe', {static:true}) private myScrollContainer: ElementRef;
  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<WorkDetailPopupComponent>,
    private familyInfoService: FamilyInfoService,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private injector: Injector,
    private readonly locationService: LocationsService,
    private candidateProfileService: CandidateProfileService,
    public datepipe: DatePipe,
    private userTypeService: UserTypeService,
    private readonly toastrService: ToastrService,
    public readonly pdfgenerator: WorkOrderPdfGenerationService,
    public readonly toaster: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.generateForm()
  }

  ngOnInit() {
    this.displayedColumns = this.data.checkHybridTempliving ? ['DaysAdded', 'lengthOfStay', 'moveOut','modificationDate', 'status'] : ['DaysAdded', 'lengthOfStay','modificationDate', 'status']
    this.getConsultantPhone();
    this.getConsultantEmail();
    this.spinner.show();
    this.retrievedFamilyDetails = false;
    console.log("details data",this.data);
    /**status logic to disable */
    this.data.supplierData ? this.statusCheck() : null;
    this.data.modification ? this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight : null;
    this.locationService.countryList.subscribe(countryListData => {
      if (countryListData.length > 0) {
        this.spinner.hide();
        this.countryList = countryListData.sort((a, b) => a.countryName.localeCompare(b.countryName));
        this.countryNames = this.countryList.map(ele => ele.countryName);
        this.isNeedsAssessmentCompleted = true;
        this.userRole = this.userTypeService.userType;
        if (this.userRole === 'file-setup-specialist' || this.userRole === 'consultant') {
          this.isFamilyReadOnly = false;
        } else {
          this.isFamilyReadOnly = true;
        }
        this.familyInfoService.getFamilyMemberInfo().subscribe(familyMemberList => {
          if (familyMemberList && familyMemberList.length > 0) {
            this.spinner.hide();
            this.displayExistingFamilyDetails(familyMemberList);
          } else {
            this.totalNumberOfRelocatingMembers = this.relocatingMembers - 1;
            this.newFamilyInformation = this.totalNumberOfRelocatingMembers;
            this.retrievedFamilyDetails = true;
          }
          this.spinner.hide();
        });

        this.data.details.customerDetails.emailDetailsList.forEach(element => {
          if (element["usageType"] == "business") {
            this.businessEmail = element["emailAddress"];
          }else{
            this.businessEmail = '';
          }
        });

        this.data.details.customerDetails.phoneDetailsList.forEach(element => {
          if (element["usageType"] == "business") {
            this.businessNum = element["phoneNumber"];
          }else{
            this.businessNum = '';
          }
          console.log("phone",this.businessNum);

        });
        this.data.disable ? this.assessNeedsForm.disable() : null; // check getting disbles
        if (this.data.details.needsAssessment.petsInformation.length > 0) {
          this.toggleFlag = true;
          this.show = true;
        }
        this.PetdeposittoggleFlag = this.data.details.needsAssessment.petDepositApproved;
      }
    });
    this.selectedCountryNames = this.data.details.needsAssessment.travelHistory;
    this.retrievedFamilyDetails = false;
    // if needs aseessment has been completed by candidate
    this.spinner.show();
    this.originalPriorityList = this.data.details.housingPreference.prioritiesValueList;
    this.numberBedroomsValueList = this.data.details.housingPreference.numberBedroomsValueList;
    this.numberBathroomsValueList = this.data.details.housingPreference.numberOfBathroomsValueList;
    this.accommodationTypeValueList = this.data.details.housingPreference.accommodationTypeValueList;
    this.financialResponsibilityValueList = this.data.details.housingPreference.parkingInfo.financialResponsibilityValueList;
    this.reFinancialResponsibilityValueList = this.data.details.customerRelocationDetails.financialResponsibilityValueList;
    this.housekeepingElemValueList = this.data.details.housingPreference.housekeepingInfo.housekeepingElemValueList;
    this.providerValueList = this.data.details.customerRelocationDetails.providerValueList;
    this.serviceDeliveryLocationValueList = this.data.details.customerRelocationDetails.serviceDeliveryLocationValueList;
    this.spinner.hide();
    if (this.data.curencieRes.length > 0) {
      this.data.curencieRes.forEach((element) => {
        this.currencieList.push(element);
      })

      this.relocationForm = this.generateRelocateForm();

      if( this.data.checkForStatus == 'addOnCancelled' && this.data.reimbursement) {
        this.data.details.customerRelocationDetails['clientContribution'] = {
          monetaryAmount: ''
        }
        this.relocationForm.get('monetaryAmount').setValue('');

        const cur = this.data.details.customerRelocationDetails.clientContribution && this.data.details.customerRelocationDetails.clientContribution.currencyCode ?
          this.data.details.customerRelocationDetails.clientContribution.currencyCode : 'USD';
        this.relocationForm.get('monetaryAmountWithCurrency').setValue('');
      }

      this.filteredCurrencyOptions = this.relocationForm.get('clientBudgetCurrency').valueChanges
        .pipe(
        startWith(''),
        map(value => this._filter(value))
        );
    }
    this.housingPrefForm = this.populateForm();
    this.minMoveDate = this.housingPrefForm.get('moveInDate').value ? this.housingPrefForm.get('moveInDate').value : null;
    this.showHousing = !this.housingPrefForm.get('houseKeeping').value;
    if (moment(this.housingPrefForm.get('moveInDate').value).format('YYYY-MM-DD HH:mm:ss') < moment(this.minDate).format('YYYY-MM-DD HH:mm:ss') && !this.submitted) {
      this.housingPrefForm.get('moveInDate').markAsTouched();
      this.housingPrefForm.get('moveInDate').markAsDirty();
    }
    this.initPriorityList();  // filtering drop down values for each priority on init
    /**set desiredLocation, Alternative Location, designationOffice Location on init */
    if(this.data.details.housingPreference && this.data.details.housingPreference.alternateLocation) {
        this.setContryDisplayName('alternateLocation', 'altOfficeLocation', 'altCountry');
    }
    if (this.data.details.housingPreference && this.data.details.housingPreference.desiredLocation) {
        this.setContryDisplayName('desiredLocation', 'desOfficeLocation', 'desCountry');
    }
    if (this.data.details.housingPreference && this.data.details.housingPreference.destinationOfficeLocation) {
        this.setContryDisplayName('destinationLocation', 'destOfficeLocation', 'destCountry');
    }
    this.data.disable ? this.housingPrefForm.disable() : null;  //disable all fields
    this.getCount();
    this.checkHPCompletedCount();
    this.getRelocationCount();
    // this.candidateProfileService.getCurrencies().subscribe(res => {
    //   this.curencieRes = res.values
    //   if (res.values.length > 0) {
    //     res.values.forEach((element) => {
    //       this.currencieList.push(element);
    //     })
    //   }
    // });
   this.candidateProfileService.getOrderPriority().subscribe(res => {
      if (res.values.length > 0) {
        res.values.forEach((element) => {
          if(element === "Exec VIP" || element === "Premier Executive" || element === "Normal") {
            this.orderPriorityList.push(element);
            }
        })
      }
    })
    /**Booking Agent */
    this.data.details.bookingAgent.forEach(element => {
      if (element) {
        this.bookingPartyId = element.partyId;
        this.bookingPartyName= element.partyName;
        this.supplierID = element.supplierID;
      }
    });
    // this.statusDropDown = new FormControl(this.statusValue[0]);
    this.disableForm();
    if(this.data.modification || !this.data.modification){
      this.modificationId = "modificationTable";
    }
  }


  ngAfterViewInit() {
    if(this.data.modificationClicked){
      this.scroll("panel-1");
    }
  }

  scroll(id) {
    console.log(`scrolling to ${id}`);
    let el = document.getElementById(id);
    el.scrollIntoView();
  }
  /**check status to disable */
  statusCheck() {
    this.data.supplierData.forEach((ele,index) => {
      if(ele.hasOwnProperty('status')) this.statusDropDown[index] = ele.status
      else  {
        this.data.supplierData[index]['status'] = 'Set Status';
        this.statusDropDown[index] = 'Set Status';
      }
    })
    if (this.data && this.data.supplierData && this.data.supplierData.length > 1) {
      const len = this.data.supplierData.length;
        for(let i = 0 ; i < len -1; i++) {
          this.data.supplierData[i]['disableStatus'] = this.data.supplierData[i+1].status === 'Accepted' || this.data.supplierData[i+1].status === 'Rejected' ? false : true;
        }
    }
  }

  /**
   * api call on status selection
   */
  onStatusSelect(event, element, index) {
    this.spinner.show();
    if (event.value == 'Accept' || event.value == 'Accepted') {
      this.candidateProfileService.acceptOrder(this.data.orderID, element.modificationId).
        subscribe(data => {
          if (data) {
            this.data.supplierData[index].status = data.status;
            this.statusDropDown[index] = 'Accepted';
            /**hybridTemp Relocation detail update */
            if(this.data.checkHybridTempliving) {
              element.newMoveOutDate ? this.housingPrefForm.get('moveOutDate').setValue(this.setDateWithOffset(element.newMoveOutDate)) : null;
              element.totalLengthofStay ? this.housingPrefForm.get('lengthOfStay').setValue(element.totalLengthofStay) : null;
              /**for pdf */
              this.data.details.housingPreference['moveOutDate'] = element.newMoveOutDate;
              this.data.details.housingPreference['lengthOfStay'] = element.totalLengthofStay;
            }
            /**reiembersement Relocation details update */
            if (this.data.reimbursement && element.totalAmountReimbursed) {
              const cur = this.data.details.customerRelocationDetails.clientContribution && this.data.details.customerRelocationDetails.clientContribution.currencyCode ? true : false;
              this.relocationForm.get('monetaryAmount').setValue(element.totalAmountReimbursed);
              cur ? this.relocationForm.get('monetaryAmountWithCurrency').setValue(`${element.totalAmountReimbursed} ${this.data.details.customerRelocationDetails.clientContribution.currencyCode}`)
                : this.relocationForm.get('monetaryAmountWithCurrency').setValue(`${element.totalAmountReimbursed} USD`);
              /**for pdf */
              this.data.details.customerRelocationDetails['clientContribution'] = {
                monetaryAmount: `${element.totalAmountReimbursed} USD`
              }
            }
            this.statusCheck();
            this.spinner.hide();
          } else {
            this.data.supplierData[index].status = element.status;
            this.statusDropDown[index] = element.status;
          }
        });
    } else if (event.value == "Reject" || event.value == "Rejected") {
      this.candidateProfileService.rejectOrder(this.data.orderID).
        subscribe(data => {
          if (data) {
            this.data.supplierData[index].status = 'Rejected';
            this.statusDropDown[index] = 'Rejected';
            const len = this.data.supplierData.length;
            for(let i = 0 ; i < len -1; i++) {
              if(this.data.supplierData[i].status === 'Set Status') {
                this.statusDropDown[index] = 'Rejected';
                this.data.supplierData[i]['status'] = 'Rejected';
              }
            }
            this.statusCheck();
            this.spinner.hide();
          } else {
            this.data.supplierData[index].status = element.status;
            this.statusDropDown[index] = element.status;
          }
        });

    }
  }

  private _filter(value):any[] {
    if (value) {
    const filterValue = typeof value === 'number' ? value : value.toLowerCase();
    const currencyList = this.currencieList.filter(option => option.displayValue.toLowerCase().indexOf(filterValue) === 0);
    return currencyList;
  } else {
      return this.currencieList;
    }
  }

  /**
  *  Code For Assess  Needs Accordian Starts Here
  */

  generateForm() {
    this.assessNeedsForm = this.formBuilder.group({
      petsInformation: new UntypedFormArray(this.createInfo(this.data.details.needsAssessment.petsInformation)),
      departureLocation: [this.data.details.needsAssessment.departureLocation ? this.data.details.needsAssessment.departureLocation : null, Validators.required],
      travelHistory: [this.data.details.needsAssessment.travelHistory ? this.data.details.needsAssessment.travelHistory : null, Validators.required]
    });
    this.assessNeedsForm.setValidators(this.checkIsValid());
  }


  generateRelocateForm() {
    const relocationData =  this.data.details.customerRelocationDetails;
    const check = this.isNullCheck;
    const relForm = this.formBuilder.group({
      filePriority: [relocationData.filePriority != null  ? check(relocationData, 'filePriority') : 'Normal', [Validators.required]],
      daysAuthorized: [relocationData ? check(relocationData, 'daysAuthorized') : '', [Validators.required]],
      serviceDeliveryLocation: [relocationData && relocationData.serviceDeliveryLocation && relocationData.serviceDeliveryLocationValueList ? this.checkLocationFilter(relocationData.serviceDeliveryLocation, relocationData.serviceDeliveryLocationValueList) : '', [Validators.required]],
      relocationFinancialResponsibility: [relocationData ? check(relocationData, 'financialResponsibility') : '', [Validators.required]],
      clientBudgetCurrency: [relocationData && relocationData.clientBudgetCurrency? this.checkCurrencyFilter(relocationData.clientBudgetCurrency, this.currencieList) : '', [Validators.required]],
      clientBudgetAmount: [relocationData ? check(relocationData, 'clientBudgetAmount') : '', [Validators.required]],
      requiresResidencyLetter: [relocationData && relocationData.requiresResidencyLetter ? !relocationData.requiresResidencyLetter : true],
      rush:[relocationData&&relocationData.rush ? relocationData.rush: false],
      provider: [relocationData ? check(relocationData, 'provider') : '', [Validators.required]],
      clientDirectedProviderComment: [relocationData ? check(relocationData, 'clientDirectedProviderComment') : ''],
      splitBillComments: [relocationData ? check(relocationData, 'splitBillComments') : ''],
      monetaryAmount: [relocationData ? check(relocationData.clientContribution, 'monetaryAmount') : ''],
      monetaryAmountWithCurrency: [relocationData && relocationData.clientContribution && relocationData.clientContribution.currencyCode ? `${relocationData.clientContribution.monetaryAmount} ${relocationData.clientContribution.currencyCode}` :
        relocationData.clientContribution ? `${relocationData.clientContribution.monetaryAmount} USD`:'' ]
    });
    return relForm;
  }
  checkLocationFilter(value, valueList) {
    return valueList.find(ele => ele.country == value)
  }

  checkCurrencyFilter(type,currList){
    return currList.find(ele => ele.value == type)
  }


  checkIsValid(): ValidatorFn {
    return (group: UntypedFormArray): ValidationErrors => {
      group.controls['petsInformation'].controls.forEach(element => {
        if (element.value.type === "Dog" && !element.value.breed) {
          element.controls.breed.setErrors({ nullValue: true })
          this.isCheckDogBreed = true;
        }
        if (element.value.type === "Other" && !element.value.other) {
          element.controls.other.setErrors({ nullValue: true })
          this.isCheckDogBreed = true;
        } else {
          this.isCheckDogBreed = false;
        }
      });
      return;
    };
  }

  createInfo(data) {
    if (data.length) {
      const petArray = data.map(pet => {
        return this.formBuilder.group({
          name: [pet.name ? pet.name : null, Validators.required],
          type: [pet.type ? pet.type : null, Validators.required],
          breed: [pet.breed ? pet.breed : null],
          weight: [pet.weight ? pet.weight : null, Validators.required],
          other: [pet.other ? pet.other : null]
        })
      });
      return petArray
    } else {
      return [this.addFormControl()];
    }
  }

  addFormControl() {
    const fb = this.injector.get(UntypedFormBuilder);
    return (
      fb.group({
        name: new UntypedFormControl('', Validators.required),
        type: new UntypedFormControl('', Validators.required),
        breed: new UntypedFormControl(),
        weight: new UntypedFormControl('', Validators.required),
        other: new UntypedFormControl()
      })
    )
  }

  displayExistingFamilyDetails(familyMemberList) {
    console.log("familyMemberList ===", familyMemberList);
    this.spinner.show();
    this.familyDetails = familyMemberList;
    this.totalNumberOfRelocatingMembers = (this.familyDetails.length > this.relocatingMembers - 1) ?
      this.familyDetails.length : this.relocatingMembers - 1;
    this.newFamilyInformation = this.totalNumberOfRelocatingMembers - this.familyDetails.length;
    this.retrievedFamilyDetails = true;
    this.data.details.needsAssessment.accompanyingMembers.forEach(element => {
      element.isTravelling && !this.accompanyingMembers.find(ele => ele === element.id) ? this.accompanyingMembers.push(element.id) : null;
    });
    this.spinner.hide();
  }

  addPet(i): void {
    (this.assessNeedsForm.get('petsInformation') as UntypedFormArray).push(
      this.addFormControl()
    )
  }

  removePet(index) {
    console.log(index);
    (<UntypedFormArray>this.assessNeedsForm.get("petsInformation")).removeAt(index);
  }


  onSubmit(status) {
    this.spinner.show();
    let submitWorkOrder: boolean;
    let completedCount = 0;
    if (status === 'draft') {
      if (this.assessNeedsForm.controls.departureLocation.status == 'VALID') completedCount++
      if (this.assessNeedsForm.controls.travelHistory.status == "VALID") completedCount++
      //if (this.assessNeedsForm.controls.petsInformation.status == "VALID" || !this.toggleFlag) completedCount++
    }
    let assessNeedsFormValues = this.assessNeedsForm.value;
    let relocationFormValues = this.relocationForm.value;
    let housingPrefFormValues = this.housingPrefForm.value;
    assessNeedsFormValues.petsInformation.forEach(ele => {
      if (!ele.breed && ele.type !== 'Dog') {
        delete ele.breed;
      }
      if (!ele.other && ele.type !== 'other') {
        delete ele.other;
      }
    });
    if (!this.show) {
      assessNeedsFormValues['petsInformation'] = [];
    }
    assessNeedsFormValues['petsInformation'] = assessNeedsFormValues.petsInformation;
    assessNeedsFormValues['assessNeedsDraft'] = status == 'draft' ? true : false;
    assessNeedsFormValues['petDepositApproved'] = this.PetdeposittoggleFlag;
    assessNeedsFormValues['accompanyingMembers'] = this.accompanyingMembers.length ? this.accompanyingMembers : [];
    this.needsAssessment = assessNeedsFormValues;
    let countCompletedItem = 0;
    this.spinner.show();
    let payload: HousingPreference = {
      // housingPreferencesDraft: status === 'draft' ? true : false,
    }
    const dailogData = this.data.details.housingPreference;
    const bookingAgentReq = this.bookingPartyId;
    let relocationDetailsReq = {
      "filePriority": (this.relocationForm.get('filePriority').value == null||this.relocationForm.get('filePriority').value == '')?null:this.relocationForm.get('filePriority').value,
      "daysAuthorized": (this.relocationForm.get('daysAuthorized').value == null||this.relocationForm.get('daysAuthorized').value == '')?null:this.relocationForm.get('daysAuthorized').value,
      "serviceDeliveryLocation": (this.relocationForm.value.serviceDeliveryLocation.type == null||this.relocationForm.value.serviceDeliveryLocation.type == '')?null:this.relocationForm.value.serviceDeliveryLocation.type,
      "financialResponsibility": (this.relocationForm.get('relocationFinancialResponsibility').value == null||this.relocationForm.get('relocationFinancialResponsibility').value == '')?null:this.relocationForm.get('relocationFinancialResponsibility').value,
      "clientBudgetAmount": (this.relocationForm.get('clientBudgetAmount').value == null||this.relocationForm.get('clientBudgetAmount').value == '')?null:this.relocationForm.get('clientBudgetAmount').value,
      "clientBudgetCurrency": (this.relocationForm.get('clientBudgetCurrency').value.value == null||this.relocationForm.get('clientBudgetCurrency').value.value == '')?null:this.relocationForm.get('clientBudgetCurrency').value.value,
      "provider": (this.relocationForm.get('provider').value == null||this.relocationForm.get('provider').value == '')?null:this.relocationForm.get('provider').value,
      "clientDirectedProviderComment": (this.relocationForm.get('clientDirectedProviderComment').value == null||this.relocationForm.get('clientDirectedProviderComment').value == '')?null:this.relocationForm.get('clientDirectedProviderComment').value,
      "splitBillComments": (this.relocationForm.get('splitBillComments').value == null||this.relocationForm.get('splitBillComments').value == '')?null:this.relocationForm.get('splitBillComments').value,
      "requiresResidencyLetter":!this.relocationForm.get('requiresResidencyLetter').value,
      "rush" : this.relocationForm.get('rush').value,
    };
    let clientContributionObj = {
      monetaryAmount : (this.relocationForm.get('monetaryAmount').value == null||this.relocationForm.get('monetaryAmount').value == '') ? null : this.relocationForm.get('monetaryAmount').value,
    }
    this.data.details.customerRelocationDetails && this.data.details.customerRelocationDetails.clientContribution && this.data.details.customerRelocationDetails.clientContribution.currencyCode ? clientContributionObj['currencyCode'] = this.data.details.customerRelocationDetails.clientContribution.currencyCode : null;
    // this.data.reiembersement ? relocationDetailsReq['clientContribution'] = clientContributionObj : null;

    this.relocationForm.get('relocationFinancialResponsibility').value == 'Split Bill' && this.data.reimbursement
      ? (relocationDetailsReq['clientContribution'] = clientContributionObj )
      : delete relocationDetailsReq["clientContribution"];

    this.relocationForm.get('relocationFinancialResponsibility').value == 'Split Bill' && this.relocationForm.get('splitBillComments').value !== '' && this.relocationForm.get('splitBillComments').value !== null
      ? (relocationDetailsReq["splitBillComments"] = this.relocationForm.get('splitBillComments').value)
      : delete relocationDetailsReq["splitBillComments"];

    (dailogData.moveInDate && (this.housingPrefForm.get('moveInDate').value == null || this.housingPrefForm.get('moveInDate').value == "")) ? payload['moveInDate'] = null :
      (this.housingPrefForm.get('moveInDate').value) ? payload['moveInDate'] = `${moment(this.housingPrefForm.get('moveInDate').value).format().split('T')[0]}T00:00:00.000Z`  : null;

    (dailogData.moveOutDate && (this.housingPrefForm.get('moveOutDate').value == null || this.housingPrefForm.get('moveOutDate').value == "")) ? payload['moveOutDate'] = null :
      this.housingPrefForm.get('moveOutDate').value ? payload['moveOutDate'] = `${moment(this.housingPrefForm.get('moveOutDate').value).format().split('T')[0]}T00:00:00.000Z` : null;

    (dailogData.numberBedrooms && (this.housingPrefForm.get('numberOfBedrooms').value == null || this.housingPrefForm.get('numberOfBedrooms').value == "")) ? payload['numberBedrooms'] = null :
      this.housingPrefForm.get('numberOfBedrooms').value ? payload['numberBedrooms'] = this.housingPrefForm.get('numberOfBedrooms').value : null;
    (dailogData.numberBedrooms && (this.housingPrefForm.get('numberOfBathrooms').value == null || this.housingPrefForm.get('numberOfBathrooms').value == "")) ? payload['numberBedrooms'] = null :
      this.housingPrefForm.get('numberOfBathrooms').value ? payload['numberOfBathrooms'] = this.housingPrefForm.get('numberOfBathrooms').value : null;
    (dailogData.numberApartments && (this.housingPrefForm.get('numberOfApartments').value == null || this.housingPrefForm.get('numberOfBedrooms').value == "")) ? payload['numberApartments'] = null :
      this.housingPrefForm.get('numberOfApartments').value ? payload['numberApartments'] = this.housingPrefForm.get('numberOfApartments').value : null;

    (dailogData.numberApartments && (this.housingPrefForm.get('accommodationType').value == null || this.housingPrefForm.get('accommodationType').value == "")) ? payload['accommodationType'] = null :
      this.housingPrefForm.get('accommodationType').value ? payload['accommodationType'] = this.housingPrefForm.get('accommodationType').value : null;

    (dailogData.lengthOfStay && (this.housingPrefForm.get('lengthOfStay').value == null || this.housingPrefForm.get('lengthOfStay').value == "")) ? payload['lengthOfStay'] = null :
      this.housingPrefForm.get('lengthOfStay').value ? payload['lengthOfStay'] = this.housingPrefForm.get('lengthOfStay').value : null;

    const priorityList = this.getExistingValues();
    if (priorityList.length > 0) {
      payload['priorities'] = priorityList;
      (status == 'draft') ? countCompletedItem++ : null;
    } else {
      (dailogData.priorities.length > 0 && priorityList.length == 0) ? payload['priorities'] = null : null;
    }

    (dailogData.additionalInstructions && (this.housingPrefForm.get('additionalInstructions').value == null || this.housingPrefForm.get('additionalInstructions').value == "")) ? payload['additionalInstructions'] = null :
      this.housingPrefForm.get('additionalInstructions').value ? payload['additionalInstructions'] = this.housingPrefForm.get('additionalInstructions').value : null;

    const locationNullObj = {country: null, state: null, city: null, streetLine1: null, postalCode: null}
    let desiredLoc = {} as any;  // form data
 let desiredData =(this.housingPrefForm.get(['desiredLocation','desOfficeLocation']).value ) === null || (this.housingPrefForm.get(['desiredLocation','desOfficeLocation']).value ) === '' ? false:true;
    desiredLoc['country'] = this.housingPrefForm.get(['desiredLocation', 'desCountry']).value &&desiredData ? this.housingPrefForm.get(['desiredLocation', 'desCountry']).value : null;
    desiredLoc['state'] = this.housingPrefForm.get(['desiredLocation', 'state']).value && desiredData? this.housingPrefForm.get(['desiredLocation', 'state']).value : null;
    desiredLoc['city'] =this.housingPrefForm.get(['desiredLocation', 'city']).value && desiredData ? this.housingPrefForm.get(['desiredLocation', 'city']).value : null;
    desiredLoc['streetLine1'] = this.housingPrefForm.get(['desiredLocation', 'street']).value && desiredData? this.housingPrefForm.get(['desiredLocation', 'street']).value : null;
    desiredLoc['postalCode'] = this.housingPrefForm.get(['desiredLocation', 'postalCode']).value && desiredData? this.housingPrefForm.get(['desiredLocation', 'postalCode']).value : null;
    payload.desiredLocation = desiredLoc;
    (this.housingPrefForm.get(['desiredLocation','desOfficeLocation']).value && status == 'draft') ? countCompletedItem ++ : null;

    let destLoc = {} as any;
      let destData =(this.housingPrefForm.get(['destinationLocation','destOfficeLocation']).value ) === null || (this.housingPrefForm.get(['destinationLocation','destOfficeLocation']).value ) === '' ? false : true;
    destLoc['country'] = this.housingPrefForm.get(['destinationLocation', 'destCountry']).value && destData ? this.housingPrefForm.get(['destinationLocation', 'destCountry']).value : null;
    destLoc['state'] = this.housingPrefForm.get(['destinationLocation', 'state']).value && destData ? this.housingPrefForm.get(['destinationLocation', 'state']).value : null;
    destLoc['city'] = this.housingPrefForm.get(['destinationLocation', 'city']).value && destData ? this.housingPrefForm.get(['destinationLocation', 'city']).value : null;
    destLoc['streetLine1'] = this.housingPrefForm.get(['destinationLocation', 'street']).value && destData ? this.housingPrefForm.get(['destinationLocation', 'street']).value : null;
    destLoc['postalCode'] = this.housingPrefForm.get(['destinationLocation', 'postalCode']).value && destData? this.housingPrefForm.get(['destinationLocation', 'postalCode']).value : null;
    payload.destinationOfficeLocation = destLoc;
    (this.housingPrefForm.get(['destinationLocation','destOfficeLocation']).value && status == 'draft') ? countCompletedItem ++ : null;

    let alterLoc = {} as any;
    let alterData =(this.housingPrefForm.get(['alternateLocation','altOfficeLocation']).value ) === null || (this.housingPrefForm.get(['alternateLocation','altOfficeLocation']).value ) ==='' ? false:true;
    alterLoc['country'] = this.housingPrefForm.get(['alternateLocation', 'altCountry']).value && alterData ? this.housingPrefForm.get(['alternateLocation', 'altCountry']).value : null;
    alterLoc['state'] = this.housingPrefForm.get(['alternateLocation', 'state']).value && alterData? this.housingPrefForm.get(['alternateLocation', 'state']).value : null;
    alterLoc['city'] = this.housingPrefForm.get(['alternateLocation', 'city']).value && alterData? this.housingPrefForm.get(['alternateLocation', 'city']).value : null;
    alterLoc['streetLine1'] = this.housingPrefForm.get(['alternateLocation', 'street']).value && alterData ? this.housingPrefForm.get(['alternateLocation', 'street']).value : null;
    alterLoc['postalCode'] = this.housingPrefForm.get(['alternateLocation', 'postalCode']).value && alterData? this.housingPrefForm.get(['alternateLocation', 'postalCode']).value : null;
    payload.alternateLocation  = alterLoc;
    (this.housingPrefForm.get(['alternateLocation','altOfficeLocation']).value && status == 'draft') ? countCompletedItem ++ : null;

    /** all single tiggles */
    payload['babyCotRequired'] = !this.housingPrefForm.get('babyCotRequired').value;
    payload['furnished'] = !this.housingPrefForm.get('furnished').value;
    payload['kitchenRequired'] = !this.housingPrefForm.get('kitchenRequired').value;

    (dailogData.acceptableCommute && (this.housingPrefForm.get('acceptableCommute').value == null || this.housingPrefForm.get('acceptableCommute').value == "")) ? payload['acceptableCommute'] = null :
      this.housingPrefForm.get('acceptableCommute').value ? payload['acceptableCommute'] = this.housingPrefForm.get('acceptableCommute').value : null;

    /**parking info */
    let parkingDat = {} as any;
    (dailogData.parkingInfo && dailogData.parkingInfo.financialResponsibility && (this.housingPrefForm.get('financialResponsibility').value == null || this.housingPrefForm.get('financialResponsibility').value == "")) ? parkingDat['financialResponsibility'] = null :
      this.housingPrefForm.get('financialResponsibility').value ? parkingDat['financialResponsibility'] = this.housingPrefForm.get('financialResponsibility').value : null;
    (dailogData.parkingInfo && dailogData.parkingInfo.comments && (this.housingPrefForm.get('parkingComments').value == null || this.housingPrefForm.get('parkingComments').value == "")) ? parkingDat['comments'] = null :
      this.housingPrefForm.get('parkingComments').value ? parkingDat['comments'] = this.housingPrefForm.get('parkingComments').value : null;
    parkingDat['parkingApproved'] = !this.housingPrefForm.get('parking').value;
    payload['parkingInfo'] = parkingDat;

    /**house keeping info */
    let housekeepingInfo = {} as any;
    (dailogData.housekeepingInfo && dailogData.housekeepingInfo.housekeepingFrequency && (this.housingPrefForm.get('housekeepingFrequency').value == null || this.housingPrefForm.get('housekeepingFrequency').value == "")) ? housekeepingInfo['housekeepingFrequency'] = null :
      this.housingPrefForm.get('housekeepingFrequency').value && this.showHousing ? housekeepingInfo['housekeepingFrequency'] = this.housingPrefForm.get('housekeepingFrequency').value : housekeepingInfo['housekeepingFrequency'] = null;
    (dailogData.housekeepingInfo && dailogData.housekeepingInfo.comments && (this.housingPrefForm.get('comments').value == null || this.housingPrefForm.get('comments').value == "")) ? housekeepingInfo['comments'] = null :
      this.housingPrefForm.get('comments').value && this.showHousing ? housekeepingInfo['comments'] = this.housingPrefForm.get('comments').value : housekeepingInfo['comments'] = null;
    payload['housekeepingInfo'] = housekeepingInfo;

    /**for submit */
    status === 'submit' ? submitWorkOrder = true : null;
    if( this.data.checkForStatus == 'addOnCancelled'){
      this.candidateProfileService.addOrderRequest(assessNeedsFormValues, payload, relocationDetailsReq,bookingAgentReq, submitWorkOrder, this.data.orderID).subscribe(data => {
        console.log("DATA for status", data);
        const response = typeof(data.message) ==='string' && data.message.includes('Failed to submit supplier orderRequest. Consultant Notified successfully') ? true : false;
        if (!response) {
          let currentDateTime = this.datepipe.transform((new Date), 'yyyy-MM-dd');
          assessNeedsFormValues['assessNeedsSubmittedDate'] = status == 'submit' ? currentDateTime : null;
          assessNeedsFormValues['assessNeedsDraft'] = status == 'draft' ? true : false;
          this.accompanyingMembers.forEach((member, index) => {
            this.accompanyingMembers[index] = this.familyDetails[this.familyDetails.findIndex(ele => ele.id === member)];
            !this.accompanyingMembers[index].isTravelling ? this.accompanyingMembers[index]['isTravelling'] = true : null;
          });
          if(status === 'draft') {
            this.showGenerateToaster()
            }
          this.spinner.hide();
          payload['housingPreferencesSubmittedDate'] = status == 'submit' ? currentDateTime : null;
        }else if(response){
          this.dialogRef.close({
            data:'null',
          action:'dismiss'
          });
        }
        this.dialogRef.close({
          data:'status',
          action:'dismiss'
        });
        this.spinner.hide();
      })
    }else{
    this.candidateProfileService.addAssessNeedsItem(assessNeedsFormValues, payload, relocationDetailsReq,bookingAgentReq, submitWorkOrder, this.data.orderID).subscribe(data => {
      console.log("DATA for status", data);
      if (data && !data.message.includes('Failed to submit supplier orderRequest. Consultant Notified successfully')) {


        let currentDateTime = this.datepipe.transform((new Date), 'yyyy-MM-dd');
        assessNeedsFormValues['assessNeedsSubmittedDate'] = status == 'submit' ? currentDateTime : null;
        assessNeedsFormValues['assessNeedsDraft'] = status == 'draft' ? true : false;
        this.accompanyingMembers.forEach((member, index) => {
          this.accompanyingMembers[index] = this.familyDetails[this.familyDetails.findIndex(ele => ele.id === member)];
          !this.accompanyingMembers[index].isTravelling ? this.accompanyingMembers[index]['isTravelling'] = true : null;
        });
        if(status === 'draft') {
          this.showGenerateToaster()
          }
        this.spinner.hide();
        payload['housingPreferencesSubmittedDate'] = status == 'submit' ? currentDateTime : null;
      }else if(data.message.includes('Failed to submit supplier orderRequest. Consultant Notified successfully')){
        this.dialogRef.close({
          data:{status:'null',suppOrderStatus:data.supplierOrderStatus},
        action:'dismiss'
        });
      }
      this.dialogRef.close({
        data:{status:'status',suppOrderStatus:data.supplierOrderStatus},
        action:'dismiss'
      });
      this.spinner.hide();
    })
  }
  }


  onClose(): void {
    this.dialogRef.close({
      action: 'dismiss'
    });
  }

  onCancel(): void {
    if(this.data.modification) {
      this.dialogRef.close({
        data: null,
        action: 'dismiss'
      })
    } else {
      this.dialogRef.close({
        action: 'dismiss'
      });
    }
  }

  selectMembers(event, member): void {
    console.log(event);
    if (event.checked) {
      !this.accompanyingMembers.find(ele => ele === member.id) ? this.accompanyingMembers.push(member.id) : null;
    } else {
      this.accompanyingMembers = this.accompanyingMembers.filter(ele => ele !== member.id);
    }
  }

  isNullCheck(obj: Object, key: string) {
    try {
      return ((obj[key] || obj[key] == false) && obj[key] !== null)  ? obj[key] : '';
    } catch (error) {
      return '';
    }
  }

  getTooltipContent(member) {
    return `Age is calculated based on when this information was provided to us, which was on ${member.age && member.age.recordedAtDate && member.age.recordedAtDate.split('T').shift()}`;
  }

  getTempTooltipContent() {
    return `Edits can be made in My Profile > Family Members`;
  }

  getTempTooltipTravelHistory() {
    return `Please use your home location if you have not travelled recently`;
  }

  checkForCheckList(id) {
    return this.data.details.needsAssessment.accompanyingMembers.find(ele => ele.id === id && ele.isTravelling) ? true : false;
  }

  onChange(enable: boolean) {
    this.show = !this.show;
  }

  onChangePetDeposit(enable: boolean) {
    if (enable) {
      this.PetdeposittoggleFlag = true;
    } else {
      this.PetdeposittoggleFlag = false;
    }
  }

  onDismiss(evt) {
    evt.preventDefault();
    this.dialogRef.close(null);
  }

  populateForm(): UntypedFormGroup {
    const housePrefData = this.data.details.housingPreference;
    const check = this.isNullCheck;
    const form = this.formBuilder.group({
      desiredLocation: this.formBuilder.group({
        desCountry: [housePrefData ? `${check(housePrefData.desiredLocation, 'country')}` : null],
        desOfficeLocation: ['', [Validators.required]],
        state: [housePrefData ? `${check(housePrefData.desiredLocation, 'state')}` : null],
        city: [housePrefData ? `${check(housePrefData.desiredLocation, 'city')}` : null],
        street: [housePrefData ? `${check(housePrefData.desiredLocation, 'streetLine1')}` : null],
        postalCode: [housePrefData ? `${check(housePrefData.desiredLocation, 'postalCode')}` : null],
      }),
      destinationLocation: this.formBuilder.group({
        destCountry: [housePrefData ? `${check(housePrefData.destinationOfficeLocation, 'country')}` : null],
        destOfficeLocation: ['', [Validators.required]],
        state: [housePrefData ? `${check(housePrefData.destinationOfficeLocation, 'state')}` : null],
        city: [housePrefData ? `${check(housePrefData.destinationOfficeLocation, 'city')}` : null],
        street: [housePrefData ? `${check(housePrefData.destinationOfficeLocation, 'streetLine1')}` : null],
        postalCode: [housePrefData ? `${check(housePrefData.destinationOfficeLocation, 'postalCode')}` : null],
      }),
      alternateLocation: this.formBuilder.group({
        altCountry: [housePrefData ? `${check(housePrefData.alternateLocation, 'country')}` : null],
        altOfficeLocation: ['', [Validators.required]],
        state: [housePrefData ? `${check(housePrefData.alternateLocation, 'state')}` : null],
        city: [housePrefData ? `${check(housePrefData.alternateLocation, 'city')}` : null],
        street: [housePrefData ? `${check(housePrefData.alternateLocation, 'streetLine1')}` : null],
        postalCode: [housePrefData ? `${check(housePrefData.alternateLocation, 'postalCode')}` : null],
      }),
      accommodationType: [housePrefData ? `${check(housePrefData, 'accommodationType')}` : ''],
      comments: [housePrefData && housePrefData.housekeepingInfo ? `${check(housePrefData.housekeepingInfo, 'comments')}` : ''],
      housekeepingFrequency: [housePrefData && housePrefData.housekeepingInfo && housePrefData.housekeepingInfo.housekeepingFrequency != null ? `${check(housePrefData.housekeepingInfo, 'housekeepingFrequency')}` : 'None'],
      moveInDate: [housePrefData && this.data.checkForStatus != 'addOnCancelled' && housePrefData.moveInDate ? this.setDateWithOffset(housePrefData.moveInDate) : '', [Validators.required]],
      moveOutDate: [housePrefData && this.data.checkForStatus != 'addOnCancelled' && housePrefData.moveOutDate ? this.setDateWithOffset(housePrefData.moveOutDate) : '', [Validators.required]],
      numberOfBedrooms: [housePrefData ? check(housePrefData, 'numberBedrooms') : '', [Validators.required]],
      numberOfBathrooms: [housePrefData ? check(housePrefData, 'numberOfBathrooms') : '', [Validators.required]],
      numberOfApartments: [housePrefData.numberApartments !=null ? check(housePrefData, 'numberApartments') : 1,[Validators.required, Validators.pattern(/^[1-9]\d*$/)]],
      babyCotRequired: [housePrefData && housePrefData.babyCotRequired ? !housePrefData.babyCotRequired : true],
      furnished: [housePrefData && housePrefData.furnished ? !housePrefData.furnished : true],
      kitchenRequired: [housePrefData && housePrefData.kitchenRequired ? !housePrefData.kitchenRequired : true],
      parking: [housePrefData.parkingInfo ? !housePrefData.parkingInfo.parkingApproved : true],
      financialResponsibility: [housePrefData.parkingInfo ? `${check(housePrefData.parkingInfo, 'financialResponsibility')}` : ''],
      parkingComments: [housePrefData.parkingInfo ? `${check(housePrefData.parkingInfo, 'comments')}` : ''],
      acceptableCommute: [housePrefData ? `${check(housePrefData, 'acceptableCommute')}` : null, [Validators.required, Validators.pattern(/^[1-9]\d*$/)]],
      additionalInstructions: [housePrefData ? check(housePrefData, 'additionalInstructions') : '', [Validators.required]],
      houseKeeping: [housePrefData && housePrefData.housekeepingInfo && ((housePrefData.housekeepingInfo.housekeepingFrequency && housePrefData.housekeepingInfo.housekeepingFrequency !== 'None') || housePrefData.housekeepingInfo.comments ) ? false : true],
      lengthOfStay: [housePrefData && this.data.checkForStatus != 'addOnCancelled' ? check(housePrefData, 'lengthOfStay') : 0, ],
      priorities: this.createPrioritiesListForm()
    });
    return form;
  }

  createPrioritiesListForm(): UntypedFormGroup {
    const isPriorityList = (this.data.details.housingPreference && this.data.details.housingPreference.priorities) ? true : false;
    const prioritiesGroup = this.formBuilder.group({
      priorityOne: [isPriorityList && this.data.details.housingPreference.priorities[0] ? this.data.details.housingPreference.priorities[0] : 'None', [Validators.required]],
      priorityTwo: [isPriorityList && this.data.details.housingPreference.priorities[1] ? this.data.details.housingPreference.priorities[1] : 'None', [Validators.required]],
      priorityThree: [isPriorityList && this.data.details.housingPreference.priorities[2] ? this.data.details.housingPreference.priorities[2] : 'None', [Validators.required]],
      priorityFour: [isPriorityList && this.data.details.housingPreference.priorities[3] ? this.data.details.housingPreference.priorities[3] : 'None', [Validators.required]],
      priorityFive: [isPriorityList && this.data.details.housingPreference.priorities[4] ? this.data.details.housingPreference.priorities[4] : 'None', [Validators.required]],
    });
    return prioritiesGroup;
  }

  /**runs for the first time when pop up loads */
  initPriorityList() {
    if (this.data.details.housingPreference && this.data.details.housingPreference.prioritiesValueList.length
      && this.data.details.housingPreference.priorities && this.data.details.housingPreference.priorities.length) {
      const usedPriorityList = this.data.details.housingPreference.priorities;
      for (let i = 0; i < 6; i++) {
        this.priorityList[i] = [...this.originalPriorityList];
        this.priorityList[i] = [...this.priorityList[i].filter(ele => !usedPriorityList.includes(ele))]
        usedPriorityList[i] ? this.priorityList[i].push(usedPriorityList[i]) : null;
      }
    } else {
      for (let i = 0; i < 6; i++) {
        this.priorityList[i] = [...this.originalPriorityList];
      }
    }
  }

  updatePriorityDropDownValues(index, fmcontrol) {
    const existingValues = this.getExistingValues();
    this.priorityList[index] = [...this.originalPriorityList.filter(ele => !existingValues.includes(ele))];
    this.housingPrefForm.get(['priorities', fmcontrol]).value !== 'None' ?
      this.priorityList[index].push(this.housingPrefForm.get(['priorities', fmcontrol]).value) : null;
  }

  getExistingValues() {
    let valueList = [];
    this.housingPrefForm.get('priorities').value.priorityOne !== 'None' ?
      valueList.push(this.housingPrefForm.get('priorities').value.priorityOne) : valueList.push('None');
    this.housingPrefForm.get('priorities').value.priorityTwo !== 'None' ?
      valueList.push(this.housingPrefForm.get('priorities').value.priorityTwo) : valueList.push('None');
    this.housingPrefForm.get('priorities').value.priorityThree !== 'None' ?
      valueList.push(this.housingPrefForm.get('priorities').value.priorityThree) : valueList.push('None');
    this.housingPrefForm.get('priorities').value.priorityFour !== 'None' ?
      valueList.push(this.housingPrefForm.get('priorities').value.priorityFour) : valueList.push('None');
    this.housingPrefForm.get('priorities').value.priorityFive !== 'None' ?
      valueList.push(this.housingPrefForm.get('priorities').value.priorityFive) : valueList.push('None');
    return valueList;
  }

  setContryDisplayName(formGroup, field, control) {
    const addressGroup: UntypedFormGroup = (this.housingPrefForm.controls[formGroup] as UntypedFormGroup);
    let value='';
    addressGroup.controls['street'].value ? value = value + addressGroup.controls['street'].value : null;
        addressGroup.controls['street'].value &&
        (addressGroup.controls['city'].value || addressGroup.controls['state'].value || addressGroup.controls['postalCode'].value || addressGroup.controls[control].value)
        ? value = value + ' , ' : null;
    addressGroup.controls['city'].value ? value = value + addressGroup.controls['city'].value : null;
        addressGroup.controls['city'].value &&
        (addressGroup.controls['state'].value || addressGroup.controls['postalCode'].value || addressGroup.controls[control].value)
        ? value = value + ' , ' : null;
    addressGroup.controls['state'].value ? value = value + addressGroup.controls['state'].value : null;
        addressGroup.controls['state'].value &&
        (addressGroup.controls['postalCode'].value || addressGroup.controls[control].value)
        ? value = value + ' , ' : null;
    addressGroup.controls['postalCode'].value ? value = value + addressGroup.controls['postalCode'].value : null;
        addressGroup.controls['postalCode'].value && addressGroup.controls[control].value  ? value = value + ' , ' : null;
    addressGroup.controls[control].value ? value = value + addressGroup.controls[control].value : null;
    addressGroup.controls[field].setValue(value);
  }

  getTooltip() {
    return `Priority requirements/special Instructions, Comments or questions, alternate transportation types`;
  }

  onNoClick(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialogRef.close();
  }
  onChangeParking(enable: boolean) {
    this.showParking = !this.showParking;
  }

  onChangeHousing(enable: boolean) {
    this.showHousing = !enable;
  }

  onStateAutocompleteSelected(result: PlaceResult, formGroup, field, control) {
    let index = 0;
    if(index == 0) {
      const addressGroup: UntypedFormGroup = (this.housingPrefForm.controls[formGroup] as UntypedFormGroup);
      addressGroup.reset();
      addressGroup.markAsDirty();
      index++;
    }
    this.updateDestinationAddresValue(result, 'state', formGroup, field, control);
    this.updateDestinationAddresValue(result, 'city', formGroup, field, control);
    this.updateDestinationAddresValue(result, 'street', formGroup, field, control);
    this.updateDestinationAddresValue(result, 'postalcode', formGroup, field, control);
    this.updateDestinationAddresValue(result, 'country', formGroup, field, control);
    this.setContryDisplayName(formGroup, field, control);
  }

  updateDestinationAddresValue(result: PlaceResult, fieldTriggered: string, formGroup: string, field: string, control: string) {

    let streetLine = '';
    const addressGroup: UntypedFormGroup = (this.housingPrefForm.controls[formGroup] as UntypedFormGroup);
    // addressGroup.reset();
    let isCity = false;
    result.address_components.forEach(element => {
      if (element.types[0] === 'country' && fieldTriggered === 'country') {
        addressGroup.controls[control].setValue(element.long_name);
        // this.postalCodes.setValue([element.long_name]);
      } else if (element.types[0] === 'postal_code') {
        addressGroup.controls['postalCode'].setValue(element.long_name);
        // this.postalCodes.setValue([element.long_name]);
      } else if (element.types[0] === 'administrative_area_level_1' &&
        (fieldTriggered === 'city' || fieldTriggered === 'street' || fieldTriggered === 'state' || fieldTriggered === 'postalcode')) {
        addressGroup.controls['state'].setValue(element.long_name);
      } else if ((element.types[0] === 'locality' || element.types[0] === 'sublocality_level_1' || element.types[0] === 'postal_town') && (fieldTriggered === 'city' || fieldTriggered === 'street' || fieldTriggered === 'postalcode')) {
        addressGroup.controls['city'].setValue(element.long_name);
        isCity = true;
      } else {
        if (!isCity && fieldTriggered === 'street') {
          streetLine = streetLine.length > 0 ?
            `${streetLine}, ${element.long_name}` : element.long_name;
          addressGroup.controls['street'].setValue(streetLine);
        }
      }
    });
    // addressGroup.controls['street'].setValue(streetLine);
  }

  setDateToUTC(date: Date) {
    return date.toISOString().split('T')[0].concat('T00:00:00.000Z');
  }

  displayFn(curr: any): string {
    return curr ? curr.value + ' - ' + curr.displayValue : '';
  }

  checkCompletedCount(countCompletedItem, status) {
    if (status == 'draft') {
      this.housingPrefForm.get('moveInDate').valid ? countCompletedItem++ : null;
      this.housingPrefForm.get('moveOutDate').valid ? countCompletedItem++ : null;
      this.housingPrefForm.get('numberOfBedrooms').valid ? countCompletedItem++ : null;
     // this.housingPrefForm.get('numberOfApartments').valid ? countCompletedItem++ : null;
      this.housingPrefForm.get('additionalInstructions').valid ? countCompletedItem++ : null;
      this.housingPrefForm.get('acceptableCommute').valid ? countCompletedItem++ : null;
    }
    return countCompletedItem;
  }

  checkValidationForPriority() {
    const len = this.getExistingValues();
    if (len.length == 0) {
      this.housingPrefForm.get('priorities').setErrors({ 'incorrect': true });
    } else {
      this.housingPrefForm.get('priorities').setErrors(null);
    }
    return len.length == 0 ? true : false;
  }

  onChangePetType(i) {
    this.assessNeedsForm.controls.petsInformation.get([i, 'breed']).setValue('')
    this.assessNeedsForm.controls.petsInformation.get([i, 'other']).setValue('')
  }

  getConsultantPhone() {
    if (this.data.details.consultantDetails.phoneDetailsList !== undefined && this.data.details.consultantDetails.phoneDetailsList.length > 0) {
      this.consultantPhoneDetails = { phoneDialingCode: this.data.details.consultantDetails.phoneDetailsList[0].phoneDialingCode, phoneNumber: this.data.details.consultantDetails.phoneDetailsList[0].phoneNumber };
    } else {
      this.consultantPhoneDetails = { phoneDialingCode: '', phoneNumber: '' };
    }
  }

  getConsultantEmail() {
    if (this.data.details.consultantDetails.emailDetailsList !== undefined && this.data.details.consultantDetails.emailDetailsList.length > 0) {
      this.consultantEmailDetails = { emailDetailsList: this.data.details.consultantDetails.emailDetailsList[0].emailAddress }
    } else {
      this.consultantEmailDetails = { emailDetailsList: '' }
    }
  }

  getCount() {
    if (!this.data.details.needsAssessment.departureLocation) this.completedCount++
    if (this.data.details.needsAssessment.travelHistory.length == 0) this.completedCount++
    return this.completedCount;
  }

  getRelocationCount() {
    if (this.data.details.customerRelocationDetails) {
      // (this.data.details.customerRelocationDetails.filePriority && this.data.details.customerRelocationDetails.filePriority !== null) ? null : this.completedItemCountRelocation++;
      (this.data.details.customerRelocationDetails.daysAuthorized && this.data.details.customerRelocationDetails.daysAuthorized !== null) ? null : this.completedItemCountRelocation++;
      (this.data.details.customerRelocationDetails.serviceDeliveryLocation && this.data.details.customerRelocationDetails.serviceDeliveryLocation !== null) ? null : this.completedItemCountRelocation++;
      (this.data.details.customerRelocationDetails.financialResponsibility && this.data.details.customerRelocationDetails.financialResponsibility !== null) ? null : this.completedItemCountRelocation++;
      (this.data.details.customerRelocationDetails.clientBudgetCurrency && this.data.details.customerRelocationDetails.clientBudgetCurrency !== null) ? null : this.completedItemCountRelocation++;
      (this.data.details.customerRelocationDetails.clientBudgetAmount && this.data.details.customerRelocationDetails.clientBudgetAmount !== null) ? null : this.completedItemCountRelocation++;
      (this.data.details.customerRelocationDetails.provider && this.data.details.customerRelocationDetails.provider !== null) ? null : this.completedItemCountRelocation++;
    }
    return this.completedItemCountRelocation;
  }


  checkHPCompletedCount() {
    if (this.data.details.housingPreference) {
      this.data.details.housingPreference.priorities.length > 0 ? null : this.completedItemCountHosing++;
      (this.data.details.housingPreference.moveInDate && this.data.checkForStatus != 'addOnCancelled' && this.data.details.housingPreference.moveInDate !== null) ? null : this.completedItemCountHosing++;
      (this.data.details.housingPreference.moveOutDate && this.data.checkForStatus != 'addOnCancelled' && this.data.details.housingPreference.moveOutDate !== null) ? null : this.completedItemCountHosing++;
      (this.data.details.housingPreference.numberBedrooms && this.data.details.housingPreference.numberBedrooms !== null) ? null : this.completedItemCountHosing++;
      (this.data.details.housingPreference.numberOfBathrooms && this.data.details.housingPreference.numberOfBathrooms !== null) ? null : this.completedItemCountHosing++;
      (this.data.details.housingPreference.additionalInstructions && this.data.details.housingPreference.additionalInstructions !== null) ? null : this.completedItemCountHosing++;
      (this.data.details.housingPreference.acceptableCommute && this.data.details.housingPreference.acceptableCommute !== null) ? null : this.completedItemCountHosing++;
      if (this.data.details.housingPreference.desiredLocation) {
        const loc = this.data.details.housingPreference.desiredLocation;
        (loc.country !== null || loc.city !== null || loc.state !== null || loc.streetLine1 !== null || loc.postalCode != null) ? null : this.completedItemCountHosing++;
      }
      if (this.data.details.housingPreference.destinationOfficeLocation) {
        const loc = this.data.details.housingPreference.destinationOfficeLocation;
        (loc.country !== null || loc.city !== null || loc.state !== null || loc.streetLine1 !== null || loc.postalCode != null) ? null : this.completedItemCountHosing++;
      }
      if (this.data.details.housingPreference.alternateLocation) {
        const loc = this.data.details.housingPreference.alternateLocation;
        (loc.country !== null || loc.city !== null || loc.state !== null || loc.streetLine1 !== null || loc.postalCode != null) ? null : this.completedItemCountHosing++;
      }
      if(!this.housingPrefForm.get('parking').value){
        (this.data.details.housingPreference.parkingInfo.financialResponsibility && this.data.details.housingPreference.parkingInfo.financialResponsibility !== null) ? null : this.completedItemCountHosing++;
      }
    }
    return this.completedItemCountHosing;
  }

  financialChange() {
    this.splitBillMonetaryAmount();
    if (this.relocationForm.get('relocationFinancialResponsibility').value == 'Split Bill' && !this.relocationForm.get('splitBillComments').value) {
      this.relocationForm.controls.splitBillComments.setErrors({ nullValue: true });
      this.splitBill = true;
    } else {
      this.relocationForm.controls.splitBillComments.setErrors(null);
      this.splitBill = false;
    }
  }

  splitBillMonetaryAmount() {
    if (this.relocationForm.get('relocationFinancialResponsibility').value == 'Split Bill' && !this.relocationForm.get('monetaryAmount').value && this.data.reimbursement) {
      this.relocationForm.controls.monetaryAmount.setErrors({ nullValue: true });
      this.relocationForm.controls.monetaryAmountWithCurrency.setErrors({ nullValue: true });
      this.splitBillMonetaryAmt = true;
    } else {
      this.relocationForm.controls.monetaryAmount.setErrors(null);
      this.relocationForm.controls.monetaryAmountWithCurrency.setErrors(null);
      this.splitBillMonetaryAmt = false;
    }
  }

  // splitBillValidation() {

  // }

  /** showGenerateToaster will show toastr with status message */
  showGenerateToaster() {
    let message = 'Draft Saved'
    this.toastrService.info(message, null,
      {
        closeButton: true,
        enableHtml: true,
        timeOut: 3000,
        progressBar: true,
        disableTimeOut: false,
      }
    );
  }

  disableForm(){
    if(this.data.orderStatus==='pending' || (this.data.orderStatus==='cancelled' && this.data.checkForStatus==='add') ||(this.data.details.orderStatus==='cancelled' && this.data.checkForStatus==='add') || (this.data.supplierRef && this.data.orderStatus==='cancelled')||(this.data.supplierRef && this.data.orderStatus==='in-progress')||(this.data.supplierRef && this.data.orderStatus==='complete')){
     this.assessNeedsForm.disable();
     this.relocationForm.disable()
     this.housingPrefForm.disable();
      this.disableToggle = true;
    if(this.data.details.orderStatus==='cancelled' || (this.data.supplierRef && this.data.orderStatus==='cancelled')) {
      this.cancelToggle = true;
    }

    }
  }

  moveInDateChange() {
    let date = new Date(this.housingPrefForm.get('moveInDate').value);
    this.minMoveDate = new Date(date);
    this.minMoveDate.setDate(date.getDate() + 1);
    this.housingPrefForm.get('moveOutDate').setValue('');
  }

  getLengthOfStay() {
    if(this.housingPrefForm.get('moveInDate').value && this.housingPrefForm.get('moveOutDate').value){
      let diff = Math.abs(this.housingPrefForm.get('moveInDate').value.getTime() - this.housingPrefForm.get('moveOutDate').value.getTime());
      this.diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      this.housingPrefForm.get('lengthOfStay').setValue(this.diffDays);
    }
  }


  /**download toaster */
  openDownloadToastr() {
    // tslint:disable-next-line: max-line-length
    const message = 'Downloading files with sensitive information is a security risk, please clear your cache afterwards';
    const toaster = this.toaster.open(message, 'DOWNLOAD', {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['toasterMessage']
    });
    toaster.onAction().subscribe(_ => this.downloadPdf());
  };

  /**pdf download service call */
  downloadPdf() {
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate, 'MMMM_dd_yyyy_HH_mm_ss', 'en-US');
    // open, pending, cancelled, in-progress, paused, complete, stopped, closed
    const name = `${this.data.orderID}-${formattedDate}`;
    let pdfName = ['open', 'in-progress', 'paused'].includes(this.data.details.orderStatus) ? `TempLiving-${name}-Draft` : `${name}`;

    let downloadData = {
      orderId: this.data.orderID,
      offering: this.data.offering,
      bookingAgent: this.data.details.bookingAgent,
      cartusConsultantInfo: this.data.details.consultantDetails,
      customerDetails: this.data.details.customerDetails,
      policyDetails: this.data.details.policyDetails,
      relocationDetails: this.data.details.customerRelocationDetails,
      customerNeedsAssessment: this.data.details.needsAssessment,
      housingInfo: this.data.details.housingPreference,
      financialDetails: this.data.details.financialDetails,
      status: this.data.details.orderStatus,
      clientDetails: this.data.clientDetails,
      transfereeDetails: this.data.transfereeDetails,
      familyDetails: this.data.details.needsAssessment.accompanyingMembers,
      currencieList: this.currencieList,
      spouseName: this.data.details.spouseName,
      submittedDate: this.data.details.supplierOrderSubmittedDate ? this.data.details.supplierOrderSubmittedDate : this.data.details.orderStatusDate,
      supplierId: this.data.supplierRef,
      checkId: this.disableToggle,
      orderStatusDate: this.data.details.orderStatusDate,
      isDraft: this.data.details.supplierOrderSubmittedDate ? this.data.details.supplierOrderSubmittedDate : null,
      addOnCancelled: this.data.checkForStatus
    }
    // (data.checkHybridTempliving && data.chckForModification) || (data.reimbursement && data.chckForModification)
    if(this.data && ((this.data.checkHybridTempliving && this.data.chckForModification) || (this.data.reimbursement && this.data.chckForModification)) ) {
      const modification = {
        'modificationData': this.data.supplierData,
        'reimbursement': this.data.reimbursement,
        'checkHybridTempliving': this.data.checkHybridTempliving,
        'rangeIncrementUnit': this.data.rangeIncrementUnit,
        'incrementalCurrencyUnit': this.data.incrementalCurrencyUnit,
      }
      downloadData['modification'] = modification;
    }
    pdfMake.createPdf(this.pdfgenerator.generateDocumentDefinition(downloadData)).download(`${pdfName}`);
  }

  onNumberValueValidation(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57
  }

  // accept orders
// orderAccepted(id,modID){
//   this.spinner.show();
//   this.candidateProfileService.acceptOrder(id,modID).subscribe(data=>{
//     if(data){

//     }
//   })
//   this.spinner.hide();
// }


//   // reject orders
//   orderRejected(id){
//     this.spinner.show();
//     this.candidateProfileService.rejectOrder(id).subscribe(data=>{
//       if(data){

//       }
//     })
//     this.spinner.hide();
//   }

  updateMonetaryAmountWithCurrency() {
    this.editMonetaryAmount = false;
    const cur = this.data.details.customerRelocationDetails.clientContribution && this.data.details.customerRelocationDetails.clientContribution.currencyCode ? true : false;
    if(this.relocationForm.get('monetaryAmount').value !== null && this.relocationForm.get('monetaryAmount').value !== '') {
      cur ? this.relocationForm.get('monetaryAmountWithCurrency').setValue(`${this.relocationForm.get('monetaryAmount').value} ${this.data.details.customerRelocationDetails.clientContribution.currencyCode}`)
      : this.relocationForm.get('monetaryAmountWithCurrency').setValue(`${this.relocationForm.get('monetaryAmount').value} USD`);
    } else {
      this.relocationForm.get('monetaryAmount').setValue(null);
      this.relocationForm.get('monetaryAmountWithCurrency').setValue(null);
    }
    this.splitBillMonetaryAmount()
  }

  setDateWithOffset(date) {
    let newDate = new Date(date);
    newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
    return newDate;
  }

}

import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-notification-pop-up',
  templateUrl: './notification-pop-up.component.html',
  styleUrls: ['./notification-pop-up.component.scss']
})
export class NotificationPopUpComponent implements OnInit {

  constructor(
    public dialog: MatDialogRef<NotificationPopUpComponent>,
    public live: LiveAnnouncer,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {}

  onDismissDialog(eve) {
    if (eve) {
      this.dialog.close({
        action: 'dismiss'
      })
    }
  }

}

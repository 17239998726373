import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-consultant-only-modal',
  templateUrl: './consultant-only-modal.component.html',
  styleUrls: ['./consultant-only-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConsultantOnlyModalComponent implements OnInit {
  selectedOption: string;
  isChecked: boolean = false;
  constructor(
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<ConsultantOnlyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    if (this.data.benefit.consultantBenefitInfo) { this.selectedOption = this.data.benefit.consultantBenefitInfo.qualifyingInput };
    (this.data.benefit.consultantBenefitInfo && this.data.benefit.consultantBenefitInfo.hasOwnProperty('activatedByDefault')) ? (this.isChecked = true) : (this.isChecked = false);
  }

  /**
	* to save
	*/
  save() {
    let data = {};
    (this.isChecked) ? data = {consultant: true, qualifyingInput : +this.selectedOption, activatedByDefault : true}
     : data = {consultant: true, qualifyingInput : +this.selectedOption};          
    this.dialogRef.close(data);
  }

  /**
	* To to cancel dialogbox
	*/
  cancel() {
    this.selectedOption = null;
    if (this.data.benefit.consultantBenefitInfo) {
      this.data.benefit.consultantBenefitInfo.consultant = this.data.benefit.consultantBenefitInfo.qualifyingInput ? true : false;
    }
    this.dialogRef.close(this.data.benefit.consultantBenefitInfo);
  }
  /**
   * to check and uncheck activate by default 
   */
   checkUncheckActivatedByDefault(isChecked) {
     this.isChecked = isChecked;
   }
}

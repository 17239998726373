import { Component, OnInit } from '@angular/core';

/** empty component necessary for accessing external links */
@Component({
  selector: 'app-extern-route',
  templateUrl: './extern-route.component.html',
  styleUrls: ['./extern-route.component.scss']
})
export class ExternRouteComponent {

  /** @ignore */
  constructor() { }
}

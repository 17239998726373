
<form class="dialog-container" [formGroup]="pointForm">
    <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px"
        fxLayoutGap.xs="0em" role="heading">
        <h2 class="divisions-title">Variable Benefit Choices</h2>
        <a class="close-icon" (click)="close()" title="close" role="button" aria-label="Close Program Modal"
            title="Close Program Modal">
            <mat-icon>close</mat-icon>
        </a>
    </div>

    <div class="pop-up-title">
        <div class="dialog-title-container">
            <div class="dialog-Wrapper">
                <div class="dialog-title-card">
                    <mat-card class="mat-elevation-z8 benefit-title">
                        <div>{{data.benefit?.displayName}}</div>
                        <img [src]="data.benefit?.iconLink" alt="Icon here" role="figure">
                    </mat-card>
                </div>
                <div class="dialog-title-Desc">
                    {{data.benefit?.description}}
                </div>
            </div>
        </div>
    </div>
    <!--Points per selection-->
    <div *ngIf="data.benefit?.pointsPerSelection && data.benefit?.advCashOutV2">
        <mat-card class="points-per-selection">
            <mat-card-content fxLayout="row" class="points">
                <h4 fxFlex="20%"> Points Per Selection </h4>
                <div class="points-input"><input formControlName="pointsPerSelection" type="number" matInput number-only
                        tabindex="0" number required aria-label="Points Per Selection"></div>
            </mat-card-content>
        </mat-card>
    </div>

    <!-- middle Section  header-->
    <div class="middle-card-section" *ngIf="singleSelectTier; else cashOut">
        <div class="card">
            <mat-card>
                <mat-card-header class="card-header header-singleTier">
                    <mat-card-title>
                        <div fxLayout="row wrap">
                            <h4 fxFlex="0 1 calc(33.3% - 32px)" class="head-description">Description</h4>
                            <h4 fxFlex="0 1 calc(33% - 32px)" class="head-points">Points</h4>
                            <div fxFlex="0 1 calc(33% - 32px)" class="delete-icon single-delete-btn">
                                <mat-icon>delete</mat-icon>
                            </div>
                        </div>
                    </mat-card-title>
                </mat-card-header>
            </mat-card>
        </div>
    </div>
    <ng-template #cashOut>
        <div class="middle-card-section">
            <div class="card">
                <mat-card>
                    <mat-card-header class="card-header">
                        <mat-card-title>
                            <div fxLayout="row">
                                <h4 fxFlex="25%">Max Selections</h4>
                                <h4 fxFlex="17%" class="selection-range-style">Selection Range</h4>
                                <h4 fxFlex="18%">Value Per Point ({{currencyCode}})</h4>
                                <h4 class="total-value-style" fxFlex="20%">Total Value to Transferee</h4>
                                <mat-icon *ngIf="!readonlyMode" class="delete-icon" fxFlex="46.5%">delete</mat-icon>
                            </div>
                        </mat-card-title>
                    </mat-card-header>
                </mat-card>
            </div>
        </div>
    </ng-template>



    <!-- middle Section main content -->
    <div *ngIf="singleSelectTier; else cashoutSection">
    <form [formGroup]="singleSelectedTierForm">
        <div class="scroll">
                  <div class="middle-section">
                        <div formArrayName="Rows" cdkDropList class="example-list" (cdkDropListDropped)="drop($event)"
                        >
                            <mat-card *ngFor="let itemrow of singleSelectedTierForm.get('Rows')['controls']; let i=index;let l=last"
                            cdkDrag     
                            class="example-box" [ngClass]="{'icon-disabled': readonlyMode}"
                            >
                        <mat-card-content>
                            <div [formGroupName]="i">                
                                <!-- <div class="drag-placeholder" *cdkDragPlaceholder></div> -->
                                <div class="drag-content">
                                    <mat-icon [ngClass]="{'icon-disabled': readonlyMode}" class="dot-handler" cdkDragHandle>drag_indicator</mat-icon>
                                </div>
                                      <div fxFlex="34%" class="single-select">
                                        <input type="text" formControlName="displayValue" class="form-control" #description (keyup)="onInputChange(description.value)" >
                                      </div>
                                      <div fxFlex="34%" class="single-select single-select-value">
                                        <input type="number" formControlName="value"  value="singleSelectedTierValue" class="form-control" readonly>
                                       <div fxFlex="20%">
                                        <mat-icon class="up-down-arrow" (click)="increasePoints(itemrow.value,i)">arrow_drop_up</mat-icon>
                                        <mat-icon  class="up-down-arrow" (click)="decreasePoints(itemrow.value,i)">arrow_drop_down</mat-icon>
                                       </div>
                                      </div>
                                      <div fxFlex="19%" class="delete-icon single-select-value">
                                        <mat-icon *ngIf="!readonlyMode"  (click)="removeSingleSelectedTier(i)">delete_outline</mat-icon>
                                        <mat-icon *ngIf="readonlyMode" class="icon-disable-color">delete</mat-icon>
                                      </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                        </div>
                  </div>
                </div>
            <button
            *ngIf="!(((userRole !== 'program-admin' && userRole !== 'product-mgmt-admin') || ( !data.program.isNew && !data.program.draft && (userRole === 'program-admin' || userRole === 'product-mgmt-admin')) ))"
            mat-button class="text-button main-btn" [disabled]="isDisable" (click)="addSingleSelectedTier()">+ Add
        </button>
</form>
</div>
    
    <ng-template #cashoutSection>
        <div class="scroll">
            <mat-card>
                <mat-card-content>
                    <div class="middle-section">
                        <div *ngIf="pointForm.controls" formArrayName="pointsArray">
                            <div *ngFor="let pointSection of getPointSections(pointForm); let j = index" fxLayout="row"
                                [formGroupName]="j">
                                <mat-select fxFlex="14%" formControlName="maxSelection" class="selection-max">
                                    <mat-option *ngFor="let item of numList" [value]="item">{{item}}</mat-option>
                                </mat-select>
                                <div class="range-selection" fxFlex="10%">
                                    <p *ngIf="pointSection.get('maxSelection').value">{{
                                        calculateRange(pointSection.get('maxSelection').value, j) }}</p>
                                </div>

                                <div class="value-point-style" fxFlex="14%">
                                    <input matInput class="value-input" min="0" type="number" formControlName="value">
                                </div>

                                <div class="total-value" fxFlex="30%">
                                    <p>{{ calculatePoints(j) }} {{currencyCode}}</p>
                                </div>

                                <div *ngIf="!readonlyMode" class="active-delete-style" fxFlex="6.5%">
                                    <mat-icon (click)="deletePoints(j)">delete_outline</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <button
            *ngIf="!(((userRole !== 'program-admin' && userRole !== 'product-mgmt-admin') || ( !data.program.isNew && !data.program.draft && (userRole === 'program-admin' || userRole === 'product-mgmt-admin')) ))"
            mat-button class="text-button main-btn" [disabled]="disableAdd()" (click)="getPointsSections()">+ Add
        </button>
        </div>
    </ng-template>
       
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end"
        *ngIf="!(((userRole !== 'program-admin' && userRole !== 'product-mgmt-admin') || ( !data.program.isNew && !data.program.draft && (userRole === 'program-admin' || userRole === 'product-mgmt-admin')) ))">
        <button mat-button class="text-button rest-Btn" (click)="close()">CANCEL </button>
        <div *ngIf="singleSelectTier;else cashOutSave">
            <button mat-button class="contained-button" [disabled]="isDisable"
            (click)='save()'>
                Save
            </button>
        </div>
        <ng-template #cashOutSave>
            <button mat-button class="contained-button" [disabled]="pointForm.status === 'INVALID'"
            (click)='save()'>Save</button>
        </ng-template>
    </mat-dialog-actions>
</form>


import { Component, OnInit, HostListener, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { OverlayRef, Overlay } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { SortDirection, MatSort, MatSortHeaderIntl } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DateConversionService } from './../../../../core/services/date-conversion.service';
import { AddEditTransfereeComponent } from '../add-edit-transferee/add-edit-transferee.component';
import { TransfereeDetailComponent } from '../../modules/transferee/transferee-detail/transferee-detail.component';
import { candidateProfile } from './../../../../core/models/candidate.model';
import { SelectionModel } from '@angular/cdk/collections';
import { map } from 'rxjs/operators';
import { UserType, UserTypeService } from '../../services/user-type.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GetClientOptions, ClientService } from '../../services/client.service';
import { AdminClient } from './../../../../core/models/admin-client.model';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ShowOverrideHistoryComponent } from '../show-override-history/show-override-history.component';
import * as transfereeColumnDisplay from '../../../../core/data/transfereeColumnDisplay.json';
import { ViewProgamDetailsComponent } from '../view-program-details/view-program-details-component';
import {SearchDuplicateCheckComponent } from '../search-duplicate-check/search-duplicate-check.component';
import { ClientContactsService } from '../../services/client-contacts.service';
import { EmployeeDetailsService } from '../../../../core/services/employee-details.service';
import { FeatureFlagService } from '../../../../core/services/feature-flag.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}

interface PageFilter {
  filters: any,
  searchText?: string
}

export const FSU_BB_STATUS = 'Authorization Initiated, Initial Contact Pending, Draft, Invitation Sent or In Progress';
export const FSU_Traditional_STATUS = 'Audit Pending, Audit Complete, Authorization Initiated, Initial Contact Pending or Draft';
export const CCI_BB_STATUS = 'Draft or Invitation Sent';
export const CCI_Traditional_STATUS = 'Draft';
export const CSM_BB_STATUS = 'Initial Contact Pending, Invitation Sent or In Progress';
export const CSM_Traditional_STATUS = 'In Progress';

@Component({
  selector: 'app-transferee-list',
  templateUrl: './transferee-list.component.html',
  styleUrls: ['./transferee-list.component.scss']
})
 
export class TransfereeListComponent implements OnInit, OnChanges {

  /** overlayRef to hold overlay config */
  overlayRef: OverlayRef;

  @Input() candidateList: Array<candidateProfile> = [];

  /** Contains the Total Candidates Count */
  @Input() candidatesCount: number = 0;
  /** displayedColumns variable array to store the displayed columns */
  displayedColumns: string[] = [];//['fullname', 'program', 'pointsAssigned', 'departure', 'destination', 'status'];

  /** dataSource variable to store the response converted to mat table datasource */
  dataSource: MatTableDataSource<any>;

  /** selection variable to store the collection of selections made in mat table */
  selection: SelectionModel<any>;

  searchValue: string;

  /** hide authorization */
  enableAuthorization = false;

  /** page event variable to handle the page events */
  pageEvent: PageEvent;
  cpPageEvent: PageEvent;

  /**Direction varialbe to get sort direction */
  direction: SortDirection;

  /** flag for transferee Detail View */
  @Output() transfereeDetailView = new EventEmitter<boolean>();
  @Output() cpTransfereeDetailView = new EventEmitter<boolean>();

  /** flag for transferee Detail View */
  detailView: boolean;

  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /** To paginate in a mat table */
  @ViewChild('MatPaginator') paginator: MatPaginator;
  @ViewChild('MatPaginatorCp') paginatorCp: MatPaginator;

  /**store the filter value */
  filterText = '';

  /** To get the current filter status */
  @Input() filterObj;

  /** To get the search text entered in table search */
  @Input() searchKeyword;

  /** To get the boolean value to display no results message */
  @Input() clearResults;

  /** Output the getClearCriteriaChange boolean value */
  @Output() getClearCriteriaChange = new EventEmitter<boolean>();

  /** To get the boolean value on whether the search box has been triggered for results.
   * This determines whether to display the highlighted element in the Milestone column and hide
   * the non-highlighted element */
  @Input() searchTriggered;

  /** sortDirection to hold the asc or desc direction value */
  sortDirection: 'ASC' | 'DESC' | null;

  /** sortColumnName to hold the clicked column name for sorting */
  sortColumnName: 'fullName' | 'departure' | 'destination' | 'status' | 'authorizationDate' | null;

  /** sortOptions to hold the sort column name and sort direction */
  sortOptions : any = {};
  cpSortOptions: any = {};

  /** Contains the Filter Option for the Current Selected Tab */
  currentFilterOptions: GetClientOptions = {};

  transferee: candidateProfile;

  /** Output the sortOptions */
  @Output() sortList = new EventEmitter<{
    sortBy: string,
    sortDirection: string
  }>();

  @Output() cpsortList = new EventEmitter<{
    sortBy: string,
    sortDirection: string
  }>();

  @Output() refreshList = new EventEmitter<boolean | {
    sortBy ?: string,
    sortDirection ?: string,
    searchProperties?: any[],
    filters ?: any,
    searchText?: string,
    itemsPerPage?: number,
    pageNumber?:number
  }>();

  @Output() cpRefreshList = new EventEmitter<boolean | {
    sortBy ?: string,
    sortDirection ?: string,
    searchProperties?: any[],
    filters ?: any,
    searchText?: string,
    itemsPerPage?: number,
    pageNumber?:number
  }>();

  @Output() closeFilterChips = new EventEmitter();

  @Output() changeTab : EventEmitter<any> = new EventEmitter();
  
  @Output() searchCandidates = new EventEmitter <PageFilter>();

  @Output() deactivateTransferees = new EventEmitter();

  /** role of current logged in user to display relevant views */
  @Input() userRole: UserType;

  /** to check bb role to allow to create traditional transferee */
  bbRole: Array<String> = [];
  /**transferee column display details */
  columnsDisplay: any = (transfereeColumnDisplay as any).default;

  /** to check costprojection type */
  @Input() isCostProjection: boolean;

  /** List of Clients */
  clientList: Array<AdminClient> = [];
  searchValueForClient: any;

  /* Managemove client */
  isMMUser :boolean = false;
  manageAuthorizationsCapability: any;
  manageAuthorization: number;
  viewSubmitAuthCapability: any;
  transfereeDetailAdmCapability: any;
  favUser = 0;
  tooltipContent: SafeHtml;
  deactivateTransfereeCapability: string;
  bbStatus: string;
  traditionalStatus: string;

  constructor(
    public dialog: MatDialog,
    private readonly matSortService: MatSortHeaderIntl,
    public readonly dateConversionService: DateConversionService,
    public readonly clientService: ClientService,
    private spinner: NgxSpinnerService,
    private announcer: LiveAnnouncer,
    private readonly clientContactsService: ClientContactsService,
    private userTypeService: UserTypeService,
    public readonly employeeService: EmployeeDetailsService,
    private featureFlagService: FeatureFlagService,
    private sanitizer: DomSanitizer
  ) {
    this.dataSource = new MatTableDataSource<any>(this.candidateList);
    this.selection = new SelectionModel<any>(true, []);
    this.pageEvent = new PageEvent();
    this.pageEvent.pageIndex = 0;
    this.pageEvent.pageSize = 75;
    this.cpPageEvent = new PageEvent();
    this.cpPageEvent.pageIndex = 0;
    this.cpPageEvent.pageSize = 75;
    // tslint:disable-next-line: arrow-return-shorthand
    // this.matSortService.sortButtonLabel = (id: string) => { return `${id}`; };
    
    
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // tslint:disable-next-line: deprecation
    if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
      this.paginator.nextPage();
    }
    // tslint:disable-next-line: deprecation
    if (event.keyCode === KEY_CODE.LEFT_ARROW) {
      this.paginator.previousPage();
    }
  }

  // clientsDetails needs to be passed into transferee-details comp for download func
  @Input() clientDetails: AdminClient ;

  ngOnInit() {
    this.checkRole()
    this.userTypeService.capabilities$.subscribe(ele => {
      ele ? this.checkCapabilities(ele) : null;
    });
    // call feature flag service to disable authorization for google clients
    this.getAuthorizationFeatureFlag();
    // this.featureFlagService.getFeatureFlag('movepro360-enable-authorizations').toPromise().then((val) => { 
    //   this.enableAuthorization = (val.toString() === 'true');
    // });
    // fix for check box display for client-contact-initiator
    this.detailView = false;
    if (this.userRole === 'file-setup-specialist' || this.userRole === 'program-admin') {
      this.isMMUser  = this.clientContactsService.isMMUser;
    } else {
      this.isMMUser = JSON.parse(sessionStorage.getItem("car-ses-mmu"));
    }
    if (this.displayedColumns.length === 0) {
      const colConfig = this.columnsDisplay.filter((item) => item.preferencesetting.name == "TransfereeListViewColumnDisplay");
      this.displayedColumns = [...colConfig[0].preferencesetting.value];
      if(this.isMMUser) {
        this.displayedColumns = this.displayedColumns.filter((items) => !items.includes('pointsAssigned'))
      } else {
        this.displayedColumns = this.displayedColumns.filter((items) => !items.includes('totalAmount'))
      }
      this.displayedColumns.unshift('favourite');
    }
    if(this.deactivateTransfereeCapability === 'write' && !this.displayedColumns.includes('select')) {
      this.displayedColumns.unshift('select');
    }
    
    this.dataSource = new MatTableDataSource<any>(this.candidateList);
    this.selection = new SelectionModel<any>(true, []);
    // this.transfereeDetailView.emit(false);
    this.isCostProjection ? this.cpTransfereeDetailView.emit(false) : this.transfereeDetailView.emit(false);
    
    //setTimeout(() => {
      // this.dataSource.paginator = this.paginator;
      // this.paginator.length = this.candidatesCount;
      // this.paginator.pageIndex = this.pageEvent.pageIndex;
      // this.paginator.pageSize = this.pageEvent.pageSize;
      if(this.isCostProjection) {
      this.dataSource.paginator = this.paginatorCp;
      this.paginatorCp.length = this.candidatesCount;
      this.paginatorCp.pageIndex = this.cpPageEvent.pageIndex;
      this.paginatorCp.pageSize = this.cpPageEvent.pageSize ? this.cpPageEvent.pageSize : null;
      } else {
        this.dataSource.paginator = this.paginator;
        if(this.paginator){
        this.paginator.length = this.candidatesCount;
        this.paginator.pageIndex = this.pageEvent.pageIndex;
        this.paginator.pageSize = this.pageEvent.pageSize ? this.pageEvent.pageSize : null;
        const paginatorIntl = this.paginator._intl;
        paginatorIntl.nextPageLabel = 'Next';
        paginatorIntl.previousPageLabel = 'Previous';
        }
      }
      // const paginatorIntl = this.paginator._intl;
      // paginatorIntl.nextPageLabel = 'Next';
      // paginatorIntl.previousPageLabel = 'Previous';
    //}, 10);
    this.searchKeyword = '';
    this.searchValue = '';
    this.getStatusBasedOnRole();
    this.tooltipContent = this.generateToolTipContent()
  }

  async getAuthorizationFeatureFlag() {
    this.enableAuthorization = await this.featureFlagService.getAuthorizationFeatureFlag();
  }

  checkCapabilities (capabilitiesList: any []) {
    capabilitiesList.forEach(capability => {
      if(capability && capability.permission === 'allow') {
        if (capability.name === 'Manage Authorizations') {
          this.manageAuthorizationsCapability = capability.operation;
        } else if (capability.name === 'MP - View Submitted Transferee Authorization') {
          this.viewSubmitAuthCapability = capability.operation;
        } else if(capability.name ==="MP - Transferee Details Administration") {
          this.transfereeDetailAdmCapability = capability.operation;
        } else if(capability.name ==="MP - Deactivate Transferee") {
          this.deactivateTransfereeCapability = capability.operation;
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    let pageIndex = this.pageEvent.pageIndex
    let cpPageIndex = this.cpPageEvent.pageIndex
    if (changes && changes.candidateList && changes.candidateList.currentValue) {
      this.dataSource.data = changes.candidateList.currentValue;
      this.isCostProjection ? this.dataSource.paginator = this.paginatorCp : this.dataSource.paginator = this.paginator
      setTimeout(() => {
        //this.paginator.pageIndex = pageIndex;
        if(this.isCostProjection) {
        this.paginatorCp.length = this.candidatesCount;
        this.paginatorCp.pageIndex = cpPageIndex;
      } else {
        this.paginator.length = this.candidatesCount;
        this.paginator.pageIndex = pageIndex;
      }
      }, 10);
      this.selection.clear();
    }
    if (changes && changes.candidatesCount && changes.candidatesCount.currentValue) {
      //this.paginator ? this.paginator.pageSize = this.pageEvent.pageSize : null;
      //this.isCostProjection && this.paginator ? this.paginator.pageSize = this.cpPageEvent.pageSize : this.paginator ? this.paginator.pageSize = this.pageEvent.pageSize : null;
      if(this.isCostProjection) {
        this.paginatorCp ? this.paginatorCp.pageSize = this.cpPageEvent.pageSize : null;
      } else {
        this.paginator ? this.paginator.pageSize = this.pageEvent.pageSize : null;
      }
      //this.dataSource.paginator = this.paginator;
      this.isCostProjection ? this.dataSource.paginator = this.paginatorCp : this.dataSource.paginator = this.paginator
      setTimeout(() => {
        //this.paginator.length = this.candidatesCount;
        // this.paginator.pageIndex = this.pageEvent.pageIndex;
        if(this.isCostProjection) {
          this.paginatorCp.length = this.candidatesCount;
          this.paginatorCp.pageIndex = cpPageIndex;
        } else {
          this.paginator.length = this.candidatesCount;
          this.paginator.pageIndex = pageIndex;
        }
      }, 10);
    }
  } 

  
  /**to check bb role  */
  checkRole() {
    let clientPartyId;
    if (this.userRole === 'file-setup-specialist' || this.userRole === 'relocation-accounting-analyst') {
      this.clientContactsService.clientId.subscribe(r => clientPartyId = r);
    }
    if (this.userRole === 'client-contact-initiator') {
      clientPartyId = sessionStorage.getItem("cc_clientPartyId");
    }
    if(this.clientService.bbRole && this.clientService.bbRole.length > 0) {
      this.bbRole = this.clientService.bbRole;
    } else {
        this.clientService.getBbRole(clientPartyId).subscribe(res => {
        if (res) {
          res.loginUserRoleCapabilities.length ?
            res.loginUserRoleCapabilities.forEach(element => {
              if (element.clientRoles[0].managedMove ) {
                this.clientContactsService.isMMUser = true;
                sessionStorage.setItem('car-ses-mmu', JSON.stringify(true));
              }
              if (element.clientRoles[0].pointsMatrix) {
                this.clientContactsService.isGOUser = true;
                sessionStorage.setItem('car-ses-goa', JSON.stringify(true));
              }
              if (element.clientRoles[0].benefitsBuilder ) {
                this.bbRole.push('benefitsBuilder');
              }
              if (element.clientRoles[0].traditionalAuthorization ) {
                this.bbRole.push('traditionalAuthorization');
              }
              if (element.clientRoles[0].costProjection ) {
                this.bbRole.push('costProjection');
              }
              if (element.clientRoles[0].sso) {
                this.bbRole.push('sso');
              }
              if (element.clientRoles[0]?.lumpSum?.lumpSumEnabled) {
                this.bbRole.push('lumpSum');
              }
            }) : null;
        }
      }, err => {
        console.log(err);
        });
    }
  }

  /**Function to open the mat Dialog on click of add */
  openDialog(isTraditional) {
    const dialogRef = this.dialog.open(AddEditTransfereeComponent, {
      data: {
              isTraditional: isTraditional,
              isCostProjection: this.isCostProjection
            },
      // panelClass: 'dialog-full-width',
      width: '60vw',
      id: 'addEditDialog'
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) { this.isCostProjection ? this.cpRefreshList.emit(true) : this.refreshList.emit(true) }
    });
  }

  /**to open search duplicate pop up */
  openDialoge(): void {
    const dialogRef = this.dialog.open(SearchDuplicateCheckComponent,{
      width: '52vw',
      data: {
        bbRole : this.bbRole,
        isCostProjection: this.isCostProjection,
      } 
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) { this.isCostProjection ? this.cpRefreshList.emit(true) : this.refreshList.emit(true) }
    });
}

  editDialog(candidate: candidateProfile, tableColumn) {
    if (candidate.moveStatus === 'In Progress' || 
        candidate.moveStatus === 'Benefits Confirmed' || 
        candidate.moveStatus === 'Initial Contact Pending' || 
        candidate.moveStatus === 'Initial Contact Complete'||
        candidate.moveStatus === 'Audit Pending'||
        candidate.moveStatus === 'Audit Complete'|| 
        (candidate.moveStatus ==='Invitation Sent' && tableColumn == 'fullname') ||
        candidate.moveStatus ==='Withdrawn') {
    this.isCostProjection ? this.cpTransfereeDetailView.emit(true) : this.transfereeDetailView.emit(true);
      this.detailView = true;
      this.transferee = candidate;
    } else {
      const dialogRef = this.dialog.open(AddEditTransfereeComponent, {
        id: 'EditDialog',
        width: '60vw',
        data: {
                candidate: candidate,
                editTransferee: true,
                isCostProjection: this.isCostProjection,
              },
        // panelClass: 'dialog-full-width'
      });
      dialogRef.afterClosed().subscribe(data => {
        if (data) { 
          this.dataSource.data[this.dataSource.data.findIndex(ele => ele.orderRequestId === data.orderRequestId)] = { ...data };
          if(this.isCostProjection) {
            this.refreshList.emit({
              itemsPerPage: this.paginatorCp.pageSize,
              pageNumber: this.paginatorCp.pageIndex
            });
          } else {
            this.refreshList.emit({
              itemsPerPage: this.paginator.pageSize,
              pageNumber: this.paginator.pageIndex
            });
          }
          data.status == 'Approved' ? this.changeTab.emit('changeTab') : null;
        }
      });
    }
  }

  isFullNameEditable(element: any): boolean {
    const nonEditableStatuses = [
      'Invitation Not Sent',
      'Authorization Initiated',
      'In Review'
    ];
  
    return this.transfereeDetailAdmCapability === 'read' && nonEditableStatuses.includes(element.moveStatus);
  }

  viewProgramDetails(program: any) {
      const dialogRef = this.dialog.open(ViewProgamDetailsComponent, {
        id: 'ViewProgramDialog',
        data: program,
        panelClass: 'dialog-full-width'
      });
      dialogRef.afterClosed().subscribe(data => {
          data ? this.clientService.tabIndex.next(program.programDetails.programName) : null;
      });
    
  }

  /*******Function to show points override history */
  showOverrideHistory(candidate: candidateProfile) {
    const dialogRef = this.dialog.open(ShowOverrideHistoryComponent, {
      id: 'ShowOverrideHistoryDialog',
      data: candidate,
      panelClass: 'dialog-full-width',
      width: '25vw'
    });
  }

  // localhost:4100/v1/admin/candidate?sortBy=fullName&sortDirection=DESC
  /**
   * Sort table based on Column name.
   * @param columnName Column name to be sorted.
   */
  applySort(columnName: string) {
    this.initSort(columnName);
    if (this.searchKeyword || (this.filterObj && this.filterObj.length)) {
      this.searchCandidates.emit({ filters: this.filterObj, searchText: this.searchKeyword });
    } else {
      if(this.isCostProjection){
        this.cpRefreshList.emit({
          itemsPerPage: this.paginatorCp.pageSize,
          pageNumber: this.paginatorCp.pageIndex,
          sortBy : this.cpSortOptions.sortColumnName,
          sortDirection :this.cpSortOptions.sortDirection
        });
      } else {
        this.refreshList.emit({
          itemsPerPage: this.paginator? this.paginator.pageSize : 0,
          pageNumber: this.paginator ? this.paginator.pageIndex : 0
        });
      }
    }
  }

   /**
   * Init Sort ..
   * @param columnName Column name to be sorted.
   */
  initSort(columnName: string): void {
    if (this.sortColumnName === columnName) {
      if (this.sortDirection === 'ASC') {
        this.sortDirection = 'DESC';
        this.announcer.announce('Descending ' + columnName, 'assertive');
      } else {
        this.sortDirection = 'ASC';
        this.announcer.announce('Ascending ' + columnName, 'assertive');
      }
    } else {
      this.sortColumnName = columnName as any;
      this.sortDirection = 'ASC';
      this.announcer.announce('Ascending ' + columnName, 'assertive');
    }
    if(this.isCostProjection){
       this.cpSortOptions['sortColumnName']= this.sortColumnName;
       this.cpSortOptions['sortDirection'] = this.sortDirection;
    }else{
    this.sortOptions['sortColumnName'] = this.sortColumnName;
    this.sortOptions['sortDirection'] = this.sortDirection;
  }
}
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    // tslint:disable-next-line: triple-equals
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  closeTransferee() {
    this.closeFilterChips.emit();
    this.detailView = false;
    this.ngOnInit();
    this.isCostProjection ? this.cpRefreshList.emit(true) : this.refreshList.emit()
    this.isCostProjection ? this.cpTransfereeDetailView.emit(false) : this.transfereeDetailView.emit(false);
  }

  /**
     * Search for Clients
     * @param searchText Search Text Value
     */
  textSearchClients(searchText: string) {
    if (searchText && searchText.length >= 3) {
      this.searchValueForClient = searchText;
      const newOptions = Object.assign({ searchText }, this.currentFilterOptions);
      this.getClientsList(newOptions);
    }
  }

  /**
   * Gets the List of Clients;
   */
  getClientsList(options?: GetClientOptions) {
    this.spinner.show();

    if (this.searchValue) {
      options.searchText = this.searchValue;
    }

    // Persisting the value
    this.currentFilterOptions = options;

    this.clientService.getClients(options).subscribe(res => {
      if (res) {
        this.clientList = res.clients;
        this.spinner.hide();
      } else {
        this.clientList = [];
        this.spinner.hide();
      }
    }, err => {
      this.clientList = [];
      this.spinner.hide();
    });
  }

  updateDisplayedColumn(displayCol) {
    this.displayedColumns = displayCol;
    this.displayedColumns.unshift('favourite');
    (((this.userRole === 'client-contact-initiator' || this.userRole === 'file-setup-specialist') && !this.displayedColumns.includes('select')) ?
    this.displayedColumns.unshift('select') : null);
  }

  onPageChange(e) {
    if (this.pageEvent && e.pageSize && this.pageEvent.pageSize !== this.pageEvent.pageSize) {
      this.pageEvent.pageIndex = 0;
    } else {
      this.pageEvent.pageIndex = e.pageIndex;
    }
    if (this.searchKeyword || this.filterObj.length) {
      this.searchCandidates.emit({filters:this.filterObj,searchText:this.searchKeyword});
    } else {
        this.refreshList.emit({
        itemsPerPage: this.paginator.pageSize,
        pageNumber: this.paginator.pageIndex
      });
    }
  }

  onCpPageChange(e) {
    if (this.cpPageEvent && e.pageSize && this.cpPageEvent.pageSize !== this.cpPageEvent.pageSize) {
      this.cpPageEvent.pageIndex = 0;
    } else {
      this.cpPageEvent.pageIndex = e.pageIndex;
    }
    if (this.searchKeyword || this.filterObj.length) {
      this.searchCandidates.emit({filters:this.filterObj,searchText:this.searchKeyword});
    } else {
    this.cpRefreshList.emit({
      itemsPerPage: this.paginatorCp.pageSize,
      pageNumber: this.paginatorCp.pageIndex
    });
    }
  }

  updateFavoriteTransferee(orderRequestId, favUser, index) {
    const favoriteParam = {
      favoriteData: [{
        favUser: favUser == 0 ? 1 : 0,
        value: orderRequestId
      }],
      type: 'transferee'
    };
    this.employeeService.updateFavorite(favoriteParam)
      .subscribe((response) => {
        if (response) {
          if (favUser) {
            this.dataSource.data[index].favUser = 0;
          } else {
            this.dataSource.data[index].favUser = 1;
          }
        }
      });
  }

  generateToolTipContent() {
    const toolTipContent = `
    <h2 class="center">Guidance Information</h2>
    <p>To enable Deactivate Transferee, select one or more transferees.</p>
      <ol type="i">
        <li class="p-b-10">For BB Transferees:
          <br>
          <span>Benefits Builder Transferees with status: ${this.bbStatus} can be deactivated.</span>
        </li>
        <li>For Traditional Transferees:
          <br>
          Traditional Transferees with status: ${this.traditionalStatus} can be deactivated.
        </li>
      </ol>  
    `
    return this.sanitizer.bypassSecurityTrustHtml(toolTipContent);
  }

  getStatusBasedOnRole () {
    if(this.userRole === 'file-setup-specialist') {
      this.bbStatus = FSU_BB_STATUS;
      this.traditionalStatus = FSU_Traditional_STATUS;
    } else if (this.userRole === 'team-client-service-manager') {
      this.bbStatus = CSM_BB_STATUS;
      this.traditionalStatus = CSM_Traditional_STATUS;
    } else if(this.userRole === 'client-contact-initiator'){
      this.bbStatus = CCI_BB_STATUS;
      this.traditionalStatus = CCI_Traditional_STATUS;
    }
  }

}

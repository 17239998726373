import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'conditionalTextReplace'
})
export class ConditionalTextReplacePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value === null || value === undefined) {
      return value;
    }
    switch (value) {
      case 'Withdrawn':
        return 'Deactivated';
      case 'Invitation Not Sent':
        return 'Draft';
      default:
        return value
    }
  }
}

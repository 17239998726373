<div cdkTrapFocus cdkTrapFocusAutoCapture  *ngIf="!traditional">
    <div class="popUpTitleBlk" fxLayout="row" fxLayoutAlign="space-between center">
      <h2>Move Phases</h2><button mat-icon-button (click)="close()"><span class="material-icons">close</span></button>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center"><app-multi-step  [steps]="5" [selected]="data.movePhaseNo"></app-multi-step></div>
    <mat-divider></mat-divider>
    <app-linked-list [dataSet]="empMovePhases" listStyleClasses="full-width" [columnStyleClasses]="['', '', 'align-right']"></app-linked-list>
    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutAlign="center center">
      <button mat-button class="contained-button" (click)="close()">Close</button>
    </div>
</div>


<!-- -- For Traditional Move-- -->
<div class="traditionalMove" *ngIf="traditional">
  <div fxLayout="row" fxFill>
    <div>
      <span>Move Phase</span>
      <app-linked-list [dataSet]="empMovePhases" listStyleClasses="full-width"
        [columnStyleClasses]="['', '', 'align-right']"></app-linked-list>
    </div>
  </div>
</div>
    
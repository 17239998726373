import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayIterationOrder'
})
export class ArrayIterationOrderPipe implements PipeTransform {

  transform(value) {
    if(value){
      return value.slice().reverse();
    }
    
  }

}

<div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
   fxLayoutGap="10px" fxLayoutGap.xs="0em">Atlas policy already assigned
</div>
<div>
    The Policy “{{data?.duplicatePolicies.join(", ")}}” has already been selected and utilised in an Active/Draft program.
</div>         
<div style="padding-top: 20px;">
    Do you still want to continue with the selection?
</div>
<div mat-dialog-actions align="end" style="padding-bottom: 20px;">
    <button mat-button class="mat-button text-button" (click)="onClose()">No</button>
    <button mat-button class="mat-button contained-button" (click)="onConfirm()">Yes</button>
</div>
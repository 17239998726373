import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[tooltipToRenderHTML]'
})
export class TooltipToRenderHTMLDirective {

  @Input('tooltipToRenderHTML')
  tooltipContent!: any;
  
  private tooltipElement: HTMLElement;
  
  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.tooltipElement = this.renderer.createElement('div');
    this.renderer.addClass(this.tooltipElement, 'custom-html-tooltip');
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.showTooltip();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hideTooltip();
  }

  private showTooltip() {
    this.tooltipElement.innerHTML = this.tooltipContent.changingThisBreaksApplicationSecurity;
    const hostElement = this.el.nativeElement;
    this.renderer.appendChild(hostElement, this.tooltipElement);
  }

  private hideTooltip() {
    this.renderer.removeChild(this.el.nativeElement, this.tooltipElement);
  }

}
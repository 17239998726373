import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroupDirective, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CandidateProfileService } from '../../services/candidate-profile.service';
import { UserTypeService } from '../../services/user-type.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SaveAuthWarningDialogComponent } from '../save-auth-warning-dialog/save-auth-warning-dialog.component';
import { AlertDialogComponent } from '../../modules/transferee/transferee-detail/alert-dialog/alert-dialog.component';
export const UnScuccessfullDocumentUploadMessage = `Authorization saved but there was an issue with the document upload. Please try again later.`
export const REPAYMENT_AGREEMENT = "Repayment Agreement";
export const ScuccessfullDocumentUploadMessage = `Document Uploaded Successfully.`

@Component({
  selector: 'app-additional-document-upload',
  templateUrl: './additional-document-upload.component.html',
  styleUrls: ['./additional-document-upload.component.scss']
})
export class AdditionalDocumentUploadComponent implements OnInit, OnChanges {
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  files: any[] = [];
  documentFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  docTypeValue: any[];

  @Input()
  orderRequestId: string;

  @Output()
  isFileSelected: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @Input()
  displayButton: boolean;

  @Input()
  allowedDoc: string;

  @Input()
  repaymentAgreementType: 'E-Sign'|'Consultant Collects'

  @Input()
  repayDocId: number

  constructor(
    private fb: UntypedFormBuilder,
    private candidateProfileService: CandidateProfileService,
    private readonly toastrService: ToastrService,
    private userTypeService: UserTypeService,
    @Optional() public addCandidateForm: FormGroupDirective,
    public dialog: MatDialog
    ) {
      if(this.addCandidateForm) {
        this.documentFormGroup = this.addCandidateForm.form;
      }
    
    this.documentFormGroup.addControl('uploadDocuments', new UntypedFormGroup({
      docList: this.fb.array([])
    }))
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(typeof changes['orderRequestId'] !== "undefined" && !changes['orderRequestId'].firstChange) {
      if(changes['orderRequestId'].currentValue)
        this.uploadOrReplaceDocument();
    }
  }

  ngOnInit(): void {
    this.getAuthDocTypes();
  }

  get docList() {
    return this.documentFormGroup.controls.uploadDocuments.get('docList') as UntypedFormArray;
  }

  addDoc(files) {
    for (const item of files) {
      const extension = item.name.split('.').pop();
      const fileSize = item.size;
      const allowedExtensions = ['csv','docx', 'pdf', 'xls', 'xlsx'];
      if(allowedExtensions.includes(extension)) {
        if(fileSize <= 25*1024*1024) {
          const docForm = this.fb.group({
            documentDisplayName : [item.name, Validators.required],
            docType: ['', Validators.required],
            file: [item, Validators.required]
          })
          this.docList.push(docForm);
          docForm.markAllAsTouched();
          this.isFileSelected.emit(true);
        } else {
          const message = `This file ${item.name} could not be uploaded. File size for each document should not exceed 25 MB`;
          this.displayErrorMessage(message);
        }
      } else {
        const message = `This file ${item.name} type ${extension} is not supported. Upload file with supported file type: CSV, DOCX, PDF, XLS, XLSX.`;
        this.displayErrorMessage(message);
      }
    }
  }

  getAuthDocTypes() {
    this.candidateProfileService.getTradOptionalValues('AuthDocTypes').subscribe(res => {
      if(res && res.values) {
        const userType = this.userTypeService.userType;
        if(this.allowedDoc) {
          this.docTypeValue = res.values.filter(ele => ele.name === this.allowedDoc)
        } else {
          if (userType === 'client-contact-initiator') {
            this.docTypeValue = res.values.filter(ele => ele.name !== 'Authorization Coding Matrix')
          } else {
            this.docTypeValue = res.values;
          }
        }  
      }
    });
  }

  /**
 * Delete file from files list
 * @param index (File index)
 */
  deleteFile(index: number) {
    this.docList.removeAt(index);
  }

  /**
 * handle file from browsing
 */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
 * on file drop handler
 */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    this.addDoc(files);
    this.fileDropEl.nativeElement.value = "";
  }

  uploadOrReplaceDocument() {
    if(this.documentFormGroup.value.uploadDocuments.docList) {
      for (const doc of this.documentFormGroup.value.uploadDocuments.docList) {
        if(doc.docType === REPAYMENT_AGREEMENT && this.repayDocId) {
          this.openAlertPopup(doc);
        } else {
          this.uploadDocuments(doc);
        }
      }
    }
  }

  uploadDocuments(doc) {
    let docDetails = {...doc};
      delete docDetails.file;
    this.candidateProfileService.postDMSDetails(this.orderRequestId, docDetails).subscribe({
      next: (res) => {
        if (res && res.request) {
          const dmsDetails = res.request;
          const token = dmsDetails.headers.Authorization;
          sessionStorage.setItem('car-ses-dms', token);

          let formData: any = new FormData();
          formData.append('guid', dmsDetails.body.guid);
          formData.append('docType', dmsDetails.body.docType);
          formData.append('keywords', JSON.stringify(dmsDetails.body.keywords));
          formData.append('File', doc.file);

          this.candidateProfileService.uploadDMSFile(formData)
          .subscribe({
            next : res => {
              if(res) {
                while (this.docList.length > 0) {
                  this.docList.removeAt(0);
                }
                this.candidateProfileService.uloadedDocument.next(res.body);
                this.displayMessage(ScuccessfullDocumentUploadMessage);
                if(doc.docType === REPAYMENT_AGREEMENT){                     
                  this.updateMilestoneRepaymentDoc(res.body);
                }
              } else {
                this.displayErrorMessage(UnScuccessfullDocumentUploadMessage);
              }
            }, error: err => {
              this.displayErrorMessage(UnScuccessfullDocumentUploadMessage);
            }
          })
        } else {
          this.displayErrorMessage(UnScuccessfullDocumentUploadMessage);
        }
      },
      error: (err) =>{
        this.displayErrorMessage(UnScuccessfullDocumentUploadMessage);
      },
    })
  }

  deleteRepaymenAgreement(doc) {
    this.candidateProfileService.deleteDocument(this.repayDocId, doc.docType, this.orderRequestId).subscribe(res => {
      this.uploadDocuments(doc)
    })
  }

  displayErrorMessage(message) {
    this.toastrService.error(message, null, {
      closeButton: false,
      enableHtml: true,
      disableTimeOut: false,
      toastClass: 'ngx-toastr custom-toast',
      timeOut: 10000
    })
  }

  displayMessage(message) {
    this.toastrService.info(message, null, {
      closeButton: false,
      enableHtml: true,
      disableTimeOut: false,
      toastClass: 'ngx-toastr custom-toast',
      timeOut: 10000
    })
  }

  openSaveWarningDialog(type) {
    const dialog = this.dialog.open(SaveAuthWarningDialogComponent, {
      width: '500px',
    });

    dialog.afterClosed().subscribe(result => {
      if (result.action === 'confirm') {
        this.uploadOrReplaceDocument();
      }
    });
  }

  updateMilestoneRepaymentDoc(data){
    let reqbody = {
      Source: "api-integration",
      DetailType: "Doc Uploaded",
      Detail: {
          appContext: null,
          orderRequestId: this.orderRequestId,
          type: "move",
          docId: data.documentID,
          docType: data.documentType.split(" - ").pop(),
      }
    }
    this.candidateProfileService.updateRepaymentDocMilestone(reqbody).subscribe((res) => {
      if (res) {
      }
    });

  }

  openAlertPopup(docType) {
    let displayMessage: string;
    if(this.repaymentAgreementType === 'Consultant Collects') {
      displayMessage = 'Repayment Agreement document already exists. Do you want to replace it with the selected file?';
    } else {
      displayMessage = 'Repayment Agreement document already exists. Duplication is not allowed.';
    }
    
    const dialog = this.dialog.open(AlertDialogComponent, {
      width: '500px',
      data: {
        displayMessage: displayMessage,
        showConfirmBtn: this.repaymentAgreementType === 'Consultant Collects' ? true : false
      },
    })

    dialog.afterClosed().subscribe(res => {
      if(res?.action === 'confirm') {
        this.deleteRepaymenAgreement(docType);
      }
    })
  }
 }

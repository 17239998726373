import { Injectable, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { NgxSpinnerModule } from "ngx-spinner";
import { AdministrationRoutingModule } from "./administration-routing.module";
import { AdministrationComponent } from "./administration.component";
import { AddEditTransfereeComponent } from "./components/add-edit-transferee/add-edit-transferee.component";
import { WarningDialogComponent } from "./components/add-edit-transferee/add-edit-transferee.component";
import { CharacterOnlyDirective } from "./../../core/directives/character-only.directive";
import { CharacterSpaceOnlyDirective } from "./../../core/directives/character-space-only.directive";
import { CharNumSpecialDirective } from "./../../core/directives/char-num-special.directive";
import { SharedModule } from "./../../core/shared.module";
import { MatRadioModule } from "@angular/material/radio";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TransfereeListComponent } from "./components/transferee-list/transferee-list.component";
// import { MatMomentDateModule, MomentDateModule } from '@angular/material-moment-adapter';

import {DragDropModule} from '@angular/cdk/drag-drop';

import { ListFilterModalComponent } from "./components/transferee-list-filter/list-filter-modal/list-filter-modal.component";
import { AdminFilterSelectionComponent } from "./components/transferee-list-filter/filter-selection/filter-selection.component";
import { ClientListComponent } from "./components/client-list/client-list.component";
import { ClientDetailsComponent } from "./components/client-details/client-details.component";
import { ClientContactsListComponent } from "./components/client-contacts-list/client-contacts-list.component";
import { ProgramListComponent } from "./components/program-list/program-list.component";
import { ListComponent } from "./components/list/list.component";
import {
  ProgramModalComponent,
  ProgramCloseWarningComponent
} from "./components/program-modal/program-modal.component";
import { ProgramModule } from "./modules/program/program.module";
import { TransfereeModule } from "./modules/transferee/transferee.module";
import { SharedTransfereeProgramModule } from "./modules/shared/shared.module";
import { AdminDataviewSelectionModalComponent } from "./components/admin-dataview-selection-modal/admin-dataview-selection-modal.component";
import { DivisionAssociationComponent } from "./components/division-association/division-association.component";
import { CopyProgramModalComponent } from "./components/copy-program-modal/copy-program-modal.component";
import { DeactivateProgramModalComponent } from "./components/deactivate-program-modal/deactivate-program-modal.component";
import { AdminFilterChipComponent } from "./components/admin-filter-chip/admin-filter-chip.component";
import { FilterProgramModalComponent } from "./components/filter-program-modal/filter-program-modal.component";
import { ConsultantOnlyModalComponent } from "./components/consultant-only-modal/consultant-only-modal.component";
import { ShowOverrideHistoryComponent } from "./components/show-override-history/show-override-history.component";
import { NameInitialsPipe } from "./pipes/name-initials.pipe";
import { ArrayIterationOrderPipe } from "./pipes/array-iteration-order.pipe";
import { UnconfirmBenefitsModalComponent } from "./modules/transferee/components/unconfirm-benefits-modal/unconfirm-benefits-modal.component";
import { PointsLogicModalComponent } from "./modules/program/components/points-logic-modal/points-logic-modal.component";
import { CustomdataDeleteConfirmComponent } from "../components/customdata-delete-confirm/customdata-delete-confirm.component";
import { ViewProgamDetailsComponent } from "./components/view-program-details/view-program-details-component";
import { TransfereeOfferingsListComponent } from "./components/transferee-offerings-list/transferee-offerings-list.component";
import { SearchDuplicateCheckComponent } from "./components/search-duplicate-check/search-duplicate-check.component";
import { DupCheckPopupComponent } from "./components/dup-check-popup/dup-check-popup.component";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { SharedTraditionalOrderModule } from "../../shared/sharedTraditionalOrder.module";
import { ApproveMovePopupComponent } from "./components/approve-move-popup/approve-move-popup.component";
import { AppConfigService } from "../../core/services/app-config.service";
import { AtlasPolicyWarningComponent } from './components/atlas-policy-warning/atlas-policy-warning.component';
import { CronofyAvailablilityPickerModule } from "./modules/cronofy-availablility-picker/cronofy-availablility-picker/cronofy-availablility-picker.module";
import { CronofyAvailablilityPickerComponent } from "./modules/cronofy-availablility-picker/cronofy-availablility-picker/cronofy-availablility-picker.component";
import { UnprocessedListComponent } from "./components/unprocessed-list/unprocessed-list.component";
import { AdminFilterModalComponent } from './components/admin-filter-modal/admin-filter-modal.component';
import { SubmissionFailureComponent } from './components/submission-failure/submission-failure.component';
import { DuplicateCheckComponent } from './components/submission-failure/duplicate-check/duplicate-check.component';
import { ErrorReviewComponent } from './components/submission-failure/error-review/error-review.component';
import { OriginViewSubmissionComponent } from "./components/submission-failure/origin-view-submission/origin-view-submission.component";
import { DuplicateRecordsDeletePopupComponent } from "./components/submission-failure/duplicate-records-delete-popup/duplicate-records-delete-popup.component";
import { DirectivesModule } from "../../core/modules/directives/directives.module";
import { ReferenceInformationComponent } from './components/submission-failure/reference-information/reference-information.component';
import { NotificationPopUpComponent } from './components/notification-pop-up/notification-pop-up.component';
import { DocumentLibraryComponent } from './components/document-library/document-library.component';
import { AvatarModule } from "../../core/components/avatar/avatar.module";
import { SaveAuthWarningDialogComponent } from './components/save-auth-warning-dialog/save-auth-warning-dialog.component';
import { ComplianceListComponent } from './components/compliance-list/compliance-list.component';
import { CompliantAdjudicationTransfereesComponent } from './components/compliant-adjudication-transferees/compliant-adjudication-transferees.component';
import { ApproveRejectCompliantTransfereesComponent } from './components/approve-reject-compliant-transferees/approve-reject-compliant-transferees.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { ConditionalTextReplacePipe } from './pipes/conditional-text-replace.pipe';
import { SpecialInstructionsComponent } from './components/special-instructions/special-instructions.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { AddressFormWrapperComponent } from './components/address-form-wrapper/address-form-wrapper.component';
import { ConditionalAuthTypeTextReplacePipe } from './pipes/conditional-auth-type-text-replace.pipe';

@Injectable()
export class GoogleMapsConfig {
  apiKey: any;
  libraries: string[];
  constructor(config: AppConfigService) {
    this.apiKey = config.getConfig("googleApiKey");
    this.libraries = ["places"];
  }
}
@NgModule({
    schemas: [NO_ERRORS_SCHEMA],
    declarations: [
        AdministrationComponent,
        AddEditTransfereeComponent,
        WarningDialogComponent,
        CharacterOnlyDirective,
        CharacterSpaceOnlyDirective,
        CharNumSpecialDirective,
        TransfereeListComponent,
        ListFilterModalComponent,
        AdminFilterSelectionComponent,
        ClientDetailsComponent,
        ClientContactsListComponent,
        ClientListComponent,
        ClientDetailsComponent,
        ProgramListComponent,
        ListComponent,
        ProgramModalComponent,
        ProgramCloseWarningComponent,
        AdminDataviewSelectionModalComponent,
        DivisionAssociationComponent,
        CopyProgramModalComponent,
        AdminFilterChipComponent,
        FilterProgramModalComponent,
        DeactivateProgramModalComponent,
        // SearchHighlightPipe,
        ConsultantOnlyModalComponent,
        PointsLogicModalComponent,
        ShowOverrideHistoryComponent,
        // NameInitialsPipe,
        ArrayIterationOrderPipe,
        CustomdataDeleteConfirmComponent,
        ViewProgamDetailsComponent,
        TransfereeOfferingsListComponent,
        SearchDuplicateCheckComponent,
        DupCheckPopupComponent,
        ApproveMovePopupComponent,
        AtlasPolicyWarningComponent,
        UnprocessedListComponent,
        AdminFilterModalComponent,
        SubmissionFailureComponent,
        DuplicateCheckComponent,
        ErrorReviewComponent,
        OriginViewSubmissionComponent,
        DuplicateRecordsDeletePopupComponent,
        ReferenceInformationComponent,
        NotificationPopUpComponent,
        // DocumentLibraryComponent,
        SaveAuthWarningDialogComponent,
        ComplianceListComponent,
        CompliantAdjudicationTransfereesComponent,
        ApproveRejectCompliantTransfereesComponent,
        InfoDialogComponent,
        ConditionalTextReplacePipe,
        SpecialInstructionsComponent,
        AddressFormComponent,
        AddressFormWrapperComponent,
        ConditionalAuthTypeTextReplacePipe
    ],
    imports: [
        CommonModule,
        AdministrationRoutingModule,
        NgxSpinnerModule,
        MatAutocompleteModule,
        MatMenuModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatCardModule,
        MatExpansionModule,
        MatDividerModule,
        MatButtonToggleModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatChipsModule,
        MatIconModule,
        MatListModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        // MomentDateModule,
        MatTabsModule,
        SharedModule,
        SharedTransfereeProgramModule,
        ProgramModule,
        CronofyAvailablilityPickerModule,
        TransfereeModule,
        MatRadioModule,
        DragDropModule,
        DirectivesModule,
        NgxMaterialTimepickerModule,
        SharedTraditionalOrderModule,
        MatStepperModule,
        AvatarModule
    ],    
    providers: [
        DatePipe
    ]
})
export class AdministrationModule {}

<div class="filter-modal-wrap">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mat-dialog-title">
        <h2>Filter Table</h2><button tabindex="0" mat-icon-button mat-dialog-close aria-label="Close" matTooltip="Close"><span
                class="material-icons close-icon">close</span></button>
    </div>
    <div class="mat-dialog-content">
        <div fxLayout fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.gt-md="flex-start"
            fxLayoutGap.gt-md="25px">
            <div *ngIf = "data.dataKey.includes('movePhase')" class="movePhase-filter">
                <h6 id="movePhaseLbl">Move Phase:</h6>
                <app-filter-selection [dataSet]="movePhases" labelId="movePhaseLbl"
                    [(selectedDataSet)]="selectedMovePhases"></app-filter-selection>
            </div>
            <div *ngIf = "data.dataKey.includes('fileStatusDesc')" class="filestatus-filter">
                <h6 id="fileStatusLbl">File Status:</h6>
                <app-filter-selection [dataSet]="fileStatuses" labelId="fileStatusLbl"
                    [(selectedDataSet)]="selectedFileStatuses"></app-filter-selection>
            </div>
            <div *ngIf = "data.dataKey.includes('departure')" class="departure-filter">
                <h6 id="departureLbl">Departure:</h6>
                <app-filter-selection [filterOptions]="departureFilterOptions" labelId="departureLbl"
                    [(selectedDataSet)]="selectedDepartures" (search)="getLocations('dept', $event)"
                    [dataSet]="departures" [PlaceHolder]="'City, State, or Country'"></app-filter-selection>
            </div>
            <div *ngIf = "data.dataKey.includes('destination')" class="destination-filter">
                <h6 id="destinationLbl">Destination:</h6>
                <app-filter-selection [filterOptions]="destinationFilterOptions" labelId="destinationLbl"
                    [(selectedDataSet)]="selectedDestinations" (search)="getLocations('dest', $event)"
                    [dataSet]="destinations" [PlaceHolder]="'City, State, or Country'"></app-filter-selection>
            </div>
        </div>
        <div class="margin-top" fxLayout fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.gt-md="flex-start" fxLayoutGap.gt-md="25px">
            <div *ngIf = "data.dataKey.includes('division')" class="division-filter">
                <h6 id="divisionLbl">Divisions:</h6>
                <app-filter-selection [filterOptions]="divisionFilterOptions" labelId="divisionLbl" [(selectedDataSet)]="selectedDivisions" (search)="getDivisionByClientId($event)" [dataSet]="filteredDivision" [PlaceHolder]="'Divisions details contains...'" ></app-filter-selection>
             </div>
            <div *ngIf = "data.dataKey.includes('policy')" class="policy-filter">
            <h6 id="policyLbl">Policy:</h6>
            <app-filter-selection [filterOptions]="policyFilterOptions" labelId="policyLbl" [(selectedDataSet)]="selectedPolicies" (search)="getPoliciesByClientId($event)" [dataSet]="filteredPolicies" [PlaceHolder]="'Policy Name contains...'" ></app-filter-selection>
         </div>
        <div  class="selection-block"  fxLayout fxLayout.lt-md="column" fxLayoutAlign="space-between" fxLayoutGap.gt-md="25px">
            <div  class="range-block">
                <div class="relative-block" *ngIf = "data.dataKey.includes('effectiveTransferDate')" id="effectiveTransferDate">
                    <h6>Effective Transfer Date Range:</h6>
                    <div class="inline-blk startDate date">
                        <mat-form-field>
                            <input matInput [matDatepicker]="startDate" placeholder="Start Date" [(ngModel)]="effectiveStartDate" (dateChange)="effectiveDateChange()">
                            <mat-datepicker-toggle tabindex="0" matSuffix [for]="startDate" matTooltip="Calendar"></mat-datepicker-toggle>
                            <mat-datepicker #startDate></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="hyphenField inline-blk">-</div>
                    <div class="inline-blk date">
                        <mat-form-field>
                            <input matInput [matDatepicker]="endDate" placeholder="End Date" [(ngModel)]="effectiveEndDate" (dateChange)="effectiveDateChange()">
                            <mat-datepicker-toggle matSuffix [for]="endDate" matTooltip="Calendar"></mat-datepicker-toggle>
                            <mat-datepicker #endDate></mat-datepicker>
                        </mat-form-field>
                        <div class="optionalField">optional</div>
                    </div>
                </div>
                    <div class="relative-block" *ngIf = "data.dataKey.includes('totalCost')" id="totalCostRange">
                        <h6 id="totalCostLbl">Total Cost Range:</h6>
                        <div class="inline-blk minCost cost">
                            <mat-form-field>

                                <input type="text" for="totalCostLbl" (keypress)="numberOnly($event)" matInput
                                    aria-label="Min Total" placeholder="Min Total" [(ngModel)]="totalMinCost">

                            </mat-form-field>
                        </div>
                        <div class="hyphenField inline-blk">-</div>
                        <div class="inline-blk cost">
                            <mat-form-field>
                                <input type="text" for="totalCostLbl" (keypress)="numberOnly($event)" matInput
                                    aria-label="Max Total" placeholder="Max Total" [(ngModel)]="totalMaxCost"
                                    (blur)="totalMaxCostBlur()">
                            </mat-form-field>
                            <div class="optionalField">optional</div>
                        </div>
                    </div>
                    <div class="relative-block" *ngIf = "data.dataKey.includes('starred')" id="totalCostRange">
                        <h6 id="totalCostLbl">Show Only Starred</h6>
                        <div class="inline-blk minCost cost">
                            <mat-slide-toggle [(ngModel)]="favouritesStarred" (change)="showOnlyStartedChanged($event)">
                                {{showOnlyStartedText}}</mat-slide-toggle>
                        </div>
                    </div>
                </div>
            </div>
        <!-- </div> -->
    </div>
    <div class="mat-dialog-actions">
        <div class="filter-buttons" fxLayout="row" fxLayoutAlign="end center">
            <button mat-button class="text-button" id="reset" (click)="onReset()"> Clear </button>
            <button mat-button class="contained-button" id="save" (click)="onSubmit()"> OK </button>
        </div>
    </div>
</div>
import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription, Observable, forkJoin, of } from 'rxjs';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TransactionService } from '../../../../core/services/transaction.service';
import { TransactionData } from '../../../../core/models/transaction-data.model';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { Transaction } from '../../../../core/models/transaction.model';
import { AggregationFetchparameter } from '../../../../core/models/aggregation-fetch-parameter.model';
import { EmployeeDetailsService } from '../../../../core/services/employee-details.service';
import { EmployeeInfo } from '../../../../core/models/employee-info.model';
import { NumbersService } from '../../../../core/services/numbers.service';
import { DateConversionService } from '../../../../core/services/date-conversion.service';
import { UserPreference } from '../../../../core/models/user-preference.model';
import { UserPreferencesService } from '../../../../core/services/user-preferences.service';
import { CdkDragStart, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { DataviewSelectionModalComponent } from './../dataview-selection-modal/dataview-selection-modal.component';
import { UserConfigService } from '../../../../core/services/user-config.service';
import * as financialColumns from '../../../../core/data/financialDataView.json';

/** Employee transaction component */
@Component({
  selector: 'app-employee-transactions',
  templateUrl: './employee-transactions.component.html',
  styleUrls: ['./employee-transactions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EmployeeTransactionsComponent implements OnInit, OnDestroy {
  /** Base constructor method
  * @param dialog MatDialog injection
  * @param transactionService TransactionService injection
  */
  constructor(
    public dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly userPreferenceService: UserPreferencesService,
    private readonly transactionService: TransactionService,
    private readonly numberService: NumbersService,
    private readonly dateSvc: DateConversionService,
    private empDetailSvc: EmployeeDetailsService,
    private readonly userConfigService: UserConfigService
  ) {
    this.dataSource = new MatTableDataSource();
    this.pageEvent = new PageEvent();
    this.pageEvent.pageIndex = 0;
    this.pageEvent.pageSize = 25;
  }
  /** employeeTransactionView variable to store the response data */
  employeeTransactionView: Subscription;

  /** displayedColumns variable array to store the displayed columns */
  displayedColumns: string[] = [];

  /** defaultColumn to store default columns */
  defaultColumn: string[] = [];
  financialSelction: string[] = [
    'FinancialViewColumnDisplay'
  ];

  /** dataSource variable to store the response converted to mat table datasource */
  dataSource: MatTableDataSource<TransactionData>;

  // dataviewSelection to access the dialog
  dataviewSelection: MatDialogRef<DataviewSelectionModalComponent>;

  /** totalTransactionAmt variable to hold the total transaction amount */
  totalTransactionAmt: any;

  /** totalTransactionAmtCurr variable to hold the total transaction amount currency*/
  totalTransactionAmtCurr: string;

  /** transactionArr variable to hold the grouped by category data*/
  transactionArr: TransactionData[] = [];

  /** taxAssistance variable to hold the tax Assistance*/
  taxAssistance: any;

  /** totalExpense variable to hold the total Expense*/
  totalExpense: any;

  /** To sort the mat table columns */
  @ViewChild(MatSort) sort: MatSort;

  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /** Pagination page event */
  pageEvent: PageEvent;

  /**store the filter value */
  filterText = '';

  /** searchKeyWord inputed from the parent dashboard component */
  @Input() searchKeyword;

  /** transactionsReport will hold the 'Cost Transactions' for report generation */
  transactionsReport = 'Employee Transactions';

  /** sortDirection to hold the asc or desc direction value */
  sortDirection: any;

  /** sortColumnName to hold the clicked column name for sorting */
  sortColumnName: any;

  /** sortOptions to hold the sort column name and sort direction */
  sortOptions = {};

  /** initialSortColumn to hold the column name for inital sorting. */
  initialSortColumn = 'invoiceDate';

  /** initialSortDirection to hold the column name for inital sorting. */
  initialSortDirection = 'desc';

  /** store preferred currency */
  preferredCurr = '';

  public fixed = false;
  showTransfereeDownloadBtn: boolean;

  /**traditional move order- order request ID*/
  @Input() orderRequestId: string;
  @Input() fileNumber: any;
  @Input() traditional: boolean;

  /**to send the totalBillAmount, totalBillCurrency to transferee detail component*/
  @Output() sendTotalBillAmount: EventEmitter<number> =   new EventEmitter();
  @Output() sendTotalBillCurrency: EventEmitter<string> = new EventEmitter();

  /** columns variable array to store the column fields, headers, and sortName */
  columns: any[] = [
    { field: 'invoiceDate', header: 'Billed Date', sortName: 'invoiceDate' },
    { field: 'accountCategory', header: 'Account Category', sortName: 'accountCategory', },
    { field: 'accountSubCategory', header: 'Account Sub Category', sortName: 'accountSubCategory', },
    { field: 'transactionDesc', header: 'Transaction Description', sortName: 'transactionDesc', },
    { field: 'invoiceNo', header: 'Invoice Number', sortName: 'invoiceNo' },
    { field: 'billAmount', header: 'Billed Amount', sortName: 'billAmount' },
  ];
  previousDragIndex: number;
  dragColumnName: string;
  financialColumnsList: any = (financialColumns as any).default;
  pageSizeOptions: any[];

  applySort(columnField: any) {
    this.clearInitialSort();
    let columnName: string;
    columnName = columnField;
    if (this.sortColumnName === columnName) {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc';
      } else {
        this.sortDirection = 'asc';
      }
    } else {
      this.sortColumnName = columnName;
      this.sortDirection = 'asc';
    }
    this.sortOptions['sortColumnName'] = this.sortColumnName;
    this.sortOptions['sortDirection'] = this.sortDirection;
    this.updateUserPreference([this.sortOptions], 'FinancialViewColumnSort');
    const aggregationFetchParameter: AggregationFetchparameter = {
      pageNumber: this.pageEvent.pageIndex,
      itemsPerPage: this.pageEvent.pageSize,
      sortColumnName: this.sortColumnName,
      sortOrder: this.sortDirection,
    };
    this.filterTransactionDetails(aggregationFetchParameter);
  }

  /** Init method to make Service call, to convert response to mat table data and to initialize sort, pagination */
  ngOnInit() {
    this.showTransfereeDownloadBtn = this.traditional? true: false;
    this.setConfiguration().subscribe(() => {
      let setTransactionData: AggregationFetchparameter = this.setTransactionDataFetchParam();
      this.taxAssistance = 0;
      forkJoin([
        this.userPreferenceService.getPreference('page_event_transaction', false),
        this.userPreferenceService
          .getPreference('user_config', false)
      ]).subscribe((response) => {
        if (response[0]) {
          this.pageEvent = response[0];
          setTransactionData = {
          itemsPerPage: this.pageEvent.pageSize,
          pageNumber: this.pageEvent.pageIndex,
        };
       }
       if (response[1]){
        if(response[1].some((data) => data.preferencesetting?data.preferencesetting.name == "FinancialViewPageSize":false)){
          let pageSize = response[1].filter((data) =>  data.preferencesetting?data.preferencesetting.name == "FinancialViewPageSize":false);
          setTransactionData.itemsPerPage = pageSize[0].preferencesetting.value[0];
        }
      }
       setTransactionData.sortColumnName = this.initialSortColumn;
       setTransactionData.sortOrder = this.initialSortDirection;
       this.sortColumnName = this.initialSortColumn;
       this.sortDirection = this.initialSortDirection;
       this.filterTransactionDetails(setTransactionData);
    });
    });
  }

  filterTransactionDetails(aggregationFetchParameter: AggregationFetchparameter) {
    if (this.displayedColumns.length === 0) {
      this.setConfiguration();
    }
    aggregationFetchParameter.pageNumber =
      aggregationFetchParameter.pageNumber + 1;
    aggregationFetchParameter.preferredCurr = this.preferredCurr;
    if (!this.traditional) {
      this.employeeTransactionView = this.route.params.subscribe((params) => {
        const fileNumber: number = +params.id;
        this.transactionService
          .getTransactionByFileNum(fileNumber, aggregationFetchParameter)
          .pipe(take(1))
          .subscribe((res: Transaction) => {
            const employeeTransactionData = res.results;
            if (employeeTransactionData && employeeTransactionData.length > 0) {
              employeeTransactionData.forEach((employee) => {
                if (employee.billAmount && employee.billCurrency) {
                  employee.billAmount = this.numberService.formatForCurrency(
                    employee.billAmount,
                    employee.billCurrency
                  );
                }
              });
            }
            this.dataSource = new MatTableDataSource<TransactionData>(
              employeeTransactionData
            );
            this.dataSource.paginator = this.paginator;
            setTimeout(() => {
              this.paginator.length = res.count;
              this.paginator.pageIndex = this.pageEvent.pageIndex;
            });
            this.getTransactionSum(res);
          });
      })
    } else {
      if (this.orderRequestId && this.fileNumber) {
        this.transactionService
          .getTransactionByOrderReqId(this.orderRequestId, this.fileNumber, aggregationFetchParameter)
          .pipe(take(1))
          .subscribe((res: Transaction) => {
            const employeeTransactionData = res.results;
            if (employeeTransactionData && employeeTransactionData.length > 0) {
              employeeTransactionData.forEach((employee) => {
                if (employee.billAmount && employee.billCurrency) {
                  employee.billAmount = this.numberService.formatForCurrency(
                    employee.billAmount,
                    employee.billCurrency
                  );
                }
              });
            }
            this.dataSource = new MatTableDataSource<TransactionData>(
              employeeTransactionData
            );
            this.dataSource.paginator = this.paginator;
            setTimeout(() => {
              this.paginator.length = res.count;
              this.paginator.pageIndex = this.pageEvent.pageIndex;
            });
            this.getTransactionSum(res);
          });
      }
    }
  }

  /** A setData method to group by category and to find the transaction total */
  getTransactionSum(transaction: Transaction) {
    let transactionTotal: TransactionData = null;
    this.totalTransactionAmt = 0;
    if (transaction) {
      transaction.results.forEach((data) => {
        const transactionObj: TransactionData = this.transactionArr.find(
          (a) => a.accountCategory === data.accountCategory
        );
        if (!transactionObj) {
          transactionTotal = {
            accountCategory: data.accountCategory,
            accountSubCategory: data.accountSubCategory,
            transactionDesc: data.transactionDesc,
            invoiceDate: data.invoiceDate,
            transactionAmount: data.transactionAmount,
            transactionCurrency: data.transactionCurrency,
            billAmount: data.billAmount,
            billCurrency: data.billCurrency,
            billedDate: data.billedDate,
            invoiceNo: data.invoiceNo,
          };
          this.transactionArr.push(transactionTotal);
        }
      });
      this.totalTransactionAmt = transaction.totalBillAmount;
      this.totalTransactionAmtCurr = transaction.totalBillCurrency;
      if (transaction.totalTaxAssistance != 0 && transaction.totalTaxAssistance) {
        this.taxAssistance = this.numberService.formatForCurrency(
          transaction.totalTaxAssistance,
          this.totalTransactionAmtCurr
        );
        this.totalExpense = this.numberService.formatForCurrency(
          transaction.totalExpense,
          this.totalTransactionAmtCurr
        );
      }
      this.totalTransactionAmt = this.numberService.formatForCurrency(
        this.totalTransactionAmt,
        this.totalTransactionAmtCurr
      );
      /**to send the data to transferee-detail component */
      this.sendTotalBillAmount.emit(transaction.totalBillAmount);
      this.sendTotalBillCurrency.emit(transaction.totalBillCurrency);
    }
  }

  /** setEmployeeDataFetchParam method to initialize object */
  setTransactionDataFetchParam() {
    this.pageEvent.pageIndex = 0;
    return {
      itemsPerPage: 25,
      pageNumber: 0,
    };
  }

  /** onPageChange method to handle page events (page size and page number) */
  onPageChange(e: PageEvent) {
    if (
      this.pageEvent &&
      e.pageSize && this.pageEvent.pageSize && 
      this.pageEvent.pageSize !== this.pageEvent.pageSize
    ) {
      this.pageEvent.pageIndex = 0;
    } else {
      this.pageEvent.pageIndex = e.pageIndex;
    }
    this.pageEvent.pageSize = e.pageSize;
    const preference: UserPreference = {
      Key: 'page_event_transaction',
      Value: this.pageEvent,
      Persist: false,
    };
    this.updateUserPreference([this.pageEvent.pageSize], 'FinancialViewPageSize');
    this.userPreferenceService.setPreference(preference);
    if (this.pageEvent) {
      this.userPreferenceService
        .getPreference('user_config', false)
        .subscribe((response) => {
          const aggregationFetchParameter: AggregationFetchparameter = {
            pageNumber: this.pageEvent.pageIndex,
            itemsPerPage: this.pageEvent.pageSize,
          };
          response.forEach((item) => {
            if (item.preferenceconfig.name === 'FinancialViewColumnSort') {
              if ('preferencesetting' in item && item.preferencesetting.value.length > 0) {
                aggregationFetchParameter.sortColumnName = item.preferencesetting.value[0].sortColumnName;
                aggregationFetchParameter.sortOrder = item.preferencesetting.value[0].sortDirection;
              } else {
                aggregationFetchParameter.sortColumnName = item.preferenceconfig.default[0].sortColumnName;
                aggregationFetchParameter.sortOrder = item.preferenceconfig.default[0].sortDirection;
              }
            }
          });
          this.filterTransactionDetails(aggregationFetchParameter);
        });
    }
  }
  /** To Destroy the subscribed method */
  ngOnDestroy() {
    if (this.employeeTransactionView) {
      this.employeeTransactionView.unsubscribe();
    }
  }

  /** To Clear the initalSort variables */
  clearInitialSort(): void {
    this.initialSortColumn = null;
    this.initialSortDirection = null;
  }

  /** Rearranges columns array and calls the setDisplayedColumnscolumns function */
  dragDrop(event: CdkDropList<string[]>, index: number) {
    moveItemInArray(this.columns, this.previousDragIndex, index);
    this.dragColumnName = '';
    this.setDisplayedColumns();
  }

  /** Captures previous index of dragged item */
  dragStarted(event: CdkDragStart, index: number) {
    const colName = event.source.element.nativeElement.innerText;
    const column = this.columns.find(col => col.header === colName);
    this.dragColumnName = column.sortName;
    this.previousDragIndex = index;
  }

  /** Rebuilds the displayedColumns array using the columns array order */
  setDisplayedColumns() {
    const orderCol: string[] = [];
    this.columns.forEach((column, index) => {
      column.index = index;
      orderCol.push(column.field);
    });
    this.updateUserPreference(orderCol, 'FinancialViewColumnOrder');
  }

  /** to set user preference configuration for employee list */
  setConfiguration(): Observable<any> {
    let displayColumn: string[] = [];
    let orderColumn: string[] = [];
    this.displayedColumns = [];
    this.userPreferenceService.getPreference('user_config', false)
      .subscribe((response) => {
        if (response) {
          response.forEach((item) => {
            if (item.preferenceconfig.name === 'FinancialViewColumnDisplay') {
              if ('preferencesetting' in item && item.preferencesetting.value.length > 0) {
                displayColumn = item.preferencesetting.value;
              } else {
                displayColumn = item.preferenceconfig.default;
              }
            } else if (item.preferenceconfig.name === 'FinancialViewColumnOrder') {
              if ('preferencesetting' in item && item.preferencesetting.value.length > 0) {
                // this code below is to fix the corrupted preferences by checking if the config.default.length
                // is greater than the preferencesetting.value
                if (item.preferenceconfig.default.length > item.preferencesetting.value.length) {
                  item.preferenceconfig.default.map(row => {
                    if (!item.preferencesetting.value.includes(row)) {
                      item.preferencesetting.value.unshift(row);
                    }
                  });
                  orderColumn = item.preferencesetting.value;
                  this.updateUserPreference(orderColumn, 'FinancialViewColumnOrder');
                }
                orderColumn = item.preferencesetting.value;
              } else {
                orderColumn = item.preferenceconfig.default;
              }
            } else if (item.preferenceconfig.name === 'FinancialViewPageSize') {
              if ('preferencesetting' in item && item.preferencesetting.value.length > 0) {
                this.paginator.pageSize = item.preferencesetting.value[0];
              } else {
                this.paginator.pageSize = item.preferenceconfig.default[0];
              }
            } else if (item.preferenceconfig.name === 'FinancialViewColumnSort') {
              if ('preferencesetting' in item && item.preferencesetting.value.length > 0) {
                this.initialSortColumn = item.preferencesetting.value[0].sortColumnName;
                this.initialSortDirection = item.preferencesetting.value[0].sortDirection;
              } else {
                this.initialSortColumn = item.preferenceconfig.default[0].sortColumnName;
                this.initialSortDirection = item.preferenceconfig.default[0].sortDirection;
              }
            }
            if (item.preferenceconfig.name == "preferredCurrency") {
              if (
                'preferencesetting' in item &&
                item.preferencesetting.value
              ) {
              item.preferencesetting.value ? this.preferredCurr = item.preferencesetting.value : null;
              } 
            }
          });
          this.columns = [];
          orderColumn.forEach((item, index, array) => {
            const column = this.financialColumnsList.find( col => col.field === item );
            let billamountFiled: any;
            let billAmountCol: any;
            if (item === 'billAmount') {
               billamountFiled =  item;
               billAmountCol = column;
            }
            if (index === array.length - 1) {
              this.columns.push({ field: item, header: billAmountCol.header, sortName: billAmountCol.sortName });
            } else {
              this.columns.push({ field: item, header: column.header, sortName: column.sortName });
            }
            if (displayColumn.includes(item)) {
              this.displayedColumns.push(item);
            }
          });
        }
      });
      return of(true);
  }

  /** dataview selection method to open modal popup */
  financialDataViewSelectionModal() {
    this.dataviewSelection = this.dialog.open(DataviewSelectionModalComponent, {
      data: {
        dataKey: this.financialSelction
      },
      panelClass: 'DataviewSelectionModal',
      autoFocus: false,
      restoreFocus: false
    });
    this.dataviewSelection.afterClosed().subscribe(displayedColumns => {
      if (displayedColumns && displayedColumns.length > 0) {
        this.updateUserPreference(displayedColumns, 'FinancialViewColumnDisplay');
      }
    });
  }

  updateUserPreference(req, key) {
    let reqObj: any;
    reqObj = {
      name: key,
      value: req,
    };
    this.userConfigService.updatePreference(reqObj).subscribe((userConfig) => {
      if (userConfig) {
        const preference: UserPreference = {
          Key: 'user_config',
          Value: userConfig,
          Persist: false,
        };
        this.userPreferenceService.setPreference(preference);
        this.setConfiguration();
      }
    });
  }
}

<mat-form-field fxHide.lt-sm class="search-full-width" appearance="outline" flex>
  <div fxLayoutAlign="center center" matPrefix class="wrapper lg-only">
    <mat-icon>search</mat-icon>
  </div>
  <input tabindex="0" type="text" placeholder="Search by Name or File #"
   matInput [(formControl)]="myControl" [matAutocomplete]="auto" (focus)="onFocus(query)">
  <button mat-button *ngIf="query" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete #auto="matAutocomplete" (opened)="announceMessage($event)" (optionSelected)="onSelectionChanged($event)">
    <mat-option *ngIf="showMsg" class="no-padding no-border" [disabled]="true">
        <div role="main" aria-hidden= "true" class="search-msg small-txt"><strong> {{ responseMessage }} </strong></div>
    </mat-option>
    <div role="main" aria-hidden= "true" *ngIf="showCustomMsg" class="search-msg small-txt no-padding">
      <strong>{{ responseMessage1 }}</strong>
      <br /> {{ responseMessage2 }}
      <br />{{ responseMessage3 }}
    </div>
    <span *ngIf="showResults">
      <div class="option-header" *ngIf="showResults">
        <span style="float:left;">Name</span>
        <span style="float:right;">File # </span>
      </div>
      <mat-option *ngFor="let option of filteredOptions" style="clear:both;" aria-live="polite" [value]="option.fileNumber">
        <span style="float:left;">
          <span class="link-color" [innerHTML]="option.firstName | highlightSearch: query"> {{ option.firstName }}
          </span>
          <span class="link-color" [innerHTML]="option.lastName | highlightSearch: query" style="padding-left:4px;">
            {{option.lastName}}</span>
        </span>
        <span style="float:right;" class="link-color" [innerHTML]="option.fileNumber | highlightSearch: query">
          {{ option.fileNumber }} </span>
      </mat-option>
    </span>
  </mat-autocomplete>
</mat-form-field>

import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { UserPreferencesService } from '../../services/user-preferences.service';
import { UserPreference } from '../../models/user-preference.model';

@Component({
  selector: 'app-filter-chip',
  templateUrl: './filter-chip.component.html',
  styleUrls: ['./filter-chip.component.scss']
})
export class FilterChipComponent implements OnInit, OnChanges {

  /** data variable will hold the filters data to display as chips */
  data = [];

  /** To get the selected filters */
  @Input() filterData;

  /** Output the filters data after selected filter is removed */
  @Output() filterOptions = new EventEmitter<any[]>();

  constructor(private userPreferenceService: UserPreferencesService) { }

  ngOnChanges(changes: SimpleChanges) {
    this.data = this.filterData;
  }

  ngOnInit() {
    this.data = this.filterData;
    this.userPreferenceService.getPreference('chips_data', false).subscribe((response) => {
      if (response) {
        this.data = response;
      }
    });
  }

  removeFilter(filter) {
    const index = this.data.indexOf(filter);
    if (index >= 0) {
      this.data.splice(index, 1);
    }
    this.filterOptions.emit(filter);
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';


  
export interface noMatchedData {
  details: string;
  recordValue: string;
  searchCriteria: string;
  isSelected: boolean
}

const TABLE_DATA: noMatchedData[] = [
  {recordValue:'john@cartus.com', details: 'Email Address', searchCriteria:'demo@cartus.com',isSelected:true},
  {recordValue: '1010193935', details: 'SSO#', searchCriteria:'1010193935',isSelected:false},
  {recordValue: '+120355533', details: 'Mobile Phone #', searchCriteria:'+120355533',isSelected:true},
];
  @Component({
  selector: 'app-dup-check-popup',
  templateUrl: './dup-check-popup.component.html',
  styleUrls: ['./dup-check-popup.component.scss']
  })
  
export class DupCheckPopupComponent implements OnInit {
  displayedColumns: string[] = ['details', 'recordValue', 'searchCriteria', 'overwrite'];
  dataSource:any = new MatTableDataSource();
  isChecked:boolean= false;
  checkedList: any[];

  constructor(
    public dialogRef: MatDialogRef<DupCheckPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
     this.getCheckedItemList();
    }

  ngOnInit() { 
    let displayData: noMatchedData[] = [];
    this.data.searchRecord.forEach((element , index) => {
    if(element.title === 'Mobile Phone #') {
      displayData.push({
        details: element.title,
        searchCriteria: `+${element.code} ${element.value}`,
        recordValue: this.data.transfereeRecord[index],
        isSelected: true
      });
    } 
    else if(element.title === 'Full Name') {
      displayData.push({
        details: element.title,
        searchCriteria: `${element.name1},${element.name2}`,
        recordValue: this.data.transfereeRecord[index],
        isSelected: true
      });
    }
    else if(element.title === 'SSO#') {
      displayData.push({
        details: element.title,
        searchCriteria: element.value,
        recordValue: this.data.transfereeRecord[index],
        isSelected: false
      });
    } else {
      displayData.push({
        details: element.title,
        searchCriteria: element.value,
        recordValue: this.data.transfereeRecord[index],
        isSelected: true
      });
    }
    });
    this.dataSource = new MatTableDataSource(displayData)
    
  }

  onClose():void{
    this.dialogRef.close({
      action:'dismiss'
    });
  }
  onConfirm(): void {
    let transferee = [];
    this.dataSource.data.forEach(element => {
      if(!element.isSelected) {
        transferee.push(element.details)
      } 
    });
    this.dialogRef.close({
      data: transferee,
      action: 'dismiss'
    });
  }

   // The  checkbox will check/ uncheck all items
   checkUncheckAll() {
    for (var i = 0; i < this.dataSource.data.length; i++) {
      this.dataSource.data[i].isSelected = !this.isChecked;
    }
    this.getCheckedItemList();
  }

   // Check All toggle 
   isAllSelected() {
    this.isChecked = this.dataSource.data.every(function(item:any) {
        return item.isSelected == false;
      })
    this.getCheckedItemList();
  }


   // Get List of Checked Items
   getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.dataSource.data.length; i++) {
      if(this.dataSource.data[i].isSelected)
      this.checkedList.push(this.dataSource.data[i]);
    }
  }

}

import { Injectable } from '@angular/core';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { FileStatus } from '../models/file-status.model';

/** FileStatusService to fetch the list of file status from API */
@Injectable({
  providedIn: 'root'
})
export class FileStatusService {

  /** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
   */
  constructor(private readonly baseClient: BaseClientService, private readonly logSvc: RemoteLoggingService) { }

  /** getFileStatuses() to fetch file status from Api */
  getFileStatuses(): Observable<FileStatus[]> {
    return this.baseClient
      .getArr<FileStatus>(`v1/filestatus`, 'get the file status')
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const emptyArray: FileStatus[] = [];
          this.logSvc.logError(err);
          return of(emptyArray);
        })
      );
  }
}

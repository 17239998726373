// tslint:disable-next-line: max-line-length
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { FilterSelectionOption } from '../../models/common/filter-selection-option.model';

/** FilterSelectionComponent for the list of check boxes*/
@Component({
  selector: 'app-filter-selection',
  templateUrl: './filter-selection.component.html',
  styleUrls: ['./filter-selection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterSelectionComponent implements OnInit, OnChanges {

  /** the data to be displayed */
  private _dataSet: any[] = [];
  @Input()
  set dataSet(value) {
    if (value) {
      this._dataSet = value;
      if (this.filterOptions.type !== 'locations') {
        this.selectedOptions = [...this.selectedDataSet];
      }
    }
  }
  get dataSet() {
    return this._dataSet;
  }

  @Input() PlaceHolder: string;

  /** used for element's aria-labelledby */
  @Input() labelId: string; 

  searchText: string;

  @Input() filterOptions: FilterSelectionOption;

  @ViewChild('checkBoxList') checkBoxList: MatSelectionList;

  @ViewChildren('muliptleList') multiCheckBoxList: QueryList<MatSelectionList>;

  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  private _selectedDataSet: [];

  @Output()
  selectedDataSetChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  set selectedDataSet(value: any) {
    if (value) {
      this._selectedDataSet = value;
      if (this._selectedDataSet.length < 1 && this.filterOptions) {
        this.searchText = '';
        if (this.filterOptions.type === 'locations' && this.multiCheckBoxList) {
          this.multiCheckBoxList.forEach(item => item.deselectAll());
          this.selectedLength = 0;
          this.dataSet = [];
        } else if (this.checkBoxList) {
          this.checkBoxList.deselectAll();
          this.selectedOptions = [];
          setTimeout(() => {
            this.search.emit('');
          });
        }
        this.searchText = '';
      }
      this.selectedDataSetChange.emit(this._selectedDataSet);
    }
  }

  get selectedDataSet() {
    return this._selectedDataSet;
  }

  selectedLength: Number = 0;

  selectedOptions = [];

  /**Base Constructor */
  constructor() {
    this.selectedDataSet = [];
    this.filterOptions = new FilterSelectionOption({});
  }

  fetchResult() {
    clearTimeout();
    if (this.searchText.length >= this.filterOptions.searchCharLimit) {
      setTimeout(() => {
        this.search.emit(this.searchText);
      }, this.filterOptions.searchDelayInMilliSeconds);
    } else {
      if (this.filterOptions.type === 'locations') {
        this.dataSet = [];
      } else if (this.filterOptions.type === 'policy') {
        this.search.emit('');
      }
    }
  }

  /** clearAll() varaible to clear the selected options */
  clearAll() {
    if (this.filterOptions.type === 'locations') {
      this.multiCheckBoxList.forEach(item => item.deselectAll());
      this.selectedLength = 0;
      this.selectedDataSet = [];
    } else {
      this.checkBoxList.deselectAll();
      this.selectedDataSet = [];
      this.selectedOptions = [];
    }
  }

  clearInputText() {
    this.searchText = '';
    if (this.filterOptions.type === 'locations') {
      this.dataSet = [];
    } else {
      this.search.emit('');
    }
  }

  /** Component Angular initialization lifecycle hook */
  ngOnInit() {
    if (this.filterOptions && this.filterOptions.type === 'locations' && this.selectedDataSet) {
      this.selectedLength = 0;
      this.selectedDataSet.forEach(item => {
        this.selectedLength += item.values.length;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) { }

  setSelectedOptions(name?: string) {
    if (this.filterOptions.type === 'locations') {
      if (!this.selectedDataSet || this.selectedDataSet.length === 0) {
        this.dataSet.forEach((i) => {
          const item = {
            name: i.name,
            label: i.label,
            values: []
          };
          this.selectedDataSet.push(item);
        });
      }
      if (this.multiCheckBoxList) {
        this.multiCheckBoxList.forEach(
          (item, index) => {
            const options = item.options.map(i => i.value);
            const values = item.selectedOptions.selected.map(i => i.value);
            if (options && options.length > 0) {
              const dataSet = this.selectedDataSet.find(i => i.label === options[0].label);
                dataSet.values.forEach((val, ind) => {
                if (options.findIndex(i => i.label === dataSet.label && i.value === val) > -1
                  && values.findIndex(i => i.label === dataSet.label && i.value === val) < 0) {
                  dataSet.values.splice(ind, 1);
                }
              });
              values.forEach(data => {
                if (dataSet.values.findIndex(i => i === data.value && dataSet.label === data.label) < 0) {
                  dataSet.values.push(data.value);
                }
              });
            }
          }
        );
      }

      this.selectedLength = 0;
      this.selectedDataSet.forEach(item => {
        this.selectedLength += item.values.length;
      });
    } else {
      if (this.selectedDataSet && this.selectedDataSet.length > 0) {
        this.selectedDataSet.forEach((item, index) => {
          const selectedIndex = this._dataSet.findIndex(i => i.value === item);
          if (selectedIndex > -1 && this.selectedOptions.findIndex(i => i === item) < 0) {
            this.selectedDataSet.splice(index, 1);
          }
        });
      }
      this.selectedOptions.forEach(item => {
        if (this.selectedDataSet.findIndex(i => item === i) < 0) {
          this.selectedDataSet.push(item);
        }
      });
      this.selectedOptions = [...this.selectedDataSet];
    }
    this.selectedDataSet = [...this.selectedDataSet];
  }
  checkSelected(name, value) {
    if (this.selectedDataSet && this.selectedDataSet.length > 0) {
      const filter = this.selectedDataSet.find(i => i.name === name);
      if (filter && filter.values.length > 0 && filter.values.findIndex(i => i === value) > -1) {
        return true;
      }
    }
    return false;
  }

}

import { Injectable } from '@angular/core';
import currencyMapping from '../data/currencyLocale.json';

/** Service to handle formatting of numbers different purposes. */
@Injectable({
  providedIn: 'root'
})
export class NumbersService {

  /** initializer, nothing at the moment */
  constructor() { }

  /**
   * convert a currency amount into a formatted string for a specific currency
   * All currencies have a specified Locale for proper formatting
   * Currencies for countries that speak Arabic, Farci, and Hebrew have been forced to output latin numbers
   * @param amount the amount to be formatted
   * @param currISOCode the 3 character ISO code of the currency for the amount
   * @param useBrowserLocale use the brower's Locale instead of the one specified for the currency.  False by default.
   */
  formatForCurrency(amount: number, currISOCode: string, useBrowserLocale: boolean = false ): string {
    if (currISOCode && currISOCode.length > 0) {
      let IntlFormatter: Intl.NumberFormat;
      if (useBrowserLocale) {
        IntlFormatter = new Intl.NumberFormat(undefined,
          { style: 'currency', currency: currISOCode});
      } else {
        const rec = currencyMapping.mapping.find(item => item.currency === currISOCode);
        IntlFormatter = new Intl.NumberFormat(rec.locale,
          { style: 'currency', currency: currISOCode});
      }
      return IntlFormatter.format(amount).replace('\u00A0', ' ');
    } else if (amount) {
        return amount.toString();
    } else {
      return null;
    }
  }
}

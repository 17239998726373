import { Injectable, HostListener, NgZone } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { AppConfigService } from './app-config.service';
/*import { CollaborationService } from './collaboration.service';
import { CollabEvents } from './collaboration-events.service';*/
import { BehaviorSubject } from 'rxjs';
import { UserContextService } from './user-context.service';

@Injectable({
  providedIn: 'root'
})
export class IdleTimeoutService {
  
  /** Keeps all the data related to Timeout */
  public timeoutData = new BehaviorSubject<any>(null);
  public timeoutData$ = this.timeoutData.asObservable();
  
  constructor() {
  }
}

import { finalize, tap } from 'rxjs/operators';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()

export class LoaderHttpInterceptor implements HttpInterceptor {

    constructor(
      private spinner: NgxSpinnerService
      ) {
      }
    count = 0;
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.spinner.show();
        this.count++;
        return next.handle(req)
            .pipe(tap(
                event => (event),
                error => (error),
            ), finalize(() => {
                // tslint:disable-next-line: rxjs-no-unsafe-scope
                this.count--;
                // tslint:disable-next-line: rxjs-no-unsafe-scope
                if (this.count === 0) {
                    this.spinner.hide();
                }
            })
            );
    }
}

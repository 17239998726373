<h2>Details by {{aggregationTypeDesc}}</h2>
<div class="aggregationView mat-elevation-z8" id="aggregationView">
  <div class="table-scroll">
    <table mat-table class="aggregateTable" [dataSource]="dataSource" cdkDropListGroup matSort
      (matSortChange)="clearInitialSort()">
      <ng-container *ngFor="let column of orderColumns; let i = index">
                   <!-- Sticky first column -->
      <ng-container *ngIf="column.field === 'aggregationName'" [matColumnDef]="column.field" sticky>

        <!-- Table Header -->
        <div *ngIf="column.field !== 'aggregationName'; else nonSortableColumns">
          <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDropList
            [ngClass]="{'initialColumnSelected': initialSortColumn === column.field,'moveriskalign':column.header == 'Move Risk Status' }" cdkDropListLockAxis="x"
            cdkDropListOrientation="horizontal" (cdkDropListDropped)="dragDrop($event, i)"
            (cdkDragStarted)="dragStarted($event, i)" cdkDrag [@.disabled]="initialSortColumn === column.field"
            [start]=" initialSortColumn === column.field ? initialSortDirection : null"
            cdkDragBoundary=".aggregateTable">
            {{ column?.header ? column.header : firstHeaderColumn }}
            <div fxLayout="row" fxFlex="0 1 150px" fxLayoutAlign="center" class="dragPreview" *cdkDragPreview
              [innerHTML]="column?.header ? column.header : firstHeaderColumn">
              {{ column?.header ? column.header : firstHeaderColumn }}</div>
          </th>
        </div>

        <!-- Non Sortable Table Header  -->
        <ng-template #nonSortableColumns>
          <th mat-header-cell *matHeaderCellDef mat-sort-header [@.disabled]="initialSortColumn === column.field"
            [ngClass]="{'initialColumnSelected': initialSortColumn === column.field,'moveriskalign':column.header == 'Move Risk Status' }">
            {{ column.header }}
            <div fxLayout="row" fxFlex="0 1 150px" fxLayoutAlign="center"
              [innerHTML]="column?.header ? column.header : firstHeaderColumn">
              {{ column?.header ? column.header : firstHeaderColumn }}
            </div>
          </th>
        </ng-template>

        <!-- Table Data Cell -->
        <td mat-cell *matCellDef="let element" [ngClass]="{'colDrag': dragColumnName === column.field }">

          <!-- Aggregation type Cell -->
          <div *ngIf="column.field === 'aggregationName'" sticky>
            <div
              (click)="element.aggregationType == 'divisionNumber' ? setActive.emit([aggregationType, {divisionName : element.divisionName, divisionNumber : element.divisionNumber}]) : setActive.emit([aggregationType, element.aggregationName])"
              (keypress)="element.aggregationType == 'divisionNumber' ? setActive.emit([aggregationType, {divisionName : element.divisionName, divisionNumber : element.divisionNumber}]) : setActive.emit([aggregationType, element.divisionName])"
              [attr.aria-label]="firstHeaderColumn"> <a tabindex="0">
                <span
                  *ngIf="aggregationType == 'divisionName'">{{element.divisionNumber === null || element.divisionNumber === '' ? 'N/A' : element.divisionName}}
                  ( {{element.divisionNumber}} )</span>
                <span
                  *ngIf="aggregationType != 'divisionName'">{{element.aggregationName === null || element.aggregationName === '' ? 'N/A' : element.aggregationName}}</span></a>
              <!--<a tabindex="0">{{ element.aggregationName !== null || element.aggregationName !== '' ? element.aggregationName : 'N/A' | highlightSearch: filterText }}</a>-->
            </div>
          </div>

          <!-- Active Employees Cell -->
          <div class="activeemployees" *ngIf="column.field === 'activeEmployees'">
            <div [innerHTML]="element.activeEmployees | highlightSearch: filterText">
              {{ element.activeEmployees | highlightSearch: filterText }}
            </div>
          </div>

          <!-- Current YTD Costs Cell -->
          <div *ngIf="column.field === 'currentYtdCosts'">
            <span [innerHTML]="element.formattedCurrentYTD | highlightSearch: filterText">
              {{ element.formattedCurrentYTD | highlightSearch: filterText }}
            </span>
          </div>

          <!-- Past YTD Costs Cell -->
          <div *ngIf="column.field === 'pastYtdCosts'">
            <span [innerHTML]="element.formattedPastYTD | highlightSearch: filterText">
              {{ element.formattedPastYTD | highlightSearch: filterText }}
            </span>
          </div>

          <!-- %Change of Total Cost Cell -->
          <div class="percentchgoftc" *ngIf="column.field === 'changeOfTotalCost'">
            <div class="arrowStyle">
              <mat-icon *ngIf="element.changeOfTotalCost !== 'N/A' && element.changeOfTotalCost < 0">arrow_downward
              </mat-icon>
              <mat-icon *ngIf="element.changeOfTotalCost !== 'N/A' && element.changeOfTotalCost >= 0">arrow_upward
              </mat-icon>
              <span
                [innerHTML]="element.changeOfTotalCost !== 'N/A' ? ((element.changeOfTotalCost * 100) | number:'1.0-2' | highlightSearch: filterText) + '%': 'N/A'">
                {{ element.changeOfTotalCost !== 'N/A' ? ((element.changeOfTotalCost * percent) | highlightSearch: filterText) + '%': 'N/A' }}
              </span>
            </div>
          </div>

          <!-- Move Risk Cell -->
          <div *ngIf="column.field === 'moveAtRiskCount'">
            <div>
              <app-move-risk fxFlex="500px"
                [moveRiskData]="moveRiskData(element.moveAtRiskCount, element.moveOnTrackCount, element.moveRiskCompleteCount, element.moveRiskNACount)">
              </app-move-risk>
            </div>
          </div>

          <!-- Current Ytd Curr Column -->

          <div *ngIf="column.field === 'currentYtdCurr'" hidden>
          </div>

        </td>
      </ng-container>
                    <!-- Rest columns -->
      <ng-container *ngIf="column.field !== 'aggregationName'" [matColumnDef]="column.field">

          <!-- Table Header -->
          <div *ngIf="column.field !== 'aggregationName'; else nonSortableColumns">
            <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDropList
              [ngClass]="{'initialColumnSelected': initialSortColumn === column.field,'moveriskalign':column.header == 'Move Risk Status' }" cdkDropListLockAxis="x"
              cdkDropListOrientation="horizontal" (cdkDropListDropped)="dragDrop($event, i)"
              (cdkDragStarted)="dragStarted($event, i)" cdkDrag [@.disabled]="initialSortColumn === column.field"
              [start]=" initialSortColumn === column.field ? initialSortDirection : null"
              cdkDragBoundary=".aggregateTable">
              {{ column?.header ? column.header : firstHeaderColumn }}
              <div fxLayout="row" fxFlex="0 1 150px" fxLayoutAlign="center" class="dragPreview" *cdkDragPreview
                [innerHTML]="column?.header ? column.header : firstHeaderColumn">
                {{ column?.header ? column.header : firstHeaderColumn }}</div>
            </th>
          </div>
  
          <!-- Non Sortable Table Header  -->
          <ng-template #nonSortableColumns>
            <th mat-header-cell *matHeaderCellDef mat-sort-header [@.disabled]="initialSortColumn === column.field"
              [ngClass]="{'initialColumnSelected': initialSortColumn === column.field,'moveriskalign':column.header == 'Move Risk Status' }">
              {{ column.header }}
              <div fxLayout="row" fxFlex="0 1 150px" fxLayoutAlign="center"
                [innerHTML]="column?.header ? column.header : firstHeaderColumn">
                {{ column?.header ? column.header : firstHeaderColumn }}
              </div>
            </th>
          </ng-template>
  
          <!-- Table Data Cell -->
          <td mat-cell *matCellDef="let element" [ngClass]="{'colDrag': dragColumnName === column.field }">
  
            <!-- Aggregation type Cell -->
            <div *ngIf="column.field === 'aggregationName'" sticky>
              <div
                (click)="element.aggregationType == 'divisionNumber' ? setActive.emit([aggregationType, {divisionName : element.divisionName, divisionNumber : element.divisionNumber}]) : setActive.emit([aggregationType, element.aggregationName])"
                (keypress)="element.aggregationType == 'divisionNumber' ? setActive.emit([aggregationType, {divisionName : element.divisionName, divisionNumber : element.divisionNumber}]) : setActive.emit([aggregationType, element.divisionName])"
                [attr.aria-label]="firstHeaderColumn"> <a tabindex="0">
                  <span
                    *ngIf="aggregationType == 'divisionName'">{{element.divisionNumber === null || element.divisionNumber === '' ? 'N/A' : element.divisionName}}
                    ( {{element.divisionNumber}} )</span>
                  <span
                    *ngIf="aggregationType != 'divisionName'">{{element.aggregationName === null || element.aggregationName === '' ? 'N/A' : element.aggregationName}}</span></a>
                <!--<a tabindex="0">{{ element.aggregationName !== null || element.aggregationName !== '' ? element.aggregationName : 'N/A' | highlightSearch: filterText }}</a>-->
              </div>
            </div>
  
            <!-- Active Employees Cell -->
            <div class="activeemployees" *ngIf="column.field === 'activeEmployees'">
              <div [innerHTML]="element.activeEmployees | highlightSearch: filterText">
                {{ element.activeEmployees | highlightSearch: filterText }}
              </div>
            </div>
  
            <!-- Current YTD Costs Cell -->
            <div *ngIf="column.field === 'currentYtdCosts'">
              <span [innerHTML]="element.formattedCurrentYTD | highlightSearch: filterText">
                {{ element.formattedCurrentYTD | highlightSearch: filterText }}
              </span>
            </div>
  
            <!-- Past YTD Costs Cell -->
            <div *ngIf="column.field === 'pastYtdCosts'">
              <span [innerHTML]="element.formattedPastYTD | highlightSearch: filterText">
                {{ element.formattedPastYTD | highlightSearch: filterText }}
              </span>
            </div>
  
            <!-- %Change of Total Cost Cell -->
            <div class="percentchgoftc" *ngIf="column.field === 'changeOfTotalCost'">
              <div class="arrowStyle">
                <mat-icon *ngIf="element.changeOfTotalCost !== 'N/A' && element.changeOfTotalCost < 0">arrow_downward
                </mat-icon>
                <mat-icon *ngIf="element.changeOfTotalCost !== 'N/A' && element.changeOfTotalCost >= 0">arrow_upward
                </mat-icon>
                <span
                  [innerHTML]="element.changeOfTotalCost !== 'N/A' ? ((element.changeOfTotalCost * 100) | number:'1.0-2' | highlightSearch: filterText) + '%': 'N/A'">
                  {{ element.changeOfTotalCost !== 'N/A' ? ((element.changeOfTotalCost * percent) | highlightSearch: filterText) + '%': 'N/A' }}
                </span>
              </div>
            </div>
  
            <!-- Move Risk Cell -->
            <div *ngIf="column.field === 'moveAtRiskCount'">
              <div>
                <app-move-risk fxFlex="500px"
                  [moveRiskData]="moveRiskData(element.moveAtRiskCount, element.moveOnTrackCount, element.moveRiskCompleteCount, element.moveRiskNACount)">
                </app-move-risk>
              </div>
            </div>
  
            <!-- Current Ytd Curr Column -->
  
            <div *ngIf="column.field === 'currentYtdCurr'" hidden>
            </div>
  
          </td>
        </ng-container>

      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div>
    <mat-paginator [pageSizeOptions]="[25, 50, 75]"></mat-paginator>
  </div>
</div>
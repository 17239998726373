import { Component, OnInit } from '@angular/core';

/** TermsOfUseComponent to view the terms of use */
@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  /** Base constructor method */
  constructor() { }

  /** ngOnInit to initialize methods and variables */
  ngOnInit() {
  }
}

import { UntypedFormGroup, ValidatorFn } from "@angular/forms";
import moment from "moment";
 
export const validateAssignmentDates = (): ValidatorFn => {
    return (form: UntypedFormGroup): { [key: string]: boolean } | null => {
        const formStartDate = form.get('assignmentStartDate').value;
        const formEndate = form.get('assignmentEndDate').value;

        if (!formStartDate || !formEndate) {
            return;
        }
        const assignStartdate =  moment(formStartDate).format('YYYY-MM-DD');
        const assignEndDate = moment(formEndate).format('YYYY-MM-DD');
        if (new Date(assignStartdate).getTime() > new Date(assignEndDate).getTime()) {
            const err = { incorrect: true };
            form.get('assignmentEndDate').setErrors(err);
            form.get('assignmentEndDate').markAsTouched();
            return err;
        } else {
            const incorrect = form.get('assignmentEndDate').hasError('incorrect');
            if (incorrect) {
                delete form.get('assignmentEndDate').errors['incorrect'];
                form.get('assignmentEndDate').updateValueAndValidity();
            }
        }
    };
}


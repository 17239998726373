import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdministrationComponent } from './administration.component';
import { ListComponent } from './components/list/list.component';
import { OriginViewSubmissionComponent } from './components/submission-failure/origin-view-submission/origin-view-submission.component';
import { DetailComponent } from './modules/program/detail/detail.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'administration',
    pathMatch: 'full'
  },
  {
    path: 'administration',
    component: AdministrationComponent,
    data: { navId: 1, title: 'Administration', showGlobalTemplate: true },
    children: [
      {
        path: '',
        component: ListComponent
      },
      {
        path: 'client-detail',
        component: ListComponent
      },
    ]
  },
  {
    path: 'original-submission',
    component: OriginViewSubmissionComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministrationRoutingModule { }


/**
 *
 * Client Contact    -     Program Admin
 * /administration
 */
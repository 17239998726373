import { Component, Input } from '@angular/core';

/** Multi-step component to show incremental steps and progress */
@Component({
  selector: 'app-multi-step',
  templateUrl: './multi-step.component.html',
  styleUrls: ['./multi-step.component.scss'],
})
export class MultiStepComponent {
  /** Input for number of steps **/
  @Input() steps: number;
  /** Input for selected step */
  @Input() selected: number;
}

import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as transfereeColumns from '../../../../core/data/transfereeDataView.json';
import * as transfereeColumnDisplay from '../../../../core/data/transfereeColumnDisplay.json';
import * as programColumns from '../../../../core/data/programDataView.json';
import { ClientContactsService } from '../../services/client-contacts.service';
@Component({
  selector: 'admin-dataview-selection-modal',
  templateUrl: './admin-dataview-selection-modal.component.html',
  styleUrls: ['./admin-dataview-selection-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminDataviewSelectionModalComponent implements OnInit {

  defaultColumn = [];
  optionsValue = [];
  current_selected: any;
  private displayedColumns = [];
  columnOrder: any = [];
  selectedOptions: any;
  selectedColumnList: any;

  transfereeColumnsList: any = (transfereeColumns as any).default;
  programColumnsList: any = (programColumns as any).default;
  transfereeColumnsDisplay: any = (transfereeColumnDisplay as any).default;
  defaultColOrder: any = (transfereeColumnDisplay as any).default;
  
  /** default columns values when modal opened */
  initialDefaultColumns: any = [];
  isMMUser: any;
  
  /** Base constructor method
    * @param dialogRef MatDialogRef<FilterModalComponent> injection

    */

  constructor(public dialogRef: MatDialogRef<AdminDataviewSelectionModalComponent>,
    private clientContactsService: ClientContactsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    /**Default table columns API  */
    this.isMMUser = this.clientContactsService.isMMUser;
    const transfereeResponse = this.transfereeColumnsDisplay;
      this.selectedColumnList = this.data.dataKey == 'TransfereeListViewColumnDisplay' ? [...this.transfereeColumnsList] : [...this.programColumnsList];
      if (transfereeResponse) {
        transfereeResponse.forEach((item) => {
          if (item.preferenceconfig.name == this.data.dataKey) {
            this.defaultColumn = item.preferenceconfig.default;
            this.optionsValue = item.preferenceconfig.options;
            this.defaultColOrder =  JSON.parse(JSON.stringify(item.preferencesetting.colOrder));
            this.defaultColumn.forEach((col) => {
              if(col !== 'select') {
              const column = this.selectedColumnList.filter((value) => {
                  return value.field === col;
               });
               this.columnOrder.push({
                 header: column[0].header,
                 checked: false,
                 field: column[0].field
               });
              }
             });
            if ('preferencesetting' in item && item.preferencesetting.value.length > 0) {
              this.displayedColumns = item.preferencesetting.value;
              if(this.isMMUser) {
                this.displayedColumns = this.displayedColumns.filter((items) => !items.includes('totalPoints'));
                this.displayedColumns = this.displayedColumns.filter((items) => !items.includes('pointsAssigned'));
              } else {
                this.displayedColumns = this.displayedColumns.filter((items) => !items.includes('totalAmount'))
              }
            } else {
              this.displayedColumns = item.preferenceconfig.default;
              this.defaultColumn = item.preferenceconfig.default;
            }
          }
        });
        this.displayedColumns.forEach(item => {
          if(this.isMMUser) {
            this.columnOrder = this.columnOrder.filter((items) => items.field !== 'totalPoints');
            this.columnOrder = this.columnOrder.filter((items) => items.field !=='pointsAssigned')
          } else {
            this.columnOrder = this.columnOrder.filter((items) => items.field !== 'totalAmount')
          }
          const colOrderindex = this.columnOrder.findIndex(x => x.field == item);
          
          if (colOrderindex > -1) {
            this.columnOrder[colOrderindex].checked = true;
          }

        });
        this.initialDefaultColumns = JSON.parse(JSON.stringify(this.displayedColumns));
      }
  }


  isDisabledColumns(columnName) {
    const defaultIndex = this.defaultColumn.findIndex(x => x == columnName);
    if (defaultIndex > -1) {
      const optionIndex = this.optionsValue.findIndex(x => x == columnName);
    if (optionIndex == -1) {
      return true;
    }
  }
return false;
  }

/** Onselection of check box */
  onSelection(e) {
    this.current_selected = e.options[0]?.value;
    // tslint:disable-next-line: triple-equals
    let colOrder = [];
    const colOrderindex = this.columnOrder.findIndex(x => x.field == e.options[0].value.field);
    const index = this.displayedColumns.indexOf(e.options[0].value.field);
    this.selectedColumnList.forEach((arrayItem) => {
      if (arrayItem.header == e.options[0].value.header) {
        if (e.options[0].selected == true) {
          if (index == -1) {
            this.displayedColumns.push(e.options[0].value.field);
            this.columnOrder[colOrderindex].checked = true;
            return 1;
          }
        } else {
          if (index !== -1) {
            this.displayedColumns.splice(index, 1);
            this.columnOrder[colOrderindex].checked = false;
          }

        }
        this.transfereeColumnsDisplay.forEach((item) => {
          if (item.preferenceconfig.name == this.data.dataKey) {
            item.preferencesetting.value = this.displayedColumns;
          }
        });
      }
    });
    this.defaultColOrder.forEach(col => {
      if (this.displayedColumns.includes(col)){
        colOrder.push(col);
      }
    });
    colOrder.length > 0 ? this.displayedColumns = colOrder : null;
  }

/** Secleting the table click on OK button columns */
  selectedListItems() {
    this.transfereeColumnsDisplay.forEach((item) => {
      if (item.preferenceconfig.name == this.data.dataKey) {
        item.preferencesetting.value = this.displayedColumns;
      }
    });
    this.dialogRef.close(this.displayedColumns);
  }

  /*** Reset the table columns and display the default columns */

  reset() {
    this.displayedColumns = [];
    this.selectedColumnList.forEach(item => {
      const index = this.columnOrder.findIndex(x => x.field == item.field);
      if (index > -1) {
        this.columnOrder[index].checked = item.checked;
        item.checked ? this.displayedColumns.push(item.field) : null;
        }
    });
    this.transfereeColumnsDisplay.forEach((item) => {
      if (item.preferenceconfig.name == this.data.dataKey) {
        item.preferencesetting.value = this.displayedColumns;
      }
    });
  }

  close() {
    this.transfereeColumnsDisplay.forEach((item) => {
      if (item.preferenceconfig.name == this.data.dataKey) {
        item.preferencesetting.value = this.initialDefaultColumns;
      }
    });
    this.dialogRef.close(this.initialDefaultColumns);
  }
}

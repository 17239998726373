/** Model for Benefit */
export interface Benefit {
    reference?: string;
    productSpecificationReference?: any;
    category?: string;
    displayName?: string;
    description?: string;
    points?: any;
    suppress?: boolean;
    productId?: string;
    hvgDetail?: HVGDetail;
    confirmedDate?: string;
  }
  
  export interface HVGDetail {
    allHighValueTaskOptOut?: boolean;
    allHighValueTaskComplete?: boolean;
    highValueItems?: HighValueItem[];
    date?: string;
    status?: string;
    totalReplacementValue?: any;
  }
  
  export interface HighValueItem {
     id: string;
     name: string;
     replacementValue: number;
     description: string;
     serialNumber: string;
     dateAdded: string;
  }
  
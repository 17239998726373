<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column">
    <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px" fxLayoutGap.xs="0em">
        <div class="candidate-dialogtitle"> {{ title }}</div>
        <a href="#" class="close-icon" (click)="onDismiss($event)" title="close">
            <mat-icon>close</mat-icon>
        </a>
    </div>
    <mat-dialog-content class="middle-section" [ngStyle] = "{'overflow': 'hidden' }">
            <mat-divider></mat-divider>
        
        <mat-list [ngStyle] = "{'max-height': '250px', 'overflow-y': 'auto' }">
            <ng-container *ngIf="data.type !== 'payment'">
                <div *ngFor="let overrideHistory of getHistoryData() | arrayIterationOrder" fxLayout="column" >
                    <div fxFlex="100%" fxLayout="column" fxLayoutGap="20px">
                        <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between">
                            <span>{{overrideLabel}}:</span> <span>{{ overrideHistory?.points }} {{ overrideHistory?.amount }} {{ overrideHistory?.currency }}</span>
                        </div>
                        <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between">
                            <span>{{overrideByLabel}}:</span>                         <span><mat-chip
                                color="primary" 
                                selected>
                                {{ overrideHistory.nameDetails.firstName | nameInitials }} {{ overrideHistory.nameDetails.lastName | nameInitials }}
                                </mat-chip>
                            {{ overrideHistory.nameDetails.firstName }} {{ overrideHistory.nameDetails.lastName }}</span>
                        </div>
                        <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between">
                            <span>{{overrideDateLabel}}:</span>  <span>{{ overrideHistory.date }}</span>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                </div>
            </ng-container>
            
            <ng-container *ngIf="data.type === 'payment'">
                <div *ngFor="let overrideHistory of getHistoryData() | arrayIterationOrder" fxLayout="column" >
                    <div fxFlex="100%" fxLayout="column" fxLayoutGap="20px">
                        <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between">
                            <span>{{overrideLabel}}:</span> <span>{{ overrideHistory?.newDate }}</span>
                        </div>
                        <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between">
                            <span>{{overrideByLabel}}:</span>                         <span><mat-chip
                                color="primary" 
                                selected>
                                {{ overrideHistory.nameDetails.firstName | nameInitials }} {{ overrideHistory.nameDetails.lastName | nameInitials }}
                                </mat-chip>
                            {{ overrideHistory.nameDetails.firstName }} {{ overrideHistory.nameDetails.lastName }}</span>
                        </div>
                        <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between">
                            <span>{{overrideDateLabel}}:</span>  <span>{{ overrideHistory.updatedAt }}</span>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                </div>
            </ng-container>
            
        </mat-list>

        <div fxLayout="column">
            <ng-container *ngIf="data.type !== 'payment'">
                <div *ngIf="candidateProfile.programDetails?.pointOverride"  fxFlex="100%" fxLayout="row" fxLayoutGap="20px">
                    <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between">
                        <span>{{defaultLabel}}:</span><span>{{ candidateProfile.programDetails?.originalPoints }}</span>
                    </div>
                </div>
                <div *ngIf="candidateProfile.programDetails?.amountOverride" fxFlex="100%" fxLayout="row" fxLayoutGap="20px">
                    <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between">
                        <span>{{defaultLabel}}:</span><span>{{ candidateProfile.programDetails?.originalAmount }} {{ candidateProfile.programDetails?.originalCurrency }}</span>
                    </div>
                </div>
            </ng-container>
            <div *ngIf="data.type === 'payment'" fxFlex="100%" fxLayout="row" fxLayoutGap="20px">
                <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between">
                    <span>{{defaultLabel}}:</span><span>{{ candidateProfile?.details?.defaultPaymentEligibilityDate }}</span>
                </div>
            </div>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions class="statusBtn add-dialogbtn" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" fxLayoutGap.xs="0px" fxLayoutAlign.xs="space-between center">
        <button fxFlex="35%" type="button" mat-raised-button class="mat-button text-button" (click)="onDismiss($event)" color="primary" id="cancel">CLOSE</button>
     </mat-dialog-actions>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import {MatDialogContent,MAT_DIALOG_DATA} from '@angular/material/dialog';
@Component({
  selector: 'app-customdata-delete-confirm',
  templateUrl: './customdata-delete-confirm.component.html',
  styleUrls: ['./customdata-delete-confirm.component.scss']
})
export class CustomdataDeleteConfirmComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data:any ) {
   }

  ngOnInit() {
  }

}

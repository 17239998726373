import { Injectable, Injector } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import * as StackTrace from 'stacktrace-js';
import { AppConfigService } from './app-config.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';

/**
 * remote logging service for sending errors to API for standard logging solution
 */
@Injectable({
  providedIn: 'root'
})
export class RemoteLoggingService {
  /** standard constructor for services */
  constructor(
    private readonly injector: Injector,
    private readonly http: HttpClient,
    private readonly appConfig: AppConfigService,
    private readonly cookieService: CookieService,
    private readonly toastrService: ToastrService
  ) { }

  /**
   * Post message to server
   * @param description the description to log
   * @param url the URL
   * @param stack the callstack, if available
   * @param isError is the message an error
   */
  private postToServer(description: string, url: string, stack?: string, isError?: boolean) {
    const headers = { // Manually get headers we send to API calls
      'Authorization': this.cookieService.get('car-ses-tok'),
      'app-context': sessionStorage.getItem('car-ses-con')
    };
    let title = isError ? 'Error: ' : 'Info: ';
    title += url;
    const error = {
      processName: 'movepro360',
      title: title,
      description: description,
      details: JSON.stringify(headers) + '\n\n' + stack
    };
    const logUrl: string = <string>this.appConfig.getConfig('logapi');
    const postHeaders = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post<string>(logUrl + 'v1/error', error, { headers: postHeaders }).pipe(
        catchError((err) => {
          console.error('FAILURE: Unable to log error to API', err);
          return of(null);
        })
    ).toPromise();
  }

  /**
   * log a message
   * @param message the message
   * @param url the URL, if available
   * @param stack the callstack, if available
   * @param isError is the message an error
   */
  public log(message: string, url: string, stack?: string, isError?: boolean) {
    const consoleMsg = `Logging to server - message: ${message}  URL: ${url}  stack: ${stack}`;
    if (isError && !consoleMsg.includes('SSO')) {
      console.error(consoleMsg);
      this.toastrService.error(
        'An error has occurred, please try again.',
        null,
        {
          disableTimeOut: true // User must explicitly dismiss error messages
        }
      );
    } else {
      console.log(consoleMsg);
    }
    this.postToServer(message, url, stack, isError);
  }

  /**
   * log an error
   * @param error the error that occurred
   */
  public logError(error: any) {
    try {
      const message = error.message ? error.message : error.toString();
      const url = this.getUrl();
      if (error instanceof Error) {
        // get the stack trace, lets grab the last 10 stacks only
        StackTrace.fromError(error).then(stackFrames => {
          // log on the server
          const stackString = stackFrames.map(sf => sf.toString()).join('\n');
          this.log(message, url, stackString, true);
        });
      } else {
        this.log(message, url, 'N/A', true);
      }
    } catch (e) {
      this.log('Error while parsing error: ' + error, '', 'N/A', true);
    }
  }

  /** gets the current URL */
  private getUrl() {
    // tslint:disable-next-line: deprecation
    const location = this.injector.get(LocationStrategy);
    const url = location instanceof HashLocationStrategy
      ? location.path(true) || window.location.toString()
      : window.location.toString();
    return url;
  }
}

import { Component, OnInit, Input, Inject, Output, EventEmitter, ViewEncapsulation, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { ProgramManagementService } from '../../services/program-management.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { RemoteLoggingService } from './../../../../core/services/remote-logging.service';
import { ToastrService } from 'ngx-toastr';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectionList, MatListOption } from '@angular/material/list';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-division-association',
  templateUrl: './division-association.component.html',
  styleUrls: ['./division-association.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DivisionAssociationComponent implements OnInit {
  showhidepregnant = false;
  value = 'Clear me';
  divisionForm: UntypedFormGroup;
  division: any;
  clientDivisions: Array<any>;
  searchValue: string = '';
  clientDivisionsList: Array<any>;

  clientDivisionsListCopy: Array<any>;
  selectDivisionsList: Array<any> = [];
  selectedDivision: Array<any> = [];
  selectedOptions: Array<any> = [];
  allOptions: Array<any> = [];
  showOptions: Array<any> = [];
  selectDivisionFlag = false;
  clearOptions: Array<any> = [];
  addedDivision: any;
  arr: UntypedFormArray;
  programName: string;
  allSelectOption: boolean;
  checkedAll: boolean;
  selectAllOptions: MatListOption;
  @ViewChild('allSelect', { static: true }) allSelect: boolean;
  @Output() publishDivisions = new EventEmitter<String>();

  constructor(
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private programService: ProgramManagementService,
    private spinner: NgxSpinnerService,
    private logSrv: RemoteLoggingService,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<DivisionAssociationComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.generateForm();
  }

  ngOnInit() {
    if(this.data && this.data.program.programName) {
      this.programName = this.data.program.programName;
    } else {
      this.programName = this.data.program.name;
    }
    this.allSelect = false;
    this.getClientDivisions();
  }

  generateForm() {
    this.divisionForm = new UntypedFormGroup({
      addDivisions: new UntypedFormArray([]),
    });

  }
  checkForExistingData(allDivisions) {
    if (this.data.program.divisions && this.data.program.divisions.length > 0) {
      if (this.data.program.divisions.length === allDivisions.length) {
        this.allSelect = false;
      } else {
        this.selectDivisionFlag = true;
        this.allSelect = false;
      }
    }
  }
  addDivisionArrayElement(val) {
    this.addDivisions.push(new UntypedFormControl(val, Validators.required));
    this.addDivisions.updateValueAndValidity();

  }

  textSearch() {
    if(this.searchValue.length >= 3 ) {
      let term = this.searchValue.toLowerCase();
      this.clientDivisionsList = this.clientDivisionsListCopy.filter(function (tag) {
        return tag.division_name.toLowerCase().substring(0, term.length) === term;
      });

    } else {
      this.clientDivisionsList = this.clientDivisionsListCopy;
    }
  }

  clearTextSearch(): void {
    this.searchValue = '';
    this.showOptions = [...this.clearOptions];
    this.checkedAll = this.showOptions.length === this.clientDivisionsListCopy.length ? true : false;
    this.clientDivisionsList = this.clientDivisionsListCopy;
  }

  modelChangeFn(): void {
    if (this.allSelect) {
      if(this.checkedAll) {
        this.showOptions = [...this.selectedOptions];
      } else {
        this.showOptions = [];
      }
    } else {
      if(this.checkedAll) {
        this.showOptions = [...this.allOptions];
        this.clearOptions = [...this.allOptions];
      } else {
        this.showOptions = [];
        this.clearOptions = [];
      }
    }
  }

  onToggle(): void {
    if (this.allSelect) {
      const temp = [];
      for (const item of this.clientDivisionsListCopy) {
        if (this.showOptions.indexOf(item.division_id) > -1) {
          temp.push(item)
        }
      }
      this.clientDivisionsList = [...temp];
      this.checkedAll = this.clientDivisionsList.length > 0 ? true : false;
    }
    else if (!this.allSelectOption || !this.allSelect) {
      this.clientDivisionsList = [...this.clientDivisionsListCopy];
      this.checkedAll = this.showOptions.length === this.clientDivisionsList.length ? true : false;
    }
  }
  get addDivisions(): UntypedFormArray {
    return this.divisionForm.get('addDivisions') as UntypedFormArray;
  }

  eventChange(e): void {
    if(this.showOptions.length !== this.clientDivisionsList.length) {
      if(this.checkedAll) {
        this.checkedAll = false;
      }
    } 
      this.clearOptions.includes(e) ? this.clearOptions.splice(this.clearOptions.findIndex(div => div === e), 1) :  this.clearOptions.push(e);
  }

  getClientDivisions() {
    this.programService.getClientDivisions(this.data.clientId).subscribe(data => {
      if (data) {
        this.clientDivisionsList = this.clientDivisions = data.divisions;
        this.clientDivisionsList.sort((a,b) => (a.division_number > b.division_number) ? 1 : ((b.division_number > a.division_number) ? -1 : 0));        for (let div of this.clientDivisionsList) {
          this.allOptions.push(div['division_id']);
        }
        this.clientDivisionsListCopy = [...this.clientDivisionsList];
        if (this.data.program && this.data.program.divisions.length > 0) {
          this.data.program.divisions.forEach(element => {
            for(const item of this.clientDivisionsList) {
              if(item.division_id === element.division_id) {
                this.selectDivisionsList.push(item);
              }
            }
          });

          for (let div of this.selectDivisionsList) {
            this.selectedOptions.push(div['division_id']);
          }
          if (this.selectedOptions.length > 0) {
            this.showOptions = [...this.selectedOptions];
            this.clearOptions = [...this.selectedOptions];
            this.allSelectOption = false;
          } else {
            this.showOptions = [...this.allOptions];
            this.allSelectOption = true;
          }
        } else {
          this.allSelect = false;
          this.allSelectOption = true;
        }

      } else {
        this.logSrv.logError('Failed to load Client Divisions');
      }
    }, () => {
      this.logSrv.logError('Failed to load Client Divisions');
    });
  }



  selectClick(selected: boolean) {
    if (selected) {
      this.allSelect = true;
      this.selectDivisionFlag = true;

    }
    else {
      this.selectDivisionFlag = false;
      this.allSelect = false;
    }
  }

  selectAll(selected: boolean) {
    if (selected) {
      this.allSelect = true;
      this.selectDivisionFlag = false;

    }
  }

  AssociateDivisions() {
    this.addDivisions.push(new UntypedFormControl('', Validators.required));
    this.addDivisions.value.forEach(element => {
      this.selectDivisionsList = this.selectDivisionsList.filter(division => division.division_id !== element.division_id);
    });
  }

  pushBackToList(index: number) {
    const findIndex = this.selectDivisionsList.findIndex(x =>
      x.division_id === this.addDivisions.value[index].division_id);
    let removeValue;
    if (this.addDivisions.length !== 1) {
      removeValue = this.addDivisions.value[index];
      this.addDivisions.removeAt(index);
    }
    if (findIndex < 0 && index <= this.addDivisions.length - 1) {

      this.selectDivisionsList.push(removeValue);
      if(this.addDivisions.length > 1){
       this.selectDivisionsList.sort((a, b) => a.division_number.localeCompare(b.division_number)) }  } else {
      this.addDivisions.push(new UntypedFormControl('', Validators.required));

    }
  }

  checkAddButtonVisibility(index: number) {
    if (index === this.addDivisions.length - 1) {
      return true;
    }
    return false;
  }

  checkRemoveButtonVisibility(index: number) {
    if (index === 0 && this.addDivisions.length === 1) {
      return false;
    }
    return true;
  }

  selectionType(event) {
    if (event.option._value === 'All') {
      this.selectDivisionFlag = false;
      this.allSelect = false;
    } else {
      this.selectDivisionFlag = true;
    }
  }

  save() {
    const divisionsList = this.divisionForm.get('addDivisions').value;
    let divisions = [];
    this.clientDivisionsList.forEach((element: any) => {
      if (this.showOptions.indexOf(element.division_id) > -1) {
        divisions.push(element);
      }
    });

    let allDivisions = [];
    this.clientDivisionsList.forEach((element: any) => {
      if (element !== "") {
        allDivisions.push(element);
      }
    });

    let divList = this.allSelect ? allDivisions : divisions;
    const returnData = {
      "program_id": this.data.program ? this.data.program.id : '',
      "divisions": this.showOptions
    }

    if (this.data.program && this.data.program.id) {
      this.programService.associateDivisions(returnData).subscribe(data => {
        if (data.divisionsUpdated) {
          this.publishedDivision(divList);
        } else {
          this.logSrv.logError('Failed to update Divisions');
        }
      }, () => {
        this.logSrv.logError('Failed to load update Divisions');
      });
    } else {
      this.publishedDivision(divList);
    }
  }

  publishedDivision(divisions) {
    this.dialogRef.close(divisions);
  }

  close() {
    this.selectedDivision = []
    this.dialogRef.close([]);
  }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, FormGroupDirective, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { CandidateProfileService } from '../../services/candidate-profile.service';
import { Observable } from 'rxjs';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'special-instructions',
  templateUrl: './special-instructions.component.html',
  styleUrls: ['./special-instructions.component.scss']
})
export class SpecialInstructionsComponent implements OnInit, OnChanges {
  specialInstructionsFormGroup!: UntypedFormGroup;
  splInstructionCategories: any[];
  splInstructionCategories$: Observable<any>;
  editMode: boolean = true;

  @Input()
  moveStatus: string;

  @Input()
  savedSpecialInstructions: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private candidateProfileService: CandidateProfileService,
    private addCandidateForm: FormGroupDirective
  ) {
    this.createForm();
  }

  ngOnInit(): void {
   this.splInstructionCategories$ = this.candidateProfileService.getTradOptionalValues('SplInstructionCategories');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['savedSpecialInstructions']?.currentValue) {
      const {categories, comment} = changes['savedSpecialInstructions']?.currentValue
      this.specialInstructions.get('categories').setValue(categories);
      this.specialInstructions.get('comment').setValue(comment);
    }

    if(changes['moveStatus']?.currentValue !== undefined && changes['moveStatus']?.currentValue !== 'Invitation Not Sent' ) {
      this.specialInstructionsFormGroup.get('specialInstructions').disable();
    }
  }

  createForm() {
    this.specialInstructionsFormGroup = this.addCandidateForm.form;
    this.specialInstructionsFormGroup.addControl('specialInstructions', this.formBuilder.group({
      categories : [null],
      comment: [null]
    }, { validators: [this.requireBothCategoriesAndComment()] }))
  }

  get specialInstructions() {
    return this.specialInstructionsFormGroup.get('specialInstructions');
  }

  onClear() {
    this.specialInstructionsFormGroup.get('specialInstructions').reset();
  }

  // Custom validator function
  requireBothCategoriesAndComment(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const categories = control.get('categories').value;
      const comment = control.get('comment').value;

      if ((categories?.length && comment) || (!categories?.length && !comment)) {
        return null; // Both categories and comment are set; return null (valid)
      } else {
        return { requireBothCategoriesAndComment: true };
      }
    };
  }

}

import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { Country } from '../../../../core/models/country.model';
import PlaceResult = google.maps.places.PlaceResult;
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit, OnChanges, OnDestroy {

  filteredCountryOptions: Observable<Country[]>;
  hasDepartureStreet: boolean = false;
  hasDepartureCity: boolean = false;
  hasDepartureState: boolean = false;
  hasDepartureCountry: boolean = false;
  departCountryCode = '';

  @Input() addressForm: FormGroup;
  @Input() isDestination: boolean;
  @Input() countryList:Country[];
  @ViewChild('autoCompleteCountryInput', { read: MatAutocompleteTrigger, static: true }) autoCompleteCountry: MatAutocompleteTrigger;

  constructor() { }

  ngOnInit(): void {
    this.getCountryList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(typeof changes['countryList'] !== "undefined") {
      if(changes['countryList'].currentValue) {
        if (this.addressForm.value.country && this.countryList) {
          this.departCountryCode = this.countryList.find(country => (this.addressForm.value.country).
            includes(country.countryName)).countryiso2CharCode;
        }
      }
    }
  }

  getCountryList() {
    this.filteredCountryOptions = this.addressForm.get('country').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
    );
  }

  _filter(value): Country[] {
    if (value) {
      const filterValue = typeof value === 'number' ? value : value.toLowerCase();
      const countryList = this.countryList.filter(option => option.countryName.toLowerCase().indexOf(filterValue) === 0);
      return countryList;
    } else {
      return this.countryList;
    }
  }

  get streets(): FormArray {
    return this.addressForm.controls.streets as FormArray;
  }

  get cities(): FormArray {
    return this.addressForm.controls.cities as FormArray;
  }

  get states(): FormArray {
    return this.addressForm.controls.states as FormArray;
  }

  get postalCodes(): FormArray {
    return this.addressForm.controls.postalCodes as FormArray;
  }

  onFocusOutDepartureCountryCode() {
    if (this.addressForm.value.country && this.countryList) {
      this.departCountryCode = this.countryList.find(country => (this.addressForm.value.country).
        includes(country.countryName)).countryiso2CharCode;
    }
    this.resetAddress(this.departCountryCode);
    this.modifyLocationFormValidations(this.departCountryCode);
  }

  onAutocompleteSelected(result: PlaceResult) {
    this.updateDepartureAddresValue(result, 'street');
  }

  onDepartureCityAutocompleteSelected(result: PlaceResult) {
    this.updateDepartureAddresValue(result, 'city');
  }

  onDepartureStateAutocompleteSelected(result: PlaceResult) {
    this.updateDepartureAddresValue(result, 'state');
  }

  onDeparturePostalCodeAutocompleteSelected(result: PlaceResult) {
    this.updateDepartureAddresValue(result, 'postalcode');
  }

  updateDepartureAddresValue(result: PlaceResult, fieldTriggered: string) {
    let streetLine = '';
    let isCity = false;
    this.postalCodes.reset();
    this.states.reset();
    this.cities.reset();

    result.address_components.forEach(element => {
      if (element.types[0] === 'postal_code') {
        this.postalCodes.setValue([element.long_name]);
      } else if (element.types[0] === 'administrative_area_level_1' &&
        (fieldTriggered === 'city' || fieldTriggered === 'street' || fieldTriggered === 'state' || fieldTriggered === 'postalcode')) {
        this.states.setValue([element.long_name]);
        this.hasDepartureState = true;
      } else if ((element.types[0] === 'locality' || element.types[0] === 'sublocality_level_1' || element.types[0] === 'postal_town') && (fieldTriggered === 'city' || fieldTriggered === 'street' || fieldTriggered === 'postalcode')) {
        this.cities.setValue([element.long_name]);
        isCity = true;
        this.hasDepartureCity = true;
      } else if ((!this.cities.value[0] && element.types[0] === 'administrative_area_level_2') && (fieldTriggered === 'city' || fieldTriggered === 'street' || fieldTriggered === 'postalcode')) {
        this.cities.setValue([element.long_name]);
        isCity = true;
        this.hasDepartureCity = true;
      } else {
        if (!isCity && fieldTriggered === 'street') {
          streetLine = streetLine.length > 0 ?
            `${streetLine}, ${element.long_name}` : element.long_name;
          this.hasDepartureStreet = true;
        }
      }
    });
    this.streets.setValue([streetLine]);
  }



  resetAddress(countryCode) {
    this.departCountryCode = countryCode ? countryCode.toLowerCase() : this.departCountryCode;
    if (countryCode) {
      this.streets.reset();
      this.cities.reset();
      this.states.reset();
      this.postalCodes.reset();
    }
  }

  /**
* State/Province & postal code required for locations in the United States of America and Canada only
*/
  modifyLocationFormValidations(departureCountryCode: string) {
    if (!['US', 'CA'].includes(departureCountryCode)) {
      //  (addressForm.controls.departureAddr as FormGroup).controls.state.clearValidators();
      this.states.controls[0].setValidators([Validators.minLength(3)]);
      // (addressForm.controls.departureAddr as FormGroup).controls.postalCode.clearValidators();
      this.postalCodes.controls[0].setValidators([Validators.minLength(2),
      Validators.maxLength(50)]);

    } else {
      this.states.controls[0].setValidators([Validators.required, Validators.minLength(3)]);
      this.postalCodes.controls[0].setValidators([Validators.required,
      Validators.minLength(2), Validators.maxLength(50)]);
    }

    this.states.controls[0].updateValueAndValidity();
    this.postalCodes.controls[0].updateValueAndValidity();
  }

  /**
 * Checks for Value or will return 'empty'
 * @param value any
 */
  isNullCheck(obj: Object, key: string) {
    try {
      // tslint:disable-next-line: triple-equals
      return ((obj[key] || obj[key] == false) && obj[key] !== null) ? obj[key] : '';
    } catch (error) {
      return '';
    }
  }

  ngOnDestroy(): void {
    this.departCountryCode = '';
  }
}

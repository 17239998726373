<div class="clients">
    <div>
        <h2>Select Client</h2>
    </div>
    <mat-form-field class="table-search" appearance="outline">
        <button mat-icon-button>
            <mat-icon (click)="applySearch(searchValue)" aria-label="search">search</mat-icon>
        </button>
        <input matInput [(ngModel)]="searchValue" (keyup.enter)="applySearch($event.target.value)" placeholder="Search by Company Name or Client #" (blur)="toggleError()">
        <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchTextResults()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <mat-error id="min-text-error" role="main" aria-label="Please enter at least 3 characters" *ngIf="showError"> Please enter at least 3 characters</mat-error> 
    <div class="emp-details mat-elevation-z8">
        <div class="table-scroll">
            <table mat-table #table [dataSource]="dataSource" matSort>
                <!-- client name Column -->
                <ng-container matColumnDef="clientName" sticky>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Company </th>
                    <td mat-cell *matCellDef="let element"> 
                        <a (click)="selectClient(element)"><span [innerHTML]="element.clientName | highlightSearch: filterText"></span></a>
                    </td>
                </ng-container>
                <ng-container  *ngIf="manageCustomDataCapability === 'write' || manageCustomDataCapability === 'read'" matColumnDef="customData" >
                    <th mat-header-cell *matHeaderCellDef> Custom Data </th>
                    <td mat-cell  *matCellDef="let element">
                        <button *ngIf="manageCustomDataCapability === 'read'" mat-raised-button class="add-custom-data" matTooltip="Click here to view Custom Data" (click)="onCall(element)">View</button>
                        <button *ngIf="!element.customDataCount && manageCustomDataCapability === 'write'" mat-raised-button class="add-custom-data" matTooltip="Click here to add Custom Data" [disabled]="manageCustomDataCapability === 'read'" disableRipple (click)="onCall(element)">Add</button>
                        <span *ngIf="element.customDataCount > 0 && manageCustomDataCapability === 'write'" matTooltip="Click here to add Custom Data" style="color:#2065F8;cursor: default;">{{element.customDataCount}} Defined<mat-icon style="cursor: pointer;color: gray;" (click)="onCall(element)">create</mat-icon></span>                              
                    </td>
                </ng-container>
                <!-- client number Column -->
                <ng-container matColumnDef="clientNo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Client # </th>
                    <td mat-cell *matCellDef="let element"><span [innerHTML]="element.clientNo | highlightSearch: filterText"></span></td>
                </ng-container> 
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <!--actions column-->
                <ng-container matColumnDef="action">
                    <tr>
                        <th mat-header-cell *matHeaderCellDef> Actions </th>
                    </tr>
                    <tr>
                        <!-- new changes start -->
                        <td mat-cell *matCellDef="let row" style="width:auto">
                            <mat-form-field fxFlex="0.1px" fxLayout.xs="row" class="tableRow">
				<!-- changes start -->
                            <button type="button" mat-raised-button class="add-custom-data">
                                View
                                <span fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                                    <mat-select [disabled]="viewListValues.length === 0">
                                        <mat-option (onSelectionChange)="getToClientScreen(type, row)" *ngFor="let type of viewListValues" [value]="type">{{type}}</mat-option>
                                    </mat-select>
                                </span>
                            </button>
                            <!-- changes start  -->
                         </mat-form-field>
                    </td> 
                    <!-- changes end -->

                    </tr>
                </ng-container>
                    </table>
            <mat-paginator #paginator [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
        </div>
    </div>
</div>
          
      
      

<div class="dialog-container restore-transferee-confirmation-popup" role="region">
    <!-- Title of pop up -->
    <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px" fxLayoutGap.xs="0em"
      role="heading" class="modal-header">
  
      <span class="modal-header-title"> 
          <!-- <span><img class="restore-icon" alt="restore-transferee" src="../../../../../../assets/images/restore_confirm.svg" /></span> -->
          Restore Transferee
      </span>
      <a class="close-icon" (click)="onDismiss()" title="close" role="button" aria-label="Close Restore Transferee Confirmation modal">
        <mat-icon class="close-btn">close</mat-icon>
      </a>
  
    </div>
    <mat-dialog-content>
  
      <!-- Message  -->
      <div class="message" fxFlex="100%" fxLayoutAlign="start" fxLayout="column" fxLayout.xs="column" fxFlex.xs="100%"
        role="none">
        <p class="content">Are you sure want to restore this transferee?</p>
      </div>
  
    </mat-dialog-content>
  
    <mat-dialog-actions class="modal-footer" align="end">
      <button type="button" class="mat-button text-button btn cancel-btn" (click)="onDismiss()" color="primary" role="button"
        aria-label="Cancel and Close Modal">Cancel</button>
      <button class="mat-button contained-button btn send-btn restore-btn" (click)="restore()" role="button"
        aria-label="Restore and Close Modal">Restore</button>
    </mat-dialog-actions>
  
  </div>
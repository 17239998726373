import { Injectable, Output, EventEmitter } from '@angular/core';
import { UserTypeService } from '../../public/administration/services/user-type.service';
@Injectable()
export class BreadCrumbService {
  userRole: string;

  constructor(
    private userTypeService: UserTypeService,
  ) {
    this.userRole = this.userTypeService.userType;
  }

  isOpen = false;
    arrVal = [];
  getBreadCrumbs(currPath, filenumber, empName) {
      if (currPath === 'emp-cost-transactions') {
        this.arrVal = [{
            params: '',
            label: 'Home',
            url: '/dashboard'
          },
          {
            params: '',
            label: empName,
            url: '/empInfo/' + filenumber
          },
          {
            params: '',
            label: 'Transactions',
            url: ''
          }];
      } else if (currPath === 'empInfo') {
        this.arrVal = [{
            params: '',
            label: 'Home',
            url: '/dashboard'
          },
          {
            params: '',
            label: empName,
            url: ''
          }];
      } else if (currPath === 'provider') {
        this.arrVal = [{
            params: '',
            label: 'Home',
            url: '/dashboard'
          },
          {
            params: '',
            label: empName,
            url: '/empInfo/' + filenumber
          },
          {
            params: '',
            label: 'Providers',
            url: ''
          }];
        }else if (currPath === 'settings' && (this.userRole === 'consultant' || this.userRole === 'file-setup-specialist' || this.userRole === 'application-owner' || this.userRole === 'program-admin'  )) {
            this.arrVal = [{
              params: '',
              label: 'Administration',
              url: '/administration'
            },
            {
              params: '',
              label: 'Account Settings',
              url: '/settings/' + filenumber
            }];
          } else if (currPath === 'settings' && (this.userRole === 'client-contact-initiator' || this.userRole === 'application-reliability-analyst' || this.userRole == 'client-account-manager')) {
            this.arrVal = [{
              params: '',
              label: 'My Dashboard',
              url: '/dashboard'
            },
            {
              params: '',
              label: 'Account Settings',
              url: '/settings/' + filenumber
            }];
          }
    
      return this.arrVal;
  }
}

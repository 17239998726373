<div class="main-content" role="main">
    
    <!-- Visible only when user is other OR is AcMgr/PrgMgr in Client List Page -->
    <ng-container *ngIf="currentViewType === 'clientList'">
        <div fxLayout="row" 
        fxLayoutGap.xs="5%">
            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%"><h1>Administration</h1></div>
            <div fxFlex="50%" fxLayoutAlign="end center" fxLayout.xs="column" fxFlex.xs="100%" 
                *ngIf="(userRole === 'program-admin' || userRole === 'product-mgmt-admin')">
                <div (click)="launchProdAdmin($event)" (keypress)="launchProdAdmin($event)">
                    <a>Product Administration </a>
                    <img src="../../../assets/icons/icon_offerings.svg" alt="go">
                </div>
            </div>
        </div>
    </ng-container>

    <router-outlet></router-outlet>

</div>

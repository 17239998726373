import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '../../../core/services/app-config.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  termsOfUseUrl: string;
  privacyNotice: string;

  constructor(private appConfig: AppConfigService) { }

  ngOnInit() {
    this.termsOfUseUrl = String(this.appConfig.getConfig('termsOfUse'));
    this.privacyNotice = String(this.appConfig.getConfig('privacyPolicy'));
  }

}

import { Component, OnInit, Injector, Inject } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProgramManagementService, Benefit, ProgramRequest, ProgramBenefitUpdateRequest } from '../../services/program-management.service';
import { candidateProfile } from '../../../../core/models/candidate.model';
import { CategoryService } from '../../services/category.service';

export type BenefitCategory = 'Before Move' | 'Shipping' | 'Travel & Transport' | 'After Arrival' | 'Ongoing' | 'Allowance' ;


@Component({
  selector: 'app-view-program-details',
  templateUrl: './view-program-details-component.html',
  styleUrls: ['./view-program-details-component.scss']
})
export class ViewProgamDetailsComponent implements OnInit {

/** title for pop up */
candidateProfile: candidateProfile = {} as candidateProfile;
/** benefitlist from the api */
originalBenefitsList: Array<Benefit> = [];
/** text searched in search box */
searchValue: string = null;
/** client id */
 clientId: string = '';
/** benefitlist which is grouped and used for binding */
benefitsList: Array<{ group: string, items: Array<Benefit> }>;
/** program name from candidate profile details */
programName: string = '';
/** transferee name */
transfereeName:string = '';

/* Managemove client */
isMMUser :boolean = false;
BenefitCategories;

  constructor(
              private readonly spinner: NgxSpinnerService,
              public dialogRef: MatDialogRef<ViewProgamDetailsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: candidateProfile,
              private injector: Injector,
              private readonly programService: ProgramManagementService,
              private toastrService: ToastrService,
              private readonly categoryService: CategoryService
              ) {
                this.isMMUser = JSON.parse(sessionStorage.getItem("car-ses-mmu"));
               }
              
                       

  ngOnInit() {
    this.candidateProfile = this.data;
    this.BenefitCategories = this.categoryService.categorySub.getValue();
    //this.spinner.show();
    this.clientId = sessionStorage.getItem('cc_clientPartyId') ? sessionStorage.getItem('cc_clientPartyId') : sessionStorage.getItem('clientId');//((this.userRole ==='client-contact-initiator')? sessionStorage.getItem('cc_clientPartyId') : sessionStorage.getItem('clientId')) ;
    this.programName = this.data.programDetails ? this.data.programDetails.programName : '';
    //this.transfereeName = (this.data.nameDetails.firstName) + ' ' + (this.data.nameDetails.lastName);
    this.loadProgramBenefits();
  }

  /**
   * Invoked on click of dismiss/close
   */
  onDismiss(evt) {
    evt.preventDefault();
    this.dialogRef.close(0);
  }

  viewProgram() {
    this.dialogRef.close(1);
  }

  /**
   * To load transferee offerings
   */
  loadProgramBenefits() {
    this.spinner.show();
      const clientid = this.clientId;
      const programName = this.programName;
      let manageMoveUser = this.isMMUser === true ? true : false;
      this.programService.getTransfereeViewProgramBenefits(clientid, programName, this.data.orderRequestId, true,manageMoveUser).subscribe(data => {
        if (data) {
          data.benefits = data.benefits.sort((a, b) => a.displayName.localeCompare(b.displayName));
          this.originalBenefitsList = JSON.parse(JSON.stringify(data.benefits));
          this.benefitsList = this.groupBenefits(this.originalBenefitsList);
          this.spinner.hide();
        } else {
          this.toastrService.error('Error fetching Benefits', 'Error');
          this.spinner.hide();
        }
      }, () => {
        this.toastrService.error('Error fetching Benefits', 'Error');
        this.spinner.hide();
      });
  }

  groupBenefits(benefits: Array<Benefit>): Array<{ group: string, items: Array<Benefit> }> {
    const benefitsByGroup = this.BenefitCategories.map(category => {
      return {
        group: category,
        items: benefits.filter(benefit => benefit.category === category)
      };
    });
    return benefitsByGroup;
  }

  textSearchBenefits(searchText) {
    if (searchText && searchText.length >= 3 || searchText.length === 0) {
      const benefitList = this.originalBenefitsList;
      const searchBenefits = benefitList.filter(benefit =>
        benefit.displayName.toLowerCase().search(searchText.toLowerCase()) !== -1);
      this.benefitsList = this.groupBenefits(searchBenefits);
      let groupItem = 0;
      for(const item of this.benefitsList) {
        if(item.items.length > 0) {
          groupItem = groupItem + item.items.length;
        }
      }
      if(groupItem === 0 ) {
        this.benefitsList = [];
      }
      this.searchValue = searchText;
    }
  }

  textSearch(event) {
    if (event.key === 'Backspace' && event.target.value.length === 0) {
      this.textSearchBenefits(event.target.value);
    }
  }

  clearTextSearch() {
    this.benefitsList = this.groupBenefits(this.originalBenefitsList);
    this.searchValue = '';
  }
}

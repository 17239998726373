  
        <div class="container document-container" fxLayout="column" fxFlex="100%">
          <p class="doc-lib-text" *ngIf="showTable">Downloading files with sensitive information is a security risk, please clear your cache
            afterwards</p>
          <p *ngIf="!showTable">No Records Available</p>
          <mat-card fxFlex="100%" class="example-card" style="height: 18px;">
            <mat-form-field class="position field-search" appearance="outline">
              <span matPrefix class="lg-only" role="none">
                <mat-icon>search</mat-icon>
              </span>
              <input [(ngModel)]="searchValue" (keyup)="applySearch($event.target.value)" matInput tabindex="0" placeholder="Search table..." aria-label="Search by name" role="text">
              <button *ngIf="searchValue" (click)="clearTextSearch()" tabindex="0" mat-button matSuffix mat-icon-button aria-label="Clear" class="close-btn"
                role="button">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </mat-card>
          <div class="table-scroll mat-elevation-z8">
            <table mat-table [dataSource]="docdataSource" matSort>
              <ng-container matColumnDef="docType">
                <th width="20%" mat-sort-header *matHeaderCellDef style="padding-left: 5px;"> Doc Type </th>
                <td mat-cell *matCellDef="let element">
                  <div *ngIf="element.docType">
                    <span [innerHTML]="element.docType | searchHighlight: searchValue"></span>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-sort-header *matHeaderCellDef style="padding-left: 24px;"> Name                  
                </th>
                <td mat-cell *matCellDef="let element; let i = index">
                  <a *ngIf="element.name" [id] = "element.name + '_' + (i+1)" (click)="openDSMFileToastr(element)" tabindex="0" href="javascript:void(0)">
                    <span [innerHTML]="element.name | searchHighlight: searchValue"></span>                            
                  </a>
                </td>
              </ng-container>
              <ng-container matColumnDef="addedBy">
                <th width="20%" mat-sort-header *matHeaderCellDef style="padding-left: 10px;">  Added By </th>
                <td mat-cell *matCellDef="let element">
                  <div *ngIf="element.addedBy" class="dms-name">
                    <span *ngIf="!element.role" class="dms-short-name">
                      <app-avatar [firstName]="element.addedBy" style="display: table-cell; padding: 5px;" [lastName]="element.lastName" [isTable]="true">
                      </app-avatar>
                    </span>
                    <span [innerHTML]="element.addedBy | searchHighlight: searchValue "></span>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="date">
                <th mat-sort-header *matHeaderCellDef style="padding-left: 8px;"> Date
                </th>
                <td mat-cell *matCellDef="let element">
                  <div *ngIf="element.date">
                    <span [innerHTML]="(element.date | date: 'yyyy-MM-dd') | searchHighlight: searchValue "></span>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="docId">
                <th mat-sort-header *matHeaderCellDef style="padding-left: 6px;"> Doc ID </th>
                <td mat-cell *matCellDef="let element">
                  <div *ngIf="element.docId">
                    <span [innerHTML]="element.docId | searchHighlight: searchValue"></span>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-sort-header *matHeaderCellDef style="padding-left: 6px;"> Action </th>
                <td mat-cell *matCellDef="let element" matTooltip="Document cannot be deleted" [matTooltipDisabled]="element.isDisabledForDelete">
                 <button mat-icon-button role="button" [disabled]="!element.isDisabledForDelete" (click)="confirmDeleteDoc(element)" >
                  <mat-icon [ngStyle]="{'color':element.isDisabledForDelete ? '#2065F8':'#575A5D' }" style="margin-top: -1px; cursor: pointer;">
                      delete</mat-icon> 
              </button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="docDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: docDisplayedColumns;"></tr>
            </table>
          </div>
        </div>
      
    


<h2>
  {{ paginator?.length }} In Review Transferees
</h2>
<div class="compliance-transferee" class="mat-elevation-z8">
  <mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true" #complianceListSort (matSortChange)="applySort($event)"> 
    <!-- Transferee Column -->
    <ng-container matColumnDef="transferee">
      <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Transferee"
       > Transferee </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.transferee">
          <span [innerHTML]="element.transferee | searchHighlight: searchKeyword"></span>
        </div>
      </td>
    </ng-container>

    <!-- Client Column -->
    <ng-container matColumnDef="client">
      <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Client "
       > Client </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.client">
          <span [innerHTML]="element.client | searchHighlight: searchKeyword"></span><br/>
          (Client #: <span [innerHTML]="element.clientNumber | searchHighlight: searchKeyword"></span> )
        </div>
      </td>
    </ng-container>

    <!-- Authorization Type Column -->
    <ng-container matColumnDef="authorizationType">
      <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Authorization Type"> Authorization Type </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.authorizationType">
          <span [innerHTML]="(element.authorizationType === 'BenefitsBuilder'? 'Benefits Builder' : element.authorizationType) | searchHighlight: searchKeyword"></span>
        </div>
      </td>
    </ng-container>

    <!-- Departure Column -->
    <ng-container matColumnDef="departure">
      <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Departure"
       > Departure </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.departure">
          <span [innerHTML]="element.departure | searchHighlight: searchKeyword"></span>
        </div>
      </td>
    </ng-container>

    <!-- Destination Column -->
    <ng-container matColumnDef="destination">
      <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Destination"
       > Destination </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.destination">
          <span  [innerHTML]="element.destination | searchHighlight: searchKeyword"></span>
        </div>
      </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="authorizationDate">
      <th width="6%" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Date"
       > Date </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.authorizationDate">
          <span [innerHTML]="element.authorizationDate | date: 'yyyy-MM-dd' | searchHighlight: searchKeyword"></span>
        </div>
      </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th width="14%" mat-header-cell *matHeaderCellDef style="padding-left: 24px;" mat-sort-header
        matTooltip="Change sorting for status" > Status / Reason </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.status">
          <ng-container>
            <a (click)="onStatusClick(element)">
              <div><span [innerHTML]="element.status"></span>
              </div>
            </a>
          </ng-container>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th width="1%" mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="align-end">
          <button  tabindex="0" [disabled]="!(element.wasRestored && userRole==='file-setup-specialist')"  mat-icon-button aria-label="edit" class="edit_icon" (click)="editRestrictedLocation(element)">
             <mat-icon aria-label="edit" matTooltip="Edit Location">edit</mat-icon>
          </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="adjudicate">
      <th width="3%" mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <ng-container>
          <button class="align-adjudicate" [ngClass]="disableAdjudication ? 'disable-adjudicate' :'enable-adjudicate'" fxFill fxLayoutAlign="center" type="button" mat-button (click)="onAdjudicate(element)" [disabled]="disableAdjudication">Adjudicate
          </button>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </mat-table>
  <mat-paginator #MatPaginator="matPaginator" (page)="onPageChange($event)"
    [pageSizeOptions]="[10,25,50,75]"></mat-paginator>
</div>
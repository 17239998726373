<div class="completePolicyCallModal" cdkTrapFocus cdkTrapFocusAutoCapture>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mat-dialog-title">
        <h2> Complete Policy Call</h2>
        <a href="#" tabindex="0" class="close-icon nofocus" (click)="onNoClick($event)" matTooltip="Close"
            style="color: grey; cursor: pointer;">
            <mat-icon class="header-icon-style">close</mat-icon>
        </a>
    </div>

    <mat-dialog-content class="middleSection" >
        <div class="dateOfPolicyCall">
            <mat-form-field>
                <input matInput [matDatepicker]="startDate" [max]="currentDate" placeholder="Date of Policy Call"
                    [(ngModel)]="policyCallCompleteDate" required>
                <mat-datepicker-toggle tabindex="0" matSuffix [for]="startDate" class="icon-style"
                    matTooltip="Calendar">
                </mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="timeOfPolicyCall">
            <mat-form-field>
            <input matInput [ngxTimepicker]="toggleTimepicker" [disableClick]="true" readonly 
            [(ngModel)]="policyCallCompleteTime" placeholder="Time of Policy Call" required>
            <ngx-material-timepicker-toggle tabindex="0"  matSuffix  [for]="toggleTimepicker" class="datepicker-style">
            </ngx-material-timepicker-toggle>
            <ngx-material-timepicker #toggleTimepicker [appendToInput]=true></ngx-material-timepicker>
            </mat-form-field>
            <!-- <h6 class="timezone-style">Timezone: {{timeZone}}</h6> -->
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="mat-dialog-actions" fxLayout="row" fxLayoutAlign="end end">
        <button class="cancelBtn-Style nofocus" (click)="onNoClick($event)" >CANCEL</button>
        <button class="policy-incomplet active-card nofocus" (click)="save()" [disabled]="policyCallCompleteTime === ''">SAVE</button>
    </mat-dialog-actions>
</div>
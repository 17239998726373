<div class="dialog-container initial-contact-send-invite-modal" role="region">
    <!-- Title of pop up -->
    <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px" fxLayoutGap.xs="0em"
      role="heading" class="modal-header">
  
      <span class="modal-header-title">{{headerLable}}</span>
      <a class="close-icon" (click)="onDismiss()" title="close" role="button" aria-label="Close Initial contact call invite Modal"
        title="Close Initial contact call invite modal">
        <mat-icon class="close-btn">close</mat-icon>
      </a>
  
    </div>
    <mat-dialog-content>
  
      <!-- Message  -->
      <div class="message" fxFlex="100%" fxLayoutAlign="start" fxLayout="column" fxLayout.xs="column" fxFlex.xs="100%"
        role="none">
        <p class="content">Would you like to send the invitation to <strong>{{data.transfereeName}}</strong> now?</p>
      </div>
  
    </mat-dialog-content>
  
    <mat-dialog-actions class="modal-footer" align="end">
      <button type="button" class="mat-button text-button btn cancel-btn" (click)="onDismiss()" color="primary" role="button"
        aria-label="Cancel and Close Modal">Do Not Send Now</button>
      <button class="mat-button contained-button btn send-btn" (click)="sendInvite()" role="button"
        aria-label="Send invite and Close Modal">Send Invite</button>
    </mat-dialog-actions>
  
  </div>
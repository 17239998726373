import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameInitials'
})
export class NameInitialsPipe implements PipeTransform {

  transform(value: string): any {
    return value
      .split(" ")
      .map(n => n[0])
      .join("");
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { CandidateProfileService } from '../../../../services/candidate-profile.service'


@Component({
  selector: 'app-scheduled-policy-call-modal',
  templateUrl: './scheduled-policy-call-modal.component.html',
  styleUrls: ['./scheduled-policy-call-modal.component.scss']
})


export class ScheduledPolicyCallModalComponent implements OnInit {
  currentDate: Date;
  timeZone: string;
  policyCallDate: Date;
  policyCallTime: string;
  smart_invite_id: string;
  policyCallDuration: number;
  isSchedule: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ScheduledPolicyCallModalComponent>,
    public spinner: NgxSpinnerService,
    public readonly toastrService: ToastrService,
    public candidateProfileService: CandidateProfileService,
  )
  {}

  ngOnInit() {
    this.isSchedule = this.data.isSchedule;
    this.currentDate = new Date();
    this.timeZone =  this.data.timeZone;
    if(this.isSchedule) {
      this.policyCallDate = new Date();
      this.policyCallTime = "";
      this.policyCallDuration = this.data.policyCallDuration
    } else {
      this.policyCallDate = new Date(this.data.policyCallDate.replace(/-/g, '\/'));
      this.policyCallTime = this.data.policyCallTime;
      this.smart_invite_id = this.data.smart_invite_id;
      this.policyCallDuration = this.data.policyCallDuration
    }
  }

  onNoClick(event: Event): void {​​​​
    event.preventDefault();
    event.stopPropagation();
    this.dialogRef.close();
  }​​​​

  save() {
    let startingHours = parseInt(moment(this.policyCallTime, ["h:mm A"]).format("HH:mm").substring(0, 2));
    let startingMin  = parseInt(moment(this.policyCallTime, ["h:mm A"]).format("HH:mm").substring(3));
    let endTime = moment(this.policyCallTime, ["h:mm A"]).add(this.policyCallDuration, 'minutes').format("HH:mm");
    let endingHours = parseInt(endTime.substring(0,2));
    let endingMin = parseInt(endTime.substring(3));
    let startDate = new Date(this.policyCallDate.getFullYear(), this.policyCallDate.getMonth(), this.policyCallDate.getDate(), startingHours, startingMin);
    let endDate = new Date(this.policyCallDate.getFullYear(), this.policyCallDate.getMonth(), this.policyCallDate.getDate(), endingHours, endingMin);
    let recipientsEmailList = this.data.candidateDetails.emailDetailsList;
    let smart_invite_id = this.smart_invite_id;
    let orderId = this.data.orderId;
    if(this.data.consultantinfo && this.data.consultantinfo.hasOwnProperty('emailDetailsList') && this.data.consultantinfo.emailDetailsList.length) {
      this.data.consultantinfo.emailDetailsList.forEach(element => {
        recipientsEmailList.push(element)
      });
    }

    let reqObj = Object.assign({}, {
      tzid: this.timeZone,
      start: startDate,
      end: endDate,
      orderId: orderId,
      smart_invite_id: smart_invite_id,
      recipientsEmailList: recipientsEmailList,
      partyId: this.data.partyid,
      candidateDetails: this.data.candidateDetails,
      consultantinfo : this.data.consultantinfo,

    });
    this.isSchedule ? this.schedulePolicyCall(reqObj): this.reSchedulePolicyCall(reqObj);
  }
  schedulePolicyCall(reqObj: any) {
    this.spinner.show();
    this.candidateProfileService.schedulePolicyCall(reqObj).subscribe(res => {
      if (res.code === 'POLICY_CALL_INVITATION_SUCCESS') {
        this.spinner.hide();
        this.dialogRef.close(reqObj);
        this.toastrService.info('Policy Call Scheduling Successful');
      } else {
        this.spinner.hide();
        this.toastrService.error('Error on sending policy call invite');
      }
    });
  }
  reSchedulePolicyCall(reqObj : any) {
    this.spinner.show();
    this.candidateProfileService.reSchedulePolicyCall(reqObj).subscribe(res => {
      if (res.code === 'POLICY_CALL_INVITATION_SUCCESS') {
        this.spinner.hide();
        this.dialogRef.close(reqObj);
        this.toastrService.info('Policy Call Rescheduling Successful');
      } else {
        this.spinner.hide();
        this.toastrService.error('Error rescheduling policy call');
      }
    });
  }
}

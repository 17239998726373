import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserContextService } from '../../../../../core/services/user-context.service';

@Component({
  selector: 'app-cronofy-availablility-picker',
  templateUrl: './cronofy-availablility-picker.component.html',
  styleUrls: ['./cronofy-availablility-picker.component.scss']
})

export class CronofyAvailablilityPickerComponent implements OnInit {
     tokens:string;
  constructor( private readonly userContextService: UserContextService,) {
   }
  ngOnInit() {
    const cronofyId = sessionStorage.getItem('cronofyId');
    this.userContextService.getCronofyToken(cronofyId).subscribe(res => {
      if (res) {
        this.tokens = res.token;
        this.callAvailability();
        let save_rules = document.getElementsByClassName("custom-name__submit");  // Find the elements
        save_rules[0].innerHTML = "Save New Rules";
        save_rules[0].setAttribute('style', 'margin-right: 8px');
      }
    })
  }
  callAvailability() {
    let availabilityRules = {

      element_token:this.tokens,
      
      target_id: "cronofy-availability-rules",
      
      availability_rule_id: "work_hours",
      
      config: {
        start_time: "00:00",
        end_time: "23:59",
        duration: 60,
        week_start_day: "monday",
        },
      styles: {
      colors: {
      available: "lightblue",
      unavailable: "white"
      },
      
      prefix: "custom-name"
      },
    }
    window['CronofyElements'].AvailabilityRules(availabilityRules);
  }
 

}

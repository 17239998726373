import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ErrorReviewComponent } from './error-review/error-review.component';
@Component({
  selector: 'app-submission-failure',
  templateUrl: './submission-failure.component.html',
  styleUrls: ['./submission-failure.component.scss']
})
export class SubmissionFailureComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  dialogTitle:string = "Duplicate check";
  selectChange:any;
  isReviewComp: boolean = false;
  // changes start
  @ViewChild('stepper', { static: false }) steppers: MatStepper;
  isReference:boolean=false;
  // changes end
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SubmissionFailureComponent>
  ) { }

  ngOnInit() {
  }

  onDismiss(evt) {
    let closeDialog
    evt.preventDefault();
    if (this.isReference) {
      closeDialog = {
        action: 'dismiss'
      }
    }
    this.dialogRef.close(closeDialog)
  }

  CompleteStep() {
      this.stepper.next();
      this.isReviewComp = true;
    this.dialogTitle="Submission Failure";
  }
  
  ReviewStep(){
    this.steppers.next();
    this.isReference=true;
  }
}
<div #scrollMe>
<div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
  fxLayoutGap="10px" fxLayoutGap.xs="0em">Work Order Details : {{!disableToggle ? data.orderID : data.supplierRef ? data.supplierRef : data.orderID}} <br />Offering: {{data.offering}}
  <span class="close-icon" (click)="onClose()" title="close">
    <mat-icon>close</mat-icon>
  </span>
</div>
<!--main panel-->
<mat-dialog-content>
  <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="none">
      <div>
         <div class="booking-agent">Booking Agent</div>
         <div class="agent">{{bookingPartyName}}</div>
         <div class="agent-bottom" *ngIf = "supplierID">ID: {{supplierID ? supplierID : '' }}</div>
     
      </div>
     <div>
          <div class="booking-agent">Status</div>
          <div class="agent">{{this.data.checkForStatus == 'addOnCancelled'? 'Open' : data.details.orderStatus | titlecase | customTitlecase: "-"}}</div>
          <div class="agent-bottom" *ngIf = "data.details?.orderStatusDate && this.data.checkForStatus != 'addOnCancelled'">on {{ data.details.orderStatusDate }}</div>
     </div>
     <div *ngIf="data?.details?.supplierOrderSubmittedDate">
      <div class="booking-agent"> Actual submitted On</div>
      <div class="agent"> {{data.details.supplierOrderSubmittedDate ? data.details.supplierOrderSubmittedDate : '' }}</div>
     </div>
  
</div>
  <div tabindex="0" class="main-panel">
    <!-- consultant info accordin-->
    <mat-accordion class=" candidate-review-panel move-departure-panel" multi="true">
      <mat-expansion-panel class="move-detail-panel" [expanded]="true">
        <mat-expansion-panel-header [collapsedHeight]="'auto'" >
          <div fxFlex="34%" style="color:#4b85ff ;" class="panel-heading">Cartus Consultant Info</div>
        </mat-expansion-panel-header>
        <div fxLayout="row" fxLayoutGap="40px">
          <div>
            <mat-form-field class="form-field" appearance="none">
              <mat-label>Consultant</mat-label>
              <input disabled matInput type="text"> {{data.details.consultantDetails ?
              data.details.consultantDetails.firstName : ''}}{{data.details.consultantDetails.lastName ?
              data.details.consultantDetails.lastName: ''}}
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="form-field" appearance="none">
              <mat-label>Consultant Location/Region</mat-label>
              <input disabled matInput>{{data.details.consultantDetails.timeZone ?
              data.details.consultantDetails.timeZone:''}}
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="40px">
          <div>
            <mat-form-field class="form-field" appearance="none">
              <mat-label>Consultant Phone #</mat-label>
              <input disabled matInput type="text">({{consultantPhoneDetails.phoneDialingCode}})
              {{consultantPhoneDetails.phoneNumber}}
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="form-field" appearance="none">
              <mat-label>Consultant Email</mat-label>
              <input disabled matInput type="text">{{consultantEmailDetails.emailDetailsList}}
            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <!--Customer Details-->
    <mat-accordion class=" candidate-review-panel move-departure-panel" multi="true">
      <mat-expansion-panel class="move-detail-panel" [expanded]="true">
        <mat-expansion-panel-header [collapsedHeight]="'auto'" >
          <div fxFlex="34%" style="color:#4b85ff ;" class="panel-heading">Customer Details</div>
        </mat-expansion-panel-header>
        <div fxLayout="row">
          <div>
            <mat-form-field class="form-field" appearance="none">
              <mat-label>Customer first name</mat-label>
              <input disabled matInput type="text">{{data.details.customerDetails.customerFirstName}}
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="form-field" appearance="none">
              <mat-label>Customer Last Name</mat-label>
              <input disabled matInput>{{data.details.customerDetails.customerSecondName}}
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="40px">
          <div>
            <mat-form-field class="form-field" appearance="none">
              <mat-label>Customer Work Phone #</mat-label>
              <input disabled matInput type="text">{{businessNum}}
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="form-field" appearance="none">
              <mat-label>Customer Work Email</mat-label>
              <input disabled matInput type="text">{{businessEmail}}
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="80px">
          <div>
            <mat-form-field class="form-field" appearance="none">
              <mat-label>Departure Country</mat-label>
              <input disabled matInput type="text">{{data.details.customerDetails.departureAddr["country"]}}
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="form-field" appearance="none">
              <mat-label>Departure Street Details</mat-label>
              <input disabled matInput type="text">{{data.details.customerDetails.departureAddr["streetLine1"]}}
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between none">
          <div>
            <mat-form-field class="form-field" appearance="none">
              <mat-label>Departure City</mat-label>
              <input disabled matInput type="text">{{data.details.customerDetails.departureAddr["city"]}}
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="form-field" appearance="none">
              <mat-label>Departure State/Provinces</mat-label>
              <input disabled matInput type="text">{{data.details.customerDetails.departureAddr["state"]}}
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="form-field" appearance="none">
              <mat-label>Departure Postal Code</mat-label>
              <input disabled matInput type="text">{{data.details.customerDetails.departureAddr["postalCode"]}}
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between none">
          <div>
            <mat-form-field class="form-field" appearance="none">
              <mat-label>Spouse/Partner Name</mat-label>
              <input disabled matInput type="text">{{data.details.spouseName}}
            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <!--Policy Details-->
    <mat-accordion class=" candidate-review-panel move-departure-panel" multi="true">
      <mat-expansion-panel class="move-detail-panel" [expanded]="true">
        <mat-expansion-panel-header [collapsedHeight]="'auto'" >
          <div fxFlex="34%" style="color:#4b85ff ;" class="panel-heading">Policy Details</div>
        </mat-expansion-panel-header>
        <div fxLayout="row" fxLayoutAlign="space-between none">
          <div fxFlex="50%">
            <mat-form-field class="form-field" fxFlex="40%" appearance="none">
              <mat-label>Client name</mat-label>
              <input readonly matInput type="text">{{data.details.policyDetails["clientName"]}}
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field class="form-field" fxFlex="65%" appearance="none">
              <mat-label>Client Number</mat-label>
              <input readonly matInput type="text">{{data.details.policyDetails["clientNumber"]}}
            </mat-form-field>
          </div>
          <div fxFlex="30%">
            <mat-form-field class="form-field" fxFlex="60%" appearance="none">
              <mat-label>Geographic Origin</mat-label>
              <input disabled matInput type="text">{{data.details.policyDetails["geographicOrigin"]}}
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row">
          <div>
            <mat-form-field class="form-field" appearance="none">
              <mat-label>File No</mat-label>
              <input disabled matInput type="text">{{data.details.policyDetails["fileNumber"]}}
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="form-field" appearance="none">
              <mat-label>Order reference</mat-label>
              <input disabled matInput type="text">{{data.details.policyDetails["orderReference"]}}
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="form-field" appearance="none">
              <mat-label>Policy Name</mat-label>
              <input disabled matInput type="text">{{data.details.policyDetails["policyName"]}}
            </mat-form-field>
          </div>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
    <!-- Relocation Details -->
    <mat-accordion class=" candidate-review-panel move-departure-panel" multi="true">
      <mat-expansion-panel class="move-detail-panel" [expanded]="true">
        <mat-expansion-panel-header [collapsedHeight]="'auto'" >
          <div style="color:#4b85ff ;" class="panel-heading" fxFlex="25%">Relocation Details</div>
          <div class="panel-heading item-count" *ngIf="completedItemCountRelocation > 0"><span
              class="assess-count">{{completedItemCountRelocation}}</span>item to complete</div>
          <div class="panel-heading all-complete" *ngIf="completedItemCountRelocation <= 0">
            <mat-icon class="complete-icon-assess-needs">check_circle</mat-icon>
            <div class="complete-text-assess-needs">All Complete</div>
          </div>
        </mat-expansion-panel-header>
        <form fxLayout="column" class="middle-section" [formGroup]="relocationForm">
          <div class="middle-section">
            <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
              fxLayoutGap.xs="0em">
              <!--File Priority-->
              <div fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field class="dropdown-css" fxFlex="100%">
                  <mat-label>File Priority</mat-label>
                  <mat-select formControlName="filePriority" >
                    <mat-option *ngFor="let type of orderPriorityList" [value]="type">{{type}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <!--Rush toggle-->
              <div fxFlex="23%" fxLayout.xs="column" fxFlex.xs="100%">
                <div fxFlex="100%" style="margin-top: 20px;">
                  <mat-label>Standard &nbsp;</mat-label>
                  <mat-slide-toggle formControlName="rush" ></mat-slide-toggle>
                  <mat-label>&nbsp; Rush </mat-label>
                </div>
              </div>
              <!--Days Authorized-->
              <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field fxFlex="50%">
                  <mat-label>Days  Authorized</mat-label>
                  <input matInput formControlName="daysAuthorized" [readonly]="data.status=='pending'" type="number" (keypress)="onNumberValueValidation($event)" min="1">
                </mat-form-field>
              </div>
            </div>
            <!-- serviceDeliveryLocation -->
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutGap.lt-md="0px">
              <mat-form-field fxFlex="40%" class="dropdown-css">
                <mat-label>Service Delivery Location</mat-label>
                <mat-select formControlName="serviceDeliveryLocation" >
                  <mat-option *ngFor="let type of serviceDeliveryLocationValueList" [value]="type"> {{type.type}} -
                    {{type.country}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
              fxLayoutGap.xs="0em">
              <!--Client Budget Amount-->
              <div fxFlex="35%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field fxFlex="90%" class="client-budget">
                  <mat-label>Client Budget Amount (Nightly)</mat-label>
                  <input matInput formControlName="clientBudgetAmount" type="number" (keypress)="onNumberValueValidation($event)" min="1" [readonly]="data.status=='pending'">
                </mat-form-field>
              </div>
              <!--Budget Currency-->
              <div fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%" >
                <mat-form-field fxFlex="90%" class="dropdown-css">
                  <mat-label>Client Budget Currency</mat-label>        
                  <input type="text" aria-label="Country" id="deptCountry"
                                            matInput #autoCompleteCurrencyInput
                                            formControlName="clientBudgetCurrency"
                                            [matAutocomplete]="autoCurrencyCountry">
                                    <mat-autocomplete  #autoCurrencyCountry="matAutocomplete" [displayWith]="displayFn" [panelWidth] = "293">
                                        <mat-option [value]="type" *ngFor="let type of filteredCurrencyOptions | async">
                                          {{type.value}} - {{type.displayValue}}
                                        </mat-option>
                                      </mat-autocomplete>
                </mat-form-field>
              </div>
              <!--Financial Responsibility-->
              <div fxFlex="35%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field fxFlex="90%" class="dropdown-css">
                  <mat-label>Financial Responsibility</mat-label>
                  <mat-select (selectionChange)="financialChange()" formControlName="relocationFinancialResponsibility" >
                    <mat-option *ngFor="let type of reFinancialResponsibilityValueList" [value]="type">{{type}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              </div>
              
              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutGap.lt-md="0px" fxLayoutAlign="space-between"
                fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px">
                <!-- Split Bill Comments -->
                <mat-form-field fxFlex="50%" class="dropdown-css"
                  *ngIf="relocationForm.get('relocationFinancialResponsibility').value == 'Split Bill'">
                  <mat-label>Split Bill Comments</mat-label>
                  <input matInput min="0" maxlength="500" formControlName="splitBillComments" [readonly]="data.status=='pending'">
                  <mat-error class="hint-color" *ngIf="financialChange()">filed is required</mat-error>
                </mat-form-field>
                  <!-- Split Bill -- Client’s Value (USD) -->
                <mat-form-field fxFlex="50%" *ngIf="relocationForm.get('relocationFinancialResponsibility').value == 'Split Bill' && data.reimbursement"
                  class="dropdown-css">
                  <mat-label>Client’s Value (USD)</mat-label>
                  <input (focusout) = "updateMonetaryAmountWithCurrency()" *ngIf="editMonetaryAmount" (keypress)="onNumberValueValidation($event)" type="number" matInput formControlName="monetaryAmount"
                    [min]="1" maxlength="50" [readonly]="data.status=='pending'">
                  <input (click)="editMonetaryAmount = true" *ngIf="!editMonetaryAmount"  matInput formControlName="monetaryAmountWithCurrency"
                    [readonly]="data.status=='pending'">
                  <mat-error class="hint-color" *ngIf="splitBillMonetaryAmount()">filed is required</mat-error>
                </mat-form-field>
              </div>
            <!-- Requires Residency Letter -->
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutGap.lt-md="0px" class="country-name">
              <div class="international-toggle">
                <mat-label>Requires Residency Letter</mat-label>
                <div class="dropdown-css">
                  <mat-label>Yes &nbsp;</mat-label>
                  <mat-slide-toggle formControlName="requiresResidencyLetter" ></mat-slide-toggle>
                  <mat-label>&nbsp; No</mat-label>
                </div>
              </div>
            </div>
            <!-- provider -->
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutGap.lt-md="0px" class="country-name">
              <mat-form-field fxFlex="35%" class="dropdown-css">
                <mat-label>Provider</mat-label>
                <mat-select formControlName="provider" >
                  <mat-option *ngFor="let type of providerValueList" [value]="type">{{type}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- Client Directed ProviderComments -->
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutGap.lt-md="0px" class="country-name">
              <mat-form-field fxFlex="95%" class="dropdown-css" >
                <mat-label >Client Directed Provider Comments (Optional)</mat-label>
                <input matInput formControlName="clientDirectedProviderComment" [readonly]="data.status=='pending'">
              </mat-form-field>
            </div>
          </div>
        </form>
      </mat-expansion-panel>
    </mat-accordion>

    <!--need assesment -->
    <mat-accordion class=" candidate-review-panel move-departure-panel" multi="true">
      <mat-expansion-panel class="move-detail-panel" [expanded]="true">
        <mat-expansion-panel-header [collapsedHeight]="'auto'" >
          <div style="color:#4b85ff ;" class="panel-heading" fxFlex="25%">Customer Needs Assessment</div>
          <div class="panel-heading item-count" *ngIf="completedCount > 0"><span
              class="assess-count">{{completedCount}}</span>item to complete</div>
          <div class="panel-heading all-complete" *ngIf="completedCount <= 0">
            <mat-icon class="complete-icon-assess-needs">check_circle</mat-icon>
            <div class="complete-text-assess-needs">All Complete</div>
          </div>
        </mat-expansion-panel-header>
        <div>
          <p class="family-detail" aria-label="program" matTooltip="program">Family Members Traveling With Me </p>
        </div>
        <form fxLayout="column" class="middle-section" [formGroup]="assessNeedsForm">
          <div fxLayout="column" class="family-info-wrapper" *ngIf="retrievedFamilyDetails">
            <div fxLayout="row" class="scroll-content" fxLayout.xs="column" fxLayoutAlign.xs="unset unset"
              fxLayoutAlign.sm="space-between unset"
              *ngIf="(familyDetails && familyDetails.length) || (newFamilyInformation && newFamilyInformation > 0) ">
              <!-- Family member card with details updated-->
              <ng-container *ngFor="let member of familyDetails; trackBy:trackByFn ; index as i">
                <ng-container [ngTemplateOutlet]="familyMemberCard" [ngTemplateOutletContext]="{member:member,index:i}">
                </ng-container>
              </ng-container>
              <!-- Family member empty cards-->
              <ng-container *ngIf="newFamilyInformation && newFamilyInformation > 0">
                <ng-container *ngFor="let val of [].constructor(newFamilyInformation); index as i">
                  <ng-container [ngTemplateOutlet]="familyMemberCard"
                    [ngTemplateOutletContext]="{index:(familyDetails.length+i)}"> </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>

          <!-- Family information wrapper card template-->
          <ng-template #familyMemberCard let-member='member' let-index='index'>
            <div fxFlex="25%" fxFlex.xs="100%" fxFlex.md="38%" fxFlex.sm="45%"
              [ngClass]="(index+1)%4==0?'family-card-wrapper':'family-card-wrapper m-r-3'">
              <mat-card fxFlex="100%" fxLayout="column" role="region" tabindex="0" class="card-style"
                [attr.aria-label]="member? (member.nameDetails.title + member.nameDetails.firstName + member.nameDetails.lastName + ' Details') : 'Add Member Information'">
                <!-- Family info with details updated -->
                <ng-container *ngIf="member" [ngTemplateOutlet]="familyCardWithDetails"
                  [ngTemplateOutletContext]="{member:member}"></ng-container>
                <!-- Family info empty card template -->
                <ng-container *ngIf="!member" [ngTemplateOutlet]="familyCardNoDetails"></ng-container>
              </mat-card>
            </div>
          </ng-template>
          <!-- Family information wrapper card template-->
          <!-- Family info empty card template -->
          <ng-template #familyCardNoDetails>
            <div class="title" fxFlex="100%" fxLayoutAlign="center center" fxLayout="column"
              fxLayoutAlign.xs="start start">
              <img class="person-icon" aria-hidden="false" aria-label="person icon"
                src="../../../../../../assets/images/family-user.svg" alt="user" />
            </div>
            <div class="details" fxFlex="100%" fxLayoutAlign="center center" fxLayout="column"
              fxLayoutAlign.sm="start start" fxLayoutAlign.xs="start start">
              <p class="add-member-info">Add Member Information</p>
              <div class="placeholder"></div>
              <div class="placeholder"></div>
              <div class="placeholder"></div>
            </div>
          </ng-template>
          <!-- Family info empty card template -->
          <!-- Family info with details updated -->
          <ng-template #familyCardWithDetails let-member='member'>
            <div class="title" fxFlex="100%" fxLayoutAlign="center center" fxLayout="column"
              fxLayoutAlign.xs="start start">
              <span class="user-initials" *ngIf="member.nameDetails">{{member.nameDetails.firstName.charAt(0) |
                titlecase}}{{member.nameDetails.lastName.charAt(0) | titlecase}}</span>
              <h3 class="relationship" role="note">{{member.relationshipType}}
                <span style="font-size: 10px !important;" *ngIf="member.relationshipType == 'Child'">
                  <span *ngIf="member.age?.currentAge <= 1">(Age: Under {{member.age?.currentAge}})</span>
                  <span *ngIf="member.age?.currentAge >1">(Age: {{member.age?.currentAge}})</span>
                  <span>
                    <mat-icon class="margin-icon" [matTooltip]="getTooltipContent(member)"
                      matTooltipClass="custom-tooltip" matTooltipPosition="above">info_outline</mat-icon>
                  </span>
                </span>
              </h3>
            </div>
            <div class="details" fxFlex="100%" fxLayoutAlign="center center" fxLayout="column"
              fxLayoutAlign.sm="start start">
              <p *ngIf="member" class="member" role="note">{{member.nameDetails.title}}
                {{member.nameDetails.firstName}}
                {{member.nameDetails.lastName}}
              </p>
            </div>
            <!-- <mat-checkbox style="font-size: 13px !important;">Traveling With Me</mat-checkbox> -->
            <mat-checkbox #checkbox style="font-size: 13px !important;" (change)='selectMembers($event, member)'
              [checked]="checkForCheckList(member.id)" [disabled]="submitted || disableToggle">Traveling With Me</mat-checkbox>
          </ng-template>
          <!-- Family info with details added -->

          <div>
            <h4 class="pets-heading">Pets</h4>
            <div class="international-toggle">
              <div>
                <mat-label>No &nbsp;</mat-label>
                <mat-slide-toggle (change)="onChange($event.checked)" [checked]="toggleFlag" [disabled]="submitted || disableToggle">
                </mat-slide-toggle>
                <mat-label>&nbsp; Yes </mat-label>
              </div>
            </div>
          </div>

          <div *ngIf="show" formArrayName="petsInformation">
            <div *ngFor="let pet of assessNeedsForm.get('petsInformation').controls; let i = index">
              <div [formGroupName]='i'>
                <div fxLayout="row" fxLayoutAlign="start" fxFlex="100%" fxLayoutGap="16px" fxLayout.xs="column"
                  fxLayoutGap.xs="0px">
                  <div class="numberCircle">{{i + 1}}</div>
                  <mat-form-field fxFlex="28%">
                    <mat-label>Pet Name</mat-label>
                    <input matInput formControlName="name">
                  </mat-form-field>
                  <mat-form-field fxFlex="13%">
                    <mat-label>Pet Type</mat-label>
                    <mat-select class="pet-value" formControlName="type"
                      (selectionChange)="onChangePetType(i,$event.value)">
                      <mat-option *ngFor="let ptype of petType" [value]="ptype">
                        {{ptype}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field fxFlex="28%"
                    *ngIf="assessNeedsForm?.controls?.petsInformation?.get([i,'type'])?.value == 'Other'">
                    <mat-label>Other Pet Type</mat-label>
                    <input matInput formControlName="other">
                    <mat-error class="hint-color" *ngIf="checkIsValid()">*Pet Type is required</mat-error>
                  </mat-form-field>
                  <mat-form-field fxFlex="28%"
                    *ngIf="assessNeedsForm?.controls?.petsInformation?.get([i,'type'])?.value == 'Dog'">
                    <mat-label>Breed</mat-label>
                    <input matInput formControlName="breed">
                    <mat-error class="hint-color" *ngIf="checkIsValid()">*Breed is required</mat-error>
                  </mat-form-field>
                  <mat-form-field fxFlex="13%">
                    <mat-label>Weight</mat-label>
                    <input matInput formControlName="weight">
                  </mat-form-field>
                  <button mat-icon-button role="button"
                    [ngClass]="this.assessNeedsForm.controls.petsInformation.length === 1?'mat-delete-btn disabled-delete':'mat-delete-btn'"
                    [disabled]="this.assessNeedsForm.controls.petsInformation.length === 1 || submitted || disableToggle"
                    (click)="removePet(i)">
                    <mat-icon class="delete-icomn-style">delete_outline</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="show">
            <button mat-icon-button role="button" class="mat-button outlined-button add-button" [disabled]="submitted || disableToggle"
              (click)="addPet(i)">
              + Add </button>
          </div>

          <div>
            <h4 class="pets-heading">Pet Deposit Approved</h4>
            <div class="international-toggle">
              <div>
                <mat-label>No &nbsp;</mat-label>
                <mat-slide-toggle (change)="onChangePetDeposit($event.checked)" [checked]="PetdeposittoggleFlag"
                  [disabled]="submitted || disableToggle">
                </mat-slide-toggle>
                <mat-label>&nbsp; Yes </mat-label>
              </div>
            </div>
          </div>

          <p class="country-assignee">Country Assignee Is Traveling From (Depature Location)</p>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutGap.lt-md="0px" class="country-name">
            <mat-form-field fxFlex="60%">
              <mat-label>Country Name</mat-label>
              <mat-select formControlName="departureLocation">
                <mat-option *ngFor="let country of countryNames" [value]="country">
                  {{country}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field fxFlex="100%">
              <mat-label>Travel History</mat-label>
              <mat-select formControlName="travelHistory" multiple>
                <mat-option *ngFor="let country of countryNames" [value]="country">{{country}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-icon class="travel-icon" [matTooltip]="getTempTooltipTravelHistory()" matTooltipClass="custom-tooltip"
              matTooltipPosition="above">help_outline</mat-icon>
          </div>
        </form>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class=" candidate-review-panel move-departure-panel" multi="true">
      <mat-expansion-panel class="move-detail-panel" [expanded]="true">
        <!-- <mat-expansion-panel-header [collapsedHeight]="'auto'" >
          <div fxFlex="34%" style="color:#4b85ff ;" class="panel-heading">Housing Info</div>
          <div class="panel-heading" style="color: #AAAAAA; margin-left: 31em;" *ngIf="completedItemCountHosing > 0">
            <span class="assess-count">{{completedItemCountHosing}}</span>item to complete
          </div>
          <div class="panel-heading all-complete" *ngIf="completedItemCountHosing <= 0">
            <mat-icon class="complete-icon-assess-needs">check_circle</mat-icon>
            <div class="complete-text-assess-needs">All Complete</div>
          </div>
        </mat-expansion-panel-header> -->
        <mat-expansion-panel-header [collapsedHeight]="'auto'" >
          <div style="color:#4b85ff ;" class="panel-heading" fxFlex="25%">Housing Info</div>
          <div class="panel-heading item-count" *ngIf="completedItemCountHosing > 0"><span
              class="assess-count">{{completedItemCountHosing}}</span>item to complete</div>
          <div class="panel-heading all-complete" *ngIf="completedItemCountHosing <= 0">
            <mat-icon class="complete-icon-assess-needs">check_circle</mat-icon>
            <div class="complete-text-assess-needs">All Complete</div>
          </div>
        </mat-expansion-panel-header>
        <form fxLayout="column" class="middle-section" [formGroup]="housingPrefForm">
          <div class="middle-section">
              <div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap.xs="0em"
                  formGroupName="desiredLocation">
                  <div fxFlex="100%" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field fxFlex="100%">
                        <mat-label>Desired Location</mat-label>
                        <input matInput
                            mapAutocomplete                                     
                            type="address" formControlName = "desOfficeLocation"
                            (placeResult)="onStateAutocompleteSelected($event, 'desiredLocation', 'desOfficeLocation', 'desCountry')">
                    </mat-form-field>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap.xs="0em"
                  formGroupName="alternateLocation">
                  <!--Alternative Location field-->
                  <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field fxFlex="100%">
                        <mat-label>Alternative Location</mat-label>
                        <!-- <input matInput formControlName="altOfficeLocation">  -->
                        <input matInput
                            mapAutocomplete                                       
                            type="address" formControlName = "altOfficeLocation"
                            (placeResult)="onStateAutocompleteSelected($event, 'alternateLocation', 'altOfficeLocation', 'altCountry')">
                    </mat-form-field>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap.xs="0em"
                  formGroupName="destinationLocation">
                  <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field fxFlex="100%">
                        <mat-label>Destination Office Location</mat-label>
                        <input matInput
                            mapAutocomplete                                        
                            type="address" formControlName="destOfficeLocation"
                            (placeResult)="onStateAutocompleteSelected($event, 'destinationLocation', 'destOfficeLocation', 'destCountry')">
                    </mat-form-field>
                  </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="start" fxLayoutAlign="space-evenly" fxLayoutGap="0em"
                  fxLayout.xs="column" fxLayoutGap.xs="0em">
                  <!--Type of Accommodation field-->
                  <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutGap="0em">
                    <mat-form-field fxFlex="40%">
                      <mat-label>Type of Accommodation (Optional)</mat-label>
                      <mat-select formControlName="accommodationType">
                        <mat-option *ngFor="let type of accommodationTypeValueList" [value]="type">{{type}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <!-- Housing fields-->
                  <div fxFlex="50%" class="priority" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-label>HouseKeeping?</mat-label>
                    <div class="international-toggle">
                      <div>
                        <mat-label>Yes &nbsp;</mat-label>
                        <mat-slide-toggle (change)="onChangeHousing($event.checked)" formControlName="houseKeeping">
                        </mat-slide-toggle>
                        <mat-label>&nbsp; No </mat-label>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="showHousing" fxFlex="100%" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field fxFlex="55%">
                      <mat-label>House keeping</mat-label>
                      <mat-select formControlName="housekeepingFrequency">
                        <mat-option *ngFor="let value of housekeepingElemValueList" [value]="value">{{value}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div *ngIf="showHousing && housingPrefForm.get('housekeepingFrequency').value != 'None'" fxFlex="100%" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field fxFlex="55%">
                      <mat-label>HouseKeeping Comments (Optional)</mat-label>
                      <input matInput formControlName="comments" minLength="1" maxlength="50">
                      <mat-error>

                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <!--move in/out fields-->
                <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                  fxLayoutGap.xs="0em">
                  <!--move in-->
                  <div fxFlex="25%" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field>
                      <mat-label>Move In</mat-label>
                      <input matInput [matDatepicker]="moveInDate" [min]="minDate" (dateChange)="moveInDateChange();getLengthOfStay()" formControlName="moveInDate">
                      <mat-datepicker-toggle matSuffix [for]="moveInDate">
                      </mat-datepicker-toggle>
                      <mat-datepicker #moveInDate></mat-datepicker>
                      <mat-error *ngIf="housingPrefForm.get('moveInDate').invalid">
                        Move In date must be > current date
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <!--move out-->
                  <div fxFlex="75%" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field>
                      <mat-label>Move Out</mat-label>
                      <input matInput [matDatepicker]="moveOutDate" [min]="minMoveDate" (dateChange)="getLengthOfStay()" formControlName="moveOutDate">
                      <mat-datepicker-toggle matSuffix [for]="moveOutDate">
                      </mat-datepicker-toggle>
                      <mat-datepicker #moveOutDate></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <!--Length of Say-->
                  <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field *ngIf="housingPrefForm.get('moveInDate').value && housingPrefForm.get('moveOutDate').value">
                      <mat-label>Length of Stay</mat-label>
                      <!-- <input matInput formControlName="lengthOfStay" (keypress)="onNumberValueValidation($event)" readonly type="number" min="1"> -->
                      <input matInput formControlName="lengthOfStay" readonly>
                    </mat-form-field>
                  </div>
  
                </div>
                <!-- bedrooms/apartment fields-->
                <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                  fxLayoutGap.xs="0em">
                  <!-- bedrooms field-->
                  <div fxFlex="25%" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field>
                      <mat-label>Number Of Bedrooms</mat-label>
                      <mat-select formControlName="numberOfBedrooms">
                        <mat-option *ngFor="let bedroom of numberBedroomsValueList" [value]="bedroom">{{bedroom}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <!-- bathrooms field-->
                  <div fxFlex="25%" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field>
                      <mat-label>Number Of Bathrooms</mat-label>
                      <mat-select formControlName="numberOfBathrooms">
                        <mat-option *ngFor="let bathrooms of numberBathroomsValueList" [value]="bathrooms">{{bathrooms}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <!--apartment field-->
                  <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field>
                      <mat-label>Number Of Apartments</mat-label>
                      <input (keypress)="onNumberValueValidation($event)" type="number" matInput formControlName="numberOfApartments" min="1" maxlength="50">
                      <mat-error *ngIf="housingPrefForm.get('numberOfApartments').errors?.pattern">
                        number must be greater than zero
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <!-- furnishing fields-->
                <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                  fxLayoutGap.xs="0em">
                  <!--Furnishing-->
                  <div fxFlex="30%" class="priority" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-label>Furnishings?</mat-label>
                    <div class="international-toggle">
                      <div>
                        <mat-label>Furnished &nbsp;</mat-label>
                        <mat-slide-toggle formControlName="furnished"></mat-slide-toggle>
                        <mat-label>&nbsp; UnFurnished </mat-label>
                      </div>
                    </div>
                  </div>
                  <!--Kitchen-->
                  <div fxFlex="30%" class="priority" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-label>Kitchen Facility Required?</mat-label>
                    <div class="international-toggle">
                      <div>
                        <mat-label>Yes &nbsp;</mat-label>
                        <mat-slide-toggle formControlName="kitchenRequired"></mat-slide-toggle>
                        <mat-label>&nbsp; No </mat-label>
                      </div>
                    </div>
                  </div>
                  <!--baby cost field-->
                  <div fxFlex="35%" class="priority" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-label>Baby Cot Needed?</mat-label>
                    <div class="international-toggle">
                      <div>
                        <mat-label>Yes &nbsp;</mat-label>
                        <mat-slide-toggle formControlName="babyCotRequired"></mat-slide-toggle>
                        <mat-label>&nbsp; No </mat-label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Parking -->
                <div fxLayout="row" fxLayoutAlign="space-evenly" fxLayoutGap="2em" fxLayout.xs="column"
                  fxLayoutGap.xs="0em">
                  <div class="parking" fxFlex="100%" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-label>Parking?</mat-label>
                    <div class="international-toggle">
                      <div>
                        <mat-label>Yes &nbsp;</mat-label>
                        <mat-slide-toggle formControlName="parking"></mat-slide-toggle>
                        <mat-label>&nbsp; No </mat-label>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="!housingPrefForm.get('parking').value" fxLayout="column" fxLayoutAlign="space-evenly"
                  fxLayout.xs="column" fxLayoutGap.xs="0em">
                  <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field fxFlex="30%">
                      <mat-label>Parking Financial Responsibility</mat-label>
                      <mat-select formControlName="financialResponsibility">
                        <mat-option *ngFor="let type of financialResponsibilityValueList" [value]="type">{{type}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field fxFlex="55%">
                      <mat-label>Parking Comments (Optional)</mat-label>
                      <input matInput formControlName="parkingComments">
                    </mat-form-field>
                  </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="space-evenly" fxLayout.xs="column" fxLayoutGap.xs="0em">
                  <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field fxFlex="30%">
                      <mat-label>Acceptable Commutes (minutes)</mat-label>
                      <input (keypress)="onNumberValueValidation($event)" type="number" matInput formControlName="acceptableCommute" min="1" maxlength="50">
                      <mat-error *ngIf="housingPrefForm.get('acceptableCommute').errors?.pattern">
                        number must be greater than zero
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <!-- Priorities Fields-->
                <div formGroupName="priorities" class="input-row" fxLayout="column" fxLayoutAlign="space-evenly"
                  fxLayout.xs="column" fxLayoutGap.xs="0em">
                  <mat-label class="parking">Priorities</mat-label>
                  <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutGap="0em">
                    <div class="numberCircle">1</div>
                    <mat-form-field class="priority-field" fxFlex="45%"
                      (click)="updatePriorityDropDownValues(0, 'priorityOne')">
                      <mat-label>Priority 1</mat-label>
                      <mat-select formControlName="priorityOne">
                        <mat-option *ngFor="let priority of priorityList[0]" [value]="priority">{{priority}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutGap="0em">
                    <div class="numberCircle">2</div>
                    <mat-form-field class="priority-field" fxFlex="45%"
                      (click)="updatePriorityDropDownValues(1, 'priorityTwo')">
                      <mat-label>Priority 2</mat-label>
                      <mat-select formControlName="priorityTwo">
                        <mat-option *ngFor="let priority of priorityList[1]" [value]="priority">{{priority}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutGap="0em">
                    <div class="numberCircle">3</div>
                    <mat-form-field class="priority-field" fxFlex="45%"
                      (click)="updatePriorityDropDownValues(2, 'priorityThree')">
                      <mat-label>Priority 3</mat-label>
                      <mat-select formControlName="priorityThree">
                        <mat-option *ngFor="let priority of priorityList[2]" [value]="priority">{{priority}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutGap="0em">
                    <div class="numberCircle">4</div>
                    <mat-form-field class="priority-field" fxFlex="45%"
                      (click)="updatePriorityDropDownValues(3, 'priorityFour')">
                      <mat-label>Priority 4</mat-label>
                      <mat-select formControlName="priorityFour">
                        <mat-option *ngFor="let priority of priorityList[3]" [value]="priority">{{priority}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutGap="0em">
                    <div class="numberCircle">5</div>
                    <mat-form-field class="priority-field" fxFlex="45%"
                      (click)="updatePriorityDropDownValues(4, 'priorityFive')">
                      <mat-label>Priority 5</mat-label>
                      <mat-select formControlName="priorityFive">
                        <mat-option *ngFor="let priority of priorityList[4]" [value]="priority">{{priority}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <!--Additional Instruction field-->
                <div class="input-row" fxLayout="column" fxLayoutAlign="space-evenly" fxLayout.xs="column"
                  fxLayoutGap.xs="0em">
                  <span>
                    <mat-label>Additional Instructions</mat-label>
                    <mat-icon class="margin-icon" [matTooltip]="getTooltip()" matTooltipClass="custom-tooltip"
                      matTooltipPosition="above">info_outline</mat-icon>
                  </span>
                  <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field fxFlex="100%">
                      <mat-label>Enter Additional Instructions</mat-label>
                      <textarea matInput formControlName="additionalInstructions" rows="1"></textarea>
                    </mat-form-field>
                  </div>
                </div>
              </div>
          </div>
        </form>
      </mat-expansion-panel>
    </mat-accordion>
    <!-- Financial Details-->
   <mat-accordion  class=" candidate-review-panel move-departure-panel"  multi="true" >
    <mat-expansion-panel class="move-detail-panel" [expanded]="true">
       <mat-expansion-panel-header [collapsedHeight]="'auto'"  >
         <div fxFlex="34%" style="color:#4b85ff ;" class="panel-heading">Financial Details</div>
       </mat-expansion-panel-header>
       <div fxLayout="row" fxLayoutGap="50px">
        <div>
         <mat-form-field class="form-field" appearance="none">
           <mat-label>Cartus VAT Country</mat-label>
           <input disabled matInput type="text" > {{data.details.financialDetails?.VATCountry}}
         </mat-form-field>
        </div>
        <div>
          <mat-form-field class="form-field" appearance="none">
            <mat-label>Cartus VAT Registration #</mat-label>
            <input disabled matInput type="text" >{{data.details.financialDetails?.VATRegistration ? data.details.financialDetails.VATRegistration : 'NA'}}
          </mat-form-field>
         </div>
         <div class="border">
          <mat-form-field class="form-field" appearance="none">
            <mat-label>Invoice Format</mat-label>
            <input disabled matInput type="text" >{{data.details.financialDetails?.mailingAddress?.invoiceFormat == 'physical' ? 'Physical' : data.details.financialDetails?.mailingAddress?.invoiceFormat == 'digital' ? 'Digital' : 'NA'}}
          </mat-form-field>
         </div>
      </div>
      <div fxLayout="row" fxLayoutGap="50px" >
        <div>
          <mat-form-field class="form-field" appearance="none">
            <mat-label>Remit to Address</mat-label>
            <input disabled matInput type="text" >{{data.details.financialDetails?.remitToAddress?.businessName}}<br>{{data.details.financialDetails?.remitToAddress?.line1}} {{data.details.financialDetails?.remitToAddress?.line2}}<br>
             {{data.details.financialDetails?.remitToAddress?.city}}<br>{{data.details.financialDetails?.remitToAddress?.subnationalDivision}}<br>{{data.details.financialDetails?.remitToAddress?.country}}<br>{{data.details.financialDetails?.remitToAddress?.postalCode}}
          </mat-form-field>
         </div>
         <div>
          <mat-form-field class="form-field" appearance="none">
            <mat-label>Fiscal Representative</mat-label> 
            <input disabled matInput type="text" >{{data.details.financialDetails?.fiscalRepresentative ? data.details.financialDetails?.fiscalRepresentative?.businessName : 'NA'}}<br>{{data.details.financialDetails?.fiscalRepresentative?.line1}} {{data.details.financialDetails?.fiscalRepresentative?.line2}}<br>
             {{data.details.financialDetails?.fiscalRepresentative?.city}}<br>{{data.details.financialDetails?.fiscalRepresentative?.subnationalDivision}}<br>{{data.details.financialDetails?.fiscalRepresentative?.country}}<br>{{data.details.financialDetails?.fiscalRepresentative?.postalCode}}
          </mat-form-field>
         </div>
         <div class="border1">
           <mat-form-field class="form-field" appearance="none">
             <mat-label>Mailing Address</mat-label>
             <input disabled matInput type="text" >
             <!--for Physical Address/Standard-->
             <p *ngIf="data.details.financialDetails?.mailingAddress?.physicalInvoicing?.standardMailAddress">
              {{data.details.financialDetails?.mailingAddress?.physicalInvoicing?.standardMailAddress?.businessName}}<br>{{data.details.financialDetails?.mailingAddress?.physicalInvoicing?.standardMailAddress?.line1}} {{data.details.financialDetails?.mailingAddress?.physicalInvoicing?.standardMailAddress?.line2}}<br>
              {{data.details.financialDetails?.mailingAddress?.physicalInvoicing?.standardMailAddress?.city}}<br>{{data.details.financialDetails?.mailingAddress?.physicalInvoicing?.standardMailAddress?.subnationalDivision}}<br>{{data.details.financialDetails?.mailingAddress?.physicalInvoicing?.standardMailAddress?.country}}<br>{{data.details.financialDetails?.mailingAddress?.physicalInvoicing?.standardMailAddress?.postalCode}}
             </p> <br>
             <!--for Physical Address/Express-->
             <p *ngIf="data.details.financialDetails?.mailingAddress?.physicalInvoicing?.expressMailAddress">
              {{data.details.financialDetails?.mailingAddress?.physicalInvoicing?.expressMailAddress?.businessName}}<br>{{data.details.financialDetails?.mailingAddress?.physicalInvoicing?.expressMailAddress?.line1}} {{data.details.financialDetails?.mailingAddress?.physicalInvoicing?.expressMailAddress?.line2}}<br>
              {{data.details.financialDetails?.mailingAddress?.physicalInvoicing?.expressMailAddress?.city}}<br>{{data.details.financialDetails?.mailingAddress?.physicalInvoicing?.expressMailAddress?.subnationalDivision}}<br>{{data.details.financialDetails?.mailingAddress?.physicalInvoicing?.expressMailAddress?.country}}<br>{{data.details.financialDetails?.mailingAddress?.physicalInvoicing?.expressMailAddress?.postalCode}}
             </p>
             <!--for Digital Address/Express-->
             <p *ngIf="data.details.financialDetails?.mailingAddress?.digitalInvoicing"> {{data.details.financialDetails?.mailingAddress?.digitalInvoicing?.emailAddress}}</p>
           </mat-form-field>
        </div>
      </div>
   </mat-expansion-panel>
   </mat-accordion>
   <mat-accordion  class=" candidate-review-panel move-departure-panel"  multi="true"  *ngIf="(data.checkHybridTempliving && data.chckForModification) || (data.reimbursement && data.chckForModification) " >
    <mat-expansion-panel class="move-detail-panel" [expanded]="true" id="panel-1">
       <mat-expansion-panel-header [collapsedHeight]="'auto'"  >
         <div fxFlex="34%" style="color:#4b85ff ;" class="panel-heading">Order Modifications</div>
       </mat-expansion-panel-header>
       <div #scrollMe *ngIf="data.modification>0">
      <table style="width: 100%;" mat-table [dataSource]="data?.supplierData">
        <!-- Days Added Column -->
        <ng-container matColumnDef="DaysAdded">
          <th mat-header-cell *matHeaderCellDef> {{ data.checkHybridTempliving ? 'Days Added' : 'Amount Added'}} </th>
          <td mat-cell  class="tableData" style="padding-right: 42px;" *matCellDef="let element; let woi = index">{{ data.checkHybridTempliving ? element.daysAdded : element.amountAdded }} {{data.checkHybridTempliving && element.daysAdded ? data.rangeIncrementUnit : element.amountAdded ? data.incrementalCurrencyUnit : '' }} </td>
        </ng-container>
        <!-- Total Length of Stay Column -->
        <ng-container matColumnDef="lengthOfStay">
          <th mat-header-cell *matHeaderCellDef> {{ data.checkHybridTempliving ? 'Total Length of Stay' : 'Total Amount Reimbursed'}} </th>
          <td mat-cell class="tableData" *matCellDef="let element">{{ data.checkHybridTempliving ? element.totalLengthofStay : element.totalAmountReimbursed }} {{data.checkHybridTempliving && element.totalLengthofStay ?  data.rangeIncrementUnit : element.totalAmountReimbursed ? data.incrementalCurrencyUnit : '' }} </td>
        </ng-container>

        <!-- New Move Out Date Column -->
        <ng-container matColumnDef="moveOut" *ngIf="data.checkHybridTempliving">
          <th mat-header-cell *matHeaderCellDef> New Move Out Date </th>
          <td mat-cell class="tableData" *matCellDef="let element"> {{(element.newMoveOutDate).split('T')[0]}} </td>
        </ng-container>
        <!--Modification Column-->
        <ng-container matColumnDef="modificationDate">
          <th mat-header-cell *matHeaderCellDef>Date of Modification </th>
          <td mat-cell class="tableData" *matCellDef="let element">{{(element.dateOfModification).split('T')[0]}}</td>
        </ng-container>
        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element; let statusIndex = index">
            <mat-form-field *ngIf="element.status == 'Set Status'">
              <mat-select [(value)]="statusDropDown[statusIndex]" (selectionChange)="onStatusSelect($event ,element, statusIndex)"
                [disabled]="element.disableStatus || element.status == 'Rejected' || element.status == 'Accepted'">
                  <mat-option *ngFor="let list of statusValue"
                      [value]="list"><span>{{list}}</span>
                  </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="element.status !== 'Set Status'">
              <mat-select [(value)]="statusDropDown[statusIndex]" (selectionChange)="onStatusSelect($event ,element, statusIndex)"
                [disabled]="element.disableStatus || element.status == 'Rejected' || element.status == 'Accepted'">
                  <mat-option *ngFor="let list of afterSetStatusValue"
                      [value]="list"><span>{{list}}</span>
                  </mat-option>
              </mat-select>
          </mat-form-field>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
    </div>
    <div class="noRecordData" *ngIf="!data.modification">
      No Modifications Recorded
    </div>
   </mat-expansion-panel>
   </mat-accordion>
  </div>
</mat-dialog-content>

<div fxLayout="row" fxLayoutAlign="space-between end">
  
    <div fxFlex="30%" class="download" *ngIf="!cancelToggle">
      <button mat-button class="download-btn" (click)="openDownloadToastr()">
        <mat-icon tabindex="0" class="download-icon close-icon">save_alt</mat-icon>
        Download
      </button>
    </div>
    <div *ngIf="disableToggle">
      <button mat-button class="cancel-btn cancel-border" (click)="onCancel()"
        [ngStyle]="cancelToggle?{'margin-left': '450%'}:null">Close</button>
    </div>
    <div fxFlex="59%" *ngIf="!disableToggle">
      <button mat-button class="cancel-btn" 
        (click)="onCancel()">Cancel</button>
      <button mat-button class="draft-btn"  
        [disabled] ="!(assessNeedsForm.dirty || this.housingPrefForm.dirty || this.relocationForm.dirty) || data?.disable || isCheckDogBreed || splitBill || splitBillMonetaryAmt  || submitted  || (show && !assessNeedsForm?.get('petsInformation').valid )"  (click)="onSubmit('draft')">Save Draft</button>  
  
      <button mat-button class="submit-btn"  
        [disabled] ="!this.relocationForm.valid ||!this.housingPrefForm.valid || data?.disable || checkValidationForPriority() || !this.assessNeedsForm?.get('departureLocation').valid || isCheckDogBreed || splitBill || splitBillMonetaryAmt || submitted || businessEmail == '' || businessNum == ''|| !data.details.policyDetails.geographicOrigin || ( show && !assessNeedsForm?.get('petsInformation').valid) || (!this.housingPrefForm?.get('parking').value && !this.housingPrefForm?.get('financialResponsibility').valid)"  
        [ngClass]="{'disable-save-btn':((!this.housingPrefForm?.get('parking').value && !this.housingPrefForm?.get('financialResponsibility').valid))||!this.relocationForm.valid || !this.housingPrefForm.valid || data?.disable || checkValidationForPriority() || !this.assessNeedsForm?.get('departureLocation').valid || !this.assessNeedsForm?.get('travelHistory').valid || isCheckDogBreed || splitBill || splitBillMonetaryAmt || submitted || businessEmail == '' || businessNum == ''|| !data.details.policyDetails.geographicOrigin || ( show && !assessNeedsForm?.get('petsInformation').valid)}" 
        (click)="onSubmit('submit')">Submit</button>
   </div>
</div>
</div>



<mat-card fxLayoutAlign="start" fxLayout="column" class="address">
        <mat-card-title class="custom card-title" fxLayout="row" fxLayoutAlign="start center">
            <img [src]="isDestination ? 'assets/images/departure.svg': 'assets/images/arrival.svg'" alt="arrival" class="depart-arrival-icon" />
            {{isDestination ? 'Destination' : 'Departure'}} Location
        </mat-card-title>
        <mat-card-content>
            <form [formGroup]="addressForm" *ngIf="departCountryCode">
                <div fxLayout="column" class="m-t-10">
                    <mat-form-field>
                        <mat-label>Country</mat-label>
                        <input type="text" aria-label="Country" id="deptCountry" matInput #autoCompleteCountryInput
                            formControlName="country" [matAutocomplete]="autoCountry">
                        <mat-autocomplete #autoCountry="matAutocomplete" [panelWidth]="293" (optionSelected)="onFocusOutDepartureCountryCode()">
                            <mat-option [value]="option.countryName" *ngFor="let option of filteredCountryOptions | async">
                                {{ option.countryName }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div fxLayout="column" class="m-t-10" formArrayName="streets">
                    <div *ngFor="let street of streets.controls; index as idx">
                        <mat-form-field class="block">
                            <mat-label>Street Address</mat-label>
                            <input matInput placeholder="Street Address (Optional)"
                            mapAutocomplete
                            [country]="departCountryCode"                                        
                            type="address"
                            (placeResult)="onAutocompleteSelected($event)"
                            [formControlName]="idx">
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="column" class="m-t-10" formArrayName="cities">
                    <div *ngFor="let city of cities.controls; index as cityIdx">
                    <mat-form-field class="block">
                        <mat-label>City</mat-label>                                       
                         <input  matInput 
                         mapAutocomplete
                         [country]="departCountryCode"
                         type="(cities)"
                         (placeResult)="onDepartureCityAutocompleteSelected($event)"                                         
                         [formControlName]="cityIdx"
                          placeholder="City">                                       
                    </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" class="m-t-10">
                    <div formArrayName="states" fxFlex="48%">
                        <div *ngFor="let state of states.controls; index as stateIdx">
                            <mat-form-field class="state-fields">
                                <mat-label>State/Province</mat-label>
                                <input matInput [formControlName]="stateIdx"
                                mapAutocomplete
                                [country]="departCountryCode"
                                type="administrative_area_level_1"
                                (placeResult)="onDepartureStateAutocompleteSelected($event)"
                                placeholder="State"/>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxFlex="4%"></div>
                    <div formArrayName="postalCodes" fxFlex="48%">
                        <div *ngFor="let postal of postalCodes.controls; index as postalIdx">
                            <mat-form-field class="postal-code-fields">
                                <mat-label>Postal Code</mat-label>
                                <input matInput placeholder="Postal Code"
                                [formControlName]="postalIdx"
                                mapAutocomplete
                                [country]="departCountryCode"
                                type="postal_code"
                                (placeResult)="onDeparturePostalCodeAutocompleteSelected($event)"
                                minLength= "1" maxlength="15">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
        </mat-card-content>
</mat-card>
<div *ngIf="data.riskType!= 4 && !traditional" cdkTrapFocus cdkTrapFocusAutoCapture>
    <div class="popUpTitleBlk" fxLayout="row" fxLayoutAlign="space-between center">
        <h2>Move Risk Status</h2><button mat-icon-button (click)="close()"><span
                class="material-icons">close</span></button>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <ng-container *ngIf="data.riskType === 1">
            <img class="trackimg" src="../../../../../assets/images/moveRisk_offTrack.svg" alt="moveRisk_offTrack"/>
            <span class="tracktext">Off Track</span>
        </ng-container>
        <ng-container *ngIf="data.riskType=== 3">
            <img class="trackimg" src="../../../../../assets/images/moveRisk_onTrack.svg" alt="moveRisk_onTrack"/>
            <span class="tracktext">On Track</span>
        </ng-container>
    </div>
    <mat-divider class="startdividerdist"></mat-divider>
    <div class="datedata" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="trandate">
            <span class="datetxt">Transfer Date:</span> <span
                class="dataval">{{dateConversionService.convertToDisplayDate(data.transferDate)}}</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="estdate">
            <img class="homeimg" src="../../../../../assets/images/moveRisk_homeIcon.svg" alt="moveRisk_homeIcon"/>
            <span class="datetxtest">Estimated Move Date:</span> <span
                class="datavalest">{{dateConversionService.convertToDisplayDate(data.predictedDate)}}</span>
        </div>
    </div>
    <div class="sliderborder" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="rangetxt">Predicted Move Range:</div>
        <img class="sliderimg" src="../../../../../assets/images/moveRisk_dateRangeHome.svg" alt="moveRisk_dateRangeHom"/>
        <div class="slider"></div>
        <div class="startline"></div>
        <div class="endline"></div>
        <div class="startlineval">{{dateConversionService.convertToDisplayDate(data.predictedDateEarly)}}</div>
        <div class="endlineval">{{dateConversionService.convertToDisplayDate(data.predictedDateLate)}}</div>
    </div>
    <mat-divider class="dividerdist"></mat-divider>
    <div fxLayout="row" fxLayoutAlign="center center">
        <button mat-button class="contained-button" (click)="close()">Close</button>
    </div>
</div>



<div *ngIf="data.riskType=== 4 && !traditional" cdkTrapFocus cdkTrapFocusAutoCapture>
    <div class="popUpTitleBlk" fxLayout="row" fxLayoutAlign="space-between center">
        <h2>Move Risk Status</h2><button mat-icon-button (click)="close()"><span
                class="material-icons">close</span></button>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <img class="trackimg" src="../../../../../assets/images/moveRisk_complete.svg" alt="moveRisk_complete"/>
        <span class="tracktext">Risk Complete</span>
    </div>
    <mat-divider class="startdividerdist"></mat-divider>
    <div class="datedata" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="trandate">
            <span class="datetxt">Transfer Date:</span> <span class="dataval">{{dateConversionService.convertToDisplayDate(data.transferDate)}}</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="estdate">
            <span class="datetxtest">Date Risk Completed:</span> <span
                class="datavalest">{{dateConversionService.convertToDisplayDate(data.moveRiskCompletionDt)}}</span>
        </div>
    </div>
    <mat-divider class="dividerdist"></mat-divider>
    <div fxLayout="row" fxLayoutAlign="center center">
        <button mat-button class="contained-button" (click)="close()">Close</button>
    </div>
</div>

<!-- ---For Traditional Move--- -->
<div class= "traditionalMove"  *ngIf="traditional">
<div *ngIf="data.riskType!= 4" cdkTrapFocus cdkTrapFocusAutoCapture>
    <div class="popUpTitleBlk" fxLayout="row" fxLayoutAlign="space-between center">
        <span>Move Risk</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-between center">
        <ng-container *ngIf="data.riskType === 1">
            <img class="trackimg" src="../../../../../assets/images/moveRisk_offTrack.svg" alt="moveRisk_offTrack"/>
            <span class="tracktext">Off Track</span>
        </ng-container>
        <ng-container *ngIf="data.riskType=== 3">
            <img class="trackimg" src="../../../../../assets/images/moveRisk_onTrack.svg" alt="moveRisk_onTrack"/>
            <span class="tracktext">On Track</span>
        </ng-container>
    </div>
    <mat-divider class="startdividerdist"></mat-divider>
    <div class="datedata" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="trandate" fxLayoutGap="15px">
            <span class="datetxt">Transfer Date:</span> <span
                class="dataval">{{dateConversionService.convertToYYYYMMDD(data.transferDate)}}</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-arround center" class="estdate"  fxLayoutGap="15px">
            <img class="homeimg" src="../../../../../assets/images/moveRisk_homeIcon.svg" alt="moveRisk_homeIcon"/>
            <span class="datetxtest">Estimated Move Date:</span> <span
                class="datavalest">{{dateConversionService.convertToYYYYMMDD(data.predictedDate)}}</span>
        </div>
    </div>
    <div class="sliderborder" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
        <div class="rangetxt">Predicted Move Range:</div>
        <img class="sliderimg" src="../../../../../assets/images/moveRisk_dateRangeHome.svg" alt="moveRisk_dateRangeHome"/>
        <div class="slider"></div>
        <div class="startline"></div>
        <div class="endline"></div>
        <div class="startlineval">{{dateConversionService.convertToYYYYMMDD(data.predictedDateEarly)}}</div>
        <div class="endlineval">{{dateConversionService.convertToYYYYMMDD(data.predictedDateLate)}}</div>
    </div>
    
</div>



<div *ngIf="data.riskType=== 4" cdkTrapFocus cdkTrapFocusAutoCapture  class="traditional_completed">
    <div class="popUpTitleBlk" fxLayout="row" fxLayoutAlign="space-between center">
        <span>Move Risk Status</span>
    </div>
    <div style="align-items: center;">
        <div fxLayout="column" fxLayoutAlign="space-between center">
            <img class="trackimg" src="../../../../../assets/images/moveRisk_complete.svg" alt="moveRisk_complete"/>
            <span class="tracktext">Risk Complete</span>
        </div>
        <mat-divider class="startdividerdist"></mat-divider>
        <div class="datedata" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column" fxLayoutAlign="space-between center" class="trandate">
                <span class="datetxt">Transfer Date:</span> <span class="dataval">{{dateConversionService.convertToYYYYMMDD(data.transferDate)}}</span>
            </div>
            <div fxLayout="column" fxLayoutAlign="space-between center" class="estdate">
                <span class="datetxtest">Date Risk Completed:</span> <span
                    class="datavalest">{{dateConversionService.convertToYYYYMMDD(data.moveRiskCompletionDt)}}</span>
            </div>
        </div>
    </div>
</div>
</div>

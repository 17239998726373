<div class="filter-chips">
    <mat-chip-list aria-label="Filter Selection">
        <ng-container *ngFor="let filter of data">
            <mat-chip
                [ngClass]="{movePhase: filter.category === 'Move Phase', fileStatusDesc: filter.category === 'File Status', policy: filter.category === 'Policy', departure: filter.category === 'Departure',
            destination: filter.category === 'Destination', dateRange: filter.category === 'Date Range', costRange: filter.category === 'Cost Range', division: filter.category === 'Division', favorites: filter.category === 'favorites'}"
                matTooltip="{{filter.value}}" matTooltipPosition="above"
                matTooltipDisabled="{{(filter.value.length <= 25)}}">
                <span>
                    <p [attr.aria-label]='filter.category' [className]="filter.category === 'favorites' ? 'favouritesHide' : ''">{{filter.category}}</p>
                    <p [attr.aria-label]='filter.value | truncate : 25 : 20'>{{filter.value | truncate : 25 : 20}} <ng-container *ngIf="filter.infinite"><img
                                src="../assets/images/endOfRange.svg" alt="endOfRange"/></ng-container>
                    </p>
                </span>
                <mat-icon tabindex="0" title="Remove" (click)="removeFilter(filter)">close</mat-icon>
            </mat-chip>
        </ng-container>
    </mat-chip-list>
</div>
import { Component, Input, OnInit, ViewChild, EventEmitter, Output, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortHeaderIntl, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CandidateProfileService } from '../../services/candidate-profile.service';
import { UserType, UserTypeService } from '../../services/user-type.service';
import { Observable } from 'rxjs';
import { CompliantAdjudicationTransfereesComponent } from '../compliant-adjudication-transferees/compliant-adjudication-transferees.component';
import { ComplianceListModel } from '../../../../core/models/compliance-list.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { AddressFormWrapperComponent } from '../address-form-wrapper/address-form-wrapper.component';
import { ToastrService } from 'ngx-toastr';

export interface Options {
  sortBy?: string;
  sortDirection?: string;
  searchText?: string,
  filters?: any,
  itemsPerPage?: number,
  pageNumber?: number
}

@Component({
  selector: 'app-compliance-list',
  templateUrl: './compliance-list.component.html',
  styleUrls: ['./compliance-list.component.scss']
})
export class ComplianceListComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['transferee', 'client', 'authorizationType','departure', 'destination', 'authorizationDate', 'status', 'action', 'adjudicate'];
  dataSource = new MatTableDataSource();

  /** To sort the mat table columns */
  @ViewChild('complianceListSort') complianceTableSort = new MatSort();
  /** Pagination of Compliance transferee */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /**Direction varialbe to get sort direction */
  direction: SortDirection;

  /** sortDirection to hold the asc or desc direction value */
  sortDirection: 'ASC' | 'DESC' | null;

  /** sortColumnName to hold the clicked column name for sorting */
  sortColumnName: 'transferee' | 'client' | 'departure' | 'destination' | 'date' | 'status' | null;

  /** Options to hold the sort column name | sort direction | pagination */
  options: Options = {};

  @Input() searchComplianceValue: string;
  @Input() selectedFilters: any;

  /** page event variable to handle the page events */
  pageEvent: PageEvent;
  @Input() searchKeyword: string;

  disableAdjudication = false;
  /** user role */
  userRole: UserType;

  constructor(
    private candidateService: CandidateProfileService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private userTypeService: UserTypeService,
    private readonly toastrService: ToastrService
  ) {
    this.pageEvent = new PageEvent();
    this.pageEvent.pageIndex = 0;
    this.pageEvent.pageSize = 75;
    this.options.pageNumber = 0;
    this.options.itemsPerPage = 75;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['selectedFilters'] !== "undefined" && !changes['selectedFilters'].firstChange) {
      if (changes['selectedFilters'].currentValue) {
        const filters = { ...changes['selectedFilters'].currentValue };
        if (filters.clients) {
          let selectedFilter = { ...filters }
          let clientPartyIds = [...selectedFilter.clients];
          clientPartyIds = clientPartyIds.map(ele => ele.clientId);
          delete selectedFilter.clients;
          this.options['filters'] = { ...selectedFilter, clientPartyIds: clientPartyIds };
        } else {
          this.options['filters'] = filters;
        }
        this.getComplianceData(this.options);
      }
    }

    if (typeof changes['searchComplianceValue'] !== "undefined" && !changes['searchComplianceValue'].firstChange) {
      if (changes['searchComplianceValue'].currentValue) {
        const searchText = changes['searchComplianceValue'].currentValue;
        if (searchText && searchText.length >= 3 ) {
          this.options['searchText'] = searchText;
          this.getComplianceData(this.options);
        }
      } else {
        this.options['searchText'] = '';
        this.getComplianceData(this.options);
      }
    }
  }

  ngAfterViewInit() {
    if (this.paginator) {
      const paginatorIntl = this.paginator._intl;
      paginatorIntl.nextPageLabel = 'Next';
      paginatorIntl.previousPageLabel = 'Previous';
      this.paginator.pageSize = this.pageEvent.pageSize;
      this.dataSource.paginator = this.paginator;
    }
  }

  ngOnInit() {
    this.userRole = this.userTypeService.userType;
    this.userTypeService.capabilities$.subscribe(ele => {
      const manageComplianceAuthorization = ele ? ele.find(role => role.name === 'MP - Manage Adjudication')?.operation : '';
      if(manageComplianceAuthorization && manageComplianceAuthorization === 'write') {
        this.getQuerParamForComplianceTab();
        this.disableAdjudication = false;
      } else {
        this.disableAdjudication = true;
      }
    });
    this.dataSource.sort = this.complianceTableSort;
    this.getComplianceData();
  }

  getComplianceData(options?) {
    if (!options) {
      this.options = {
        itemsPerPage: this.pageEvent.pageSize,
        pageNumber: this.pageEvent.pageIndex
      };
    }
    this.candidateService.getComplianceList(this.options).subscribe(
      res => {
        if (res) {
          this.dataSource = new MatTableDataSource([])
          this.dataSource = new MatTableDataSource(res);
          if (this.paginator) {
            this.paginator.length = res['count'] || 0
            this.paginator.pageIndex = this.pageEvent.pageIndex;
            this.paginator.pageSize = this.pageEvent.pageSize ? this.pageEvent.pageSize : null;
            const paginatorIntl = this.paginator._intl;
            paginatorIntl.nextPageLabel = 'Next';
            paginatorIntl.previousPageLabel = 'Previous';
          }
        } else {
          this.paginator.length = 0;
          this.dataSource = new MatTableDataSource([]);
        }
      });
  }


  onStatusClick(candidate) {
    /* TODO - to be covered in another ticket
      const dialogRef = this.dialog.open(EmailCoversationContentComponent, {
        data: {},
        width: '52vw',
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(result => {
          this.getComplianceData();
      });
      */
  }

  onAdjudicate(event) {
    const dialogRef = this.dialog.open(CompliantAdjudicationTransfereesComponent, {
      data: {
        rowData: event
      },
      width: '52vw',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        if (this.sortColumnName && this.sortDirection) {
          this.options.sortBy = this.sortColumnName
          this.options.sortDirection = this.sortDirection
        };
        this.getComplianceData(this.options);
      }
    });
  }

  onPageChange(event) {
    if (event) {
      this.pageEvent.pageIndex = event.pageIndex;
      this.pageEvent.pageSize = event.pageSize;
    } else {
      this.pageEvent.pageIndex = 0;
    }
    this.options.itemsPerPage = this.pageEvent.pageSize
    this.options.pageNumber = this.pageEvent.pageIndex
    this.getComplianceData(this.options);
  }


  /**
   * Sort table based on selected Column name 
   * sortObj stores column name and direction based on the change events
   */
  applySort(sortObj) {
    if (sortObj) {
      this.sortColumnName = sortObj.active
      this.sortDirection = sortObj.direction ? sortObj.direction.toUpperCase() : 'ASC'
      this.options.sortBy = this.sortColumnName === 'transferee' ? 'fullName' : this.sortColumnName
      this.options.sortDirection = this.sortDirection
      this.getComplianceData(this.options)
    }
  }

  getQuerParamForComplianceTab() {
    const query = localStorage.getItem('query');
    let queryObject;
    if(query) {
      queryObject = JSON.parse(window.atob(query))
    }

    if (queryObject && queryObject.hasOwnProperty('orderRequestId')) {
      this.fetchTransfereeDetails(queryObject.orderRequestId)
    };
  }

  fetchTransfereeDetails(orderRequestId) {
    this.spinner.show();
    let transfereeData;
    this.candidateService.getTransferee(orderRequestId).subscribe(res => {
      if (res) {
        res.candidateDetails._id = orderRequestId;
        transfereeData = new ComplianceListModel(res.candidateDetails)
        if(transfereeData.moveStatus === 'In Review') {
          this.onAdjudicate(transfereeData);
        } else {
          this.openInfoModal(transfereeData);
        }
        this.spinner.hide();
      }
    })
  }

  openInfoModal(transfereeDetails) {
    const  transfereeName = transfereeDetails.hasOwnProperty('firstName') && transfereeDetails.hasOwnProperty('lastName') ? `${transfereeDetails.firstName} ${transfereeDetails.lastName}` : '';
    const  moveStatus = transfereeDetails.hasOwnProperty('moveStatus') ? `${transfereeDetails.moveStatus}` : '';
    this.dialog.open(InfoDialogComponent, {
      data: {
        displayMessage: `This Transferee ${transfereeName} has already been ${moveStatus === 'Withdrawn' ? 'Rejected': 'Approved'}`
      },
      width: '38vw',
      disableClose: true,
    });
  }

  editRestrictedLocation(candidate) {
    const dialogRef = this.dialog.open(AddressFormWrapperComponent, {
      width: '56vw',
      data: candidate
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.action === 'submitted') {
        const reqPayload = this.constructReqPayload(result.addressForm.value);
        this.updateLocation(result.orderReqId, reqPayload)
      }
    });
  }

  updateLocation(orderReqId, reqBody) {
    this.candidateService.updateLocation(orderReqId, reqBody).subscribe(res => {
      console.log(res);
      this.toastrService.info(res.message, null, {
        closeButton: true,
        enableHtml: true,
        disableTimeOut: false // User must explicitly dismiss error messages
      });
    })
  }

 convertForm({ cities, country, postalCodes, states, streets }) {
    return {
      city: cities?.[0],
      country: country,
      state: states?.[0],
      streetLine1: streets?.[0],
      ...(postalCodes[0] && {postalCode: postalCodes[0]})
    };
  }
  
 constructReqPayload(data) {
    return {
      departureAddr: this.convertForm(data.departureForm),
      destinationAddr: this.convertForm(data.destinationForm)
    };
  }
}
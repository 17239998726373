<div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
    fxLayoutGap="20px" fxLayoutGap.xs="0em">
    <div class="warnig-dialogtitle">Record Details Do Not Match Search Criteria</div>
    <span  class="close-icon" (click)="onClose()" title="close"><mat-icon>close</mat-icon></span> 
</div>
<div tabindex = "0" >
    <p class="warning-content">The following record details do not match the search criteria entered: </p>
    <!-- <ul class="list-items" *ngFor="let datas of data">
        <li>{{datas}}</li>
    </ul> -->

<!-- grid for warning-->
<table mat-table [dataSource]="dataSource">
    <!-- Details Column -->
    <ng-container matColumnDef="details">
      <th mat-header-cell *matHeaderCellDef> Details </th>
      <td mat-cell *matCellDef="let element"> {{element.details}} </td>
    </ng-container>
    <!-- Record Value Column -->
    <ng-container matColumnDef="recordValue">
      <th mat-header-cell *matHeaderCellDef> Record Value </th>
      <td mat-cell *matCellDef="let element"> {{element.recordValue}} </td>
    </ng-container>  
    <!-- Search Criteria Column -->
    <ng-container matColumnDef="searchCriteria">
      <th mat-header-cell *matHeaderCellDef> Search Criteria Value </th>
      <td mat-cell *matCellDef="let element"> {{element.searchCriteria}} </td>
    </ng-container>  
    <!-- overwrite Column -->
    <ng-container matColumnDef="overwrite">
     <th mat-header-cell *matHeaderCellDef> <mat-checkbox class="example-margin" (change)="checkUncheckAll()" [(ngModel)]="isChecked">overwrite All</mat-checkbox>  </th>
     <td mat-cell *matCellDef="let element"><span *ngIf="element.details != 'SSO#'">Yes<mat-slide-toggle (change)="isAllSelected()" [(ngModel)]="element.isSelected">No</mat-slide-toggle></span> <span *ngIf="element.details == 'SSO#'" disabled>Cannot be modified</span> </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<div fxLayout="row" fxLayoutAlign="end end" >
    <button class="cancel-btn" mat-button (click)="onClose()">Cancel</button>
    <button class="confirm-btn" mat-button (click)="onConfirm()">Continue</button>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { PowerBiReportConfig } from '../../models/powerbi-report-config.model';
import { PowerBiVisualConfig } from '../../models/powerbi-visual-config.model';

/** This component renders Power BI reports */
@Component({
  selector: 'app-powerbi-report-container',
  templateUrl: './powerbi-report-container.component.html',
  styleUrls: ['./powerbi-report-container.component.scss']
})
export class PowerBiReportContainerComponent implements OnInit {
  /** Report config */
  @Input() configs$: Observable<PowerBiReportConfig[] | PowerBiVisualConfig[]>;

  /** Component instantiation */
  constructor() { }

  /** Component Angular initialization lifecycle hook */
  ngOnInit() { }
}

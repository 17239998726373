import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { LocationsService } from '../../services/locations.service';
import { startWith, map, Observable } from 'rxjs';
import { Country } from '../../../../core/models/country.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'address-form-wrapper',
  templateUrl: './address-form-wrapper.component.html',
  styleUrls: ['./address-form-wrapper.component.scss']
})
export class AddressFormWrapperComponent implements OnInit {

  addressForm: FormGroup;
  countryList: Country[];
  filteredCountryOptions: Observable<Country[]>;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialogRef<AddressFormWrapperComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private readonly locationService: LocationsService
  ) {}

  ngOnInit(): void {
    this.createAddressForm();
    this.getCountryList();
  }

  createAddressForm() {
    this.addressForm = this.fb.group({
      departureForm: this.createAddressFormGroup(this.dialogData.departureAddr),
      destinationForm: this.createAddressFormGroup(this.dialogData.destinationAddr)
    })
  }

  get departureForm(): FormGroup {
    return this.addressForm.controls.departureForm as FormGroup;
  }

  get destinationForm(): FormGroup {
    return this.addressForm.controls.destinationForm as FormGroup;
  }

  private createAddressFormGroup(editData?: any): FormGroup {
    const check = this.isNullCheck;
    return this.fb.group({
      country: [editData ? check(editData, 'country'): '', [Validators.required]],
      cities: new FormArray([
        new FormControl(editData ? check(editData, 'city') : '', [Validators.required])
      ]),
      streets: new FormArray([
        new FormControl(editData ? check(editData, 'streetLine1') : '')
      ]),
      states: new FormArray([
        new FormControl(editData ? check(editData, 'state') : '', [Validators.minLength(3)])
      ]),
      postalCodes: new FormArray([
        new FormControl(editData ? check(editData, 'postalCode') : '', [Validators.minLength(1), Validators.maxLength(15)])
      ]),
    });
  }

  getCountryList() {
    this.locationService.countryList.subscribe(countryList => {
      if (countryList.length > 0) {
        countryList.sort((a, b) => a.countryName.localeCompare(b.countryName));
        this.countryList = countryList;
      }
    });
  }

  /**
 * Checks for Value or will return 'empty'
 * @param value any
 */
  isNullCheck(obj: Object, key: string) {
    try {
      // tslint:disable-next-line: triple-equals
      return ((obj[key] || obj[key] == false) && obj[key] !== null) ? obj[key] : '';
    } catch (error) {
      return '';
    }
  }

  onSubmit() {
    this.dialog.close({
      action: 'submitted',
      addressForm: this.addressForm,
      orderReqId: this.dialogData.orderRequestID
    })
  }

  cancel() {
    this.dialog.close({
      action: 'dismiss'
    })
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { LinkedListData } from '../../models/linked-list-data.model';

/** This component is a display only component that shows a vertically linked list of values and labels */
@Component({
  selector: 'app-linked-list',
  templateUrl: './linked-list.component.html',
  styleUrls: ['./linked-list.component.scss']
})
export class LinkedListComponent implements OnInit {

  /** the data to be displayed */
  @Input() dataSet: LinkedListData[];

  /** list style classes */
  @Input() listStyleClasses: string;

  /** column style classes */
  @Input() columnStyleClasses: string[];

  /** empty constructor */
  constructor() { }

  /** empty init */
  ngOnInit() {
  }
}

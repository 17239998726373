<ol class="breadcrumb">
  <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index">
    <mat-icon class="material-icons" *ngIf="i > 0">chevron_right</mat-icon>
    <li aria-label="Breadcrumb Item" class="breadcrumb-item">
      <ng-container *ngIf="breadcrumb.url !== ''; else internalBlock">
        <a [routerLink]="[breadcrumb.url]" [attr.aria-label]="breadcrumb.label" [innerHTML]="breadcrumb.label"></a>
      </ng-container>
      <ng-template #internalBlock>
        <span [attr.aria-label]="breadcrumb.label">{{ breadcrumb.label }}</span>
      </ng-template>
    </li>
  </ng-container>
</ol>

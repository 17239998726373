import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CandidateProfileService } from '../../services/candidate-profile.service';

@Component({
  selector: 'app-approve-reject-compliant-transferees',
  templateUrl: './approve-reject-compliant-transferees.component.html',
  styleUrls: ['./approve-reject-compliant-transferees.component.scss']
})
export class ApproveRejectCompliantTransfereesComponent implements OnInit {
  displayLabel = ''
  displayWarning = ''
  constructor(public dialog: MatDialogRef<ApproveRejectCompliantTransfereesComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private readonly toaster: MatSnackBar,
    public adjudicateDialog: MatDialog,
    public candidateService: CandidateProfileService,) {
  }

  ngOnInit(): void {
    if (this.dialogData && this.dialogData.action && this.dialogData.transfereeName) {
      this.displayLabel = this.dialogData.action
      this.displayWarning = this.dialogData.action === 'Reject' ? `Are you sure you want to reject this transferee, ${this.dialogData.transfereeName}?` : `Are you sure you want to approve this transferee, ${this.dialogData.transfereeName}?`
    }
  }

  adjudicateTransferee() {
    const orderRequestId = this.dialogData.orderRequestID
    const reqAction = this.displayLabel === 'Approve' ? 'Approved' : 'Rejected'
    const toasterMessage = this.displayLabel === 'Approve' ? `${this.dialogData.transfereeName}, transferee has been approved successfully` : `${this.dialogData.transfereeName}, transferee has been rejected successfully`
    const requestBody = {
      action: reqAction,
      comment: this.dialogData?.comment
    }
    this.candidateService.adjudicateCompliantTransferee(orderRequestId, requestBody).subscribe(res => {
      if (res) {
        this.displayToasterMessage(toasterMessage)
        this.adjudicateDialog.closeAll()
      }
    })
  }

  onDismissDialog(eve) {
    if (eve) {
      this.dialog.close({
        action: 'dismiss'
      })
    }
  }

  displayToasterMessage(message) {
    this.toaster.open(message, 'CLOSE', {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['toasterMessage']
    })
  }

}

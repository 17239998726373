<div class="adjudicate-styles">
    <div mat-dialog-title class="dialog-header" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px"
        fxLayoutGap.xs="0em">Restriction Adjudication
        <a class="close-icon" (click)="onDismissDialog($event)" title="close">
            <mat-icon>close</mat-icon>
        </a>
    </div>
    <mat-card>
        <mat-card-content class="card-content" *ngIf="transfereeDetails">
            <div><strong>Transferee Name:</strong> {{transfereeDetails.firstName}} {{transfereeDetails.lastName}}</div>
            <div><strong>Move Order Number:</strong> {{transfereeDetails.moveOrderNumber}}</div>
            <div><strong>Client Number:</strong> {{transfereeDetails.clientNumber}}</div>
            <div><strong>Client Name:</strong> {{transfereeDetails.client}}</div>
            <div><strong>Departure Location:</strong> {{transfereeDetails.depCity}} {{transfereeDetails.depState}}
                {{transfereeDetails.depCountry}}</div>
            <div><strong>Destination Location:</strong> {{transfereeDetails.desCity}} {{transfereeDetails.desState}}
                {{transfereeDetails.desCountry}}</div>
            <div><strong>Account Manager on the File:</strong> {{transfereeDetails.accountManagersOnFile.join(', ')}}</div>
            <div>
                <mat-form-field  appearance="outline" class="align-text-area" fxFlex="100%">
                    <textarea [formControl] = "reviewComment" matInput cdkTextareaAutosize cdkAutosizeMinRows="4" cdkAutosizeMaxRows="10"
                      placeholder="Please provide details/reasoning as applicable"></textarea>
                </mat-form-field>
            </div>
        </mat-card-content>
    </mat-card>
    <div class="action-buttons">
        <button class="cancel-adj" (click)="onDismissDialog($event)">Cancel</button>
        <button class="adjudicate" (click)="OnAdjudicateTrnasferee('Reject')">Reject</button>
        <button class="adjudicate" (click)="OnAdjudicateTrnasferee('Approve')">Approve</button>
    </div>
</div>
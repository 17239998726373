import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-save-auth-warning-dialog',
  templateUrl: './save-auth-warning-dialog.component.html',
  styleUrls: ['./save-auth-warning-dialog.component.scss']
})
export class SaveAuthWarningDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SaveAuthWarningDialogComponent>
  ) { }

  ngOnInit(): void {
  }

  onConfirm() {
    this.dialogRef.close({
      action: 'confirm'
    });
  }

  onCancel() {
    this.dialogRef.close({
      action: 'cancel'
    });
  }

}

import { Component, OnInit, Output } from '@angular/core';
import { EmployeeDetailsService } from '../../core/services/employee-details.service';
import { EmployeeInfo } from '../../core/models/employee-info.model';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

/** ProviderComponent to view the provider*/
@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.scss']
})
export class ProviderComponent implements OnInit {

  /** Base constructor method */
  constructor(private empDetailSvc: EmployeeDetailsService,
    private route: ActivatedRoute) { }

  /** ngOnInit to initialize methods and variables*/
  ngOnInit() {

    this.route.params.subscribe(params => {
      const fileNumber: number = +params.id;
    });

  }
}

import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Locations } from '../../../core/models/locations.model';
import { map, catchError } from 'rxjs/operators';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Address } from 'cluster';
import { BaseClientService } from '../../../core/services/base-client.service';
import { Country } from '../../../core/models/country.model';

export interface ExtraParams {
  country: string,
  cityLongName: string,
  cityShortName: string
}

@Injectable({
  providedIn: 'root'
})
export class LocationsService { 

  countryList: BehaviorSubject<Array<Country>> = new BehaviorSubject<Array<Country>>([]);
  
  constructor(private readonly baseClientService: BaseClientService,
    private readonly http:HttpClient) { 
    this.loadCountryList().subscribe(data => {
      if (data && Array.isArray(data)){
       this.countryList.next(data);
      }
    })
  }

  loadCountryList() {
    return this.baseClientService.getById<Array<Country>>(`v1/country-info`).pipe(
      map(r => r.body), catchError((err, source) => {
        const empty: Array<Country> = null;
        console.log('Failed to get Locations: ', err);
        return of(empty);
      })
    );
  }

  locationSearch(searchType:'cities'| 'states', searchText: string, extraParams: ExtraParams) : Observable<Locations> {

    const httpParams = new HttpParams({ fromObject: { type: searchType, searchText, country: extraParams.country, cityLongName: extraParams.cityLongName, cityShortName: extraParams.cityShortName } })

    return this.baseClientService.getById<Locations>(`v1/locations?${httpParams.toString()}`).pipe(
      map(r => r.body), catchError((err, source) => {
        const empty: Locations = null;
        console.log('Failed to get Locations: ', err);
        return of(empty);
      })
    );

  }

  getLocations(): Observable<Locations> {
  // console.log(this.http.get('http://localhost:4300/v1/country-info'));
    return this.baseClientService.getById<Locations>(`v1/locations`).pipe(
      map(r => r.body), catchError((err, source) => {
        const empty: Locations = null;
        console.log('Failed to get Locations: ', err);
        return of(empty);
      })
    );
  }
}



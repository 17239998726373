import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormGroupDirective, FormArray } from '@angular/forms';

@Component({
  selector: 'app-multiplicity-form',
  templateUrl: './multiplicity-form.component.html',
  styleUrls: ['./multiplicity-form.component.scss']
})
export class MultiplicityFormComponent implements OnInit {
  @Input() customFormGroup;
  @Input() formGroupValue;
  @Input() dataModel;
  multiplicityForm: FormGroup;
  selectedOption
  @Input() nestedFormIndex;

  constructor(private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    this.multiplicityForm = this.customFormGroup as FormGroup;
    this.getMultiplicityFormCtrlAttr();
  }

  getMultiplicityFormCtrlAttr() {
    let formcontrolAttr = []
    const attrList = this.dataModel[this.formGroupValue];
    if (attrList && attrList.attributes && attrList.attributes.length) {
      formcontrolAttr = attrList.attributes;
    }
    const multiplicityList = formcontrolAttr.find(comp => comp.isMultiplicity)?.children
    if(multiplicityList && multiplicityList.length){
    const multiFormAttributes =  multiplicityList[this.nestedFormIndex];
      if(multiFormAttributes && multiFormAttributes.attributes && multiFormAttributes.attributes.length){
        multiFormAttributes.attributes.map(formProperties => {
          if (formProperties && formProperties.enumeratedValues && formProperties.enumeratedValues.length) {
            formProperties['fieldType'] = 'Select';
            this.selectedOption = formProperties.enumeratedValues.find(item => item.isDefault)?.value
          } else if (formProperties && formProperties.dataType === 'string' && formProperties.attributeKind === 'Property') {
            formProperties['fieldType'] = 'Text';
          } else if(formProperties && formProperties.dataType === 'string' && formProperties.attributeKind === 'Constant') {
            formProperties['fieldType'] = 'Constant'; 
          }  else if (formProperties && formProperties.dataType === 'Number') {
            formProperties['fieldType'] = formProperties.dataType;
            this.updateNumberVal(formProperties.name)
          }  else{
            formProperties['fieldType'] = formProperties.dataType;
          }

          return formProperties;
        })
      } 
      return multiFormAttributes.attributes || []
    } 
  }

  updateNumberVal(formControlName) {
    if(this.multiplicityForm?.get(formControlName)?.value && this.multiplicityForm?.get(formControlName)?.value !== null) {
      const value = this.multiplicityForm?.get(formControlName)?.value.toString().replace(/\D/g, "");
      value !== '' ? this.multiplicityForm?.get(formControlName)?.patchValue(parseFloat(value)) : this.multiplicityForm?.get(formControlName)?.patchValue(null);
    }
  }

  restrictNonNumericChar(event){
    if (event.target.type === "number" && !event.key.match(/^[0-9]+$/)) {
      event.preventDefault();
    }
  }
}

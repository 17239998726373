<div *ngIf="employee$ | async as empHeaderData" class="emp-header">
  <mat-card class="mat-elevation-z4 ">
    <mat-card-content>
      <div fxLayout="row" fxFill>
        <div fxFlex="nogrow" class="avatar-block" fxLayoutAlign="center center">
            <app-avatar [firstName]="empHeaderData.firstName" [lastName]="empHeaderData.lastName"></app-avatar>
        </div>
        <div fxFlex>
          <h2 class="nospace">{{empHeaderData.firstName}} {{empHeaderData.lastName}}</h2>
          <svg version="1.1" [ngClass] = "favUser ? 'filled' : 'empty'" class = "star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" (click) = "updateFavorite()">
            <use [attr.xlink:href]="favUser ? 'assets/images/star-rating.icons.svg#star-filled' : 'assets/images/star-rating.icons.svg#star-empty'"></use>
          </svg> 
          <div><span class="small-text" aria-label="Emp. ID:">Emp. ID: </span> <span [attr.aria-label]="empHeaderData.clientEmployeeId">{{empHeaderData.clientEmployeeId}}</span></div>
          <div><span class="small-text" aria-label="Division:">Division: </span> <span [attr.aria-label]="empHeaderData.divisionName">{{empHeaderData.divisionName}}</span></div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxFill>
        <button tabindex="0" aria-label="View Phone Numbers" type="button" mat-button fxFlex="49" fxLayoutAlign="center" [ngClass]="phoneAnimationState === 'in' ? 'selected' : ''" (click)="togglePhoneDisplay()">
          <span><mat-icon>phone</mat-icon></span>
        </button>
        <button tabindex="0" aria-label="View Email Addresses" type="button" mat-button fxFlex="49" fxLayoutAlign="center" [ngClass]="emailAnimationState === 'in' ? 'selected' : ''" (click)="toggleEmailDisplay()">
          <span><mat-icon>email</mat-icon></span>
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
  <div fxFill fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign="space-between">
    <div fxFlex.gt-sm="49" [@slideInOut]="phoneAnimationState">
        <div tabindex="0" class="small-text" aria-label="Phone Numbers">Phone Numbers:</div>
        <div *ngFor="let item of empHeaderData.phone" fxLayout="row" fxLayoutAlign="space-between" class="contact-data">
          <div tabindex="0" fxFlex="60" [attr.aria-label]="item.phoneDialingCode !== null ? item.phoneDialingCode + item.phoneNumber : item.phoneNumber">{{ item.phoneDialingCode !== null ? item.phoneDialingCode + item.phoneNumber : item.phoneNumber}}</div>
          <div tabindex="0" fxFlex="30" [attr.aria-label]="item.phoneNumberDesc">{{item?.phoneNumberDesc}}</div>
        </div>
    </div>
    <div fxFlex.gt-sm="49" [@slideInOut]="emailAnimationState">
        <div tabindex="0" class="small-text" aria-label="Email Addresses">Email Addresses:</div>
        <div *ngFor="let item of empHeaderData.email" fxLayout="row" fxLayoutAlign="space-between" class="contact-data">
          <div tabindex="0" fxFlex="60" [attr.aria-label]="item.emailAddress">{{item.emailAddress}}</div>
          <div tabindex="0" fxFlex="30" [attr.aria-label]="item.emailAddressDesc">{{item?.emailAddressDesc}}</div>
        </div>
    </div>
  </div>
</div>

import { Component,Inject, OnInit } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-approve-move-popup',
  templateUrl: './approve-move-popup.component.html',
  styleUrls: ['./approve-move-popup.component.scss']
})
export class ApproveMovePopupComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ApproveMovePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  onClose():void{
    this.dialogRef.close({
      action:'dismiss'
    });
  }

  onCancel(): void{
    this.dialogRef.close({
      action:'dismiss'
    });
  }

  onEditClick() {
    // const dialogRef = this.dialog.open(AddEditTransfereeComponent,{
    //   data:this.data
    // });
    this.dialogRef.close({
      action:'dismiss',
      data:'Add'
    });
  }

  onAuthMove(): void {
    this.dialogRef.close({
      data: true,
      action: 'dismiss'
    });
  }

  onConfirm() {
    this.dialogRef.close({
      data: 'Confirm',
      action: 'dismiss'
    });
  }

}

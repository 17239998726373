<ng-container [formGroup]="addMemberInfo">
  <div mat-dialog-content fxLayout="column" fxLayoutGap="0px" fxLayoutGap.xs="10px" fxLayoutGap.sm="10px">
    <button (click)="closeDialog($event);" title="close" class="close-icon-wrapper" aria-label="close dialog">
    </button>
    <p mat-dialog-title role="heading" aria-level="1">Member Information</p>
    <div class="scroll-div">
      <p class="description">
        Please add the details for all family member who are relocating with you.
      </p>
      <p class="div-title" role="heading" aria-level="2">Basic Details</p>
      <!-- Relationship type-->
      <div fxLayout="row" fxFlex="100%" fxLayoutGap="23px" class="p-b-10">
        <mat-form-field fxFlex="16%" fxFlex.xs="38%" fxFlex.sm="38%" hideRequiredMarker>
          <mat-label>Relationship</mat-label>
          <mat-select formControlName="relationship" required>
            <mat-option *ngFor="let relValue of relationshipValues" [value]="relValue">{{relValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- Current age when family member when relationhip type is child -->
        <mat-form-field fxFlex="16%" fxFlex.xs="38%" fxFlex.sm="38%" hideRequiredMarker *ngIf= "addMemberInfo.get('relationship').value == 'Child'">
          <mat-label>Current Age</mat-label>
          <mat-select formControlName="currentAge" required >
            <mat-option *ngFor="let childAge of age" [value]="childAge">{{childAge}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- Title when iphone -->
        <ng-container formGroupName="nameDetails">
          <mat-form-field fxFlex.xs="38%" fxFlex.sm="38%" fxHide.xs="false" fxHide.sm="false" fxHide=true
            hideRequiredMarker>
            <mat-label>Title</mat-label>
            <mat-select formControlName="title">
              <mat-option *ngFor="let title of titleValues" [value]="title">{{title}}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </div>
      <div fxLayout="row" fxFlex="100%" fxLayoutGap="36px" fxLayoutGap.xs="0px" fxLayoutAlign.xs="space-between center"
        fxLayoutGap.sm="0px" fxLayoutAlign.sm="space-between center" formGroupName="nameDetails">

        <!-- Title -->
        <mat-form-field fxFlex="16%" fxHide.xs="true" fxHide.sm="true" hideRequiredMarker>
          <mat-label>Title</mat-label>
          <mat-select formControlName="title">
            <mat-option *ngFor="let title of titleValues" [value]="title">{{title}}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- First Name -->
        <mat-form-field class="first-name" fxFlex="22%" fxFlex.xs="47%" fxFlex.sm="47%" hideRequiredMarker>
          <mat-label>First Name</mat-label>
          <input formControlName="firstName" matInput multi-type-char aria-label="first name" required minLength="2"
            maxLength="50">
        </mat-form-field>

        <!-- Last Name -->
        <mat-form-field class="last-name" fxFlex="22%" fxFlex.xs="47%" fxFlex.sm="47%" hideRequiredMarker>
          <mat-label>Last Name</mat-label>
          <input formControlName="lastName" matInput multi-type-char aria-label="last name" required minLength="1"
            maxLength="50">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxFlex="100%" fxLayoutAlign.xs="space-between center" style="margin: 20px 0 30px 0;"
        fxLayoutGap.sm="0px" fxLayoutAlign.sm="space-between center" >

        <div fxFlex="30%">
          <mat-checkbox formControlName="legalDependent" class="option-radio-button">Legal Dependent</mat-checkbox>
        </div>
        
        <div fxFlex="30%">
          <mat-checkbox formControlName="occupyingProperty" class="option-radio-button">Occupying Property</mat-checkbox>
        </div>

        <div fxFlex="40%">
          <mat-checkbox formControlName="accompanying" class="option-radio-button">Relocating with Customer</mat-checkbox>
        </div>

      </div>



      <ng-container formGroupName="contact">
        <ng-container formArrayName="phoneDetails">
          <p class="div-title" role="heading" aria-level="2">Phone Details</p>
          <div
            *ngIf="!addMemberInfo.controls.contact.get('phoneDetails')?.controls || addMemberInfo.controls.contact.get('phoneDetails')?.controls.length ==0"
            class="p-b-20 border-radius" fxFlex="100%" fxLayout="row" fxLayoutAlign="start center" fxFlex.xs="100%"
            fxLayout.xs="column" fxLayoutAlign.xs="unset unset" fxLayoutGap="0px" fxLayoutGap.xs="10px" fxFlex.sm="100%"
            fxLayout.sm="column" fxLayoutAlign.sm="unset unset" fxLayoutGap.sm="10px">
            <button class="add-contact" fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="100%" mat-icon-button role="button"
              (click)="addNewRow('phone', 0, addMemberInfo.get('contact'))" title="Add Contact">
              <img src="../../../../../assets/images/add-contact.svg" alt="add-contact" class="m-r-10" />
              <span role="button">Add Contact</span>
            </button>
          </div>
          <ng-container
            *ngIf="(familyMemberDetails && familyMemberDetails.phoneDetailsList && familyMemberDetails.phoneDetailsList.length >0) || addMemberInfo.controls.contact.get('phoneDetails')?.controls.length >0">
            <ng-container
              *ngFor="let phoneDetail of addMemberInfo.controls.contact.get('phoneDetails')?.controls; last as islast; let index = index">
              <ng-container [formGroupName]='index'>
                <div fxFlex="100%" fxLayout="row" fxFlex.xs="100%" fxLayout.xs="column" fxLayoutGap="60px"
                  fxLayoutGap.xs="10px" fxFlex.sm="100%" fxLayout.sm="column" fxLayoutGap.sm="10px">
                  <div fxFlex="44%" fxLayoutAlign="space-between center" fxLayoutAlign.xs="unset center"
                    fxLayoutGap.xs="10px" fxLayoutGap="0px">

                    <!-- Phone Type -->
                    <mat-form-field fxFlex="69%" fxFlex.sm="65%" fxFlex.xs="65%" class="phone-type" hideRequiredMarker>
                      <mat-label>Phone Type</mat-label>
                      <mat-select formControlName='type' required (selectionChange)="selectPhoneType($event, index)">
                        <mat-option *ngFor="let phone of phoneTypes[index]; let phoneIndex = index" [value]="phone">
                          {{ phone | titlecase }}</mat-option>
                      </mat-select>
                    </mat-form-field>

                    <!-- Preferred Contact -->
                    <mat-radio-group aria-label="Is Primary Contact ?" fxFlex="24%" fxFlex.xs="30%" fxFlex.sm="15%"
                      (keydown.enter)="changePrimaryPhone($event, index)" (change)="changePrimaryPhone($event, index)"
                      *ngIf="addMemberInfo.controls.contact.get('phoneDetails')?.controls.length > 1">
                      <mat-radio-button role="button" value="true"
                        [checked]="addMemberInfo.controls.contact.get('phoneDetails')?.controls[index].controls['primary'].value == true">
                        Primary</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div fxFlex="49%" fxFlex.sm="40%" fxFlex.xs="40%" fxLayout.xs="row" fxFlex.xs="100%"
                    fxLayoutAlign="space-between center" fxLayout.sm="row" fxFlex.sm="100%">

                    <!-- Phone Dial Code -->
                    <!-- <mat-form-field class="dial-code" fxFlex="46%" hideRequiredMarker>
                      <mat-label>Phone Dial Code</mat-label>
                      <mat-select formControlName="phoneDialCode" required>
                        
                        <mat-option [value]="country.countryDialingCode" *ngFor="let country of countryList">
                          {{ country.countryName }} (+ {{ country.countryDialingCode }})
                        </mat-option>
                        <mat-select-trigger>
                          +
                          {{ addMemberInfo.controls.contact.controls.phoneDetails.controls[index].controls['phoneDialCode'].value }}
                        </mat-select-trigger>
                      </mat-select>
                    </mat-form-field> -->

                    <!--Phone Dial Code New Implementation -->
                    <mat-form-field fxFlex="34%" hideRequiredMarker>
                      <mat-label>Phone Dial Code</mat-label>
                      <input type="text" aria-label="Phone Code" matInput #autoCompleteInput formControlName="phoneDialCode"
                        [matAutocomplete]="auto">
                      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" [panelWidth]="293">
                        <mat-option [value]="country.countryDialingCode" *ngFor="let country of filteredOptions | async">
                          {{ country.countryName }} (+ {{ country.countryDialingCode }})
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>

                    <!-- Phone Number -->
                    <mat-form-field fxFlex="50%" hideRequiredMarker>
                      <mat-label>Phone Number</mat-label>
                      <input formControlName="phoneNumber" number-only matInput required maxlength="18">
                    </mat-form-field>
                    <button class="delete-btn" fxFlex="10%" fxFlex.sm="11%" fxFlex.xs="11%" mat-icon-button role="button"
                      (click)="deleteRow('phone', index, addMemberInfo.get('contact'),addMemberInfo.controls.contact.get('phoneDetails')?.controls[index].value._id,addMemberInfo.controls.contact.get('phoneDetails')?.controls[index].value.primary)">
                      <mat-icon aria-hidden="false" title="Delete Contact" aria-label="delete contact"
                        class="delete-icon">
                        delete
                      </mat-icon>
                    </button>
                  </div>

                </div>
                <div class="p-b-20 border-radius" fxFlex="100%" fxLayout="row" fxFlex.xs="100%" fxLayout.xs="column"
                  fxLayoutAlign.xs="unset unset" fxLayoutGap="0px" fxLayoutGap.xs="10px" fxFlex.sm="100%"
                  fxLayout.sm="column" fxLayoutAlign.sm="unset unset" fxLayoutGap.sm="10px">
                  <button class="add-contact" fxFlex="20%" fxLayout="row" fxFlex.xs="100%" fxFlex.sm="100%"
                    mat-icon-button role="button" *ngIf="islast && index < 4"
                    [disabled]="!(addMemberInfo.get('contact.phoneDetails').valid && addMemberInfo.get('contact.phoneDetails.' +index)?.controls.phoneNumber.value )"
                    (click)="addNewRow('phone', (index + 1), addMemberInfo.get('contact'))">
                    <img src="../../../../../assets/images/add-contact.svg" alt="add-contact" title="Add Contact"
                      class="m-r-10" />
                    <span role="button">Add Contact</span>
                  </button>
                </div>
              </ng-container>

            </ng-container>

          </ng-container>

        </ng-container>
        <ng-container formArrayName="emailDetails">
          <p class="email-title" role="heading" aria-level="2">Email Details</p>
          <div fxFlex="80%" fxLayout="row" class="add-email-wrapper">
            <button class="add-contact" fxFlex="20%" fxLayout="row" fxLayoutGap="10px" mat-icon-button role="button"
              aria-label="Add new email" *ngIf="addMemberInfo.get('contact.emailDetails')?.controls.length ==0"
              (click)="addNewRow('email', 0, addMemberInfo.get('contact'))" title="Add Email">

              <img src="../../../../../assets/images/add-contact.svg" alt="add-email" class="m-r-10" />
              <span role="button">Add Email</span>
            </button>
          </div>
          <ng-container
            *ngIf="(familyMemberDetails && familyMemberDetails.emailDetailsList && familyMemberDetails && familyMemberDetails.emailDetailsList.length >0) || addMemberInfo.controls.contact.get('emailDetails')?.controls.length >0">
            <ng-container
              *ngFor="let emailDetail of addMemberInfo.controls.contact.get('emailDetails')?.controls; last as islast; let index = index">
              <ng-container [formGroupName]='index'>
                <div class="p-t-50" fxFlex="100%" fxLayout="row" fxLayout.xs="column" fxLayoutGap.xs="10px"
                  fxLayout.sm="column" fxLayoutGap.sm="10px" fxLayoutGap="60px" fxFlex.xs="100%" fxFlex.sm="100%">
                  <div fxFlex="44%" fxLayoutAlign="space-between center" fxLayoutAlign.xs="unset center"
                    fxLayoutGap="0px" fxLayoutGap.xs="10px">

                    <!-- Email Type -->
                    <mat-form-field fxFlex="69%" fxFlex.xs="65%" fxFlex.sm="65%" class="phone-type" hideRequiredMarker>
                      <mat-label>Email Type</mat-label>
                      <mat-select formControlName="usageType" (selectionChange)="selectEmailType($event, index)"
                        required>
                        <mat-option *ngFor="let email of emailTypes[index]; let emailIndex = index" [value]="email">
                          {{ email | titlecase }}</mat-option>
                      </mat-select>
                    </mat-form-field>

                    <!-- Primary Email -->
                    <mat-radio-group aria-label="Is Primary Email ?" fxFlex="24%" fxFlex.xs="30%" fxFlex.sm="15%"
                      (keydown.enter)="changePrimaryEmail($event, index)" (change)="changePrimaryEmail($event, index)"
                      *ngIf="addMemberInfo.controls.contact.get('emailDetails')?.controls.length > 1">
                      <mat-radio-button role="button" value="true"
                        [checked]="addMemberInfo.controls.contact.get('emailDetails')?.controls[index].controls['primary'].value == true">
                        Primary</mat-radio-button>
                    </mat-radio-group>

                  </div>
                  <div fxFlex="49%" fxLayout="row" fxFlex.xs="100%" fxLayoutAlign="space-between center"
                    fxFlex.sm="100%">
                    <!-- Email Address -->
                    <mat-form-field fxFlex="86%" fxFlex.xs="78%" fxFlex.sm="87%" hideRequiredMarker>
                      <mat-label>Email Address</mat-label>
                      <input formControlName="emailAddress" matInput aria-label="email address" required>
                    </mat-form-field>

                    <button class="delete-btn" fxFlex="10%" fxFlex.xs="11%" fxFlex.sm="11%" mat-icon-button role="button"
                      (click)="deleteRow('email', index, addMemberInfo.get('contact'),addMemberInfo.controls.contact.get('emailDetails')?.controls[index].value._id,addMemberInfo.controls.contact.get('emailDetails')?.controls[index].value.primary)">
                      <mat-icon aria-hidden="false" title="Delete Email" aria-label="delete email" class="delete-icon">
                        delete
                      </mat-icon>
                    </button>
                  </div>
                </div>
                <div fxFlex="80%" fxLayout="row" class="add-email-wrapper">
                  <button class="add-contact" fxFlex="20%" fxLayout="row" fxLayoutGap="10px" mat-icon-button
                    role="button" aria-label="Add new email?"
                    [disabled]="!(addMemberInfo.get('contact.emailDetails').valid && addMemberInfo.get('contact.emailDetails.' +index)?.controls.emailAddress.value )"
                    *ngIf="islast && addMemberInfo.get('contact.emailDetails')?.controls.length !== 2 "
                    (click)="addNewRow('email', (index + 1), addMemberInfo.get('contact'))">

                    <img src="../../../../../assets/images/add-contact.svg" title="Add Email" alt="add-email"
                      class="m-r-10" />
                    <span role="button">Add Email</span>
                  </button>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>

        </ng-container>
      </ng-container>
    </div>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutAlign.xs="space-between center"
    fxLayoutAlign.sm="space-between center">
    <button mat-button class="cancel-btn" (click)="closeDialog($event);">Cancel</button>
    <button mat-button [ngClass]="(!addMemberInfo.valid) ? 'submit-disabled' : 'add-btn'"
      [disabled]='!addMemberInfo.valid' (click)="popuplateFamilyInfoValues();">{{addeditButtonTxt}}</button>
  </div>
</ng-container>

import { Injectable } from '@angular/core';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Country } from '../models/country.model';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  countryList: BehaviorSubject<Array<Country>> = new BehaviorSubject<Array<Country>>([]);
  constructor(
    protected baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService
  ) {}

  getLocations(type: string, searchText: string): Observable<any> {
    return this.baseClient
      .getArr<string>(`v1/locations?type=${type}&searchText=${searchText}`, 'get location name')
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const emptyArray: string[] = [];
          this.logSvc.logError(err);
          return of(emptyArray);
        })
      );
  }
}

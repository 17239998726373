<div cdkTrapFocus cdkTrapFocusAutoCapture>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <h2>Recent Sentiment</h2>
        <button mat-icon-button (click)="close()">
            <span class="material-icons">close</span>
        </button>
    </div>
    <mat-divider></mat-divider>
    <app-linked-list [dataSet]="keyDatesData" listStyleClasses="full-width" [columnStyleClasses]="['', '', 'align-right']"></app-linked-list>
    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutAlign="end center">
        <button mat-button class="outlined-button" (click)="close()">Close</button>
        <button mat-button class="contained-button" (click)="viewAll()">View All</button>
    </div>
</div>

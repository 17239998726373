import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CandidateProfileService } from '../../../../services/candidate-profile.service'

@Component({
  selector: 'app-complete-policy-call-modal',
  templateUrl: './complete-policy-call-modal.component.html',
  styleUrls: ['./complete-policy-call-modal.component.scss']
})
export class CompletePolicyCallModalComponent implements OnInit {

  currentDate: Date;
  policyCallCompleteDate: Date;
  policyCallCompleteTime: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CompletePolicyCallModalComponent>,
    public spinner: NgxSpinnerService,
    public readonly toastrService: ToastrService,
    public candidateProfileService: CandidateProfileService
  )
  {}

  ngOnInit() {
    this.policyCallCompleteDate = this.data.policyCallCompleteDate ? new Date(this.data.policyCallCompleteDate.replace(/-/g, '\/')) : new Date();
    this.policyCallCompleteTime = this.data.policyCallCompleteTime;
    this.currentDate = new Date();
  }

  onNoClick(event: Event): void {​​​​
    event.preventDefault();
    event.stopPropagation();
    this.dialogRef.close();
  }​​​​

  save() {
    let startDate = moment(this.policyCallCompleteDate).format('YYYY-MM-DD')+'T'+moment(this.policyCallCompleteTime, ["h:mm A"]).format("HH:mm:ss");
    let reqObj = Object.assign({}, {
      start: startDate,
      orderId: this.data.orderId,
      recipientsEmailList: this.data.candidateDetails.emailDetailsList,
      partyId: this.data.partyid,
      custFileId: this.data.custFileId,
      candidateDetails: this.data.candidateDetails,
      consultantinfo : this.data.consultantinfo
    });
    this.updateCompletePolicyCall(reqObj);
  }
  
  updateCompletePolicyCall(reqObj : any) {
    this.spinner.show();
    this.candidateProfileService.makePolicyCallComplete(reqObj.orderId, {"policyCallCompleteDate": reqObj.start}).subscribe((res) => {
      if (res && res.code === 'POLICY_CALL_STATUS_UPDATE_SUCCESS') {
        this.spinner.hide();
        this.dialogRef.close(reqObj);
        this.toastrService.info(res.message);
      } else {
        this.spinner.hide();
        this.toastrService.error('Error in updating the policy call completed date and time');
      }
      this.spinner.hide();
    });
  }


}

import { Component, OnInit, ViewEncapsulation, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ProviderService } from '../../../../core/services/provider.service';
import { Providers } from '../../../../core/models/providers.model';
import { ActivatedRoute } from '@angular/router';
import { NumbersService } from '../../../../core/services/numbers.service';
import { DateConversionService } from '../../../../core/services/date-conversion.service';
import { UserPreferencesService } from '../../../../core/services/user-preferences.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(providers: any, searchProvider: any): any {
    if(searchProvider === undefined) return providers;
    // search for supplier name and invoice number
    return providers.filter(function(thisperson){
         return (thisperson.supplierName.toLowerCase().includes(searchProvider.toLowerCase()) || 
         thisperson.invoices.filter(invoices => {invoices.apInvId.toLowerCase() === searchProvider.toLowerCase()}));
    }) 
  }
}

/** provider component to display the provider details for a particular customer */
@Component({
  selector: 'app-employee-providers',
  templateUrl: './employee-providers.component.html',
  styleUrls: ['./employee-providers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeeProvidersComponent implements OnInit, OnDestroy {
  @Input() orderRequestId: string;
  @Input() searchProvider;
  @Input() fileNumber: any;
  @Input() traditional: boolean;
  @Output() providerCount:EventEmitter<number> = new EventEmitter();

  /** Providers */
  public providers$: Observable<Providers>;
  /** providers variable to store the response data of type Subscription*/
  providers: Subscription;
  /** Provider Subscription */
  providerSub: Subscription;
  /** to store prefered currency */
  preferredCurr : string = "";
  providerCountSub: Subscription;

  /** Base constructor method
   * @param providerService ProviderService injection
   * @param route ActivatedRoute injection
   * @param numbersService NumbersService injection
   * @param dateConversionService DateConversionService injection
   */
  constructor(
    private readonly providerService: ProviderService,
    private route: ActivatedRoute,
    public readonly numbersService: NumbersService,
    private readonly userPreferenceService : UserPreferencesService,
    public readonly dateConversionService: DateConversionService,
    ) { }

  /** Init method to make Service call, to get the response from the api and to fetch params from url */
  ngOnInit() {
    if (!this.traditional){
    this.providers = this.route.params.subscribe(params => {
      const fileNumber: number = +params.id;
      this.userPreferenceService.getPreference('user_config', false)
      .subscribe((response) => {
        if (response) {
          response.forEach((item) => {
            if (item.preferenceconfig.name === "preferredCurrency") {
              if (
                'preferencesetting' in item &&
                item.preferencesetting.value
              ) {
              typeof item.preferencesetting.value === 'string' ? this.preferredCurr = item.preferencesetting.value : null;
              }
            }

          });
        }
      });
      this.providers$ = this.providerService.getProvidersByFileNum(fileNumber, this.preferredCurr);
    });
    } else {
      if(this.fileNumber) {
      this.providers = this.route.params.subscribe(params => {
        const fileNumber: number = +params.id;
      this.userPreferenceService.getPreference('user_config', false)
        .subscribe((response) => {
          if (response) {
            response.forEach((item) => {
              if (item.preferenceconfig.name === "preferredCurrency") {
                if (
                  'preferencesetting' in item &&
                  item.preferencesetting.value
                ) {
                  typeof item.preferencesetting.value === 'string' ? this.preferredCurr = item.preferencesetting.value : null;
                }
              }

            });
          }
        });
        this.providers$  = this.providerService.getProvidersByFileNumber(this.fileNumber, this.orderRequestId, this.preferredCurr);   
    });
    }
  }
  }

  /** To Destroy the subscribed method */
  ngOnDestroy(): void {
    if (this.providers) {
      this.providers.unsubscribe();
    }
  }
}

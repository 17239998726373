import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConfigService } from '../../../core/services/app-config.service';
import { UserContextService } from '../../../core/services/user-context.service';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LogoutModalComponent implements OnInit {

  constructor(private readonly router: Router,
    private readonly appConfig: AppConfigService,
    private readonly userContextService: UserContextService,
    public dialogRef: MatDialogRef<LogoutModalComponent>
    ) { }

  ngOnInit(): void {
  }

  onLogout(): void {
    sessionStorage.clear();
    this.dialogRef.close();
    if (this.userContextService.windowRef && this.userContextService.windowRef.length > 0) {
      this.userContextService.windowRef.forEach(ref => {
        ref.location.href = this.appConfig.getConfig('cartusUrl') ? this.appConfig.getConfig('cartusUrl') + '/resp/Public#/logout' : 'https://online.cartus.com/resp/Public#/logout';
      });
    }
    const logoutURL = this.appConfig.getConfig('login') + '/#/logout?appId=1';
    this.router.navigate(['/externalRedirect', { externalUrl: logoutURL }], {
      skipLocationChange: true,
    });
  }

}
<div class="filter-chips">
    <mat-chip-list aria-label="Filter Selection">
        <ng-container *ngFor="let filter of filterData">
            <mat-chip
                [ngClass]="{
                    movePhase: filter.category === 'Move Phase',
                    fileStatusDesc: filter.category === 'File Status',
                    policy: filter.category === 'Policy',
                    departure: filter.category === 'Departure',
                    destination: filter.category === 'Destination',
                    dateRange: filter.category === 'Status Date Range',
                    costRange: filter.category === 'Cost Range',
                    division: filter.category === 'Division',
                    authorizationType: filter.category === 'Authorization Type',
                    favorites: filter.category === 'Show Only Starred' || 'Show Only Lump Sum',
                    dateRange: filter.category === 'Authorization Date Range',
                    client:filter.category === 'Client' 
                }"
                matTooltip="{{filter.value}}" matTooltipPosition="above"
                matTooltipDisabled="{{(filter.value.length <= 25)}}">
                <span>
                    <p [attr.aria-label]='filter.category'>{{filter.category}}</p>
                    <p [attr.aria-label]='filter.value'>{{filter.value | conditionalTextReplace}}<ng-container *ngIf="filter.infinite"><img
                                src="../assets/images/endOfRange.svg" alt="endOfRange"/></ng-container>
                    </p>
                </span>
                <mat-icon tabindex="0" title="Remove" (click)="removeFilter(filter)">close</mat-icon>
            </mat-chip>
        </ng-container>
    </mat-chip-list>
</div>
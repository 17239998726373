<h2>
  {{ paginator?.length }} Unprocessed Transferees
</h2>
<div class="unprocessed-transferee" class="mat-elevation-z8">
    <mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true" >
    <!-- Transferee Column -->
    <ng-container matColumnDef="transferee">
      <!-- <div class="unprocessed-transferee"> -->
        <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Transferee"(click)="applySort('fullName')" > Transferee </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.nameDetails">
            <span [innerHTML]="element.nameDetails.lastName | searchHighlight: searchKeyword"></span>,
            <span [innerHTML]="element.nameDetails.firstName | searchHighlight: searchKeyword"></span>
          </div>
        </td>
    <!-- </div> -->
    </ng-container>

    <!-- Client Column -->
    <ng-container matColumnDef="client">
      <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Client " (click)="applySort('client')"> Client </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.client">
          <span [innerHTML]="element.client.clientEntityName | searchHighlight: searchKeyword"></span><br/>
          (Client #: <span [innerHTML]="element.client.clientNumber | searchHighlight: searchKeyword"></span> )
        </div>
      </td>
    </ng-container>

    <!-- Authorization Type Column -->
    <ng-container matColumnDef="authorizationType">
      <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Authorization Type " (click)="applySort('authorizationType')"> Authorization Type </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.authorizationType">
          <span [innerHTML]="(element.authorizationType === 'BenefitsBuilder'? 'Benefits Builder' : element.authorizationType) | searchHighlight: searchKeyword"></span>
        </div>
      </td>
    </ng-container>

    <!-- Departure Column -->
    <ng-container matColumnDef="departure">
      <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Departure" (click)="applySort('departure')"> Departure </th>
      <td mat-cell *matCellDef="let element">
        <div tabindex="0" *ngIf="element.departureAddr">
          <span [innerHTML]="element.departureAddr.country ? (element.departureAddr.country | searchHighlight: searchKeyword) : ''"></span><br />
          <span [innerHTML]="element.departureAddr.state? (element.departureAddr.state | searchHighlight: searchKeyword ):''"></span>,
          <span [innerHTML]="element.departureAddr.city? (element.departureAddr.city | searchHighlight: searchKeyword ): ''"></span>
        </div>
      </td>
    </ng-container>

    <!-- Destination Column -->
    <ng-container matColumnDef="destination">
      <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Destination" (click)="applySort('destination')"> Destination </th>
      <td mat-cell *matCellDef="let element">
        <div tabindex="0" *ngIf="element.destinationAddr">
          <span [innerHTML]="element.destinationAddr.country ? (element.destinationAddr.country  | searchHighlight: searchKeyword) : ''"></span><br />
          <span [innerHTML]="element.destinationAddr.state? (element.destinationAddr.state | searchHighlight: searchKeyword) : ''"></span>,
          <span [innerHTML]="element.destinationAddr.city? (element.destinationAddr.city | searchHighlight: searchKeyword) : ''"></span>
        </div>
      </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="date">
        <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Date" (click)="applySort('authorizationDate')"> Date </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.authorizationDate">
            <span [innerHTML]="element.authorizationDate | date: 'yyyy-MM-dd' | searchHighlight: searchKeyword"></span>
          </div>
        </td>
    </ng-container>

    <!-- Original Submission Column -->
    <ng-container matColumnDef="original submission">
        <th width="7%" mat-header-cell *matHeaderCellDef > Original Submission </th>
        <td mat-cell *matCellDef="let element">
          <div class="view-origin">
            <a class="view-origin" target="_blank"  (click)="goToLink(element)"><img class="margin-right">View Submission</a>
          </div>
        </td>
    </ng-container>
      
      <!-- Status Column -->
    <ng-container matColumnDef="status">
        <th width="10%" mat-header-cell *matHeaderCellDef style="padding-left: 24px;" mat-sort-header matTooltip="Change sorting for status" (click)="applySort('status')"> Status </th>
        <td mat-cell *matCellDef="let element">
        <div *ngIf="element.status">
          <!-- <a (click)="onStatusClick(element)"><span [innerHTML]="element.status"></span></a> -->
          <ng-container *ngIf = "manageAuthorizationFailures === 'write'">
            <a (click)="onStatusClick(element)">
            <span [innerHTML]="element.status"></span>
            </a>
          </ng-container>
          <ng-container *ngIf = "manageAuthorizationFailures === 'read'" > <span [innerHTML]="element.status"></span> </ng-container>
        </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </mat-table>
    <mat-paginator #MatPaginator="matPaginator" (page)="onPageChange($event)" [pageSizeOptions]="[10,25,50,75]"></mat-paginator>
  </div>


<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column">
    <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="column"
        fxLayoutAlign="space-between" fxLayoutGap.xs="0em">
        <div id="program-name" class="candidate-dialogtitle"> Program : {{programName}}<span class="closeBtn"><a href="#" class="close-icon" (click)="onDismiss($event)" title="close">
            <mat-icon>close</mat-icon>
        </a></span></div>
        
        <div class="transferee-dialogtitle"> Transferee : {{data.nameDetails?.firstName}} {{ data.nameDetails?.lastName}}<span class="infoIcon"
                matTooltip="only the minimum number approved services are displayed below"><img class="info-con"
                    alt="info" src="../../../../../../../assets/images/info-24px .svg" /></span></div>
    </div>
    <div class="search-block" role="search" fxLayout.xs="row" fxLayoutAlign.xs="center center">
        <mat-form-field class="table-search" appearance="outline">
            <span matPrefix class="lg-only" role="none">
                <mat-icon>search</mat-icon>
            </span>
            <input matInput tabindex="0" placeholder="Search for benefits by name" [(ngModel)]="searchValue"
                (keyup)="textSearch($event)" (keyup.enter)="textSearchBenefits($event.target.value)" role="searchbox">
            <button tabindex="0" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearTextSearch()"
                *ngIf="searchValue" aria-label="Clear Search">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>

    <mat-dialog-content class="middle-section" *ngIf = "benefitsList">

        <app-transferee-offerings-list [benefitsList]="benefitsList"></app-transferee-offerings-list>

    </mat-dialog-content>
    <mat-dialog-actions class="statusBtn add-dialogbtn" fxLayout="row" fxLayoutGap="10px"
        fxLayoutGap.xs="0px" fxLayoutAlign.xs="space-between center">
        <button fxFlex="40%" class="mat-button outlined-button" type="button" (click)="viewProgram()"
            id="addEditSave">View Program Details</button>
        <button fxFlex="20%" type="button" mat-raised-button class="mat-button text-button" (click)="onDismiss($event)"
            color="primary" id="cancel">Close</button>
    </mat-dialog-actions>
</div>
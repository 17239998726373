<div class="clients-contacts">
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a (click)="getClients()">Clients</a>
        </li>
        <li>
            {{ client.clientName}}
        </li>
    </ol>
    <mat-form-field class="table-search" appearance="outline">
        <button aria-label="search" mat-icon-button (click)="applySearch(searchValue)">
            <mat-icon>search</mat-icon>
        </button>
        <input matInput [(ngModel)]="searchValue" (keyup.enter)="applySearch($event.target.value)" placeholder="Search by Client Contact Name #" (blur)="toggleError()">
        <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchTextResults()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <mat-error id="min-text-error" role="main" aria-label="Please enter at least 3 characters" *ngIf="showError"> Please enter at least 3 characters</mat-error>
    <h2>Select Client Contact</h2>
    <div class="emp-details mat-elevation-z8">
        <div class="table-scroll">
            <table mat-table #table [dataSource]="dataSource" matSort (matSortChange)="clearInitialSort()">
                <ng-container matColumnDef="fullName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [@.disabled]="initialSortColumn === 'fullName'" [start]="initialSortDirection" [ngClass]="{'initialColumnSelected': initialSortColumn === 'fullName' }"> Full Name </th>
                    <td mat-cell *matCellDef="let element">
                        <a (click)="selectClientContact(element)">
                            <span [innerHTML]="element.lastName | highlightSearch: filterText"></span>,
                            <span [innerHTML]="element.firstName | highlightSearch: filterText" class="first-name"></span>
                        </a>
                    </td>
                </ng-container>
                <!-- Status Column -->                
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Status"
                    aria-label="status" (click)="applySort('status')">Status</th>
                    <td mat-cell *matCellDef="let element">
                         <a (click)="showEditDialog(element)">{{ element.status }}</a>
                        <div *ngIf="element.statusDate && element.status !=='Invitation Not Sent'" class="status-date" role="cell">on {{element.statusDate}}</div>
                    </td>
                </ng-container> 
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator #paginator [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
        </div>
    </div>
</div>
<!-- attributes nested form -->
<form autocomplete="off" class="middle-section" [formGroup]="form" *ngIf="form" (change)="onSelectionChange($event)">
    <mat-card class="form-container">
        <mat-card-title class="card-title" fxLayout="row" fxLayoutAlign="start center" *ngIf="componentTitle">
            {{componentTitle}}
        </mat-card-title>
        <mat-card-content>
            <div fxFlex="100%" fxLayout="row" fxLayoutAlign="start center" class="container">
                <div class="attributes-container" [fxFlex]="!formFields.hasOwnProperty('isMultiplicity') ? '48%' : '98%' " fxLayout="row"  *ngFor="let formFields of getFormControlAttributes()">
                    <div *ngIf="formFields && !formFields.hasOwnProperty('isMultiplicity')">
                        <div class="inner-container">
                            <div [ngSwitch]="formFields.fieldType">
                                <div  *ngSwitchCase="'Text'">
                                    <mat-form-field>
                                        <mat-label>{{formFields.displayName}}</mat-label>
                                        <input *ngIf="(formFields.hasOwnProperty('displaySetting') &&  formFields.displaySetting == dynamicBenefitService.displayWithCommas); else noDisplaySetting" matInput placeholder="{{formFields.displayName}}" type="text"
                                            [formControlName]="formFields.name"
                                            [value]="form.get(formFields.name).value" />
                                        <ng-template #noDisplaySetting>
                                            <input  matInput placeholder="{{formFields.displayName}}"
                                            [formControlName]="formFields.name" type="text" [value]="form.get(formFields.name).value" (change)="onSelectionChange($event)"/>
                                        </ng-template>
                                    </mat-form-field>
                                </div>
                                <div *ngSwitchCase="'Number'">
                                    <mat-form-field>
                                        <mat-label>{{formFields.displayName}}</mat-label>
                                        <input matInput placeholder="{{formFields.displayName}}" type="number"
                                            [formControlName]="formFields.name"
                                            [value]="form.get(formFields.name).value" (keypress)="restrictNonNumericChar($event)" (change)="onSelectionChange($event)" />
                                    </mat-form-field>
                                </div>
                                <div *ngSwitchCase="'Select'">
                                    <mat-form-field>
                                        <mat-label>{{formFields.displayName}}</mat-label>
                                        <mat-select [formControlName]="formFields.name" [(value)]="formFields.defaultValue" (selectionChange)="getSelection($event)">
                                            <mat-option
                                                *ngFor="let opt of formFields.enumeratedValues" [value]="opt.value">{{opt.value}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div *ngSwitchCase="'Constant'">
                                    <mat-form-field>
                                        <mat-label>{{formFields.displayName}}</mat-label>
                                        <input matInput placeholder="{{formFields.displayName}}" type="text"
                                            [value]="form.get(formFields.name).value" [formControlName]="formFields.name" />
                                    </mat-form-field>
                                </div>
                                <div *ngSwitchCase="'Boolean'">
                                    <mat-slide-toggle class="set-toggle-attr" (click)="$event.stopPropagation();"
                                        aria-label="{{formFields.displayName}}" [value]="form.get(formFields.name).value"
                                        [formControlName]="formFields.name" (change)="onToggle($event,formFields)">
                                    </mat-slide-toggle>
                                    <span class="toggle-stlye">{{formFields.displayName}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="formFields && formFields.hasOwnProperty('isMultiplicity')">
                        <form formArrayName="multiplicity">
                            <div *ngFor="
                                let formGroupValue of getFormArray('multiplicity');let formindex = index">
                                <div  class="multiplicity-form" [formGroupName]="formindex">
                                    <div *ngFor="let nestedFormGroup of getMultiplicityFormGroup(); let subformIndex = index;  let lastRow = last; let firstRow = first;">
                                        <app-multiplicity-form class="multiplicity-form-dependent" fxFlex="80%"  [customFormGroup]="getNestedFormGroup(subformIndex,'multiplicity')"
                                            [formGroupValue]="formGroupType" [dataModel]="dynamicDataModel" [nestedFormIndex]="subformIndex">
                                        </app-multiplicity-form>
                                        <div  fxFlex="15%">
                                            <button class="minus" [disabled]="getMultiplicityFormGroup().length == 1 || disableMuliplicityForm"
                                                (click)="removeMultiplicity(subformIndex)"></button>
                                            <button class="plus" *ngIf="lastRow" [disabled]="disableMuliplicityForm"
                                                (click)="addNewMultiplicity(nestedFormGroup, subformIndex)"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                </div>
        </mat-card-content>
    </mat-card>
</form>
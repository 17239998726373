import { Component, OnInit, ViewChild, Input, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SortDirection, MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProgramModalComponent } from '../program-modal/program-modal.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ProgramManagementService, Benefit } from '../../services/program-management.service';
import { DetailComponent, Program } from '../../modules/program/detail/detail.component';
import { AdminClient } from './../../../../core/models/admin-client.model';
import { ToastrService } from 'ngx-toastr';
import pdfMake from 'pdfmake/build/pdfmake';
import { ProgramReportGenerationService } from './../../services/program-report-generation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserTypeService, UserType } from '../../services/user-type.service';
import { DivisionAssociationComponent } from './../../components/division-association/division-association.component';
import { FilterProgramModalComponent } from './../../components/filter-program-modal/filter-program-modal.component';
import * as moment from 'moment';
import { AdminDataviewSelectionModalComponent } from '../admin-dataview-selection-modal/admin-dataview-selection-modal.component';
import * as programColumns from '../../../../core/data/programDataView.json';
import * as transfereeColumnDisplay from '../../../../core/data/transfereeColumnDisplay.json';
import { DeactivateProgramModalComponent } from './../../components/deactivate-program-modal/deactivate-program-modal.component';
import { SelectionModel } from '@angular/cdk/collections';
import { CopyProgramModalComponent } from '../copy-program-modal/copy-program-modal.component';
import { ClientService } from '../../services/client.service';
import { ClientContactsService } from '../../services/client-contacts.service';
import { CategoryService } from '../../services/category.service';
import { FeatureFlag, FeatureFlagService } from '../../../../core/services/feature-flag.service';

export interface GetProgramOptions {
  sortBy?: string;
  searchProperties?: any[];
  sortDirection?: string;
  searchText?: string;
  filterObj?:any;
  itemsPerPage?: number,
  pageNumber?:number
}

/**Quick access keyboard code for table next, last and escape*/
export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}
@Component({
  selector: 'app-program-list',
  templateUrl: './program-list.component.html',
  styleUrls: ['./program-list.component.scss']
})
export class ProgramListComponent implements OnInit {
  /** displayedColumns variable array to store the displayed columns */
  displayedColumns: string[] = [];
  displayedColumnsForCCI: string[] = [];
  columnsToShow: string[] = [];
  /** dataSource variable to store the response converted to mat table datasource */
  dataSource: MatTableDataSource<any>;
  sortOptions: any = {};

  // tslint:disable-next-line: no-inferrable-types
  programLength: number = 0;

  divisionList: Array<any> = [];

  /** page event variable to handle the page events */
  pageEvent: PageEvent;

  /**Direction varialbe to get sort direction */
  direction: SortDirection;

  @Input() clientId: string;

  /** To display client details in download report*/
  @Input() clientDetails: AdminClient;

  /** Is Detail View */
  detailView: boolean;

  selectedProgram: any;
  benefitsList: Array<{
    group: string,
    items: Array<Benefit>
  }>;

  searchValue: string;
  filterText: string;

  /** Contains the Filter Option for the program Tab */
  programFilterOptions: GetProgramOptions = {};

  divisionSelection: MatDialogRef<DivisionAssociationComponent>;

  //  dataviewSelection to access the dialog
  dataviewSelection: MatDialogRef<AdminDataviewSelectionModalComponent>;

  programListDataView: string[] = ['ProgramListViewColumnDisplay'];

  /** To sort the mat table columns */
  @ViewChild(MatSort) sort: MatSort;

  /** To paginate in a mat table */
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(DetailComponent) detailComponent: DetailComponent;


  /** sortDirection to hold the asc or desc direction value */
  sortDirection: 'ASC' | 'DESC' | null;

  /** sortColumnName to hold the clicked column name for sorting */
  sortColumnName: 'clientEntityName' | 'location' | 'status' | null;

  BenefitCategories;
  /** Stores data to be rendered in view Program page */
  selectedProgramDetail: Program = {} as Program;

  /** user role */
  userRole: UserType;

  // Flag to check if deactivated or delete
  isDeactivateOrDelete = false;

  /** filterprogramDialogRef to access the Dialog */
  filterDialogRef: MatDialogRef<FilterProgramModalComponent>;
  /** selection variable to store the collection of selections made in mat table */
  selection: SelectionModel<any>;

  /** warning messages */
  maximumSelectedWarningDeactivetMessage = 'Maximum 10 records are allowed to deactivate at a time.';
  invalidProgramForDeactivationMessage = 'Only those Program with status Active and Draft can be deactivated.';
  invalidProgramForCopyMessage = 'Only those Program with status Active and Draft can be copied.';
  invalidProgramForRemove = 'Only those Program with status Draft can be deleted.'
  maximumSelectedWarningDeleteMessage = 'Maximum 10 records are allowed to deleted at a time.';
  /**transferee list column details */
  programColumnsList: any = (programColumns as any).default;

  

  /**transferee column display details */
  columnsDisplay: any = (transfereeColumnDisplay as any).default;

  /** to add search properties of program list */
  colDisplay: any[];

  /** is ManageMove Client or not */
  isMMUser: boolean;

  isGOUser:boolean;

  /** FilterProgram flags */
  selectedStatus: boolean[] = [false, false, false];
  expirationStartDate: Date = null;
  expirationEndDate: Date = null;
  policyChecked: boolean = false;
  isCartusInsuredChecked: boolean = false;
  addPolicyToFilter: boolean = false;
  isCartusInsuredFilter: boolean = false;
  isPageChangeEvent = false;
  isDialogOpen = false;

  /** To get the current filter status */
  filterObj = {
    filterObj: {}
  };

  /* manage programadmin capabilities*/
  userCapabilities: number;

  isLumpSumEnabled: boolean = false; //LumpSum feature flag
  isTraditionalProgramTypeSelected: boolean = false;
  isBBProgramTypeSelected: boolean = false;

  constructor(
    public dialog: MatDialog,
    private readonly programService: ProgramManagementService,
    private toastrService: ToastrService,
    private readonly pdfgenerator: ProgramReportGenerationService,
    private spinner: NgxSpinnerService,
    private userTypeService: UserTypeService,
    private readonly toastr: MatSnackBar,
    private readonly clientService: ClientService,
    private clientContactsService: ClientContactsService,
    private readonly categoryService: CategoryService,
    public featureFlagService: FeatureFlagService
  ) {
    this.dataSource = new MatTableDataSource<any>([]);
    this.pageEvent = new PageEvent();
    this.pageEvent.pageIndex = 0;
    this.pageEvent.pageSize = 25;
    this.dataSource.data = [];
    this.dataSource.paginator = this.paginator;
    this.colDisplay = [];
  }

  ngOnInit() {
    this.getFeatureFlag(); // get Feature flag from API
    this.isMMUser = this.clientContactsService.isMMUser;
    this.isGOUser =this.clientContactsService.isGOUser;
    sessionStorage.setItem('isMMUser', this.isMMUser.toString());
    this.BenefitCategories = this.categoryService.categorySub.getValue();
    this.clientService.tabIndex$.subscribe(data => {
      if(data) {
        this.programService.getProgramDetail(this.clientId, data).subscribe(data => {
        this.selectedProgram = data.programs[0]; 
        this.viewProgram(this.selectedProgram); });
      }
    });
    this.userRole = this.userTypeService.userType; // need to remove
    this.userTypeService.capabilities$.subscribe(ele => {
      ele ? this.userCapabilities = ele.findIndex(element => element.name == "Benefits Builder Program Administration Portal" && element.operation == "write") : null;
    });
    //this.columnsToShow = (this.userRole === 'client-contact-initiator') ? this.displayedColumnsForCCI : this.displayedColumns ;
    this.userRole === 'program-admin' || this.userRole === 'product-mgmt-admin' || this.userRole === 'client-account-manager' ? this.programListDataView = ['ProgramListViewColumnDisplayforProgramAdmn'] : null;
    this.userRole === 'client-contact-initiator' ? this.programListDataView = ['ProgramListViewColumnDisplayForCCi'] : null;
    if (this.displayedColumns.length === 0) {
      const colConfig = this.columnsDisplay.filter((item) => item.preferencesetting.name == this.programListDataView[0]);
      this.displayedColumns = [...colConfig[0].preferencesetting.value];
      if(this.isMMUser) {
        this.displayedColumns = this.displayedColumns.filter((items) => !items.includes('totalPoints'))
      } else {
        this.displayedColumns = this.displayedColumns.filter((items) => !items.includes('totalAmount'))
      }
    }
    this.columnsToShow = this.displayedColumns;
    this.programColumnsList.forEach((item) => {
      if (this.displayedColumns.includes(item.field) && item.filter) {
        this.colDisplay = this.colDisplay.concat(item.filter);
      }
    });
    this.selection = new SelectionModel<any>(true, []);
    if ((this.userRole === 'program-admin' || this.userRole === 'product-mgmt-admin') && !this.displayedColumns.includes('select')) {
      this.displayedColumns.unshift('select');
    }
    if (this.displayedColumns.includes('status') && !(this.userRole === 'program-admin' || this.userRole === 'product-mgmt-admin')) {
      this.filterObj.filterObj['selectedStatus'] = ['active'];
      this.selectedStatus[0] = true;
      this.getProgramsList(this.clientId, null, this.filterObj);
    } else if(!this.detailView) {
      this.filterObj.filterObj['selectedStatus'] = ['active'];
      this.filterObj.filterObj['draft'] = true;
      this.getProgramsList(this.clientId,null,this.filterObj);
    }
    // this.selection.isSelected = this.isChecked.bind(this);
    this.selection.clear();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // tslint:disable-next-line: deprecation
    if (event.keyCode === KEY_CODE.RIGHT_ARROW && !this.isDialogOpen) {
      this.paginator.nextPage();
    }
    // tslint:disable-next-line: deprecation
    if (event.keyCode === KEY_CODE.LEFT_ARROW && !this.isDialogOpen) {
      this.paginator.previousPage();
    }
  }
  /**
   * To add new program
   * isDialogOpen flog is fix for when dialog open backgroud page loading 
   * while click on right arrow/left arrow buttons on keyboad
   */
  addNewProgram() {
    this.isDialogOpen = true;
    const dialogRef = this.dialog.open(ProgramModalComponent, {
      panelClass: 'dialog-full-width',
      data: {
        clientId: this.clientId,
        isLumpSumEnabled: this.isLumpSumEnabled
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      this.isDialogOpen = false;
      if (data) {
        this.detailView = true;
        this.selectedProgram = data;
      }
    });
  }
  /**
   * On closing a program
   */
  closeProgram() {
    this.detailView = false;
    this.selectedProgram = null;
    this.selectedProgramDetail = {} as Program;
    this.selection.clear();
    if (Object.keys(this.filterObj.filterObj).length) {
      this.getProgramsList(this.clientId, null, this.filterObj);
    } else {
      this.getProgramsList(this.clientId);
    }
  }

  openDownloadToastr(element) {
    // tslint:disable-next-line: max-line-length
    const message = 'Downloading files with sensitive information is a security risk, please clear your cache afterwards';
    const toaster = this.toastr.open(message, 'DOWNLOAD', {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['toasterMessage']
    });
    toaster.onAction().subscribe(_ => this.loadProgramBenefits(element));
  }

  /**
   * To load Program benefits
   * @param program Program Details
   */
  loadProgramBenefits(program) {
    this.spinner.show();
    let benefitsList: any;
    let manageMoveUser = this.isMMUser === true ? true : false;
    this.programService.getProgramBenefits(this.clientId, program.name, true, manageMoveUser).subscribe(data => {
      if (data) {
        if(!this.isMMUser) {
          const guaranteedBenefits = data.benefits.filter(benefit => benefit.points.toString() === 'Guaranteed');
          data.benefits = guaranteedBenefits.concat(data.benefits.filter(benefit => benefit.points.toString() !== 'Guaranteed'));
          benefitsList = this.groupBenefits(data.benefits).map(category => {
            return {
              group: category.group,
              items: category.items.filter(item => !item.suppress),
              points: category.items.filter(benefit => benefit.points.toString() === 'Guaranteed').
                concat(category.items.filter(benefit => benefit.points.toString() !== 'Guaranteed'))
            };
          }).filter(category => category.items.length !== 0);
        } else {
          //program.totalAmount = program.totalAmount + ' ' + program.currency;
          const guaranteedBenefits = data.benefits.filter(benefit => benefit.amount.toString() === 'Guaranteed');
          data.benefits = guaranteedBenefits.concat(data.benefits.filter(benefit => benefit.amount.toString() !== 'Guaranteed'));
          benefitsList = this.groupBenefits(data.benefits).map(category => {
            return {
              group: category.group,
              items: category.items.filter(item => !item.suppress),
              totalAmount: category.items.filter(benefit => benefit.amount.toString() === 'Guaranteed').
                concat(category.items.filter(benefit => benefit.amount.toString() !== 'Guaranteed'))
            };
          }).filter(category => category.items.length !== 0);
        }

        pdfMake.createPdf(this.pdfgenerator.generateDocumentDefinition(program, program.divisions,
          benefitsList, this.clientDetails)).download(program.name);
        this.spinner.hide();
      } else {
        this.toastrService.error('Error fetching Benefits', 'Error');
        this.spinner.hide();
      }
    }, () => {
      this.toastrService.error('Error fetching Benefits', 'Error');
      this.spinner.hide();
    }
    );
  }

  /**
   * Group benefits by category
   * @param benefits benefit details
   */
  groupBenefits(benefits: Array<Benefit>): Array<{
    group: string,
    items: Array<Benefit>
  }> {
    !this.BenefitCategories ? this.BenefitCategories = this.categoryService.categorySub.getValue(): null;
    const benefitsByGroup = this.BenefitCategories.map(category => {
      return {
        group: category,
        items: benefits.filter(benefit => benefit.category === category)
      };
    });
    return benefitsByGroup;
  }
  
  /**
   * Search for Programs
   * @param searchText Search Text Value
   */
  textSearchPrograms(searchText: string) {
    if (searchText && searchText.length >= 3 || searchText.length === 0) {
      this.searchValue = searchText;
      this.filterText = this.searchValue;
      const newOptions = Object.assign(this.programFilterOptions, {
        searchText
      });
      this.programFilterOptions = newOptions;
      this.programFilterOptions.searchProperties = this.colDisplay;
      this.programFilterOptions['itemsPerPage'] = (this.paginator && this.paginator.pageSize) ? this.paginator.pageSize : null;
      this.programFilterOptions['pageNumber'] = 0;
      if (Object.keys(this.filterObj.filterObj).length) {
        this.getProgramsList(this.clientId, this.programFilterOptions, this.filterObj);
      } else {
        this.getProgramsList(this.clientId, this.programFilterOptions);
      }
    }
  }

  textSearch(event) {
    if (event.key === 'Backspace' && event.target.value.length === 0) {
      this.textSearchPrograms(event.target.value);
    }
  }

  /**
   * Gets the List of Client Contacts;
   * @param clientId client id  selected by user
   * @param options options for itemsPerPage,pageNumber,sortBy,sortDirection
   * @param filterObj filterObj for filter options
   */
  getProgramsList(clientId?: string, options: GetProgramOptions = null, filterObj?: any,) {
    if (!options) {
      options = {
        itemsPerPage: 75,
        pageNumber: 0
      };
      if (this.sortOptions && this.sortOptions.hasOwnProperty('sortColumnName') && this.sortOptions.hasOwnProperty('sortDirection')) {
        options['sortBy'] = this.sortOptions.sortColumnName;
        options['sortDirection'] = this.sortOptions.sortDirection;
      }
    } else {
      options.hasOwnProperty('itemsPerPage') ? null : options['itemsPerPage'] = (this.paginator && this.paginator.pageSize) ? this.paginator.pageSize : null;
      options.hasOwnProperty('pageNumber') ? null : options['pageNumber'] = this.paginator.pageIndex;

      if (this.sortOptions && this.sortOptions.hasOwnProperty('sortColumnName') && this.sortOptions.hasOwnProperty('sortDirection')) {
        options['sortBy'] = this.sortOptions.sortColumnName;
        options['sortDirection'] = this.sortOptions.sortDirection;
      }
    }
    if (options && !options.searchText && this.searchValue) {
      options.searchText = this.searchValue;
      options['searchProperties'] = this.colDisplay;
    }
    this.programService.getProgramsList(this.clientId, options, filterObj ? filterObj : null).subscribe(data => {
      if (data && data.programs) {
        this.dataSource.data = data.programs;
        const dataSourceWithStatusFlag = this.dataSource.data.map(ele => ({ ...ele, status: this.getProgramStatus(ele) }));
        this.dataSource.data = [...dataSourceWithStatusFlag];
        this.isDeactivateOrDelete = false;
        this.programLength = data.programs.length;
        this.divisionList = data.divisionList;
        this.paginator ? this.paginator.pageSize = options.itemsPerPage : null;
        this.paginator ? this.paginator.pageIndex = options.pageNumber : null;
        let pageIndex = options.pageNumber;
        this.dataSource.paginator = this.paginator;
        this.paginator ? setTimeout(() => {
          this.paginator.pageIndex = pageIndex;
          this.paginator.length = data.count;
        }) : null;
        if (!this.isPageChangeEvent) {
          this.selection.clear();
        } else {
          this.isPageChangeEvent = false;
        }
      } else {
        this.toastrService.error('Error retreiving programs list', 'Error');
      }
    }, () => {
      this.toastrService.error('Error Fetching Programs', 'Error');
      this.spinner.hide();
    });
  }

  getProgramStatus(program: any) {
    if (program.hasOwnProperty('programExpirationDate')) {
      const trimmedDate = program.programExpirationDate.split('T')[0];
      program.programExpirationDate = trimmedDate;
      if (moment(moment().format('MM/DD/YYYY')).isBefore(moment(program.programExpirationDate.split('T')[0]).format('MM/DD/YYYY'))) {
        if (program.draft) {
          return 'Draft';
        }
        return 'Active';
      }
      if (moment(moment().format('MM/DD/YYYY')).isSameOrAfter(moment(program.programExpirationDate.split('T')[0]).format('MM/DD/YYYY'))) {
        return 'Deactivated';
      }
    }
    else {
      if (program.draft) {
        return 'Draft';
      }
      return 'Active';
    }
  }

  /**
   * Clear Text search
   */
  clearTextSearch() {
    this.searchValue = '';
    if (Object.keys(this.filterObj.filterObj).length) {
      this.getProgramsList(this.clientId, null, this.filterObj);
    } else {
      this.getProgramsList(this.clientId);
    }
    this.filterText = this.searchValue;
  }

  /**
   * Init Sort ..
   * @param columnName Column name to be sorted.
   */

  initSort(columnName: string): void {
    if (this.sortColumnName === columnName) {
      if (this.sortDirection === 'ASC') {
        this.sortDirection = 'DESC';
      } else {
        this.sortDirection = 'ASC';
      }
    } else {
      this.sortColumnName = columnName as any;
      this.sortDirection = 'ASC';
    }
    this.sortOptions['sortColumnName'] = this.sortColumnName;
    this.sortOptions['sortDirection'] = this.sortDirection;
    this.programFilterOptions = {
      sortBy: this.sortColumnName,
      searchProperties: this.colDisplay,
      sortDirection: this.sortDirection
    };
    if (Object.keys(this.filterObj.filterObj).length) {
      this.getProgramsList(this.clientId, {
        itemsPerPage: (this.paginator && this.paginator.pageSize) ? this.paginator.pageSize : null,
        pageNumber: (this.paginator && this.paginator.pageIndex) ? this.paginator.pageIndex : null,
      }, this.filterObj);
    } else {
      this.getProgramsList(this.clientId, {
        itemsPerPage: (this.paginator && this.paginator.pageSize) ? this.paginator.pageSize : null,
        pageNumber: (this.paginator && this.paginator.pageIndex) ? this.paginator.pageIndex : null
      });
    }
  }

  /**
   * To display program details
   * @param program selected program information
   */
  viewProgram(program) {
    this.selectedProgramDetail['programName'] = program.name;
    this.selectedProgramDetail['selectedClientId'] = this.clientId;
    this.selectedProgramDetail['atlasPolicy'] = program.clientPolicy;
    this.selectedProgramDetail['contractSeries'] = program.contractId;
    this.selectedProgramDetail['totalPoints'] = program.totalPoints;
    if (this.isMMUser) {
      this.selectedProgramDetail['totalAmount'] = parseInt(program.totalAmount);
      this.selectedProgramDetail['currency'] = program.currency;
    }
    program.baseProgramName ? this.selectedProgramDetail['templateName'] = program.baseProgramName : null;
    program.sourceProgramName ? this.selectedProgramDetail['sourceProgramName'] = program.sourceProgramName : null;
    this.selectedProgramDetail['isNew'] = false;
    this.selectedProgramDetail['divisions'] = program.divisions;
    this.selectedProgramDetail['id'] = program.id;
    this.selectedProgramDetail['programExpirationDate'] = program.programExpirationDate;
    program.programActivationDate ? this.selectedProgramDetail['programActivationDate'] = program.programActivationDate : this.selectedProgramDetail['programActivationDate'] = '';
    this.selectedProgramDetail['policyCallRequired'] = program.policyCallRequired;
    this.selectedProgramDetail['policyCallDuration'] = program.policyCallDuration;
    this.selectedProgramDetail['draft'] = program.status === "Draft" ? true : false;
    this.selectedProgramDetail['isCartusInsured'] = program.isCartusInsured,
    this.selectedProgramDetail['pointsValueAllocationType'] = program.pointsValueAllocationType,
    this.selectedProgramDetail['initialContactBy'] = program.initialContactBy,
    this.selectedProgramDetail['initialContactRequired'] = program.initialContactRequired,
    this.selectedProgramDetail['active'] = program.status === "Active" ? true : false;
    this.selectedProgramDetail['isDEIEnabled'] = program.isDEIEnabled;
    this.selectedProgramDetail['isSustainabilityEnabled'] = program.isSustainabilityEnabled;
    this.selectedProgramDetail['programTypeInfo'] = program.programTypeInfo;
    this.selectedProgramDetail['initialAuditRequired'] = program.initialAuditRequired; 
    this.selectedProgram = this.selectedProgramDetail;
    this.detailView = true;
    this.detailComponent ? this.detailComponent.ngOnInit() : null;
  }

  assignDivision(selectedProg?: any) {
    this.divisionSelection = this.dialog.open(DivisionAssociationComponent, {
      data: {
        clientId: this.clientId,
        program: selectedProg,
        from: 'program'
      },
      panelClass: 'DataviewSelectionModal',
      autoFocus: false,
      restoreFocus: false
    });
    this.divisionSelection.afterClosed().subscribe((publishDivision: any) => {
      if (publishDivision) {
        if (Object.keys(this.filterObj.filterObj).length) {
          this.getProgramsList(this.clientId, {
            itemsPerPage: (this.paginator && this.paginator.pageSize) ? this.paginator.pageSize : null,
            pageNumber: (this.paginator && this.paginator.pageIndex) ? this.paginator.pageIndex : null
          }, this.filterObj);
        } else {
          this.getProgramsList(this.clientId, {
            itemsPerPage: (this.paginator && this.paginator.pageSize) ? this.paginator.pageSize : null,
            pageNumber: (this.paginator && this.paginator.pageIndex) ? this.paginator.pageIndex : null
          });
        }
      }
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    let count = 0;
    this.dataSource.data.forEach(item => {
      let checkIfSelected = this.selection.selected.find(x => x.id === item.id);
      if (checkIfSelected) {
        count++;
      }
    });
    return (count === this.dataSource.data.length) ? true : false;
  }

  /** filterProgramModal method to open the Filter program modal popup */
  filterProgramModal(): void {
    this.filterDialogRef = this.dialog.open(FilterProgramModalComponent, {
      data: {
        dataKey: this.displayedColumns,
        selectedStatus: [...this.selectedStatus],
        expirationStartDate: this.expirationStartDate,
        expirationEndDate:this.expirationEndDate,
        policyChecked:this.policyChecked,
        isCartusInsuredChecked:this.isCartusInsuredChecked,
        addPolicyToFilterFlag: this.addPolicyToFilter,
        addIsCartusInsuredFilterFlag: this.isCartusInsuredFilter,
        isTraditionalProgramTypeSelected : this.isTraditionalProgramTypeSelected,
        isBBProgramTypeSelected : this.isBBProgramTypeSelected
      },
      panelClass: 'makeItMiddle',
      autoFocus: false,
      
      restoreFocus: false,
    });
    this.filterDialogRef.afterClosed().subscribe(data => {
      if (data) {
        let draftProgram = false;
        if (data.reset) {
          this.filterObj.filterObj = {};
          this.filterObj.filterObj['selectedStatus'] = ['active'];
          this.filterObj.filterObj['draft'] = true;
          this.getProgramsList(this.clientId, null,this.filterObj);
          this.selectedStatus = [false, false, false];
          this.expirationStartDate = null;
          this.expirationEndDate = null;
          this.policyChecked = false;
          this.isCartusInsuredChecked = false;
          this.addPolicyToFilter = false;
          this.isCartusInsuredFilter = false;
          this.filterObj.filterObj = {};
          this.isTraditionalProgramTypeSelected = false;
          this.isBBProgramTypeSelected = false ;
        } else {
          this.filterObj.filterObj = {};
          if (data.selectedStatus) {
            if (data.selectedStatus.includes('draft')) {
              draftProgram = true;
              data.selectedStatus = data.selectedStatus.filter(ele => (ele !== 'draft'));
              this.filterObj.filterObj['draft'] = true;
            }
            data.selectedStatus = [...data.selectedStatus.filter(ele => ele !== null)];
          }
          if (data.selectedStatus && data.selectedStatus.length > 0) {
            this.filterObj.filterObj['selectedStatus'] = [...data.selectedStatus.filter(ele => ele !== null)];
          } else {
            if(!draftProgram && (!data.date.expirationStartDate && !data.date.expirationEndDate)){
              this.filterObj.filterObj['selectedStatus'] = ['active'];
              this.filterObj.filterObj['draft'] = true;
            }  
          }
          if (data.date && (data.date.expirationStartDate || data.date.expirationEndDate)) {
            if (data.date.expirationStartDate) {
              data.date.expirationStartDate ? this.filterObj.filterObj['fromDate'] = new Date(data.date.expirationStartDate).toISOString().split('T')[0].concat('T00:00:00.000Z') : this.filterObj.filterObj['fromDate'] = '';
            }
            if (data.date.expirationEndDate) {
              data.date.expirationEndDate ? this.filterObj.filterObj['toDate'] = new Date(data.date.expirationEndDate).toISOString().split('T')[0].concat('T00:00:00.000Z') : this.filterObj.filterObj['toDate'] = '';
            }
          }
          if (data.addPolicyToFilterFlag) {
            this.filterObj.filterObj['policyCallRequired'] = data.policyChecked;
          }

          if ((data.addIsCartusInsuredFilterFlag)) {
            this.filterObj.filterObj['isCartusInsured'] = data.isCartusInsuredChecked;
          }

          if(data.programType.length > 0) {
            this.filterObj.filterObj['programType'] = [...data.programType];
          }

          this.isTraditionalProgramTypeSelected = data.programType.includes("Traditional") ? true : false ;
          this.isBBProgramTypeSelected = data.programType.includes("Benefits Builder") ? true : false ;

          this.paginator ? this.paginator.pageIndex = 0 : null;
          this.getProgramsList(this.clientId, Object.assign({}, { itemsPerPage: this.paginator ?  this.paginator.pageSize : null , pageNumber: 0 }), this.filterObj);
          this.selectedStatus = data.selectedStatusFlags ?  [...data.selectedStatusFlags] : [];
          this.expirationStartDate = data.expirationStartDateFlag;
          this.expirationEndDate = data.expirationEndDateFlag;
          this.policyChecked = data.policyCheckedFlag;
          this.isCartusInsuredChecked = data.isCartusInsuredCheckedFlag;
          this.addPolicyToFilter = data.addPolicyToFilterFlag;
          this.isCartusInsuredFilter = data.addIsCartusInsuredFilterFlag;
        }
      }
    });
  }

  /**
    * To display informative messages on screen
    * @param message message to be displayed
    */
  displayToastr(message) {
    this.toastrService.info(message, null, {
      closeButton: true,
      enableHtml: true,
      disableTimeOut: false,
    });
  }


  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.addCurrentSelections();
  }

  removeCurrentSelections() {
    this.dataSource.data.forEach((row) => {
      this.selection.selected.forEach((item, index) => {
        if (item.id === row.id) {
          item.checked = false;
          this.selection.selected.splice(index, 1);
        }
      });
    });
  }

  addCurrentSelections() {
    this.dataSource.data.forEach((row) => {
     row.checked = true;
     this.selection.select(row);
    });
  }

  

  /**
   * To copy selected program from program list
   * @param selectedProgram Selected program from program list
   */
  copyProgram(selectedProgram) {
    if (selectedProgram[0].status === 'Deactivated') {
      this.displayToastr(this.invalidProgramForCopyMessage);
    } else {
      const dialogRef = this.dialog.open(CopyProgramModalComponent, {
        panelClass: 'dialog-full-width',
        data: {
          programName: selectedProgram[0].name,
          clientName: this.clientDetails.clientEntityName,
          clientNumber: this.clientDetails.clientNumber,
          clientPolicy: selectedProgram[0].clientPolicy
        }
      });

      dialogRef.afterClosed().subscribe(copyDetails => {
        if (copyDetails) {
          let onlyCopy = true;
          const copiedProgram = { copyProgramDetails: copyDetails };
          if (selectedProgram[0].status === 'Draft') {
            copiedProgram['draft'] = true;
            copiedProgram['benefits'] = [];
            onlyCopy = false;
          }
          this.programService.updateBenefitForProgram(copiedProgram, selectedProgram[0].id, true, onlyCopy).subscribe((data) => {
            if (data) {
              const toastmsg = 'Program Copied';
              this.toastrService.info(toastmsg, null, {
                progressBar: true,
                timeOut: 2000
              });
              this.spinner.hide();
              setTimeout(() => {
                this.closeProgram();
              }, 2000);
            } else {
              this.spinner.hide();
              const toastmsg = 'Error copying program, please try again';
              this.toastrService.error(toastmsg);
            }
          },
            () => {
              this.spinner.hide();
              const toastmsg = 'Error copying program, please try again';
              this.toastrService.error(toastmsg);
            });
        }
      });
    }
  }

  /**
  * To deactivate program
  */
  deactivateProgram() {
    let dialogRef;
    if (this.selection.selected.length > 10 || this.selection.selected.map(ele => ele.status).includes('Deactivated')) {
      if (this.selection.selected.map(ele => ele.status).includes('Deactivated')) {
        this.displayToastr(this.invalidProgramForDeactivationMessage);
      } else {
        this.displayToastr(this.maximumSelectedWarningDeactivetMessage);
      }
    } else {
      dialogRef = this.dialog.open(DeactivateProgramModalComponent, {
        data: {
          programList: this.selection.selected.map(ele => ele.name),
          programIdList: this.selection.selected.map(ele => ele.id),
          label : 'Deactivate'
        },
        panelClass: 'DataviewSelectionModal',
        autoFocus: false,
        restoreFocus: false
      });
      dialogRef.afterClosed().subscribe((data: any) => {
        if (data.isSuccessful) {
          if (this.dataSource.data) {
            this.isDeactivateOrDelete = true;
            this.selection.clear();
            this.onPageChange();
          }
          this.displayToastr("Deactivated successfully");
        }
      });
    }
  }

  /** dataview selection method to open modal popup */
  dataViewSelectionModal() {
    this.dataviewSelection = this.dialog.open(AdminDataviewSelectionModalComponent, {
      data: {
        dataKey: this.programListDataView
      },
      panelClass: 'DataviewSelectionModal',
      autoFocus: false,
      restoreFocus: false
    });
    this.dataviewSelection.afterClosed().subscribe(displayedColumn => {
      if (displayedColumn && displayedColumn.length > 0) {
        if ((this.userRole === 'program-admin' || this.userRole === 'product-mgmt-admin') && !displayedColumn.includes('select')) {
          displayedColumn.unshift('select');
        }
        this.colDisplay = [];
        this.displayedColumns = [...displayedColumn];
        this.columnsToShow = this.displayedColumns;
        this.programColumnsList.forEach((item) => {
          if (this.displayedColumns.includes(item.field) && item.filter) {
            this.colDisplay = this.colDisplay.concat(item.filter);
          }
        });
      }
    });
  }

  onPageChange(e?) {
    this.isPageChangeEvent = true;
    let options = {
      itemsPerPage: (this.paginator && this.paginator.pageSize) ? this.paginator.pageSize : null,
      pageNumber: (this.paginator && this.paginator.pageIndex) ? this.paginator.pageIndex : null
    }
    if (Object.keys(this.filterObj.filterObj).length) {
      this.getProgramsList(this.clientId, options, this.filterObj);
    } else {
      this.getProgramsList(this.clientId, options);
    }
  }

  removeProgram(event): void {
    let dialogRef;
    // tslint:disable-next-line: max-line-length
    const isDeactivatedInclued = this.selection.selected.map(ele => ele.status).includes('Deactivated');
    const isActiveInclued = this.selection.selected.map(ele => ele.status).includes('Active');
    if (this.selection.selected.length > 10 || isDeactivatedInclued || isActiveInclued) {
      if (isActiveInclued || isDeactivatedInclued) {
        this.displayToastr(this.invalidProgramForRemove);
      } else {
        this.displayToastr(this.maximumSelectedWarningDeleteMessage);
      }
    } else {
      dialogRef = this.dialog.open(DeactivateProgramModalComponent, {
        data: {
          programList: this.selection.selected.map(ele => ele.name),
          programIdList: this.selection.selected.map(ele => ele.id),
          label : 'Delete'
        },
        panelClass: 'DataviewSelectionModal',
        autoFocus: false,
        restoreFocus: false
      });
      dialogRef.afterClosed().subscribe((data: any) => {
        if (data.isSuccessful) {
          if (this.dataSource.data) {
            this.selection.clear();
            this.isDeactivateOrDelete = true;
            this.onPageChange();
          }
          this.displayToastr("Deleted successfully");
        }
      });
    }
  }

  isChecked(row: any): boolean {
    const found = this.selection.selected.find(el => el.id === row.id);
    if (found) {
      return true;
    }
    return false;
  }

  checked(row: any) {
    this.selection.select(row)
    var found = this.selection.selected.find(x => x.id === row.id);
    if (found) {
      found.checked = true;
    }
  }

  unChecked(row: any) {
    var found = this.selection.selected.find(x => x.id === row.id);
    if (found) {
      found.checked = false;
    }
    this.selection.deselect(found);
  }

  getFeatureFlag() {
    this.featureFlagService.getFeatureFlagsBBUrl().subscribe(
      (res) => {
        const featureFlag: FeatureFlag[] = res.items;
        this.isLumpSumEnabled =  featureFlag && featureFlag.find( ele => ele.key === 'lump-sum-transferee' ).on;
      }
    )
  }


}

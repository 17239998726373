<div *ngIf="userContext" class="account-settings" aria-live="assertive">
  <h2>Account Settings</h2>
  <app-breadcrumb></app-breadcrumb>
  <mat-card class="mat-elevation-z4 mat-card-main">
    <mat-card-content>
      <div fxLayout="row" fxFill>
        <div fxFlex="nogrow" class="avatar-block" fxLayoutAlign="center center">
          <app-avatar [firstName]="userContext.firstName" [lastName]="userContext.lastName"></app-avatar>
        </div>
        <div fxFlex>
          <h2 class="nospace">{{userContext.firstName}} {{userContext.lastName}}</h2>
          <div><span class="small-text" aria-label="Company:">Company: </span> <span
              [attr.aria-label]="userContext.clientLegalName">{{userContext.clientLegalName}}</span></div>
          <!-- <div><span class="small-text" aria-label="Division:">Division: </span> <span [attr.aria-label]="empHeaderData.divisionName">{{empHeaderData.divisionName}}</span></div> -->
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxFill>
        <button tabindex="0" aria-label="View Phone Numbers" type="button" mat-button fxFlex="49" fxLayoutAlign="center"
          [ngClass]="phoneAnimationState === 'in' ? 'selected' : ''" (click)="togglePhoneDisplay()">
          <span>
            <mat-icon>phone</mat-icon>
          </span>
        </button>
        <button tabindex="0" aria-label="View Email Addresses" type="button" mat-button fxFlex="49"
          fxLayoutAlign="center" [ngClass]="emailAnimationState === 'in' ? 'selected' : ''"
          (click)="toggleEmailDisplay()">
          <span>
            <mat-icon>email</mat-icon>
          </span>
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
  <div fxFill fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign="space-between">
    <div fxFlex.gt-sm="49" [@slideInOut]="phoneAnimationState">
      <div tabindex="0" class="small-text" aria-label="Phone Numbers">Phone Numbers:</div>
      <div *ngFor="let item of userContacts?.phone" fxLayout="row" fxLayoutAlign="space-between" class="contact-data">
        <div tabindex="0" fxFlex="60"
          [attr.aria-label]="item?.phoneDialingCode !== null ? item.phoneDialingCode + item.phoneNumber : item.phoneNumber">
          {{ item.phoneDialingCode !== null ? item.phoneDialingCode + item.phoneNumber : item.phoneNumber}}</div>
        <div tabindex="0" fxFlex="30" [attr.aria-label]="item?.phoneNumberDesc">{{item?.phoneNumberDesc}}</div>
      </div>
    </div>
    <div fxFlex.gt-sm="49" [@slideInOut]="emailAnimationState">
      <div tabindex="0" class="small-text" aria-label="Email Addresses">Email Addresses:</div>
      <div *ngFor="let item of userContacts?.email" fxLayout="row" fxLayoutAlign="space-between" class="contact-data">
        <div tabindex="0" fxFlex="60" [attr.aria-label]="item?.emailAddress">{{item?.emailAddress}}</div>
        <div tabindex="0" fxFlex="30" [attr.aria-label]="item?.emailAddressDesc">{{item?.emailAddressDesc}}</div>
      </div>
    </div>
  </div>
  <div class="changePassword" aria-live="polite" *ngIf="isViewer === false">
    <div fxLayout="row" fxFill>
      <div class="changePasswordLabel" (click)="linkToChangePassword()" fxFlex.gt-sm="100" fxFlex.lt-md="65"
        tabindex="0">
        <div fxFlex.gt-sm="15" fxFlex.lt-md="35">
          <span class="change-pswd">Change Password</span>
        </div>
      </div>
    </div>
  </div>
  <mat-card class="mat-elevation-z4">
    <div aria-live="polite">
      <div fxLayout="row" fxFill>
        <span fxFlex.gt-md = "20%" fxFlex.sm = "100" class = "headline">Display Currency </span>  
        <span *ngIf = "!edit" fxFlex = "90%" fxLayoutAlign = "end">{{currency?.value.currencyName ? currency?.value.currencyName : 'None '}}
          <mat-icon style= "cursor:pointer;" (click) = "edit = !edit">edit</mat-icon>
        </span>
      </div>
    <mat-card-actions *ngIf = "edit">
      <div class="form-field" fxLayout.lt-md="column" fxLayout.md="column" fxLayout.gt-md="row" fxLayoutGap.gt-md="20px" fxLayoutAlign.gt-md="space-between center" fxFill>
        <mat-form-field fxFlex.sm="100" fxFlex.gt-md="20" class="form-field-select">
        <mat-select [formControl]="currency" [compareWith]="compareCurrency">
          <mat-option class = "font-size" value="">Default - Billed Currency</mat-option>
          <mat-option *ngFor="let curr of currencyList; let i = index" class = "font-size"[(value)]="currencyList[i]">{{curr.currencyAbbr}} | {{curr.currencyName}}</mat-option>
        </mat-select>
        </mat-form-field>
        <mat-card class="mat-card-chip notif-chip" fxLayout = "row" fxLayoutGap="10px" fxFlex.sm="100" fxFlex.gt-md="80" *ngIf="!currency?.value.currencyName"> 
          <mat-icon>info</mat-icon><span> Please select a currency to use as a common display detail for all monetary data. Please note with this selection, any aggregation
          displays will use base reporting currency. Note: this will effect all displays of monetary value for you site-wide.</span></mat-card>
          <mat-card class="mat-card-chip notif-chip" fxLayout = "row" fxLayoutGap="10px" fxFlex.sm="100" fxFlex.gt-md="80" *ngIf="currency?.value.currencyName"> 
            <mat-icon>info</mat-icon><span> Please note that currencies that are converted will have exchange rate applied based on the date it was incurred. Note: this will effect all displays of monetary value for you site-wide. </span></mat-card>
        </div>
    </mat-card-actions>
  </div>
  
  </mat-card>
  <mat-card class="mat-elevation-z4" *ngIf = "edit">
    <mat-card-actions>
      <button class="mat-button contained-button btn" type="button" (click)="save()" id="save" >Save </button>
      <button class="mat-button outlined-button btn" type="button" (click)="cancel()" id="cancel" >Cancel </button>
    </mat-card-actions>
  </mat-card>
  <mat-card class="mat-elevation-z4" *ngIf="cronofyId">
    <div aria-live="polite">
      <div fxLayout="row" fxFill>
        <span fxFlex.gt-md = "20%" fxFlex.sm = "100" class = "headline">Working Hours</span>  
        <span *ngIf = "!edits" fxFlex = "90%" fxLayoutAlign = "end">
          <mat-icon style="color: blue;">info</mat-icon>&nbsp; &nbsp;
          <a style= "cursor:pointer;" (click) = "cancelWorkHours()">View Details</a>&nbsp; &nbsp;
          <mat-icon style= "cursor:pointer;" (click) = "cancelWorkHours()">edit</mat-icon>
        </span>
      </div>
      <mat-card-actions *ngIf="edits">
        <div class="form-field" fxLayout.lt-md="column" fxLayout.md="column" fxLayout.gt-md="row" fxLayoutGap.gt-md="20px" fxLayoutAlign.gt-md="space-between center" fxFill>
          <!-- <mat-form-field fxFlex.sm="100" fxFlex.gt-md="20" class="form-field-select">
          <mat-select [formControl]="currency" [compareWith]="compareCurrency">
            <mat-option class = "font-size" value="">Default - Billed Currency</mat-option>
            <mat-option *ngFor="let curr of currencyList" class = "font-size"[(value)]="curr">{{curr.currencyAbbr}} | {{curr.currencyName}}</mat-option>
          </mat-select>
          </mat-form-field> -->
          <app-cronofy-availablility-picker  ></app-cronofy-availablility-picker>
          <mat-card class="mat-card-chip notif-chip-hours"  fxLayout = "row" fxLayoutGap="10px" fxFlex.sm="100" fxFlex.gt-md="80" > 
            <mat-icon>info</mat-icon><span> Please use this interface to mark your available working hours for your regular work week.</span></mat-card>
          </div>
      </mat-card-actions>
      <mat-card class="mat-elevation-z4"  *ngIf = "edits">
        <mat-card-actions>
          <button class="mat-button outlined-button" type="button" (click)="edits = !edits" id="cancel" >Cancel </button>
        </mat-card-actions>
      </mat-card>
  </div>
  </mat-card>

 
</div>

import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, Inject, Output, EventEmitter, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { FilterSelectionOption } from './../../../../core/models/common/filter-selection-option.model';
import { MovePhaseService } from '../../../../core/services/move-phase.service';
import { PolicyService } from '../../../../core/services/policy.service';
import { KeyValuePair } from './../../../../core/models/key-value-pair.model';
import { Location } from './../../../../core/models/common/location.model';
import { LocationService } from './../../../../core/services/location.service';
import { AggregationFilter } from './../../../../core/models/aggregation-filter.model';
import { FilterDetailsService } from './../../../../core/services/filter-details.service';
import { FilterDetails } from './../../../../core/models/filter-details.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateConversionService } from '../../../../core/services/date-conversion.service';
import { UserPreferencesService } from '../../../../core/services/user-preferences.service';
import { FileStatusService } from './../../../../core/services/file-status.service';
import { DivisionService } from '../../../../core/services/division.service';
import { Division } from './../../../../core/models/division.model';

/** Filter modal to filter the employee details summary */
@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class FilterModalComponent implements OnInit, OnDestroy {

  /** policies variable to hold the policy response from the service as keyValuePair[]*/
  policies: KeyValuePair[] = [];
  divisions: KeyValuePair[] = [];
  /** filteredPolicies variable to hold the selected policies as keyValuePair[]*/
  filteredPolicies: KeyValuePair[];
  filteredDivision: KeyValuePair[];

  /** movePhases variable to hold the policy response from the service as keyValuePair[]*/
  movePhases: KeyValuePair[];

  /** fileStatuses variable to hold the file status response from the service as keyValuePair[]*/
  fileStatuses: KeyValuePair[];

  /** policySub variable of type Subscription */
  policySub: Subscription;

  /** divisionSub variable of type Subscription */
  divisionSub: Subscription;

  /** movePhaseSub variable of type Subscription */
  movePhaseSub: Subscription;

  /** fileStatusSub variable of type Subscription */
  fileStatusSub: Subscription;

  selectedDestinations: any;
  selectedDepartures: any;
  selectedMovePhases: any;
  selectedPolicies: any;
  selectedDivisions: string[] = [];
  selectedFileStatuses: any;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  totalMinCost: any;
  totalMaxCost: any;
  destinationFilterOptions: FilterSelectionOption;
  departureFilterOptions: FilterSelectionOption;
  policyFilterOptions: FilterSelectionOption;
  divisionFilterOptions: FilterSelectionOption;
  destinations: Location[];
  departures: Location[];
  divisionList: Division[] = [];

  showOnlyStartedText: string;
  favouritesStarred: any;
  isFavorites: boolean;
  /** policiesKeyValPair to store the converted string[] values */
  policiesKeyValPair: string[] = [];

  /** divisionKeyValPair to store the converted string[] values */
  divisionKeyValPair: Division[] = [];


  /** movePhasesKeyValPair to hold the converted string[] values*/
  movePhasesKeyValPair: KeyValuePair[] = [];

  /** FilterDetails api subscription */
  filterDetailsSub: Subscription;

  @Input() type: string;

  /** To get the datechange event */
  @Output()
  dateChange: EventEmitter< MatDatepickerInputEvent< any>>;

  /** Base constructor method
  * @param filterDialogRef MatDialogRef<FilterModalComponent> injection
  * @param policySvc PolicyService injection
  * @param divisionSvs PolicyService injection
  * @param movePhaseSvc MovePhaseService injection
  * @param fileStatusSvc FileStatusService injection
  * @param dialogRef MatDialogRef<FilterModalComponent> injection
  * @param filterDetailsService FilterDetailsService injection
  */
  constructor(public filterDialogRef: MatDialogRef<FilterModalComponent>,
    private readonly policySvc: PolicyService,
    private readonly divisionSvs: DivisionService,
    private readonly movePhaseSvc: MovePhaseService,
    private readonly fileStatusSvc: FileStatusService,
    private readonly locationSvc: LocationService,
    public dialogRef: MatDialogRef<FilterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private filterDetailsService: FilterDetailsService,
    private userPreferenceService: UserPreferencesService,
    private dateConversionService: DateConversionService) { }

  /** Component Angular initialization lifecycle hook */
  ngOnInit() {
    if (this.data.dataKey.length > 0) {
    this.destinationFilterOptions = new FilterSelectionOption({
      enableSearch: true,
      searchCharLimit: 3,
      searchDelayInMilliSeconds: 500,
      type: 'locations'
    });
    this.departureFilterOptions = new FilterSelectionOption({
      enableSearch: true,
      searchCharLimit: 3,
      searchDelayInMilliSeconds: 500,
      type: 'locations'
    });
    this.policyFilterOptions = new FilterSelectionOption({
      enableSearch: true,
      searchCharLimit: 3,
      searchDelayInMilliSeconds: 500,
      type: 'policy'
    });
    this.divisionFilterOptions = new FilterSelectionOption({
      enableSearch: true,
      searchCharLimit: 3,
      searchDelayInMilliSeconds: 500,
      type: 'division'
    });
    if (this.data.dataKey.includes('policy')) {
    this.policySub = this.policySvc.getPoliciesByClientId()
      .subscribe(res => {
        const policyNames = res['policyNames'] && res['policyNames'].length > 0 ? res['policyNames'] :  [];
        policyNames.forEach(policy => {
          const policies: KeyValuePair = {
            key: policy,
            value: policy
          };
          this.policies.push(policies);
        });
        this.filteredPolicies = [...this.policies];
      });
    }
    if (this.data.dataKey.includes('division')) {
    this.divisionSub = this.divisionSvs.getDivisionByClientId()
      .subscribe(res => {
        this.divisionList = res['divisionList'] && res['divisionList'].length > 0 ? res['divisionList'] : [];
        this.divisionList.forEach(division => {
          const divisions: KeyValuePair = {
            key: division.divisionNumber,
            value: division.divisionName + ' (' + division.divisionNumber + ')'
          };
          this.divisions.push(divisions);
        });
        this.filteredDivision = [...this.divisions];
      });
    }
    if (this.data.dataKey.includes('movePhase')) {
    this.movePhaseSub = this.movePhaseSvc.getMovePhases()
      .subscribe(res => {
        this.movePhases = res;
      });
    }

    if (this.data.dataKey.includes('fileStatusDesc')) {
    this.fileStatusSub = this.fileStatusSvc.getFileStatuses()
      .subscribe(res => {
        this.fileStatuses = res.map(fileStatus => {
          return {
            key: fileStatus.fileStatusDesc,
            value: fileStatus.fileStatusDesc
          };
        });
      });
    }
    this.userPreferenceService.getPreference('aggregation_filter', false).subscribe((response) => {
      if (response) {
        const filters: AggregationFilter = response;
        this.selectedDestinations = filters.destination;
        this.selectedDepartures = filters.departure;
        this.selectedMovePhases = filters.movePhase && filters.movePhase.length > 0 ?
          filters.movePhase.map(item => item.value) : [];
        this.selectedPolicies = filters.policy && filters.policy.length > 0 ?
          filters.policy : [];
        let formatStartDate: any, formatEndDate: any;
        if (filters.transferStartDate !== undefined && filters.transferStartDate !== null) {
          formatStartDate = this.dateConversionService.convertToMmDdYyyy(filters.transferStartDate.toString());
        } else {
          formatStartDate = null;
        }
        if (filters.transferEndDate !== undefined && filters.transferEndDate !== null) {
          formatEndDate = this.dateConversionService.convertToMmDdYyyy(filters.transferEndDate.toString());
        } else {
          formatEndDate = null;
        }
        if (filters.division) {
          const selectedDivisionArray = [];
          filters.division.forEach(function(item) {
            selectedDivisionArray.push(
               item.divisionName + ' (' + item.divisionNumber + ')'
            );
          });
          this.selectedDivisions = selectedDivisionArray;
        }
        this.effectiveStartDate = formatStartDate ? new Date(formatStartDate) : null;
        this.effectiveEndDate = formatEndDate ? new Date(formatEndDate) : null;
        this.totalMinCost = filters.minTotalCost;
        this.totalMaxCost = filters.maxTotalCost;
        this.selectedFileStatuses = filters.fileStatusDesc;
        this.favouritesStarred = filters.favorites;
        if (this.favouritesStarred == 'OFF') {
          filters.favorites = null;
        }

      }
    });
    }
  }

  getLocations(type, e) {
    this.locationSvc.getLocations(type, e).subscribe((res) => {
      if (type === 'dept') {
        this.departures = res;
      } else {
        this.destinations = res;
      }
    });
  }

  getPoliciesByClientId(e) {
    if (e && e !== '') {
      this.filteredPolicies = this.policies.filter(policy => policy.value.toLowerCase().includes(e.toLowerCase()));
    } else {
      this.filteredPolicies = [...this.policies];
    }
  }

  getDivisionByClientId(e) {
    if (e && e !== '') {
      this.filteredDivision = this.divisions.filter(division => division.value.toLowerCase().includes(e.toLowerCase()));
    } else {
      this.filteredDivision = [...this.divisions];
    }
  }


  onReset() {
    this.selectedPolicies = [];
    this.selectedMovePhases = [];
    this.selectedDepartures = [];
    this.selectedDestinations = [];
    this.selectedFileStatuses = [];
    this.selectedDivisions = [];
    this.effectiveStartDate = null;
    this.effectiveEndDate = null;
    this.totalMaxCost = null;
    this.totalMinCost = null;
    this.favouritesStarred = null;
  }

  onSubmit(): void {
    if (this.selectedPolicies) {
      this.policiesKeyValPair = [...this.selectedPolicies];
    }
    if (this.selectedMovePhases) {
      this.selectedMovePhases.forEach(movePhase => {
        if (this.movePhases) {
          const mPhase = this.movePhases.find((i) => i.value.toLowerCase() === movePhase.toLowerCase());
          let movePhases: KeyValuePair;
          if (mPhase) {
            movePhases = {
              key: mPhase.key,
              value: movePhase
            };
            this.movePhasesKeyValPair.push(movePhases);
          }
        }
      });
    }

    let startDate: any, endDate: any;
    if (this.effectiveStartDate !== undefined && this.effectiveStartDate !== null) {
      startDate = this.dateConversionService.convertToYyyyMmDd(this.effectiveStartDate);
    } else {
      startDate = null;
    }
    if (this.effectiveEndDate !== undefined && this.effectiveEndDate !== null) {
      endDate = this.dateConversionService.convertToYyyyMmDd(this.effectiveEndDate);
    } else {
      endDate = null;
    }

    let tempTotalCost = 0;
    if ((this.totalMinCost && this.totalMaxCost) && (+this.totalMinCost > +this.totalMaxCost)) {
      tempTotalCost = this.totalMinCost;
      this.totalMinCost = this.totalMaxCost;
      this.totalMaxCost = tempTotalCost;
    }
    let effStartDate: any, effEndDate: any;
    if (startDate > endDate) {
      effStartDate = endDate;
      effEndDate = startDate;
    } else {
      effStartDate = startDate;
      effEndDate = endDate;
    }

    if (this.selectedDivisions.length > 0) {
      this.selectedDivisions.forEach(division => {
        if (this.divisionList) {
          const div = this.divisionList.find((i) => (i.divisionName + ' (' + i.divisionNumber + ')')
                        .toLowerCase() == division.toLowerCase());
          let divKeyValue: Division;
          if (div) {
            divKeyValue = {
              divisionNumber: div.divisionNumber,
              divisionName: div.divisionName
            };
           this.divisionKeyValPair.push(divKeyValue);
        }
      }
    });
  }

    const filters: AggregationFilter = {
      policy: this.policiesKeyValPair ? this.policiesKeyValPair : [],
      movePhase: this.movePhasesKeyValPair ? this.movePhasesKeyValPair : [],
      fileStatusDesc: this.selectedFileStatuses ? this.selectedFileStatuses : [],
      departure: this.selectedDepartures ? this.selectedDepartures : [],
      destination: this.selectedDestinations ? this.selectedDestinations : [],
      transferStartDate: effStartDate ? effStartDate : null,
      transferEndDate: effEndDate ? effEndDate : null,
      minTotalCost: (this.totalMinCost || this.totalMinCost === 0) ? this.totalMinCost : null,
      maxTotalCost: this.totalMaxCost ? this.totalMaxCost : null,
      division: this.divisionKeyValPair ? this.divisionKeyValPair : [],
      favorites: this.favouritesStarred ? this.favouritesStarred : null
    };
    this.dialogRef.close(filters);

  
  
    }

  /** To trigger the effective end date change event to set default date for start date */
  effectiveDateChange() {
    if (!this.effectiveStartDate && this.effectiveEndDate) {
      this.effectiveStartDate = new Date('01-01-1900');
    }
  }

  /** To set the default value for total Min Cost */
  totalMaxCostBlur() {
    if (!this.totalMinCost && this.totalMaxCost) {
      this.totalMinCost = 0;
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57 )) {
      return false;
    }
    return true;
  }

  /** Component Angular destructor lifecycle hook */
  ngOnDestroy(): void {
    if (this.policySub) {
      this.policySub.unsubscribe();
    }

    if (this.movePhaseSub) {
      this.movePhaseSub.unsubscribe();
    }
    if (this.fileStatusSub) {
      this.fileStatusSub.unsubscribe();
    }
  }

  showOnlyStartedChanged(event) {
    this.showOnlyStartedText = event.checked ? 'ON' : 'OFF';
    this.favouritesStarred = this.showOnlyStartedText == 'ON' ? 1 : '';
  }

}

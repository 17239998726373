import { Injectable, ViewEncapsulation } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { Providers } from './../models/providers.model';
import { RecentProvider } from './../models/recent-provider.model';
import { HttpParams } from '@angular/common/http';

/** ProviderService to fetch Provider details from Api and to display in the provider table*/
@Injectable({
  providedIn: 'root'
})
export class ProviderService {

/** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
   */
  constructor(private readonly baseClient: BaseClientService, private readonly logSvc: RemoteLoggingService) { }

  /** getProvidersByFileNum() to fetch Provider details from Api as provider[] */
  getProvidersByFileNum(fileNumber: number, curr : string): Observable<Providers> {
    let params;
    if (curr) {
      params = new HttpParams({ fromObject: {
      currencyAbbr: curr
      }});
    }
    return this.baseClient
      .getOne<Providers>(`v1/provider/${fileNumber}`, 'get the provider details', params)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: Providers = null;
          this.logSvc.logError(err);
          return of(empty);
        })
      );
  }

  /** getProvidersByFileNum() to fetch Provider details from Api as provider[] */
  getRecentProvidersByFileNum(fileNumber: number, curr : string): Observable<RecentProvider> {
    let params;
    if(curr) {
      params = new HttpParams({ fromObject: {
      currencyAbbr: curr
      }});
    }
    return this.baseClient
      .getOne<RecentProvider>(`v1/provider-invoices/${fileNumber}`, 'get the recent provider details', params)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: RecentProvider = null;
          this.logSvc.logError(err);
          return of(empty);
        })
      );
  }
  getProvidersByFileNumber(fileNumber: number,orderRequestId:any, curr : string): Observable<Providers> {
    let params;
    if (curr) {
      params = new HttpParams({ fromObject: {
      currencyAbbr: curr
      }});
    }
    return this.baseClient
      .getOne<Providers>(`v1/provider/${fileNumber}?context=benefits-builder&orderRequestId=${orderRequestId}`, 'get the provider details', params)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: Providers = null;
          this.logSvc.logError(err);
          return of(empty);
        })
      );
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-client-screen-save-changes',
  templateUrl: './client-screen-save-changes.component.html',
  styleUrls: ['./client-screen-save-changes.component.scss']
})
export class ClientScreenSaveChangesComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ClientScreenSaveChangesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }
  	/**
	* To to close dialogbox
	*/
	close() {
		this.dialogRef.close([]);
	}
}

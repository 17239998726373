import { Injectable } from '@angular/core';
import { BaseClientService } from '../../../core/services/base-client.service';
import { RemoteLoggingService } from '../../../core/services/remote-logging.service';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  public readonly categorySub = new BehaviorSubject<string[]>(null);
  
  /**to store BenefitColorMapping */
  public BenefitColorMapping = [];

  constructor(private baseClientService: BaseClientService, private logSvc: RemoteLoggingService) { }

  /**get category values api call */
  getCategoryData(): Observable<any> {
    return this.baseClientService
      .getOne<any>('v1/admin/value-list/?key=ProductCategories')
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      )
  }

  getCategoryDisplayNames() {
    this.getCategoryData().subscribe(res => {
      if (res) {
        let categoryDisplayNames;
        categoryDisplayNames = [...res.values];
        res.values = res.values.sort((a, b) => a.order - b.order );
        res.values.forEach((ele, index) => {
          categoryDisplayNames[index] = ele.displayName;
          this.BenefitColorMapping[ele.displayName] = ele.color;
        });
        this.categorySub.next(categoryDisplayNames);
      }
    });
  }
}
<div class="filter-modal-wrap display-column">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mat-dialog-title">
        <h2>Table Columns</h2><button mat-icon-button mat-dialog-close aria-label="Close" matTooltip="Close" (click) = 'close()'><span
                class="material-icons close-icon">close</span></button>
    </div>
    <div class="mat-dialog-content">
  
        <div class="data-view-checklist">
       
               
     <mat-selection-list #dataviewSelection (selectionChange)="onSelection($event)">
        <mat-list-option *ngFor="let item of columnOrder" [value]="item" [selected]="item.checked" [disabled]="isDisabledColumns(item.field)">
          {{item.header}}
        </mat-list-option>
      </mat-selection-list>
          </div>
        
    </div>
    <p class="selected-value">
        <span>{{dataviewSelection.selectedOptions.selected.length}} : </span> selected 
       </p>
    <div class="mat-dialog-actions action-buttons">
        <div class="selection-buttons" fxLayout="row" fxLayoutAlign="end center">
            <button mat-button class="text-button rest-btn" (click)="reset()"> RESET </button>
            <button mat-button class="contained-button" (click)='selectedListItems()'> OK </button>
        </div>
    </div>
</div>

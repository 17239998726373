<div class="dialog-container" role="region">
  <!-- Title of pop up -->
  <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px"
    fxLayoutGap.xs="0em" role="heading">
    <span class="copy-header">Copy Program</span>
    <a class="close-icon" (click)="onDismiss()" title="close" role="button" aria-label="Close Program Modal"
      title="Close Program Modal">
      <mat-icon>close</mat-icon>
    </a>
  </div>

  <mat-dialog-content>
    <form fxLayout="column" class="middle-section" [formGroup]="programForm">
      <!-- disabled old name input -->
      <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none">
        <mat-form-field>
          <mat-label>Name of Program Being Copied</mat-label>
          <input matInput readonly role="text" formControlName="programName">
        </mat-form-field>
      </div>

      <!-- new program name input -->
      <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none">
        <mat-form-field>
          <mat-label>Name for New Copy of the Program</mat-label>
          <input matInput placeholder="Name for New Copy of the Program" formControlName="newProgramName" role="text">
        </mat-form-field>
      </div>

      <!-- client name input -->
      <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none">
        <mat-form-field>
          <mat-label>Client New Program is for</mat-label>
          <input placeholder="Client New Program is for" matInput formControlName="clientNewProgram"
            [matAutocomplete]="auto" role="text" (blur)="validateSelectedClient()">
          <mat-autocomplete #auto="matAutocomplete" >
            <mat-option *ngFor="let option of clientList"
              [value]="option.clientEntityName+'('+ option.clientNumber + ')'">
              {{option.clientEntityName +'('+ option.clientNumber + ')'}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <!-- Message  -->
      <div class="message" fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none">
        <p> <span> <strong>Please be Aware :</strong></span> <em>Divisions</em>,
          <em>Contract Series</em> and <em>Atlas Policy</em> Information are <strong>not</strong> copied.</p>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button type="button" class="mat-button text-button btn" (click)="onDismiss()" color="primary" role="button"
      aria-label="Cancel and Close Modal">CANCEL</button>
    <button [disabled]="programForm.invalid" class="mat-button contained-button btn" (click)="submitProgramForm()"
      role="button" aria-label="Proceed to Next screen in Program Creation">Copy as Draft</button>
  </mat-dialog-actions>

</div>
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelExportService {

  constructor(private readonly toastrService: ToastrService) { }

  /** exportAsExcelFile take the json data and converts to worksheet and generates the report */
  public exportAsExcelFile(json: any[], reportOptions: any, totalOptions: any): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([{ 'col1': 'DATE:' + ' ' + reportOptions.reportDate,
    'col2': '', 'col3': '', 'col4': 'TIME:' + ' ' + reportOptions.reportTime }], { header: [], skipHeader: true });
    XLSX.utils.sheet_add_json(worksheet, [{}], { header: [], skipHeader: true, origin: 'A2' });
    XLSX.utils.sheet_add_json(worksheet, [{ message: reportOptions.reportMessage }], { skipHeader: true, origin: 'A3' });
    XLSX.utils.sheet_add_json(worksheet, [{}], { header: [], skipHeader: true, origin: 'A4' });
    XLSX.utils.sheet_add_json(worksheet, json, { origin: reportOptions.addressCell });
    if (reportOptions.reportName === 'Employee Transactions') {
      XLSX.utils.sheet_add_json(worksheet, [{}], { header: [], skipHeader: true, origin: -1 });
      if(totalOptions.taxAssistance){
        XLSX.utils.sheet_add_json(worksheet, [{'col1': '', 'col2': '', 'col3': '', 'col4': 'TOTAL EXPENSES',
        'col5': totalOptions.totalExpense, 'col6': totalOptions.totalCurrency }], { skipHeader: true, origin: -1 });
        XLSX.utils.sheet_add_json(worksheet, [{'col1': '', 'col2': '', 'col3': '', 'col4': 'TAX ASSISTANCE',
        'col5': totalOptions.taxAssistance, 'col6': totalOptions.totalCurrency }], { skipHeader: true, origin: -1 });
      }
      XLSX.utils.sheet_add_json(worksheet, [{'col1': '', 'col2': '', 'col3': '', 'col4': 'TOTAL COST',
      'col5': totalOptions.totalAmount, 'col6': totalOptions.totalCurrency }], { skipHeader: true, origin: -1 });
    }

    const workbook: XLSX.WorkBook = { Sheets: { 'Data': worksheet }, SheetNames: ['Data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.toastrService.clear();
    this.showDownloadToaster('EXCEL Report Downloading...');
    this.saveAsExcelFile(excelBuffer, reportOptions.fileName, reportOptions.reportDate);
  }

  /** saveAsExcelFile will download the file as excel */
  private saveAsExcelFile(buffer: any, fileName: string, reportDate: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + ' ' + reportDate + EXCEL_EXTENSION);
  }

  /** showDownloadToaster will show toastr with status message */
  showDownloadToaster(message) {
    this.toastrService.info(message, null,
      {
        closeButton: true,
        timeOut: 5000,
        enableHtml: true,
        disableTimeOut: false // User must explicitly dismiss error messages
      }
    );
  }
}

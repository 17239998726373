import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { FamilyInfoService } from './../../../../services/family-info.service';

@Component({
  selector: 'app-transferee-accopanying-members-modal',
  templateUrl: './transferee-accopanying-members-modal.component.html',
  styleUrls: ['./transferee-accopanying-members-modal.component.scss']
})

export class TransfereeAccopanyingMembersModalComponent implements OnInit {
  transfereeDataSource: any = new  MatTableDataSource();
  familyDataSource: any = new  MatTableDataSource();

  transfereeInfoDisplayedColumns: Array<string> = ['partyId','moveOrderId','referenceNumber'];
  familyInfoDisplayedColumns: Array<string> = ['name','relationship','partyId'];
  @ViewChild('familyInfoTable', { read: MatSort, static: true }) sort: MatSort;

  constructor(
  public dialogRef: MatDialogRef <TransfereeAccopanyingMembersModalComponent>,
  private familyInfoService: FamilyInfoService,
  private readonly spinner: NgxSpinnerService,
  @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if(this.data.hasOwnProperty('transfereeDetails') && this.data.hasOwnProperty('orderRequestId')) {
      this.transfereeDataSource = new  MatTableDataSource([{
        partyId: this.data.transfereeDetails.candidateId,
        moveOrderId: this.data.orderRequestId,
        referenceNumber: this.data.transfereeDetails.orderRefNum
      }]);
      
      this.spinner.show();
      this.familyInfoService.getFamilyMemberInfo().subscribe(familyMemberList => {
        this.spinner.hide();
        if (familyMemberList && familyMemberList.length > 0) {
          let familyListInfo = [];
          familyMemberList.forEach(member => {
            familyListInfo.push({
              name: member.nameDetails.lastName + ', '+ member.nameDetails.firstName,
              relationship: member.relationshipType,
              partyId: member.id
            })
          });
          this.familyDataSource = new MatTableDataSource(familyListInfo);
          this.familyDataSource.sort = this.sort;
        }
      });
    }
  }

  /**
 * To dismiss/cancel the popup
 */
  onDismiss() {
    this.dialogRef.close();
  }

}

import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { DateConversionService } from '../../../../core/services/date-conversion.service';
import { CandidateProfileService, client, clientList } from '../../services/candidate-profile.service';
import { AdminFilterSelectionComponent } from '../transferee-list-filter/filter-selection/filter-selection.component';
import { Observable } from 'rxjs';
@Component({
  selector: 'admin-filter-modal',
  templateUrl: './admin-filter-modal.component.html',
  styleUrls: ['./admin-filter-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AdminFilterModalComponent implements OnInit {
  statusStartDate: Date | string;
  statusEndDate: Date | string;

  selectedFilters: {
    [filterType: string]: any
  } = {};

  disableFilter = false;
  authorizationDate = {};
  clientList$: Observable<client[]>;
  clients = new UntypedFormControl([]);
  selectedStatus: string[] = [];
  selectedTabLabel: string;
  authorizationType: Array<any> = [{'label': 'Traditional','value':'Traditional'}, {'label': 'Benefits Builder','value':'BenefitsBuilder'}];
  statusCheckBoxList: Array<any> = [{'label': 'Submission Failure','value':'Submission Failure'}, {'label': 'Duplicate Check','value':'Duplicate Check'}];
  selectedAuthorizationType: Array<string> = [];

  constructor(
    public filterDialogRef: MatDialogRef<AdminFilterSelectionComponent>,
    public dialog: MatDialog,
    private candidateService: CandidateProfileService,
    private dateConversionService: DateConversionService,
    @Inject(MAT_DIALOG_DATA) public data: any)
   { }

  ngOnInit() {
    const dateConvert = this.convertDateToUTC;
    if(this.data.selectedAdminFilters) {
      this.selectedFilters = this.data.selectedAdminFilters;
      this.clients.setValue(this.data.selectedAdminFilters.clients);

      if(this.data.selectedAdminFilters.authorizationDateRange && (this.data.selectedAdminFilters.authorizationDateRange.startDate  || this.data.selectedAdminFilters.authorizationDateRange.endDate)){
        this.authorizationDate = this.data.selectedAdminFilters.authorizationDateRange;
        this.statusStartDate = dateConvert(this.data.selectedAdminFilters.authorizationDateRange.startDate);
        this.statusEndDate = dateConvert(this.data.selectedAdminFilters.authorizationDateRange.endDate);
      }

      if(this.data.selectedAdminFilters.authorizationType) {
        this.selectedAuthorizationType = this.data.selectedAdminFilters.authorizationType;
      }

      if(this.data.selectedAdminFilters.status) {
        this.selectedStatus = this.data.selectedAdminFilters.status;
      }
    }
    if(this.data && this.data.selectedTabLabel){this.selectedTabLabel = this.data.selectedTabLabel };
    this.getClientList();
  }

  onSubmit(): void {
    this.filterDialogRef.close(this.selectedFilters);
  }

  onReset() {
    this.filterDialogRef.close(this.selectedFilters);
  }

  getClientList() {
    if(this.selectedTabLabel === 'Unprocessed') {
      this.clientList$ = this.candidateService.getUnprocessedClientList();
    } else if(this.selectedTabLabel === 'Compliance') {
      this.clientList$ = this.candidateService.getComplianceClientList()
    }  
  }

  onClientSelection() {
    this.selectedFilters['clients'] = this.clients.value;
  }

  selectedDataSetChange(source: string, newValue: any) {
    if (source === 'startDate' || source === 'endDate') {
      this.statusDateChange();
      const date = this.dateConversionService.convertToYyyyMmDd(newValue);
      newValue = date;
      this.authorizationDate[source] = newValue;
      this.selectedFilters['authorizationDateRange'] = this.authorizationDate;
    } else {
      this.selectedFilters[source] = newValue;
    }
    
  }

    /** To trigger the status end date change event to set default date for start date */
  statusDateChange() {
    if (!this.statusStartDate && this.statusEndDate) {
      this.statusStartDate = new Date('01-01-1900');
      this.authorizationDate['startDate'] = this.dateConversionService.convertToYyyyMmDd(this.statusStartDate);
    } 
  }

  getErrorMessage(){
    if ( this.statusStartDate > this.statusEndDate && this.statusEndDate) {
      this.disableFilter = true;
      return "Status start date shouldn't be greater than Status end date.";
    } else {
      this.disableFilter = false;
    }
  }

  clearAllSelectedClients() {
    this.clients.setValue([]);
    this.selectedFilters['clients'] = [];
  }

  
  /**
   * Checks for Value or will return 'empty'
   * @param value any
   */
  convertDateToUTC(dateToConvert) {
    try {
      if(dateToConvert){
        const localTime = new Date();
        let diff = localTime.getTimezoneOffset();
        if(diff > 0){
          return new Date(dateToConvert.split('T')[0].concat('T23:00:00.000Z'));
        }else{
          return new Date(dateToConvert.split('T')[0].concat('T00:00:00.000Z'));
        }
      }else{
        return '';
      }
    } catch (error) {
      return '';
    }
  }

  compareByClientId(optionOne, optionTwo) {
    return optionOne.clientId === optionTwo.clientId;
  }
}

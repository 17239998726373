import { Component, inject, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CandidateProfileService } from '../../../services/candidate-profile.service';
@Component({
  selector: 'app-duplicate-records-delete-popup',
  templateUrl: './duplicate-records-delete-popup.component.html',
  styleUrls: ['./duplicate-records-delete-popup.component.scss']
})
export class DuplicateRecordsDeletePopupComponent implements OnInit {
    constructor(
    public dialogRef: MatDialogRef<DuplicateRecordsDeletePopupComponent>,
 @Inject(MAT_DIALOG_DATA) public data: any,
 private candidateProfileService:CandidateProfileService ){}

  ngOnInit() {
    console.log("popup Component data",this.data);
    this.data;
  }
  
//1 popup data delete function
onClickDelete(){
  this.candidateProfileService.getDeleteRecord(this.data.id).subscribe(response=>{
   if (response){
    this.dialogRef.close({action:'dismisWindow'});
    
   }
  },error=>{
   this.dialogRef.close();
  })
}

onCancel(evt) {
  evt.preventDefault();
  this.dialogRef.close(null)

}
}
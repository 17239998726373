import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ClientContactRegistration } from '../models/client-contact-registration.model';
import { APIResponse } from '../models/response.model';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
@Injectable({
  providedIn: 'root'
})
export class ClientContactService {
  /**
   * @param baseClientService Base Client service
   * @param logSvc Log service
   */
  constructor(private readonly baseClientService: BaseClientService,
    private readonly logSvc: RemoteLoggingService, ) {}

  /**
   * To update client contact details 
   * @param request client contact details to be updated
   */
  updateClientContact(request: ClientContactRegistration) {
    return this.baseClientService.put < ClientContactRegistration > ('v1/clientContacts/updateClientContact', request).pipe(
      map(r => r.body),
      catchError((err, source) => {
        let empty: any = null;
        if(err.message.includes('SSO')) {
          const errSplit = err.message.split(':');
          empty = errSplit[1];
        }
        this.logSvc.logError('Failed to updated client contact details' + err);        
        return of(empty);
      })
    );
  }

  /**
   * To send and resend invites to client contact
   * @param request client contact details to update/send/resend invites
   */
  sendInviteToClientContact(request): Observable < any > {
    return this.baseClientService
      .post < APIResponse > (`v1/clientContacts/sendRegistartionMail`, request)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          let empty: any = null;
          if (err.message.includes('SSO')) {
            const errSplit = err.message.split(':');
            empty = errSplit[1];
            return of(empty);
          }
          this.logSvc.logError('Failed to send invite' + err);
          return null;
        })
      );
  }
}

import { Component, OnInit, Injector, Inject } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { candidateProfile } from '../../../../core/models/candidate.model';

const titleMappings = {
  'lumpSum': {
    title: 'Lump Sum Amount Override Info',
    overrideLabel: 'Lump Sum Amount Override',
    overrideByLabel: 'Lump Sum Amount Override By',
    overrideDateLabel: 'Lump Sum Amount Override Date',
    defaultLabel: 'Default Lump Sum Amount',
  },
  'pointOverride': {
    title: 'Points Override Info',
    overrideLabel: 'Points Override',
    overrideByLabel: 'Override By',
    overrideDateLabel: 'Override Date',
    defaultLabel: 'Default Program Points',
  },
  'amountOverride': {
    title: 'Amount Override Info',
    overrideLabel: 'Amount Override',
    overrideByLabel: 'Override By',
    overrideDateLabel: 'Override Date',
    defaultLabel: 'Default Program Amount',
  },
  'paymentEligibility': {
    title: 'Payment Eligibility',
    overrideLabel: 'New Payment Eligibility Date',
    overrideByLabel: 'Payment Eligibility Date Override By',
    overrideDateLabel: 'Payment Eligibility Date Override Date',
    defaultLabel: 'Default Payment Eligibility Date',
  },
};

@Component({
  selector: 'app-show-override-history',
  templateUrl: './show-override-history.component.html',
  styleUrls: ['./show-override-history.component.scss']
})
export class ShowOverrideHistoryComponent implements OnInit {

/** title for pop up */
title: string;
candidateProfile: candidateProfile = {} as candidateProfile;
overrideLabel: string;
overrideByLabel: string;
overrideDateLabel: string;
overrideHistory: any;
defaultLabel: string;

  constructor(
    public dialogRef: MatDialogRef<ShowOverrideHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.candidateProfile = this.data;
    this.setTitle();
  }

  setTitle() {
    const data = this.data || {};
    if (data.isLumpSum) {
      this.setLabels(titleMappings.lumpSum);
    } else if (data.programDetails && data.programDetails.pointOverride) {
      this.setLabels(titleMappings.pointOverride);
    } else if (data.programDetails && data.programDetails.amountOverride) {
      this.setLabels(titleMappings.amountOverride);
    } else if(data.type === 'payment') {
      this.setLabels(titleMappings.paymentEligibility);
    }
  }
  
  setLabels(labels: any) {
    this.title = labels.title;
    this.overrideLabel = labels.overrideLabel;
    this.overrideByLabel = labels.overrideByLabel;
    this.overrideDateLabel = labels.overrideDateLabel;
    this.defaultLabel = labels.defaultLabel;
  }

  /**
   * Invoked on click of dismiss/close
   */
  onDismiss(evt) {
    evt.preventDefault();
    this.dialogRef.close();
  }

  getHistoryData(): any[] {
    if (this.title === 'Points Override Info') {
      return this.candidateProfile.pointsOverrideHistory;
    } else if (this.title === 'Amount Override Info' || this.title === 'Lump Sum Amount Override Info') {
      return this.candidateProfile.amountOverrideHistory;
    } else if (this.title === 'Amount Override Info' || this.title === 'Lump Sum Amount Override Info') {
      return this.candidateProfile.amountOverrideHistory;
    } else if (this.title === 'Payment Eligibility') {
      return this.data?.details.paymentEligibilityDateHistory;
    } else {
      return [];
    }
  }
}

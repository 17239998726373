import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, flatMap } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { UserContextService } from './user-context.service';
// import { HttpParams } from '@angular/common/http';

export interface FeatureFlag{
	/** feature flag key */
	key: string;
	/** kind of response */
	kind: string;
	/** to indicate if feature flag is enabled */
	on: boolean;
	/**environment name */
	_environmentName: string;
}

export interface FeatureFlags{
	items: Array<FeatureFlag>
}

/** FeatureFlagService to fetch feature flags from Api */
@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {

/** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
   */
  constructor(
    private readonly baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private readonly userContextService: UserContextService
  ) { }

  teamsFeatureFlag$ = new BehaviorSubject<string>(null);

  async getAuthorizationFeatureFlag(): Promise<boolean> {
    if (!this.teamsFeatureFlag$.value) {
     
        const response = await this.getFeatureFlag('movepro360-enable-authorizations').toPromise();
        this.teamsFeatureFlag$.next(response);

    }
    return Promise.resolve(this.teamsFeatureFlag$.value && this.teamsFeatureFlag$.value.toString() === 'true');
  }

  /** getFeatureFlag() to fetch a feature flag */
  getFeatureFlag(name: string): Observable<string> {
    return this.userContextService.getUserContext().pipe(flatMap(context => {
      let body = {
        partyId: context.partyId,
        clientId: context.clientNo
      };
      return this.baseClient
        .post<string>(`v1/featureFlag/${name}`, body, 'get the feature flag')
        .pipe(
          map(r => r.body),
          catchError((err, source) => {
            const empty: string = null;
            this.logSvc.logError(err);
            return of(empty);
          })
        );
    }));
  }

  getFeatureFlagsBBUrl(): Observable<FeatureFlags> {
    let httpParam = new HttpParams();
    httpParam = httpParam.append('project_key', 'move-pro-360')
    return this.baseClient
    .getById<FeatureFlags>(`v1/featureFlag?`+ httpParam.toString(), 'get the feature flag')
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          console.log( 'Failed to get feature flag details', err );
          const empty: FeatureFlags = null;
          this.logSvc.logError(err);
          return of(empty);
        })
      );
  }

  async getAuthorizationFeatureFlagByName(): Promise<boolean> {
    if (!this.teamsFeatureFlag$.value) {
     
        const response = await this.getFeatureFlagByName('enable-login-app').toPromise();
        this.teamsFeatureFlag$.next(response);
    }
    return Promise.resolve(this.teamsFeatureFlag$.value && this.teamsFeatureFlag$.value.toString() === 'true');
  }
  
  /** getFeatureFlagByName() to fetch a feature flag with name as input */
  getFeatureFlagByName(name: string): Observable<any> {
    return this.baseClient
      .getOne<boolean>(`v1/featureFlag/${name}`)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: string = null;
          this.logSvc.logError(err);
          return of(empty);
        })
      );
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'conditionalAuthTypeTextReplace'
})
export class ConditionalAuthTypeTextReplacePipe implements PipeTransform {

  transform(value: unknown, isLumpSum : boolean = false): unknown {
    if (value === null || value === undefined || value === '') {
      return value;
    } 
    if(isLumpSum){
      return value + ' (Lump Sum)'
    }else if(value === 'BenefitsBuilder'){
      return 'Benefits Builder';
    }else{
      return value;
    }
  }

}

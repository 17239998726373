import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CronofyAvailablilityPickerComponent } from './cronofy-availablility-picker.component';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [],
})
export class CronofyAvailablilityPickerModule { }

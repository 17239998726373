import { Injectable, Injector } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AppConfigService } from './app-config.service';

@Injectable()
export class AppInitService { 

  constructor(private readonly appConfig: AppConfigService,) { }  

  Init() {
        return new Promise<void>((resolve, reject) => {
            console.log("AppInitService.init() called");
            try {
                this.loadGoogleMapApiScript();
                resolve();
            } catch ( error ) {
                reject(new Error ('Could not load the Google Maps API'));
            }

        });
    }

    loadGoogleMapApiScript () {
        var script = document.createElement('script');
        script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp&key='+this.appConfig.getConfig('googleApiKey')+'&libraries=places&callback=LazyMapsAPILoader';
        script.async = true;
        document.head.appendChild(script);
       
    }
          
}

<h2 class="header">Employee List</h2>
<div *ngIf="userAdminCapabilities" (click)="openAdministation($event)" class="single-auth">
  <a tabindex="0" href="javascript:"> Authorization </a>
</div>
<div [ngClass]="{'fix-height' : fixed}"></div>
<div [style.display]="(clearResults) ? 'none' : 'block' " class="emp-details mat-elevation-z8"
  [ngClass]="{'fixed' : fixed}">
  <div class="table-scroll">
    <table mat-table [dataSource]="dataSource" cdkDropListGroup matSort [matSortDisableClear]="true"
      class="employeeTable">
      <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column.field" [sticky] = "column.sortName === 'lastName' || column.sortName === 'favorites'">
        <div *ngIf="column.sortName === 'lastName' || column.sortName === 'favorites'; else draggableColumns">
          <th mat-header-cell *matHeaderCellDef [@.disabled]="initialSortColumn === column.sortName"
            [ngClass]="{'initialColumnSelected': initialSortColumn === column.sortName }"
            (click)="applySort(column.sortName)"  [matTooltip]="'Change sorting for ' + column.header"
            [start]="initialSortColumn === 'lastName' ? initialSortDirection : null" mat-sort-header>
            <ng-container *ngIf="column.sortName === 'lastName'; else starHeader">
              {{ column.header }}
            </ng-container>
            <ng-template #starHeader>
              <svg version="1.1" [ngClass]="0 ? 'filled' : 'empty'" class="star" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <use xlink:href="assets/images/star-rating.icons.svg#star-filled"></use>
              </svg>
            </ng-template>
          </th>
        </div>
        <ng-template #draggableColumns>
          <th mat-header-cell *matHeaderCellDef mat-sort-header [@.disabled]="initialSortColumn === column.sortName"
            [ngClass]="{'initialColumnSelected': initialSortColumn === column.sortName }" cdkDropList
            cdkDropListLockAxis="x" cdkDragBoundary=".employeeTable" cdkDropListOrientation="horizontal"
            (cdkDropListDropped)="dragDrop($event, i)" (cdkDragStarted)="dragStarted($event, i)"
            (click)="applySort(column.sortName)" cdkDrag class="selectedColumn"  [matTooltip]="'Change sorting for ' + column.header"
            [start]=" initialSortColumn === column.field ? initialSortDirection : null">
              {{ column.header }}
            <div fxLayout="row" fxFlex="0 1 150px" fxLayoutAlign="center" class="dragPreview" *cdkDragPreview
              [innerHTML]="column.header"> {{ column.header }}</div>
          </th>
        </ng-template>
        <td mat-cell *matCellDef="let element; let row = index" [ngClass]="{'colDrag': dragColumnName === column.sortName }">

          <!-- Starred Column -->
          <div *ngIf="column.field === 'starred'" >
            <div>
              <svg version="1.1" [ngClass]="element.favUser ? 'filled' : 'empty'" class="star"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                (click)="updateFavorite(element.fileNumber, element.favUser, row)">
                <use
                  [attr.xlink:href]="element.favUser ? 'assets/images/star-rating.icons.svg#star-filled' : 'assets/images/star-rating.icons.svg#star-empty'">
                </use>
              </svg>
            </div>
          </div>

          <!-- empName Column -->
          <div *ngIf="column.field === 'fullName'">
            <a routerLink="/empInfo/{{element.fileNumber}}" tabindex="0"><span
                [innerHTML]="element.lastName | highlightSearch: filterText">
                {{ element.lastName | highlightSearch: filterText }}
              </span>
              <span>,</span>&nbsp;<span
                [innerHTML]="element.firstName | highlightSearch: filterText">{{ element.firstName | highlightSearch: filterText }}</span></a>
            <a routerLink="/empInfo/{{element.fileNumber}}" tabindex="0">
              <span> File #: </span>
              <span [innerHTML]="element.fileNumber | highlightSearch: filterText">
                {{ element.fileNumber | highlightSearch: filterText }}
              </span>
            </a>
            <a routerLink="/empInfo/{{element.fileNumber}}" tabindex="0">
              <span> Emp. ID: </span>
              <span
                [innerHTML]="(element.clientEmployeeId && element.clientEmployeeId != '' ? element.clientEmployeeId : 'N/A') | highlightSearch: filterText">
                {{ (element.clientEmployeeId && element.clientEmployeeId != '' ? element.clientEmployeeId : 'N/A') | highlightSearch: filterText }}
              </span>
            </a>
          </div>

          <!-- Division Column -->
          <div *ngIf="column.field === 'division'">
            <div>
              <span [innerHTML]="element.divisionName | highlightSearch: filterText">
                {{element.divisionName}}</span>&nbsp;
              (<span [innerHTML]="element.divisionNumber | highlightSearch: filterText">{{element.divisionNumber}}</span>)
            </div>
          </div>

          <!-- File status Column -->
          <div *ngIf="column.field === 'fileStatusDesc'">
            <div [innerHTML]="element.fileStatusDesc | highlightSearch: filterText">
              {{ element.fileStatusDesc | highlightSearch: filterText }}
            </div>
          </div>

          <!-- MovePhase Column -->
          <div *ngIf="column.field === 'movePhase'" [ngClass]="{'clickable' : element.movePhase.id }"
            (click)="openMovePhaseModal(element, $event)"
            (keypress)="openMovePhaseModal(element, $event)" tabindex="0">
            <a *ngIf="element.movePhase.id" tabindex=0
              attr.aria-label="Current Move Phase is {{element.movePhase.id}} of 5: {{element.movePhase.altText}}"
              matTooltip="Current Move Phase is {{element.movePhase.id}} of 5: {{element.movePhase.altText}}">
              <app-multi-step [steps]="5" [selected]="element.movePhase.id"></app-multi-step>
            </a>
            <span *ngIf="!element.movePhase.id" matTooltip="Move Phase is Not Applicable"> N/A </span>
          </div>

          <!-- PolicyName Column -->
          <div *ngIf="column.field === 'policy'"
            [innerHTML]="(element.policyName ? element.policyName : 'N/A') | highlightSearch: filterText">
            {{ (element.policyName ? element.policyName : 'N/A') | highlightSearch: filterText }}
          </div>

          <!-- Departure Column -->
          <div *ngIf="column.field === 'departure'">
            <p *ngIf="element.departureCountry" [innerHTML]="element.departureCountry | highlightSearch: filterText">
              {{ element.departureCountry | highlightSearch: filterText }}
            </p>
            <p *ngIf="element.departureCity" [innerHTML]="element.departureCity | highlightSearch: filterText">
              {{ element.departureCity | highlightSearch: filterText }}
            </p>
            <p *ngIf="element.departureState" [innerHTML]="element.departureState | highlightSearch: filterText">
              {{ element.departureState | highlightSearch: filterText }}
            </p>
          </div>

          <!-- Destination Column -->
          <div *ngIf="column.field === 'destination'">
            <p *ngIf="element.destinationCountry"
              [innerHTML]="element.destinationCountry | highlightSearch: filterText">
              {{ element.destinationCountry | highlightSearch: filterText }}
            </p>
            <p *ngIf="element.destinationCity" [innerHTML]="element.destinationCity | highlightSearch: filterText">
              {{ element.destinationCity | highlightSearch: filterText }}
            </p>
            <p *ngIf="element.destinationState" [innerHTML]="element.destinationState | highlightSearch: filterText">
              {{ element.destinationState | highlightSearch: filterText }}
            </p>
          </div>

          <!-- Provider Count Column -->
          <div *ngIf="column.field === 'providers'" [ngClass]="{'clickable' : element.providerCount > 0 }"
            (click)="openProviderSummary(element, $event)" (keypress)="openProviderSummary(element, $event)"
            tabindex="0">
            <a *ngIf="element.providerCount > 0" [innerHTML]="element.providerCount | highlightSearch: filterText"
              tabindex="0">
              {{ element.providerCount | highlightSearch: filterText }}
            </a>
            <span *ngIf="element.providerCount == 0" [innerHTML]="element.providerCount | highlightSearch: filterText"
              tabindex="0">
              {{ element.providerCount | highlightSearch: filterText }}
            </span>
          </div>

          <!-- Sentiment Column -->
          <div *ngIf="column.field === 'sentiment'" [ngClass]="{ 'clickable' : element.sentimentLevel > 0 }"
            (click)="recentSentimentModal(element.fileNumber, element.sentimentLevel, $event)"
            (keypress)="recentSentimentModal(element.fileNumber, element.sentimentLevel, $event)" tabindex="0">
            <div *ngIf="element.sentimentLevel > 0; else sentimentDisable">
              <star-rating [rating]="element.sentimentLevel" matTooltip="{{ element.sentimentLevel }} star{{element.sentimentLevel > 1 ? 's' : '
                  '}}" [readOnly]="true" [starType]="'svg'" tabindex="-1" attr.aria-label="{{ element.sentimentLevel }} star{{element.sentimentLevel > 1 ? 's' : '
                  '}}"></star-rating>
              <p class="link-color" [innerHTML]="element.sentimentName | highlightSearch: filterText">
                {{ element.sentimentName | highlightSearch: filterText }}
              </p>
            </div>
            <ng-template #sentimentDisable>
              <star-rating [rating]="element.sentimentLevel" disabled=true [readOnly]="true" [starType]="'svg'">
              </star-rating>
              <p><span class="grey-sentiment"> Not Provided </span></p>
            </ng-template>
          </div>

          <!-- Effective Transfer Date Column -->
          <div *ngIf="column.field === 'effectiveTransferDate'"
            [innerHTML]="element.effectiveTransferDate === null || element.effectiveTransferDate === '' ? 'N/A' : dateConversionService.convertToDisplayDate(element.effectiveTransferDate)">
            {{ element.effectiveTransferDate === null || element.effectiveTransferDate === '' ? 'N/A' : dateConversionService.convertToDisplayDate(element.effectiveTransferDate) }}
          </div>

          <!-- Last Milestone Column -->
          <div *ngIf="column.field === 'milestone'" class="clickable" tabindex="0"
            [ngClass]="{'clickable' : element.milestoneDate.length > 0 }"
            (click)="element.milestoneDate.length > 0 ? recentMilestoneModal(element.fileNumber, $event) : null"
            (keypress)="element.milestoneDate.length > 0 ? recentMilestoneModal(element.fileNumber, $event) : null">
            <div tabindex="0" *ngIf="element.milestoneDate.length > 0"
              [attr.aria-label]="element.milestoneDesc + ' on ' + dateConversionService.convertToDisplayDate(element.milestoneDate)">
              <p class="link-color" aria-hidden="true"
                [innerHTML]="element.milestoneDesc | highlightSearch: filterText">
                {{ element.milestoneDesc | highlightSearch: filterText }}
              </p>
              <p class="link-color"
                [innerHTML]="dateConversionService.convertToDisplayDate(element.milestoneDate) | highlightSearch: filterText">
                {{ dateConversionService.convertToDisplayDate(element.milestoneDate) | highlightSearch: filterText }}
              </p>
            </div>
            <span *ngIf="element.milestoneDate == 0"> N/A
            </span>
          </div>

          <!-- Total Cost Column -->
          <div *ngIf="column.field === 'totalCost'" [ngClass]="{ 'clickable' : element.totalCost !== 0 }" tabindex="0"
            class="total-cost"
            (click)="recentTransactionModal(element, $event)" (keypress)="recentTransactionModal(element, $event)">
            <ng-container *ngIf="element.totalCost !== 0; else totalCostDisable">
              <span class="align-right-totalcost">
                <a [innerHTML]="element.totalCost">{{ element.totalCost }}</a></span>
            </ng-container>
            <ng-template #totalCostDisable>
              <span class="align-right-totalcost text-link-color" [innerHTML]="element.totalCost">
                {{ element.totalCost }}
              </span>
            </ng-template>
          </div>

          <!-- Move Risk Status -->
          <div *ngIf="column.field === 'moveAtRisk'" class = "moveRisk">
            <app-move-risk [moveRiskData]="combineMoveRiskData(element)"></app-move-risk>
          </div>

        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div fxLayout="row" class="table-footer">
    <div fxFlex="0 0 auto" fxLayoutAlign="start center">
      <app-download-menu-button [reportName]="employeeReport" [reportListColName]="displayedColumns"></app-download-menu-button>
    </div>
    <div fxFlex="64%"></div>
    <div fxFlex>
      <mat-paginator [pageSizeOptions]="[25, 50, 75]" (page)="onPageChange($event)"></mat-paginator>
    </div>
  </div>
</div>

<h2 mat-dialog-title>Modify Location</h2>
<mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="space-between" class="addcandidate-toppadding" class="location-details"
        fxLayout.xs="column" fxLayoutGap.xs="0px">
        <address-form [addressForm]="departureForm" [isDestination]="false" [countryList]="countryList"></address-form>
        <div fxFlex="5%" fxShow="true" class="chevron" fxHide.xs fxLayoutAlign="center center">
            <mat-icon class="material-icons modal-rightnavarrow">chevron_right</mat-icon>
        </div>
        <address-form [addressForm]="destinationForm" [isDestination]="true" [countryList]="countryList"></address-form>
    </div>
</mat-dialog-content>


<!-- pop up action buttons start -->
<mat-dialog-actions class="m-b-0" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px" fxLayoutGap.xs="0px"
    fxLayoutAlign.xs="space-between center">
    <button fxFlex="15%" type="button" class="mat-button text-button" (click)="cancel()" color="primary"
        id="cancel">Cancel</button>
    <button fxFlex="15%" class="mat-button save-btn" type="button" (click)="onSubmit()">Save</button>
</mat-dialog-actions>
<!-- pop up action buttons end -->
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { PowerBiReportService } from '../../services/powerbi-report.service';
import { PowerBiReportConfig } from '../../models/powerbi-report-config.model';
import * as pbi from 'powerbi-models';
import { MatDialog } from '@angular/material/dialog';
import { NgxPowerBiService } from 'ngx-powerbi';
import { PowerBiVisualConfig } from '../../models/powerbi-visual-config.model';
import { PowerBiModalComponent } from '../powerbi-modal/powerbi-modal.component';
import { models } from 'mongoose';
import { UserPreferencesService } from '../../services/user-preferences.service';

/** This component renders a Power BI report */
@Component({
  selector: 'app-powerbi-report',
  templateUrl: './powerbi-report.component.html',
  styleUrls: ['./powerbi-report.component.scss']
})
export class PowerBiReportComponent implements OnInit {
  /** Power BI configuration */
  @Input() config: PowerBiReportConfig | PowerBiVisualConfig;

  /** Dom element to render Power BI report inside */
  @ViewChild('reportElement', { static: true }) reportElement: HTMLDivElement;

  /** Dom element to render Power BI report inside */
  @ViewChild('imgElement', { static: true }) imgElement: HTMLImageElement;

  /** Whether the component is rendering in mocked mode */
  public mockedMode: boolean;

  /** In mocked mode we render an image instead */
  public mockedModeImgUrl: string;

  /** Power BI service */
  private powerBiService: NgxPowerBiService;

  /** Power BI HTML element unique id */
  public elementId: string;

  preferredCurr : any = "";
  currencyList: string[] = [];

  /** Base constructor method
   * @param dialog MatDialog injection
   * @param PowerBiReportService PowerBiReportService injection
   */
  constructor(
    private readonly powerBiReportsSvc: PowerBiReportService,
    public dialog: MatDialog,
    private readonly userPreferenceService: UserPreferencesService,
  ) {
    this.powerBiService = new NgxPowerBiService();
  }

  /** Component Angular initialization lifecycle hook */
  ngOnInit() {
    if (this.config) {
      this.elementId = this.config.type + this.config.groupId + this.config.reportId;
      this.userPreferenceService.getPreference('user_config', false)
      .subscribe((response) => {
        if (response) {
          response.forEach((item) => {
            if (item.preferenceconfig.name == "preferredCurrency") {
              item.preferenceconfig.options.forEach(val => item.preferencesetting && val.currencyAbbr !== item.preferencesetting.value ? this.currencyList.push(val.currencyName) :null);
              if (item.preferencesetting && item.preferencesetting.value.length !== 0) {
                this.preferredCurr = item.preferenceconfig.options.filter(curr => curr.currencyAbbr === item.preferencesetting.value)[0].currencyName
              }
              this.powerBiReportsSvc.setEmbedToken(this.config).toPromise().then((config: any) => {
                const filter : pbi.IBasicFilter = {
                  $schema: 'http://powerbi.com/product/schema#basic',
                  target: {
                    table: "Currency Selection",
                    column: "CurrencyName"
                  },
                  filterType: 1,
                  operator: 'In',
                  values: [this.preferredCurr],
                  requireSingleSelection: false
                };
                if (this.preferredCurr && this.preferredCurr.length !== 0) { 
                  config.slicers  = [{
                    selector: {
                      $schema: "http://powerbi.com/product/schema#visualSelector",
                      visualName : "68a697079451c550e681", 
                    },
                    state: {
                      filters: [filter]
                    }
                  }]; 
                  config.filters = [filter]
                } else {
                  if (config.slicers && config.slicers.length > 0) { delete config['slicers'] };
                  if (config.filters && config.filters.length > 0) { delete config['filters'] };
                }
                
                if (config.accessToken) {
                  if (config.accessToken.indexOf('.jpg') === -1) {
                    this.mockedMode = false;
                    this.powerBiService.embed(<HTMLElement>document.getElementById(this.elementId), config);
                  } else {
                    this.mockedMode = true;
                    this.mockedModeImgUrl = '../../../assets/images/' + config.accessToken;
                  }
                }
              });
            }
          });
        }
      });

      
    }
  }

  openFullscreen() {
    const filter: pbi.IBasicFilter = {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: "Currency Selection",
        column: "CurrencyName"
      },
      filterType: 1,
      //logicalOperator: "And",
      //conditions: [
        //{
          operator: "In",
          values: [this.preferredCurr],
        //}
      //],
    };
    if (this.preferredCurr && this.preferredCurr.length != 0) {
      this.config.fullscreenConfig.slicers = [
        {
          selector: {
            $schema: "http://powerbi.com/product/schema#visualSelector",
            visualName: "68a697079451c550e681",
          },
          state: {
            filters: [filter]
          }
        }
      ]
    }
    else {
      if (this.config.fullscreenConfig.slicers && this.config.fullscreenConfig.slicers.length > 0) { delete this.config.fullscreenConfig['slicers'] }
    };
    //this.preferredCurr && this.preferredCurr.length != 0 ? this.config.fullscreenConfig.filters = [filter] : (this.config.fullscreenConfig.filters && this.config.fullscreenConfig.filters.length > 0 ? delete this.config.fullscreenConfig['filters'] : null);
    if (this.config.fullscreenConfig) {
      this.dialog.open(PowerBiModalComponent, {
        data: this.config.fullscreenConfig,
        autoFocus: false,
        closeOnNavigation: true,
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%'
      });
    }
  }
}
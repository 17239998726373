import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { PrivacyStatementComponent } from './components/privacy-statement/privacy-statement.component';
import { TermsPrivacyRoutingModule } from './terms-privacy-routing.module';

@NgModule({
  declarations: [
      TermsOfUseComponent,
      PrivacyStatementComponent
  ],
  imports: [
    CommonModule,
    TermsPrivacyRoutingModule
  ]
})
export class TermsPrivacyModule { }

<div class="content" aria-live="assertive" *ngIf="employee$ | async as employee">
  <app-breadcrumb></app-breadcrumb>
  <app-employee-header></app-employee-header>
  <div fxLayout="row" class="dataSpacing" tabindex="0">
    <span fxFlex.gt-sm="13" fxFlex.lt-md="25" fxLayoutAlign="end center" class="smallText">Move File: </span>
    <span fxFlex class="data" >{{employee.fileNumber}}</span>
  </div>
  <div fxLayout="row" class="dataSpacing" tabindex="0">
    <span fxFlex.gt-sm="13" fxFlex.lt-md="25" fxLayoutAlign="end center" class="smallText">Status: </span>
    <span fxFlex class="data">{{employee.fileStatusDesc}}</span>
  </div>
  <mat-expansion-panel class="movePhase-panel" [disabled]="!employee.movePhase || !employee.movePhase.movePhaseId || employee.movePhase.movePhaseId==''">
    <mat-expansion-panel-header>
      <div fxLayout="row" fxFill>
        <div class="movePhaseLabel" fxFlex.gt-md="15" fxFlex.lt-md="35">
          <span>Move Phase:</span>
        </div>
        <div>
          <app-multi-step matTooltip="Current Move Phase is {{employee.movePhase.movePhaseId}} of 5: {{employee.movePhase.movePhaseDesc}}" [steps]="5" [selected]="employee.movePhase.movePhaseId" *ngIf="employee.movePhase && employee.movePhase.movePhaseId && employee.movePhase.movePhaseId!=''"></app-multi-step>
          <span class="move-phase-na" *ngIf="!employee.movePhase || !employee.movePhase.movePhaseId || employee.movePhase.movePhaseId==''" matTooltip="Move Phase is Not Applicable">N/A</span>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div class="move-phase">
      <app-linked-list [dataSet]="empMovePhases" listStyleClasses="full-width"
        [columnStyleClasses]="['', '', 'align-right']"></app-linked-list>
    </div>
  </mat-expansion-panel>

  <mat-divider></mat-divider>

  <div fxLayout="row" class="dataSpacing" tabindex="0">
    <div fxFlex.gt-sm="13" fxFlex.lt-md="25" fxLayoutAlign="end">
      <div>
        <span role="main" aria-label="Move details" class="smallText">Move Details:</span>
      </div>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="start">
      <div fxFlex="0 1 auto">
        <div><span class="smallItalics">Departure</span></div>
        <div><span class="data">{{employee.departureCountry}}</span></div>
        <div><span class="data">{{employee.departureCity}}<ng-container *ngIf="!!employee.departureState">,
              {{employee.departureState}}</ng-container></span></div>
      </div>
      <div fxFlex="0 1 auto" class="moveToIcon">
        <mat-icon aria-label="travel to">chevron_right</mat-icon>
      </div>
      <div fxFlex="0 1 auto" aria-live="polite" aria-atomic="true">
        <div><span class="smallItalics">Destination</span></div>
        <div><span class="data">{{employee.destinationCountry}}</span></div>
        <div><span class="data">{{employee.destinationCity}}<ng-container *ngIf="!!employee.destinationState">,
              {{employee.destinationState}}</ng-container></span></div>
      </div>
      <div fxFlex></div>
    </div>
  </div>
  <div aria-live="polite">
    <div class="dataSpacing" tabindex="0">
      <span fxFlex.gt-sm="13" fxFlex.lt-md="25" fxLayoutAlign="end" fxFlexAlign="center" class="smallText">People Moving:</span>
      <span fxFlex class="data">{{employee.accompanyingMemberCount}}</span>
    </div>
    <div class="dataSpacing" tabindex="0">
      <span fxFlex.gt-sm="13" fxFlex.lt-md="25" fxLayoutAlign="end" fxFlexAlign="center" class="smallText">Policy:</span>
      <span fxFlex class="data">{{employee.policyName}}</span>
    </div>
    <div class="dataSpacing" tabindex="0">
      <span fxFlex.gt-sm="13" fxFlex.lt-md="25" fxLayoutAlign="end" fxFlexAlign="center" class="smallText">Move Type:</span>
      <span fxFlex class="data">{{employee.moveTypeDesc}}</span>
    </div>
    <div class="dataSpacing">
      <div fxLayout="row" fxLayoutAlign="space-between" tabindex="0">
        <span fxFlex.gt-sm="13" fxFlex.lt-md="25" fxLayoutAlign="end" fxFlexAlign="center" class="smallText">Consultant:</span>
        <span fxFlex class="data">{{employee.consultant.consFirstName}} {{employee.consultant.consLastName}}</span>
      </div>
      <div fxLayout="row" tabindex="0">
        <span fxFlex.gt-sm="13" fxFlex.lt-md="25" fxLayoutAlign="end" fxFlexAlign="center" class="smallText">Phone:</span>
        <span fxFlex
          class="data">{{employee.consultant && employee.consultant.consultantPhone && employee.consultant.consultantPhone.length > 0 ? employee.consultant.consultantPhone[0].consPhoneDialingCode : ''}}{{employee.consultant && employee.consultant.consultantPhone && employee.consultant.consultantPhone.length > 0 ? employee.consultant.consultantPhone[0].consPhoneNumber : ''}}</span>
      </div>
      <div fxLayout="row" tabindex="0">
        <span fxFlex.gt-sm="13" fxFlex.lt-md="25" fxLayoutAlign="end" fxFlexAlign="center" class="smallText">Email:</span>
        <span fxFlex class="data"><a *ngIf="employee.consultant && employee.consultant.consultantEmail"
            href="mailto:{{employee.consultant.consultantEmail[0].consEmailAddr}}">{{employee.consultant.consultantEmail[0].consEmailAddr}}</a></span>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="provider-info-panel" *ngIf="providerCount > 0" aria-live="polite">
    <div fxLayout="row" fxFill>
      <div fxFlex.gt-sm="85" fxFlex.lt-md="65" tabindex="0">
        <div fxFlex.gt-sm="15" fxFlex.lt-md="35">
          <span>Providers: </span>
        </div>
        <div fxFlex fxLayout.lt-md="row">
          <span fxFlex.lt-md="55" class="smallText">{{providerCount}}</span>
        </div>
      </div>
      <div  fxFlex.gt-sm="15" fxFlex.lt-md="35" class="provider-info-action">
          <button class="contained-button" aria-label="View Provider" mat-button (click)="viewProviders()">View</button>
      </div>
    </div>
  </div>
  <mat-accordion>
    <mat-expansion-panel id="milestone-panel" aria-live="polite" [expanded]="milestonePanelState" *ngIf="this.milestonesData && this.milestonesData.length > 0">
        <mat-expansion-panel-header>
          <div fxLayout="row" fxFill>
            <div fxFlex.gt-sm="15" fxFlex.lt-md="35">
              <span>Milestones: </span>
            </div>
            <div fxFlex fxLayout.lt-md="row" class="panel-info-header">
              <span fxFlex.lt-md="55"
                class="smallText">{{this.milestonesData[0].columns[0].label}}:</span>
              <span fxFlex.lt-md> {{ this.milestonesData[0].value }}</span>
              <span fxFlex.lt-md class="smallText" *ngIf="this.milestonesData[0].columns[0].subLabel">
                {{this.milestonesData[0].columns[0].subLabel}} </span>
            </div>
          </div>
        </mat-expansion-panel-header>
        <app-linked-list [dataSet]="milestonesData"></app-linked-list>
      </mat-expansion-panel>
        <mat-expansion-panel id="sentiment-panel" aria-live="polite" [expanded]="sentimentPanelState" *ngIf="this.sentimentData && this.sentimentData.length > 0">
            <mat-expansion-panel-header>
              <div fxLayout="row" fxFill>
                <div fxFlex.gt-sm="15" fxFlex.lt-md="35">
                  <span>Sentiment: </span>
                </div>
                <div fxFlex fxLayout.lt-md="row" class="panel-info-header">
                  <span fxFlex.lt-md="55"
                    class="smallText">{{this.sentimentData[0].columns[0].label}}</span>
                  <span fxFlex.lt-md class='rating-wrap'>
                      <star-rating [rating]="this.sentimentData[0].columns[1].label" [readOnly]="true" [starType]="'svg'"></star-rating>
                  </span>
                </div>
              </div>
            </mat-expansion-panel-header>
            <app-linked-list [dataSet]="sentimentData"></app-linked-list>
          </mat-expansion-panel>
      </mat-accordion>
  </div>

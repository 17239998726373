import { Component, Inject, OnInit } from '@angular/core';
import { CandidateProfileService } from '../../../services/candidate-profile.service';
@Component({
  selector: 'app-reference-information',
  templateUrl: './reference-information.component.html',
  styleUrls: ['./reference-information.component.scss']
})
export class ReferenceInformationComponent implements OnInit {
   /** Object to store candidate profile details */
   data:any;
  custFileId: any;
  constructor(private candidatecandidateProfileServiceProfile:CandidateProfileService) {
    this.custFileId=this.data;
   }

  ngOnInit() {
    this.candidatecandidateProfileServiceProfile.UnprocessReferenceData$.subscribe(data => {
      if(data) {
        this.data =JSON.parse(JSON.stringify(data));
      }})
  }
}


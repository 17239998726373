import { Component, Inject,  Input,  OnDestroy,  OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialog} from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CustomdataDeleteConfirmComponent } from '../customdata-delete-confirm/customdata-delete-confirm.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserSessionService } from '../../../core/services/user-session.service';
import * as moment from 'moment';
import { UserType, UserTypeService } from '../../administration/services/user-type.service';
import { formatDate } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigurableFieldService } from '../../administration/services/configurable-field.service';
@Component({
  selector: 'app-custom-client-modal',
  templateUrl: './custom-client-modal.component.html',
  styleUrls: ['./custom-client-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomClientModalComponent implements OnInit, OnDestroy {
  constructor(@Inject(MAT_DIALOG_DATA) public data,
    private _formbuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef < CustomClientModalComponent > ,
    private userTypeService: UserTypeService,
    private readonly spinner: NgxSpinnerService,
    private userSessionService: UserSessionService,
    private configurableFieldService: ConfigurableFieldService) {


  }

  moment: any = moment;
  searchValue: string;
  subtask: boolean;
  isAuthorizationRequired: boolean = false;
  customData: UntypedFormGroup;
  customElementType: string = "";
  autocompletearray = []
  CustomDataArr: any = [];
  showForm: boolean = false;
  cardId = 1;
  expireitems: boolean = false;
  updateData: boolean = false;
  minimum: Date = new Date();
  enableChange = false;
  filteredoptions: Observable < string[] > ;
  selectlistitems: any = [];
  selectArray = [];
  userRole: UserType;
  atlasAssocMappingData: any = [];
  configurableFieldsList = [];
  manageCustomDataCapability: string;
  capabilitySub : Subscription;

  ngOnInit() {
    this.getCapabilities();
    /*Initializing the form group */
    this.userRole = this.userTypeService.userType;
    this.customData = this._formbuilder.group({
      type: new UntypedFormControl(''),
      customDataForm: this._formbuilder.array([]),
    })


    if (this.data.configurableFieldsList) {
      this.configurableFieldsList = this.data.configurableFieldsList.sort((a, b) => a.displayName.localeCompare(b.displayName));
      this.getCustomFieldsToBeDisplayed(this.configurableFieldsList);
    } else {
      this.configurableFieldService.getConfigurableFields(this.data.clientPartyId, true, true).subscribe(data => {
        if (data && data.length > 0) {
          this.configurableFieldsList = data.sort((a, b) => a.displayName.localeCompare(b.displayName));
          this.getCustomFieldsToBeDisplayed(this.configurableFieldsList);
        } else {
          this.configurableFieldsList = [];
        }
      });
    }
    if (this.CustomDataArr.length == 0 && this.userRole === 'client-account-manager') {
      this.addCustomFields()
    }
  }

  getCapabilities() {
   this.capabilitySub = this.userTypeService.capabilities$.subscribe(ele => {
      if (ele) {
        ele.forEach(element => {
          if(element.name == "Manage Custom Data Elements" && element.permission == "allow") {
            element.operation == 'read' ? this.manageCustomDataCapability = 'read' :
            element.operation == 'write' ? this.manageCustomDataCapability = 'write' : null;
          }
        });
      }
    });
  }

  getCustomFieldsToBeDisplayed(configurableFields) {
    this.CustomDataArr = configurableFields.filter((ele) => ele.expirationDate ? formatDate(new Date(ele.expirationDate), 'yyyy-MM-dd', 'en_US') >= formatDate(new Date(), 'yyyy-MM-dd', 'en_US') : true)

    /*Extracting DropDownValues from a json file.so when the type is SingleSelect, we populate these DropDownValues in the dialog popup.*/
    configurableFields.forEach((item) => {
      this.selectlistitems.push(item['valueList']);
    });
  }

  /*This method takes a big single array and chunks into array of arrays for a better view of DropDownValues in dialog popup. */
  slectlistChunkArray(_array, len) {
    const chunkedarr = [];
    let i = 0;
    while (i < _array.length) {
      chunkedarr.push(_array.slice(i, i + len));
      i += len;
    }
    return chunkedarr;
  }

  //Helps to extract the data that is typed inside Atlas input field
  atlasvalue() {
    this.atlasAssocMappingData = [];
    this.userSessionService.getAtlasAssociation(this.data.clientNo).subscribe((atlasvalue: any) => {
      if (atlasvalue && atlasvalue.length > 0 && atlasvalue.length !== this.autocompletearray.length) {
        atlasvalue = atlasvalue.sort((a, b) => a.configurableFieldLabel.localeCompare(b.configurableFieldLabel));
        this.atlasAssocMappingData = atlasvalue;
        this.autocompletearray.length == 0 ?
          atlasvalue.forEach(element => {
            if (this.configurableFieldsList.findIndex(data => data.displayName === element.configurableFieldLabel) === -1) {
              this.autocompletearray.push(element.configurableFieldLabel);
            }
          }) : null;
        this.data.configurableFieldsList = this.data.configurableFieldsList ?  this.data.configurableFieldsList.sort((a, b) => a.displayName.localeCompare(b.displayName)) : [];        
        this.filteredoptions = (this.customData && this.customData.get('customDataForm') as UntypedFormArray).at(0).get('atlasAssociation').valueChanges.pipe(startWith(''), map((value: string) => this.autocomplete(value)))
      }
    });
  }

  //Helps to match the value typed inside the Atlas input field with the Array(Array contains list of defined Atlas names.)
  autocomplete(value: string): string[] {
    const filtervalue = value.toLowerCase();
    return this.autocompletearray.filter(option => option.toLowerCase().indexOf(filtervalue) === 0);
  }

  //Helps to Search with the name typed inside the Search filed with the list of custom data available.
  filter(query: string) {
    if (this.expireitems) {
      this.CustomDataArr = this.configurableFieldsList.filter(customerdata => (customerdata.displayName.toLowerCase()).includes(query.toLowerCase()))
    } else {
      this.CustomDataArr = this.configurableFieldsList.filter(customerdata => {
        if (customerdata.expirationDate)
          return formatDate(new Date(customerdata.expirationDate), 'yyyy-MM-dd', 'en_US') >= formatDate(new Date(), 'yyyy-MM-dd', 'en_US') &&
            customerdata.displayName.toLowerCase().includes(query.toLowerCase());
        else
          return customerdata.displayName.toLowerCase().includes(query.toLowerCase());
      })
    }
  }

  //Helps to clear the value typed inside the Search field
  clearTextSearch() {
    this.searchValue = '';
    this.filter(this.searchValue)
  }

  /*This Helps to work with FormArray */
  addCustomFields() {
    this.showForm = true;
    this.minimumexpirationDate = new Date(new Date().getTime() + (3600 * 1000 * 24)); 
    this.selectArray = [];
    this.customElementType = '';
    this.customData = this._formbuilder.group({
      customDataForm: this._formbuilder.array([])
    })
    this.customDataForm.push(
      this._formbuilder.group({
        atlasAssociation: ['', [this.autocompleteStringValidator(this.autocompletearray), Validators.required]],
        activationDate: new Date(),
        expirationDate: null,
        isAuthorizationRequired: false,
        _id: '',
        type: ''
      }));
    this.atlasvalue();
  }
  /** while adding new data get type wrt association */
  onChangeAtlasAssociation(value) {
    const val = this.atlasAssocMappingData.filter(data => {
      return data.configurableFieldLabel === value.controls.atlasAssociation.value ? value.controls.atlasAssociation.value : null;
    });
    this.selectArray = [];
    this.customElementType = "";
    value.controls.type.patchValue(val[0].configurableFieldTypDescr);
    if (val[0].valueList && val[0].valueList.length > 0) {
      this.customElementType = "DropDown";
      val[0].valueList.forEach(ele => {
        this.selectArray.push(ele.DropDownValue);
      });
    }
  }

  autocompleteStringValidator(validOptions: Array < string > ): ValidatorFn {
    return (control: AbstractControl): {
      [key: string]: any
    } | null => {
      if (validOptions.indexOf(control.value) !== -1) {
        return null /* valid option selected */
      }
      return {
        'invalidAutocompleteString': {
          value: control.value
        }
      }
    }
  }

  /*The getter method helps to get the controls of formgroup */
  get customDataForm() {
    return this.customData.get('customDataForm') as UntypedFormArray;
  }


  /*Setting Minimum value for Expiration Date datepicker.*/
  minimumexpirationDate = new Date(new Date().getTime() + (3600 * 1000 * 24)); 
  changeDate(event) {
    this.customData.get(['customDataForm', 0, 'expirationDate']).setValue(null);
    this.minimumexpirationDate = formatDate(new Date(event.value), 'yyyy-MM-dd', 'en_US') >= formatDate(new Date(), 'yyyy-MM-dd', 'en_US') ? new Date(new Date(event.value).getTime() + (3600 * 1000 * 24)) : new Date(new Date().getTime() + (3600 * 1000 * 24));
  }


  /*Toggling the flag to hide the elements in the ui. */
  updateAllComplete() {
    this.subtask != this.subtask
  }


  /*Remove the form array from UI upon clicking the close icon */
  discardCard(index: number) {
    this.enableChange = false;
    this.showForm = false;
    if (this.CustomDataArr.length >= 0 && this.selectItemIndex > -1 && this.selectItemObject._id) {
      this.CustomDataArr.splice(this.selectItemIndex, 0, this.selectItemObject);
      this.selectItemObject = {};
    }
    if (this.customDataForm.length >= 1) {
      this.customDataForm.removeAt(index);
    }
  }

  /*Save the data that is typed inside the form in dialog popup */
  saveData(index: number) {
    this.showForm = false;
    this.enableChange = false;
    let dataObj: any = {
      clientPartyId: this.data.clientPartyId,
      displayName: this.customData.value.customDataForm[0].atlasAssociation,
      activationDate: moment(this.customData.value.customDataForm[0].activationDate).format('YYYY-MM-DD'),
      isAuthorizationRequired: this.customData.value.customDataForm[0].isAuthorizationRequired,
    }
    if (this.customData.value.customDataForm[0].expirationDate) {
      dataObj = {
        ...dataObj,
        expirationDate: moment(this.customData.value.customDataForm[0].expirationDate).format('YYYY-MM-DD') //this.customData.value.customDataForm[0].expirationDate
      };
    }
    let position = -1
    this.configurableFieldsList ? position = this.configurableFieldsList.findIndex(customer => customer._id === this.customData.value.customDataForm[0]._id) : this.configurableFieldsList = [];
    if (position == -1) {
      this.userSessionService.addCustomData(dataObj).subscribe(res => {
        this.autocompletearray = this.autocompletearray.filter(ele => ele !== this.customData.value.customDataForm[0].atlasAssociation);
        this.CustomDataArr.push(res.customData);        
        this.configurableFieldsList.findIndex(ele => ele._id == res.customData._id) === -1 ? this.configurableFieldsList.push(res.customData) : null;
        this.CustomDataArr.sort((a, b) => a.displayName.localeCompare(b.displayName));
        this.customDataForm.removeAt(index);
        this.customData = this._formbuilder.group({
          customDataForm: this._formbuilder.array([])
        })
      });
    } else {
      let reqObj: any = {
        clientPartyId: this.data.clientPartyId
      };
      this.updateData = true;
      this.CustomDataArr = [...this.configurableFieldsList];
      let expiryDate = (this.customData.value.customDataForm[0].expirationDate == null) ? null :  new Date((this.customData.value.customDataForm[0].expirationDate).getTime() + (3600 * 1000 * 24)).toISOString();
      if ((moment(this.CustomDataArr[position].expirationDate).format('YYYY-MM-DD') !== moment((this.customData.value.customDataForm[0].expirationDate)).format('YYYY-MM-DD')) && this.customData.value.customDataForm[0].hasOwnProperty('expirationDate') || moment(this.CustomDataArr[position].expirationDate)) {
        reqObj = {
          ...reqObj,
          expirationDate: expiryDate
        };
        this.CustomDataArr[position].expirationDate = (this.customData.value.customDataForm[0].expirationDate == null) ? null : moment(this.customData.value.customDataForm[0].expirationDate).format('YYYY-MM-DD') //new Date((this.customData.value.customDataForm[0].expirationDate).toString())
      }
      if (this.CustomDataArr[position].isAuthorizationRequired !== this.customData.value.customDataForm[0].isAuthorizationRequired && this.customData.value.customDataForm[0].hasOwnProperty('isAuthorizationRequired') && this.customData.value.customDataForm[0].isAuthorizationRequired !== null) {
        reqObj = {
          ...reqObj,
          isAuthorizationRequired: this.customData.value.customDataForm[0].isAuthorizationRequired
        };
        this.CustomDataArr[position].isAuthorizationRequired = this.customData.value.customDataForm[0].isAuthorizationRequired
      }
      if (reqObj.hasOwnProperty('isAuthorizationRequired') || reqObj.hasOwnProperty('expirationDate')) {
        this.userSessionService.updateCustomData(reqObj, this.CustomDataArr[position]._id).subscribe(res => {
          this.afterUpdate();
        });
      } else {
        this.afterUpdate();
      }

    }
  }
  selectItemIndex: number = -1;
  selectItemObject: any;

  /**
   * Actions to be performed after updating a configurable field
   */
  afterUpdate() {
    this.customData = this._formbuilder.group({
      customDataForm: this._formbuilder.array([])
    });
    this.searchValue = '';
    this.updateData = false;
    if (this.configurableFieldsList) {
      this.CustomDataArr = this.configurableFieldsList.filter(ele => ele.expirationDate ? formatDate(new Date(ele.expirationDate), 'yyyy-MM-dd', 'en_US') >= formatDate(new Date(), 'yyyy-MM-dd', 'en_US') : true)
    }
  }


  /*Helps to edit the data that was saved earlier */
  changeData(data) {
    this.showForm = true;
    this.enableChange = true;
    this.minimumexpirationDate = formatDate(new Date(data.activationDate), 'yyyy-MM-dd', 'en_US') > formatDate(new Date(), 'yyyy-MM-dd', 'en_US') ? new Date(new Date(data.activationDate).getTime() + (3600 * 1000 * 24)) : new Date();

    data.valueList && data.valueList.length > 0 ? this.customElementType = "DropDown" : this.customElementType = "";
    this.customData = this._formbuilder.group({
      customDataForm: this._formbuilder.array([
        this._formbuilder.group({
          atlasAssociation: [data.displayName, Validators.required],
          activationDate: this.setDateWithOffset(data.activationDate),
          expirationDate: data.expirationDate ? this.setDateWithOffset(data.expirationDate) : null,
          isAuthorizationRequired: data.isAuthorizationRequired,
          _id: data._id,
          type: data.type
        })
      ]),
    });

    data.valueList ? this.selectArray = data.valueList : null;
    this.selectItemIndex = this.CustomDataArr.findIndex(customer => customer._id === data._id);
    this.CustomDataArr = this.configurableFieldsList.filter(customer => customer._id !== data._id &&
      (customer.expirationDate ? formatDate(new Date(customer.expirationDate),
        'yyyy-MM-dd', 'en_US') >= formatDate(new Date(), 'yyyy-MM-dd', 'en_US') : true));
    this.selectItemObject = {
      ...data,
      atlasAssociation: data.displayName,
      activationDate: this.setDateWithOffset(data.activationDate),
      expirationDate: data.expirationDate ? this.setDateWithOffset(data.expirationDate) : null,
      isAuthorizationRequired: data.isAuthorizationRequired,
      id: data._id,
      type: data.type
    }
  }

  /**
   * Adjust date by adding offset for values to be displayed in mat datepicker
   * @param date 
   * @returns 
   */
  setDateWithOffset(date) {
    let newDate = new Date(date);
    newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
    return newDate;
  }

  onDismiss() {    
    this.dialogRef.close(this.data);
  }

  /*Helps to delete the data that was saved earlier(By changing the expiration date to today's date) */
  deleteCustomData(data) {
    let dialogResponse = this.dialog.open(CustomdataDeleteConfirmComponent, {
      data: data
    })
    dialogResponse.afterClosed().subscribe(resp => {
      if(resp == "true"){
        const index = this.CustomDataArr.findIndex(customer => customer._id === data._id);
        let expiryDate = new Date();
        const dataObj = {
          clientPartyId: this.data.clientPartyId,
          expirationDate: expiryDate.toISOString()        
        }
        this.userSessionService.deleteCustomData(dataObj, data._id).subscribe(res => {          
            this.CustomDataArr[index].expirationDate = dataObj.expirationDate;
            // set activation date to todays date if activation date is greater than today
            if (formatDate(new Date(this.CustomDataArr[index].activationDate),
                'yyyy-MM-dd', 'en_US') > formatDate(new Date(), 'yyyy-MM-dd', 'en_US')) {              
              this.CustomDataArr[index].activationDate = this.setDateToUTC(new Date());
            }
            this.getCustomFieldsToBeDisplayed(this.CustomDataArr);         
        });    
      }  
    })
  }

  setDateToUTC(date:Date){    
    return date.toISOString().split('T')[0].concat('T00:00:00.000Z');
  }

  getExpiredData() {
    this.expireitems = !this.expireitems;
    this.filter(this.searchValue || '');
    // if (this.expireitems) {
    //   this.CustomDataArr = this.configurableFieldsList;
    // } else {
    //   this.CustomDataArr = this.configurableFieldsList.filter(ele => ele.expirationDate ? formatDate(new Date(ele.expirationDate), 'yyyy-MM-dd', 'en_US') > formatDate(new Date(), 'yyyy-MM-dd', 'en_US') : true)

    // }
  }

  isExpired(customData) {
    return customData.expirationDate ? formatDate(new Date(customData.expirationDate), 'yyyy-MM-dd', 'en_US') < formatDate(new Date(), 'yyyy-MM-dd', 'en_US') : false
  }

  ngOnDestroy(): void {
    this.capabilitySub ? this.capabilitySub.unsubscribe() : null;
  }
}

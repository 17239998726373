<div class="dialog-container" role="region">
    <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px" fxLayoutGap.xs="0em" role="heading">
        <h2 class="divisions-title"> Divisions: {{programName ? programName : ''}}</h2>
        <a class="close-icon" (click)="close()" title="close" role="button" aria-label="Close Program Modal" title="Close Program Modal">
            <mat-icon>close</mat-icon>
        </a>
    </div>
    <mat-dialog-content class="divisions-block">
        <span class="divisios-title">Divisions:</span>
        <div class="toggle-division">
            <span [ngClass]="{'selected-only': allSelect === true}">Show All</span> <mat-slide-toggle class="toggle-class" [(ngModel)]="allSelect" (change)="onToggle()"  >
                <span [ngClass]="{'selected-only': allSelect === false}"> Show Selected Only</span><div [ngClass]="clearOptions?.length == 0 ? 'selected-count-zero' : 'selected-count'"> <span class="count">{{clearOptions?.length}}</span></div>
            </mat-slide-toggle>
        </div>
        <div class="data-content">
            <div class="search-field" fxLayout.xs="row" fxLayoutAlign.xs="center center" role="group">
                <mat-checkbox class="select-all" [(ngModel)]="checkedAll" (ngModelChange)="modelChangeFn()"></mat-checkbox>
                <mat-form-field class="input-search" appearance="outline">
                    <span matPrefix class="lg-only" role="none">
                        <mat-icon>search</mat-icon>
                    </span>
                    <input matInput tabindex="0" placeholder="Search for options..."  
                        [(ngModel)]="searchValue" 
                        (ngModelChange) = "textSearch()"
                        aria-label="Search for options..." 
                        role="text">
                            <button tabindex="0" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearTextSearch()" *ngIf="searchValue" role="button">
                                <mat-icon>close</mat-icon>
                            </button>
                </mat-form-field>
            </div>
            <div class="options-data">
                <mat-selection-list #divisionList [(ngModel)]="showOptions" >
                    <mat-list-option *ngFor="let cdl of clientDivisionsList" (click)="eventChange(cdl.division_id)" [value]="cdl.division_id">
                        {{cdl.division_name}} ({{cdl.division_number}})
                    </mat-list-option>
                </mat-selection-list>
                <div class="alignment" *ngIf="clientDivisionsList?.length === 0">
                    <span >No Matching Elements Found</span>
                </div>
                
            </div>
        </div>
        
        </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button class="text-button rest-btn" (click)="close()">CANCEL</button>
        <button mat-button class="contained-button" [disabled] = "(showOptions?.length < 1)" (click)='save()'>Save</button>
    </mat-dialog-actions>
</div>

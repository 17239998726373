<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column">
  <div>
    <form fxLayout="column" class="middle-section" [formGroup]="dupCheckForm">
      <fieldset>
        <div class="middle-section">
          <mat-dialog-content>
            <div class="input-row" fxLayout="row" fxLayoutAlign="space-evenly" fxLayout.xs="column" fxLayoutGap.xs="0em">
              <!--Title field-->
              <div fxFlex="10%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                  <mat-label>Salutation</mat-label>
                  <mat-select formControlName="title" placeholder="title" character-only formControlName="title"
                    minLength="1" maxlength="50">
                    <mat-option *ngFor="let title of titleValues" [value]="title">{{title}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <!--First Name field-->
              <div fxFlex="28%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                  <input matInput placeholder="First Name" multi-type-char formControlName="firstName" minLength="1"
                    maxlength="50">
                </mat-form-field>
              </div>
              <!--Last Name field-->
              <div fxFlex="28%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                  <input matInput placeholder="Last Name" multi-type-char formControlName="lastName" minLength="2"
                    maxlength="50">
                </mat-form-field>
              </div>
              <!--Employee # field-->
              <div fxFlex="28%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                  <input matInput placeholder="Employee # (Optional)" formControlName="EmployeeName" minLength="1"
                    maxlength="50">
                </mat-form-field>
              </div>
            </div>
            <div class="input-row" class="addcandidate-toppadding" fxLayout="row" fxLayoutAlign="space-between"
              fxLayout.xs="column" fxLayoutGap.xs="0em">
              <!--Email Address field-->
              <div fxFlex="48%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%" class="emailcontainer m-t-23">
                <mat-form-field fxFlex>
                  <input matInput type="text" placeholder="Email Address" formControlName="email">
                </mat-form-field>
              </div>
              <div fxFlex="48%" fxLayout.xs="row" fxFlex.xs="100%" fxLayout="row" fxFlexFill
                fxLayoutAlign="space-between">
                <div fxFlex="24%" fxFlex.xs="30%" class="m-t-23">
                  <mat-form-field>
                    <mat-label>Phone Code</mat-label>
                    <input type="text" aria-label="Phone Code" matInput #autoCompleteInput
                      formControlName="phoneDialingCode" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" [panelWidth]="293">
                      <mat-option [value]="option.countryDialingCode" *ngFor="let option of filteredOptions | async">
                        {{ option.countryName }} (+ {{ option.countryDialingCode }})
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <div fxFlex="76%" fxFlex.xs="70%" class="international-extn m-t-23">
                  <mat-form-field fxFlex>
                    <input matInput type="text" placeholder="Phone Number" number-only formControlName="phoneNumber"
                      minLength="7" maxlength="18">
                    <mat-hint align="start">Mobile # required to receive text messages</mat-hint>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-dialog-content>
        </div>
      </fieldset>
    </form>
  </div>
  <!--Mat Table starts here-->
  <div>
    <p  class="search-warning">Please review the results listed below to avoid
      duplicate issues.</p>
    <mat-card *ngIf="!showTable" class="no-records">
      <span style="color: black;">No Duplicates Found.</span>
    </mat-card>
    <div *ngIf="showTable" class="search-table-container">
        <mat-card  class="table-list">
          <span style="color: black;">Possible Duplicates Found
            ({{dataSource?.data.length}})</span>
        </mat-card>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 search-table">
        <!-- Name Column -->
        <ng-container matColumnDef="fullName">
          <td mat-cell *matCellDef="let element" style="width:18% !important;">
            <div *ngIf="element.name">
              <span [innerHTML]="element.name[0] | searchHighlight: firstName"></span>,
              <span [innerHTML]="element.name[1] | searchHighlight: lastName"></span> <br>
              <span [innerHTML]="element.empId ? (element.empId | searchHighlight: searchEmp) : '' "></span>
            </div>
          </td>
        </ng-container>
        <!-- email Column -->
        <ng-container matColumnDef="email">
          <td mat-cell *matCellDef="let element" style="width:18% !important;">
            <span
              [innerHTML]="element.emailDetailsList[0]?.usageType === 'business' ? (element.emailDetailsList[0].emailAddress  | searchHighlight: email) : 'N/A' "></span><br>
            +<span
              [innerHTML]="element.phoneDetailsList[0]?.usageType === 'personal' ? (element.phoneDetailsList[0].phoneDialCode | searchHighlight: phoneDialingCode) : 'N/A' "></span>
            <span class="phoneNumber"
              [innerHTML]="element.phoneDetailsList[0]?.usageType === 'personal' ? (element.phoneDetailsList[0].phoneNumber  | searchHighlight: phoneNumber) : 'N/A' "></span>
          </td>
        </ng-container>
        <!-- departure Column -->
        <ng-container matColumnDef="departureAddr">
          <td mat-cell *matCellDef="let element" style="width:30% !important;">
            <div tabindex="0" *ngIf="element.departureAddr">
              <span [innerHTML]="element.departureAddr.country | searchHighlight: searchKeyword"></span><br />
              <span
                [innerHTML]="element.departureAddr.state?(element.departureAddr.state | searchHighlight: searchKeyword ):''"></span>,
              <span
                [innerHTML]="element.departureAddr.city? (element.departureAddr.city | searchHighlight: searchKeyword ): ''"></span>
  
            </div>
          </td>
        </ng-container>
        <!-- destination Column -->
        <ng-container matColumnDef="destinationAddr">
          <td mat-cell *matCellDef="let element" style="width:30% !important;">
            <div tabindex="0" *ngIf="element.destinationAddr">
              <span [innerHTML]="element.destinationAddr.country | searchHighlight: searchKeyword"></span><br />
              <span
                [innerHTML]="element.destinationAddr.state? (element.destinationAddr.state | searchHighlight: searchKeyword) : ''"></span>,
              <span
                [innerHTML]="element.destinationAddr.city? (element.destinationAddr.city | searchHighlight: searchKeyword) : ''">
              </span>
  
            </div>
          </td>
        </ng-container>
        <!--Add move column-->
        <ng-container matColumnDef="add">
          <td mat-cell *matCellDef="let element; let i = index" style="width:4% !important;">
            <button aria-label="Associate Transferee" class="mat-button outlined-button add-move" (click)="onCreate(false, element)">
              Add Move
            </button>
          </td>
        </ng-container>

        <tbody class="table-body">
          <tr style="width:100% !important;" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </tbody>
      </table>
    </div>
    <mat-dialog-actions class="cancleAndCreateNewPerson">
      <div class="statusBtn add-dialogbtn" style="margin-top: 0.5em;" fxLayout="row" fxLayoutAlign="start"
      fxLayoutGap="5px" fxLayoutGap.xs="0px" fxLayoutAlign.xs="space-between center">
      <button fxFlex="15%" type="button" class="mat-button text-button delete-btn" color="primary"
          id="delete" (click)="openDialog()">DELETE</button>
        <!-- changes for CANCEL and New person Button -->
          <div class="statusBtn add-dialogbtn" style="margin-top: 0.5em;" fxLayout="row" fxLayoutAlign="end"
          fxLayoutGap="8px" fxLayoutGap.xs="0px" fxLayoutAlign.xs="space-between center">
        <button fxFlex="18%" type="button" class="mat-button text-button" (click)="onDismiss($event)" color="primary"
          id="cancel">CANCEL</button>
        <button fxFlex="30%" class="mat-button contained-button send-invite" (click)="onCreate(true)">Create New
          Person</button>
        </div>
      </div>
        </mat-dialog-actions>
 
  </div>

</div>

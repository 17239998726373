<div fxLayout="row wrap" class="resction_details_div" *ngIf="parentForm" [formGroup]="parentForm">
    <ng-container formArrayName="values">
        <div fxFlex="100" fxLayoutGap="1.25em" *ngFor="let item of parentForm?.get('values')['controls']; let i = index;let firstItem = first;let lastItem = last;">
            <div fxFlex="70" fxLayoutGap="1.25em" [formGroupName]="i">
                <div fxFlex="50"  class="resction_details_row">
                    <mat-form-field  class="example-form-field">
                        <mat-label>Operator</mat-label>
                        <mat-select disableOptionCentering="true" formControlName="operator" (ngModelChange)="selectedOperatorRes(i)" (openedChange)="matFocusedEvent($event,i,'operator')">
                          <mat-option *ngFor="let item of operatorsList" [disabled]="disableResOption(item)" [value]="item.value">{{item.displayValue}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error *ngIf="lastOperatorNotFound && lastItem">*Please select operator</mat-error>
                </div>
                <div fxFlex="50"  class="resction_details_row">
                    <mat-form-field  class="example-form-field">
                        <mat-label>Number</mat-label>
                        <input type="number" min="0" max="99" matInput formControlName="value" (keydown)="restrictNegativeVal($event)" />
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="20" fxLayoutAlign="start start" class="rules_field resction_details_row">
                <div fxFlex="25" *ngIf="parentForm?.get('values') && parentForm?.get('values').value && parentForm?.get('values').value.length > 1">
                    <div  class="row_delete_icon" fxLayoutAlign="center center">
                        <button class="minus" (click)="removeOperatorRestriction(i)"></button>
                    </div>
                </div>
                <div fxFlex="25" *ngIf="addNewOperatorIsAlwd()">
                    <div *ngIf="lastItem " class="row_delete_icon" fxLayoutAlign="center center">
                        <button class="plus" (click)="addOperatorRestriction()"></button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

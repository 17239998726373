export class FilterSelectionOption {
    public enableSearch: boolean;
    public searchCharLimit: number;
    public searchDelayInMilliSeconds: number;
    public type: string;
    constructor(options: any) {
        this.enableSearch = options['enableSearch'] ? options['enableSearch'] : false;
        this.searchCharLimit = options['searchCharLimit'] ? options['searchCharLimit'] : 0;
        this.searchDelayInMilliSeconds = options['searchDelayInMilliSeconds'] ? options['searchDelayInMilliSeconds'] : 0;
        this.type = options['type'] ? options['type'] : 'default';
    }
}

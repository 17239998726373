import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SearchResult} from '../../core/models/search-result.model';
import { catchError, map } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {

  /** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
   */
  constructor(private baseClient: BaseClientService, private logSvc: RemoteLoggingService) { }

  getResults(q: string): Observable<SearchResult> {
    return this.baseClient
      .getOne<SearchResult>(`v1/globalsearch?searchText=${q}`, 'get the search results')
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: SearchResult = null;
          this.logSvc.logError(err);
          return of(empty);
        })
      );
  }
}


<div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
fxLayoutGap="20px" fxLayoutGap.xs="0em">
<div class="warnig-dialogtitle">Recalculate Points</div>
<span  class="close-icon" (click)="onDismiss($event)" title="close"><mat-icon>close</mat-icon></span> 
</div>
<div tabindex = "0" >
<p class="warning-content">The following details have been updated and can affect the points assigned to the transferee. Any details set to “No” when saved will be discarded and will not included in future recalculations.</p>
<!-- grid for warning-->
<table mat-table [dataSource]="dataSource">
<!-- Details Column -->
<ng-container matColumnDef="details">
  <th mat-header-cell *matHeaderCellDef> Details </th>
  <td mat-cell *matCellDef="let element"> {{element.details}} </td>
</ng-container>
<!--  Prior Value Column -->
<ng-container matColumnDef="recordValue">
  <th mat-header-cell *matHeaderCellDef> Prior Value </th>
  <td mat-cell *matCellDef="let element"> {{element.priorValue}} </td>
</ng-container>  
<!-- Current Value Column -->
<ng-container matColumnDef="searchCriteria">
  <th mat-header-cell *matHeaderCellDef> Current Value </th>
  <td mat-cell *matCellDef="let element"> {{element.searchCriteria}} </td>
</ng-container>  
<!-- overwrite Column -->
<!-- <ng-container matColumnDef="overwrite">
 <th mat-header-cell *matHeaderCellDef> <mat-checkbox class="example-margin" (change)="checkUncheckAll()" [(ngModel)]="isChecked">overwrite All</mat-checkbox>  </th>
 <td mat-cell *matCellDef="let element"><span >Yes<mat-slide-toggle (change)="isAllSelected()" [(ngModel)]="element.isSelected">No</mat-slide-toggle></span>  </td>
</ng-container> -->
<tr mat-header-row *matHeaderRowDef="displayedColumns" class="warnig-dialogtitle-1"></tr>
<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
</div>
<div fxLayout="row" >
    <mat-card fxFlex='30%' class="points-calculate">
        <p style="text-align: center;">Points Assigned</p>
        <p style="text-align: center;font-weight: 600;">{{datas.pointsValue.totalPoints}}</p>
    </mat-card>
    <p fxFlex='20%' role="none"></p>
<button fxFlex='25%' class="cancel-btn" mat-button (click)="onDismiss($event)">Cancel</button>
<button  fxFlex='25%'class="confirm-btn" mat-button (click)="onConfirm()">Save Recalculation</button>

</div>
<!-- <div fxLayout="row" fxLayoutAlign="start start">
    <mat-card>
        <p>points Assigned</p>
        <p>200 </p>
    </mat-card>
</div> -->
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'getPreferredPhone'
})
export class GetPreferredPhonePipe implements PipeTransform {
  transform(value: Array < any > , type: 'phone' | 'email'): Array < any > {
    return value.filter(item => (item.primary === true && (!item.delete || item.delete === false)));
  }

}

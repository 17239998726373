import { Injectable } from '@angular/core';
import { PdfFontsService } from './pdf-fonts.service';
import { formatDate } from '@angular/common';
import { UserTypeService, UserType } from '../services/user-type.service';

@Injectable({
  providedIn: 'root'
})

export class ProgramReportGenerationService {
  programBorderColor = '#2065F8';
  formattedDate;
  /** user role */
  userRole: UserType = this.userTypeService.userType;
  /**
   * @param pdfMakeFonts Inject PDF Fonts service
   */
  constructor(
    private readonly pdfMakeFonts: PdfFontsService,
    private userTypeService: UserTypeService

  ) {
    pdfMakeFonts.defineFonts();
    pdfMakeFonts.assignFonts();
    this.pdfMakeFonts.loadLogos();
  }

  /**
   * Generate Document Definition for PDF to be generated
   * @param programInformation Program Details
   * @param divisions Division Details
   * @param benefitInformation Benefit Details
   * @param clientInfo Client Details
   */
  generateDocumentDefinition(programInformation, divisions, benefitInformation, clientInfo) {
    const documentDefinition = {
      info: { title: 'Program Details', subject: 'Details of programs listed' },
      pageOrientation: 'portrait',
      pageSize: 'LETTER',
      pageMargins: [40, 110, 40, 110],
      header: (currentPage) => {
        return {
          table: {
            headerRows: 1,
            widths: ['50%', '50%'],
            body: [
              [{
                image: this.pdfMakeFonts.bbLogob64, width: 120, height: 40, alignment: 'left',
                color: '#FFFFFF', fillColor: '#FFFFFF', border: [false, false, false, false], margin: [30, 35, 0, 0],
              },
              {
                image: this.pdfMakeFonts.cartusLogob64, width: 120, height: 40, alignment: 'right',
                color: '#FFFFFF', fillColor: '#FFFFFF', border: [false, false, false, false], margin: [0, 35, 20, 0],
              }
              ]
            ]
          },
        };
      },
      footer: (currentPage, pageCount) => {
        return {
          table: {
            headerRows: 1, widths: ['60%', '40%'],
            body: [
              [{
                text: `© ${new Date().getFullYear()} Cartus Corporation | All rights reserved. 
             Cartus and the Cartus logo are registered trademarks of Cartus Corporation
             `, fontSize: 9, color: '#575A5D', alignment: 'left', border: [false, false, false, false],
                margin: [30, 30, 0, 0]
              },
              {
                text: `Page ${currentPage.toString()}`, fontSize: 9, color: '#575A5D',
                alignment: 'right', border: [false, false, false, false], margin: [0, 30, 30, 0]
              }
              ]
            ]
          },
        };
      },
      styles: {
        avenirBook: {
          font: 'AvenirBook',
          normal: true
        },
        avenirLight: {
          font: 'AvenirLight',
          fontSize: 18,
          normal: true
        },
        avenirMedium: {
          font: 'AvenirMedium',
          normal: true
        },
        programTable: {
          border: [true, true, true, true], borderColor: ['#ff00ff', '#00ffff', '#ff00ff', '#00ffff'],
          fillColor: '#2065F8'
        }
      }
    };
    const docDefinition = JSON.parse(JSON.stringify(documentDefinition));
    docDefinition.footer = documentDefinition.footer;
    docDefinition.header = documentDefinition.header;
    const content = [this.generateClientDetails(programInformation, clientInfo), this.generateProgramDetails(programInformation, divisions),
    this.generateBenefits(benefitInformation)];
    docDefinition.content = content;
    if (programInformation.draft) {
      docDefinition.watermark = { text: 'DRAFT', opacity: 0.1, fontSize: 175, angle: 325};
    }
    return docDefinition;
  }


  /**
   * Generate Client Information section
   * @param programInformation program information
   * @param clientInfo client details
   */
  generateClientDetails(programInformation, clientInfo) {
    const currentDate = new Date();
    this.formattedDate = formatDate(currentDate, 'MMMM dd, yyyy', 'en-US');
    const expirationDate = (programInformation.hasOwnProperty('programExpirationDate') && programInformation.programExpirationDate) ?
    formatDate(programInformation.programExpirationDate, 'MMMM dd, yyyy', 'en-US') : 'N/A';

    const clientName = (this.userRole === 'client-contact-initiator') ?
      JSON.parse(sessionStorage.getItem('UserContext')).clientLegalName : clientInfo.clientEntityName;
    const clientNo = (this.userRole === 'client-contact-initiator') ?
      JSON.parse(sessionStorage.getItem('UserContext')).clientNo : clientInfo.clientNumber;


    const clientDetails = {
      table: {
        headerRows: 0, widths: ['*', 'auto'], borderColor: ['#ff00ff', '#00ffff', '#ff00ff', '#00ffff'],
        body: [
          [{
            text: clientName, alignment: 'left', color: '#000000', lineHeight: 0,
            border: [false, false, false, false], fontSize: 9, margin: [0, -10, 0, 15], style: 'avenirBook'
          },
          {
            text: `Generated: ${this.formattedDate}`, alignment: 'right', color: '#000000', lineHeight: 0,
            border: [false, false, false, false], fontSize: 9, margin: [0, -10, 0, 15], style: 'avenirBook'
          }
          ],
          [
            {
              text: `ID #: ${clientNo}`, alignment: 'left', color: '#000000', lineHeight: 0,
              border: [false, false, false, false], fontSize: 9, margin: [0, 0, 0, 15], style: 'avenirBook'
            },
            {
              text: `Program Expiration: ${expirationDate}`, alignment: 'right', color: '#000000', lineHeight: 0,
              border: [false, false, false, false], fontSize: 9, margin: [0, 0, 0, 15], style: 'avenirBook'
            }

          ]
        ]
      }
    };
    return clientDetails;
  }

  numberWithCommas(x: number) : string {
      return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


 /**
  * Generate Program Information section
  * @param programInformation Program Details
  * @param divisions Division Array
  */
 generateProgramDetails(programInformation, divisions) {
   let divisionInfo = '';
   let programType = {};
   let programData = {};
   if (divisions && divisions.length > 0) {
     if (divisions[0].entityName) { // download in program details
       divisions.sort((a, b) => a.entityName.localeCompare(b.entityName));
       divisionInfo = divisions.map((item) => {
         return item.entityName;
       }).join(', ');
     } else {  // download in program list page
         divisions.sort((a, b) => a.division_name.localeCompare(b.division_name));
         divisionInfo = divisions.map((item) => {
           return `${item.division_name} (${item.division_number})`;
         }).join(', ');
     }
   } else {
     divisionInfo = 'None';

    }
    let condition = false;
    condition = programInformation.hasOwnProperty('totalPoints') && programInformation.totalPoints !== undefined ? true : false;
    if(condition) {
      programType = {
        text: `Total Points:`, alignment: 'right', color: '#000000', lineHeight: 1, border: [false, true, false, false],
        borderColor: ['', this.programBorderColor, '', ''], fontSize: 9, margin: [0, 18, 0, 0], style: 'avenirMedium'
      };
      programData = {
        text: programInformation.totalPoints, alignment: 'right', color: '#19305A', lineHeight: 1,
        border: [false, true, true, false], borderColor: ['', this.programBorderColor, this.programBorderColor, ''],
        margin: [0, 10, 0, 0], style: 'avenirLight',
      };
    } else {
      if (programInformation.hasOwnProperty('totalAmount') && programInformation.totalAmount && programInformation.hasOwnProperty('currency') && programInformation.currency) {
        programType = {
          text: `Amount:`, alignment: 'right', color: '#000000', lineHeight: 1, border: [false, true, false, false],
          borderColor: ['', this.programBorderColor, '', ''], fontSize: 9, margin: [0, 18, 0, 0], style: 'avenirMedium'
        };
        programData = {
          text: this.numberWithCommas(programInformation.totalAmount) + ' ' + programInformation.currency, alignment: 'left', color: '#19305A', lineHeight: 1,
          border: [false, true, true, false], borderColor: ['', this.programBorderColor, this.programBorderColor, ''],
          margin: [0, 10, 20, 0], style: 'avenirLight',
        };
      } else {
        programType = {
          text: ``, alignment: 'right', color: '#000000', lineHeight: 1, border: [false, true, false, false],
          borderColor: ['', this.programBorderColor, '', ''], fontSize: 9, margin: [0, 18, 0, 0], style: 'avenirMedium'
        };
        programData = {
          text: '', alignment: 'left', color: '#19305A', lineHeight: 1,
          border: [false, true, true, false], borderColor: ['', this.programBorderColor, this.programBorderColor, ''],
          margin: [0, 10, 20, 0], style: 'avenirLight',
        }
    }
    }


    let programName = programInformation.programName ? programInformation.programName : programInformation.name;
    programName = programName[0].toUpperCase() + programName.slice(1);
    const policyCallStatus = programInformation.policyCallRequired === true ? 'Required' : 'Not Required';
    const initialContactInfo = programInformation.initialContactBy ? programInformation.initialContactBy : 'N/A';
    let programDetails = {
      table: {
        headerRows: 0, widths: ['80%', '10%', '10%'], style: 'programTable',
        body: [
          [
            // Program Name
            {
              text: programName, alignment: 'left', color: '#19305A', lineHeight: 1, margin: [0, 10, 0, 0],
              border: [true, true, false, false], borderColor: [this.programBorderColor, this.programBorderColor, '', ''],
              fontSize: 18, style: 'avenirLight',
            },
            // Total Points Header
            programType,
            // Total points value
            programData
          ],
          [
            // Divisions
            {
              text: [`Division(s): `, { text: `${divisionInfo}`, style: 'avenirBook' }], alignment: 'left', color: '#000000', lineHeight: 1.2,
              border: [true, false, false, false], borderColor: [this.programBorderColor, '', '', ''], fontSize: 11, margin: [0, 10, 0, 5],
              style: 'avenirMedium'
            },
            {
              text: ` `, alignment: 'left', color: '#000000', lineHeight: 1, border: [false, false, false, false],
              fontSize: 9, margin: [0, 10, 0, 5],
            },
            {
              text: ` `, alignment: 'left', color: '#000000', lineHeight: 1, border: [false, false, true, false],
              borderColor: ['', '', this.programBorderColor, ''], fontSize: 9, margin: [0, 10, 0, 5],
            }
          ],
          [
            // Policy Names
            {
              text: [`Policy: `, {
                text: (`${programInformation.atlasPolicy ? programInformation.atlasPolicy : programInformation.clientPolicy}`).split(',').join(' , '),
                style: 'avenirBook'
              }], alignment: 'left', color: '#000000',
              lineHeight: 1.2, border: [true, false, false, false], borderColor: [this.programBorderColor, '', '', this.programBorderColor],
              fontSize: 11, margin: [0, 10, 0, 10], style: 'avenirMedium'
            },
            {
              text: ` `, alignment: 'left', color: '#000000', lineHeight: 1, border: [false, false, false, false],
              borderColor: ['', '', '', this.programBorderColor], fontSize: 9, margin: [0, 10, 0, 10],
            },
            {
              text: ` `, alignment: 'left', color: '#000000', lineHeight: 1, border: [false, false, true, false],
              borderColor: ['', '', this.programBorderColor, ''], fontSize: 9, margin: [0, 10, 0, 5],
            }
          ],
          [
            // Policy Call Status
            {
              text: [`Policy Call Confirm: `, {
                text: `${policyCallStatus}`,
                style: 'avenirBook'
              }], alignment: 'left', color: '#000000',
              lineHeight: 1.2, border: [true, false, false, false], borderColor: [this.programBorderColor, '', '', this.programBorderColor],
              fontSize: 11, margin: [0, 10, 0, 10], style: 'avenirMedium'
            },
            {
              text: ` `, alignment: 'left', color: '#000000', lineHeight: 1, border: [false, false, false, false],
              borderColor: ['', '', '', this.programBorderColor], fontSize: 9, margin: [0, 10, 0, 10],
            },
            {
              text: ` `, alignment: 'left', color: '#000000', lineHeight: 1, border: [false, false, true, false],
              borderColor: ['', '', this.programBorderColor, ''], fontSize: 9, margin: [0, 10, 0, 5],
            }
          ],
          [
            // Initial Contact
            {
              text: [`Initial Contact Required By: `, {
                text: `${initialContactInfo}`,
                style: 'avenirBook'
              }], alignment: 'left', color: '#000000',
              lineHeight: 1.2, border: [true, false, false, true], borderColor: [this.programBorderColor, '', '', this.programBorderColor],
              fontSize: 11, margin: [0, 10, 0, 10], style: 'avenirMedium'
            },
            {
              text: ` `, alignment: 'left', color: '#000000', lineHeight: 1, border: [false, false, false, true],
              borderColor: ['', '', '', this.programBorderColor], fontSize: 9, margin: [0, 10, 0, 10],
            },
            {
              text: ` `, alignment: 'left', color: '#000000', lineHeight: 1, border: [false, false, true, true],
              borderColor: ['', '', this.programBorderColor, this.programBorderColor], fontSize: 9, margin: [0, 10, 0, 10],
            }
          ]
        ]
      },
      layout: {
        hLineWidth: function (i, node) { return 0.75; },
        vLineWidth: function (i, node) { return 0.75; }
      }
    };
    if(!condition) {
      programDetails.table.widths = ['70%', '10%', '25%']
      return programDetails;
    }
    return programDetails;
  }

  /**
   * Generate Move phase header section of PDF
   * @param movePhase move phase values
   */
  generateMovePhaseHeader(movePhase) {
    return {
      unbreakable: true,
      stack: [
      {
      table: {
        widths: ['14%', '86%'],
        body: [
          [{ text: '', margin: [0, 5, 0, 5], border: [false, false, false, false] },
          { text: '', margin: [0, 5, 0, 5], border: [false, false, false, false] },
          ],
          [{
            text: 'Move Phase: ', style: 'avenirMedium', border: [false, false, false, false], fillColor: '#EEECE9',
            fontsize: 11, margin: [0, 5, 0, 5], color: '#575A5D',
          },
          {
            text: movePhase[0].toUpperCase() + movePhase.slice(1), style: 'avenirMedium', border: [false, false, false, false], color: '#4F85C4',
            fontsize: 11, fillColor: '#EEECE9', margin: [0, 5, 0, 5],
          }
          ]
        ]
       }
      }
     ]
    }
  }

  /**
   * Generate Benefits section of PDF
   * @param benefitDetails Individual benefit details
   */
  generateBenefitCard(benefitDetails) {
    let individualBenefit = [];

    benefitDetails.forEach(value => {

      if(value.hasOwnProperty('points')) {
        individualBenefit.push([{text: value.displayName, border: [false, false, false, false],
          fontSize: 11, margin: [0, 5, 0, 2], color: '#19305A', style:'avenirMedium'
        },
        { text: (value.category === 'core' || value.points.toString().includes('Guaranteed')) ? `Core` : ` ${value.cashOut ? 'N/A' : (value.points > 1 ? value.points + ' Points'  : value.points + ' Point') + (value.perPointCurrency ? ' : ' + value.perPointCurrency * value.points + value.rangeIncrementUnit : '') }`,
         style: 'avenirMedium',border: [false, false, false, false], color: '#19305A', fontSize: 11,
         alignment: 'right', margin: [0, 8, 0, 2],
       }
       ]);
      } else {
        individualBenefit.push([{text: value.displayName, border: [false, false, false, false],
          fontSize: 11, margin: [0, 5, 0, 2], color: '#19305A', style:'avenirMedium'
        },
        { text: value.amount.toString().includes('Guaranteed') ? `Core` :  ` `,
         style: 'avenirMedium',border: [false, false, false, false], color: '#19305A', fontSize: 11,
         alignment: 'right', margin: [0, 8, 0, 2],
       }
       ]);
      }

      if (value.description) {
        individualBenefit.push([{
          text: value.description, border: [false, false, false, false], fontSize: 9,
          margin: [0, 0, 0, 2], color: '#000000', style: 'avenirBook'
        },
        {
          text: '', border: [false, false, false, false], color: '#000000', fontSize: 9,
          alignment: 'right', margin: [0, 0, 0, 2]
        }
        ]);

      }

      individualBenefit.push([{ text: '', margin: [0, 3, 0, 3], border: [false, false, false, false] },
      { text: '', margin: [0, 3, 0, 3], border: [false, false, false, false] },
      ]);
      // to be added for display logic
      // individualBenefit.push([{
      //     text: 'Benefit Display Logic:<display logc defined>', style:'avenirMedium', border: [false, false, false, false],
      //     fontSize: 9, margin: [0, 0, 0, 2], color: '#575A5D',
      //   },
      //   { text: '', border: [false, false, false, false], color: '#575A5D',
      //     fontSize: 9, alignment: 'right', margin: [0, 0, 0, 2],
      //   }
      // ])
    });
    const benefitCard = {
      table: { widths: ['85%', '15%'], body: individualBenefit }
    };
    return benefitCard;
  }

  /**
   * Generate Benefits Section (Move Phase Header and benefits)
   * @param benefitInformation Benefit information
   */
  generateBenefits(benefitInformation) {
    let benefitsArray = [];
    benefitInformation.forEach(function (value, key) {
      benefitsArray = benefitsArray.concat(this.generateMovePhaseHeader(value.group));
      benefitsArray = benefitsArray.concat(this.generateBenefitCard(value.items));
    }.bind(this));
    return benefitsArray;
  }

}

<div class="search-block" fxLayout.xs="row" fxLayoutAlign.xs="center center" role="group" *ngIf="!detailView">
  <mat-form-field class="table-search" appearance="outline">
      <span matPrefix class="lg-only" role="none">
          <mat-icon>search</mat-icon>
      </span>
      <input matInput tabindex="0" placeholder="Search within table for..."  
          [(ngModel)]="searchValue" 
          (keyup.enter)="textSearchPrograms($event.target.value)"
          (keyup)="textSearch($event)"
          aria-label="Search within table for..." 
          role="text">
              
              <button tabindex="0" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearTextSearch()" *ngIf="searchValue" role="button">
                  <mat-icon>close</mat-icon>
              </button>
  </mat-form-field> 
  <button tabindex="0" mat-icon-button class="filter-icon" 
            (click)="filterProgramModal()" aria-label="Filter the Programs Lists">
            <mat-icon 
            tabindex="0" 
            [matTooltip]="'Filter the Programs Lists'"  
            aria-hidden="true" 
            class="material-icons">
            filter_list
          </mat-icon>
  </button>
  <button tabindex="0" mat-icon-button aria-label="Select data view table list" matTooltip="Column Selection results" (click)="dataViewSelectionModal()">
    <mat-icon>view_column</mat-icon>
  </button>
</div>

<div class="program-wrapper" role="table" *ngIf="!detailView">
  <h2 style="margin: 0px;">{{ paginator?.length }} Programs </h2>
    <div class="add-btn" role="none" *ngIf="userCapabilities > -1">
      <button tabindex="0" class="user-water" aria-label="Add new program" role="button" (click)="addNewProgram()">
        <!-- <mat-icon>add</mat-icon> -->
      </button>
    </div>
 
  
  <div class="emp-details">
    <div class="table-scroll" role="table">
      <table mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true">
        <!-- Selection column -->
        <ng-container matColumnDef="select" width="1%" *ngIf="userCapabilities > -1">
          <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox aria-label="Select All/ Deselect All" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
              <mat-checkbox  aria-label="Select/Deselect" (click)="$event.stopPropagation()" (change) ="$event.checked ? checked(row) : unChecked(row)" [checked] ="isChecked(row)">
              </mat-checkbox>
          </td>
        </ng-container>
        <!-- Program Name Column -->
        <ng-container matColumnDef="programName" width="29%">
          <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Program Name"
            role="columnheader" aria-label="program name" (click)="initSort('programName')"> Program Name
          </th>
          <td mat-cell *matCellDef="let element">
            <div role="cell">
              <!-- routerLink="/empInfo/{{element.fileNumber}}" -->
              <a tabindex="0" (click)="viewProgram(element)">
                <span [innerHTML]="element.name | searchHighlight: filterText"></span>
              </a>

            </div>
          </td>
        </ng-container>

        <!-- Total Points Column -->
        <ng-container width="5%" matColumnDef="totalPoints" role="columnheader" aria-label="total points" *ngIf="!isMMUser">
          <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Total Points" (click)="initSort('points')"> Total
            Points
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="!element.hasOwnProperty('totalPoints')">N/A</span>
            <span *ngIf="(!isMMUser && !isGOUser) && (element && element.hasOwnProperty('totalPoints'))"[innerHTML]="element.totalPoints | searchHighlight: filterText"></span>
            <span [innerHTML]="0" *ngIf="isMMUser"></span>
            <span  *ngIf="isGOUser"> Custom Points</span>
          </td>
        </ng-container>

         <!-- Program Type Column -->
         <ng-container width="5%" matColumnDef="programType" role="columnheader" aria-label="program type" >
          <th mat-header-cell *matHeaderCellDef > 
            Program Type
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="(!isMMUser && !isGOUser) && (element && element.hasOwnProperty('programTypeInfo') && element.programTypeInfo.hasOwnProperty('type')) " [innerHTML]="element.programTypeInfo.type | searchHighlight: filterText"></span>
          </td>
        </ng-container>

        <!-- Total Amount Column -->
        <ng-container width="5%" matColumnDef="totalAmount" role="columnheader" aria-label="total points" *ngIf="isMMUser">
          <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Total Amount" (click)="initSort('totalAmount')"> Total
           Amount
          </th>
          <td mat-cell *matCellDef="let element">
            <span [innerHTML]="element.totalAmount | number:'1.0':'en-US'| searchHighlight: filterText"></span>
            &nbsp;<span [innerHTML]=" element.currency"></span>
          </td>
        </ng-container>

        <!-- program-expiration  Column -->
        <ng-container matColumnDef="programExpirationDate" role="columnheader" aria-label="programExpirationDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Program Expiration" (click)="initSort('toDate')"> Expiration
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.hasOwnProperty('programExpirationDate')  ?  (element.programExpirationDate | date:"yyyy-MM-dd") :'N/A' }}          </td>
        </ng-container>
        <!-- policyCallRequired Column -->
        <ng-container width="15%" matColumnDef="policyCallRequired" role="columnheader" aria-label="policyCallRequired">
          <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Policy Call Status" (click)="initSort('policyCallRequired')"> Policy Call Confirm
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.policyCallRequired === true ? 'Required' : element?.policyCallRequired === false ? 'Not Required':'N/A'}}
          </td>
        </ng-container>

         <!-- isCartusInsured Column -->
         <ng-container width="15%" matColumnDef="isCartusInsured" role="columnheader" aria-label="isCartusInsured">
          <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Cartus Insured Shipping Status" (click)="initSort('isCartusInsured')"> Cartus Insured Shipping
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.isCartusInsured === true ? 'Insured' : element.isCartusInsured === false ? 'Not Insured': 'N/A'}}
          </td>
        </ng-container>

        <ng-container width="25%" matColumnDef="divisions" role="columnheader" aria-label="divisions" *ngIf="(userRole !== 'client-contact-initiator')" >
          <th mat-header-cell *matHeaderCellDef >
            Divisions
          </th>
          <td mat-cell *matCellDef="let element" style="font-size:0.9em;">
            <div style="display: flex;">
              <a style="cursor:unset;text-decoration:none;" tabindex="0" (click)="return">
                <span *ngIf="divisionList.length === 0"></span>
                <span *ngIf="divisionList.length === element.divisions.length">All</span>
                <span *ngIf="element.divisions.length === 1 && element.divisions.length != divisionList.length" [innerHTML]="element.divisions[0].division_name"></span>
                <span *ngIf="element.divisions.length > 1 && element.divisions.length != divisionList.length" innerHTML="{{ element.divisions.length }} Defined"></span>
              </a>
              <div *ngIf = "userCapabilities > -1">
              <button *ngIf="element.divisions.length === 0" tabindex = "0" aria-label="Add" class="division-btn" [disabled]="element.status ==='Deactivated'" type="button" mat-raised-button  (click)="assignDivision(element)">Add</button>
              <button *ngIf="element.divisions.length !== 0" tabindex = "0" aria-label="Edit" [disabled] = "element.status ==='Deactivated'" type="button"
              (click)="assignDivision(element)" mat-icon-button style="position: relative; top: -10px; height: 35px; width: 35px; color: #76787b;">
                <mat-icon>edit</mat-icon>
              </button>
              </div>

            </div>
          </td>
        </ng-container>
        <!-- Points assigned Column -->
        <ng-container width="10%" matColumnDef="download" role="columnheader" aria-label="download">
          <th mat-header-cell *matHeaderCellDef>Download</th>
          <td mat-cell *matCellDef="let element" style="font-size:0.9em;">
            <a tabindex="0" (keydown.enter)="openDownloadToastr(element)" (click)="openDownloadToastr(element)">Download Report</a>
          </td>
        </ng-container>
        <!-- Status column -->
        <ng-container width="5%"
          matColumnDef="status" role="columnheader" aria-label="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Status"
            (click)="initSort('status')">
            Status
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.status | titlecase}}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToShow"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToShow;"></tr>
      </table>
    </div>

    <div fxLayout="row" class="table-footer" fxLayoutAlign="end center" fxLayoutAlign.xs="start start" role="">
      <div fxFlex="66%" fxLayoutAlign="start center">
        <!-- <app-download-menu-button tabindex="0" [reportName]="employeeReport"></app-download-menu-button> -->


      </div>
      <!-- <div fxFlex="64%"></div> -->
      <div fxFlex="34%" fxFlex.xs="100%">
        <!-- (page)="onPageChange($event)" -->
        <mat-paginator #paginator  
                 (page)="onPageChange($event)"             
                 [pageIndex]="0"
                 [pageSize]="75"
                 [pageSizeOptions]="[10, 25, 50, 75]"></mat-paginator>
      </div>
    </div>
  </div>
</div>

<div class="actions" *ngIf="selection && selection.selected.length > 0 && userCapabilities > -1">
  <button [disabled]="selection.selected.length>1" mat-button  class="edit-division contained-button"  role="button" aria-label="Copy Program" (click)="copyProgram(selection.selected)">Copy Program</button>
  <button mat-button  class="edit-division contained-button"  role="button" aria-label="Deactivate Program" (click)="deactivateProgram($event)">Deactivate Program</button>
  <button mat-button  class="edit-division contained-button"  role="button" aria-label="Deactivate Program" (click)="removeProgram($event)">Delete Program</button>
</div>

<ng-container *ngIf="detailView">
  <app-program-detail [clientDetails]="clientDetails" (close)="closeProgram()" [program]="selectedProgram" [isLumpSumEnabled]="isLumpSumEnabled"></app-program-detail>
</ng-container>
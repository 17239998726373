<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column">
    <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px" fxLayoutGap.xs="0em">
        <div fxFlex="90%"  class="candidate-dialogtitle"> {{data.status == 'policyCallSchedule' ? 'Policy Call Schedule Information' : 'Policy Completion Date Info'}}</div>
        <a fxFlex="10%" href="#" class="close-icon" (click)="onDismiss($event)" title="close">
            <mat-icon class="icon-margin">close</mat-icon>
        </a>
    </div>
    <mat-dialog-content class="middle-section" [ngStyle] = "{'overflow': 'hidden', 'max-width': '100%', 'flex': '1 1 100%' }">
            <mat-divider></mat-divider>
        
        <mat-list *ngIf="policyStatusHistoryData && policyStatusHistoryData.length >0"[ngStyle]="{'max-height': '250px', 'overflow-y': 'auto', 'padding': '5px' }">
            <div *ngFor="let history of policyStatusHistoryData"
                fxLayout="column">
                <div fxFlex="100%" fxLayout="column" fxLayoutGap="20px">
                    <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between">
                        <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between">
                            <span>{{data.status == 'policyCallSchedule' ? 'Policy Call Scheduled On' : 'Completed On'}}:</span> <span class="value">{{ history.policyCallDate }}</span>
                        </div>
                    </div>
                    <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between">
                        <span>{{data.status == 'policyCallSchedule' ? 'Policy Call Scheduled by' : 'Completed By'}}:</span> <span class="value"><mat-chip color="primary" selected>
                                {{ history.nameDetails.firstName | nameInitials }} {{ history.nameDetails.lastName | nameInitials}}
                            </mat-chip>
                            {{ history.nameDetails.firstName }} {{ history.nameDetails.lastName }}</span>
                    </div>
                    <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between">
                        <span>{{data.status == 'policyCallSchedule' ? 'Policy Call Updated On' : 'Updated On'}}:</span> <span class="value">{{ history.updatedOn }}</span>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </div>
        </mat-list>
        <div *ngIf="isLoad && policyStatusHistoryData.length === 0" class="no-info">
            No information available
        </div>

    </mat-dialog-content>
    <mat-dialog-actions class="statusBtn add-dialogbtn" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" fxLayoutGap.xs="0px" fxLayoutAlign.xs="space-between center">
        <button fxFlex="35%" type="button" style="margin-left: 4em !important;" mat-raised-button class="mat-button text-button close-btn" (click)="onDismiss($event)" color="primary" id="cancel">CLOSE</button>
     </mat-dialog-actions>
</div>
<div class="outer">
    <div class="main">
        <div class="header">
            <img src="../../../../../../assets/images/cartus.png" class="logo">
        </div>
        <div class="content" fxLayout="column">
            <div class="message">
                <br />
                Team,
                <br />
                <p>The following Authorization was unable to be created.</p>
                <p>Please review the information. If the authorization is not a
                    duplicate or sent in error, please take steps to manually create the authorization.</p>
                <p>If additional data clarification is needed, please reach out to the client contact for assistance.</p>
            </div>
            <div class="sub-data">
                <h1>Customer</h1>
                <div *ngIf="originalData?.candidate?.nameDetails" fxLayout="column">
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">First Name</div>
                        <div fxFlex="75%" class="value">{{originalData?.candidate?.nameDetails.firstName}}</div>
                    </div>
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">Last Name </div>
                        <div fxFlex="75%" class="value">{{originalData?.candidate?.nameDetails.lastName}}</div>
                    </div>
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">Title </div>
                        <div fxFlex="75%" class="value">{{originalData?.candidate?.nameDetails.title ? originalData?.candidate?.nameDetails.title : None}}</div>
                    </div>
                </div>
                <div *ngIf="originalData?.candidate?.phoneDetailsList.length" fxLayout="column">
                    <h1>Phone Numbers</h1>
                    <div *ngFor="let phone of originalData.candidate.phoneDetailsList">
                        <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                            <div fxFlex="25%" fxLayoutAlign="start" class="key">{{phone.type}} {{phone?.usageType}}</div>
                            <div fxFlex="75%" class="value">{{phone.phoneDialCode}} - {{phone.phoneNumber}}</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="originalData?.candidate?.emailDetailsList.length" fxLayout="column">
                    <h1>Emails</h1>
                    <div *ngFor="let email of originalData.candidate.emailDetailsList">
                        <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                            <div fxFlex="25%" fxLayoutAlign="start" class="key">{{email.usageType}} </div>
                            <div fxFlex="75%" class="value">{{email?.emailAddress}}</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="originalData?.candidate?.departureAddr" fxLayout="column">
                    <h1>Departure Address</h1>
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">StreetLine1</div>
                        <div fxFlex="75%" class="value">{{originalData.candidate.departureAddr?.streetLine1}}</div>
                    </div>
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">City</div>
                        <div fxFlex="75%" class="value">{{originalData.candidate.departureAddr?.city}}</div>
                    </div>
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">St/Prov</div>
                        <div fxFlex="75%" class="value">{{originalData.candidate.departureAddr?.state}}</div>
                    </div>        
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">Country</div>
                        <div fxFlex="75%" class="value">{{originalData.candidate.departureAddr?.country}}</div>
                    </div>        
                </div>
                <div *ngIf="originalData?.candidate?.destinationAddr" fxLayout="column">
                    <h1>Destination Address</h1>
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">StreetLine1</div>
                        <div fxFlex="75%" class="value">{{originalData.candidate.destinationAddr?.streetLine1}}</div>
                    </div>        
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">City</div>
                        <div fxFlex="75%" class="value">{{originalData.candidate.destinationAddr.city}}</div>
                    </div>
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">St/Prov</div>
                        <div fxFlex="75%" class="value">{{originalData.candidate.destinationAddr.state}}</div>
                    </div>
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">Country</div>
                        <div fxFlex="75%" class="value">{{originalData.candidate.destinationAddr.country}}</div>
                    </div>        
                </div>
                <div *ngIf="originalData?.candidate?.optionalDetails" fxLayout="column">
                    <h1>Optional Details</h1>
                    <div *ngFor="let opDetail of originalData?.candidate?.optionalDetails" fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">{{opDetail[0]}}</div>
                        <div fxFlex="75%" class="value">{{opDetail[1]}}</div>
                    </div>        
                </div>
                <div *ngIf="originalData?.candidate?.programDetails" fxLayout="column">
                    <h1>Program Details</h1>
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">Program Name</div>
                        <div fxFlex="75%" class="value">{{originalData?.candidate?.programDetails?.programName}}</div>
                    </div> 
                    <div *ngIf="originalData?.candidate?.programDetails?.currency" fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">Currency</div>
                        <div fxFlex="75%" class="value">{{originalData?.candidate?.programDetails?.currency}}</div>
                    </div> 
                </div>
                <div *ngIf="originalData?.candidate?.authorizationDetails" fxLayout="column">
                    <h1>Authorization Details</h1>
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">Initiator Party Id</div>
                        <div fxFlex="75%" class="value">{{originalData?.candidate?.authorizationDetails?.cciPartyId}}</div>
                    </div>
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">Contract No</div>
                        <div fxFlex="75%" class="value">{{originalData?.candidate?.authorizationDetails?.contractDetails?.contractId}}</div>
                    </div> 
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">Contract Name</div>
                        <div fxFlex="75%" class="value">{{originalData?.candidate?.authorizationDetails?.contractDetails?.contractName}}</div>
                    </div>
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">Currency</div>
                        <div fxFlex="75%" class="value">{{originalData?.candidate?.authorizationDetails?.currency?.description}}</div>
                    </div>
                </div>
                <div *ngIf="originalData?.candidate?.additionalDetails" fxLayout="column">
                    <h1>Billing and Reporting Data fields</h1>
                    <div *ngFor="let detail of originalData.candidate.additionalDetails">
                        <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                            <div fxFlex="25%" fxLayoutAlign="start" class="key">{{detail.displayName}} </div>
                            <div fxFlex="75%" class="value">{{detail?.value}}</div>
                        </div>
                    </div>
                </div>
                <div fxLayout="column">
                    <h1>General Details</h1>
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">Division Party Id</div>
                        <div fxFlex="75%" class="value">{{originalData?.candidate?.divisionPartyId}}</div>
                    </div> 
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">Type</div>
                        <div fxFlex="75%" class="value">{{originalData?.candidate?.type}}</div>
                    </div> 
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">Authorization Type</div>
                        <div fxFlex="75%" class="value">{{originalData?.candidate?.authorizationType}}</div>
                    </div> 
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">Assigned Policy</div>
                        <div fxFlex="75%" class="value">{{originalData?.candidate?.assignedPolicy}}</div>
                    </div> 
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">Client Id</div>
                        <div fxFlex="75%" class="value">{{originalData?.client?.clientId}}</div>
                    </div> 
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">Client Number</div>
                        <div fxFlex="75%" class="value">{{originalData?.client?.clientNumber}}</div>
                    </div> 
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4em" fxLayoutGap.xs="1em">
                        <div fxFlex="25%" fxLayoutAlign="start" class="key">Failed At</div>
                        <div fxFlex="75%" class="value">{{originalData?.authorizationDate}}</div>
                    </div> 
                </div>
                <div *ngIf="originalData?.validationMessages">
                    <h1>Failure Reasons</h1>
                    <div *ngFor="let error of originalData?.validationMessages">
                       <ng-container *ngIf="error.type === 'Warning'">{{error.message}}</ng-container>
                    </div>
                    <br/>
                </div>   
                <div>
                    The AuthMetaData identifier: {{originalData?.candidate?._id}}
                </div>
                <br/>
                <div>
                    Thank you
                </div>
                <br/>
            </div>
            <div class="footer"  fxLayoutGap="20px" fxFlex fxLayoutAlign="flex-end">
                <div  fxflex="70%" class="footer-text">Powered by</div>
                <div fxflex="30%"><img src="../../../../../../assets/images/cartus.png" class="logofooter"></div>
            </div>
        </div>
        
    </div>
</div>
<div cdkTrapFocus cdkTrapFocusAutoCapture aria-live="assertive">
  <div class="flex-div">
    <div class="inner-div">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h2 [innerHTML]="interfaceTitle">{{ interfaceTitle }}</h2>
        <button tabindex="0" mat-icon-button (click)="close()"><span
            class="material-icons">close</span></button>
      </div>
      <mat-divider></mat-divider>
      <app-linked-list [dataSet]="keyDatesData" listStyleClasses="full-width"
        [columnStyleClasses]="['', '', 'amount-format']"></app-linked-list>
      <mat-divider></mat-divider>
      <div fxLayout="column">
        <mat-card tabindex="0" class="mat-card-chip" fxFlex.gt-xs="100" fxFlex.md="100" *ngIf="taxAssistance">Total Expenses<span
            class="amount"> {{totalExpense}}</span></mat-card>
        <mat-card tabindex="0" class="mat-card-chip" fxFlex.gt-xs="100" fxFlex.md="100" *ngIf="taxAssistance">Tax Assistance<span
            class="amount"> {{taxAssistance}}</span></mat-card>
        <mat-card tabindex="0" class="mat-card-chip totalCost" fxFlex.gt-xs="100" fxFlex.md="100">Total Costs<span class="amount">
            {{data.totalCost}}</span></mat-card>
      </div>
    </div>


    <div class="bottom-btns" fxLayout="row" fxLayoutAlign="end center">
      <button mat-button class="outlined-button" (click)="close()">Close</button><button mat-button
        class="contained-button" (click)="viewAll()">View All</button>
    </div>
  </div>
</div>
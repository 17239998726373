<div class ="transferee-detail" *ngIf = "!detailView">
<h2 *ngIf="!isCostProjection">
    {{ paginator?.length }} Authorized Transferees 
</h2>
<h2 *ngIf="isCostProjection">
    {{ paginatorCp?.length }} Cost Projections 
</h2>
    <div class="add-btn" *ngIf = "(manageAuthorizationsCapability == 'write' || manageAuthorizationsCapability == 'read') && enableAuthorization">
        <button class="user-water" tabindex="0" aria-label="Add Transferee" [disabled]="manageAuthorizationsCapability ==='read'" (click)="openDialoge()" (keyup.enter)="openDialoge()">
    </button>
    </div>
    
<div [style.display]="(clearResults) ? 'none' : 'block' " class="emp-details">
    <div class="table-scroll">
        <table mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true">
            <!-- Selection column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef width="1%">
                    <mat-checkbox aria-label="Select All/ Deselect All" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox  aria-label="Select/Deselect" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)" [disabled]="row.moveStatus === 'Withdrawn'">
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- Favourite Column -->
            <ng-container matColumnDef="favourite">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Transferee Marked as Favorite" (click)="applySort('favorites')" class="fav-col-width">
                    <svg version="1.1" [ngClass]="0 ? 'filled' : 'empty'" class="star" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink">
                        <use xlink:href="assets/images/star-rating.icons.svg#star-filled"></use>
                    </svg> 
                </th>
                <td mat-cell *matCellDef="let element, let row = index" class="mat-column-starred">
                    <svg version="1.1" [ngClass]="element.favUser ? 'filled' : 'empty'" class="star"
                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        (click)="updateFavoriteTransferee(element.orderRequestId, element.favUser, row)">
                        <use
                        [attr.xlink:href]="element.favUser ? 'assets/images/star-rating.icons.svg#star-filled' : 'assets/images/star-rating.icons.svg#star-empty'">
                        </use>
                    </svg>
                </td>
            </ng-container>
            
            <!-- fullname Column -->
            <ng-container matColumnDef="fullname" >
                <th width="20%" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Full Name" (click)="applySort('fullName')"> Full Name </th>
                <td mat-cell *matCellDef="let element; let i = index">
                    <div *ngIf="element">
                        <!-- routerLink="/empInfo/{{element.fileNumber}}" -->
                        
                        <ng-container *ngIf="isFullNameEditable(element); else otherStatus">
                            <span [id] = "element.nameDetails.lastName + '_' + (i+1)">
                                <span [innerHTML]="element.nameDetails.lastName | searchHighlight: searchKeyword"></span>, 
                                <span [innerHTML]="element.nameDetails.firstName | searchHighlight: searchKeyword"></span>
                            </span>
                            <br>
                            <span> File #: </span>
                                <span [innerHTML]="(element.custFileId && element.custFileId != '' ? element.custFileId : 'N/A') | searchHighlight: searchKeyword">
                            </span>
                        </ng-container>
                        <ng-template #otherStatus>
                            <a [id] = "element.nameDetails.lastName + '_' + (i+1)" (click)="editDialog(element,'fullname')" tabindex="0" href="javascript:void(0)">
                                <span [innerHTML]="element.nameDetails.lastName | searchHighlight: searchKeyword"></span>, 
                                <span [innerHTML]="element.nameDetails.firstName | searchHighlight: searchKeyword"></span>                            
                            </a>
                            <a  [id] = "'custFileId' + '_' + (i+1)" (click)="editDialog(element,'fullname')" tabindex="0" href="javascript:void(0)">
                                <span> File #: </span>
                                <span [innerHTML]="(element.custFileId && element.custFileId != '' ? element.custFileId : 'N/A') | searchHighlight: searchKeyword">
                                </span>
                              </a>
                        </ng-template>

                    </div>
                </td>
            </ng-container>

            <!-- Program Column -->
            <ng-container matColumnDef="program">
                                                             <!--mat-sort-header matTooltip="Change sorting for Program" (click)="applySort('programName')"-->
                <th width="15%" mat-header-cell *matHeaderCellDef > Program </th>
                <td mat-cell *matCellDef="let element" >
                    <p tabindex="0" aria-label="test" *ngIf="(userRole !== 'client-contact-initiator') && element.programDetails && element.programDetails.programName" [innerHTML]="element.programDetails.programName | searchHighlight: searchKeyword"></p>
                    <a (click)="viewProgramDetails(element)" *ngIf="userRole === 'client-contact-initiator'" tabindex="0">
                        <p tabindex="0" aria-label="test" *ngIf="element.programDetails && element.programDetails.programName" [innerHTML]="element.programDetails.programName | searchHighlight: searchKeyword"></p>
                    </a>
                    <p tabindex="0" aria-label="test" *ngIf="(userRole === 'client-contact-initiator' || userRole === 'file-setup-specialist') && element.assignedPolicy" [innerHTML]="element.assignedPolicy | searchHighlight: searchKeyword"></p>
                </td>
            </ng-container>

            <!-- Type Column -->
            <ng-container matColumnDef="type">
                <th width="20%" mat-header-cell *matHeaderCellDef mat-sort-header [matTooltip]="isCostProjection ? 'Change sorting for Cost Projection Type' : 'Change sorting for Authorization Type'" (click)="applySort('authorizationType')">
                    <p *ngIf="!isCostProjection" style="margin-bottom: 0px;"> Authorization Type </p>
                    <p *ngIf="isCostProjection"  style="margin-bottom: 0px;"> Cost Projection Type </p>
                 </th>
                <td mat-cell *matCellDef="let element" >
                    <p tabindex="0" aria-label="test" *ngIf="element.authorizationType" 
                        [innerHTML]="element.authorizationType|conditionalAuthTypeTextReplace:element.isLumpSum | searchHighlight: searchKeyword"></p>
                </td>
            </ng-container>

            <!-- Points assigned Column -->
            <ng-container matColumnDef="pointsAssigned" *ngIf="!isMMUser">
                <th width="4%" mat-header-cell *matHeaderCellDef> Points Assigned </th>
                <td mat-cell *matCellDef="let element" >
                    <div *ngIf="element.programDetails && !isMMUser">
                        <p tabindex="0" *ngIf="element.programDetails.points" fxLayout="row" fxLayoutAlign="unset center">{{element.programDetails.points}}
                            <mat-icon style="cursor: pointer;" *ngIf="(element.pointsOverrideHistory && element.pointsOverrideHistory.length > 0 ) || element.programDetails.amountOverride" class="error-icon" aria-label="points overridden" (click)="showOverrideHistory(element)">error</mat-icon>
                        </p>
                        <!-- <p class="points-remaining">remaining:45</p> -->
                    </div>
                    <div *ngIf="element.programDetails && isMMUser">
                        <p tabindex="0"  fxLayout="row" fxLayoutAlign="unset center" role="note">0
                        </p>
                        <!-- <p class="points-remaining">remaining:45</p> -->
                    </div>
                </td>
            </ng-container>

            <!-- Points assigned Column -->
            <ng-container matColumnDef="totalAmount" *ngIf="isMMUser">
                <th width="4%" mat-header-cell *matHeaderCellDef> Total Amount </th>
                <td mat-cell *matCellDef="let element" >
                    <div *ngIf="element.programDetails">
                        <p tabindex="0" *ngIf="element.programDetails.programBudget.totalAmount" fxLayout="row" fxLayoutAlign="unset center">{{element.programDetails.programBudget.totalAmount | number:'1.0':'en-US'}}
                            <mat-icon style="cursor: pointer;" *ngIf="element.programDetails.amountOverride" class="error-icon" aria-label="points overridden" (click)="showOverrideHistory(element)">error</mat-icon>
                            &nbsp;<span>{{element.programDetails.programBudget.currency}}</span>
                        </p>
                        <!-- <p class="points-remaining">remaining:45</p> -->
                    </div>
                    <!-- <div *ngIf="element.programDetails && isMMUser">
                        <p tabindex="0"  fxLayout="row" fxLayoutAlign="unset center" role="note">0
                        </p>
                        
                    </div> -->
                </td>
            </ng-container>

            <!-- Departure Column -->
            <ng-container matColumnDef="departure">
                <th width="20%" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Departure" (click)="applySort('departure')">
                    Departure </th>
                <td mat-cell *matCellDef="let element">
                    <div tabindex="0" *ngIf="element.departureAddr">
                        <span [innerHTML]="element.departureAddr.country | searchHighlight: searchKeyword"></span><br/>                        
                        <span [innerHTML]="element.departureAddr.state?(element.departureAddr.state | searchHighlight: searchKeyword ):''"></span><br/>
                        <span [innerHTML]="element.departureAddr.city? (element.departureAddr.city | searchHighlight: searchKeyword ): ''"></span>
                        
                    </div>
                </td>
            </ng-container>

            <!-- Destination Column -->
            <ng-container matColumnDef="destination">
                <th width="20%" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Destination" (click)="applySort('destination')">Destination</th>
                <td mat-cell *matCellDef="let element">
                    <div tabindex="0" *ngIf="element.destinationAddr">
                        <span [innerHTML]="element.destinationAddr.country | searchHighlight: searchKeyword"></span><br />
                        <span [innerHTML]="element.destinationAddr.state? (element.destinationAddr.state | searchHighlight: searchKeyword) : ''"></span> <br />
                        <span [innerHTML]="element.destinationAddr.city? (element.destinationAddr.city | searchHighlight: searchKeyword) : ''"> </span>
                        
                    </div>
                </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <th  width="20%" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Status" (click)="applySort('status')">Status Info</th>
                <td tabindex="0" mat-cell *matCellDef="let element">
                    <div class="align-info-tip">
                    <span *ngIf="viewSubmitAuthCapability ==='read' && (element.moveStatus ==='Invitation Not Sent' || element.moveStatus ==='Authorization Initiated' || element.moveStatus ==='Invitation Sent'); else otherStatus"><a (click)="editDialog(element,'status')"> <span [innerHTML]="element.moveStatus | conditionalTextReplace | searchHighlight: searchKeyword"></span></a></span>
                    <span *ngIf="userRole !== 'client-contact-initiator' && element.moveStatus === 'In Review'"><mat-icon style="cursor: pointer;" matTooltip ="Legal approval required before authorizing any move/services in the country selected." position="center" >info</mat-icon></span>
                    <ng-template #otherStatus>
                        <span [innerHTML]="element.moveStatus | conditionalTextReplace | searchHighlight: searchKeyword"></span>
                    </ng-template>
                    </div>
                <div  *ngIf="element.moveStatusDate" style="font-size: 12px;">on {{element.moveStatusDate}}</div>
            </td>
            </ng-container>

            <!-- Authorization Date Column -->
            <ng-container matColumnDef="authorizationDate">
                <th  width="20%" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Authorization Date" (click)="applySort('authorizationDate')">Authorization Date</th>
                <td tabindex="0" mat-cell *matCellDef="let element">
                    {{ element.hasOwnProperty('authorizationDate')  ?  (element.authorizationDate | date:"yyyy-MM-dd") :'N/A' }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <div fxLayout="row" class="table-footer" fxLayoutAlign="end center" fxLayoutAlign.xs="start start">
        <div fxFlex="66%" fxLayoutAlign="start center">
            <!-- <app-download-menu-button tabindex="0" [reportName]="employeeReport"></app-download-menu-button> -->
            <ng-container *ngIf="deactivateTransfereeCapability === 'write'">
                <button class="deactivate-btn" [disabled]="selection.selected.length === 0" (click)="deactivateTransferees.emit(selection.selected)">Deactivate Transferee</button>
                <span><mat-icon class="info-icon" [tooltipToRenderHTML]="tooltipContent" position="center" >info</mat-icon></span>
            </ng-container>
        </div>
        <!-- <div fxFlex="64%"></div> -->
        <div fxFlex="34%" fxFlex.xs="100%">
            <!-- (page)="onPageChange($event)" -->
            <mat-paginator #MatPaginator="matPaginator" *ngIf="!isCostProjection" (page)="onPageChange($event)" [pageSizeOptions]="[10, 25, 50,75]"></mat-paginator>
            <mat-paginator #MatPaginatorCp="matPaginator" *ngIf="isCostProjection" (page)="onCpPageChange($event)" [pageSizeOptions]="[10, 25, 50,75]"></mat-paginator>
        </div>
    </div>
</div>
</div>
<div *ngIf="detailView">
    <app-transferee-detail 
    (close)="closeTransferee()" 
    [transferee]="transferee" 
    [clientDetails]="clientDetails">
    </app-transferee-detail>
</div>
import { Component, Input, OnInit, ViewChild,OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DmsData } from '../../modules/transferee/transferee-detail/transferee-detail.component';
import { CandidateProfileService } from '../../services/candidate-profile.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserTypeService } from '../../services/user-type.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../../modules/transferee/transferee-detail/alert-dialog/alert-dialog.component';
import { REPAYMENT_AGREEMENT } from '../additional-document-upload/additional-document-upload.component';
import { Subscription } from 'rxjs';




@Component({
  selector: 'document-library',
  templateUrl: './document-library.component.html',
  styleUrls: ['./document-library.component.scss']
})
export class DocumentLibraryComponent implements OnInit,OnChanges, OnDestroy {

  docDisplayedColumns: Array<string> = ['docType','name', 'addedBy', 'date', 'docId'];
  showTable: boolean = false;
  searchValue: string = '';
  displayDocData: DmsData[] = [];
  docdataSource: any = new  MatTableDataSource();
  matExpansionHeaderHeight = '40px';

  @ViewChild(MatSort) sort: MatSort;

  @Input()
  orderRequestId: string;
  
  documentsListToDelete: any;
  @Output()
  repayDocId: EventEmitter<number> = new EventEmitter<number>();

  uploadDocSub: Subscription

  constructor( 
    private candidateProfileService: CandidateProfileService,
    private readonly toaster: MatSnackBar,
    private userTypeService: UserTypeService,
    public dialog: MatDialog
    ) { }

  ngOnInit(): void {
    this.userTypeService.capabilities$.subscribe(ele => {
      if(ele){this.checkCapabilitiesForDMS(ele)};
    });
    this.updateDocumentListWithUploadedRecord();
  }

  ngOnChanges(changes: SimpleChanges) :void {
    if(changes && (changes.orderRequestId)){
      this.subscribeToDmsData(this.orderRequestId);
    }
  }


  subscribeToDmsData(id) {
    this.candidateProfileService.getDmsData(id).subscribe(
      res => {
        if (res) { this.DmsData(res) };
      }
    );
  }

  /**DMS Code */
  DmsData(data) {
    this.displayDocData = [];
    data.documentList.forEach(ele => {
      if(ele.documentType === REPAYMENT_AGREEMENT) {
        this.repayDocId.emit(ele.documentID);
      }
      this.createListForMatTable(ele);
    });

    if(data.documentList.length > 0) {
      this.docdataSource = new MatTableDataSource(this.displayDocData);
      this.docdataSource.sort = this.sort;
      this.showTable = true;
    }
  }

  createListForMatTable(ele) {
    this.displayDocData.push({
      docType: ele.documentType,
      name: ele.documentDisplayName ? ele.documentDisplayName : ele.documentType,
      addedBy:`${ele.addedBy.firstName} ${ele.addedBy.lastName}`,
      lastName: ele.addedBy.lastName,
      date: ele.documentDate,
      docId: ele.documentID,
      role: ele.addedBy.role ? true : false,
      isDisabledForDelete:this.documentsListToDelete.some(element=>element.toLowerCase().includes(`MP - ${ele.documentType}`.toLowerCase()))
    })
  }

  clearTextSearch() {
    this.searchValue = '';
    this.docdataSource = new MatTableDataSource(this.displayDocData);
  }

  applySearch(searchValue: any) {
    this.docdataSource.filter = searchValue.trim().toLowerCase();
  }

  /**download toaster */
  openDSMFileToastr(dmsData) {
    // tslint:disable-next-line: max-line-length
    const message = 'Downloading files with sensitive information is a security risk, please clear your cache afterwards';
    const toaster = this.toaster.open(message, 'DOWNLOAD', {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['toasterMessage']
    });
    toaster.onAction().subscribe(_ => this.downloadDMSFile(dmsData));
  };
  
  /* get DMS Data */
  downloadDMSFile(dmsData) {
    this.candidateProfileService.getDMSDetails(this.orderRequestId, dmsData)
      .subscribe(
        data => {
          if (data && data.request) {
            const dmsDetails = data.request;
            const token = dmsDetails.headers.Authorization;
            const guid = dmsDetails.queryParams.guid;
            sessionStorage.setItem('car-ses-dms', token);
            sessionStorage.setItem('car-ses-guid', guid);
            this.candidateProfileService.getDMSDowloadFile(dmsDetails)
            .subscribe(
              data => {
                if (data && data.headers && data.headers.get('content-disposition')) {
                  let filename = JSON.parse(data.headers.get('content-disposition').split(';')[1].split('=')[1]);
                  filename = filename.slice(filename.indexOf('-') + 1).trim();
                  let blob: Blob = new Blob([data.body], {type: 'application/octetstream'});
                  let a = document.createElement('a');
                  a.download = filename;
                  a.href = window.URL.createObjectURL(blob);
                  a.click();
                }
              })
          }
        });
  }

  checkCapabilitiesForDMS(capabilitiesList: any[]){
    this.documentsListToDelete = capabilitiesList.filter(x=>x.operation === "delete" && x.permission=== "allow" ).map(x=>{return x.name})
    if(this.documentsListToDelete.length > 0) {
      this.docDisplayedColumns.push('action');
    }
  }

  confirmDeleteDoc(element) {
    console.log("element", element)
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: "30vw",
      data: {
        displayMessage: `You are about to delete ${element.name}. Are you sure you want to proceed? If correct, select Delete to continue`,
        action:"delete",
        showConfirmBtn: true
      },
    })


    dialogRef.afterClosed().subscribe(result => {
      if (result?.action === 'confirm') {
        this.deleteDoc(element.docId,element.docType)
      }
      if (result?.action === 'dismiss') {
        console.log("closed")
      }
    });
  }

  deleteDocFromList(docId){
    let docIndex = this.displayDocData.findIndex(x=>x.docId === docId)
    if(docIndex > -1 ){
      this.displayDocData.splice(docIndex,1)
      this.docdataSource = new MatTableDataSource(this.displayDocData);
      this.docdataSource.sort = this.sort;
      this.showTable = true;
    }
  }

  deleteDoc(docId,docType){
    this.candidateProfileService.deleteDocument(docId,docType,this.orderRequestId)
    .subscribe( res =>{
        this.deleteDocFromList(docId)
        let message = "Document deleted successfully" ;
        const toaster = this.toaster.open(message, 'CLOSE', {
          duration: 10000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          panelClass: ['toasterMessage']
        });
      
    })
  }

  updateDocumentListWithUploadedRecord() {
    this.uploadDocSub = this.candidateProfileService.uloadedDocument.subscribe(
      uploadedDoc => {
        if(uploadedDoc) {
         const {firstName, lastName} = JSON.parse(sessionStorage.getItem('UserContext'))
          const newDocument = {
            "documentType": uploadedDoc.documentType.split(" - ").pop(),
            "documentID": uploadedDoc.documentID,
            "documentDate": uploadedDoc.documentDate,
            "addedBy": {
              firstName,
              lastName
            }
          }
          if(this.displayDocData.length == 0 ) {
            const docLibrary = {
              documentList: [newDocument],
              count: 1
            }
            this.DmsData(docLibrary);
          } else {
            this.createListForMatTable(newDocument);
            this.docdataSource = new MatTableDataSource(this.displayDocData);
          }
        }
      }
    )
  }

  ngOnDestroy(): void {
    if (this.uploadDocSub) {
      this.uploadDocSub.unsubscribe();
    } 
  }

}




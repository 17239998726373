import { Component, OnInit } from '@angular/core';

/** PrivacyStatementComponent to view the privacy statement */
@Component({
  selector: 'app-privacy-statement',
  templateUrl: './privacy-statement.component.html',
  styleUrls: ['./privacy-statement.component.scss']
})
export class PrivacyStatementComponent implements OnInit {
  /** Base constructor method */
  currentYear: number = new Date().getFullYear();
  constructor() { }

  /** ngOnInit to initialize methods and variables */
  ngOnInit() {
  }
}

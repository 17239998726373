<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column">
    <div disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
        fxLayoutGap="20px" fxLayoutGap.xs="0em">
        <!-- <div class="warnig-dialogtitle">Duplicate Check</div> -->
        <!-- changes start -->
        <span class="warnig-dialogtitle"*ngIf="!isReviewComp"><strong>Duplicate Check</strong></span>
        <span class="warnig-dialogtitle"*ngIf="isReviewComp"><strong>Submission Failure</strong></span>
            <!-- changes end -->
        <span class="close-icon" (click)="onDismiss($event)" title="close">
            <mat-icon>close</mat-icon>
        </span>
    </div>

    <div>
        <mat-horizontal-stepper labelPosition="bottom" #stepper linear>

            <mat-step label="Duplicate Check">
              <app-duplicate-check (nextStep)='CompleteStep()'></app-duplicate-check>
            </mat-step>
          
            <mat-step label="Error Review">
                <ng-container *ngIf="isReviewComp">
                    <app-error-review (nextStep)='ReviewStep()'></app-error-review>
                </ng-container>
            </mat-step>

             <mat-step label="Reference Info">
                <ng-container *ngIf="isReference">
                <app-reference-information></app-reference-information>
             </ng-container>
        </mat-step>
        

        </mat-horizontal-stepper>
    </div>
    

</div>

import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
// import { GridRowStyleBuilder } from '@angular/flex-layout/grid/typings/row/row';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserType, UserTypeService } from './../../../../services/user-type.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-points-logic-modal',
  templateUrl: './points-logic-modal.component.html',
  styleUrls: ['./points-logic-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PointsLogicModalComponent implements OnInit {
  numList = [];
  pointForm: UntypedFormGroup;
  tierConfigs = [];
  /** user role */
  userRole: UserType;
  readonlyMode: boolean = false;
  public singleSelectTier = false;
  public selectedTierValue;
  public singleSelectedTierForm: UntypedFormGroup;
  public singleSelectedTierValue = 0;
  public isDisable = false;
  public currencyCode:string;
  constructor(
    public formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<PointsLogicModalComponent>,
    private userTypeService: UserTypeService,
    @Inject(MAT_DIALOG_DATA) public data
    ) { }

  ngOnInit() {
    this.userRole = this.userTypeService.userType;
    this.singleSelectTier = this.data.benefit.singleSelectTier;
    this.initSingleSelectedTier();
    this.numList = Array.from({length: 10}, (v, k) => k + 1);
    this.pointForm = new UntypedFormGroup({
      pointsArray: new UntypedFormArray([
        this.initPointSection()
      ]),
      pointsPerSelection: new UntypedFormControl(this.data.benefit.advCashOutV2? this.data.benefit.pointsPerSelection: null, this.data.benefit.advCashOutV2? [ Validators.required, Validators.min(1)] : null)
    });
    if (this.data.benefit && this.data.benefit.tierConfigs && this.data.benefit.tierConfigs.length > 0) {
      this.singleSelectTier = false;
      this.data.benefit.tierConfigs.forEach((item, key) => {
        if (this.data.benefit.tierConfigs.length - 2 >= key) {
          const control = <UntypedFormArray>this.pointForm.get(['pointsArray']);
          control.push(this.initPointSection());
        }
          this.pointForm.get(['pointsArray', key]).setValue({
            maxSelection : item.maxSelection,
            value : item.pointValue? item.pointValue : 0
          });
        
      });
    } else if(this.data.benefit &&  this.data.benefit.tierValues && this.data.benefit.tierValues.length > 0){
      this.data.benefit.tierValues.forEach(element => {
          (<UntypedFormArray> this.singleSelectedTierForm.get('Rows')).push(this.initRows(element));
      });
    }
    if ((this.userRole !== 'program-admin' && this.userRole !== 'product-mgmt-admin') || ( !this.data.program.isNew && !this.data.program.draft && (this.userRole === 'program-admin' || this.userRole === 'product-mgmt-admin'))) {
      this.pointForm.disable();
      this.readonlyMode = true;
    }
    this.currencyCode = this.data.benefit.hasOwnProperty('currencyCode') ? (this.data.benefit.currencyCode != '' ? this.data.benefit.currencyCode : 'USD')  : 'USD';
  }

  initPointSection() {
    return new UntypedFormGroup({
      maxSelection: new UntypedFormControl('', [Validators.required]),
      value : new UntypedFormControl('', [Validators.required])
    });
  }


  close() {
    this.dialogRef.close();
  }

  getPointSections(form) {
    return form.controls.pointsArray.controls;
  }

  save() {
  if(this.singleSelectTier) {
    let verify =  this.singleSelectedTierForm.get("Rows")['controls'];
    const dragArray = [];
    const disableData = this.singleSelectedTierForm.get("Rows");
    disableData.value.filter(element=>{ 
      if(element.displayValue === ''){
        this.isDisable = true;
      } 
    });
    verify.forEach(element => {
      if(element && element.value){
        return dragArray.push(element.value);
      }
    });
    if (!this.isDisable) {
      this.data.benefit.tierValues = dragArray;
      this.dialogRef.close(this.data);
    }
  } else {
    let maxSelection = 0;
    this.pointForm.get('pointsArray').value.forEach((item) => maxSelection = item.maxSelection + maxSelection);
    this.data.benefit.maxSelection = maxSelection;
    this.data.benefit.tierConfigs = this.tierConfigs;
    this.data.benefit.advCashOutV2? this.data.benefit.pointsPerSelection = Number(this.pointForm.get('pointsPerSelection').value): null;
    this.dialogRef.close(this.data);
  }
  }

  getPointsSections() {
    const control = <UntypedFormArray>this.pointForm.get('pointsArray');
    control.push(this.initPointSection());
  }

  calculateRange(range, index) {
    let minRange = 1;
    let maxRange = 0;
    for ( let i = 0; i <= index; i++) {
      if (index === 0) {
        minRange = 1;
        maxRange = this.pointForm.get(['pointsArray', index, 'maxSelection']).value;
      }  else if (this.pointForm.get(['pointsArray', i, 'maxSelection']).value && index > 0) {
        minRange = i < index ? this.pointForm.get(['pointsArray', i, 'maxSelection']).value + minRange  : minRange;
        maxRange = minRange + this.pointForm.get(['pointsArray', index, 'maxSelection']).value - 1;
      }
    }
    if (this.tierConfigs[index]) {
      this.tierConfigs[index].maxSelection = range;
      this.tierConfigs[index].minRange = minRange;
      this.tierConfigs[index].maxRange = maxRange;
    } else {
      this.tierConfigs.push({
        maxSelection : range,
        minRange : minRange,
        maxRange : maxRange
      });
    }
    return minRange + ' - ' + maxRange;
  }

  calculatePoints(index) {
    let value = 0;
    //let maxRange = 0;
    for ( let i = 0; i <= index; i++) {
      value = this.pointForm.get(['pointsArray', i, 'value']).value * this.pointForm.get(['pointsArray', i, 'maxSelection']).value + value;
    }
    (value>= 0) && this.tierConfigs[index] ? 
      this.tierConfigs[index].pointValue = this.pointForm.get(['pointsArray', index, 'value']).value  : null;
    return value && this.pointForm.get(['pointsArray', index, 'value']).value ? this.numberWithCommas(value) : 0;
  }

  numberWithCommas(x) {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x))
        x = x.replace(pattern, "$1,$2");
    return x;
  }
  
  deletePoints(index) {
    if (!((this.userRole !== 'program-admin' && this.userRole !== 'product-mgmt-admin') || ( !this.data.program.isNew && !this.data.program.draft && (this.userRole === 'program-admin' || this.userRole === 'product-mgmt-admin')))) {
      const formArrayControl = <UntypedFormArray>this.pointForm.get(['pointsArray']);
      formArrayControl.removeAt(index);
      this.tierConfigs.splice(index, 1);
    }
  }

  disableAdd() {
    const pointLogicSection = this.pointForm.get('pointsArray').value[this.pointForm.get('pointsArray').value.length - 1];
    if (!pointLogicSection) {
      return false;
    }
    if ((pointLogicSection.maxSelection !== '') && (pointLogicSection.value !== '')) {
      return false;
    }
    return true;
  }

  /**
   * This function is used to initialize single selecte tier value
   */
  public initSingleSelectedTier(){
    if(this.data && this.data.benefit && this.data.benefit.tierValues && this.data.benefit.tierValues.length > 0){
      this.singleSelectedTierForm = this.formBuilder.group({
        Rows: this.formBuilder.array([])
      });
    } else {
      this.singleSelectedTierForm = this.formBuilder.group({
        Rows: this.formBuilder.array([this.initRows()])
      });
    }
  }

  /**
   * This function used to add single selected tier offering
   */
  addSingleSelectedTier(){
    this.isDisable = true;
    this.formArr.push(this.initRows());
  }

  /**
   * This function is used to get new  form array of single selecte tier offering
   */
  get formArr() {
    this.singleSelectedTierValue = 0;
    return this.singleSelectedTierForm.get("Rows") as UntypedFormArray;
  }
/**
 * This function is used to initialize every value in new row single selecte tier offering
 * @param data data of tierValues
 * @returns 
 */
 public initRows(data?) {
  if(this.data && this.data.benefit && this.data.benefit.hasOwnProperty('tierValues') &&  this.data.benefit.hasOwnProperty('tierValues') &&  this.data.benefit.tierValues && this.data.benefit.tierValues.length > 0){
      return this.formBuilder.group({
        displayValue: [data && data.displayValue ? data && data.displayValue : '', [Validators.required]],
        value:[data && data.value ? data && data.value : this.singleSelectedTierValue, [Validators.required]]
      });
    } else{
      this.isDisable = true;
      return this.formBuilder.group({
        displayValue: [data && data.displayValue ? data && data.displayValue : '', [Validators.required]],
        value:[data && data.value ? data && data.value : this.singleSelectedTierValue, [Validators.required]]
      });
    }
  }
/**
 * This function is used increase tiervalues 
 * @param itemrow selected tier value
 * @param index  index of selected value
 */
 public increasePoints(itemrow,index){
    this.singleSelectedTierValue= parseInt(itemrow.value) + 1;
    const increaseFormValue = (<UntypedFormArray>this.singleSelectedTierForm.get("Rows")).at(index);
    increaseFormValue.patchValue({
      value: this.singleSelectedTierValue
    });
  }
/**
 * This function is used decrease tiervalues 
 * @param itemrow selected tier value
 * @param index  index of selected value
 */
  public decreasePoints(itemrow,index){
    this.singleSelectedTierValue = parseInt(itemrow.value) - 1;
    const decreaseFormValue = (<UntypedFormArray>this.singleSelectedTierForm.get("Rows")).at(index);
    if(this.singleSelectedTierValue >= 0) {
      decreaseFormValue.patchValue({
        value: this.singleSelectedTierValue
      });
    }
  }

/**
 * This function used to remove tier Values
 * @param index index
 */
 public removeSingleSelectedTier(index) {
  this.isDisable = false;
    this.formArr.removeAt(index);
  }
/**
 * This function used to disable add and save button 
 * @param event enter value
 */
public  onInputChange(value){
    if(value.length <= 0){
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
  }

  /**
   * This is used for drap and drop the entries of tierValues
   * @param event 
   */
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.singleSelectedTierForm.get('Rows')['controls'], event.previousIndex, event.currentIndex);
  }
}

import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApproveRejectCompliantTransfereesComponent } from '../approve-reject-compliant-transferees/approve-reject-compliant-transferees.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CandidateProfileService } from '../../services/candidate-profile.service';
import { ComplianceListModel } from '.././../../../core/models/compliance-list.model';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-compliant-adjudication-transferees',
  templateUrl: './compliant-adjudication-transferees.component.html',
  styleUrls: ['./compliant-adjudication-transferees.component.scss']
})
export class CompliantAdjudicationTransfereesComponent implements OnInit {
  warningDialog = false
  transfereeDetails = {}
  reviewComment = new UntypedFormControl();
  constructor(
    public dialog: MatDialogRef<CompliantAdjudicationTransfereesComponent>,
    public adjudicateDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public compliantData: any,
    ) { }

  ngOnInit() {
    if (this.compliantData) {
      this.getTransfereeDetails()
    }
  }

  getTransfereeDetails() {
    this.transfereeDetails = {}
    this.transfereeDetails = this.compliantData.rowData
  }

  onDismissDialog(eve) {
    if (eve) {
      this.dialog.close({
        action: 'dismiss'
      })
      localStorage.removeItem('query');
    }
  }

  OnAdjudicateTrnasferee(action) {
    if(action){
      this.warningDialog = true
      const dialogRef = this.adjudicateDialog.open(ApproveRejectCompliantTransfereesComponent, {
        data: {
          transfereeName: this.transfereeDetails.hasOwnProperty('firstName') && this.transfereeDetails.hasOwnProperty('lastName') ? `${this.transfereeDetails['firstName']} ${this.transfereeDetails['lastName']}` : '',
          action: action,
          comment: this.reviewComment  && this.reviewComment.value? this.reviewComment.value: '',
          orderRequestID: this.transfereeDetails.hasOwnProperty('orderRequestID') && this.transfereeDetails['orderRequestID'] ? this.transfereeDetails['orderRequestID']  : ''
        },
        width: '52vw',
        disableClose: true,
      });
      localStorage.removeItem('query');
     }
  }
}

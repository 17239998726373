import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserConfig } from '../models/user-config.model';
import { BaseClientService } from '../services/base-client.service';
import { RemoteLoggingService } from '../services/remote-logging.service';
import { map, catchError } from 'rxjs/operators';
/** UserConfigService to get and set the user preferences data from the API */
@Injectable({
  providedIn: 'root'
})
export class UserConfigService {

  /** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
   */
  constructor(protected baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService) {
  }

  /** getUserConfigDetails() method to get the reponse from the api using base client service */
  getUserConfigDetails(): Observable<Array<UserConfig>> {
    return this.baseClient.getArr<UserConfig>('v1/userPreference', 'get the UserConfig data').pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: UserConfig[] = [];
        this.logSvc.logError(err);
        return of(empty);
      })
    );
  }

  /** settUserConfigDetails() method to set the reponse to the api using base client service */
  setUserConfigDetails(userConfig: UserConfig): Observable<boolean> {
    return this.baseClient.post<boolean>('v1/userConfig', userConfig, 'setting user config').pipe(
      map(r => r.body),
      catchError((err, source) => {
          const empty = false;
          this.logSvc.logError(err);
          return of(empty);
      })
    );
  }

  /** settUserConfigDetails() method to set the reponse to the api using base client service */
  updatePreference(req): Observable<boolean> {
    return this.baseClient.put<boolean>('v1/userPreference', req, 'update user preference').pipe(
      map(r => r.body),
      catchError((err, source) => {
          const empty = false;
          this.logSvc.logError(err);
          return of(empty);
      })
    );
  }
}

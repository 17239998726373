import { Injectable } from '@angular/core';
import { PdfFontsService } from './pdf-fonts.service';
import { formatDate, formatNumber } from '@angular/common';
import { ProgramReportGenerationService } from './program-report-generation.service';

@Injectable({
  providedIn: 'root'
})
export class TransfereeBenefitSelectionReportGenService {
  programBorderColor = '#D0E1EE';
  benefitsBorderColor = '#CCCCCC';
  benefitsCardFillColor = '#F7F7F7';
  isMMUser = JSON.parse(sessionStorage.getItem("car-ses-mmu"));
  formattedDate;
  constructor(
    private readonly pdfMakeFonts: PdfFontsService,
    private readonly programReportGenerationService: ProgramReportGenerationService
  ) {
    pdfMakeFonts.defineFonts();
    pdfMakeFonts.assignFonts();
    this.pdfMakeFonts.loadLogos();
  }

  generateDocumentDefinition(benefitList, clientDetails, transferee, confirmedPoints, orderId) {
    let totalUsed = 0, content: any;
    const documentDefinition = {
      info: { title: 'Program Details', subject: 'Details of programs listed' },
      pageOrientation: 'portrait',
      pageSize: 'LETTER',
      pageMargins: [40, 110, 40, 110],
      header: (currentPage) => {
        return {
          table: {
            headerRows: 1,
            widths: ['50%', '50%'],
            body: [
              [{
                image: this.pdfMakeFonts.bbLogob64, width: 120, height: 40, alignment: 'left',
                color: '#FFFFFF', fillColor: '#FFFFFF', border: [false, false, false, false], margin: [30, 35, 0, 0],
              },
              {
                image: this.pdfMakeFonts.cartusLogob64, width: 120, height: 40, alignment: 'right',
                color: '#FFFFFF', fillColor: '#FFFFFF', border: [false, false, false, false], margin: [0, 35, 20, 0],
              }
              ]
            ]
          },
        };
      },
      footer: (currentPage, pageCount) => {
        return {
          table: {
            headerRows: 1, widths: ['60%', '40%'],
            body: [
              [{
                text: `© ${new Date().getFullYear()} Cartus Corporation | All rights reserved.
               Cartus and the Cartus logo are registered trademarks of Cartus Corporation
               `, fontSize: 9, color: '#575A5D', alignment: 'left', border: [false, false, false, false],
                margin: [30, 30, 0, 0]
              },
              {
                text: `Page ${currentPage.toString()}`, fontSize: 9, color: '#575A5D',
                alignment: 'right', border: [false, false, false, false], margin: [0, 30, 30, 0]
              }
              ]
            ]
          },
        };
      },
      styles: {
        avenirBook: {
          font: 'AvenirBook',
          normal: true
        },
        avenirLight: {
          font: 'AvenirLight',
          fontSize: 18,
          normal: true
        },
        avenirMedium: {
          font: 'AvenirMedium',
          normal: true
        },
        programTable: {
          border: [true, true, true, true], borderColor: ['#ff00ff', '#00ffff', '#ff00ff', '#00ffff'],
          fillColor: '#2065F8'
        }
      }
    };
    const docDefinition = JSON.parse(JSON.stringify(documentDefinition));
    docDefinition.footer = documentDefinition.footer;
    docDefinition.header = documentDefinition.header;
    if(this.isMMUser) {
      for(const item of benefitList) {
        if(item.items.length > 0) {
          for(const i of item.items) {
            if(i.budget && i.confirmedCount) {
              totalUsed = totalUsed + (i.budget.total.estimatedAmount.amount * i.confirmedCount);
            } else if(i.budget) {
              totalUsed = totalUsed + i.budget.total.estimatedAmount.amount;
            }
          }
        }
      }
      

    }
    if(!this.isMMUser) {
      content = [
        this.generateTransfereeSection(clientDetails, transferee),
        this.generateProgramDetails(transferee, confirmedPoints),
        this.reviewOrConfirm(),
        this.generateBenefitsforConfirmed(benefitList),
      ];
    } else {
      content = [
        this.generateTransfereeSection(clientDetails, transferee),
        this.generateProgramDetails(transferee, confirmedPoints, totalUsed),
        this.reviewOrConfirm(),
        this.generateBenefitsforConfirmed(benefitList),
      ];
    }
    docDefinition.content = content;
    return docDefinition;
  }

  generateTransfereeSection(clientInfo, transfereeInfo) {
    let clientName: string;
    let orderRefNum: any;
    let fullName: string;
    let clientId: any;
    if (clientInfo && transfereeInfo) {
      clientName = (clientInfo.hasOwnProperty('clientEntityName')) ? clientInfo.clientEntityName : 'NA';
      orderRefNum = transfereeInfo.hasOwnProperty('orderRefNum') ? transfereeInfo.orderRefNum : 'NA';
      fullName = transfereeInfo.nameDetails.firstName + ' ' + transfereeInfo.nameDetails.lastName;
      clientId = (clientInfo.hasOwnProperty('clientNumber')) ? clientInfo.clientNumber : 'NA';
    }
    const currentDate = new Date();
    this.formattedDate = formatDate(currentDate, 'MMMM dd, yyyy', 'en-US');

    const transfereeDetails = {
      table: {
        headerRows: 0, widths: ['34%', '33%', '33%'], style: 'programTable',
        body: [
          // Transferee Details
          [
            // Transferee Name
            {
              text: fullName, alignment: 'left', color: '#000000', lineHeight: 1, margin: [0, 10, 0, 0],
              border: [false, false, false, false], fontSize: 9, style: 'avenirBook',
            },
            // Client Number
            {
              text: `Client: ${clientName}`, alignment: 'left', color: '#575A5D', lineHeight: 1,
              border: [false, false, false, false], fontSize: 9, margin: [0, 10, 0, 0], style: 'avenirBook'
            },
            // Generated Date
            {
              text: `Generated: ${this.formattedDate}`, alignment: 'right', color: '#19305A', lineHeight: 1,
              border: [false, false, false, false], fontSize: 9, margin: [0, 10, 0, 0], style: 'avenirBook',
            }
          ],
          [
            // Order#
            {
              text: orderRefNum, alignment: 'left', color: '#19305A', lineHeight: 1, margin: [0, 2, 0, 5],
              border: [false, false, false, false], fontSize: 9, style: 'avenirBook',
            },
            // Client ID
            {
              text: `ID#: ${clientId}`, alignment: 'left', color: '#575A5D', lineHeight: 1,
              border: [false, false, false, false], fontSize: 9, margin: [0, 2, 0, 5], style: 'avenirBook'
            },
            {
              text: ` `, alignment: 'center', color: '#19305A', lineHeight: 1,
              fontSize: 9, border: [false, false, false, false], margin: [0, 2, 0, 5], style: 'avenirBook'
            }
          ]
        ]
      },
      layout: {
        hLineWidth: function (i, node) { return 0.75; },
        vLineWidth: function (i, node) { return 0.75; }
      }
    };
    return transfereeDetails;
  }

  generateProgramDetails(transferee, confirmedPoints, amountUsed?) {
    let totalPoints, programName, pointsUsed, remainingPoints;
    if (transferee) {
      programName = transferee.programDetails.programName;
    }

    if(!this.isMMUser) {
      totalPoints = transferee.programDetails.points;
      pointsUsed = confirmedPoints;
      remainingPoints = totalPoints - pointsUsed;
    } else {
      if (transferee.isCashedOut) {
        amountUsed = transferee.programDetails.programBudget.totalAmount;
      }
      totalPoints = this.programReportGenerationService.numberWithCommas(transferee.programDetails.programBudget.totalAmount) + ' ' + transferee.programDetails.programBudget.currency;
      pointsUsed = this.programReportGenerationService.numberWithCommas(amountUsed) + ' ' + transferee.programDetails.programBudget.currency;
      remainingPoints = this.programReportGenerationService.numberWithCommas(transferee.programDetails.programBudget.totalAmount - amountUsed) + ' ' + transferee.programDetails.programBudget.currency;
    }
    
    let totalHeader = {};
    let usedHeader = {};
    let remainingHeader = {};
    if(!this.isMMUser) {
      totalHeader = {
        text: `Total Points:`, alignment: 'right', color: '#000000', lineHeight: 1, border: [false, true, false, false],
        borderColor: ['', this.programBorderColor, '', ''], fontSize: 11, margin: [0, 18, 0, 0], style: 'avenirMedium'
      };
      usedHeader = {
        text: `Points Used:`, alignment: 'right', color: '#000000', lineHeight: 1, border: [false, false, false, false],
        borderColor: ['', '', '', ''], fontSize: 11, margin: [0, 2, 0, 0], style: 'avenirMedium'
      };
      remainingHeader = {
        text: `Points Remaining:`, alignment: 'right', color: '#000000', lineHeight: 1, border: [false, false, false, true],
        borderColor: ['', '', '', this.programBorderColor], fontSize: 11, margin: [0, 2, 0, 0], style: 'avenirMedium'
      }
    } else {
      totalHeader = {
        text: `Total Amount:`, alignment: 'right', color: '#000000', lineHeight: 1, border: [false, true, false, false],
        borderColor: ['', this.programBorderColor, '', ''], fontSize: 11, margin: [0, 18, 0, 0], style: 'avenirMedium'
      };
      usedHeader = {
        text: `Amount Used:`, alignment: 'right', color: '#000000', lineHeight: 1, border: [false, false, false, false],
        borderColor: ['', '', '', ''], fontSize: 11, margin: [0, 2, 0, 0], style: 'avenirMedium'
      };
      remainingHeader = {
        text: `Amount Remaining:`, alignment: 'right', color: '#000000', lineHeight: 1, border: [false, false, false, true],
        borderColor: ['', '', '', this.programBorderColor], fontSize: 11, margin: [0, 2, 0, 0], style: 'avenirMedium'
      }
    }


    const programDetails = {
      table: {
        headerRows: 0, widths: ['60%', '30%', '10%'], style: 'programTable',
        body: [
          [
            // Program Name
            {
              text: programName, alignment: 'left', color: '#19305A', lineHeight: 1, margin: [0, 10, 0, 0],
              border: [true, true, false, false], borderColor: [this.programBorderColor, this.programBorderColor, '', ''],
              fontSize: 18, style: 'avenirLight',
            },
            // Total Points Header
            totalHeader,
            // Total points value
            {
              text: totalPoints, alignment: 'right', color: '#19305A', lineHeight: 1,
              border: [false, true, true, false], borderColor: ['', this.programBorderColor, this.programBorderColor, ''],
              fontSize: 18, margin: [0, 10, 10, 0], style: 'avenirLight',
            }
          ],
          [
            {
              text: ` `, alignment: 'left', color: '#000000', lineHeight: 1, border: [true, false, false, false],
              borderColor: [this.programBorderColor, '', '', ''], fontSize: 11, margin: [0, 2, 0, 5],
            },
            // Selected Points Header
            usedHeader,
            // Selected points value
            {
              text: pointsUsed, alignment: 'right', color: '#19305A', lineHeight: 1,
              border: [false, false, true, false], borderColor: ['', '', this.programBorderColor, ''],
              fontSize: 14, margin: [0, 2, 10, 0], style: 'avenirLight',
            }
          ],
          [
            {
              text: ` `, alignment: 'left', color: '#000000', lineHeight: 1, border: [true, false, false, true],
              borderColor: [this.programBorderColor, '', '', this.programBorderColor], fontSize: 11, margin: [0, 2, 0, 5],
            },
            // Remaining Points Header
            remainingHeader,
            // Remaining points value
            {
              text: remainingPoints, alignment: 'right', color: '#19305A', lineHeight: 1,
              border: [false, false, true, true], borderColor: ['', '', this.programBorderColor, this.programBorderColor],
              fontSize: 14, margin: [0, 2, 10, 0], style: 'avenirLight',
            }
          ],
        ]
      },
      layout: {
        hLineWidth: function (i, node) { return 0.75; },
        vLineWidth: function (i, node) { return 0.75; }
      }
    };
    if(this.isMMUser) {
      programDetails.table.widths = ['55%', '20%', '25%']
      return programDetails; 
    }
    return programDetails;
  }

  reviewOrConfirm() {
    const displayText = 'Confirmed Benefits';
    return {
      table: {
        headerRows: 0, widths: ['2%', '96%', '2%'], style: 'programTable',
        body: [
          [
            {
              text: ` `, alignment: 'left', color: '#000000', lineHeight: 1, border: [false, false, false, false],
              borderColor: ['', '', '', ''], fontSize: 11, margin: [0, 5, 0, 5], style: 'avenirMedium'
            },
            //  Benefits Selected
            {
              text: displayText, alignment: 'center', color: '#002060', lineHeight: 1,
              border: [false, false, false, false], decoration: 'underline',
              decorationcolor: '#002060',
              fontSize: 14, margin: [0, 5, 0, 0], style: 'avenirMedium'
            },
            {
              text: ` `, alignment: 'right', color: '#19305A', lineHeight: 1,
              border: [false, false, false, false], borderColor: ['', '', '', ''],
              margin: [0, 5, 0, 0], style: 'avenirMedium'
            }
          ]
        ]
      },
      layout: {
        hLineWidth: function (i, node) { return 0.75; },
        vLineWidth: function (i, node) { return 0.75; }
      }
    };
  }

  generateMovePhaseHeader(movePhase) {
    return {
      unbreakable: true,
      stack: [
        {
          table: {
            widths: ['14%', '86%'],
            body: [
              [{ text: '', margin: [0, 5, 0, 5], border: [false, false, false, false] },
              { text: '', margin: [0, 5, 0, 5], border: [false, false, false, false] },
              ],
              [{
                text: 'Move Phase: ', style: 'avenirMedium', border: [false, false, false, false],
                fontsize: 11, margin: [0, 5, 0, 5], color: '#575A5D',
              },
              {
                text: movePhase[0].toUpperCase() + movePhase.slice(1), style: 'avenirMedium',
                border: [false, false, false, false], fontsize: 11, margin: [0, 5, 0, 5],
              }
              ]
            ]
          }
        }
      ]
    };
  }

  generateBenefitCard(benefitDetails) {
    // tslint:disable-next-line: prefer-const
    let individualBenefit = [];
    const confirmedText = 'Confirmed On: ';
    benefitDetails.forEach(value => {
      let pointsText;
      if(this.isMMUser) {
        if(value.budget && value.confirmedCount) {
          pointsText = this.programReportGenerationService.numberWithCommas(value.budget.total.estimatedAmount.amount * value.confirmedCount) + ' ' + value.budget.total.estimatedAmount.currency;
        } else if(value.budget) {
          pointsText = this.programReportGenerationService.numberWithCommas(value.budget.total.estimatedAmount.amount) + ' ' + value.budget.total.estimatedAmount.currency;
        }else {
          pointsText = 'Core Benefit';
        }
      } else {
        if(!value.currency) {
          value.points == 'Guaranteed' ? pointsText =`Core Benefit 0 Pts`: value.tierValues ? pointsText = `${value.tierValues[0].value} Pts` : value.productMonetaryValue ? pointsText = `${value.points} Point(s): ${value.productMonetaryValue.amount} ${value.productMonetaryValue.currency}` : pointsText = `${value.points} Pts`;
        } else {
          value.points ===  0? '0 Pts': pointsText = `${value.points}`
          pointsText.concat(`Point(s): ${formatNumber(Math.ceil(value.value), 'en-US')} ${value.currency}`)
        }
      }
      individualBenefit.push([
        {
          text: value.confirmedCount ? value.displayName + ' x ' + value.confirmedCount : value.displayName, border: [true, true, false, true], borderColor: [this.benefitsBorderColor,this.benefitsBorderColor,'',this.benefitsBorderColor],
          color:'#222222',fontSize: 11, margin: [0, 4, 0, 4], style: 'avenirMedium', fillColor: this.benefitsCardFillColor,
        },
        {
          text: confirmedText,
          style: 'avenirBook', border: [false, true, false, true], borderColor: ['',this.benefitsBorderColor,'',this.benefitsBorderColor],
          color: '#707070', fontSize: 9, alignment: 'right', margin: [0, 6, 0, 0], fillColor: this.benefitsCardFillColor
        },
        {
          text: value.confirmedDate,
          style: 'avenirBook', border: [false, true, false, true], borderColor: ['',this.benefitsBorderColor,'',this.benefitsBorderColor],
          color: '#707070', fontSize: 11,alignment: 'left', margin: [0, 4, 0, 4], fillColor: this.benefitsCardFillColor
        },
        {
          text: pointsText,
          style: 'avenirMedium', border: [false, true, true, true], borderColor: ['',this.benefitsBorderColor,this.benefitsBorderColor,this.benefitsBorderColor],
          color: '#222222', fontSize: 11,alignment: 'right', margin: [0, 4, 0, 4], fillColor: this.benefitsCardFillColor
        }
      ]);

      individualBenefit.push([
        {
          text: '', margin: [0, 3, 0, 3], border: [false, false, false, false]
        },
        {
          text: '', margin: [0, 0, 0, 0], border: [false, false, false, false]
        },
        {
          text: '', margin: [0, 0, 0, 0], border: [false, false, false, false]
        },
        {
          text: '', margin: [0, 3, 0, 3], border: [false, false, false, false]
        },
      ]);
    });
    const benefitCard = {
      table: { widths: ['50%', '13%', '12%', '25%'], body: individualBenefit }
    };
    return benefitCard;
  }

  /**
  * none added block
  */
  boxCard() {
    return {
      unbreakable: true,
      stack: [
        {
          table: {
            headerRows: 0, widths: ['20%', '60%', '20%'], style: 'programTable',
            body: [
              [
                {
                  text: '', style: 'avenirMedium', border: [true, true, false, true], decoration: 'dashed',
                  fontsize: 18, margin: [0, 2, 0, 2], color: '#575A5D', borderColor: [this.benefitsBorderColor, this.benefitsBorderColor, '', this.benefitsBorderColor]
                },
                {
                  text: 'None Added', color: '#575A5D', style: 'avenirMedium', border: [false, true, false, true],
                  fontsize: 18, margin: [0, 2, 0, 2], alignment: 'center', borderColor: ['', this.benefitsBorderColor, '', this.benefitsBorderColor,]
                },
                {
                  text: '', style: 'avenirMedium', border: [false, true, true, true],
                  fontsize: 18, margin: [0, 2, 0, 2], color: '#575A5D', borderColor: ['', this.benefitsBorderColor, this.benefitsBorderColor, this.benefitsBorderColor]
                }
              ]
            ]
          }
        }
      ],
      layout: {
        hLineWidth: function (i, node) { return 0.75; },
        vLineWidth: function (i, node) { return 0.75; },
        hLineStyle: function (i, node) {
          return { dash: { length: 2, space: 1 } };
        },
        vLineStyle: function (i, node) {
          return { dash: { length: 2 } };
        }
      }
    };
  }
  /**
* line diverder 
*/
  lineDiveder() {
    return {
      table: {
        headerRows: 0, widths: ['50%', '50%'], style: 'programTable',
        body: [
          [
            {
              text: ``, style: 'avenirMedium', border: [false, true, false, false], fontsize: '0.5', margin: [0, 5, 0, 0],
              borderColor: [this.benefitsBorderColor, this.benefitsBorderColor, this.benefitsBorderColor, this.benefitsBorderColor]
            },
            {
              text: ``, style: 'avenirMedium', border: [false, true, false, false], fontsize: '0.5', margin: [0, 5, 0, 0],
              borderColor: [this.benefitsBorderColor, this.benefitsBorderColor, this.benefitsBorderColor, this.benefitsBorderColor]
            }
          ]
        ]
      },
      layout: {
        hLineWidth: function (i, node) { return 0.75; },
        vLineWidth: function (i, node) { return 0.75; }
      }
    };
  }
  /**
   * space between item cards
   */
  cardSpacing() {
    return {
      table: {
        body: [
          [
            {
              text: '', margin: [0, 2, 0, 2], border: [false, false, false, false],
            }
          ]
        ]
      },
      layout: {
        hLineWidth: function (i, node) { return 0.75; },
        vLineWidth: function (i, node) { return 0.75; }
      }
    };
  }

  generateBenefitsforConfirmed(confirmedBenefits) {
    let benefitsArray = [];
    
    confirmedBenefits.forEach(function (value) {
      benefitsArray = benefitsArray.concat(this.generateMovePhaseHeader(value.group));
      if (value.items.length === 0) {
        benefitsArray = benefitsArray.concat(this.boxCard());
        benefitsArray = benefitsArray.concat(this.cardSpacing());
        benefitsArray = benefitsArray.concat(this.lineDiveder());
      } else {
        benefitsArray = benefitsArray.concat(this.generateBenefitCard(value.items));
        benefitsArray = benefitsArray.concat(this.lineDiveder());
      }
    }.bind(this));
    return benefitsArray;
  }
}

import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from '@angular/core';
import { BaseClientService } from './../../../core/services/base-client.service';
import { map, catchError } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { RemoteLoggingService } from './../../../core/services/remote-logging.service';
export interface clientApplicationAccessData {
  _id?: string,
  name?: string,
  collectionName?: string,
  displayOrder?: number,
  metaSchema?: Array < {
    _structureType?: string,
    propertyName?: string,
    displayName?: string,
    description?: string,
    tooltip?: string,
    dataType?: string,
    fieldType?: string,
    selectionType?: string,
    enabled?: {
        when?: {
            prop?: string,
            is?: boolean
        }
    },
    isRequired?: {
        when?: {
            prop?: string,
            is?: boolean
        }
    },
    values?: Array<String>
  } > ;
}

@Injectable({
  providedIn: 'root'
})
export class ClientScreenApplicationAccessService {

  constructor(private baseClientService: BaseClientService, private logSvc: RemoteLoggingService) { }

  getClientScreenApplicationAcces(): Observable<clientApplicationAccessData[]> {
		return this.baseClientService
			.getOne<any>(`${'v1/admin/meta-schema?pageId=ClientDetailsMetaModels'}`)
			.pipe(
				map(r => r.body),
				catchError(err => {
					this.logSvc.logError(err);
					const emptyArray = null;
					return of(emptyArray);
				})
			);
	}
  
  getClientScreenAppRoleDetails(partyId,client): Observable<any[]> {
		return this.baseClientService
			.getOne<any>(`${`v1/admin/client-roledetails?partyId=${partyId}&roleName=client`}`)
			.pipe(
				map(r => r.body),
				catchError(err => {
					this.logSvc.logError(err);
					const emptyArray = null;
					return of(emptyArray);
				})
			);
	}

    /** post application access changes  */
    saveApplicationAccessChanges(payload) {
      return this.baseClientService.post<any>(`v1/admin/client-roledetails`, payload).pipe(
        map(r => r.body), catchError((err, source) => {
          const empty: any = null;
          console.log('Failed to save client application access changes ', err);
          return of(empty);
        })
      );
    }

    /** put application access changes  */
    updateApplicationAccessChanges(payload, clientId) {
      return this.baseClientService.put<any>(`v1/admin/client-roledetails`, payload).pipe(
        map(r => r.body), catchError((err, source) => {
          const empty: any = null;
          console.log('Failed to save client application access changes ', err);
          return of(empty);
        })
      );
    }
}

import { Component, OnInit, Inject, ViewEncapsulation, Input } from '@angular/core';
import { ModalData } from '../../../../core/services/popup-position.service';
// import { DateConversionService } from 'src/app/core/services/date-conversion.service';
import { DateConversionService } from '../../../../core/services/date-conversion.service';
import { MovePhaseService } from '../../../../core/services/move-phase.service';

@Component({
  selector: 'app-move-risk-modal',
  templateUrl: './move-risk-modal.component.html',
  styleUrls: ['./move-risk-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MoveRiskModalComponent implements OnInit {
  @Input() traditional: boolean;
  @Input() movePhaseAndRiskData:any
  constructor(
    @Inject(ModalData) public data: any,
    public readonly dateConversionService: DateConversionService,
    private movePhaseService: MovePhaseService
  ) {}

  /** Init method */
  ngOnInit() {
    this.movePhaseAndRiskData ? 
   this.createTraditionalMoveRiskData(this.movePhaseAndRiskData) : null;
  }

  createTraditionalMoveRiskData(data: any) {
    let tempMovePhaseandRiskData = {
      "transferDate": data.effectiveTransferDate,
      "predictedDate":data.estimatedMoveDate,
      "riskType":data.moveRiskVal,
      "predictedDateEarly":data.estimatedMoveDateEarly,
      "predictedDateLate":data.estimatedMoveDateLate,
      "moveRiskCompletionDt":data.moveRiskCompletionDt?data.moveRiskCompletionDt:""
    };
    this.data = tempMovePhaseandRiskData;
  }

  createMoveRiskData(data: any) {
    let tempMovePhaseandRiskData = {
      "transferDate": data.transferDate,
      "predictedDate":data.predictedDate,
      "riskType":data.riskType,
      "predictedDateEarly":data.predictedDateEarly,
      "predictedDateLate":data.predictedDateLate,
      "moveRiskCompletionDt":data.moveRiskCompletionDt?data.moveRiskCompletionDt:""
    };
    this.data = tempMovePhaseandRiskData;
  }


  /** Method to close Modal */
  close() {
    this.data.overlayRef.dispose();
  }
}

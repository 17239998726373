import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { Milestones } from '../models/milestones.model';
import { HttpParams } from '@angular/common/http';

/** MilestoneService to fetch Milestone details from Api and to display in the Milestone List*/
@Injectable({
  providedIn: 'root'
})
export class MilestoneService {

/** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
   */
  constructor(private baseClient: BaseClientService, private logSvc: RemoteLoggingService) { }

  /** getMilestonesByFileNum() to fetch Milestone details from Api */
  getMilestonesByFileNum(fileNumber: number, itemsPerPage: number = 0): Observable<Milestones> {
    let params: HttpParams;
    if (itemsPerPage > 0) {
      params = new HttpParams({ fromObject: {
        'itemsPerPage': itemsPerPage.toString()
      }});
    }
    return this.baseClient
      .getOne<Milestones>(`v1/milestone/${fileNumber}`, 'get the milestone details')
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: Milestones = null;
          this.logSvc.logError(err);
          return of(empty);
        })
      );
  }
  getMilestonesByFileNumber(fileNumber: any,orderRequestId:number, itemsPerPage: number = 0): Observable<Milestones> {
    let params: HttpParams;
    if (itemsPerPage > 0) {
      params = new HttpParams({ fromObject: {
        'itemsPerPage': itemsPerPage.toString()
      }});
    }
    return this.baseClient
      .getOne<Milestones>(`v1/milestone/${fileNumber}?context=benefits-builder&orderRequestId=${orderRequestId}`, 'get the milestone details')
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: Milestones = null;
          this.logSvc.logError(err);
          return of(empty);
        })
      );
  }

  
}

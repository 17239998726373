import { Component, Input, ViewEncapsulation, ElementRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef, OverlayContainer } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { PopupPositionService } from '../../services/popup-position.service';
import { MoveRiskModalComponent } from '../../../public/dashboard/components/move-risk-modal/move-risk-modal.component';

/**
 * Interprets Move-Risk value passed in through the moveRiskData input
 *  View type is dictated by the viewType value passed.  Currently accepts 'default' for employee-details
 * table template and 'ahggregate' for aggregate table template
 */

@Component({
  selector: 'app-move-risk',
  templateUrl: './move-risk.component.html',
  styleUrls: ['./move-risk.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MoveRiskComponent {

  /** To get the moveRisk data */
  @Input() moveRiskData;

  overlayRef: OverlayRef;

  constructor(private overlay: Overlay,
    private readonly positionService: PopupPositionService) {
  }

  /** Open the move Risk dialog model*/
  openMoveRiskModal(evt, data: any) {
    if (evt.type === 'click' || evt.keyCode === 13) {

      const target = this.getPositionByEvents(evt);
      const element = new ElementRef(target);
      const positionStrategy = this.placeByPositionStrategy(element);
      const overlayConfig = new OverlayConfig({
        width: '315px',
        height: '430px',
        panelClass: 'moveRiskModal',
        hasBackdrop: true,
        backdropClass: 'mat-backdrop-transparent',
        positionStrategy,
      });
      this.overlayRef = this.overlay.create(overlayConfig);
      const containerPortal = new ComponentPortal(MoveRiskModalComponent, null, this.positionService.createInjector({
        transferDate: data.effectiveTransferDate,
        predictedDateEarly: data.predictedDateEarly,
        predictedDateLate: data.predictedDateLate,
        predictedDate: data.estimatedMoveDate,
        riskType: data.moveRiskLevel,
        overlayRef: this.overlayRef
      }));
      this.overlayRef.attach(containerPortal);
      setTimeout(() => {
        this.overlayRef.updatePosition();
        this.overlayRef.addPanelClass('visibility');
        this.overlayRef.addPanelClass('cdk-focused');
      }, 100);
      this.overlayRef.backdropClick().subscribe(() => {
        this.overlayRef.removePanelClass('visibility');
        this.overlayRef.dispose();
      });

    }

  }

   /** Open the move Risk dialog model*/
   openMoveRiskCompleteModal(evt, data: any) {
    if (evt.type === 'click' || evt.keyCode === 13) {

      const target = this.getPositionByEvents(evt);
      const element = new ElementRef(target);
      const positionStrategy = this.placeByPositionStrategy(element);
      const overlayConfig = new OverlayConfig({
        width: '315px',
        height: '309px',
        panelClass: 'moveRiskCompleteModal',
        hasBackdrop: true,
        backdropClass: 'mat-backdrop-transparent',
        positionStrategy,
      });
      this.overlayRef = this.overlay.create(overlayConfig);
      const containerPortal = new ComponentPortal(MoveRiskModalComponent, null, this.positionService.createInjector({
        transferDate: data.transferDate,
        predictedDateEarly: data.predictedDateEarly,
        predictedDateLate: data.predictedDateLate,
        predictedDate: data.estimatedMoveDate,
        riskType: data.moveRiskLevel,
        moveRiskCompletionDt: data.moveRiskCompletionDt,
        overlayRef: this.overlayRef
      }));
      this.overlayRef.attach(containerPortal);
      setTimeout(() => {
        this.overlayRef.updatePosition();
        this.overlayRef.addPanelClass('visibility');
        this.overlayRef.addPanelClass('cdk-focused');
      }, 100);
      this.overlayRef.backdropClick().subscribe(() => {
        this.overlayRef.removePanelClass('visibility');
        this.overlayRef.dispose();
      });

    }

  }

  /** get the position by events of the target */
  getPositionByEvents(evt) {
    if (evt.type === 'click') {
      return {
        getBoundingClientRect: () => ({
          bottom: evt.clientY,
          height: 0,
          left: evt.clientX,
          right: evt.clientX,
          top: evt.clientY,
          width: 0,
        }),
      };
    } else if (evt.keyCode === 13) {
      const rect = evt.target.getBoundingClientRect();
      return {
        getBoundingClientRect: () => ({
          bottom: rect.y,
          height: 0,
          left: rect.x,
          right: rect.x,
          top: rect.y,
          width: 0
        }),
      };
    }
  }

  /** place the pop up by position strategy */
  placeByPositionStrategy(element: ElementRef) {
    return this.overlay
      .position()
      .flexibleConnectedTo(element)
      .withFlexibleDimensions(false)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'bottom',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);
  }
}

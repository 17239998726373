<div>
<div class="titleAndCloseButton" fxLayout="row" fxLayoutAlign="space-between">
<mat-dialog-title><h2><strong>Delete Transferee</strong></h2></mat-dialog-title>
<span class="close-icon" (click)="onCancel($event)" title="close">
    <mat-icon  mat-dialog-close>close</mat-icon>
</span>
</div>

<p>You are about to delete the following transferee:</p>
<p class="nameDetailsBackground">
{{this.data.lastName}},{{this.data.firstName}}
</p>
    <h4><strong>Please be aware:</strong></h4>
    <li>All related data previously entered will be lost.</li>
    <br><br>
    <div class="deleteRecordPopup">
    <mat-dialog-action>
        <button mat-button style="color: #0e6af5;" (click)="onCancel($event)">CANCEL</button> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button mat-button style="background-color: #0e6af5;color: white;" (click)="onClickDelete()">Confirm Delete</button>
    </mat-dialog-action>
    </div>
</div>
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseClientService } from '../services/base-client.service';
import { RemoteLoggingService } from '../services/remote-logging.service';
import { map, catchError } from 'rxjs/operators';
import { FilterDetails } from '../models/filter-details.model';

@Injectable({
  providedIn: 'root'
})
export class FilterDetailsService {

  /** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
   */

  constructor(protected baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService) { }

  /** getFilterDetails() method to get the reponse from the api using base client service */
  getFilterDetails(): Observable<Array<FilterDetails>> {
    return this.baseClient.getArr<FilterDetails>('v1/filterDetails', 'get the filter details data').pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: FilterDetails[] = [];
        this.logSvc.logError(err);
        return of(empty);
      })
    );
  }
}

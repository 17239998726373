<!-- Restrictions -->
<ng-container *ngIf="type === 'restrictions'">
    <div fxFlex="100" class="tab-content">
        <h4><span fxFlex = "80" *ngIf="(benefit.scopes && 
            ((benefit.scopes.spouse_partner && benefit.scopes.spouse_partner.spousePartnerRelocating) ||
            (benefit.scopes.valid_complex_rules && benefit.scopes.valid_complex_rules.length >0) || 
            (benefit.scopes.departure_locations  ) || (benefit.scopes.destination_locations))
            )">Restrict this benefit to: </span></h4>
        <div  *ngIf="isRestrictionsAdded; else noInfoAdded">
            <!---For Transfereeswith Spouse-->
            <div *ngIf="benefit.scopes.spouse_partner &&
                    benefit.scopes.spouse_partner.spousePartnerRelocating"><span>Transferees with Spouse</span></div>
            <!---For Transfereeswith Spouse-->
            <div *ngIf="benefit.scopes.triggered_access &&
                benefit.scopes.triggered_access.hasOwnProperty('percentage') && 
                benefit.scopes.triggered_access.hasOwnProperty('noOfDays') && 
                benefit.scopes.triggered_access.hasOwnProperty('milestone')">
                <span><strong class="cashout_triggered">Triggered</strong> access to <strong class="cashout_triggered">
                    {{benefit.scopes.triggered_access.percentage}}% </strong>of benefit <strong class="cashout_triggered">
                        {{benefit.scopes.triggered_access.noOfDays}} Days After {{benefit.scopes.triggered_access.milestone}}
                    </strong>
                </span>
            </div>
            <!---For ComplexRules-->
            <div *ngIf="benefit.scopes.valid_complex_rules && benefit.scopes.valid_complex_rules.length > 0">
                <span *ngFor="let element of benefit.scopes.valid_complex_rules">
                    {{element.name}}<br/>
                </span>
            </div>
            <!---For Departure Locations-->
            <div *ngIf="benefit.scopes.departure_locations">
                <div *ngIf="benefit.scopes.departure_locations.includes && benefit.scopes.departure_locations.includes.length > 0">
                    <span class = "rule">Departure Location Includes  </span><br/>
                    <span *ngFor="let element of benefit.scopes.departure_locations.includes | sortBy: 'asc' ; let i = index">{{element }}
                        {{i < benefit.scopes.departure_locations.includes?.length -1 ? ', ' : ''}}
                        <!-- <span *ngIf="benefit.scopes.departure_locations.includes.length >1">,</span> -->
                    </span>
                </div>
                <div *ngIf="benefit.scopes.departure_locations.excludes && benefit.scopes.departure_locations.excludes.length > 0">
                    <span class = "rule">Departure Location Excludes  </span><br/>
                    <span *ngFor="let element of benefit.scopes.departure_locations.excludes | sortBy: 'asc' ; let i = index">{{element }}
                        {{i < benefit.scopes.departure_locations.excludes?.length -1 ? ', ' : ''}}
                        <!-- <span *ngIf="benefit.scopes.departure_locations.excludes.length >1">,</span> -->
                    </span>
                </div>
            </div>
            <!---For Destination Locations-->
            <div *ngIf="benefit.scopes.destination_locations">
                <div *ngIf="benefit.scopes.destination_locations.includes && benefit.scopes.destination_locations.includes.length > 0">
                    <span class = "rule">Destination Location Includes  </span><br/>
                    <span *ngFor="let element of benefit.scopes.destination_locations.includes | sortBy: 'asc' ; let i = index">{{element }}
                        {{i < benefit.scopes.destination_locations.includes?.length -1 ? ', ' : ''}}
                        <!-- <span *ngIf="benefit.scopes.destination_locations.includes.length >1">,</span> -->
                    </span>
                </div>
                <div *ngIf="benefit.scopes.destination_locations.excludes && benefit.scopes.destination_locations.excludes.length > 0">
                    <span class = "rule">Destination Location Excludes  </span><br/>
                    <span *ngFor="let element of benefit.scopes.destination_locations.excludes | sortBy: 'asc' ; let i = index">{{element }}
                        {{i < benefit.scopes.destination_locations.excludes?.length -1 ? ', ' : ''}}
                        <!-- <span *ngIf="benefit.scopes.destination_locations.excludes.length >1">,</span> -->
                    </span>
                </div>
            </div>
            <!--Number of Relocating Children-->
            <div *ngIf="benefit.scopes.simple_rules && benefit.scopes.simple_rules.length">
                <div *ngFor="let element of benefit.scopes.simple_rules">
                    <span class = "rule"> {{element.type}} </span><br/>
                    <span *ngFor="let val of element.values">
                        <span>{{val.operator}} {{val.value}}</span><br/>
                    </span>                                        
                </div>                
            </div>
        </div>
        <ng-template #noInfoAdded>
            <p>No Information Added</p>
        </ng-template>
    </div>
</ng-container>
<!--Rules-->
<ng-container *ngIf="type === 'rules'">
    <div fxFlex = "100"  class="tab-content">
        <div  *ngIf = "((benefit.exclusions && benefit.exclusions?.length > 0 )|| (benefit.prerequisiteRule && benefit.prerequisiteRule?.length > 0) || (benefit.inclusions && benefit.includeRule?.length > 0))">
            <h4><span fxFlex = "80">If this benefit is selected: </span></h4>
            <div *ngIf="benefit.prerequisiteRule && benefit.prerequisiteRule?.length > 0"><span class = "rule">Enable  </span> <span *ngFor="let element of benefit.prerequisiteRule; let i = index">{{element.displayName}}{{i < benefit.prerequisiteRule?.length -1 ? ', ' : '.'}}</span></div>
            <div *ngIf="benefit.exclusions && benefit.exclusions?.length > 0"><span class = "rule">Disable  </span> <span *ngFor="let element of benefit.excludeRule; let i = index">{{element}}{{i < benefit.excludeRule.length -1 ? ', ' : '.'}} </span></div>
            <div *ngIf="benefit.inclusions && benefit.inclusions?.length > 0"><span class = "rule">Select  </span> <span *ngFor="let element of benefit.includeRule; let i = index">{{element}}{{i < benefit.includeRule.length -1 ? ', ' : '.'}} </span></div>
        </div>
        <!--No Information Added-->
        <div *ngIf="(!benefit.exclusions || !benefit.exclusions?.length) && 
        (!benefit.prerequisiteRule || !benefit.prerequisiteRule?.length) && 
        (!benefit.includeRule || !benefit.includeRule?.length)">
            <p>No Information Added</p>              
        </div> 
    </div>
</ng-container>
<!--Tasks-->
<ng-container *ngIf="type === 'tasks'">
    <div fxFlex = "100"  class="tab-content">
        <div  *ngIf = "isTasksAdded; else noInfoAdded">
            <h4><span fxFlex = "80">Tasks: </span></h4>
            <div  class="tab-content tasks-content">
                <ul><li *ngFor="let element of benefit.tasks; let i = index">{{element}}</li></ul>
            </div>
        </div>
        <ng-template #noInfoAdded>
            <p>No Information Added</p>
        </ng-template>
    </div>
</ng-container>
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header class='panel-heading'>
            Special Instructions (optional)&nbsp; <button class="myfab"
            mat-mini-fab>{{specialInstructions.value.categories?.length || 0}}</button>
        </mat-expansion-panel-header>
        <ng-container [formGroup]="specialInstructionsFormGroup" *ngIf="editMode">
            <ng-container formGroupName="specialInstructions">
                <div fxLayout="row" fxLayoutAlign="space-evenly" fxLayoutGap="1em" class="addcandidate-toppadding"
                    fxLayout.xs="column" fxLayoutGap="1em" fxLayoutGap.xs="0px">
                    <mat-form-field fxFlex="100%">
                        <mat-label>Relates To</mat-label>
                        <mat-select formControlName="categories" multiple>
                            <ng-container *ngIf="splInstructionCategories$ | async as splInstructionCategories">
                                <mat-option *ngFor="let category of splInstructionCategories.values"
                                [value]="category">{{category}}
                            </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-evenly" fxLayoutGap="1em" class="addcandidate-toppadding"
                    fxLayout.xs="column" fxLayoutGap="1em" fxLayoutGap.xs="0px">
                    <mat-form-field  appearance="outline" class="align-text-area" fxFlex="100%">
                        <textarea formControlName="comment" matInput cdkTextareaAutosize cdkAutosizeMinRows="4" cdkAutosizeMaxRows="10"
                          placeholder="Please provide instructions"></textarea>
                    </mat-form-field>
                </div>
                <div class="align-right">
                    <button mat-button class="text-button" [disabled]="moveStatus && moveStatus !== 'Invitation Not Sent'" (click)="onClear()">Clear</button>
                </div>
            </ng-container>
        </ng-container>
    </mat-expansion-panel>
</mat-accordion>
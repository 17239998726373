<ngx-spinner bdColor="rgb(148,148,148, 0.6)" size="medium" color="#fff" type="ball-scale-multiple" fullScreen="true" aria-live="assertive">
  <p aria-busy="true" style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div fxLayout="row" fxFill
[ngClass]="{ 'no-animation' : !envAnimation, 'blank-template-container' : !showGlobalTemplate }">
<div fxFlex="250px" fxHide.lt-md class="sidebar-desktop" *ngIf="isSidebarDisplay && showGlobalTemplate && !viewOriginFlag"
  [ngClass]="{ 'sidebar-desktop-min' : sidebarMinimized }">
  <div fxFill fxLayout="column" fxLayoutAlign="space-between stretch">
    <div fxFlex="nogrow" fxLayoutAlign="center center" class="header-desktop product-desktop">
      <div class="moveProLogoSection" fxFlex="250px">
        <a tabindex="0" aria-label="Move Pro logo link to dashboard" matTooltip="To Dashboard"
          href="/#/dashboard"><span class="moveProLogo"></span></a>
      </div>
    </div>
    <div fxFlex class="nav-container-desktop">
      <!--app-nav-list #desktopNavList [minimizable]="true"></app-nav-list-->
    </div>
    <div fxFlex="nogrow">
      <hr class="sidebar-hr" />
    </div>
    <div fxFlex="nogrow" class="sidebar-desktop-toggle">
      <button fxFill fxLayoutAlign="end" type="button" mat-button (click)="sidebarToggle()">
        <div fxFill fxLayoutAlign="center center" class="icon-container">
          <mat-icon class="material-icons-outlined" [ngClass]="{ 'sidebar-desktop-toggle-min' : sidebarMinimized }">
            eject</mat-icon>
        </div>
      </button>
    </div>
  </div>
</div>
<div fxFlex="calc(100% - 250px)" *ngIf="!viewOriginFlag">
  <div class="content-col-desktop" *ngIf="showGlobalTemplate">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxHide.lt-md class="header-desktop header-desktop-r">
      <div class="moveProLogoSection" fxFlex="250px">
        <a tabindex="0" aria-label="Move Pro logo link to dashboard" matTooltip="To dashboard" (click)="openDashboard()"
          ><span class="moveProLogo"></span></a>
      </div>
      <div fxFlex fxLayoutAlign="center" class="app-global-search" *ngIf="contextSelected && router.url !== '/administration'">
        <app-global-search></app-global-search>
      </div>
      <div fxLayout="row" *ngIf="showLogout && router.url!=='/administration' " class="icon-text-link-container logout" fxLayoutAlign="flex-end center">
        <div fxLayout="row" class="icon-text-link" tabindex="0" (click)="logoutModal()"
          (keypress)="logoutModal()" (mouseover)="logoutImg.src = logoutImg.srcOn" (mouseleave)="logoutImg.src = logoutImg.srcOut"
          (mousedown)="logoutImg.src = logoutImg.srcOn">
          <a fxLayout="row" fxLayoutAlign="flex-start flex-end">
            Logout
          </a>
          <img [src]="logoutImg.src || logoutImg.srcOut" alt="logoutImg"/>
        </div>
      </div>
      <span fxFlex="60px" fxLayoutAlign="center" fxHide>
        <button type="button" mat-button>
          <mat-icon>help</mat-icon>
        </button>
      </span>
      <span fxFlex="60px" fxLayoutAlign="center" fxHide>
        <button type="button" mat-button>
          <mat-icon>forum</mat-icon>
        </button>
      </span>
      <span fxFlex="60px" fxLayoutAlign="center" fxHide>
        <button type="button" mat-button>
          <mat-icon>notifications</mat-icon>
        </button>
      </span>
      <span fxFlex="220px" fxLayoutAlign="end" *ngIf="contextSelected">
        <app-user-context></app-user-context>
      </span>
    </div>
  </div>
  <div fxHide.gt-sm>
    <div class="header-mobile">
      <div fxFill fxLayout="row" fxLayoutAlign="space-between center">
        <div class="moveProLogoSection" fxFlex="70px" fxFlex.lt-md="90px"
          [ngClass]="{'hideElement' : this.occupyFullWidth}">
          <a tabindex="0" aria-label="Move Pro logo link to dashboard" matTooltip="To dashboard" href="/#/dashboard">
            <span class="moveProLogo"></span></a>
        </div>
        <span fxFlex class="header-mobile-search" *ngIf="contextSelected">
          <mat-form-field appearance="outline" flex [ngClass]="{'occupyFullWidth' : this.occupyFullWidth}">
            <input tabindex="0" fxFlex="100px" class="search-input" name="query" [(ngModel)]="this.query" matInput
              placeholder="Search" aria-label="Search" (keyup)="getSearchResults(query)" (focus)="showError(query)"
              (blur)="hideError(query)">
            <button fxFlex="20px" class="header-mobile-clear-btn" mat-button *ngIf="query" matSuffix mat-icon-button
              aria-label="Clear" (click)="clearSearch()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </span>
        <div fxLayout="row" *ngIf="showLogout  && router.url!=='/administration'" class="icon-text-link-container logout" fxLayoutAlign="flex-end center">
          <div fxLayout="row" class="icon-text-link" tabindex="0" (click)="logoutModal()"
            (keypress)="logoutModal()" (mouseover)="logoutImg.src = logoutImg.srcOn" (mouseleave)="logoutImg.src = logoutImg.mobileSrcOff"
            (mousedown)="logoutImg.src = logoutImg.srcOn">
            <!-- <mat-icon>exit_to_app</mat-icon> -->
            <img [src]="logoutImg.src || logoutImg.mobileSrcOut" alt="logoutImg"/>
          </div>
        </div>
        <span fxFlex="65px" fxLayoutAlign="end" *ngIf="contextSelected" class="context-mobile"
          [ngClass]="{'hideElement' : this.occupyFullWidth}">
          <app-user-context></app-user-context>
        </span>
      </div>
    </div>
    <!-- Search Results for mobile view -->
    <div class="search-bar-sm" *ngIf="contextSelected">
      <div fxHide.gt-sm flex>
        <div>
          <mat-option *ngIf="showMsg" class="no-padding">
            <div role="main" aria-hidden="true" class="search-msg small-txt"> <strong> {{ responseMessage }} </strong></div>
          </mat-option>
          <div role="main" aria-hidden="true" *ngIf="showCustomMsg" class="search-msg small-txt no-padding">
            <strong>{{ responseMessage1 }}</strong>
            <br /> {{ responseMessage2 }}
            <br />{{ responseMessage3 }}
          </div>
          <div *ngIf="showResults" class="result-container">
            <div class="option-header" *ngIf="showResults">
              <span style="float:left;">Name</span>
              <span style="float:right;">File # </span>
            </div>
            <mat-option *ngFor="let option of filteredOptions" style="clear:both;"
              routerLink="/empInfo/{{option.fileNumber}}">
              <a routerLink="/empInfo/{{option.fileNumber}}">
                <span style="float:left;">
                  <span class="link-color" [innerHTML]="option.firstName | highlightSearch: query">
                    {{ option.firstName }} </span>
                  <span class="link-color" [innerHTML]="option.lastName | highlightSearch: query"
                    style="padding-left:4px;"> {{option.lastName}}</span>
                </span>
                <span style="float:right;" class="link-color"
                  [innerHTML]="option.fileNumber | highlightSearch: filterText"> {{ option.fileNumber }} </span>
              </a>
            </mat-option>
          </div>
        </div>
      </div>
    </div>
  </div>

  <mat-drawer-container fxFill [ngClass.lt-md]="{ 'content-mobile' : true, 'blank-template' : !showGlobalTemplate }"
    [ngClass.gt-sm]="{ 'content-desktop' : true, 'blank-template' : !showGlobalTemplate}">
    <mat-drawer fxHide.gt-sm #nav mode="over" class="nav-mobile" (window:resize)="nav ? nav.close() : true"
      [autoFocus]="false" *ngIf="showGlobalTemplate">
      <div fxFill fxLayout="column" fxLayoutAlign="space-between">
        <div fxFlex>
          <!--app-nav-list #mobileNavList></app-nav-list-->
        </div>
        <div fxFlex="nogrow">
          <hr class="sidebar-hr" />
        </div>
        <div fxFlex="nogrow" class="sidebar-mobile-logout">
          <button fxFill fxLayoutAlign="end" type="button" mat-button (click)="logout()">
            <span>Logout</span>
            <mat-icon>exit_to_app</mat-icon>
          </button>
        </div>
      </div>
    </mat-drawer>
    <mat-drawer-content id = "scrollContainer">

      <!-- Cartus Online Link -->
      <!-- Needs to be hidden if user is employee or manager of Cartus -->
      <div fxLayout="row" *ngIf="isViewer === false" class="cartus-online" fxLayoutAlign="flex-end center">
        <div fxLayout="row" tabindex="0" (click)="openCartusOnline($event)" (keypress)="openCartusOnline($event)">
          <a fxLayout="row" fxLayoutAlign="flex-start flex-end">
            Launch CartusOnline</a>
          <em class="material-icons">open_in_new</em>
        </div>      
      </div>

      <!-- Main Content -->
      <div fxFill [ngClass]="{ 'main-content' : showGlobalTemplate }"
        (click)="!showGlobalTemplate||(nav ? nav.close() : true)" *ngIf="contextSelected">
        <router-outlet></router-outlet>
        <div [ngClass]="{ 'main-bottom' : showGlobalTemplate }"></div>
      </div>

      <!-- Client Contact -->
      <div fxFill class="main-content" *ngIf="!contextSelected && !viewerFlag">
        <div *ngIf="displayContextSelection">
          <div class="contexts-content">
            <h1>Select Account</h1>
            <div class="emp-details mat-elevation-z8">
              <div class="table-scroll">
                <table matSort mat-table [dataSource]="dataSource" (matSortChange)="clearInitialSort()">
                  <!-- Company Column -->
                  <ng-container matColumnDef="company" sticky>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [@.disabled]="initialSortColumn === 'company'"
                     [start]="initialSortDirection" [ngClass]="{'initialColumnSelected': initialSortColumn === 'company' }"> Company </th>
                    <td mat-cell *matCellDef="let element">
                      <div>
                        <a fxFlex.layout="stretch baseline" (click)="selectContext(element)"><span class="client-name"
                            [innerHTML]="element.clientLegalName"></span></a>
                        <a (click)="selectContext(element)"><span class="small-text">Client#: </span><span
                            [innerHTML]="element.clientNo"></span></a>
                      </div>
                    </td>
                  </ng-container>

                  <!-- Company Address Column -->
                  <ng-container matColumnDef="companyAddr">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [@.disabled]="initialSortColumn === 'companyAddr'"
                      [start]="initialSortDirection" [ngClass]="{'initialColumnSelected': initialSortColumn === 'companyAddr' }"> Company Address </th>
                    <td mat-cell *matCellDef="let element">
                      <span [innerHTML]="element.clientAddrLine1"></span>
                      <span [innerHTML]="element.clientAddrLine2"></span>, <br />
                      <span [innerHTML]="element.clientCityName"></span>, <span
                        [innerHTML]="element.clientStProvName"></span>,
                      <br /><span [innerHTML]="element.clientCountryName"></span>, <span
                        [innerHTML]="element.clientPostalCode"></span>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="noContextError">
          <div class="contexts-content">
            <h1>Please contact the Cartus Help Desk</h1>
            <div class="no-context mat-elevation-z8">
              <p class="stext"> Cartus' Help Desk is here to help you with all of your technical needs from Sunday
                8:00 p.m. through Friday 8:00 p.m. Eastern U.S. time. </p>
              <p class="stext"> For assistance with other questions or issues, please contact your Cartus
                Representative. </p>
              <p class="stext">
                <span class="sub-heading">Contact the Cartus Help Desk team</span>
                <br><span class="sub-heading">By Phone:</span> 1.800.316.8070 (within the U.S.) or +1.203.205.1726<br>
                <span class="sub-heading">By Email: </span>
                <a href="mailto:helpdesk-cartus@cartus.com">helpdesk-cartus@cartus.com</a>
              </p>
              <p class="stext"> Please include your name, phone number and a description of your issue. </p>
            </div>
          </div>
        </div>
        <div [ngClass]="{ 'main-bottom' : showGlobalTemplate }"></div>
      </div>

      <!-- Program Admin & Account Manager -->
      <div fxFill class="main-content" *ngIf="viewerFlag" >

        <!-- Admin Link for Account Manager specific for Benefits Builder -->
        <!-- *ngIf="isAdminVisible" -->
        <ng-container *ngIf="userAdminCapabilities">
          <div class="bb-admin-link">
            <a aria-label="Launch Benefit Builder Admin" 
              (click)="openAdministation()" 
              (keypress)="openAdministation($event)"><img style="width: 100%; max-width: 160px;" src="../assets/images/BB-logo.png" alt="Benefits Builder Admin"></a>
          </div>
        </ng-container>
        
        <app-clients [clientList]="clients" [userType]="userType" (showLogout)="setLogoutDisplay($event)" *ngIf="!clientContactFlag && !clientScreenFlag && clients" 
          (selectedClient)="getSelectedClient($event)" (onActionBtnSelect)="getClientScreen($event)">
        </app-clients>
        <app-client-contacts *ngIf="clientContactFlag" (showLogout)="setLogoutDisplay($event)" [client]="selectedClient" (backToClients)="getClients()"
          (clientContactSelected)="getSelectedClientContact($event)"></app-client-contacts>
        <app-client-screen *ngIf="clientScreenFlag"  (showLogout)="setLogoutDisplay($event)" [client]="selectedClient" (backToClients)="getClients()"></app-client-screen>
        <div [ngClass]="{ 'main-bottom' : showGlobalTemplate }"></div>
      </div>
      <footer *ngIf="showGlobalTemplate">
        <div class="footer-content">
          <div class="footer-donot">
            <span><a tabindex="0" target="_blank" href="https://submit-irm.trustarc.com/services/validation/0080a1bc-5f59-48fc-993e-83efc1029056">Do Not Sell or Share My Personal Information</a></span> <br />
          </div>
          <div class="footer-links">
            <a tabindex="0" [href]="termsOfUseUrl" target="_blank">Terms of Use</a> <span class="footer-link-divider">|</span> <a
             tabindex="0" [href]="privacyNotice" target="_blank">Privacy Policy</a>
          </div>
          <div class="copyrights">&copy; 2000-{{currentYear}} Cartus Corporation</div>
        </div>
      </footer>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
<div *ngIf="viewOriginFlag">
  <router-outlet></router-outlet>
</div>

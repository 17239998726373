<div class="container">
  <div class='myCustomHeading'>
    <span mat-dialog-title>Custom Data</span>
    <a style="color: black;">
      <mat-icon mat-dialog-close (click)="onDismiss()">close</mat-icon>
    </a>
  </div>
  <div>
    <span style="font-weight: bold;">Client: {{data.clientName}} ({{data.clientNo}}) </span>
  </div>
  <br>
  <div class="myCustomHeading">
    <mat-form-field class="table-search" appearance="outline">
      <span matPrefix class="lg-only" role="none">
        <mat-icon>search</mat-icon>
      </span>
      <input matInput tabindex="-1" style="size: 50;" (keyup)="filter(searchValue)" placeholder="Search by name..."
        [(ngModel)]="searchValue">
      <button tabindex="0" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearTextSearch()"
        *ngIf="searchValue" aria-label="Clear Search">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>&nbsp;
    
    <div style="padding-top: 11px;">
      <input type="checkbox" [checked]="expireitems" aria-label="Show Expired Data Items" (change)="getExpiredData()" />
      <span>
        Show Expired Data Items</span>
    </div>
    <button mat-mini-fab color="success" *ngIf="manageCustomDataCapability ==='write'" [disabled]="showForm"
      (click)="addCustomFields()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <div class="borderheading" *ngIf="showForm">
    <div>
      <form [formGroup]="customData">
        <div formArrayName="customDataForm">
          <span *ngFor="let atlasdata of customDataForm.controls;let pointIndex=index" [formGroupName]="pointIndex">
            <mat-form-field appearance="fill">
              <mat-label style="color: rgb(184, 182, 182);;">Atlas Association</mat-label>
              <input type="text" matInput formControlName="atlasAssociation" [matAutocomplete]="auto"
                [readonly]="enableChange">
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onChangeAtlasAssociation(atlasdata)">
                <mat-option style="font-size:small" *ngFor="let option of filteredoptions | async" [value]="option">
                  <span>{{option}}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>&nbsp;
            <mat-form-field appearance="fill">
              <mat-label style="color: rgb(184, 182, 182);;">Activation Date</mat-label>
              <input matInput formControlName="activationDate" (dateChange)="changeDate($event)" [min]="minimum"
                [readonly]="enableChange" [matDatepicker]="picker1" required>
              <mat-datepicker-toggle matSuffix [for]="picker1" [disabled]="enableChange"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            &nbsp;
            <mat-form-field appearance="fill" style="width: 214px;">
              <mat-label style="color: rgb(184, 182, 182);">Expiration Date</mat-label>
              <input matInput [matDatepicker]="picker2" [readonly]="manageCustomDataCapability ==='read'" [min]="minimumexpirationDate" aria-label="Expiration Date" formControlName="expirationDate">
              <mat-datepicker-toggle matSuffix [for]="picker2" [disabled]="manageCustomDataCapability ==='read'"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
            <span>
              <mat-icon [ngClass]="{'disable':customData.invalid===true}"
                style="cursor: pointer;float:right;padding-right: 21px;padding-top: 5px;"
                (click)="discardCard(pointIndex)">clear</mat-icon>
            </span>
            <div fxLayout="row">
              <div style="text-align: left;" *ngIf="customData.value.customDataForm[0].atlasAssociation">
                <mat-form-field appearance="fill">
                  <mat-label>Type</mat-label>
                  <input type="text" matInput formControlName="type" aria-label="type" readonly>
                </mat-form-field>
              </div>
              <div style="width: 91%;padding-right: 11px;text-align: right;">
                <mat-slide-toggle [disabled]="manageCustomDataCapability ==='read'"  [checked]="isAuthorizationRequired" style="font-size: medium;"
                  formControlName="isAuthorizationRequired">
                  {{customData.value.customDataForm[0].isAuthorizationRequired ? '' : 'Not'}} Required
                </mat-slide-toggle><br>
                <span style="font-size: 12px;">For Authorization </span>
              </div>
              <button mat-button type="button" (click)="saveData(pointIndex)" *ngIf="manageCustomDataCapability ==='write'"
                [disabled]="(customData.invalid && !enableChange) || customData.get(['customDataForm',0,'expirationDate']).status == 'INVALID'">
                <mat-icon>done</mat-icon>
              </button>
            </div>
          </span>

        </div>

      </form>
      <div class="divcontainer" *ngIf="customElementType=='DropDown'" style="margin-top: 0px;">
        <div>
          <div *ngFor="let selectArray of selectArray"> {{selectArray}} </div>
        </div>
     
      </div>
    </div>

  </div>
  <br>

  <div class="ScrollStyle" mat-dialog-content *ngIf="!updateData">
    <div fxLayout="row" fxLayoutGap="10" *ngFor="let data of CustomDataArr">
      <div fxFlex="94%">
        <mat-card>
          <mat-card-content style="justify-content: space-between;display: flex;">
            <span style="font-weight: bold;cursor: pointer; color: #002F82;"
            [ngStyle]="{'background-color':searchValue?'yellow':'transparent'}" type="button"
              (click)="changeData(data)">{{data.displayName}}</span>
            <span *ngIf="!data.isAuthorizationRequired" style="font-weight: bold;color:black;">Not Required for
              Authorization</span>
            <span *ngIf="data.isAuthorizationRequired" style="font-weight: bold;color:black;">Required for
              Authorization</span>
          </mat-card-content>
          <mat-card-content style="justify-content: space-between;display: flex;">
            <span>Type:<span style="font-weight: bold;color: #000000;">{{data.type}}</span></span>
            <span>Activation:<span
                style="font-weight: bold;color: black;">{{moment.utc(data.activationDate).format('YYYY-MM-DD')}}</span></span>
            <span *ngIf="data.expirationDate=='Invalid Date' || !data.expirationDate">Expiration:<span
                style="font-weight: bold;">None</span></span>
            <span *ngIf="data.expirationDate!='Invalid Date' && data.expirationDate">Expiration:<span                
                  style="font-weight: bold;color: black;">{{moment.utc(data.expirationDate).format('YYYY-MM-DD')}}</span></span>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxFlex="5%" *ngIf="manageCustomDataCapability ==='write' && !isExpired(data)">
        <mat-card style="padding-left: 8px;">
          <mat-card-content>
            <mat-icon style="cursor: pointer;" (click)="deleteCustomData(data)">delete</mat-icon>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
  <span *ngIf="(!configurableFieldsList || configurableFieldsList.length === 0) && manageCustomDataCapability ==='read'">
    No Custom Data Elements Defined
  </span>
</div>


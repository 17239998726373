<form autocomplete="off" [formGroup]="multiplicityForm" *ngIf="multiplicityForm">
    <div fxFlex="100%" fxLayout="row" fxLayoutAlign="start center" class="multiplicity-container container">
        <div class="multiplicity-attr-container attributes-container" fxFlex="25%" fxLayout="row" fxLayoutGap="2px" *ngFor="let formFields of getMultiplicityFormCtrlAttr()">
            <div class="inner-container">
                <div *ngIf="formFields">
                    <div [ngSwitch]="formFields.fieldType">
                        <div *ngSwitchCase="'Text'">
                            <mat-form-field>
                                <mat-label>{{formFields.displayName}}</mat-label>
                                <input matInput placeholder="{{formFields.displayName}}"
                                    [formControlName]="formFields.name" type="text" [value]="multiplicityForm.get(formFields.name).value" />
                            </mat-form-field>
                        </div>
                        <div *ngSwitchCase="'Number'"  class="property-value">
                            <mat-form-field  appearance="outline">
                                <mat-label>{{formFields.displayName}}</mat-label>
                                <input [matTooltip]="multiplicityForm.get(formFields.name).value" matInput type="number"
                                    [formControlName]="formFields.name"
                                    (keypress)="restrictNonNumericChar($event)" 
                                    [value]="multiplicityForm.get(formFields.name).value" />
                            </mat-form-field>
                        </div>
                        <div *ngSwitchCase="'Select'">
                            <mat-form-field>
                                <mat-label>{{formFields.displayName}}</mat-label>
                                <mat-select [compareWith]="compareObjects" (selectionChange)="getSelection($event,formFields.displayName)" multiple>
                                    <mat-option (onSelectionChange)="getSelection($event,formFields.name)"
                                        *ngFor="let opt of formFields.enumeratedValues" [value]="opt.value">{{opt.value}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngSwitchCase="'Constant'" class="label-constant">
                            <mat-form-field>
                                <mat-label>{{formFields.displayName}}</mat-label>
                                <input matInput placeholder="{{formFields.displayName}}" type="text"
                                    [value]="multiplicityForm.get(formFields.name).value" [formControlName]="formFields.name" [readonly]="true" [hidden]="true">
                            </mat-form-field>
                        </div>
                        <div *ngSwitchCase="'Boolean'">
                            <mat-slide-toggle (click)="$event.stopPropagation();" [value]="form.get(formFields.name).value" 
                                aria-label="{{formFields.displayName}}" [formControlName]="formFields.name"
                                (change)="onToggle($event)">
                            </mat-slide-toggle>
                            {{formFields.displayName}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
export const convertDateToUTC = (dateToConvert) => {
    console.log(dateToConvert.split('T')[0].concat('T00:00:00.000Z'));
    try {
      if(dateToConvert){
        const localTime = new Date();
        let diff = localTime.getTimezoneOffset();
        if(diff > 0){
          return new Date(dateToConvert.split('T')[0].concat('T23:00:00.000Z'));
        }else{
          return new Date(dateToConvert.split('T')[0].concat('T00:00:00.000Z'));
        }
      }else{
        return '';
      }
    } catch (error) {
      return '';
    }
  }
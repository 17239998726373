<div class="main-pop-styles">
    <div mat-dialog-title class="dialog-header" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px"
        fxLayoutGap.xs="0em">Warning
        <a class="close-icon" (click)="onDismissDialog($event)" title="close">
            <mat-icon>close</mat-icon>
        </a>
    </div>
    <div mat-dialog-content class="dailog-title" tabindex="0"><span aria-hidden='true'>{{displayWarning}}</span></div>
    <div class="action-buttons">
        <button class="cancel-adj" (click)='onDismissDialog($event)'>Cancel</button>
        <button class="confirm-action" (click)='adjudicateTransferee()'>Confirm</button>
    </div>
</div>
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CandidateProfileService } from '../../../../services/candidate-profile.service';

@Component({
  selector: 'banking-info',
  templateUrl: './banking-info.component.html',
  styleUrls: ['./banking-info.component.scss']
})
export class BankingInfoComponent implements OnInit, OnChanges {

  @Input()
  orderRequestId: string;

  matExpansionHeaderHeight = '40px';
  bankingInfoArray: any;

  constructor(private candidateProfileService: CandidateProfileService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.orderRequestId) {
      this.getBankDetails();
    }
  }

  ngOnInit(): void {
  }

  getBankDetails() {
    this.candidateProfileService.getBankInfoData(this.orderRequestId).subscribe(bankingInfoData =>{
      this.bankingInfoArray = bankingInfoData;
    })
  }

}

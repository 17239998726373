<ngx-spinner type="ball-spin-clockwise-fade" role="alert" aria-atomic="true" size="medium">
  <span class="cdk-visually-hidden">Loading, please wait...</span>
</ngx-spinner>

<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column">
  <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
    fxLayoutGap="20px" fxLayoutGap.xs="0em">
    <div class="candidate-dialogtitle">{{formTitle}}</div>
    <a href="#" class="close-icon" (click)="onDismiss($event)" [ngClass]="{'disable':formDisable==true}" title="close">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <div>
    <form fxLayout="column" class="middle-section" [formGroup]="addCusForm">
      <fieldset>
        <div class="middle-section">
          <mat-dialog-content>
            <div class="input-row" fxLayout="row" fxLayoutAlign="space-evenly" fxLayout.xs="column"
              fxLayoutGap.xs="0em">
              <!--Title field-->
              <div fxFlex="10%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                  <mat-label>Salutation</mat-label>
                  <mat-select formControlName="title" placeholder="title" [(ngModel)]="title" character-only
                    formControlName="title" minLength="1" maxlength="50">
                    <mat-option *ngFor="let title of titleValues" [value]="title">{{title}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <!--First Name field-->
              <div fxFlex="28%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                  <input matInput placeholder="First Name" [(ngModel)]="searchfname" multi-type-char
                    formControlName="firstName" minLength="1" maxlength="50">
                </mat-form-field>
              </div>
              <!--Last Name field-->
              <div fxFlex="28%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                  <input matInput placeholder="Last Name" [(ngModel)]="searchlname" multi-type-char 
                    formControlName="lastName" minLength="2" maxlength="50">
                </mat-form-field>
              </div>
              <!--Employee # field-->
              <div fxFlex="28%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                  <input matInput placeholder="Employee # (Optional)" [(ngModel)]="searchEmp" 
                    formControlName="EmployeeName" minLength="1" maxlength="50">
                </mat-form-field>
              </div>
            </div>
            <div class="input-row" class="addcandidate-toppadding" fxLayout="row" fxLayoutAlign="space-between"
              fxLayout.xs="column" fxLayoutGap.xs="0em">
              <!--Email Address field-->
              <div fxFlex="{{emailFieldSize}}" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%"
                class="emailcontainer m-t-23">
                <mat-form-field fxFlex>
                  <input matInput type="text" placeholder="Email Address" [(ngModel)]="searchEmail"
                    formControlName="email">
                </mat-form-field>
              </div>
              <!--SSO value field-->
              <div fxFlex="21%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%" *ngIf = "data.bbRole.includes('sso')"
                class="emailcontainer m-t-23">
                <mat-form-field fxFlex>
                  <input matInput type="text" placeholder="SSO #" [(ngModel)]="searchSSO"
                    formControlName="sso">
                </mat-form-field>
              </div>
              <div fxFlex="48%" fxLayout.xs="row" fxFlex.xs="100%" fxLayout="row" fxFlexFill
                fxLayoutAlign="space-between">
                <div fxFlex="24%" fxFlex.xs="30%" class="m-t-23">
                  <mat-form-field>
                    <mat-label>Phone Code</mat-label>
                    <input type="text" aria-label="Phone Code" [(ngModel)]="PhoneCode" matInput #autoCompleteInput
                      formControlName="phoneDialingCode" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" [panelWidth]="293">
                      <mat-option [value]="option.countryDialingCode" *ngFor="let option of filteredOptions | async">
                        {{ option.countryName }} (+ {{ option.countryDialingCode }})
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <div fxFlex="76%" fxFlex.xs="70%" class="international-extn m-t-23">
                  <mat-form-field fxFlex>
                    <input matInput type="text" [(ngModel)]="PhoneNumber" placeholder="Phone Number" number-only
                      formControlName="phoneNumber" minLength="7" maxlength="18">
                      <mat-hint align = "start">Mobile # required to receive text messages</mat-hint>
                    <!-- <mat-error>
                        {{ getErrorMessage('phoneNumber', 'Mobile #') }}
                    </mat-error> -->
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-dialog-content>
        </div>
      </fieldset>
      <mat-dialog-actions *ngIf="!isEditable" class="statusBtn add-dialogbtn" fxLayout="row" fxLayoutAlign="end"
        fxLayoutGap="10px" fxLayoutGap.xs="0px" fxLayoutAlign.xs="space-between center">
        <button fxFlex="25%" [disabled]="formDisable" type="button" class="mat-button text-button"
          (click)="onDismiss($event)" color="primary" id="cancel">CANCEL</button>
        <button fxFlex="25%" [disabled]="!addCusForm.valid" class="mat-button contained-button send-invite"
          (click)="onEditClick()">Check For
          Records</button>
      </mat-dialog-actions>
    </form>
  </div>
  <br>

  <div *ngIf="isEditable">
    <mat-card>
        <div flexLayout="row" fxLayoutAlign="space-between">
          <div>
            <span>Search Results</span>
          </div>
          <div>
            <button type="button" mat-button class="text-button" (click)="onDismissTable()" color="primary"
            id="cancel">Clear</button>
          </div>
        </div>
    </mat-card>
    <div *ngIf = "dataSource?.data.length > 0; else noRecord" class="search-table-container">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 search-table">
      <!-- Name Column -->
      <ng-container matColumnDef="fullName">
        <td mat-cell *matCellDef="let element" style="border-bottom: 1px solid grey; width:18% !important;">
          <div *ngIf="element.name">
            <span [innerHTML]="element.name[0] | searchHighlight: searchfname"></span>,
            <span [innerHTML]="element.name[1] | searchHighlight: searchlname"></span> <br>
            <span [innerHTML]="element.empId ? (element.empId | searchHighlight: searchEmp) : '' "></span>
          </div>
        </td>
      </ng-container>
         <!-- SSO Column -->
         <ng-container matColumnDef="sso" *ngIf = "data.bbRole.includes('sso')">
          <td mat-cell *matCellDef="let element" style="border-bottom: 1px solid grey; width:18% !important;" > SSO #:
            <span [innerHTML]="element.ssoIdentifier ? (element.ssoIdentifier  | searchHighlight: searchSSO) : '' "></span><br>
          </td>
        </ng-container>   
      <!-- email Column -->
      <ng-container matColumnDef="email">
        <td mat-cell *matCellDef="let element" style="border-bottom: 1px solid grey; width:18% !important;"> 
          <span [innerHTML]="element.emailDetailsList[0]?.usageType === 'business' ? (element.emailDetailsList[0].emailAddress  | searchHighlight: searchEmail) : 'N/A' "></span><br>
          +<span [innerHTML]="element.phoneDetailsList[0]?.usageType === 'personal' ? (element.phoneDetailsList[0].phoneDialCode | searchHighlight: PhoneCode) : 'N/A' "></span>
          <span class="phoneNumber" [innerHTML]="element.phoneDetailsList[0]?.usageType === 'personal' ? (element.phoneDetailsList[0].phoneNumber  | searchHighlight: PhoneNumber) : 'N/A' "></span>
        </td>
      </ng-container>
      <!-- departure Column -->
      <ng-container matColumnDef="departureAddr">
        <td mat-cell *matCellDef="let element" style="border-bottom: 1px solid grey; width:30% !important;">
          <div tabindex="0" *ngIf="element.departureAddr">
            <span [innerHTML]="element.departureAddr.country | searchHighlight: searchKeyword"></span><br />
            <span
              [innerHTML]="element.departureAddr.state?(element.departureAddr.state | searchHighlight: searchKeyword ):''"></span>,
            <span
              [innerHTML]="element.departureAddr.city? (element.departureAddr.city | searchHighlight: searchKeyword ): ''"></span>

          </div>
        </td>
      </ng-container>
      <!-- destination Column -->
      <ng-container matColumnDef="destinationAddr">
        <td mat-cell *matCellDef="let element" style="border-bottom: 1px solid grey; width:30% !important;">
          <div tabindex="0" *ngIf="element.destinationAddr">
            <span [innerHTML]="element.destinationAddr.country | searchHighlight: searchKeyword"></span><br />
            <span
              [innerHTML]="element.destinationAddr.state? (element.destinationAddr.state | searchHighlight: searchKeyword) : ''"></span>,
            <span
              [innerHTML]="element.destinationAddr.city? (element.destinationAddr.city | searchHighlight: searchKeyword) : ''">
            </span>

          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="add">
        <td mat-cell *matCellDef="let element; let i = index"
          style="border-left: 1px solid grey;border-bottom: 1px solid grey; width:4% !important;">

          <!--no missmatch data then trigger matmenu directly-->
          <button aria-label="Associate Transferee" style="margin: auto;height: 30px;width: 30px; cursor: pointer;" class="user-water" *ngIf="menuItemsList.length > 1"  #menuFor="matMenuTrigger" [matMenuTriggerFor]="menu">
            <mat-icon>add</mat-icon>
          </button>

          <!--when user confirms missmatch then checkConfirm is set to true and matmenu is triggered -->
          <!-- <button aria-label="Associate Transferee" style="margin: auto;height: 30px;width: 30px; cursor: pointer;" class="user-water" *ngIf="checkConfirm[i] && (data.bbRole.includes('benefitsBuilder') && data.bbRole.includes('traditionalAuthorization'))"  #menuFor="matMenuTrigger" [matMenuTriggerFor]="menu">
            <mat-icon>add</mat-icon>
          </button> -->

          <!--not bb-role-->
          <button aria-label="Associate Transferee" style="margin: auto;height: 30px;width: 30px; cursor: pointer;" class="user-water" (click)="openWarningOrAddModal(menuItemsList[0], element);" *ngIf="menuItemsList.length === 1">
            <mat-icon>add</mat-icon>
          </button>
          
          <mat-menu class ="menu-without-scroll" #menu="matMenu" [overlapTrigger]="false">
              <button mat-menu-item *ngFor="let item of menuItemsList"[attr.aria-label]="item"  tabindex="0"  (click) = "openWarningOrAddModal(item, element)">{{ generateMenuItemName(item) }}</button>
          </mat-menu>
        </td>
      </ng-container>

      
      <tbody class="table-body">  
      <tr style="width:100% !important;" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </tbody>
    </table>
    </div>
    <ng-template #noRecord>  No Records Found.</ng-template>
    <mat-dialog-actions class="statusBtn add-dialogbtn" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px"
      fxLayoutGap.xs="0px" fxLayoutAlign.xs="space-between center">
      <button fxFlex="25%" type="button" class="mat-button text-button" (click)="onDismiss($event)" color="primary"
        id="cancel">CANCEL</button>
        <button fxFlex="25%" mat-flat-button color="primary" (click)="openDialog(menuItemsList[0])" *ngIf = "menuItemsList.length === 1">Create
          New</button>
        <button fxFlex="25%" mat-flat-button color="primary" #ddTrigger="matMenuTrigger" *ngIf = "menuItemsList.length > 1" [matMenuTriggerFor]="dd">Create
        New..<span style="float: right;">
          <mat-icon>arrow_drop_down</mat-icon>
        </span></button>
    </mat-dialog-actions>
    <mat-menu class ="menu-without-scroll" #dd="matMenu" [overlapTrigger]="false">
        <button mat-menu-item *ngFor="let item of menuItemsList"[attr.aria-label]="item"  tabindex="0"  (click) = "openDialog(item)">{{ generateMenuItemName(item) }}</button>
    </mat-menu>
    </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, NG_VALUE_ACCESSOR, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import moment from 'moment';
import { Country } from '../../../core/models/country.model';
import { LocationsService } from '../../../public/administration/services/locations.service';
import { DynamicBenefitService } from '../../services/dynamic-benefit.service';

@Component({
  selector: 'app-dynamic-attribute',
  templateUrl: './dynamic-attribute.component.html',
  styleUrls: ['./dynamic-attribute.component.scss']
})
export class DynamicAttributeComponent implements OnInit {
upsertDynamicForm:UntypedFormGroup
@Input() formDetails;
@Input() formGroupType;
@Input() dynamicDataModel;
@Input() componentDescription;
@Output() formEvent = new EventEmitter<FormGroupDirective>();
@Input()originalDynamicModel;
@Input() disableMuliplicityForm:boolean = false;
form: FormGroup;
selectedOption:string;
componentTitle:string;
currencyList: Array<Country> = [];
currencyCodeList: Array<any> = [];
  constructor(private rootFormGroup: FormGroupDirective,
    private  locationService: LocationsService,
    private dynamicBenefitService: DynamicBenefitService,
    private formBuilder:FormBuilder) {}

  ngOnInit(): void {
    this.getLocaleCurrencyCode(); 
  }

  initializeForm() {
    this.form = this.formDetails as FormGroup;
    this.getFormControlAttributes()
    if(this.componentDescription){
        this.componentTitle = this.componentDescription.filter((item) => {
          return item[this.formGroupType];
        })[0][this.formGroupType];
    }
    this.form?.updateValueAndValidity();
    this.formEvent.emit(this.rootFormGroup)
  }

  onSelectionChange(event){
     this.formEvent.emit(this.rootFormGroup);
  }

  restrictNonNumericChar(event){
    if (event.target.type === "number" && !event.key.match(/^[0-9]+$/)) {
      event.preventDefault();
    }
  }

  getFormArray(formControlType){
    const multiplicityFormArr = this.formDetails?.controls[formControlType]?.controls as FormArray;
    return multiplicityFormArr;
  }

  getMultiplicityFormGroup() {
    let multiplicityFormGroupList = [];
    if (this.dynamicDataModel && this.formGroupType) {
      multiplicityFormGroupList = this.dynamicDataModel[this.formGroupType]?.attributes?.find(item => item.isMultiplicity)?.children;
    }
    return multiplicityFormGroupList;
  }

  getNestedFormGroup(nestedFormIndex, fieldName){
    const multiplicityFormArr = this.formDetails.controls[fieldName].controls as FormArray;
    const parentCtrl = multiplicityFormArr[0] as FormGroup
    const nestedFormGroup = parentCtrl[nestedFormIndex] as FormGroup
    return nestedFormGroup;
  }

  getLocaleCurrencyCode() {
    this.locationService.countryList.subscribe(countryList => {
      if (countryList.length > 0) {
        countryList.sort((a, b) => a.countryName.localeCompare(b.countryName));
        const currList = [];
        this.currencyList = [];
         this.currencyCodeList = [];
        countryList.forEach((curr)=> {
          curr.currency ? currList.push(curr): null;
        });
        currList.sort((a, b) =>  a.currency &&  b.currency ? a.currency.code.localeCompare(b.currency.code) : null);
        this.currencyList = currList.filter((e, i) => currList.findIndex(a => a.currency && e.currency ? a.currency.code === e.currency.code : null) === i);
        currList.forEach((item) => {})
        for(const item of currList) {
          const data = {
            isDefault: false,
            isSelected: false,
            value: item.currency.code 
          }
          if(!this.currencyCodeList.find((curr) => {return curr.value == data.value})) {
            this.currencyCodeList.push(data);
          }
        }
      }
      this.initializeForm(); // Initiate form after the countryList API response to avoid form errors
    });
  }


  getFormControlAttributes() {
    let formcontrolAttr = []
    let attrList
    if (this.dynamicDataModel && this.formGroupType) {
      attrList = this.dynamicDataModel[this.formGroupType];
    }
    if (attrList && attrList.attributes && attrList.attributes.length) {
      formcontrolAttr = attrList.attributes;
    }
    formcontrolAttr.forEach(formProperties => {
      if (formProperties && formProperties.hasOwnProperty('isMultiplicity')) {
        return
      } else {
        if (formProperties && formProperties.hasOwnProperty('currency-code') && formProperties['currency-code']) {
          formProperties._specType = 'enumeratedAttribute';
          formProperties.enumeratedValues = this.currencyCodeList;
          formProperties['fieldType'] = 'Select';
          if (formProperties.hasOwnProperty('defaultValue')) {
            const defaultValue = this.currencyCodeList.find(selectedValue => selectedValue.value === formProperties.defaultValue)?.value
            formProperties['defaultValue'] = defaultValue ? defaultValue : null;
          }
        } else if (formProperties && formProperties?.displaySetting === this.dynamicBenefitService.displayWithCommas) {
          formProperties['fieldType'] = 'Text';
          this.updateDisplayCommasCtrl(formProperties.name);
        } else if (formProperties && formProperties?.enumeratedValues?.length) {
          formProperties['fieldType'] = 'Select';
          const defaultValue = formProperties?.enumeratedValues?.find(item => item.isSelected)?.value
          formProperties['defaultValue'] = defaultValue ? defaultValue : null;
        } else if (formProperties && formProperties.dataType === 'string' && formProperties.attributeKind === 'Property') {
          formProperties['fieldType'] = 'Text';
        } else if (formProperties && formProperties.dataType === 'string' && formProperties.attributeKind === 'Constant') {
          formProperties['fieldType'] = 'Constant';
        } else if (formProperties && formProperties.dataType === 'Number') {
          formProperties['fieldType'] = formProperties.dataType;
          this.updateTypeNumberCtrl(formProperties.name);
        } else {
          formProperties['fieldType'] = formProperties.dataType;
        }
      }
    })
    return formcontrolAttr;
  }

  getSelection(event) {
    let refMatch;
    if (event?.value) {
      const sourceValue = event?.value;
      const formCtrlIndex = this.dynamicDataModel[this.formGroupType]?.attributes?.findIndex(items => items.reference.endsWith(sourceValue))
      const nestformCrtl = this.originalDynamicModel[this.formGroupType]?.attributes?.find(items => items.isMultiplicity);
      const resetCtrlIndex = this.originalDynamicModel[this.formGroupType]?.attributes?.findIndex(items => items.isMultiplicity);
      if(nestformCrtl){
         refMatch = nestformCrtl.reference.endsWith(sourceValue);
      }
      if (formCtrlIndex == -1 && refMatch) {
          if (resetCtrlIndex != -1 && nestformCrtl) {
            nestformCrtl.children?.splice(1);
            nestformCrtl.children[0]?.attributes?.map(attrField => {
              if (attrField?.attributeKind !== 'Constant') {
                attrField.defaultValue = null;
              }
              return attrField
            })
            this.dynamicDataModel[this.formGroupType].attributes.splice(resetCtrlIndex, 0, nestformCrtl);
            this.form?.addControl('multiplicity', new UntypedFormArray([
              this.createMultiplicityForm(nestformCrtl.children)
          ]))
        }
      } else {
        const hasMultiplicity = this.dynamicDataModel[this.formGroupType]?.attributes?.find(items => items.isMultiplicity);
        if(hasMultiplicity) {
          this.form.removeControl('multiplicity');
          this.dynamicDataModel[this.formGroupType].attributes.splice(resetCtrlIndex,1);
        }
      }
      this.form?.updateValueAndValidity();
      this.formEvent.emit(this.rootFormGroup);  
    }
  }

  createMultiplicityForm(multiplicityDetails: Array<[]>) {
    let formFields = this.formBuilder.group({});
    if (multiplicityDetails && multiplicityDetails.length > 0) {
        multiplicityDetails.forEach((multiForm, index) => {
            if (multiForm && multiForm['attributes'] && multiForm['attributes'].length) {
                formFields[index] = this.formBuilder.group({});
                multiForm['attributes'].forEach(nestedFormFields => {
                    if (nestedFormFields.hasOwnProperty('defaultValue')) {
                      if(nestedFormFields['defaultValue'] !== '')
                        formFields[index].addControl(nestedFormFields['name'], this.formBuilder.nonNullable.control(nestedFormFields['defaultValue']));
                      else
                        formFields[index].addControl(nestedFormFields['name'], this.formBuilder.nonNullable.control(null));
                    } else {
                        formFields[index].addControl(nestedFormFields['name'], this.formBuilder.nonNullable.control(null));
                    }
                    if (nestedFormFields.hasOwnProperty('validations') && nestedFormFields['validations'].includes('REQUIRED')) {
                        formFields[index].get(nestedFormFields['name']).setValidators(Validators.required);
                    }
                })
            }
        });
    }
    return formFields;
}

  onToggle(event,formField){
    const selectedParentCtrl = this.dynamicDataModel[this.formGroupType].attributes.find(items => items.hasOwnProperty('on-load-dependent-attribute')  && items['on-load-dependent-attribute'] === formField.reference);
    const childIndex = this.dynamicDataModel[this.formGroupType].attributes.findIndex(items => items.hasOwnProperty('on-load-dependent-attribute')  && items['on-load-dependent-attribute'] === formField.reference);
    if(!event?.checked && selectedParentCtrl && selectedParentCtrl.name ){
      this.form.removeControl(selectedParentCtrl.name);
      this.dynamicDataModel[this.formGroupType].attributes.splice(childIndex,1);
      const nestedChildIndex = this.dynamicDataModel[this.formGroupType].attributes.findIndex(items => items.hasOwnProperty('on-load-dependent-attribute')  && items['on-load-dependent-attribute'] === selectedParentCtrl.reference);
      const nestedChildCtrl = this.dynamicDataModel[this.formGroupType].attributes.find(items => items.hasOwnProperty('on-load-dependent-attribute')  && items['on-load-dependent-attribute'] === selectedParentCtrl.reference);
      if(nestedChildCtrl && nestedChildCtrl.isMultiplicity){
        this.dynamicDataModel[this.formGroupType].attributes.splice(nestedChildIndex,1);
        this.form.removeControl('multiplicity');
        const multiplicityAttrIndex = this.originalDynamicModel[this.formGroupType]?.attributes.findIndex(items => items.hasOwnProperty('isMultiplicity'))
        if (multiplicityAttrIndex != -1) {
          this.originalDynamicModel[this.formGroupType]?.attributes[multiplicityAttrIndex]?.children.splice(1);
          this.originalDynamicModel[this.formGroupType]?.attributes[multiplicityAttrIndex]?.children[0]?.attributes?.map(attrField => {
            if (attrField?.attributeKind !== 'Constant') {
              attrField.defaultValue = null;
            }
            return attrField
          })
        }
      }
    } else {
      const selectedParentCtrl = this.originalDynamicModel[this.formGroupType].attributes.find(items => items.hasOwnProperty('on-load-dependent-attribute')  && items['on-load-dependent-attribute'] === formField.reference);
      const currentIndex = this.dynamicDataModel[this.formGroupType].attributes.findIndex(items => items.hasOwnProperty('on-load-dependent-attribute')  && items['on-load-dependent-attribute'] === formField.reference);
      const childIndex = this.originalDynamicModel[this.formGroupType].attributes.findIndex(items => items.hasOwnProperty('on-load-dependent-attribute')  && items['on-load-dependent-attribute'] === formField.reference);
      if(currentIndex == -1){
        if(selectedParentCtrl && selectedParentCtrl.name){
          this.form.addControl(selectedParentCtrl.name, this.formBuilder.control(''));
          if(selectedParentCtrl?.validations.includes('REQUIRED')) {
            this.form.get(selectedParentCtrl.name).setValidators(Validators.required);
          }
          this.dynamicDataModel[this.formGroupType].attributes.splice(childIndex,0,selectedParentCtrl);
      } 
      }    
    }
    this.formEvent.emit(this.rootFormGroup);  
  }

  
  /** Multiplicity - add new Multiplicity */
  addNewMultiplicity(formGroup) {
    if(formGroup?.attributes) {
      let newMul = JSON.parse(JSON.stringify(formGroup));
      newMul.attributes.forEach(element => {
        if(element?.attributeKind !== 'Constant') {
          element.defaultValue = null;
        }
      });
      const resetCtrlIndex = this.dynamicDataModel[this.formGroupType]?.attributes?.findIndex(items => items.isMultiplicity);
      this.updateMultiplicityRowsValue((this.form.get('multiplicity') as UntypedFormArray).controls, resetCtrlIndex);
      const nestformCrtl = this.dynamicDataModel[this.formGroupType]?.attributes?.find(items => items.isMultiplicity);
      if (resetCtrlIndex != -1 && nestformCrtl) {
        nestformCrtl.children.push(newMul);
        this.dynamicDataModel[this.formGroupType].attributes[resetCtrlIndex] = nestformCrtl;
        (this.form.get('multiplicity') as UntypedFormArray).controls = [];
        (this.form.get('multiplicity') as UntypedFormArray).push(this.createMultiplicityForm(nestformCrtl.children));
      }
      this.formEvent.emit(this.rootFormGroup);  
    }
  }
  /** Multiplicitys - remove Multiplicity */
  removeMultiplicity(subFormIndex) {
    const resetCtrlIndex = this.dynamicDataModel[this.formGroupType]?.attributes?.findIndex(items => items.isMultiplicity);
    this.updateMultiplicityRowsValue((this.form.get('multiplicity') as UntypedFormArray).controls, resetCtrlIndex);
    const nestformCrtl = this.dynamicDataModel[this.formGroupType]?.attributes?.find(items => items.isMultiplicity);
    if (resetCtrlIndex != -1 && nestformCrtl && nestformCrtl?.children && nestformCrtl?.children[subFormIndex]) {
      nestformCrtl.children.splice(subFormIndex, 1);
      this.dynamicDataModel[this.formGroupType].attributes[resetCtrlIndex] = nestformCrtl;
      (this.form.get('multiplicity') as UntypedFormArray).controls = [];
      (this.form.get('multiplicity') as UntypedFormArray).push(this.createMultiplicityForm(nestformCrtl.children));
    }
    this.formEvent.emit(this.rootFormGroup);
  }

  updateDisplayCommasCtrl(formControlName) {
    const value = this.form?.get(formControlName)?.value.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.form?.get(formControlName)?.patchValue(value);
  }

  updateTypeNumberCtrl(formControlName) {
    if(this.form?.get(formControlName)?.value && this.form?.get(formControlName)?.value !== null) {
      const value = this.form?.get(formControlName)?.value.toString().replace(/\D/g, "");
      value !== '' ? this.form?.get(formControlName)?.patchValue(parseFloat(value)) : this.form?.get(formControlName)?.patchValue(null);
    }
  }

  updateMultiplicityRowsValue(multiplicityFormGroup, multiIndex) {
    multiplicityFormGroup.forEach((formGroupArr) => {
      for(let i = 0; i < Object.keys(formGroupArr).length; i++) {
          let formGroupKey = Object.keys(formGroupArr)[i];
          if(!isNaN(parseFloat(formGroupKey))) {
            Object.keys(formGroupArr[formGroupKey].value).forEach(key => {
              this.dynamicDataModel[this.formGroupType]?.attributes[multiIndex].children.forEach((child, childIndex) => {
                child?.attributes.forEach(element => {
                    if(element.name == key && formGroupKey == childIndex) {
                      element.defaultValue = formGroupArr[formGroupKey].value[key];
                    }
                });
              });
            });
          }
      }
    });

  }

}

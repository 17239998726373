import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-opearator-uitype-restriction',
  templateUrl: './opearator-uitype-restriction.component.html',
  styleUrls: ['./opearator-uitype-restriction.component.scss']
})
export class OpearatorUitypeRestrictionComponent implements OnInit {
  @Input() restriction: any;
  @Input() parentForm:UntypedFormGroup;
  @Input() operatorsList:Array<any>;

  lastOperatorNotFound: boolean;
  matFocused = [];

  constructor() { }

  ngOnInit(): void { }

  matFocusedEvent(event,i,key){
    this.matFocused[i] = {};
    this.matFocused[i][key] = event;
  }

  selectedOperatorRes(Index,type?){
    if(type){
      this.matFocused[Index] = {};
      this.matFocused[Index][type] = false;
    }
    this.parentForm.get('values').get(Index+'').get('value').setValue(0);
    this.lastOperatorNotFound = false;
  }

  disableResOption(item){
    let flag = false;
    let values = this.parentForm.get('values').value;
    if(values && values.length){
      values.forEach(element => {
        if(element.operator && element.operator == item.value) {
          flag = true;
        }
      });
    }

    return flag;
  }

  addOperatorRestriction () {
    let newRes = new UntypedFormGroup({
      "operator": new UntypedFormControl(null,Validators.required),
      "value": new UntypedFormControl(null,Validators.required)
    })
    let values = this.parentForm.get('values').value;
    let lastValIndex = (values && values.length) ? values.length -1 : -1;
    this.lastOperatorNotFound = false
    if(lastValIndex > -1 && values[lastValIndex].operator) {
      (this.parentForm.get('values') as UntypedFormArray).push(newRes)
    }else{
      this.lastOperatorNotFound = true;
    }
  }

  removeOperatorRestriction (index) {
    (this.parentForm.get('values') as UntypedFormArray).removeAt(index);
  }

  addNewOperatorIsAlwd() {
    let flag = false;
    let values = this.parentForm.get('values').value;
    if(!values || !values.length){
      flag = true;
    }else if(this.operatorsList && this.operatorsList.length){
      flag = this.operatorsList.length > values.length
    }
    return flag;
  }

  restrictNegativeVal ($event){
    if($event.key == '-' || $event.keyCode == 189){
      $event.preventDefault();
    }
  }

}

import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CandidateProfileService } from '../../../services/candidate-profile.service';

@Component({
  selector: 'app-policy-history',
  templateUrl: './policy-history.component.html',
  styleUrls: ['./policy-history.component.scss']
})
export class PolicyHistoryComponent implements OnInit {
  policyStatusHistoryData: any = [];
  isLoad: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<PolicyHistoryComponent>,
    private candidateProfileService: CandidateProfileService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.candidateProfileService.getPolicyHistory(this.data.currOrderRequestId, this.data.status).subscribe(res => {
      if(res && res.policyStatusHistoryData && res.policyStatusHistoryData.length) {
        this.policyStatusHistoryData = res.policyStatusHistoryData;
      } else {
        this.isLoad = true;
      }
    })

  }

  onDismiss(evt) {
    evt.preventDefault();
    this.dialogRef.close();
  }

}

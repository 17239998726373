import { AggregationView } from './../../core/models/aggregation-view.model';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { AggregationFetchparameter } from '../models/aggregation-fetch-parameter.model';
import { HttpParams } from '@angular/common/http';


/** Destination Country Service to fetch data from Api and to display the data in the table format under aggregation view */
@Injectable({
  providedIn: 'root'
})
export class AggregationViewService {
  /** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
   */
  constructor(
    protected baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService
  ) {}

  /** getDestCountry method to fetch location data from Api to display in aggregation table */
  getAggregateView(aggregationType: string, aggregationFetchParameter: AggregationFetchparameter): Observable<AggregationView> {
    const params = new HttpParams({ fromObject: {
      itemsPerPage: aggregationFetchParameter.itemsPerPage.toString(),
      pageNumber: aggregationFetchParameter.pageNumber.toString(),
      groupBy: aggregationType,
      searchText: aggregationFetchParameter.searchText ? aggregationFetchParameter.searchText : '',
      currencyAbbr: aggregationFetchParameter.preferredCurr ? aggregationFetchParameter.preferredCurr : null
    }});
    return this.baseClient
      .getOne<AggregationView>(
        'v1/aggregateDashboard',
        'get the Aggregation view details',
        params
      )
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: AggregationView = null;
          this.logSvc.logError(err);
          return of(empty);
        })
      );
  }
}

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { PowerBiReportConfig } from '../models/powerbi-report-config.model';
import { PowerBiVisualConfig } from '../models/powerbi-visual-config.model';
import { PowerBiEmbedToken } from '../models/powerbi-embed-token.model';
import { HttpParams } from '@angular/common/http';
import { AppConfigService } from './app-config.service';

/** This service is used for Power BI */
@Injectable({
  providedIn: 'root'
})
export class PowerBiReportService {
    /** standard constructor for services */
    constructor(
        private readonly appConfig: AppConfigService,
        private readonly baseClient: BaseClientService,
        private readonly logSvc: RemoteLoggingService
        ) { }

    /** Returns Power BI configuration */
    getConfig(): PowerBiReportConfig[] | PowerBiVisualConfig[] {
        // TODO: Eventually we will get Power BI configs dynamically based on user config
        const configs: PowerBiReportConfig[] | PowerBiVisualConfig[] =
        <PowerBiReportConfig[] | PowerBiVisualConfig[]>this.appConfig.getConfig('powerBiConfig');
        return configs;
    }

    /** Returns Power BI configuration with a valid access token */
    setEmbedToken(config: PowerBiReportConfig | PowerBiVisualConfig): Observable<PowerBiReportConfig | PowerBiVisualConfig> {
        delete config.description; // This property is only for developer use
        const params = new HttpParams({fromObject: {
            accessType: config.accessType,
            userType: config.userType,
            groupId: config.groupId,
            reportId: config.reportId
        }});
        return this.baseClient.getOne<PowerBiEmbedToken>('v1/powerbi', 'getting Power BI report token', params).pipe(
            map(r => {
                config.accessToken = r.body.embedToken.token;
                config.embedUrl = r.body.embedUrl;
                config.id = config.reportId;
                return config; // Return input config with extra properties set
            }),
            catchError((err) => {
                this.logSvc.logError(err);
                return of(config); // Return input config with extra properties still unset
            })
        );
    }
}

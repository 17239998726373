<div fxLayout="column">

  <mat-card fxFlex="100%" class="example-card" style="background-color: #F7F7F7; height: 20px;">
    <mat-form-field class="position field-search" appearance="outline">
      <span matPrefix class="lg-only" role="none">
        <mat-icon>search</mat-icon>
      </span>
      <input matInput tabindex="0" placeholder="Search by name" [(ngModel)]="searchValue" [disabled]="moveStatus === 'Withdrawn'" (keyup)="filter(searchValue)"
        (keyup.enter)="filter(searchValue)" aria-label="Search by name" role="text">
      <button tabindex="0" mat-button matSuffix mat-icon-button aria-label="Clear" class="close-btn"
        [hidden]="!searchValue" (click)="clearTextSearch()" role="button">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <span matTooltip="Click here to edit Details"
      *ngIf="!editDetails && additionalDetailCapability === 'write'"
      style="color:#2065F8;cursor: default; float: right;">
      <button [disabled]="moveStatus === 'Withdrawn'" (click)="editDetails = !editDetails">
        <mat-icon tabindex="0" style="cursor: pointer;color: gray;" >create</mat-icon>
      </button>
      Edit Details
    </span>
    <span>
      <button tabindex="0"
        *ngIf="editDetails" [disabled]="!isCurrencyValid() || AdditionalDetailsFormGroup.invalid" class=" saveBtn mat-button contained-button"
        (click)="onSaveClick()">SAVE
      </button>
    </span>
    <span style="width: 200px;position: absolute;padding-top: 13px;top: 0px;right: 200px;color:#2065F8;"
      *ngIf="editDetails" class="mat-button text-button" color="primary" (click)="onDismiss()">CANCEL
    </span>
  </mat-card>

  <div class="config-field" fxLayout="row" fxLayoutGap>
    <mat-card fxFlex="25%" class="example-card" *ngFor="let field of configurableFieldList">
      <mat-card-content>
        <div *ngIf="!editDetails">
          <div tabindex="0" class="label"><span [innerHTML]="field.displayName | highlight: searchValue">{{field.displayName}}</span>
          </div>
          <div class="value">
            <p *ngIf="(field.type === 'Multiple Select' && !showMore)">
              {{ (showMore) ? field.value : field.value | slice:0:1}} <span *ngIf="!showMore">...</span>
              <a href="javascript:;" *ngIf="!showMore" (click)="showMore=true">[Show More]</a>
            </p>
            <ul *ngIf="(field.type === 'Multiple Select' && showMore)">
              <li *ngFor="let val of field.value">{{val}}</li>
            </ul>
            <p *ngIf="(field.type === 'Multiple Select')">
              <span *ngIf="showMore">...</span>
              <a href="javascript:;" *ngIf="showMore" (click)="showMore=false">[Show less]</a>
            </p>
            <div class="value currency-padding" *ngIf="field.type === 'Currency'">
              <div fxFlex="20%">
                Currency: {{field.value?.currencyCode}}
              </div>
              <div>
                Amount: {{field.value?.currencyAmount}}
              </div>
            </div>
            <p *ngIf="!(field.type === 'Multiple Select') && !(field.type === 'Date') && !(field.type === 'Currency')">
              {{field.value? field.value: '' }}
            </p>
            <p *ngIf="(field.type === 'Date')">
              {{field.value | date :'MM/dd/yyyy'}}

          </div>
        </div>
        <div *ngIf="editDetails">
          <form [formGroup]="AdditionalDetailsFormGroup">
            <div [ngSwitch]="field.type">
              <div *ngSwitchCase="'Text'">
                <mat-form-field>
                  <mat-label>{{field.displayName}}</mat-label>
                  <input [formControlName]="field.displayName" [value]="field.value? field.value: null" type="text" class="level" matInput>
                </mat-form-field>
              </div>
              <div *ngSwitchCase="'Number'">
                <mat-form-field>
                  <mat-label>{{field.displayName}}</mat-label>
                  <input [formControlName]="field.displayName" [value]="field.value" type="number" class="level" 
                    min="0" oninput="validity.valid||(value='');" matInput>
                </mat-form-field>
              </div>
              <div *ngSwitchCase="'Date'">
                <mat-form-field>
                  <mat-label>{{field.displayName}}</mat-label>
                  <input matInput [formControlName]="field.displayName"  [matDatepicker]="picker" class="level"
                    [value]="field.value">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div *ngSwitchCase="'Single Select'">
                <mat-form-field>
                  <mat-label>{{field.displayName}} </mat-label>
                  <mat-select [formControlName]="field.displayName">
                    <mat-option *ngFor="let opt of sortSingleSelectValue(field)" [value]="opt">
                      {{opt}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngSwitchCase="'Multiple Select'">
                <mat-form-field>
                  <mat-label>{{field.displayName}} </mat-label>
                  <mat-select (selectionChange)="getSelections($event,field.displayName)"
                    formControlName="{{field.displayName}}"
                    [(value)]="AdditionalDetailsFormGroup.get(field.displayName).value"
                    (openedChange)="openedChanges($event,field.displayName)" multiple>
                    <mat-form-field>
                      <input matInput placeholer="Search" [value]="searchText"
                        (input)="filterValue($event.target.value,field.displayName)" />
                      <button tabindex="0" mat-button matSuffix mat-icon-button aria-label="Clear" class="close-btn"
                        [hidden]="!searchText" (click)="clearSearch()" role="button">
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                    <mat-option (onSelectionChange)="getSelections($event,field.displayName)"
                      *ngFor="let opt of field.dropDownValues" [value]="opt">
                      {{opt}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngSwitchCase="'Country'">
                <mat-form-field>
                  <mat-label>{{field.displayName}} </mat-label>
                  <input type="text" matInput #autoCompleteInput [formControlName]="field.displayName"
                    [matAutocomplete]="autoCountry">
                  <mat-autocomplete #autoCountry="matAutocomplete" [panelWidth]="293">
                    <mat-option [value]="option.countryName" *ngFor="let option of filteredCountryOptions | async">
                      {{ option.countryName }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div [formGroupName]="field.displayName" *ngSwitchCase="'Currency'">
                {{field.displayName}}
                <mat-form-field>
                  <mat-label>Currency</mat-label>
                  <input type="text" aria-label="currency" matInput #autoCompleteCurrencyCode [matAutocomplete]="autoCurrencyCode"
                    formControlName="currencyCode">
                    <mat-autocomplete #autoCurrencyCode ="matAutocomplete" [displayWith]="displayCurrencyCodeFn" >
                      <mat-option  [value]="curr" *ngFor="let curr of _getFilterObservableWrtDisplayName(field.displayName) | async">
                          {{curr}}
                      </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Amount</mat-label>
                  <input type="number" class="level" 
                  number-only formControlName="currencyAmount" 
                  matInput min="0">
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="dynamicData">
    <div class="row">
      <div class="label">
        <strong>Party ID:</strong>
      </div>
      <div class="value">
        {{ data.candidatePartyId }}
      </div>
    </div>
    <div class="row">
      <div class="label">
        <strong>Order Reference:</strong>
      </div>
      <div class="value">
        {{ data.orderReference }}
      </div>
    </div>
    <div class="row">
      <div class="label">
        <strong>Order ID:</strong>
      </div>
      <div class="value">
        {{ data.orderRequestId }}
      </div>
    </div>
    <div *ngIf="data && data.custFileId" class="row">
      <div class="label">
        <strong>Customer FileId ID:</strong>
      </div>
      <div class="value">
        {{ data.custFileId }}
      </div>
    </div>
    <button mat-button class="closeButton" mat-dialog-close>Close</button> 
  </div>
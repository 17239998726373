<ngx-spinner type="ball-spin-clockwise-fade" role="alert" aria-atomic="true" size="medium">
    <span class="cdk-visually-hidden">Loading, please wait...</span>
</ngx-spinner>

<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column">
    <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
        fxLayoutGap="20px" fxLayoutGap.xs="0em">
        <div class="candidate-dialogtitle"> {{ formTitle }}</div>
        <a href="#" class="close-icon" (click)="onDismiss($event)" [ngClass]="{'disable':formDisable==true}"
            title="close">
            <mat-icon>close</mat-icon>
        </a>
    </div>
    <form fxLayout="column" class="middle-section" [formGroup]="addCandidateForm">
        <div class="middle-section">
            <mat-dialog-content>
                <div>
                    <div class="lump-sum-tggle-pos" fxLayout="row" fxLayoutGap.xs="0em" *ngIf="authorizationType === 'Traditional' && clientService.bbRole.includes('lumpSum') && isLumpSumTransfereeEnabled">
                        <div fxFlex="30%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                           <mat-slide-toggle  tabindex="0" class="lump-sum-toggle" [disabled]="isFieldReadOnly" [checked]="isLumpSum" (toggleChange)="onToggleChange()">Lump Sum</mat-slide-toggle>
                        </div>
                    </div>
                    
                    <div class="input-row" fxLayout="row" fxLayoutAlign="space-evenly" fxLayout.xs="column"
                        fxLayoutGap.xs="0em" formGroupName="nameDetails">
                        <!--Title field-->
                        <div fxFlex="10%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label>Title</mat-label>
                                <mat-select [disabled] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || editCandidateProfile?.moveStatus == 'Withdrawn' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" formControlName="title">
                                    <mat-option *ngFor="let title of titleValues" [value]="title">{{title}}</mat-option>
                                </mat-select>
                                <mat-error>
                                    {{ getErrorMessage('nameDetails.title', 'Title') }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!--First Name field-->
                        <div fxFlex="40%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <input matInput placeholder="First Name" 
                                [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || editCandidateProfile?.moveStatus == 'Withdrawn' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                 multi-type-char formControlName="firstName" minLength="1" maxlength="50">
                                <mat-error>
                                    {{ getErrorMessage('nameDetails.firstName', 'First Name') }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!--Last Name field-->
                        <div fxFlex="40%" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <input matInput placeholder="Last Name" [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" multi-type-char formControlName="lastName" minLength="2" maxlength="50">
                                <mat-error>
                                    {{ getErrorMessage('nameDetails.lastName', 'Last Name') }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                     <!--Transferee and Accompanying memeber Info section-->
                     <mat-accordion class="example-headers-align" *ngIf="transfereeIDInfoCapability == 'read' && transfereeInfo">
                        <mat-expansion-panel class="transferee-info-expansion-panel" [expanded]="true" [hideToggle]="true" [disabled]="true">
                            <mat-expansion-panel-header class='panel-heading '>
                                Transferee ID Information
                            </mat-expansion-panel-header>
                            <div>
                                <!-- Transferee details-->
                                <div>
                                    <mat-label class="table-title">Transferee Details</mat-label>
                                    <div class="table-scroll mat-elevation-z8">
                                        <table mat-table [dataSource]="transfereeDataSource" matSort>
                                          <ng-container matColumnDef="partyId">
                                            <th width="20%" mat-sort-header [disabled]="true" *matHeaderCellDef style="padding-left: 24px;"> Party Id </th>
                                            <td mat-cell *matCellDef="let element">
                                              <div *ngIf="element.partyId">
                                                <span [innerHTML]="element.partyId"></span>
                                              </div>
                                            </td>
                                          </ng-container>
                                          <ng-container matColumnDef="moveOrderId">
                                            <th width="20%" mat-sort-header [disabled]="true" *matHeaderCellDef style="padding-left: 24px;"> MoveOrder Id</th>
                                            <td mat-cell *matCellDef="let element">
                                                <div *ngIf="element.moveOrderId">
                                                    <span [innerHTML]="element.moveOrderId"></span>
                                                </div>
                                            </td>
                                          </ng-container>
                                          <ng-container matColumnDef="referenceNumber">
                                            <th width="20%" mat-sort-header [disabled]="true"  *matHeaderCellDef style="padding-left: 10px;">  Reference Number </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div *ngIf="element.referenceNumber">
                                                    <span [innerHTML]="element.referenceNumber"></span>
                                                </div>
                                            </td>
                                          </ng-container>
                                          <tr class="mat-row" *matNoDataRow>
                                            <td class="mat-cell" colspan="3" style="text-align: center;">No records found</td>
                                          </tr>
                                          <tr mat-header-row *matHeaderRowDef="transfereeInfoDisplayedColumns"></tr>
                                          <tr mat-row *matRowDef="let row; columns: transfereeInfoDisplayedColumns;"></tr>
                                        </table>
                                    </div>
                                </div>
                                <!-- Family Memeber details-->
                                <div>
                                    <mat-label class="table-title">Accompanying Member Details</mat-label>
                                    <div class="table-scroll mat-elevation-z8">
                                        <table #familyInfoTable mat-table [dataSource]="familyDataSource" matSort>
                                          <ng-container matColumnDef="name">
                                            <th width="20%" mat-sort-header *matHeaderCellDef style="padding-left: 24px;">Name</th>
                                            <td mat-cell *matCellDef="let element">
                                              <div *ngIf="element.name">
                                                <span [innerHTML]="element.name"></span>
                                              </div>
                                            </td>
                                          </ng-container>
                                          <ng-container matColumnDef="relationship">
                                            <th width="20%" mat-sort-header *matHeaderCellDef style="padding-left: 24px;">Relationship</th>
                                            <td mat-cell *matCellDef="let element">
                                                <div *ngIf="element.relationship">
                                                    <span [innerHTML]="element.relationship"></span>
                                                </div>
                                            </td>
                                          </ng-container>
                                          <ng-container matColumnDef="partyId">
                                            <th width="20%" mat-sort-header [disabled]="true" *matHeaderCellDef style="padding-left: 10px;">Party Id</th>
                                            <td mat-cell *matCellDef="let element">
                                                <div *ngIf="element.partyId">
                                                    <span [innerHTML]="element.partyId"></span>
                                                </div>
                                            </td>
                                          </ng-container>
                                          <tr class="mat-row" *matNoDataRow>
                                            <td class="mat-cell" colspan="3" style="text-align: center;">No records found</td>
                                          </tr>
                                          <tr mat-header-row *matHeaderRowDef="familyInfoDisplayedColumns"></tr>
                                          <tr mat-row *matRowDef="let row; columns: familyInfoDisplayedColumns;"></tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <!--sso section-->
                    <mat-accordion class="example-headers-align" *ngIf="clientService.bbRole?.includes('sso')">
                        <mat-expansion-panel [expanded]="true">
                            <mat-expansion-panel-header class='panel-heading '>
                                Single Sign On(SSO)
                            </mat-expansion-panel-header>
                            <div class="input-row" class="addcandidate-toppadding" fxLayout="row" fxLayoutAlign="space-between"
                                fxLayoutGap="0.5em" fxLayout.xs="column" fxLayoutGap.xs="0em">
                                <div class="sso-field" fxFlex="30%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                    <span (click)="toggle()" [class.disabled]="disableSSO()">SSO &nbsp;</span><mat-slide-toggle [disabled]="disableSSO()" tabindex="0" class="sso-toggle" [checked]="isNewHire" (change)="setSSONewHire($event.checked)" (keyDown.enter)="onSSOKeyDown()">New Hire</mat-slide-toggle>
                                </div>
                                <div fxFlex="70%"  fxLayout.xs="column" fxFlex.xs="100%" [ngStyle]="!isNewHire ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                                    <mat-form-field fxFlex>
                                        <mat-label>SSO Number</mat-label>
                                        <input matInput type="text" placeholder="SSO number"
                                            [readonly]="disableSSO()" 
                                            formControlName="ssoIdentifier" (input)="ssoChange($event.target.value)">
                                        <mat-error>
                                            {{ getErrorMessage('ssoIdentifier', 'ssoIdentifier') }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header class='panel-heading'>
                                Contact Details
                            </mat-expansion-panel-header>
                            <div formArrayName="email">
                                <div *ngFor="let emailDetail of addCandidateForm.get('email')['controls']; last as islast; let index = index">
                                    <div [formGroupName]='index'>
                                        <div class="input-row" class="addcandidate-toppadding" fxLayout="row" fxLayoutAlign="space-between"
                                            fxLayoutGap="0.5em" fxLayout.xs="column" fxLayoutGap.xs="0em">
                                            <!--Email Address field-->
                                            <div fxFlex="60%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                                <mat-form-field fxFlex>
                                                    <input matInput type="text" placeholder="Email Address"
                                                        [readonly]="disableAllFields || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" 
                                                        (input)="checkEmailValueChangeForSSO($event.target.value)" formControlName="emailAddress">
                                                    <mat-error>
                                                        {{ getErrorMessage('email', 'Email Address') }}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="25%" fxLayout.xs="row" fxFlex.xs="100%" fxFlexFill>
                                                <mat-form-field>
                                                    <mat-label>Type</mat-label>
                                                    <mat-select (selectionChange)="checkSSOValidation()" formControlName="usageType" (click)="getEmailTypes(index)" [disabled]="disableAllFields || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || editCandidateProfile?.moveStatus == 'Withdrawn' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                                        <mat-option (onSelectionChange)="checkSSOValidation($event.source.value)" *ngFor="let email of emailTypes[index]; let emailIndex = index"
                                                            [value]="email">{{email}}</mat-option>
                                                    </mat-select>
                                                    <mat-error *ngIf="disableSaveDraftForSSO"> {{ getErrorMessage('email', 'email type') }} </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="5%" fxLayout="row" fxFlexFill>
                                                <button mat-icon-button role="button"
                                                    *ngIf = "userRole !== 'client-contact-initiator'"
                                                    [ngClass]="(this.addCandidateForm.controls.email.controls.length === 1 || disableAllFields || this.addCandidateForm.controls.email.controls.length === 1 || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))?'mat-delete-btn disabled-delete':'mat-delete-btn'"
                                                    (click)="deleteRow('email', index, addCandidateForm, $event)"
                                                    [disabled]="disableAllFields || this.addCandidateForm.controls.email.controls.length === 1 || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || editCandidateProfile?.moveStatus == 'Withdrawn' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                                    <mat-icon aria-label="delete email" tabindex="0" (keyup.enter) = "deleteRow('email', index, addCandidateForm, $event)" class="delete-icomn-style">delete_outline</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                        <div> <button mat-icon-button role="button" id="addNewEmail"
                                                [ngStyle] = "(islast && this.addCandidateForm.controls.email.controls.length !== 2 && userRole !== 'client-contact-initiator')? {'visibility': 'visible'} : {'visibility': 'hidden'}"
                                                *ngIf = "islast && this.addCandidateForm.controls.email.controls.length !== 2 && userRole !== 'client-contact-initiator'"
                                                [disabled] = "disableAllFields || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || editCandidateProfile?.moveStatus == 'Withdrawn' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                                (click)="addNewRow('email', (index + 1), addCandidateForm)"
                                                class="mat-button outlined-button add-button"> + Add </button> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="phone-details" formArrayName="phone">
                                <div *ngFor="let phoneDetail of addCandidateForm.get('phone')['controls']; last as islast; let index = index">
                                    <div [formGroupName]='index'>
                                        <div fxLayout.xs="row" fxFlex.xs="100%" fxLayout="row" fxFlexFill fxLayoutGap="0.5em"
                                            fxLayoutAlign="space-between">
                                            <div fxFlex="15%" fxFlex.xs="30%">
                                                <mat-form-field>
                                                    <mat-label>Phone Code </mat-label>
                                                    <input type="text" aria-label="Phone Code" matInput #autoCompleteInput
                                                        formControlName="phoneDialCode" [matAutocomplete]="auto" [readonly]="disableAllFields || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || editCandidateProfile?.moveStatus == 'Withdrawn' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" [panelWidth]="293">
                                                        <mat-option [value]="option.countryDialingCode"
                                                            *ngFor="let option of filteredOptions | async">
                                                            {{ option.countryName }} (+ {{ option.countryDialingCode }})
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                    <mat-error>{{ getErrorMessage('phone', 'Phone Code') }}</mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="58%" fxFlex.xs="70%">
                                                <mat-form-field fxFlex>
                                                    <input matInput type="text" placeholder="Phone Number" [readonly]="disableAllFields || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || editCandidateProfile?.moveStatus == 'Withdrawn' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                                        number-only formControlName="phoneNumber" minLength="7" maxlength="18">
                                                    <mat-error> {{ getErrorMessage('phone', 'Mobile #') }}</mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="27%" fxLayout="row" fxFlexFill>
                                                <mat-form-field>
                                                    <mat-label>Type</mat-label>
                                                    <mat-select formControlName="type" (click)="getPhoneTypes(index)"
                                                        [disabled]="disableAllFields || (userRole === 'client-contact-initiator') || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || editCandidateProfile?.moveStatus == 'Withdrawn' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                                        <mat-option  *ngFor="let phone of phoneTypes[index]; let phoneIndex = index"
                                                            [value]="phone">{{phone}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error> {{ getErrorMessage('phone', 'phone type') }} </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="5%" fxLayout="row" fxFlexFill>
                                                <button mat-icon-button role="button"
                                                    *ngIf = "userRole !== 'client-contact-initiator'"
                                                    [ngClass]="this.addCandidateForm.controls.phone.controls.length === 1?'mat-delete-btn disabled-delete':'mat-delete-btn'"
                                                    (click)="deleteRow('phone', index, addCandidateForm, $event)"
                                                    [disabled]="disableAllFields || this.addCandidateForm.controls.phone.controls.length === 1 || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || editCandidateProfile?.moveStatus == 'Withdrawn' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                                    <mat-icon aria-label="delete phone" tabindex="0" (keyup.enter) = "deleteRow('phone', index, addCandidateForm, $event)" class="delete-icomn-style">delete_outline</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                        <div> <button mat-icon-button role="button" id="addNewPhoneRow"
                                            *ngIf = "islast && this.addCandidateForm.controls.phone.controls.length !== 5 && userRole !== 'client-contact-initiator'"
                                            [disabled] = "disableAllFields || this.addCandidateForm.controls.phone.invalid || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || editCandidateProfile?.moveStatus == 'Withdrawn' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                            (click)="addNewRow('phone', (index + 1), addCandidateForm)"
                                            class="mat-button outlined-button add-button"> + Add </button></div>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                    
                    <div fxLayout="row" fxLayoutAlign="space-between"  class="addcandidate-toppadding"
                        formGroupName="addressForm" class="location-details" fxLayout.xs="column" 
                        fxLayoutGap.xs="0px">
                        <!-- <div fxFlex="50%" fxLayoutAlign="start" fxLayout="column" fxFlex.xs="40%" formGroupName="departureAddr"> -->
                        <mat-card fxFlex="45%" fxLayoutAlign="start" fxLayout="column" fxFlex.xs="40%"
                            formGroupName="departureAddr" class="address">
                            <mat-card-title class="custom card-title" fxLayout="row" fxLayoutAlign="start center">
                                <img src="../../assets/images/arrival.svg" alt="arrival" class="arrival-icon" />
                                Departure Location
                            </mat-card-title>
                            <mat-card-content>
                                <div fxLayout="column" class="m-t-10">
                                    <mat-form-field>
                                        <mat-label>Country</mat-label>
                                        <input type="text" aria-label="Country" id="deptCountry"
                                            matInput #autoCompleteCountryInput
                                            formControlName="country"
                                            [matAutocomplete]="autoCountry" (blur)="onFocusOutDepartureCountryCode()"  [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                    <mat-autocomplete  #autoCountry="matAutocomplete" [panelWidth] = "293">
                                        <mat-option [value]="option.countryName" *ngFor="let option of filteredCountryOptions | async">
                                            {{ option.countryName }}
                                        </mat-option>
                                      </mat-autocomplete>
                                        <!--<mat-select formControlName='country' [disabled] = "userRole !== 'client-contact-initiator'">
                                            <mat-option *ngFor="let country of countryList" [value]="country.countryName">
                                                {{ country.countryName }}</mat-option>
                                        </mat-select> -->
                                        <mat-error>
                                            {{ getErrorMessage('addressForm.departureAddr.country', 'Country') }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>



                                <div fxLayout="column" class="m-t-10" formArrayName="streets">
                                    <div *ngFor="let street of streets.controls; index as idx">
                                        <mat-form-field>
                                            <mat-label>Street Address</mat-label>
                                            <input matInput [formControlName]="idx" *ngIf="hasDepartureStreet"
                                            [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" 
                                            placeholder="Street Address (Optional)"/>
                                            <input matInput placeholder="Street Address (Optional)" *ngIf="!hasDepartureStreet"
                                            mapAutocomplete
                                            [country]="departCountryCode"                                        
                                            type="address"
                                            (placeResult)="onAutocompleteSelected($event)"
                                            [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" 
                                            [formControlName]="idx">
                                        </mat-form-field>
                                    </div>
                                </div>



                                <div fxLayout="column" class="m-t-10" formArrayName="cities">
                                    <div *ngFor="let city of cities.controls; index as cityIdx">
                                    <mat-form-field *ngIf="hasDepartureStreet">
                                        <mat-label>City</mat-label>
                                        <input matInput  [formControlName]="cityIdx" [readonly]="disableAllFields || !hasDepartureCountry || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                         placeholder="City"/>
                                      
                                        <mat-error>
                                            {{ getErrorMessage('addressForm.departureAddr.cities', 'City',0) }}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field *ngIf="!hasDepartureStreet">
                                        <mat-label>City</mat-label>                                       
                                         <input  matInput 
                                         mapAutocomplete
                                         [country]="departCountryCode"
                                         type="(cities)"
                                         (placeResult)="onDepartureCityAutocompleteSelected($event)"                                         
                                         [formControlName]="cityIdx" [readonly]="disableAllFields || !hasDepartureCountry || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                          placeholder="City">                                       
                                        <mat-error>
                                            {{ getErrorMessage('addressForm.departureAddr.cities', 'City',0) }}
                                        </mat-error>
                                    </mat-form-field>
                                    </div>
                                </div>

                                <div fxLayout="row" class="m-t-10">
                                    <div formArrayName="states" fxFlex="50%">
                                        <div *ngFor="let state of states.controls; index as stateIdx">
                                            <mat-form-field class="state-fields">
                                                <mat-label>State/Province</mat-label>
                                                <input matInput [formControlName]="stateIdx" *ngIf="hasDepartureStreet || hasDepartureCity"
                                                [readonly]="disableAllFields || !hasDepartureCountry || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" 
                                                placeholder="State"/>
                                                <input matInput [formControlName]="stateIdx" *ngIf="!hasDepartureStreet && !hasDepartureCity"
                                                mapAutocomplete
                                                [country]="departCountryCode"
                                                type="administrative_area_level_1"
                                                (placeResult)="onDepartureStateAutocompleteSelected($event)"
                                                (onLocationSelected)="onLocationSelected($event)"
                                                [readonly]="disableAllFields || !hasDepartureCountry || userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist' && editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" 
                                                placeholder="State"/>
                                                
                                                <mat-error>
                                                    {{ getErrorMessage('addressForm.departureAddr.states', 'State',0) }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div formArrayName="postalCodes" fxFlex="45%">
                                        <div *ngFor="let postal of postalCodes.controls; index as postalIdx">
                                            <mat-form-field class="postal-code-fields">
                                                <mat-label>Postal Code</mat-label>
                                                <input matInput placeholder="Postal Code" *ngIf="hasDepartureStreet ||hasDepartureCity || hasDepartureState"
                                                [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" [formControlName]="postalIdx" minLength= "1" maxlength="15"/>

                                                <input matInput placeholder="Postal Code" *ngIf="!hasDepartureStreet && !hasDepartureCity && !hasDepartureState"
                                                mapAutocomplete
                                                [country]="departCountryCode"
                                                type="postal_code"
                                                (placeResult)="onDeparturePostalCodeAutocompleteSelected($event)"
                                                (onLocationSelected)="onLocationSelected($event)"
                                                [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" [formControlName]="postalIdx" minLength= "1" maxlength="15">
                                                <mat-error>
                                                    {{ getErrorMessage('addressForm.departureAddr.postalCodes', 'Postal Code',0) }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div fxLayout="column" class="m-t-10">
                                    
                                </div> -->


                            </mat-card-content>
                        </mat-card>

                        <!-- </div> -->

                        <div fxFlex="5%" fxShow="true" class="chevron" fxHide.xs fxLayoutAlign="center center">
                            <mat-icon class="material-icons modal-rightnavarrow">chevron_right</mat-icon>
                        </div>
                        <mat-card class="address" fxFlex="45%" fxFlex.xs="40%" fxLayout="column"
                            formGroupName="destinationAddr">
                            <mat-card-title class="card-title p-t-40 p-l-0" fxLayout="row" fxLayoutAlign="start center">
                                <img src="../../assets/images/departure.svg" alt="departure" class="departure-icon" />
                                Destination Location
                            </mat-card-title>
                            <mat-card-content>
                                <div fxLayout="column" class="m-t-10">
                                    <mat-form-field>
                                        <mat-label>Country</mat-label>
                                        <input type="text" aria-label="Country" matInput #autoCompleteDestCountryInput id="destCountry"
                                            formControlName="country" (blur)="onFocusOutDestinationCountryCode()"
                                            [matAutocomplete]="autoDestCountry"  [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                        <mat-autocomplete  #autoDestCountry="matAutocomplete" [panelWidth] = "293">
                                            <mat-option [value]="option.countryName" *ngFor="let option of filteredDestCountryOptions | async">
                                                {{ option.countryName }}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <!--<mat-select formControlName='country' [disabled] = "userRole !== 'client-contact-initiator'">
                                            <mat-option *ngFor="let country of countryList" [value]="country.countryName">
                                                {{ country.countryName }}</mat-option>
                                        </mat-select> -->
                                        <mat-error>
                                            {{ getErrorMessage('addressForm.destinationAddr.country', 'Country') }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div fxLayout="column" class="m-t-10" formArrayName="destiStreets">
                                    <div *ngFor="let street of destiStreets.controls; index as destiStreetIdx">
                                        <mat-form-field>
                                            <mat-label>Street Address</mat-label>
                                            <input matInput [formControlName]="destiStreetIdx" *ngIf="hasDestinationStreet"
                                            [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" 
                                            placeholder="Street Address (Optional)"/>
                                            <input matInput placeholder="Street Address (Optional)" *ngIf="!hasDestinationStreet"
                                            mapAutocomplete
                                            [country]="destiCountryCode"                                        
                                            type="address"
                                            (placeResult)="onDestinationStreetAutocompleteSelected($event)"                                        
                                            [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" [formControlName]="destiStreetIdx">
                                        </mat-form-field>

                                    </div>
                                </div>


                                <div fxLayout="column" class="m-t-10" formArrayName="destiCities">
                                    <div *ngFor="let city of destiCities.controls; index as destiCityIdx">
                                        <mat-form-field>
                                            <mat-label>City</mat-label>
                                            <input matInput [formControlName]="destiCityIdx" *ngIf="hasDestinationStreet"
                                            [readonly]="disableAllFields || !hasDestinationCountry || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist')  || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"  
                                            placeholder="City"/>
                                            <input matInput [formControlName]="destiCityIdx" *ngIf="!hasDestinationStreet"
                                            mapAutocomplete
                                            [country]="destiCountryCode"                                        
                                            type="(cities)"
                                            (placeResult)="onDestinationCityAutocompleteSelected($event)"
                                            [readonly]="disableAllFields || !hasDestinationCountry || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"  
                                            placeholder="City"/>
                                            
                                            <mat-error>
                                                {{ getErrorMessage('addressForm.destinationAddr.destiCities', 'City',0)
                                                }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>


                                <div fxLayout="row" class="m-t-10" >
                                    <div formArrayName="destiStates" fxFlex="50%">
                                        <div *ngFor="let state of destiStates.controls; index as destiStateIdx">
                                            <mat-form-field class="state-fields">
                                                <mat-label>State/Province</mat-label>
                                                <input matInput [formControlName]="destiStateIdx" *ngIf="hasDestinationStreet || hasDestinationCity"
                                                [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent'  || editCandidateProfile?.moveStatus == 'Authorization Initiated' || !hasDestinationCountry || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                                placeholder="State"/>
                                                <input matInput [formControlName]="destiStateIdx" *ngIf="!hasDestinationStreet && !hasDestinationCity"
                                                mapAutocomplete
                                                [country]="destiCountryCode"                                        
                                                type="administrative_area_level_1"
                                                (placeResult)="onDestinationStateAutocompleteSelected($event)"
                                                [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || !hasDestinationCountry || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                                placeholder="State"/>

                                                <mat-error>
                                                    {{ getErrorMessage('addressForm.destinationAddr.destiStates',
                                                    'State',0) }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div formArrayName="destiPostalCodes" fxFlex="45%">
                                        <div
                                            *ngFor="let postalCode of destiPostalCodes.controls; index as destiPostalCodeIdx">
                                            <mat-form-field class="postal-code-fields">
                                                <mat-label>Postal Code</mat-label>
                                                <input matInput placeholder="Postal Code" *ngIf="hasDestinationStreet || hasDestinationCity || hasDestinationState"
                                                [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" 
                                                [formControlName]="destiPostalCodeIdx" minLength= "1" maxlength="15"/>
                                                <input matInput placeholder="Postal Code" *ngIf="!hasDestinationStreet && !hasDestinationCity && !hasDestinationState"
                                                mapAutocomplete
                                                [country]="destiCountryCode"                                      
                                                type="postal_code"
                                                (placeResult)="onDestinationPostalCodeAutocompleteSelected($event)"
                                                [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" 
                                                [formControlName]="destiPostalCodeIdx" minLength= "1" maxlength="15"/>

                                                <mat-error>
                                                    {{ getErrorMessage('addressForm.destinationAddr.destiPostalCodes',
                                                    'Postal Code',0) }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div fxLayout="column" class="m-t-10">
                                   
                                </div> -->
                            </mat-card-content>
                        </mat-card>
                    </div>


                </div>
                <div>
                    <div fxLayout="row" fxLayoutAlign="space-evenly" fxLayoutGap="1em" class="addcandidate-toppadding"
                        fxLayout.xs="column" fxLayoutGap="1em" fxLayoutGap.xs="0px">
                        <mat-form-field *ngIf = "userRole === 'file-setup-specialist'">
                            <mat-label>Division</mat-label>
                            <input matInput type="text"
                                   aria-label="Division"
                                   formControlName="divisionPartyId"
                                   [matAutocomplete]="divisionAutocomplete"
                                   [readonly] = "disableAllFields || ((userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || (editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))"
                                   />
                            <mat-autocomplete #divisionAutocomplete="matAutocomplete" [displayWith]="displayDivisionFn" (optionSelected)="getProgram($event.option.value.division_id, 'selectNewProgram')">
                              <mat-option *ngFor="let division of filteredDivisionValues | async" [value]="division">
                                {{division.division_name}} ({{ division.division_number }})
                              </mat-option>
                            </mat-autocomplete>
                            <mat-error>
                                {{ getErrorMessage('divisionPartyId', 'Division') }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field *ngIf = "userRole !== 'file-setup-specialist'">
                            <mat-label>Division</mat-label>
                            <input matInput type="text"
                                   aria-label="Division"
                                   formControlName="divisionPartyId"
                                   [matAutocomplete]="divisionAutocomplete"
                                   [readonly] = "disableAllFields || ((userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || (editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))"
                                   />
                            <mat-autocomplete  #divisionAutocomplete="matAutocomplete" [displayWith]="displayCCDivisionFn" (optionSelected)="getProgram($event.option.value.cc_division_id, 'selectNewProgram')">
                                <mat-option *ngFor="let division of filteredDivisionValues | async" [value]="division">
                                    {{division.cc_division_name}} ({{ division.cc_division_number }})
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error>
                                {{ getErrorMessage('divisionPartyId', 'Division') }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div formGroupName="programDetails" *ngIf="authorizationType !== 'Traditional' || isLumpSum" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="addcandidate-toppadding"
                        fxLayout.xs="column" fxLayoutGap="1em" fxLayoutGap.xs="0px">
                        <mat-form-field fxFlex.gt-md="60">
                            <mat-label>Assign Program</mat-label>
                            <mat-select [disabled]="disableAllFields || !(addCandidateForm.value.divisionPartyId) || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || (editCandidateProfile && editCandidateProfile.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" formControlName="programName"  (selectionChange)= "setPoints($event)">
                                <mat-option  tabindex="0" *ngFor="let program of programValues" [value]="program.programName">{{program.programName}}</mat-option>
                            </mat-select>
                            <mat-error>
                                {{ getErrorMessage('programDetails.programName', 'Program') }}
                            </mat-error>
                        </mat-form-field>
                        <mat-card *ngIf = "((points == 0 && isGOUser)|| points || addCandidateForm.value.programDetails?.points) && !isMMUser && !isLumpSum" fxFlex="25%" fxLayout="column" fxLayoutAlign="center center" class="mat-card-chip mat-card-point" 
                            [ngClass] = "(userRole != 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move') || data?.moveStatus === 'Withdrawn' ? 'mat-card-point-disabled' : 'mat-card-point'" >Points Assigned:
                            <div class="points-container" fxLayout="row" fxLayoutAlign="space-around stretch">
                                <span *ngIf = '!editPoint' tabindex="0" fxFlex="80%" fxLayoutAlign="center center"> {{addCandidateForm.value.programDetails?.points}} </span>  
                                <input matInput class = 'points' tabindex="0" [disabled]="disableAllFields || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" number-only fxFlex="80%" fxLayoutAlign="center center" formControlName="points" *ngIf = 'editPoint'>
                                <mat-icon tabindex="0" *ngIf = "((userRole === 'client-contact-initiator' || userRole === 'file-setup-specialist') && (editCandidateProfile?.moveStatus != 'Invitation Sent' && editCandidateProfile?.moveStatus != 'Authorization Initiated' && editCandidateProfile?.moveStatus != 'Requested' && this.editCandidateProfile?.moveStatus != 'Approved' && this.editCandidateProfile?.moveStatus != 'Not Approved' && editCandidateProfile?.moveStatus != 'CP In Progress' && data?.moveStatus != 'Withdrawn'))" class="edit" (click)="editPointValidation()" id="editPointId" fxLayoutAlign="end" [ngStyle] = "{'color':editPoint ? '#4F85C4':'#ffffff' }">edit</mat-icon> 
                            </div>
                            <mat-error>
                            {{ editData || addCandidateForm?.get('programDetails.points').touched ? getErrorMessage('programDetails.points', 'Points') : '' }}
                        </mat-error>
                        </mat-card>
                        <mat-card *ngIf = "(amount || addCandidateForm.value.programDetails?.totalAmount) && isMMUser" fxFlex="25%" fxLayout="column" fxLayoutAlign="center center" class="mat-card-chip mat-card-point" 
                        [ngClass] = "editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || data?.moveStatus === 'Withdrawn' ? 'mat-card-point-disabled' : 'mat-card-point'" >Budget Amount:
                        <div tabindex="0" class="points-container" fxLayout="row" fxLayoutAlign="space-around stretch">
                            <span *ngIf = '!editPoint' tabindex="0" fxFlex="80%" fxLayoutAlign="center center"> {{addCandidateForm.value.programDetails?.totalAmount | number:'1.0':'en-US'}} {{addCandidateForm.value.programDetails?.currency}} </span>  
                            <input matInput class = 'points' tabindex="0" [disabled]="editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated'" number-only fxFlex="80%" fxLayoutAlign="center center" formControlName="totalAmount" *ngIf = 'editPoint'>
                            <mat-icon aria-label="Edit Amount" tabindex="0" *ngIf = "((userRole === 'client-contact-initiator' || userRole === 'file-setup-specialist') && (editCandidateProfile?.moveStatus != 'Invitation Sent' && editCandidateProfile?.moveStatus != 'Authorization Initiated' && data?.moveStatus != 'Withdrawn'))" class="edit" (keydown.enter)="editPointValidation()" (click)="editPointValidation()" fxLayoutAlign="end" [ngStyle] = "{'color':editPoint ? '#4F85C4':'#ffffff' }">edit</mat-icon> 
                        </div>
                        <mat-error>
                        {{ editData || addCandidateForm?.get('programDetails.totalAmount').touched ? getErrorMessage('programDetails.totalAmount', 'Amount') : '' }}
                    </mat-error>
                    </mat-card>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-evenly" fxLayoutGap="1em" class="addcandidate-toppadding"
                        fxLayout.xs="column" fxLayoutGap="1em" fxLayoutGap.xs="0px">
                        <mat-form-field >
                            <mat-label>Assign Policy</mat-label>
                            <input matInput type="text"
                                   aria-label="Assign Policy"
                                   formControlName="assignedPolicy"
                                   [matAutocomplete]="policyAutocomplete"
                                   [readonly]="disableAllFields || ((userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || (editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))"
                                   />
                            <mat-autocomplete #policyAutocomplete="matAutocomplete">
                              <mat-option *ngFor="let policy of filteredPolicyValues | async" [value]="policy">
                                {{policy}}
                              </mat-option>
                            </mat-autocomplete>
                            <mat-error>
                                {{ getErrorMessage('assignedPolicy', 'Policy') }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div formGroupName="programDetails" *ngIf="isLumpSum">
                        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px" class="m-b-10">
                            <div fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <!-- <input class="file-checkbox" type="checkbox" formControlName="cartusCalculate" aria-checked="false" tabindex="0" (keyDown.enter)="onCheckBoxKeyDown()"> -->
                                <mat-checkbox  aria-label="Cartus Calculate" formControlName="cartusCalculate" aria-checked="false" tabindex="0" [disabled]="isFieldReadOnly" (change)="onCartusCalClick()" (keyDown.enter)="onCartusCalClick()">
                                    Cartus Calculate  
                                </mat-checkbox>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px" class="addcandidate-toppadding">
                            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field fxFlex>
                                    <input matInput type="text" placeholder="Lump Sum Value" formControlName="amount" [readonly]="isFieldReadOnly">
                                </mat-form-field>
                            </div>
                            <div fxFlex="20%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Currency</mat-label>
                                    <input type="text"
                                    aria-label="currency"
                                    matInput
                                    formControlName="currency"
                                    [matAutocomplete]="lumpSumCurrency"
                                    [readonly]="isFieldReadOnly">
                                    <mat-autocomplete #lumpSumCurrency ="matAutocomplete">
                                        <mat-option [value]="curr.currency.code" *ngFor="let curr of filteredLumpSumCurrency | async">
                                            {{curr.currency?.code}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    
                    <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" class="addcandidate-toppadding"
                        fxLayout.xs="column" fxLayoutGap="1em" fxLayoutGap.xs="0px">
                        <mat-form-field fxFlex.gt-md="60">
                            <mat-label>Executive Officer</mat-label>
                            <mat-select formControlName="executiveOfficer"
                                [disabled]="disableAllFields || ((userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || (editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move') )">
                                <mat-option value="Yes">Yes (No Loans Permitted)</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                            <mat-error>
                                {{ getErrorMessage('executiveOfficer', 'Executive Officer') }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="userRole==='file-setup-specialist'" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px"
                        class="addcandidate-toppadding">
                        <div fxFlex="33%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label>Auth Create Date</mat-label>
                                <input matInput [matDatepicker]="authCreatedate" formControlName="authCreatedate"  [max]="maxSubmitDate"(dateChange)="restrictHistoricDates($event.target.value,'authSubTime')"
                                    [readonly]="disableAllFields || ((userRole !== 'file-setup-specialist') || (editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                <mat-datepicker-toggle matSuffix [for]="authCreatedate"
                                    [disabled]="disableAllFields || (userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' ||  (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                </mat-datepicker-toggle>
                                <mat-datepicker #authCreatedate></mat-datepicker>
                                <mat-error> {{ getErrorMessage('authCreatedate', 'Auth Create Date')}} </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="33%" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label>Auth Create Time</mat-label>
                                <input matInput [ngxTimepicker]="authSubTime" formControlName="authSubTime" [max]="maxAuthCreateTime"
                                    [disabled]="disableAllFields || ((userRole !== 'file-setup-specialist') || (editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                <ngx-material-timepicker-toggle matSuffix [for]="authSubTime" class="timepicker-style"
                                    [disabled]="disableAllFields || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' ||  (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                </ngx-material-timepicker-toggle>
                                <ngx-material-timepicker #authSubTime></ngx-material-timepicker>
                                <mat-error> {{ getErrorMessage('authSubTime', 'Auth Create Time')}} </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="33%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label>Auth Create Time Zone</mat-label>
                                <mat-select formControlName="authTimeZone"
                                    [disabled]="disableAllFields || ((userRole !== 'file-setup-specialist') || (editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                    <mat-option [value]="zone" *ngFor="let zone of timeZoneValues">{{zone}}</mat-option>
                                </mat-select>
                                <mat-error> {{ getErrorMessage('authTimeZone', 'Auth Create Time Zone')}} </mat-error>
                            </mat-form-field>
                        </div>
                </div>
                <div *ngIf="userRole==='file-setup-specialist'">
                    <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px"
                        class="addcandidate-toppadding">
                        <div fxFlex="33%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label>Client Authorization Submit Date</mat-label>
                                <input matInput [matDatepicker]="cliAuthSubDt" formControlName="cliAuthSubDt" [max]="maxSubmitDate" (dateChange)="restrictHistoricDates($event.target.value,'cliAuthSubTime')"
                                    [readonly]="disableAllFields || ((userRole !== 'file-setup-specialist') || (editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                <mat-datepicker-toggle matSuffix [for]="cliAuthSubDt"
                                matTooltip="Client Authorization Submit Date"  [disabled]="disableAllFields || (userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' ||  (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                </mat-datepicker-toggle>
                                <mat-datepicker #cliAuthSubDt></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div fxFlex="33%" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label>Client Authorization Submit Time</mat-label>
                                <input matInput [ngxTimepicker]="cliAuthSubTime" formControlName="cliAuthSubTime" [max]="maxCliAuthSubTime"
                                    [disabled]="disableAllFields || ((userRole !== 'file-setup-specialist') || (editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                <ngx-material-timepicker-toggle matSuffix [for]="cliAuthSubTime" class="timepicker-style"
                                matTooltip="Client Authorization Submit Time" [disabled]="disableAllFields || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' ||  (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                </ngx-material-timepicker-toggle>
                                <ngx-material-timepicker #cliAuthSubTime></ngx-material-timepicker>
                            </mat-form-field>
                        </div>
                        <div fxFlex="33%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label>Client Authorization Time Zone</mat-label>
                                <mat-select formControlName="cliAuthSubTimezone"
                                    [disabled]="disableAllFields || ((userRole !== 'file-setup-specialist') || (editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                    <mat-option [value]="czone" *ngFor="let czone of timeZoneValues">{{czone}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px"
                        class="addcandidate-toppadding">
                        <div fxFlex="33%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label  >Authorization Ready to Process Date</mat-label>
                                <input matInput  [matDatepicker]="authReadytoProcessDt" formControlName="authReadytoProcessDt" [max]="maxSubmitDate" (dateChange)="restrictHistoricDates($event.target.value,'authReadytoProcessTime')"
                                    [readonly]="disableAllFields || ((userRole !== 'file-setup-specialist') || (editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                <mat-datepicker-toggle matSuffix [for]="authReadytoProcessDt"
                                    matTooltip="Authorization Ready to Process Date" [disabled]="disableAllFields || ((userRole !== 'file-setup-specialist') || (editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                </mat-datepicker-toggle>
                                <mat-datepicker #authReadytoProcessDt ></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div fxFlex="33%" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label>Authorization Ready to Process Time</mat-label>
                                <input matInput [ngxTimepicker]="authReadytoProcessTime" formControlName="authReadytoProcessTime" [max]="maxAuthReadyToProcessTime"
                                    [disabled]="disableAllFields || ((userRole !== 'file-setup-specialist') || (editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                <ngx-material-timepicker-toggle matSuffix [for]="authReadytoProcessTime" class="timepicker-style"
                                    matTooltip="Authorization Ready to Process Time" [disabled]="disableAllFields || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' ||  (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                </ngx-material-timepicker-toggle>
                                <ngx-material-timepicker #authReadytoProcessTime></ngx-material-timepicker>
                            </mat-form-field>
                        </div>
                        <div fxFlex="33%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label>Authorization Ready to Process Time Zone</mat-label>
                                <mat-select formControlName="authReadytoProcessTimezone"
                                    [disabled]="disableAllFields || ((userRole !== 'file-setup-specialist') || (editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                    <mat-option [value]="atzone" *ngFor="let atzone of timeZoneValues">{{atzone}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="isTraditional" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px"
                        class="addcandidate-toppadding">
                        <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label>Delivery Team Model</mat-label>
                                <mat-select formControlName="deliveryTeamModel"
                                    [disabled]="disableAllFields || ((userRole !== 'file-setup-specialist') || (editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                    <mat-option *ngFor="let teamModel of deliveryTeamModelValues" [value]="teamModel">{{teamModel}}</mat-option>
                                </mat-select>
                                <mat-error> {{ getErrorMessage('deliveryTeamModel', 'Delivery Team Model') }} </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label>Initial Contact By</mat-label>
                                <mat-select formControlName="initialContactBy"
                                    [disabled]="disableAllFields || ((userRole !== 'file-setup-specialist') || (editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                    <mat-option [value]="contactBy" *ngFor="let contactBy of initialContactByValues">{{contactBy}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                
                <mat-accordion>
                    <mat-expansion-panel formGroupName="authorizationDetails" [expanded]="true"
                        *ngIf="userRole === 'file-setup-specialist'">
                        <mat-expansion-panel-header class='panel-heading'>
                            Authorization Details
                        </mat-expansion-panel-header>
                        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                            fxLayoutGap.xs="0px" class="addcandidate-toppadding">
                            <div fxFlex fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Client Contact Initiator</mat-label>
                                    <input type="text"
                                    aria-label="Client Contact Initiator"
                                    matInput #autoCompleteCci
                                    formControlName="cciDetails"
                                    [matAutocomplete]="auto" [readonly]="disableAllFields || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                    <mat-autocomplete  #auto="matAutocomplete" [displayWith]="displayClientContactFn" [readonly]="editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                        <mat-option [value]="cci" *ngFor="let cci of filteredClientContactDetails | async">
                                            {{ cci.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error> {{ getErrorMessage('authorizationDetails.cciDetails', 'Client Contact
                                        Initiator') }} </mat-error>
                                        <mat-icon class="drop-down-client">arrow_drop_down</mat-icon>
                                </mat-form-field>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                            fxLayoutGap.xs="0px" class="addcandidate-toppadding">

                            <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Contract Name</mat-label>
                                    <mat-select formControlName="contractDetails" [compareWith]="compareContractData" (selectionChange)="getContractBaseDetails($event)"
                                        [disabled]="disableAllFields || ((userRole !== 'file-setup-specialist') || (editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                        <mat-option [value]="contractData.contractDetails" *ngFor="let contractData of contractList">
                                            {{contractData?.contractDetails?.baseContract}}  {{contractData?.contractDetails?.contractId}}   {{contractData?.contractDetails?.contractName}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error> {{ getErrorMessage('authorizationDetails.contractDetails', 'contractDetails') }} </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Billing Currency</mat-label>
                                    <input type="text"
                                    aria-label="currency"
                                    matInput #autoCompleteCurr
                                    formControlName="currency"
                                    [matAutocomplete]="autoCurrency" [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                    <mat-autocomplete #autoCurrency ="matAutocomplete" [displayWith]="displayCurrencyFn" >
                                        <mat-option [value]="curr.currency" *ngFor="let curr of filteredCurrency | async">
                                            {{curr.currency?.code}}- {{curr.currency?.description}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Move Type</mat-label>
                                    <mat-select formControlName="moveType" [matTooltip]="addCandidateForm.get('authorizationDetails.moveType').value"
                                        [disabled]="disableAllFields || ((userRole !== 'file-setup-specialist') || (editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                        <mat-option *ngFor="let move of moveTypeValues" [value]="move" [matTooltip]="move">{{move}}</mat-option>
                                    </mat-select>
                                    <mat-error> {{ getErrorMessage('authorizationDetails.moveType', 'Move Type') }} </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>

                

                <!-- Billing and Reporting Data fields Start -->
                <mat-accordion *ngIf="configurableFields && configurableFields.length>0" >
                    <mat-expansion-panel formGroupName="optionalDetails" [expanded]="true">
                       <mat-expansion-panel-header class='panel-heading'>
                          Billing and Reporting Data fields
                       </mat-expansion-panel-header>
                       <mat-panel-description class="remove-margin">
                          <div fxFlex="100%" class="container">
                             <div *ngFor="let field of configurableFields">
                                <form [formGroup]="additionalDetailsFormGroup" (ngSubmit)="onSubmit(myform)">
                                <div [ngSwitch]="field.type">
                                   <div class="addcandidate-toppadding conf-field" *ngSwitchCase="'Text'">
                                      <mat-form-field>
                                         <mat-label>{{field.displayName}}</mat-label>
                                         <input formControlName="{{field.displayName}}" aria-label="{{field.displayName}}" [value]="field.value" type="text" class="level" [readonly]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                         matInput>
                                      </mat-form-field>
                                   </div>
                                   <div class="addcandidate-toppadding conf-field" *ngSwitchCase="'Number'">
                                      <mat-form-field>
                                         <mat-label>{{field.displayName}}</mat-label>
                                         <input formControlName="{{field.displayName}}" aria-label="{{field.displayName}}" [value]="field.value" type="number" class="level" 
                                         [readonly]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                         matInput>
                                      </mat-form-field>
                                   </div>
                                   <div class="addcandidate-toppadding conf-field" *ngSwitchCase="'Date'">
                                      <mat-form-field>
                                         <mat-label>{{field.displayName}}</mat-label>
                                         <input matInput [matDatepicker]="fieldDate" aria-label="{{field.displayName}}" formControlName="{{field.displayName}}" 
                                         readonly class="level">
                                         <mat-datepicker-toggle matSuffix [for]="fieldDate"></mat-datepicker-toggle>
                                         <mat-datepicker #fieldDate></mat-datepicker>
                                      </mat-form-field>
                                   </div>
                                   <div class="addcandidate-toppadding conf-field" *ngSwitchCase="'Single Select'">
                                      <mat-form-field>
                                         <mat-label>{{field.displayName}} </mat-label>
                                         <input type="text" aria-label="{{field.displayName}}" matInput #autoCompleteSingleSelect formControlName="{{field.displayName}}"
                                         [readonly]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" 
                                         [matAutocomplete]="autoSingleSelect">
                                        <mat-autocomplete #autoSingleSelect="matAutocomplete" [displayWith]="displaySelectFn">
                                            <mat-option [value]="opt" *ngFor="let opt of _getFilterObservableWrtDisplayName(field.displayName) | async">
                                                {{opt}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        </mat-form-field>
                                        </div>
                                   <div class="addcandidate-toppadding conf-field" *ngSwitchCase="'Multiple Select'">
                                      <mat-form-field>
                                        <mat-label>{{field.displayName}} </mat-label>
                                        <mat-select (selectionChange)="getSelection($event,field.displayName)" 
                                            formControlName="{{field.displayName}}"
                                            aria-label="{{field.displayName}}" 
                                            [readonly]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                            [(value)]="additionalDetailsFormGroup.get(field.displayName).value"
                                            (openedChange)="openedChange($event,field.displayName)"
                                             multiple>
                                            <mat-form-field class="multiple--search-field">
                                                <input matInput placeholer="Search" (input)="filterValues($event.target.value,field.displayName)" />
                                            </mat-form-field>
                                            <mat-option (onSelectionChange)="getSelection($event,field.displayName)" *ngFor="let opt of field.valueList" [value]="opt">
                                                {{opt}}
                                            </mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                   </div>
                                   <div class="addcandidate-toppadding conf-field" *ngSwitchCase="'Country'">
                                      <mat-form-field>
                                         <mat-label>{{field.displayName}} </mat-label>
                                         <input type="text" aria-label="{{field.displayName}}" matInput #autoCompleteSingleSelect formControlName="{{field.displayName}}"
                                         [readonly]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" [matAutocomplete]="autoSingleSelect">
                                        <mat-autocomplete #autoSingleSelect="matAutocomplete" [displayWith]="displaySelectFn">
                                            <mat-option [value]="opt" *ngFor="let opt of _getFilterObservableWrtDisplayName(field.displayName) | async">
                                                {{opt}}
                                            </mat-option>
                                        </mat-autocomplete>
                                      </mat-form-field>
                                   </div>
                                   <div [formGroupName]="field.displayName"  class="addcandidate-toppadding conf-field" *ngSwitchCase="'Currency'">
                                       <div class="currencyDisplayName">
                                            {{field.displayName}}
                                       </div>
                                       <div class="addcandidate-toppadding conf-field"  fxLayoutAlign="space-between">
                                        <div fxFlex="50%" class="currency-Amount">
                                            <mat-form-field>
                                                <mat-label>Currency</mat-label>
                                                <input type="text"
                                                aria-label="currency" 
                                                [readonly]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                                matInput #autoCompleteCurrencyCode
                                                [matAutocomplete]="autoCurrencyCode" 
                                                formControlName="currencyCode">
                                                <mat-autocomplete #autoCurrencyCode ="matAutocomplete" [displayWith]="displayCurrencyCodeFn" >
                                                    <mat-option  [value]="curr" *ngFor="let curr of _getFilterObservableWrtDisplayName(field.displayName) | async">
                                                        {{curr}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                        <div fxflex=40% class="currency currency-Amount">
                                            <mat-form-field>
                                                <mat-label>Amount</mat-label>
                                                <input type="number" class="level"  number-only
                                                [readonly]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                                formControlName="currencyAmount"
                                                matInput>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                </form>
                             </div>
                          </div>
                       </mat-panel-description>
                    </mat-expansion-panel>
                 </mat-accordion>
                <!-- Billing and Reporting Data fields End -->
                <!--Optional Details-->
                <mat-accordion>
                    <mat-expansion-panel *ngIf="!isTraditional" formGroupName="optionalDetails">
                        <mat-expansion-panel-header class='panel-heading'>
                            Optional Details
                        </mat-expansion-panel-header>
                        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                            fxLayoutGap.xs="0px" class="addcandidate-toppadding">
                            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>File Priority</mat-label>
                                    <mat-select formControlName="priority"
                                    [disabled]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move') ">
                                        <mat-option *ngFor="let file of filePriorityValues" [value]="file">{{file}}</mat-option>
                                    </mat-select>
                                    <mat-error> {{ getErrorMessage('optionalDetailsTraditionals.priority', 'File Priority') }} </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Employee Type</mat-label>
                                    <mat-select 
                                    [disabled]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                        formControlName="employeeType">
                                        <mat-option *ngFor="let type of employeeTypeValue" [value]="type">{{type}}</mat-option>
                                    </mat-select>
                                    <mat-error> {{ getErrorMessage('optionalDetailsTraditionals.employeeType', 'Employee Type') }}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px"
                        class="addcandidate-toppadding">
                        <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label>Assignment Start Date</mat-label>
                                <input matInput [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator'&& userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" 
                                    [matDatepicker]="assignmentStartDate" formControlName="assignmentStartDate">
                                <mat-datepicker-toggle matSuffix [for]="assignmentStartDate" [disabled]="disableAllFields || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"></mat-datepicker-toggle>
                                <mat-datepicker #assignmentStartDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label>Assignment End Date</mat-label>
                                <input matInput [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator'&& userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" 
                                    [matDatepicker]="assignmentEndDate" formControlName="assignmentEndDate">
                                <mat-datepicker-toggle matSuffix [for]="assignmentEndDate" [disabled]="disableAllFields || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"></mat-datepicker-toggle>
                                <mat-datepicker #assignmentEndDate></mat-datepicker>
                                <mat-error class="error-msg">
                                    Assignment end date should be greater than start date.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                            fxLayoutGap.xs="0px" class="addcandidate-toppadding">

                            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Employee #</mat-label>
                                    <input matInput [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" characnumspl-only formControlName="employeeID" maxlength="99">
                                    <mat-error> {{ getErrorMessage('optionalDetails.employeeID', 'Employee #') }} </mat-error>

                                </mat-form-field>
                            </div>

                            <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Job Start Date </mat-label>
                                    <input matInput  [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator'&& userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" [matDatepicker]="jobStartDate" formControlName="jobStartDate">
                                    <mat-datepicker-toggle matSuffix [for]="jobStartDate" [disabled]="disableAllFields || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"></mat-datepicker-toggle>
                                    <mat-datepicker #jobStartDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel *ngIf="isTraditional" formGroupName="optionalDetailsTraditionals">
                        <mat-expansion-panel-header class='panel-heading'>
                            Optional Details
                        </mat-expansion-panel-header>
                        <div *ngIf="isTraditional && userRole === 'file-setup-specialist'" fxLayout="row" fxLayoutAlign="space-between"
                            fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px" class="addcandidate-toppadding">
                            <div  fxFlex="49%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <input class="file-checkbox" type="checkbox" formControlName="isConfidentialFile"
                                    [attr.disabled]="disableAllFields || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move') ? true : null"
                                    aria-checked="false" tabindex="0" (keyDown.enter)="onCheckBoxKeyDown()">
                                    <span class="text-file" [ngClass]="{'text-disable-style': editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated'}">Confidential File </span>
                                <mat-error> {{ getErrorMessage('optionalDetailsTraditionals.isConfidentialFile', 'Confidential File') }}
                                </mat-error>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                            fxLayoutGap.xs="0px" class="addcandidate-toppadding">
                            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>File Priority</mat-label>
                                    <mat-select formControlName="priority"
                                    [disabled]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move') ">
                                        <mat-option *ngFor="let file of filePriorityValues" [value]="file">{{file}}</mat-option>
                                    </mat-select>
                                    <mat-error> {{ getErrorMessage('optionalDetailsTraditionals.priority', 'File Priority') }} </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Employee Type</mat-label>
                                    <mat-select 
                                    [disabled]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                        formControlName="employeeType">
                                        <mat-option *ngFor="let type of employeeTypeValue" [value]="type">{{type}}</mat-option>
                                    </mat-select>
                                    <mat-error> {{ getErrorMessage('optionalDetailsTraditionals.employeeType', 'Employee Type') }}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="displayDefaultCountry()" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px"
                            class="addcandidate-toppadding">
                            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Default Home Country</mat-label>
                                    <input type="text" aria-label="Country" matInput 
                                        #autoCompleteCountryInput formControlName="defaultHomeCountry"
                                        [matAutocomplete]="homeCountry" [readonly]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                    <mat-autocomplete  #homeCountry="matAutocomplete" [panelWidth] = "293">
                                        <mat-option [value]="option.countryName" *ngFor="let option of filteredDefaultHomeCountry | async">
                                            {{ option.countryName }}
                                        </mat-option>
                                      </mat-autocomplete>
                                    <mat-error> {{ getErrorMessage('optionalDetailsTraditionals.defaultHomeCountry', 'Default Home Country') }}</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Default Host Country</mat-label>
                                    <input type="text" aria-label="Country" matInput 
                                        #autoCompleteCountryInput
                                        formControlName="defaultHostCountry"
                                        [matAutocomplete]="hostCountry" [readonly]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                    <mat-autocomplete  #hostCountry="matAutocomplete" [panelWidth] = "293">
                                        <mat-option [value]="option.countryName" *ngFor="let option of filteredDefaultHostCountry | async">
                                            {{ option.countryName }}
                                        </mat-option>
                                      </mat-autocomplete>
                                    <mat-error> {{ getErrorMessage('optionalDetailsTraditionals.defaultHostCountry', 'Default Host Country') }}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px"
                            class="addcandidate-toppadding">
                            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Assignment Start Date</mat-label>
                                    <input matInput  [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator'&& userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" 
                                        [matDatepicker]="assignmentStartDate" formControlName="assignmentStartDate">
                                    <mat-datepicker-toggle matSuffix [for]="assignmentStartDate" [disabled]="disableAllFields || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"></mat-datepicker-toggle>
                                    <mat-datepicker #assignmentStartDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Assignment End Date</mat-label>
                                    <input matInput  [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator'&& userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" 
                                        [matDatepicker]="assignmentEndDate" formControlName="assignmentEndDate">
                                    <mat-datepicker-toggle matSuffix [for]="assignmentEndDate" [disabled]="disableAllFields || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"></mat-datepicker-toggle>
                                    <mat-datepicker #assignmentEndDate></mat-datepicker>
                                    <mat-error>
                                        Assignment end date should be greater than start date.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px" 
                            class="addcandidate-toppadding">
                            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Employee #</mat-label>
                                    <input matInput [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" characnumspl-only formControlName="employeeID" maxlength="99">
                                    <mat-error> {{ getErrorMessage('optionalDetailsTraditionals.defaultHostCountry', 'Default Host Country') }}</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Job Start Date </mat-label>
                                    <input matInput  [readonly] = "disableAllFields|| (userRole !== 'client-contact-initiator'&& userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" 
                                        [matDatepicker]="jobStartDate" formControlName="jobStartDate">
                                    <mat-datepicker-toggle matSuffix [for]="jobStartDate" [disabled]="disableAllFields || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"></mat-datepicker-toggle>
                                    <mat-datepicker #jobStartDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>

                <!--family members details-->
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header class='panel-heading'>
                            Family Members (optional) &nbsp; <button class="myfab"
                                mat-mini-fab>{{this.addCandidateForm.controls.accompaningMember.value.length - familyDelete}}</button>
                        </mat-expansion-panel-header>
                        <div formArrayName="accompaningMember">
                            <div
                                *ngFor="let familyDetail of addCandidateForm.get('accompaningMember')['controls']; last as islast; let index = index">
                                <div [formGroupName]='index'>
                                    <mat-expansion-panel
                                        *ngIf="this.addCandidateForm.controls.accompaningMember.value.length > 0 && !this.addCandidateForm.controls.accompaningMember.controls[index].controls['isDelete'].value"
                                        [expanded]="true">
                
                                        <mat-expansion-panel-header class="panel-heading panel-heading-family">
                                            <div>
                                                <p class="relation-panel"> {{addCandidateForm ?
                                                    addCandidateForm.controls.accompaningMember.controls[index].controls['relationship'].value
                                                    : null}}</p>
                                                <p class="title-panel">
                                                    {{addCandidateForm ?
                                                    addCandidateForm.controls.accompaningMember.controls[index].controls['salutation'].value
                                                    : null}} {{addCandidateForm ?
                                                    addCandidateForm.controls.accompaningMember.controls[index].controls['firstName'].value
                                                    : null}} {{addCandidateForm ?
                                                    addCandidateForm.controls.accompaningMember.controls[index].controls['lastName'].value
                                                    : null}}
                                                </p>
                                            </div>
                                        </mat-expansion-panel-header>
                
                                        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                                            fxLayoutGap.xs="0px" class="addcandidate-toppadding">
                                            <div fxFlex="40%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                                <mat-form-field>
                                                    <mat-label>Relationship</mat-label>
                                                    <mat-select formControlName="relationship"
                                                        [disabled]="(userRole !== 'client-contact-initiator'&& userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                                        <mat-option *ngFor="let relValue of relationshipValues" [value]="relValue">
                                                            {{relValue}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="40%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                                <!-- Current age when family member when relationhip type is child -->
                                                <mat-form-field fxFlex="40%" fxFlex.xs="38%" fxFlex.sm="38%" hideRequiredMarker
                                                    *ngIf=" addCandidateForm.controls.accompaningMember.controls[index].controls['relationship'].value == 'Child'">
                                                    <mat-label>Current Age</mat-label>
                                                    <mat-select formControlName="currentAge" autocomplete="off"
                                                        [disabled]="(userRole !== 'client-contact-initiator'&& userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                                        <mat-option *ngFor="let childAge of age" [value]="childAge">{{childAge}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                                            fxLayoutGap.xs="0px" class="addcandidate-toppadding">
                                            <div fxFlex="30%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                                <mat-form-field>
                                                    <mat-label>Salutation</mat-label>
                                                    <mat-select formControlName="salutation"
                                                        [disabled]="(userRole !== 'client-contact-initiator'&& userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                                        <mat-option *ngFor="let title of titleValues" [value]="title">{{title}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="34%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                                <mat-form-field>
                                                    <mat-label>First Name</mat-label>
                                                    <input type="text" aria-label="Client Contact Initiator" matInput
                                                        formControlName="firstName" multi-type-char
                                                        [readonly]="(userRole !== 'client-contact-initiator'&& userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="34%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                                <mat-form-field>
                                                    <mat-label>Last Name</mat-label>
                                                    <input type="text" aria-label="Client Contact Initiator" matInput
                                                        formControlName="lastName" multi-type-char
                                                        [readonly]="(userRole !== 'client-contact-initiator'&& userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div fxLayout="row" style="margin: 20px 0 30px 0;" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px"
                                            class="addcandidate-toppadding">
                                            <div fxFlex="30%">
                                                <mat-checkbox formControlName="legalDependent" class="option-radio-button">Legal Dependent</mat-checkbox>
                                            </div>
                                        
                                            <div fxFlex="30%">
                                                <mat-checkbox formControlName="occupyingProperty" class="option-radio-button">Occupying Property</mat-checkbox>
                                            </div>
                                        
                                            <div fxFlex="40%">
                                                <mat-checkbox formControlName="accompanying" class="option-radio-button">Relocating with Customer</mat-checkbox>
                                            </div>
                                        </div>

                                        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                                            fxLayoutGap.xs="0px" class="addcandidate-toppadding">
                                            <div fxFlex="47%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                                <mat-form-field>
                                                    <mat-label>Email Address</mat-label>
                                                    <input type="text" aria-label="Client Contact Initiator" matInput
                                                        formControlName="emailAddress"
                                                        [readonly]="(userRole !== 'client-contact-initiator'&& userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="15%" fxFlex.xs="30%" (click)="updatePhonecode(index)">
                                                <mat-form-field>
                                                    <mat-label>Phone Code </mat-label>
                                                    <input type="text" aria-label="Phone Code" matInput #autoCompleteInput
                                                        formControlName="phoneDialCode" [matAutocomplete]="autos"
                                                        [readonly]="(userRole !== 'client-contact-initiator'&& userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                                    <mat-autocomplete #autos="matAutocomplete" [displayWith]="displayFn"
                                                        [panelWidth]="293">
                                                        <mat-option [value]="option.countryDialingCode"
                                                            *ngFor="let option of filteredOptions | async">
                                                            {{ option.countryName }} (+ {{ option.countryDialingCode }})
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="35%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                                <mat-form-field>
                                                    <mat-label>Mobile Phone Number</mat-label>
                                                    <input type="text" number aria-label="Client Contact Initiator" matInput
                                                        formControlName="phoneNumber"
                                                        [readonly]="(userRole !== 'client-contact-initiator'&& userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                                            fxLayoutGap.xs="0px" class="addcandidate-toppadding">
                                            <div fxFlex="40%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                                <button mat-icon-button role="button" id="addNewEmail"
                                                    (click)="deleteFamilyRow(addCandidateForm, index, $event)"
                                                    [disabled]="(userRole !== 'client-contact-initiator'&& userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                                    <mat-icon class="delete-icomn-style" style="margin-top: -1px; cursor: pointer;">
                                                        delete_outline</mat-icon>
                                                    <span> Delete</span>
                                                </button>
                                            </div>
                                        </div>
                
                                    </mat-expansion-panel>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px"
                            class="addcandidate-toppadding">
                            <div fxFlex="40%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <button mat-icon-button role="button" id="addNewEmail"
                                    (click)="addNewFamilyRow(addCandidateForm)"
                                    [disabled]="this.addCandidateForm.controls.accompaningMember.invalid || (userRole !== 'client-contact-initiator'&& userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                    class="mat-button outlined-button add-button-member">
                                    <mat-icon class="add-person" aria-hidden="false">person_add</mat-icon> Add Member
                                </button>
                            </div>
                        </div>
                
                    </mat-expansion-panel>
                
                </mat-accordion>
                <!-- Special Instructions Section-->
                <special-instructions *ngIf="isSpecialInstructionsEnabled && userRole === 'client-contact-initiator'" [moveStatus]="editCandidateProfile.moveStatus" [savedSpecialInstructions]="editCandidateProfile.specialInstructions"></special-instructions>
                <!--Additional Doc Upload Section-->
                <mat-accordion *ngIf="isUploadDocEnabled && editCandidateProfile.moveStatus !=='Invitation Sent'">
                    <mat-expansion-panel>
                    <mat-expansion-panel-header class='panel-heading'>
                        Additional Documents Upload
                    </mat-expansion-panel-header>
                    <app-additional-document-upload [orderRequestId]="orderRequestId" (isFileSelected)="checkIsFileSelected($event)"></app-additional-document-upload>
                    </mat-expansion-panel>
                </mat-accordion>
                
                <!--Document Library Section-->
                <mat-accordion class="candidate-review-panel move-departure-panel doc-lib" multi="true" *ngIf="isUploadDocEnabled && manageAuthorizationCapability === 'write' && (editCandidateProfile.moveStatus === 'Invitation Sent' || editCandidateProfile.moveStatus === 'Invitation Not Sent' || editCandidateProfile.moveStatus === 'Authorization Initiated')">
                    <mat-expansion-panel class="move-detail-panel" [expanded]="true">
                        <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight">
                            <div fxFlex="34%" class="panel-heading">Document Library</div>
                        </mat-expansion-panel-header>
                        <mat-panel-description style="overflow-x:auto" class="remove-margin">
                            <document-library style="width: 100%;" [orderRequestId]="data.candidate.orderRequestId"></document-library>
                        </mat-panel-description>
                    </mat-expansion-panel>
                </mat-accordion> 
                
            </mat-dialog-content>
        </div>
        <mat-dialog-actions class="statusBtn add-dialogbtn" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px" fxLayoutGap.xs="0px" fxLayoutAlign.xs="space-between center">
            <button fxFlex="25%" [disabled]="formDisable" type="button" class="mat-button text-button" (click)="onDismiss($event)" color="primary" id="cancel">{{disableAllFields ? 'CLOSE' : 'CANCEL'}}</button>
            <button fxFlex="35%" *ngIf="isSaveDraft && !disableAllFields" class="mat-button outlined-button" type="button" (click)="isFileSelected ? openSaveAuthWarningDialog('saveData') : saveData('saveDraft')" id="addEditSave"
             [disabled]="disableAllFields  || (additionalDetailsFormGroup && (!isAdditionalValid() || !isCurrencyValid())) || !(addCandidateForm?.get('nameDetails')?.controls.firstName.valid && addCandidateForm?.get('nameDetails')?.controls.lastName.valid && addCandidateForm?.get('authorizationDetails')?.controls.cciDetails.valid && addCandidateForm?.get('accompaningMember')?.valid && addCandidateForm?.get('uploadDocuments')?.valid && (addCandidateForm?.get('optionalDetails')?.valid && !isTraditional ) || (isTraditional && addCandidateForm?.get('optionalDetailsTraditionals')?.valid )) || userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist'|| (disableSaveDraftForSSO && !isNewHire && clientService.bbRole?.includes('sso'))">Save Draft</button>
             <button fxFlex="35%" *ngIf="isMoveNotApprove && isApproved && !disableAllFields && sendInviteBtnText !== 'Authorize Move' && sendInviteBtnText !== 'Invite Transferee' " class="mat-button outlined-button" type="button" (click)="saveData('moveNotApprove')" id="addEditSave"
             [disabled]="(additionalDetailsFormGroup && (!isAdditionalValid() || !isCurrencyValid())) || !(addCandidateForm?.get('nameDetails')?.controls.firstName.valid && addCandidateForm?.get('nameDetails')?.controls.lastName.valid && addCandidateForm?.get('authorizationDetails')?.controls.cciDetails.valid) || userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist' || editCandidateProfile?.moveStatus == 'Requested'  || editCandidateProfile?.moveStatus !== 'CP In Progress' ">Move Not Approved</button>
             <button fxFlex="36%" *ngIf="!isSaveDraft && !isMoveNotApprove && isApproved" class="mat-button outlined-button" type="button" (click)=" isFileSelected ? openSaveAuthWarningDialog('saveChanges') : saveData('saveChanges')" id="addEditSave"
            [disabled]="disableAllFields || (additionalDetailsFormGroup && !(editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated') && !isAdditionalValid())
                        || !addCandidateForm.valid || !((userRole === 'consultant' || userRole === 'client-contact-initiator' || userRole === 'file-setup-specialist' ) && (editCandidateProfile.moveStatus === 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated'))">Save Changes</button>
            <button fxFlex="35%" *ngIf="isApproved && !disableAllFields && editCandidateProfile.moveStatus !== 'CP In Progress' && sendInviteBtnText !== 'Re-send Invite'" [disabled]="isSendInviteBtnDisabled()" class="mat-button contained-button send-invite" (click)="this.sendInviteBtnText == 'Approve Move'? openDialoge() : isFileSelected ? openSaveAuthWarningDialog('sendInvite') : sendInvite()" id="addEditSendInvite">{{ sendInviteBtnText }}</button>
            <button fxFlex="35%" *ngIf="isApproved && !disableAllFields && editCandidateProfile.moveStatus !== 'CP In Progress' && sendInviteBtnText === 'Re-send Invite' && (resendInviteCapability === 'read' || resendInviteCapability === 'write')" [disabled]="disableAllFields || ((additionalDetailsFormGroup && !additionalDetailsFormGroup?.valid && !isMMUser && sendInviteBtnText !== 'Re-send Invite' && editCandidateProfile?.moveStatus !== 'Not Requested')) || !addCandidateForm.valid || (!(userRole === 'consultant' || userRole === 'client-contact-initiator' || userRole === 'file-setup-specialist' || userRole === 'team-client-service-manager') && editCandidateProfile.moveStatus === 'Invitation Sent') || editCandidateProfile?.moveStatus == 'Authorization Initiated'||  editCandidateProfile?.moveStatus == 'Withdrawn' || editCandidateProfile?.moveStatus == 'Requested' || (editCandidateProfile?.moveStatus && this.isCostProjection  && editCandidateProfile?.moveStatus !== 'CP In Progress') && editCandidateProfile?.moveStatus !== 'Not Requested' || resendInviteCapability === 'read'" class="mat-button contained-button send-invite" (click)="this.sendInviteBtnText == 'Approve Move'? openDialoge() : sendInvite()" id="addEditSendInvite">{{ sendInviteBtnText }}</button>
            <button fxFlex="35%" *ngIf="isApproved && !disableAllFields && editCandidateProfile.moveStatus === 'CP In Progress' && (resendInviteCapability === 'read' || resendInviteCapability === 'write')"  [disabled]="((sendInviteBtnText=='Invite Transferee' || sendInviteBtnText=='Authorize Move') && (disableAllFields || ((additionalDetailsFormGroup && !additionalDetailsFormGroup?.valid && !isMMUser && sendInviteBtnText !== 'Re-send Invite' && editCandidateProfile?.moveStatus !== 'Not Requested')) || !addCandidateForm.valid || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist' && !(userRole === 'consultant' && editCandidateProfile.moveStatus === 'Invitation Sent')) || editCandidateProfile?.moveStatus == 'Authorization Initiated'||  editCandidateProfile?.moveStatus == 'Withdrawn' || editCandidateProfile?.moveStatus == 'Requested' || (editCandidateProfile?.moveStatus && this.isCostProjection  && editCandidateProfile?.moveStatus !== 'CP In Progress') && editCandidateProfile?.moveStatus !== 'Not Requested')) || resendInviteCapability === 'read'" class="mat-button contained-button send-invite" (click)="this.sendInviteBtnText == 'Approve Move'? openDialoge() : sendInvite()" id="addEditSendInvite">{{ sendInviteBtnText }}</button>
        </mat-dialog-actions>
    </form>
</div>
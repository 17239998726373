import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AppComponent } from './app.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list'
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule, MatFormFieldControl  } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserContextComponent } from './public/components/user-context/user-context.component';
import { NavListComponent } from './public/components/nav-list/nav-list.component';
import { DashboardModule } from './public/dashboard/dashboard.module';
import { ProviderModule } from './public/provider/provider.module';
import { ExternRouteComponent } from './public/components/extern-route/extern-route.component';
import { environment } from '../environments/environment';
import { AvatarModule } from './core/components/avatar/avatar.module';
import { CookieService } from 'ngx-cookie-service';
import { BreadCrumbService } from './core/services/breadcrumb.service';
import { HighlightSearchPipe } from '../app/public/dashboard/pipes/highlight-search/highlight-search.pipe';
import { TruncatePipe } from '../app/public/dashboard/pipes/truncate/truncate.pipe';
import { ToastrModule } from 'ngx-toastr';
import { TermsPrivacyModule } from './public/terms-privacy/terms-privacy.module';
import { GlobalSearchComponent } from './public/components/global-search/global-search.component';
import { PageNotFoundComponent } from './public/components/page-not-found/page-not-found.component';
import { LoaderHttpInterceptor } from './core/services/http-interceptor.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ClientsComponent } from './public/components/clients/clients.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ClientContactsComponent } from './public/components/client-contacts/client-contacts.component';
import { CdkColumnDef } from '@angular/cdk/table';
import { LogoutModalComponent } from './public/components/logout-modal/logout-modal.component';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ProgramModule } from './public/administration/modules/program/program.module';
import { ToastIdleTimeoutComponent, FormatTimePipe } from './core/components/toast-idle-timeout/toast-idle-timeout.component';

import { ClientContactDetailsComponent } from './public/components/client-contact-details/client-contact-details.component';
import { CustomClientModalComponent } from './public/components/custom-client-modal/custom-client-modal.component';
import { CustomdataDeleteConfirmComponent } from './public/components/customdata-delete-confirm/customdata-delete-confirm.component';
import { SharedTraditionalOrderModule } from './shared/sharedTraditionalOrder.module';
import { ContactUsComponent } from './public/components/contact-us/contact-us.component';
import { CronofyAvailablilityPickerModule } from './public/administration/modules/cronofy-availablility-picker/cronofy-availablility-picker/cronofy-availablility-picker.module';
import { ClientScreenComponent } from './public/components/client-screen/client-screen.component';
import { ClientScreenSaveChangesComponent } from './public/components/client-screen-save-changes/client-screen-save-changes.component';
import { AppInitService } from './core/services/app-init.service';
import { AdministrationModule } from './public/administration/administration.module';
import { TransfereeModule } from './public/administration/modules/transferee/transferee.module';
import { NumberWithCommasPipe } from './shared/pipes/numberwithcommas.pipe';

export function initializeApp(appInitService: AppInitService) {
    return (): Promise<any> => {
      return appInitService.Init();
    };
}
@NgModule({
    declarations: [
        AppComponent,
        UserContextComponent,
        NavListComponent,
        ExternRouteComponent,
        GlobalSearchComponent,
        PageNotFoundComponent,
        ClientsComponent,
        CustomClientModalComponent,
        ClientContactsComponent,
        LogoutModalComponent,
        ClientScreenSaveChangesComponent,
        ToastIdleTimeoutComponent,
        FormatTimePipe,
        ClientContactDetailsComponent,
        ClientScreenComponent,
        ContactUsComponent,
    ],
    imports: [
        BrowserModule,
        environment.animation ? BrowserAnimationsModule : NoopAnimationsModule,
        HttpClientModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatCardModule,
        MatGridListModule,
        FlexLayoutModule,
        MatFormFieldModule,
        MatInputModule,
        MatMenuModule,
        MatTableModule,
        MatButtonModule,
        MatSidenavModule,
        MatExpansionModule,
        MatIconModule,
        MatRadioModule,
        MatCheckboxModule,
        DashboardModule,
        ProviderModule,
        TermsPrivacyModule,
        AdministrationModule,
        ProgramModule,
        CronofyAvailablilityPickerModule,
        MatSnackBarModule,
        TransfereeModule,
        NgxSpinnerModule,
        MatPaginatorModule,
        MatSortModule,
        AvatarModule,
        MatTooltipModule,
        MatListModule,
        MatDialogModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatNativeDateModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-bottom-center',
            maxOpened: 3,
            preventDuplicates: true,
            // autoDismiss: true,
            // newestOnTop: true
        }),
        AppRoutingModule,
        SharedTraditionalOrderModule
    ],
    providers: [
        AppInitService,
        CdkColumnDef,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderHttpInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [
                AppInitService
            ],
            multi: true
        },
        CookieService,
        BreadCrumbService,
        HighlightSearchPipe,
        TruncatePipe,
        NumberWithCommasPipe
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }

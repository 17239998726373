import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DynamicBenefitService } from '../../../shared/services/dynamic-benefit.service';
import { AbstractControl, FormArray, FormControl, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ChildrenOutletContexts } from '@angular/router';

@Component({
  selector: 'app-dynamic-benefit-upsert-form',
  templateUrl: './dynamic-benefit-upsert-form.component.html',
  styleUrls: ['./dynamic-benefit-upsert-form.component.scss']
})
export class DynamicBenefitUpsertFormComponent implements OnInit {

  @Input() productSpecification: any;
  @Output() validForm: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  attributesConfig: any;
  dynamicDataModel: any = {};
  formData: FormGroup
  upsertDynamicForm: UntypedFormGroup;
  dynamicForm:UntypedFormGroup;
  componentList = []
  componentDescription = []
  @Output() formRequestPayload: EventEmitter<Object> = new EventEmitter<Object>();
  @Input() diasbleDynamicForm:boolean = false;
  @Input() isDraftProgram;
    /* to store dependent attribute reference for multiplity */
    componentReference = '';
/*optional fields for API request payload */
  readonly optionalMultiplicityAttr = ["departure","months","percentOfRepayment"]
  constructor(private dynamicBenefitService: DynamicBenefitService,
    private readonly formBuilder: UntypedFormBuilder,) {}
    originalDynamicModel:any = {}
    ngOnInit(): void {
        this.getConfigurations();
        this.upsertDynamicForm = this.formBuilder.nonNullable.group({
            primaryForm: new UntypedFormArray([])
        })
    }

  getConfigurations() {
    this.dynamicBenefitService.getAttributesConfig(this.productSpecification.reference).subscribe((response) => {
        if(response && response.length) {
            this.attributesConfig = response;
            this.dynamicDataModel =  this.generateDataModelRecurs(this.productSpecification, this.dynamicDataModel) 
            this.originalDynamicModel =  this.dynamicDataModel ? JSON.parse(JSON.stringify(this.dynamicDataModel)) : {};
            this.createForm(); 
        }
    })
  }
createForm(){
    (<UntypedFormArray>this.upsertDynamicForm.get('primaryForm')).push(this.generateDynamicForm(this.dynamicDataModel));
}


    checkFormValidity(): boolean{
        let valid = true;
        let primaryForm = this.upsertDynamicForm.get('primaryForm') as FormArray
        if (primaryForm.controls.length) {
            let primaryFormGroup = primaryForm.controls[0] as FormGroup;
            for(let i = 0; i < this.componentList.length; i++) {
                let comp = this.componentList[i];
                const nestedFormGroups = Object.keys(primaryFormGroup[comp].value)
                if (nestedFormGroups && nestedFormGroups.length) {
                    for(let j = 0; j < nestedFormGroups.length; j++) {
                        let compGroup = nestedFormGroups[j];
                        if (compGroup != 'multiplicity') {
                            if(primaryFormGroup[comp].controls[compGroup].value === null  || primaryFormGroup[comp].controls[compGroup].value === '' || !primaryFormGroup[comp].controls[compGroup].valid) {
                                valid = false;
                                return valid;
                            }
                        } else {
                            const multiForms = Object.keys(primaryFormGroup[comp]?.controls[compGroup]?.controls[0][0]?.value)
                            const multiplicityFormGroupList = this.dynamicDataModel[comp]?.attributes?.find(item => item.isMultiplicity)?.children;
                            valid = this.checkMultiplicityFormValidity(multiplicityFormGroupList, multiForms, primaryFormGroup[comp].controls[compGroup].controls[0]);
                            return valid;
                        }
                    }
                }
            }
        }
        return valid;
    }

    checkMultiplicityFormValidity(multiplicityRows, multiplicityKeys, multiplicityFormGroup): boolean {
        let valid = true;
        if(multiplicityRows && multiplicityRows.length){
            for(let i = 0; i < multiplicityRows.length; i ++) {
                let index = i;
                let multiRows = multiplicityRows[i];
                for(let j = 0; j < multiplicityKeys.length; j++) {
                    let formEntity = multiplicityKeys[j];
                    const isNotRequiredAttribute = this.optionalMultiplicityAttr.some(ele => ele === formEntity);
                    if (!isNotRequiredAttribute) {
                         if (multiplicityFormGroup[index].get(formEntity).value === null || multiplicityFormGroup[index].get(formEntity).value === '' || isNaN(multiplicityFormGroup[index].get(formEntity).value)) {
                            valid = false;
                            return valid;
                         }
                    }
                }
            }
        }
        return valid;
    }

    generateReqPayloadAttributes(event) {
        const payloadAttributes = {
            attributes: [],
            children:[]
        }
        let primaryForm = this.upsertDynamicForm.get('primaryForm') as FormArray
        if (primaryForm.controls.length) {
            this.validForm.emit(this.checkFormValidity())
            let primaryFormGroup = primaryForm.controls[0] as FormGroup
            this.componentList.forEach(comp => {
                if (!primaryFormGroup[comp].value.hasOwnProperty('multiplicity')) {
                    const formList = Object.keys(primaryFormGroup[comp].value);
                    formList.forEach(formEntity => {
                        let formValue 
                       const formControlField = this.getReference(comp,formEntity,'acceptCommas')
                        if (formControlField) {
                            const inputformValue = primaryFormGroup[comp].value[formEntity]
                                formValue = typeof(inputformValue) === 'string' && primaryFormGroup[comp].value[formEntity] ? parseFloat(inputformValue.replace(/,/g, '')) : primaryFormGroup[comp].value[formEntity];  
                        } else {
                            formValue = primaryFormGroup[comp].value[formEntity]
                    }
                        if (primaryFormGroup[comp].value[formEntity]) {
                            payloadAttributes.attributes.push({
                                reference: this.getReference(comp, formEntity, 'reference'),
                                value: formValue
                            })
                        } else {
                            if(typeof(primaryFormGroup[comp].value[formEntity]) ==='boolean'){
                                payloadAttributes.attributes.push({
                                    reference: this.getReference(comp, formEntity, 'reference'),
                                    value: formValue
                                 }) 
                                 if(this.isDraftProgram){
                                    const dependentAttr = this.generateDenpedentAttrRef(comp,formEntity,'parent');
                                    const dependentAttrChild = this.generateDenpedentAttrRef(comp,formEntity,'child');
                                    if(dependentAttr){
                                        payloadAttributes.attributes.push(dependentAttr)  
                                    }
                                    if(dependentAttrChild){
                                        payloadAttributes.children = [];
                                        payloadAttributes.children.push(dependentAttrChild)  
                                    }
                                 }
                            } else {
                                if(this.isDraftProgram){
                                    payloadAttributes.attributes.push({
                                        reference: this.getReference(comp, formEntity, 'reference'),
                                        value: null
                                    })   
                                } 
                            }
                         }
                    })
                }
                else {
                    const nestedFormGroups = Object.keys(primaryFormGroup[comp].value)
                    if (nestedFormGroups && nestedFormGroups.length) {
                        nestedFormGroups.forEach(compGroup => {
                            if (compGroup != 'multiplicity') {
                                if (primaryFormGroup[comp].controls[compGroup].value) {
                                    payloadAttributes.attributes.push({
                                        reference: this.getReference(comp, compGroup, 'reference'),
                                        value: primaryFormGroup[comp].controls[compGroup].value
                                    })
                                }
                            } else {
                                const multiForms = Object.keys(primaryFormGroup[comp].controls[compGroup].controls[0][0].value)
                                let multiAttr = []
                                let rowValueAttributes = {
                                    attributes: []
                                }
                                let rowAttributes = []
                                const multiplicityFormGroupList = this.dynamicDataModel[comp]?.attributes?.find(item => item.isMultiplicity)?.children;
                                if(multiplicityFormGroupList && multiplicityFormGroupList.length){
                                multiplicityFormGroupList.forEach((multiForm, index) => {
                                    multiForms.forEach(formEntity => {
                                        const isRequiredAttribute = this.optionalMultiplicityAttr.some(ele => ele === formEntity);
                                        if (!isRequiredAttribute) {
                                             if (primaryFormGroup[comp].controls[compGroup].controls[0][index].value[formEntity]) {
                                                rowAttributes.push({
                                                    reference: this.getReference(comp, formEntity, 'multiplicityChild', index),
                                                    value: primaryFormGroup[comp].controls[compGroup].controls[0][index].value[formEntity]
                                                })
                                             }
                                        }  
                                    })
                                    if(rowAttributes.length === 2){
                                        rowValueAttributes.attributes.push(...rowAttributes);
                                        multiAttr.push({...rowValueAttributes}) 
                                    }
                                    rowValueAttributes.attributes = []
                                    rowAttributes = [];
                                 })
                                 const multiplicityChildValue = multiAttr && multiAttr.length && multiAttr[0]?.attributes?.length ? multiAttr : null
                                 if(multiplicityChildValue || this.isDraftProgram){
                                    payloadAttributes.children = [];
                                    payloadAttributes.children.push({
                                        reference: this.getReference(comp, 'multi', 'multiplicity'),
                                        value: multiplicityChildValue   
                                    })   
                                }
                            }
                            }
                        })
                    }

                }
                if (this.isDraftProgram && payloadAttributes && !payloadAttributes.children.length && this.componentReference) {
                    payloadAttributes.children.push({
                        reference: this.componentReference,
                        value: null
                    })
                }
                this.formRequestPayload.emit(payloadAttributes);
            })
        }
    }

    /* get reference of each attribute */
    getReference(component, nestedAttrKey, property, multiIndex?) {
        if (component && nestedAttrKey && this.originalDynamicModel) {
            if (property === 'multiplicity') {
                return this.originalDynamicModel[component] && this.originalDynamicModel[component] && this.originalDynamicModel[component].attributes.find(item => item.isMultiplicity)?.reference;
            } else if (property === 'reference') {
                return this.originalDynamicModel[component] && this.originalDynamicModel[component].attributes && this.originalDynamicModel[component].attributes.find(item => item.name === nestedAttrKey)?.reference;
            } else if (property === 'multiplicityChild') {
                const attrIndex = this.originalDynamicModel[component] && this.originalDynamicModel[component].attributes && this.originalDynamicModel[component].attributes.findIndex(item => item.isMultiplicity);
                if (attrIndex != -1) {
                    return this.dynamicDataModel[component] && this.dynamicDataModel[component].attributes && this.dynamicDataModel[component].attributes[attrIndex].children[multiIndex].attributes.find(item => item.name === nestedAttrKey)?.reference;
                }
            } else if (property === 'acceptCommas') {
                return this.originalDynamicModel[component] && this.originalDynamicModel[component].attributes && this.originalDynamicModel[component].attributes.find(item => item.name === nestedAttrKey)?.displaySetting;
            }
        }
    }

    generateDenpedentAttrRef(component, formField, dependencyType) {
        let dependentAttr = {}
        if (dependencyType === 'parent') {
            const parentAttr = this.originalDynamicModel[component].attributes.find(item => item['dependent-attribute'] && item.name === formField);
            const childAttr = this.originalDynamicModel[component].attributes.find(item => item.reference === parentAttr['dependent-attribute']);
            if (childAttr) {
                dependentAttr = {
                    reference: childAttr.reference,
                    value: null
                }
            }
            return dependentAttr
        }
        else if (dependencyType === 'child') {
            const multiplicityAttr = this.originalDynamicModel[component].attributes.find(item => item.isMultiplicity)
            const rowlist = {
                reference: multiplicityAttr.reference,
                value: null
            }
            return rowlist
        }
    }

  copyObject(original: any, dest: any, exclusions: string[], type?: string) {
    if(type && type === 'attributes' || type === 'children') {
        Object.keys(original[type]).forEach(key => {
            if (dest.reference === key) {
                if(Object.keys(original[type][key]).length) {
                    Object.keys(original[type][key]).forEach(configKey => {
                        dest[configKey] = original[type][key][configKey];
                    })
                }
            }
        })
    } else {
        Object.keys(original).forEach(key => {
            if (exclusions && !exclusions.includes(key)) {
                dest[key] = original[key];
            }
        })
    }
    return dest;
  }

  getAttribConfig (attribRef: string, type: string = 'attributes'): any {
    const filteredConfig = this.attributesConfig.filter(config => this.productSpecification.reference === config.reference);
    const attribConfig = filteredConfig.find(config => { if(config[type][attribRef]) { return config} });
    if (attribConfig) {
        return attribConfig;
    } else {
        return;
    }
  }

  checkAttrDisplay(childReference): boolean {
    let displayChildrenSpec = false;

    if (Array.isArray(this.attributesConfig)) {
        const filteredConfig = this.attributesConfig.filter(config => this.productSpecification.reference === config.reference);
        if(filteredConfig.length) {
            filteredConfig.forEach(config => {
                displayChildrenSpec = ( 
                    config?.children.hasOwnProperty(childReference) &&
                    config?.children[childReference].context &&
                    Array.isArray(config?.children[childReference].context) && 
                    config?.children[childReference].context.includes('program'));
            });
        }
    }

    return displayChildrenSpec;
  }

  generateDataModelRecurs(specification: any, model: any, display?: boolean) {
    // always start recursive functions with the exit conditions
    if (!specification?.children?.length) {
        return;
    }
    specification.children.forEach(childSpec => {
    if (display) {
            if (childSpec['_specType'] === 'multiplicity') {   // more direct
                if (childSpec.specificationReference) {
                    let multiplicityAttr = { reference: childSpec.reference, isMultiplicity: true, children: [] }
                    let attribConfig: any = this.getAttribConfig(multiplicityAttr.reference, 'children');
                    if (attribConfig) { multiplicityAttr = this.copyObject(attribConfig, multiplicityAttr, [], 'children') }
                    if(childSpec?.items?.length) {
                        childSpec.items.forEach( attributes => {
                            const dataObj = { attributes: []}
                            attributes?.attributes.forEach(attr => {
                                let attribConfig: any = this.getAttribConfig(attr.reference);
                                if (attribConfig) { attr = this.copyObject(attribConfig, attr, [], 'attributes') }
                                const index = dataObj.attributes.findIndex(element => element && element.reference === attr.reference)
                                if (index == -1) {
                                    dataObj.attributes.push(attr)
                                }
                            });
                            const index = multiplicityAttr.children.findIndex(element => element === dataObj)
                            if (dataObj.attributes.length && index == -1) {
                                multiplicityAttr.children.push(dataObj)
                            }
                        });
                    } else if (childSpec.specificationReference?.attributes?.length) {
                        const dataObj = { attributes: []}
                        childSpec.specificationReference.attributes.forEach(attr => {
                            let attribConfig: any = this.getAttribConfig(attr.reference);
                            if (attribConfig) { attr = this.copyObject(attribConfig, attr, [], 'attributes') }
                            const index = dataObj.attributes.findIndex(element => element && element.reference === attr.reference)
                            if (index == -1) {
                                dataObj.attributes.push(attr)
                            }
                        });
                        const index = multiplicityAttr.children.findIndex(element => element === dataObj)
                        if (dataObj.attributes.length && index == -1) {
                            multiplicityAttr.children.push(dataObj)
                        }
                    }
                    if (!model.hasOwnProperty('attributes')) {
                        model['attributes'] = [];
                    }
                    if(!model.attributes.find(attr => attr.reference === multiplicityAttr.reference))
                       model.attributes.push(multiplicityAttr);
                }
            } else if (model.hasOwnProperty(childSpec.reference)) {
                model[childSpec.reference] = this.copyObject(childSpec, {}, ['children']);
                if (Array.isArray(model[childSpec.reference]?.attributes)) {
                    model[childSpec.reference].attributes.forEach(attrib => {
                        let attribConfig: any = this.getAttribConfig(attrib.reference);
                        if (attribConfig) { attrib = this.copyObject(attribConfig, attrib, [], 'attributes') }
                    });
                }
            } else {
                model[childSpec.reference] = this.copyObject(childSpec, {}, ['children']);
                if (Array.isArray(model[childSpec.reference]?.attributes)) {
                    model[childSpec.reference].attributes.forEach(attrib => {
                        let attribConfig: any = this.getAttribConfig(attrib.reference);
                        if (attribConfig) { attrib = this.copyObject(attribConfig, attrib, [], 'attributes') }
                    });
                }
            }
            this.generateDataModelRecurs(childSpec, model[childSpec.reference], display);
        } else if (this.checkAttrDisplay(childSpec.reference)) {
            this.generateDataModelRecurs(childSpec, model, true);
        } else {
            this.generateDataModelRecurs(childSpec, model);
        }
    });
    return model;
  }

    generateDynamicForm(modelData) {
        this.dynamicForm = this.formBuilder.group({});
        this.componentList = []
        this.componentDescription =[]
        const model = {};
        if(modelData){
            this.componentList = Object.keys(modelData)
        }
        if (!this.componentList.length) {
            return this.dynamicForm
        }
        if (this.componentList.length) {
            this.componentList.forEach((compGroup) => {
                this.dynamicForm[compGroup] = this.formBuilder.group({});
                this.componentDescription.push({
                     [compGroup]: modelData[compGroup].description
                })
                if (modelData[compGroup].attributes && modelData[compGroup].attributes.length) {
                    this.getAvailableAttributes(modelData[compGroup].attributes);
                    modelData[compGroup].attributes.forEach(compFormControls => {
                          const nestedChild = compFormControls.chidren 
                        if (compFormControls && compFormControls.hasOwnProperty('isMultiplicity')) {
                            this.dynamicForm[compGroup].addControl('multiplicity', new UntypedFormArray([
                                this.generateMultiplicityForm(compFormControls.children)
                            ]))
                        } else {
                            if(compFormControls.hasOwnProperty('defaultValue')) {
                                this.dynamicForm[compGroup].addControl(compFormControls.name, this.formBuilder.control({value:compFormControls.defaultValue,disabled: this.diasbleDynamicForm}));
                                
                         } else {
                            let deafultValue = '';
                            if(compFormControls.hasOwnProperty('enumeratedValues') && compFormControls.enumeratedValues.length){
                                deafultValue = compFormControls.enumeratedValues?.find(item => item.isSelected)?.value
                            }
                            if(deafultValue){
                                this.dynamicForm[compGroup].addControl(compFormControls.name, this.formBuilder.control({value:deafultValue,disabled: this.diasbleDynamicForm}))
                            } else {
                                this.dynamicForm[compGroup].addControl(compFormControls.name, this.formBuilder.control({value:'',disabled: this.diasbleDynamicForm}))
                            }
                         }
                            if(compFormControls.validations.includes('REQUIRED')){
                                this.dynamicForm[compGroup].get(compFormControls.name).setValidators(Validators.required);
                             }
                        }
                    })
                }
            })
        }

        return this.dynamicForm;
    } 

    getAvailableAttributes(attributesList) {
        attributesList.forEach(attrFields => {
            if (attrFields.hasOwnProperty('dependency') && attrFields.hasOwnProperty('dependent-attribute') && attrFields.dataType === 'Boolean') {
                const currentFieldIndex = attributesList.findIndex(item => item.reference === attrFields['dependent-attribute']);
                if (!attrFields.dependency && attrFields['dependent-attribute']) {
                    const parentAttr1 = attributesList.find(item => item.reference === attrFields['dependent-attribute']);
                    if (parentAttr1 && !parentAttr1.dependency) {
                        const parentfieldIndex = attributesList.findIndex(item => item.reference === attrFields['dependent-attribute']);
                        if (parentfieldIndex != -1) {
                            attributesList.splice(parentfieldIndex, 1);
                        }
                    }
                    if (currentFieldIndex != -1) {
                        attributesList.splice(currentFieldIndex, 1);
                    }
                } else {
                    if(!attrFields.defaultValue){
                        attributesList.splice(currentFieldIndex, 1);
                      const nestedFiledIndex =  attributesList.findIndex(item => item.isMultiplicity);
                      attributesList.splice(nestedFiledIndex);
                    }
                }
            } else if (attrFields.dataType === 'string' && attrFields._specType === "enumeratedAttribute" && attrFields.hasOwnProperty('on-load-dependent-attribute')) {
                if (!attrFields.dependency && attrFields['on-load-dependent-attribute']) {
                    const parentAttr = attributesList.find(item => item.reference === attrFields['on-load-dependent-attribute']);
                    if (!parentAttr.dependency) {
                        const parentfieldIndex = attributesList.findIndex(item => item.parentAttr === attrFields['on-load-dependent-attribute']);
                        if (parentfieldIndex != -1) {
                            attributesList.splice(parentfieldIndex, 1);
                        }
                    }
                }
            } else if (attrFields.hasOwnProperty('isMultiplicity') && attrFields.hasOwnProperty('on-load-dependent-attribute')) {
                const parentAttr = attributesList.find(item => item.reference === attrFields['on-load-dependent-attribute']);
                /** storing multiplicity attribute reference required for request payload in draft case*/
                this.componentReference = attributesList.find(item => item.isMultiplicity)?.reference;
                const childIndex = attributesList.findIndex(item => item.isMultiplicity)
                if (parentAttr && !parentAttr.dependency) {
                    if (childIndex != -1) {
                        attributesList.splice(childIndex, 1);
                    }
                } else {
                    if(parentAttr && parentAttr.enumeratedValues && parentAttr.enumeratedValues.length){
                        if(!parentAttr.enumeratedValues[0]?.isSelected){
                            attributesList.splice(childIndex, 1);  
                        } else {
                            if (!attrFields.reference.endsWith(parentAttr.enumeratedValues[0]?.value)) {
                                attributesList.splice(childIndex, 1);
                            }
                        } 
                    } 
                }
            }
        })
    }

    generateMultiplicityForm(multiplicityDetails: Array<[]>) {
        let formFields = this.formBuilder.group({});
        if (multiplicityDetails && multiplicityDetails.length > 0) {
            multiplicityDetails.forEach((multiForm, index) => {
                if (multiForm && multiForm['attributes'] && multiForm['attributes'].length) {
                    formFields[index] = this.formBuilder.group({});
                    multiForm['attributes'].forEach(nestedFormFields => {
                        if (nestedFormFields.hasOwnProperty('defaultValue')) {
                            formFields[index].addControl(nestedFormFields['name'], this.formBuilder.nonNullable.control({value:nestedFormFields['defaultValue'],disabled: this.diasbleDynamicForm}));
                        } else {
                            formFields[index].addControl(nestedFormFields['name'], this.formBuilder.nonNullable.control({value:'',disabled: this.diasbleDynamicForm}));
                        }
                        if (nestedFormFields.hasOwnProperty('validations') && nestedFormFields['validations'].includes('REQUIRED')) {
                            formFields[index].get(nestedFormFields['name']).setValidators(Validators.required);
                        }
                    })
                }
            });
        }
        return formFields;
    }

    getFormArray(formGroup) {
        const arr = this.upsertDynamicForm?.controls?.primaryForm as FormArray
        return arr.controls 
    }
      
} 


import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-sensitive-info-mask',
  templateUrl: './sensitive-info-mask.component.html',
  styleUrls: ['./sensitive-info-mask.component.scss']
})
export class SensitiveInfoMaskComponent implements OnInit {

  @Input() type: string;

  @Input() value: string;

  @Input() dialingCode: string;
  isRevealed: boolean = false;
  maskedString: SafeHtml;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.maskedString = this.showMasked(this.type, this.value, this.dialingCode);
   }

   maskString(stringToMask: string) {
     let returnVal: string = '';
     for (let i = 0; i < stringToMask.length ; i++) {
       returnVal = `${returnVal}&#9679;`;
     }
     return returnVal;
   }
   reveal() {
     this.isRevealed = !this.isRevealed;
     if (this.isRevealed) {
       if (this.type !== 'email') {
         this.maskedString = this.sanitizer.bypassSecurityTrustHtml(`+${this.dialingCode ? this.dialingCode : '1'}${this.formatPhoneNumber(this.value)}`);
       } else {
         this.maskedString = this.sanitizer.bypassSecurityTrustHtml(this.value);
       }
     } else {
       this.maskedString = this.showMasked(this.type, this.value, this.dialingCode);
     }
   }

   showMasked(type: string, value: string, dialingCode: string): SafeHtml {
     let maskedString: SafeHtml = null;
     if (type && value) {
       if (this.type === 'email') {
         const splitEmail = this.value.split('@');
         maskedString = this.sanitizer.bypassSecurityTrustHtml(`${splitEmail[0].substr(0, 1)}${this.maskString(splitEmail[0].substring(1, splitEmail[0].length - 1))}@${splitEmail[1]}`);
       } else {
         maskedString = this.sanitizer.bypassSecurityTrustHtml(`+${dialingCode ? dialingCode : '1'}(${this.maskString(value.substring(0, 3))}) ${this.maskString(value.substring(3, 6))}-${value.substring(6, value.length)}`);
       }
     }
     return maskedString;
   }

   formatPhoneNumber(phoneNumber) {
     const regex = /^[A-Za-z0-9 ]+$/;
     if ( regex.test(phoneNumber) ) {
       return `(${phoneNumber.substring(
         0,
         3
       )}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6, phoneNumber.length)}`;
     } else {
       return phoneNumber;
     }
   }
}

<div cdkTrapFocus cdkTrapFocusAutoCapture>
    <div class="popUpTitleBlk" fxLayout="row" fxLayoutAlign="space-between center">
        <h2>Recent Provider Payments</h2><button mat-icon-button (click)="close()"><span class="material-icons">close</span></button>
    </div>
    <mat-divider></mat-divider>
    <app-linked-list [dataSet]="keyDatesData" listStyleClasses="full-width" [columnStyleClasses]="['', '', 'amount-format']"></app-linked-list>
    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutAlign="end center">
        <button mat-button class="outlined-button" tabindex="0" (click)="close()">Close</button><button tabindex="0" mat-button class="contained-button" (click)="viewAll()">View All</button>
    </div>
</div>

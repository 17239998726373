import { shortAddress } from "./address.model"

export class ComplianceListModel {
  readonly transferee: string
  readonly client: string
  readonly departure: string
  readonly destination: string
  readonly authorizationDate: string
  readonly status: string
  readonly lastName: string
  readonly firstName: string
  readonly depState: string
  readonly depCountry: string
  readonly depCity: string
  readonly desState: string
  readonly desCountry: string
  readonly desCity: string
  readonly reason :string
  readonly moveOrderNumber:string
  readonly accountManagersOnFile:string[]
  readonly clientNumber:string
  readonly orderRequestID:string
  readonly authorizationType :string
  readonly moveStatus: string;
  readonly departureAddr?: shortAddress;
  readonly destinationAddr?: shortAddress;
  readonly wasRestored?: boolean

  constructor(payload: any) {
    if (payload) {
      this.client = payload?.client?.clientEntityName ? payload.client.clientEntityName : ''
      this.lastName = payload?.nameDetails?.lastName ? payload.nameDetails.lastName : ''
      this.firstName = payload?.nameDetails?.firstName ? payload.nameDetails.firstName : ''
      this.depCountry = payload?.departureAddr?.country ? payload.departureAddr.country : ''
      this.depState = payload?.departureAddr?.state ? payload.departureAddr.state : ''
      this.depCity = payload?.departureAddr?.city ? payload.departureAddr.city : ''
      this.authorizationDate = payload.authorizationDate ? payload.authorizationDate : ''
      this.desCountry = payload?.destinationAddr?.country ? payload.destinationAddr.country : ''
      this.desState = payload?.destinationAddr?.state ? payload.destinationAddr.state : ''
      this.desCity = payload?.destinationAddr?.city ? payload.destinationAddr.city : ''
      this.status = payload.status  && payload.reason ? `${payload.status} / ${payload.reason}` : `${payload.status}${payload.reason}`
      this.transferee = this.lastName && this.firstName ? `${this.lastName}, ${this.firstName}` : `${this.lastName}${this.firstName}`
      this.departure = this.depCountry && this.depState && this.depCity ? `${this.depCountry}<br>${this.depState}, ${this.depCity}` : `${this.depCountry}<br>${this.depState} ${this.depCity}`
      this.destination = this.desCountry && this.desState && this.desCity ? `${this.desCountry}<br>${this.desState}, ${this.desCity}` : `${this.desCountry}<br>${this.desState} ${this.desCity}`
      this.moveOrderNumber = payload?.orderRefNum ? payload.orderRefNum :''
      this.accountManagersOnFile = payload?.accountManagersOnFile ? payload.accountManagersOnFile : []
      this.clientNumber = payload?.client?.clientNumber ? payload.client.clientNumber :''
      this.orderRequestID = payload?._id ? payload?._id : ''
      this.authorizationType = payload?.authorizationType ? payload?.authorizationType : ''
      this.moveStatus = payload?.moveStatus ? payload.moveStatus : '';
      this.departureAddr = payload?.departureAddr;
      this.destinationAddr = payload?.destinationAddr;
      this.wasRestored = payload?.wasRestored;
    }
  }
}
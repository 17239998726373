<div class="main-pop-styles">
    <div mat-dialog-title class="dialog-header" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px"
        fxLayoutGap.xs="0em">Alert
        <a class="close-icon" (click)="onDismiss()" title="close">
            <mat-icon>close</mat-icon>
        </a>
    </div>
    <div mat-dialog-content class="dailog-title" tabindex="0"><span aria-hidden='true'>{{dialogData?.displayMessage}}</span></div>
    <div class="action-buttons">
        <button class="cancel-adj" (click)='onDismiss()'>Cancel</button>
        <button class="confirm-action" *ngIf="!!dialogData?.showConfirmBtn" (click)='onConfirm()'>{{dialogData?.action === "delete" ? "Delete" : "Confirm"}}</button>
    </div>
</div>
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AdminClient } from './../../../../core/models/admin-client.model';
import { FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CandidateProfileService } from '../../services/candidate-profile.service';
import { Subscription } from 'rxjs';
import { UserTypeService } from '../../services/user-type.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss']
})
export class ClientDetailsComponent implements OnInit {
  /** Basic details of client to be displayed */
  @Input() clientDetails: AdminClient;

  @Output() closePanel = new EventEmitter<boolean>();
  editEmail = true;
  emailAddress = new FormControl('', [Validators.email, Validators.pattern('^[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$')]);
  capabilitySubs: Subscription;
  clientEmailCapability = ''
  editAuthEmail = true;


  constructor(private candidateProfileService: CandidateProfileService,  private userTypeService: UserTypeService,
    private readonly toaster: ToastrService) { }

  ngOnInit() {
    this.capabilitySubs = this.userTypeService.capabilities$.subscribe(ele => {
      this.clientEmailCapability = ele ? ele.find(element => element.name === 'MP - ClientCommunicationMailID')?.operation : ''
    });
    if (this.clientDetails && this.clientDetails.clientId) {
      this.getClientEmailAddress(this.clientDetails.clientId);
    }
  }

  close() {
    this.closePanel.emit(true);
  }

  updateEmail(eve) {
    this.editEmail = eve ? false : true;
  }
 
  submitEmail(eve) {
    if (this.emailAddress.valid) {
      const reqPayload = {
        clientNo: this.clientDetails.clientNumber,
        email: eve ? eve : ''
      };
       this.candidateProfileService.updateClientRouterDetails(this.clientDetails.clientId, reqPayload).subscribe(res => {
        this.displayToasterMessage('Authorization email address has been updated successfully')
        this.editEmail = true;
       });
    }
  }

  getClientEmailAddress(clientId) {
    this.candidateProfileService.getClientRouterdetails(clientId).subscribe(res => {
      if (res) {
        const email = res.email ? res.email : '';
        this.emailAddress.setValue(email);
      };
    });
  }

  getErrorMessage() {
    return this.emailAddress.hasError('email') ? 'Please enter a valid email address' : '';
  }

  displayToasterMessage(message) {
    this.toaster.info(message, null, {
      closeButton: true,
      enableHtml: true,
      disableTimeOut: false,
    });
  }

  ngOnDestroy(): void {
    if (this.capabilitySubs) { this.capabilitySubs.unsubscribe() };
  }
}

/** interface for phone details */
export interface phoneDetails {
  _id?: string;
  textingAvailable?: boolean;
  primary?: boolean;
  usageType?: string;
  phoneDialCode?: number;
  phoneNumber: string;
  locationType?: string;
  type?: string;
  delete?: boolean;
}

export const phoneTypes: Array<string> = ['Mobile', 'Departure Business', 'Departure Residence',
  'Destination Business', 'Destination Residence'];
<header class="header-desktop">
    <a href="/" class="html-logo"><img src="../../../../../assets/images/MovePro360-White.svg" alt="MovePro360-White"></a>
</header>
<main>
    <div class="main-content">
        <h1>Cartus®</h1>
        <h1>PRIVACY STATEMENT</h1>
        <p>Last modified 4, December 2019</p>
        <p> We at <strong>Cartus </strong> (collectively, “Cartus”, “We”, “Us”, or “Our”) cognize the importance of protecting the privacy of personal data (as defined below, "Personal Information") collected by us. This Privacy Statement describes how Cartus collects, uses, discloses, retains, and transfers Personal Information, in performing relocation services including human resources data transferred to us for processing on behalf of our customers, as well as human resources data of our employees. Information obtained and shared with you through this web site is our confidential information unless expressly indicated otherwise. It also describes your choices regarding use, access and correction of your Personal Information. Personal Information may be requested/collected to enable the delivery of our services, to operate our business and to make the experience of using this site faster and more effective.</p>
        <p><strong>By using this web site, you consent to the terms of this Privacy Statement and to Cartus’ processing of Personal Information for the purposes stated above, as well as those explained where Cartus collects Personal Information on the web. IF YOU DO NOT AGREE TO THIS PRIVACY STATEMENT, YOU CANNOT USE THE SITE.</strong> Should our Privacy Statement change, we intend to take every reasonable step to ensure that these changes are brought to your attention by posting all changes prominently on our web site for a reasonable period of time.</p>
        <h3>Scope of this Privacy Statement</h3>
        <p>This Privacy Statement applies to the following sites only:www.cartus.com , go.cartus.com, movepro360.cartus.com, online.cartus.com , www.cartusnetworks.com , www.cartusvirtualresources.com , www.cartusmoves.com , www.myaffinityservices.com and homerewards.cartus.com owned and operated by Cartus (collectively, “Cartus”, “We”, “Us”, or “Our”).</p>
        <p>Each site may contain links to third-party web sites. When you click on those links, you will go to a third-party site where you will be subject to that site’s privacy statement and we encourage you to read that policy statement. Please be aware that we are not responsible for the privacy practices of those other web sites and we expressly disclaim any liability for their actions, including actions relating to the use and disclosure of Personal Information by those third parties.</p>
        <h3><a href="//privacy.truste.com/privacy-seal/validation?rid=b6115b84-e1d0-4d12-bc80-c26bf0a411a0" target="_blank"><img style="border: none" src="//privacy-policy.truste.com/privacy-seal/seal?rid=b6115b84-e1d0-4d12-bc80-c26bf0a411a0" alt="TRUSTe"></a></h3>
        <p>Cartus collects Personal Information in order to operate our business of providing relocation, assignment management and real estate related services. We may transfer Personal Information to companies, which may include our affiliated companies, and others that help us to provide our services. </p>
        <p>In some cases, Cartus acts solely as a processor of the Personal Information at the direction of our client. In those instances, an individual who seeks access to, or who seeks to correct, amend, or delete inaccurate Personal Information should direct the query to the client. If such a query is directed to Cartus, we will review with our client and Cartus or the client will respond to you within a reasonable timeframe.</p>
        <p>We will retain Personal Information for as long as needed to provide our services and as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
        <h3>1. What is Personal Information?</h3>
        <p>Personal Information means any information that may directly identify you, any designee you appoint to manage your MovePro360 account or a member of your family, including name, address, mobile or other telephone number, e-mail address, government identification number, date of birth, photograph, financial account, or other similar data elements that may be created or made available in connection with your relocation. We ask that you refrain from providing us with sensitive personal information about you or members of your family, such as your ethnicity, religion, health condition, and/or sexual orientation, as these data elements are not usually needed for your relocation.</p>
        <h3>2. Collection, Use, Transfer and Protection of Your Personal Information</h3>
        <h3>2(a). Use of Personal Information</h3>
        <p>We process your Personal Information as necessary for Cartus to provide relocation services under the terms of its contractual obligations with your employer or membership group consistent with the legitimate interests of your employer or membership group (i.e., our corporate or government entity client). If you provide us with sensitive Personal Information, we will process it based on your consent. We will collect, use, disclose, retain and transfer Personal Information only for purposes of managing and facilitating your move from one location to a new location (the “Relocation Purpose”), including the following to the extent authorized by your employer or membership group: (a) arranging for your physical move and the storage of your property; (b) tracking and processing expense reimbursements; (c) home sales, purchases, and rentals, including financing arrangements; (d) explaining various employer-sponsored benefits; (e) immigration and other customs related purposes; (f) cultural assistance and training; and (g) other activities relating to the delivery and improvement of relocation services. Any access to your Personal Information is restricted to those individuals that have a need to know to fulfill their job responsibilities. We will not use Personal Information for any other purpose incompatible with the Relocation Purpose outlined in this Privacy Statement, unless it is required or authorized by laws that apply to Cartus or otherwise authorized by you.</p>
        <h3>Collection of Personal Information</h3>
        <p>While most of the Personal Information collected by Cartus is supplied by our clients, our suppliers, and our customers as part of the Relocation Purpose, we may, at times, ask you to (or you may voluntarily in MovePro360) update or confirm a subset of that Personal Information on this web site. To do this, you may be asked to register to use this site. Additionally, your Personal Information may be shared with our affiliates, and third party suppliers (e.g. home inspectors, moving companies) where necessary to support and solely for the Relocation Purpose, which may include making Personal Information available to your employer or membership group. In addition, we may collect Personal Information from our suppliers and from client and potential client contacts. That Personal Information may be used by Cartus to market and/or provide our services to those clients and potential clients.</p>
        <h3>Transfer of Personal Information</h3>
        <p>We will share your Personal Information with our affiliates and with third parties for any legitimate reason related to the Relocation Purpose and only in the ways that are described in this Privacy Statement. In addition, your information may be stored and secured in databases outside of your home country, including the United States of America. For example, we may disclose Personal Information (a) to local subcontractors of Cartus who provide relocation services in countries in which Cartus does not have offices; (b) to a third party service provider with facilities in India which processes Personal Information for the Relocation Purpose (e.g., freight forwarder invoices may be audited in India); or (c) to governmental authorities, courts, external advisors, or other similar parties located in or outside out of your home country. Some of those countries may have different data protection standards than your home country. We exercise appropriate due diligence in selecting our third party service providers, and contractually require them to treat your Personal Information confidentially, and implement appropriate technical, organizational, and administrative controls to safeguard it against unauthorized access, use, or disclosure.</p>
        <p>We remain responsible for the handling of your Personal Information by those affiliates and third parties as provided in the EU - U.S. Privacy Shield Framework Principles and the Swiss-U.S. Privacy Shield Framework Principles, including the Supplemental Principles.</p>
        <p>Cartus may disclose Personal Information about you in special cases when we have reason to believe that disclosing this Personal Information is necessary to identify, contact or bring legal action against someone who may be violating Cartus' terms of use or terms of service, or may be causing injury to or interference (either intentionally or unintentionally) with any of Cartus' rights or property, other Cartus customers, or anyone else. Cartus may disclose or access Personal Information when we believe in good faith that the law permits it, as required by law, such as to comply with a subpoena, and for administrative and other purposes that we deem necessary to maintain, service, and improve our products and services.</p>
        <h3>Data Retention</h3>
        <p>We retain your Personal Information only to the extent necessary to perform contractual obligations and to meet the compliance obligations that apply to our multi-jurisdictional business, as reflected in our record retention policy. When we no longer need your Personal Information, we will securely remove, deactivate, or de-identify your Personal Information from our systems. If you wish to request that we no longer use your Personal Information for the Relocation Purpose, contact us as specified in <strong>Contacting Us/Correction/Updating Personal Information/Exercising Your Rights.</strong> We will retain and use your information as necessary to comply with our legal obligations, resolve disputes and enforce our agreements.</p>
        <h3>Registration for Personalized Services</h3>
        <p>If you wish to use Cartus’ personalized services, you may be asked to create an account or enroll as a delegate authorized by an existing account holder. The process of creating this account or enrolling as a delegate necessitates that you provide Personal Information about yourself. If you choose to provide us with your Personal Information on the web, we may transfer that Personal Information within Cartus and its affiliates or to Cartus’ third-party service providers, including locations across borders and from your country or jurisdiction to other countries or jurisdictions around the world.</p>
        <p>Cartus strives to comply with all applicable laws worldwide that are designed to protect your privacy. Although legal requirements may vary from country to country, Cartus intends to adhere to the principles set forth in this Privacy Statement even if, in connection with the above, we transfer your Personal Information from your country to countries that may not require an "adequate" level of protection for your Personal Information. In other words, our goal is to provide protection for your Personal Information no matter where that Personal Information is collected, transferred, or retained.</p>
        <h3>Requests for Periodic Industry Updates</h3>
        <p>If you request Periodic Industry Updates, we collect Personal Information, which is submitted directly to our web hosting services vendor’s system to create automatic e-mails to people who choose to subscribe. The information we collect is not used in any other way and is not retained once you end your subscription to Periodic Industry Updates. If you would like to discontinue receiving this information, you may update your email preferences by using the “Unsubscribe” link found in emails we send to you or by contacting us at <a href="mailto:InformationProtection@cartus.com">InformationProtection@cartus.com</a>.</p>
        <h3>Requests to Find or List a Property and to Find a Sales Associate</h3>
        <p>If you submit a request through this site to find or list a property or to find a real estate agent, you will be asked to provide Personal Information and we refer this information to real estate agents to respond to your request. For example, if you ask that we list your property or if you ask to see more listings in a given area, you will be directed to a real estate agent in that area.</p>
        <h3>Voice Recording</h3>
        <p>In addition, telephone calls to or from Cartus in the United States may be recorded. Phone call participants are advised at the commencement of services that their participation in telephone calls to or from Cartus, whether or not an announcement is made at the time of the call, grants all rights of permission of the participant(s) to Cartus to record calls and utilize such recordings for its business purposes. The participant is advised to notify and obtain recording consent from any family members or others who may communicate with Cartus as part of a relocation or other parties that participate in conference calls in or request Cartus to conference in to any telephone calls with Cartus.</p>
        <h3>2(b). Passive Collection</h3>
        <p>As is true of most websites, we gather certain information automatically. This information may include Internet protocol (IP) addresses, browser type, Internet service provider (ISP), referring/exit pages, the files viewed on our site (e.g., HTML pages, graphics, etc.), operating system, date/time stamp, and/or clickstream data to conduct research, analyze trends in the aggregate for internal audits and third-parties, generally to improve our services, and administer the site.</p>
        <h3>Tracking Technologies</h3>
        <p>Technologies such as cookies or similar technologies are used by Cartus and our marketing partners and analytics providers. These technologies are used in analyzing trends, administering the site, tracking users’ use of the site and to gather demographic information about our user base as a whole. We may receive reports based on the use of these technologies by these companies on an individual as well as aggregated basis.</p>
        <p>We use cookies for session control and for authentication. Users can control the use of cookies at the individual browser level. If you reject cookies, you may still use our site, but your ability to use some features or areas of our site may be limited.</p>
        <h3>Behavioral Targeting / Re-Targeting</h3>
        <p>We partner with a third party to display advertising on our website or to manage our advertising on other sites. Our third party partner may use cookies or similar technologies in order to provide you advertising based upon your browsing activities and interests. If you wish to opt out of interest-based advertising click here [or if located in the European Union click here ]. Please note you will continue to receive generic ads.</p>
        <h3>2(c). Use of Information With Respect to Third Parties</h3>
        <p>We reserve the right to share your Personal Information with our clients, affiliates and third party service providers, as described herein, in order to help us deliver our services. As authorized by your employer or membership group we may also share your Personal Information with third parties who offer or provide you with other services related to the Relocation Purpose. Those third parties (for example, landlords, schools and lenders) will offer and/or enter into direct relationships with you and will not be subject to our control. We encourage you to read their privacy policies/statements. Please be aware that we are not responsible for the privacy practices of those third parties.</p>
        <p>Cartus may also disclose Personal Information if Cartus is involved in a merger, acquisition, or sale of all or a portion of its assets, in such case, you will be notified via email and/or a prominent notice on our web site of any change in ownership or uses of your Personal Information, as well as any choices you may have regarding your Personal Information.</p>
        <p>Service providers are also an important means by which Cartus maintains its web site and mailing lists. Cartus will take reasonable steps to ensure that these third-party service providers are obligated to protect Personal Information on Cartus’ behalf.</p>
        <p>Cartus does not intend, without your consent, to transfer Personal Information to third parties who are not bound to act on Cartus’ behalf unless such transfer is legally required or requested by your employer or membership group as part of the Relocation Purpose. Similarly, it is against Cartus’ policy to sell, without your consent, your Personal Information collected online.</p>
        <h3>Social Media Widgets</h3>
        <p>Our Web site may include Social Media Features, such as the Facebook Like button and Widgets, such as the Share this button. These Features may collect your IP address, which page you are visiting on our site, and may set a cookie to enable the Feature to function properly. Social Media Features and Widgets are either hosted by a third party or hosted directly on our Site. Your interactions with these Features are governed by the privacy statement of the company providing it.</p>
        <h3>3. Location of Servers and Transfer of Information to the United States</h3>
        <p>The site is hosted by servers in the United States, and, as such, the site is governed by United States law. If you are located outside of the United States, your Personal Information will be transferred to the United States. By submitting your Personal Information, you acknowledge our legitimate business interest or consent, as applicable to your jurisdiction, in making the transfer to the United States and to its storage, processing, and use there in accordance with this Privacy Statement and United States law.</p>
        <h3>4. Compliance with Privacy Shield and Independent Recourse Mechanism; Federal Trade Commission Jurisdiction</h3>
        <p>Cartus complies with the EU-U.S. Privacy Shield Framework and the Swiss – U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information transferred from the European Union and the United Kingdom and/or Switzerland to the United States, respectively in reliance on Privacy Shield. Cartus has certified to the Department of Commerce that it adheres to the Privacy Shield Principles. If there is any conflict between the terms in this Privacy Statement and the Privacy Shield Principles, the Privacy Shield Principles shall govern. To learn more about the Privacy Shield program, and to view our certification, please visit visit <a href="https://www.privacyshield.gov/" traget="_blank">https://www.privacyshield.gov/</a> .
        <p>Cartus is responsible for the processing of Personal Information it receives, under the Privacy Shield Framework, and subsequently transfers to a third party acting as an agent on its behalf. Cartus complies with the Privacy Shield Principles for all onward transfers of Personal Information from the EU and Switzerland, including the onward transfer liability provisions.</p>
        <p>With respect to Personal Information received or transferred pursuant to the Privacy Shield Frameworks, Cartus is subject to the regulatory enforcement powers of the U.S. Federal Trade Commission. In certain situations, Cartus may be required to disclose Personal Information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.</p>
        <p>If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact our U.S.-based third party dispute resolution provider (free of charge) at <a href="https://feedback-form.truste.com/watchdog/request" target="_blank">https://feedback-form.truste.com/watchdog/request</a> . Where applicable for employee matters, in the context of employment relationship, and employee data that Cartus processes in its role as a service provider, Cartus commits to cooperation with the panel of the EU Data Protection Authorities (DPAs), the state or national data protection authority where the employee works or the Swiss Federal Data Protection and Information Commissioner (FDPIC), as applicable, to investigate unresolved complaints.</p>
        <p>Under certain conditions, more fully described on the Privacy Shield website <a href="https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint" target="_blank">https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint</a>, you may be entitled to invoke binding arbitration when other dispute resolution procedures have been exhausted. Once arbitration is invoked, in some cases the individual may lose the option to seek relief for the same claimed violation in another forum. For more information about binding arbitration please visit: www.commerce.gov/privacyshield/ .</p>
        <h3>5. Security</h3>
        <p>The security of your Personal Information is important to us. Cartus maintains its systems in accordance with reasonable industry standards to reasonably secure the Personal Information of its customers against unauthorized access, accidental or intentional manipulation, and loss, both during transmission and once it is received. However, no data transmission over the Internet can be guaranteed to be 100% secure, and you use the site at your own risk. If you have any questions about the security of your personal information, you can contact us using the addresses noted in the “Contact Us” section below.</p>
        <h3>Blogs</h3>
        <p><a href="https://www.cartus.com/en/blog" target="_blank">www.cartus.com/en/blog</a> offers publicly accessible blogs or community forums. You should be aware that any information you provide in these areas may be read, collected, and used by others who access them. To request removal of your personal information from our blog or community forum, contact us at InformationProtection@cartus.com . In some cases, we may not be able to remove your personal information, in which case we will let you know if we are unable to do so and why.</p>
        <h3>6. Children</h3>
        <p>This site is not directed to children under the age of 13 (“Children”), and we do not knowingly collect any information on this site, including Personal Information, from Children.</p>
        <h3>7. Changes in this Privacy Statement</h3>
        <p>If we decide to change our Privacy Statement, we will post those changes to this Privacy Statement here, and any other places we deem appropriate so that you can be aware of what Personal Information we collect, how we use it, and under what circumstances, if any, we disclose it. We reserve the right to modify this Privacy Statement at any time, so please review it frequently. If we make material changes to this Statement, we will notify you here or by other means we deem appropriate, prior to the change becoming effective.</p>
        <h3>8. Contacting Us/Correction/Updating Personal Information/Exercising Your Rights</h3>
        <p>You may contact us through the methods stated below for a number of purposes, including: (1) if you no longer wish to use the site; (2) to opt-out of communications or unsubscribe from any industry updates as described in “Requests for Periodic Industry Updates”; (3) to receive information or special offers; (4) to provide feedback and comments on this Statement; (5) to request access to your Personal Information, deletion or correction of your incorrect Personal Information and/or to confirm if we hold any of your Personal Information; and (6) to make inquiries whether we hold or process on behalf of a third party, any of your Personal Information or complaints/objections regarding our Privacy Statement and/or privacy practices.</p>
        <p>By e-mail to: <a href="mailto:InformationProtection@cartus.com">InformationProtection@cartus.com</a></p>
        <p>By mail: <strong>Privacy Officer,</strong> Cartus, 100 Reserve Road, Danbury, CT 06810;<br />Or if your relocation is being handled by our UK affiliate, or you are a<br />Cartus employee located in the EU:</p>
        <p>Director of Legal Services, Cartus Limited, Frankland Road, Blagrove,<br />Swindon, Wiltshire SN5 8RS</p>
        <p>By phone: (203) 205-1540</p>
        <p>By phone (Singapore): +65 6880 5804</p>
        <p>We will respond to your request within a reasonable timeframe.</p>
        <p>In addition, if you are a resident of the EU, you may have the following rights that you should exercise through the contacts listed above or directly through your employer or membership group. Cartus will respond to you or aid your employer when you exercise the following rights:</p>
        <ul style="list-style-type: disc !important;padding-left:1em !important;margin-left:1em;">
            <li>The right to require a copy of your personal data;</li>
            <li>The right to require that inaccurate or out of date personal data be corrected;</li>
            <li>The right to request that personal data is erased when it is no longer necessary to be retained;</li>
            <li>If consent it the basis for processing, the right to withdraw your consent;</li>
            <li>The right to data portability, if applicable;</li>
            <li>The right, where there is a dispute in relation to the accuracy or processing of your personal data, to request a restriction be placed on further processing;</li>
            <li>The right to object to processing, if applicable; and</li>
            <li>You also have the right to lodge a complaint with your applicable data protection authority.</li>
        </ul>
    </div>
</main>

<footer>
    <div class="footer-content">
        <div class="copyrights">&copy; 2000-{{currentYear}} Cartus Corporation</div>
    </div>
</footer>

import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { BaseClientService } from '../../core/services/base-client.service';
import { RemoteLoggingService } from '../../core/services/remote-logging.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicBenefitService {

  lumpSumBenefitPrefix = 'OFF-LumpSumSim';
  lumpSumReference: string = 'CMP-LmpSmSDA';
  displayWithCommas: string = 'ACCEPT_COMMAS';


  constructor(private baseClientService: BaseClientService, private logSvc: RemoteLoggingService) { }

  getAttributesConfig(productReference): Observable<any> {
    return this.baseClientService
      .getOne<any>(`v1/admin/prod-spec-metadatas?ref=${productReference} `)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
    }
  
}

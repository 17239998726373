import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customTitlecase'
})
export class CustomTitlecasePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return value.toLowerCase().split(args).map(word => {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

}

import { Component, Input, OnInit, ViewChild, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortHeaderIntl, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CandidateProfileService } from '../../services/candidate-profile.service';
import { UserTypeService } from '../../services/user-type.service';
import { SubmissionFailureComponent } from '../submission-failure/submission-failure.component';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

export interface UnpTransferee {
  transferee: string;
  client: string;
  departure: string;
  destination: string;
  date: any;
  originalsubmission: string;
  status: string
}

export interface Options {
  sortBy ?: string;
  sortDirection ?: string;
  searchText?: string,
  filters ?: any,
  itemsPerPage?: number,
  pageNumber?: number
}

@Component({
  selector: 'app-unprocessed-list',
  templateUrl: './unprocessed-list.component.html',
  styleUrls: ['./unprocessed-list.component.scss']
})
export class UnprocessedListComponent implements OnInit, OnChanges {
  displayedColumns: string[] = ['transferee', 'client', 'authorizationType','departure', 'destination', 'date', 'original submission', 'status'];
    dataSource = new MatTableDataSource();
    displayUnprocessedData: UnpTransferee[] = [];

    /** To sort the mat table columns */
  @ViewChild(MatSort) sort: MatSort;

  /** Pagination of Unprocessed transferee */
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /**Direction varialbe to get sort direction */
  direction: SortDirection;

   /** sortDirection to hold the asc or desc direction value */
   sortDirection: 'ASC' | 'DESC' | null;

   /** sortColumnName to hold the clicked column name for sorting */
  sortColumnName: 'transferee' | 'client' | 'departure' | 'destination' | 'date' | 'status' | null;

  /** Options to hold the sort column name | sort direction | pagination */
  options: Options = {};

  @Input() searchValue: string;
  @Input() selectedFilters: any;
 
  /** page event variable to handle the page events */
  pageEvent: PageEvent;
  @Input() searchKeyword:string
  
  manageAuthorizationFailures: string;
  

  constructor(
         private candidateService: CandidateProfileService,
         private readonly matSortService: MatSortHeaderIntl,
         private userTypeService: UserTypeService,
         public dialog: MatDialog,
      ) {
        // this.matSortService.sortButtonLabel = (id: string) => { return `"Change sorting for " ${id}`; };
        this.pageEvent = new PageEvent();
        this.pageEvent.pageIndex = 0;
        this.pageEvent.pageSize = 75;
        this.options.pageNumber = this.pageEvent.pageIndex;
        this.options.itemsPerPage = this.pageEvent.pageSize;
      }

  ngOnChanges(changes: SimpleChanges): void {
    if(typeof changes['selectedFilters'] !== "undefined" && !changes['selectedFilters'].firstChange) {
      if(changes['selectedFilters'].currentValue){
        const filters = {...changes['selectedFilters'].currentValue};
          if(filters.clients) {
            let selectedFilter = {...filters}
            let clientPartyIds = [...selectedFilter.clients];
            clientPartyIds = clientPartyIds.map(ele => ele.clientId);
            delete selectedFilter.clients;
            this.options['filters'] = {...selectedFilter, clientPartyIds: clientPartyIds};
          } else {
            this.options['filters'] = filters;
          }
          this.subscribeToUnpocessList(this.options);
      } 
    }

    if(typeof changes['searchValue'] !== "undefined" && !changes['searchValue'].firstChange) {
      if(changes['searchValue'].currentValue){
        const searchText = changes['searchValue'] .currentValue;
          if(searchText && searchText.length >= 3){
            this.options['searchText'] = searchText;
            this.subscribeToUnpocessList(this.options);
          }
      }  else {
        this.options['searchText'] = '';
        this.subscribeToUnpocessList(this.options);
      }
    }
  }


  ngOnInit() {
    this.userTypeService.capabilities$.subscribe(ele => {
      if (ele) { this.checkCapabilities(ele) };
    });
    this.dataSource.sort = this.sort;
    if(this.paginator) {
      this.paginator.pageSize = this.pageEvent.pageSize;
      this.dataSource.paginator = this.paginator;
    }

    this.subscribeToUnpocessList();
    localStorage.removeItem('view-org')   
  }

  /**
   * Sort table based on Column name.
   * @param columnName Column name to be sorted.
   */
   applySort(columnName: string) {
    this.initSort(columnName);
    this.options.sortBy = columnName;
    this.options.sortDirection = this.sortDirection;
    this.subscribeToUnpocessList(this.options);
  }
  /**
   * Init Sort ..
   * @param columnName Column name to be sorted.
   */
   initSort(columnName: string): void {
    if (this.sortColumnName === columnName) {
      this.sortDirection == 'ASC' ? this.sortDirection = 'DESC' : this.sortDirection = 'ASC';
    } else {
      this.sortDirection = 'ASC';
    }
    this.sortColumnName = columnName as any;
  }


  checkCapabilities (capabilitiesList: any []) {
    capabilitiesList.forEach(capability => {
      if(capability && capability.permission === 'allow' && capability.operation) {
        if(capability.name ==="MP - Manage Authorization Failures") {
          this.manageAuthorizationFailures = capability.operation;
        }
        }
       });
    }


  getUnprocessData(data) {
    this.dataSource.data = [];
    this.dataSource = new MatTableDataSource(data.candidates);
  }

  subscribeToUnpocessList(options?) {
    if (!options) {
      options = {
        itemsPerPage: this.pageEvent.pageSize,
        pageNumber: this.pageEvent.pageIndex
      };
    }
    this.candidateService.getUnprocessedTransferee(options).subscribe(
      res => {
        if (res) {
          this.getUnprocessData(res);
          if(this.paginator){
            this.paginator.length = res.count;
            this.paginator.pageIndex = this.pageEvent.pageIndex;
            this.paginator.pageSize = this.pageEvent.pageSize ? this.pageEvent.pageSize : null;
            }
        } else {
          this.dataSource = new MatTableDataSource([]);
          if (this.paginator) { this.paginator.length = 0 };
        }
      });
  }


  onStatusClick(candidate) {
    const dialogRef = this.dialog.open(SubmissionFailureComponent, {
      data: {
        candidate: candidate
      },
      width: '52vw',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof (result) != undefined && result != null) {
        if (this.sortDirection && this.sortColumnName) {
          this.options.sortBy = this.sortColumnName;
          this.options.sortDirection = this.sortDirection;
        }
        this.subscribeToUnpocessList(this.options);
      }
    });
  }

  onPageChange(event) {
    if (event) {
      this.pageEvent.pageIndex = event.pageIndex;
      this.pageEvent.pageSize = event.pageSize;
    } else {
      this.pageEvent.pageIndex = 0;
    }
    this.options.itemsPerPage = this.pageEvent.pageSize;
    this.options.pageNumber = this.pageEvent.pageIndex;
    this.subscribeToUnpocessList(this.options);
  }
  
  goToLink(data) {
    var newWindows = window.open('/#/original-submission','_blank');
    newWindows.localStorage.setItem('view-org', 'true');
    let object = { 
      "candidate": data,
      "client": data.client,
      "authorizationDate": data.authorizationDate,
      "validationMessages": data.validationMessages,
    }
    newWindows['data'] = object
  }
}



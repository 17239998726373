import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-restore-transferee-confirmation-modal',
  templateUrl: './restore-transferee-confirmation-modal.component.html',
  styleUrls: ['./restore-transferee-confirmation-modal.component.scss']
})
export class RestoreTransfereeConfirmationModalComponent implements OnInit {

    /**
   * @param dialogRef DialogRef
   * @param data Data to popup
   */
     constructor(public dialogRef: MatDialogRef < RestoreTransfereeConfirmationModalComponent > ,
      @Inject(MAT_DIALOG_DATA) public data: any) { }
  
    ngOnInit() {
    }
  
    /**
   * To dismiss/cancel the popup
   */
    onDismiss() {
      this.dialogRef.close();
    }
    /**
     * Confirm action to parent component
     */
     restore() {
      this.dialogRef.close({
        action: 'restore'
      });
    }

}

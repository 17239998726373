<header class="header-desktop">
    <a href="/" class="html-logo"><img src="../../../../assets/images/MovePro360-White.svg" alt="MovePro360-White"></a>
</header>
<main>
    <div class="main-content">
        <h1>
            Page Not Found
        </h1>
        <p>
            The page you are looking for is not found.
        </p>

        <p>Below are few useful links </p>
        <ul>
            <li><a href="#/dashboard">My Dashboard</a></li>
        </ul>
    </div>
</main>

<footer>
    <div class="footer-content">
        <div class="footer-links"><a [href]="termsOfUseUrl" target="_blank" class="">Terms of Use</a><span
                class="footer-link-divider">|</span><a [href]="privacyNotice" target="_blank" class="">Privacy
                Statement</a></div>
        <div class="copyrights">© 2000-{{currentYear}} Cartus Corporation</div>
    </div>
</footer>
<div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
fxLayoutGap="20px" fxLayoutGap.xs="0em">
<div class="warnig-dialogtitle">Point Calculation History</div>
<span  class="close-icon" (click)="onDismiss($event)" title="close"><mat-icon>close</mat-icon></span> 
</div>
<div tabindex = "0" >
    <p class="warning-content">Below you can view the history of changes that affected the point calculation for this transferee.</p>
</div>
<mat-dialog-content>
  <mat-accordion  *ngFor="let tableDatas of tableData ; let i =index"  class=" candidate-review-panel move-departure-panel border-radius" [togglePosition]="'after'"
    multi="true" >
    <mat-expansion-panel class="move-detail-panel work-expansion-panel" [expanded]="false">
      <mat-expansion-panel-header class="panel-color" [collapsedHeight]="'auto'"
        [expandedHeight]="matExpansionHeaderHeight">
        <div fxFlex="30%" fxLayout="row" fxLayoutAlign="space-between none" class="work-panel-heading ">
          <p style="color: #2065F8;">{{tableDatas.date}} {{tableDatas.initialCalculation ? 'Initial Calculation' : tableDatas.manualOverride ? 'FSU Manual Override' :'Recalculation'}}</p>
        </div>
        <div fxFlex="30%" fxLayout="row" fxLayoutAlign="space-between none" class="work-panel-heading ">
            <p style="color: #707070;">Points Assigned :{{tableDatas.totalPoints}} Points</p>
          </div>
        <div fxLayout fxFlex="37" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign.sm="start center"
          fxLayoutAlign="end center" class="xs-none">
          <div fxLayout="row" class="launch-text">
            <span class="launch">Updated by:</span>
            <span class="updated-name"><app-avatar [firstName]="tableDatas.firstName" [lastName]="tableDatas.lastName" class="updated-avatar">
            </app-avatar>
        </span>
            <span >{{tableDatas .firstName}}</span>
             <span class="added-by">{{tableDatas.lastName}}</span>
          </div>
        </div>
      </mat-expansion-panel-header>
      <table mat-table   *ngIf = "tableDatas.tableValue" [dataSource]="tableDatas.tableValue">
        <!-- Details Column -->
        <ng-container matColumnDef="details">
          <th mat-header-cell *matHeaderCellDef> Details </th>
          <td mat-cell *matCellDef="let element"> {{element.details}} </td>
        </ng-container>
        <!--  Prior Value Column -->
        <ng-container matColumnDef="recordValue">
          <th mat-header-cell *matHeaderCellDef> Prior Value </th>
          <td mat-cell *matCellDef="let element"> {{element.recordValue}} </td>
        </ng-container>  
        <!-- Current Value Column -->
        <ng-container matColumnDef="searchCriteria">
          <th mat-header-cell *matHeaderCellDef> Current Value </th>
          <td mat-cell *matCellDef="let element"> {{element.searchCriteria}} </td>
        </ng-container>  
        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="warnig-dialogtitle-1"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </mat-expansion-panel>
      </mat-accordion>
    
      <div fxLayout="row" fxLayoutAlign="end center" >
        <button  fxFlex='15%'class="confirm-btn"  mat-button (click)="onDismiss($event)">Close</button>    </div>
    </mat-dialog-content>
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

  constructor(public dialog: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
    ) { }

  ngOnInit(): void {
  }

  onDismiss() {
    this.dialog.close({
      action: 'dismiss'
    })
  }

  onConfirm() {
    this.dialog.close({
      action: 'confirm'
    })
  }
}
